import * as types from './type'

const initialState = {
    dataId: [],
    loadingId: false
}

export const dataLeadStatusIdRuducer = (state = initialState, action) => {

    switch (action.type) {

        case types.GET_LEAD_STATUS_ID:
            {
                return {
                    ...state,
                    ...{ dataId: [] }
                }
            }
        case types.GET_LEAD_STATUS_ID_SUCCESS:
                return {
                    ...state,
                    ...{ dataId: action.payload.status }
                }
        case types.GET_LEAD_STATUS_ID_FAIL:
            {
                return {
                    ...state,
                    ...{ dataId: [] }
                }
            }
        case types.CREATE_LEAD_STATUS_ID:
            return {
                ...state,
                ...{dataId: [], loadingId: true}
            }
        case types.CREATE_LEAD_STATUS_ID_SUCCESS:
            return {
                ...state,
                loadingId: false
            }
        default:
            return state
    }
}