
import * as types from "./type"

export const getTicket = (data) => ({
  type: types.GET_TICKET,
  payload: data
})
export const getTicketSuccess = (data) => ({
  type: types.GET_TICKET_SUCCESS,
  payload: data
})
export const getTicketFail = (data) => ({
  type: types.GET_TICKET_FAIL,
  payload: data
})

// for create company

export const createTicket = (data) => ({
  type: types.CREATE_TICKET,
  payload: data
})

export const createTicketSuccess = (data) => ({
  type: types.CREATE_TICKET_SUCCESS,
  payload: data
})
export const createTicketFail = (data) => ({
  type: types.CREATE_TICKET_FAIL,
  payload: data
})



export const resetMessage = (data) => ({
  type: types.RESET_MESSAGE,
  payload: data
})

export const resetMessageError = (data) => ({
  type: types.RESET_MESSAGE_ERROR,
  payload: data
})

//EDIT
 export const editTicket = (data) => ({
   type: types.EDIT_TICKET,
   payload: data
 })
 export const editTicketSuccess = (data) => ({
   type: types.EDIT_TICKET_SUCCESS,
   payload: data
 })
 export const editTicketFail = (data) => ({
   type: types.EDIT_TICKET_FAIL,
   payload: data
 })

// //Delete
// export const deleteSms = (data) => ({
//   type: types.DELETE_TICKET,
//   payload: data
// })
// export const deleteSmsSuccess = (data) => ({
//   type: types.DELETE_TICKET_SUCCESS,
//   payload: data
// })
// export const deleteSmsFail = (data) => ({
//   type: types.DELETE_TICKET_FAIL,
//   payload: data
// })