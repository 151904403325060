export const GET_PRODUCTS_START = "GET_PRODUCTS_START"
export const GET_PRODUCTS_SUCCESS = "GET_PRODUCTS_SUCCESS"
export const GET_PRODUCTS_ERROR = "GET_PRODUCTS_ERROR"

export const START_SEARCH_PRODUCTS = "START_SEARCH_PRODUCTS"

export const CHECKED_PRODUCTS = "CHECKED_PRODUCTS"

export const POST_PRODUCTS = "POST_PRODUCTS"
export const POST_PRODUCTS_SUCCESS = "POST_PRODUCTS_SUCCESS"
export const POST_PRODUCTS_ERROR = "POST_PRODUCTS_ERROR"

export const PUT_PRODUCTS = "PUT_PRODUCTS"
export const PUT_PRODUCTS_SUCCESS = "PUT_PRODUCTS_SUCCESS"
export const PUT_PRODUCTS_ERROR = "PUT_PRODUCTS_ERROR"