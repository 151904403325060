import React from 'react'
import {
 
  Typography,
  Paper,
} from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import moment from 'moment';
const useStyles = makeStyles((theme) => ({
  tableBorder: {
    border: "1px solid #d4d8dd",
    borderRadius: "0.5rem",
  },
}));

const TicketHistoryCareList = ({detailCustomer}) => {
  const classes = useStyles();
  
  // sort note list by time newest
  const arraySortByDate =
    detailCustomer?.detail?.history_care.length > 0 &&
    detailCustomer?.detail?.history_care
      .sort((a, b) => (a.created_on < b.created_on ? 1 : -1))
      .map((item, index) => (
        <div className="item" key={index}>
          <div className="box-avatar">
            {item?.created_by.first_name?.charAt(0)}
          </div>

          <div className="box-content">
            <div className="note-list-item">
              <div className="name">
                {item?.created_by.first_name}
                <span className="time">
                  {" "}
                  - {moment(item?.created_on).format("DD-MM-YYYY, h:mm A")}
                </span>
              </div>
              <p>{item?.content}</p>
            </div>
          </div>
        </div>
      ));
  return (
    <>
     
    <Paper
          sx={{ width: "100%", mb: 2 }}
          elevation={0}
          className={`${classes.tableBorder}`}
        >
          <div
        className="ul-heading"
        style={{ padding: '8px', display: "flex", alignItems: "center", marginBottom:'0px',flexWrap:'wrap' }}
      >
        <span>Lịch sử chăm sóc</span>
      </div>
      {detailCustomer?.detail?.history_care.length > 0 ? (
        <div className="box-note" style={{ margin:10 }}>
      <div className='box-note-form'>
      <div className="list">{arraySortByDate}</div>
      </div>  
      </div>
      ) : (
        <Typography style={{ padding: '8px'}}>No data found</Typography>
      )}  
      </Paper>
      
    </>
  )
}

export default TicketHistoryCareList