import { put, takeLatest } from "redux-saga/effects";
import * as types from './type';
import * as actions from './action';
import axiosInstance from "../../components/axiosApi";

function* getNewRevenue({payload}) {
    try {
        const res = yield axiosInstance.get(`/report/new-revenue/${payload}/`);
        yield put(actions.getNewRevenueSuccess(res.data))
    } catch (error) {
        yield put(actions.getNewRevenueFail(error))
    }
}

export function* watchingGetNewRevenue() {
    yield takeLatest(types.GET_NEW_REVENUE, getNewRevenue);
}