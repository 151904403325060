import {
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React, { useEffect } from "react";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import moment from "moment";
import { LeadDetail } from "./styled";
import { useDispatch, useSelector } from "react-redux";
import { getLeadStatusId } from "../../../../../redux/sourceLead/action";
import DialogOrderStatus from "../Dialog";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  customModalContent:{
    paddingLeft:'15px',
    paddingRight:'15px'
  },
  customModal:{
    width:'calc(100% - 20px)',
    marginLeft:'10px',
    marginRight:'10px'
  },
  customModalTitle:{
    padding:'10px 15px',
    color:'#fff',
    background:process.env.NODE_ENV === 'development' ? "#5a8dee" : process.env.REACT_APP_PRODUCT_BASE_COLOR
  },
  notHover:{
    "&:hover": {
      backgroundColor: "transparent"
    }
  }
}));
const TableOrder = ({ leadStatus }) => {
  const classes = useStyles();
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "rgba(38,60,85,.1)",
      color: "#516377",
      fontWeight: 700,
      padding: 10,
      fontSize: ".75rem",
      textTransform: "uppercase",
      letterSpacing: "0.8px",
    },
    [`&.${tableCellClasses.body}`]: {
      padding: 10,
      lineHeight: "18px",
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  const [open, setOpen] = React.useState(false);
  const [statusId, setStatusId] = React.useState();
  const [idOpen, setIdOpen] = React.useState();
  const [userDetailOpen, setUDO] = React.useState(false);
  const dispatch = useDispatch();

  const dataLeadStatusIdRuducer = useSelector(
    (item) => item.dataLeadStatusIdRuducer
  );
  const loadingId = useSelector(
    (item) => item.dataLeadStatusIdRuducer.loadingId
  );

  const handleStatusId = (id) => {
    setOpen(!open);
    setIdOpen(id);
    dispatch(getLeadStatusId(id));
    setStatusId(id);
  };
  const handleAddStatusId = (id) => {
    setUDO(true);
  };

  const handleCloseUserDetailDialog = () => {
    setUDO(!userDetailOpen);
  };

  const Row = (props) => {
    const { row } = props;

    return (
      <React.Fragment>
        <TableRow>
          <TableCell></TableCell>
          <TableCell align="left">
            {row.id}
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => handleStatusId(row.id)}
              className={classes.notHover}
            >
              <LeadDetail style={{fontSize: '13px',color: '#f46a6a'}}>({row.total_sub} nguồn con)</LeadDetail>
            </IconButton>
          </TableCell>
          <TableCell align="left">{row.title}</TableCell>
          <TableCell align="left">
            {moment(row.created_on).format("DD-MM-YYYY, h:mm A")}
          </TableCell>
          <TableCell></TableCell>
        </TableRow>
        {row.id === idOpen && (
          <StyledTableRow>
            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
              <Collapse in={open} timeout="auto" unmountOnExit>
                <Box sx={{ margin: 1 }}>
                  <Table size="small" aria-label="purchases">
                    <TableHead>
                      <TableRow>
                        <TableCell></TableCell>
                        <TableCell style={{ minWidth: 140 }}>
                          Mã nguồn
                        </TableCell>
                        <TableCell style={{ minWidth: 140 }}>
                          Tên nguồn
                        </TableCell>
                        <TableCell align="left">Ngày tạo</TableCell>
                        <TableCell align="right" style={{ maxWidth: 50 }}>
                          <Button
                            variant="outlined"
                            className="search-row-button"
                            onClick={() => {
                              handleAddStatusId(row.id);
                            }}
                          >
                            Thêm mới
                          </Button>
                        </TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {dataLeadStatusIdRuducer?.dataId?.map((item) => (
                        <TableRow>
                          <TableCell></TableCell>
                          <TableCell component="th" scope="row">
                            {item?.id}
                          </TableCell>
                          <TableCell>{item.title}</TableCell>
                          <TableCell align="left">
                            {moment(item.created_on).format(
                              "DD-MM-YYYY, h:mm A"
                            )}
                          </TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Box>
              </Collapse>
            </TableCell>
          </StyledTableRow>
        )}
      </React.Fragment>
    );
  };

  useEffect(() => {
    if (loadingId) {
      dispatch(getLeadStatusId(statusId));
    }
  }, [loadingId]);

  return (
      <TableContainer
        style={{ width: "100%", overflowX: "auto", borderRadius: "0.5rem" }}
      >
        <Table sx={{ minWidth: 700 }} size={"small"}>
          <TableHead>
            <StyledTableRow>
              <StyledTableCell align="left"></StyledTableCell>
              <StyledTableCell align="left" style={{ minWidth: 100 }}>
                Mã nguồn
              </StyledTableCell>
              <StyledTableCell align="left" style={{ minWidth: 100 }}>
                Tên nguồn
              </StyledTableCell>
              <StyledTableCell> Ngày tạo </StyledTableCell>
              <StyledTableCell></StyledTableCell>
            </StyledTableRow>
          </TableHead>

          <TableBody>
            {leadStatus?.map((row) => (
              <Row row={row} />
            ))}
          </TableBody>
        </Table>

        <Dialog
          fullWidth={true}
          maxWidth={"sm"}
          open={userDetailOpen}
          onClose={handleCloseUserDetailDialog}
          style={{ minHeight: "100%" }}
          classes={{
              paper: classes.customModal
          }}
        >
            <DialogTitle className={`${classes.customModalTitle}`}>
            Thêm mới nguồn con
            <IconButton
              onClick={() => setUDO(false)}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: '#fff',
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>

          <DialogContent className={`${classes.customModalContent}`}>
            <DialogOrderStatus setUDO={setUDO} statusId={statusId} />
          </DialogContent>
        </Dialog>
      </TableContainer>
  );
};

export default TableOrder;
