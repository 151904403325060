import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch } from "react-redux";
import { CustomButton } from "../../components/Common/CustomButton";
import { showMessageError, showMessageSuccess } from "../../redux/notification/action";
import axiosInstance from "../../components/axiosApi";
import FacebookTable from './FacebookTable'
const FacebookList = () => {
  const dispatch = useDispatch();
  const [dataFanpage, setDataFanpage] = useState([]);
  const handleConnectFb = async () => {
        
    try {
      const res = await axiosInstance.get("/facebook/facebook-create/");
      if (res.status === 200) {
        dispatch(showMessageSuccess("Connect Success"));
        window.open(res.data,'_blank');
        getFacebookFanPage()
      }
    } catch (error) {
      dispatch(showMessageError("Lỗi server"));
    }
  };

  const getFacebookFanPage = useCallback(async () => {
    try {
      const res = await axiosInstance.get("/facebook/");
      if (res.status === 200) {
        
        setDataFanpage(res.data);
        
      }
    } catch (error) {
      dispatch(showMessageError("Lỗi server"));
    }
  }, [])

  const handleSub = async(id)=>{
    try {
      const res = await axiosInstance.post(`/facebook/fbsubscriber/${id}/`);
      if (res.status === 200) {
        
        dispatch(showMessageSuccess("Subscriber thành công"));
        
      }
    } catch (error) {
      dispatch(showMessageError("Lỗi server"));
    }
  }

useEffect(() => {
  getFacebookFanPage()
}, [])


  

  return (
    <>
      <CustomButton onClick={() =>
        handleConnectFb()
      }
      style={{marginBottom:'10px'}}
      >
        Đồng Bộ Facebook
    </CustomButton>
    <FacebookTable 
      data={dataFanpage}
      handleSub={handleSub}
      />
    </>
  )
}

export default FacebookList