import * as types from './type'

const initialState = {
    data: [],
    loading: false,
}

export const dataCdrReducer = (state = initialState,action) => {
    switch (action.type){
        case types.GET_CDR:
            return {
                ...state,
                ...{ loading: true }
            }

        case types.GET_CDR_SUCCESS:
            return {
                ...state,
                ...{
                    data: action.payload,
                    loading: false
                }
            }
        case types.GET_CDR_FAIL:
            return {
                ...state,
                ...{ data: [], loading: false }
            }
        default:
            return state;
    }
}