import { put, takeLatest } from "redux-saga/effects";
import * as types from "./type";
import * as actions from "./action";
import axiosInstance from "../../components/axiosApi";

function* getModule() {
  try {
    const res = yield axiosInstance.get(`/modules/`);
    yield put(actions.getModuleSuccess(res.data));
  } catch (error) {
    yield put(actions.getModuleFail(error));
  }
}

export function* watchingModule() {
  yield takeLatest(types.GET_MODULE, getModule);
}
