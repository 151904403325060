import {

 
  Grid,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,

 
} from "@mui/material";
import axiosInstance from "./components/axiosApi";
import React, { useEffect, useState } from "react";

import PersonAddAltOutlinedIcon from "@mui/icons-material/PersonAddAltOutlined";
import LocalOfferOutlinedIcon from "@mui/icons-material/LocalOfferOutlined";
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";
import AutorenewOutlinedIcon from "@mui/icons-material/AutorenewOutlined";

import { ChartBar } from "./pages/Dashboard/components/Chart-Bar";
import { ChartDoughnut } from "./pages/Dashboard/components/Chart-Doughnut";

import MonetizationOnRoundedIcon from "@mui/icons-material/MonetizationOnRounded";

import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";
import { useDispatch, useSelector } from "react-redux";
import { getReports } from "./redux/report/action";
import { useIsMobile } from "./hook/useIsMobile";

import { renderWithCommand } from "./helpers";
import { withStyles, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  scrollMobile: {
    "@media (max-width: 480px)": {
    'whiteSpace': 'nowrap',
    'flexWrap': 'nowrap', 
    'overflowX': 'scroll',
    'overflowY': 'hidden',
    },
  },
  chartMobileWidth:{
    
      "@media (max-width: 480px)": {
      'width': '400px',
    }
  }
}));

const STATUS_ORDER = {
  unApproved: "Chưa xác nhận",
  approved: "Xác nhận",
  success: "Thành công",
  cancel: "Đã hủy",
};

const TitleTable = styled("div")(({ theme }) => ({
  padding: "10px",
  fontWeight: "600",
  fontSize: "1.25rem",
}));



function TableOrder(reportsData) {
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "rgba(38,60,85,.1)",
      color: "#516377",
      fontWeight: 700,
      padding: 10,
      fontSize: ".75rem",
      textTransform: "uppercase",
      letterSpacing: "0.8px",
    },
    [`&.${tableCellClasses.body}`]: {
      padding: 10,
      lineHeight: "18px",
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));


  return (
    <TableContainer style={{ width: "100%", overflowX: "auto" }}>
      <Table sx={{ minWidth: 700 }} size={"small"}>
        <TableHead>
          <StyledTableRow>
            <StyledTableCell align="left">STT</StyledTableCell>
            <StyledTableCell align="left" style={{ minWidth: 120 }}>
              Người phụ trách
            </StyledTableCell>
            <StyledTableCell align="left" style={{ minWidth: 120 }}>
              Trạng thái
            </StyledTableCell>
            <StyledTableCell align="left" style={{ minWidth: 120 }}>
              Tổng tiền
            </StyledTableCell>
            <StyledTableCell align="left" style={{ minWidth: 120 }}>
              Khuyến mại
            </StyledTableCell>
            <StyledTableCell align="left" style={{ minWidth: 125 }}>
              Số lượng
            </StyledTableCell>
          </StyledTableRow>
        </TableHead>

        <TableBody>
          {!reportsData?.reportsData.loading ? (
            reportsData?.reportsData?.dataList[
              reportsData?.reportsData?.typeTable
            ]?.map((row, index) => {
              return (
                <StyledTableRow hover={true} key={row.name}>
                  <StyledTableCell align="left">{index + 1}</StyledTableCell>
                  <StyledTableCell align="left">
                    {`${row.assigned_to__first_name} ${row.assigned_to__last_name}`}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {row.lead_status__title}
                  </StyledTableCell>
                  <StyledTableCell style={{ width: 160 }} align="left">
                    {row.total}
                  </StyledTableCell>
                  <StyledTableCell align="left">{row.discount}</StyledTableCell>
                  <StyledTableCell align="left">
                    {row.count} VNĐ
                  </StyledTableCell>
                </StyledTableRow>
              );
            })
          ) : (
            <h1>Không có dữ liệu</h1>
          )}
        </TableBody>
      </Table>

      {/* <TablePagination
        rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
        colSpan={3}
        labelRowsPerPage={"Số hàng trên 1 trang:"}
        count={reportsData.reportsData?.dataList?.credits_count}
        rowsPerPage={rowsPerPage}
        page={page}
        SelectProps={{
          inputProps: {
            "aria-label": "rows per page",
          },
          native: true,
        }}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        ActionsComponent={TablePaginationActions}
      /> */}
    </TableContainer>
  );
}

export default function MainPanel() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const reportsData = useSelector((state) => state.reportReducer);
  const isMobile = useIsMobile();

  const [dataReportPbx, setDataReportPbx] = useState([]);

  const orgPbx = useSelector((item) => item.dataLogoFooterRuducer.data?.org);

  useEffect(() => {
    const getReportPbx = async() => {
      let email = orgPbx?.pbx_username;
      let password = orgPbx?.pbx_password;
      try {
        const res = await axiosInstance.get(
          `/report/pbx-start/?email=${email}&password=${password}`
        );
        if (res.status === 200) {
          setDataReportPbx(res.data.data);
         
        }
      } catch (error) {
      }
    }
    getReportPbx()
  }, [orgPbx?.pbx_username,orgPbx?.pbx_password]);

  useEffect(() => {
    dispatch(getReports());
    // eslint-disable-next-line
  }, []);


  const getOrderData = () => {
    const data = {
      unApproved: 0,
      approved: 0,
      success: 0,
      cancel: 0,
    };
    reportsData.dataList?.total_status?.map((order) => {
      switch (order.lead_status__title) {
        case STATUS_ORDER.unApproved:
          data.unApproved = order.count;
          break;
        case STATUS_ORDER.approved:
          data.approved = order.count;
          break;
        case STATUS_ORDER.success:
          data.success = order.count;
          break;
        case STATUS_ORDER.cancel:
          data.cancel = order.count;
          break;
        default:
          break;
      }
      return data
    });
    return [data.unApproved, data.approved, data.success, data.cancel];
  };

  const dataOrder = {
    title: "Biểu đồ tình trạng đơn hàng",
    labels: [
      STATUS_ORDER.unApproved,
      STATUS_ORDER.approved,
      STATUS_ORDER.success,
      STATUS_ORDER.cancel,
    ],
    datasets: [
      {
        // label: "# of Votes",
        data: getOrderData(),
        backgroundColor: [
          "rgba(255, 91, 92, 0.85)",
          "rgba(90, 141, 238, 0.85)",
          "rgba(253, 172, 65, 0.85)",
          "rgba(46, 216, 182, 0.85)",
        ],
        borderColor: [
          "rgba(255, 91, 92, 0.85)",
          "rgba(90, 141, 238, 0.85)",
          "rgba(253, 172, 65, 0.85)",
          "rgba(46, 216, 182, 0.85)",
        ],
        borderWidth: 1,
      },
    ],
  };

  const getSourceData = () => {
    const labels = [];
    const data = [];
    reportsData.dataList?.total_source?.map((source, idx) => {
      data.push(source.count);
      labels.push(source.lead_source__title);

      return data
    });
    return { data, labels };
  };

  const dataSource = {
    title: "Biểu đồ nguồn khách hàng",
    labels: getSourceData().labels,
    datasets: [
      {
        // label: "# of Votes",
        data: getSourceData().data,
        backgroundColor: [
          "rgba(255, 91, 92, 0.85)",
          "rgba(90, 141, 238, 0.85)",
          "rgba(253, 172, 65, 0.85)",
          "rgba(46, 216, 182, 0.85)",
        ],
        borderColor: [
          "rgba(255, 91, 92, 0.85)",
          "rgba(90, 141, 238, 0.85)",
          "rgba(253, 172, 65, 0.85)",
          "rgba(46, 216, 182, 0.85)",
        ],
        borderWidth: 1,
      },
    ],
  };

  const getOrderOfUserData = () => {
    const labels = [];
    const data = [];
    reportsData.dataList?.total_order_of_user?.map((user, idx) => {
      data.push(user.count);
      labels.push(
        `${user.assigned_to__first_name} ${user.assigned_to__last_name}`
      );
      return {data,labels}
    });
    return { data, labels };
  };

  const dataOrderOfUser = {
    title: "Biểu đồ % đơn hàng nhân viên",
    labels: getOrderOfUserData().labels,
    datasets: [
      {
        // label: "# of Votes",
        data: getOrderOfUserData().data,
        backgroundColor: [
          "rgba(255, 91, 92, 0.85)",
          "rgba(90, 141, 238, 0.85)",
          "rgba(253, 172, 65, 0.85)",
          "rgba(46, 216, 182, 0.85)",
        ],
        borderColor: [
          "rgba(255, 91, 92, 0.85)",
          "rgba(90, 141, 238, 0.85)",
          "rgba(253, 172, 65, 0.85)",
          "rgba(46, 216, 182, 0.85)",
        ],
        borderWidth: 1,
      },
    ],
  };

  const getTopProductData = () => {
    const data = {
      labels: [],
      count: [],
    };
    reportsData.dataList?.top_10_product_selling?.map((prod) => {
      data.labels.push(prod.item__title);
      data.count.push(prod.count);
      return data
    });
    return data;
  };



  const dataTop10Prod = {
    title: "Top 10 sản phẩm bán chạy",
    labels: getTopProductData().labels,
    datasets: [
      {
        barPercentage: 0.5,
        barThickness: 20,
        base: 0,
        maxBarThickness: 30,
        // minBarLength: 2,
        data: getTopProductData().count,
        backgroundColor: "rgba(90, 141, 238, 0.85)",
        borderColor: "rgba(90, 141, 238, 0.85)",
        borderWidth: 0.5,
      },
    ],
  };

  const getTopCityData = () => {
    const data = {
      labels: [],
      count: [],
    };
    reportsData.dataList?.top_10_city?.map((city) => {
      data.labels.push(city.lead__city__name);
      data.count.push(city.count);
      return data
    });
    return data;
  };

  const dataTop10City = {
    title: "Top 10 thành phố doanh thu cao",
    labels: getTopCityData().labels,
    datasets: [
      {
        barPercentage: 0.5,
        barThickness: 20,
        base: 0,
        maxBarThickness: 30,
        // minBarLength: 2,
        data: getTopCityData().count,
        backgroundColor: "rgba(90, 141, 238, 0.85)",
        borderColor: "rgba(90, 141, 238, 0.85)",
        borderWidth: 4,
      },
    ],
  };

  return (
    <>
      <div className="screen">
        <div className="box-card">
          <div className="item bg-c-blue">
            <div className="box-content">
              <h3 className="title">Tổng khách hàng</h3>
              <div className="text-bottom">
                <GroupOutlinedIcon />
                <div className="number">
                  {reportsData.dataList?.total_customer}
                </div>
              </div>
            </div>
          </div>
          <div className="item bg-c-green">
            <div className="box-content">
              <h3 className="title">Khách hàng mới</h3>
              <div className="text-bottom">
                <PersonAddAltOutlinedIcon />
                <div className="number">
                  {reportsData.dataList?.total_new_customer}
                </div>
              </div>
            </div>
          </div>
          <div className="item bg-c-yellow">
            <div className="box-content">
              <h3 className="title">Tổng đơn hàng</h3>
              <div className="text-bottom">
                <LocalOfferOutlinedIcon />
                <div className="number">
                  {reportsData.dataList?.total_order}
                </div>
              </div>
            </div>
          </div>
          <div className="item bg-c-red">
            <div className="box-content">
              <h3 className="title">Tổng đơn hàng mới</h3>
              <div className="text-bottom">
                <AutorenewOutlinedIcon />
                <div className="number">
                  {reportsData.dataList?.total_order_new}
                </div>
              </div>
            </div>
          </div>
          <div className="item bg-c-ibiza">
            <div className="box-content">
              <h3 className="title">Tổng doanh thu</h3>
              <div className="text-bottom">
                <MonetizationOnRoundedIcon />
                <div className="number">
                  {renderWithCommand(
                    reportsData.dataList?.total_money?.total_money
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="item bg-c-cosmic">
            <div className="box-content">
              <h3 className="title">Tổng khuyến mại</h3>
              <div className="text-bottom">
                <MonetizationOnOutlinedIcon />
                <div className="number">
                  {renderWithCommand(reportsData.dataList?.total_money?.total_discount)}
                </div>
              </div>
            </div>
          </div>
        </div>

        {!isMobile ? (
          <>
            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 2 }}
            >

              
              <Grid
                item
                xs={6}
                sm={6}
                md={4}
                xl={4}
                style={{ marginBottom: 10 }}
              >
                <Paper
                  className="user-list"
                  style={{ height: "100%", padding: 15, minHeight: 300 }}
                >
                  <ChartDoughnut data={dataOrder} />
                </Paper>
              </Grid>
              <Grid
                item
                xs={4}
                sm={6}
                md={4}
                xl={4}
                style={{ marginBottom: 10 }}
              >
                <Paper
                  className="user-list"
                  style={{ height: "100%", padding: 15, minHeight: 300 }}
                >
                  <ChartDoughnut data={dataSource} />
                </Paper>
              </Grid>
              <Grid
                item
                xs={4}
                sm={12}
                md={4}
                xl={4}
                style={{ marginBottom: 10 }}
              >
                <Paper
                  className="user-list"
                  style={{ height: "100%", padding: 15, minHeight: 300 }}
                >
                  <ChartDoughnut  data={dataOrderOfUser} />
                </Paper>
              </Grid>
              <Grid item xs={12} md={6} xl={6}>
                <Paper
                  className="user-list"
                  style={{ height: "100%", padding: 10, minHeight: 250 }}
                >
                  <ChartBar data={dataTop10City} />
                </Paper>
              </Grid>
              <Grid item xs={12} md={6} xl={6}>
                <Paper
                  className="user-list"
                  style={{ height: "100%", padding: 10, minHeight: 250 }}
                >
                  <ChartBar data={dataTop10Prod} />
                </Paper>
              </Grid>
            </Grid>

            





          </>
        ) : (
          <>
            <Grid className={`${classes.scrollMobile}`} item xs={12} style={{ 
                maxWidth: "100%",
                
                }}>
              <Paper
                className={`${classes.chartMobileWidth} user-list`}
                style={{ height: "100%", padding: 10, minHeight: 250}}
              >
                <ChartDoughnut  data={dataOrder} />
              </Paper>
            </Grid>
            <Grid className={`${classes.scrollMobile}`} item xs={12} style={{ maxWidth: "100%", marginTop: "20px",
                }}>
              <Paper
                className={`${classes.chartMobileWidth} user-list`}
                style={{ height: "100%", padding: 10, minHeight: 250 }}
              >
                <ChartDoughnut data={dataSource} />
              </Paper>
            </Grid>
            <Grid className={`${classes.scrollMobile}`} item xs={12} style={{ maxWidth: "100%", marginTop: "20px",
                }}>
              <Paper
                className={`${classes.chartMobileWidth} user-list`}
                style={{ height: "100%", padding: 10, minHeight: 250}}
              >
                <ChartDoughnut height="250px" data={dataOrderOfUser} />
              </Paper>
            </Grid>
            <Grid className={`${classes.scrollMobile}`} item xs={12} style={{ maxWidth: "100%", marginTop: "20px",
                 }}>
              <Paper
                className={`${classes.chartMobileWidth} user-list`}
                style={{ height: "100%", padding: 10, minHeight: 250 }}
              >
                <ChartBar data={dataTop10Prod} />
              </Paper>
            </Grid>
            <Grid className={`${classes.scrollMobile}`} item xs={12} style={{ maxWidth: "100%", marginTop: "20px",
                }}>
              <Paper
                className={`${classes.chartMobileWidth} user-list`}
                style={{ height: "100%", padding: 10, minHeight: 250 }}
              >
                <ChartBar data={dataTop10City} />
              </Paper>
            </Grid>
           

            
          </>
        )}
        <div className="box-card-sumary">
                  
                  <div className="item">
                    <div className="box-icon">
                    <svg style={{width:'5em',heihgt:'5em',position:'absolute',top:'10px',left:'12px'}}
              xmlns="http:www.w3.org/2000/svg"
              aria-hidden="true"
              role="img"
             
              preserveAspectRatio="xMidYMid meet"
              viewBox="0 0 512 512"
            >
              <path
                fill="currentColor"
                d="M30.58,60.98c3.73,6.73,8.03,13.19,13.63,19.09C49.81,86,56.77,91.4,65.8,96c0.67,0.33,1.3,0.33,1.87,0.1 c0.87-0.33,1.73-1.03,2.6-1.9c0.67-0.67,1.5-1.73,2.37-2.9c3.47-4.56,7.76-10.23,13.83-7.4c0.13,0.07,0.23,0.13,0.37,0.2 l20.22,11.63c0.07,0.03,0.13,0.1,0.2,0.13c2.67,1.83,3.77,4.66,3.8,7.86c0,3.27-1.2,6.93-2.97,10.03c-2.33,4.1-5.76,6.8-9.73,8.6 c-3.77,1.73-7.96,2.67-12,3.27c-6.33,0.93-12.26,0.33-18.33-1.53c-5.93-1.83-11.9-4.86-18.43-8.9l-0.47-0.3 c-3-1.87-6.23-3.87-9.4-6.23C28.12,99.9,16.29,87.24,8.59,73.31c-6.46-11.7-10-24.32-8.06-36.35c1.07-6.6,3.9-12.59,8.83-16.56 c4.3-3.47,10.1-5.36,17.59-4.7c0.87,0.07,1.63,0.57,2.03,1.3l12.96,21.92c1.9,2.47,2.13,4.9,1.1,7.33c-0.87,2-2.6,3.83-4.96,5.56 c-0.7,0.6-1.53,1.2-2.4,1.83c-2.9,2.1-6.2,4.53-5.06,7.4L30.58,60.98L30.58,60.98z M74.96,0c-3.61,0-6.7,1.27-9.27,3.81 c-2.57,2.54-3.81,5.63-3.81,9.27v22.96c0,3.64,1.27,6.73,3.81,9.27c2.54,2.54,5.63,3.81,9.27,3.81h10.75 c-0.75,2.89-1.68,5.63-2.83,8.2c-1.13,2.6-3.06,5.08-5.72,7.45c5.11-1.33,9.65-3.32,13.66-5.95c3.99-2.6,7.45-5.86,10.31-9.71h8.67 c3.61,0,6.7-1.3,9.27-3.81c2.57-2.54,3.81-5.63,3.81-9.27V13.08c0-3.61-1.27-6.7-3.81-9.27C116.53,1.24,113.43,0,109.8,0 C98.18,0,86.57,0,74.96,0L74.96,0z M91.15,34.65H73.87c0.2-1.84,0.8-3.57,1.8-5.19c1-1.62,2.88-3.54,5.64-5.75 c1.69-1.35,2.77-2.38,3.25-3.08c0.47-0.7,0.71-1.37,0.71-2c0-0.68-0.23-1.27-0.7-1.75c-0.47-0.48-1.06-0.72-1.77-0.72 c-0.73,0-1.34,0.25-1.8,0.75c-0.47,0.51-0.78,1.39-0.94,2.66l-5.76-0.51c0.23-1.75,0.64-3.12,1.24-4.1 c0.6-0.98,1.45-1.73,2.55-2.26c1.1-0.53,2.62-0.79,4.56-0.79c2.03,0,3.6,0.25,4.73,0.75c1.12,0.49,2.01,1.26,2.65,2.29 c0.65,1.04,0.97,2.19,0.97,3.47c0,1.36-0.37,2.66-1.11,3.91c-0.74,1.24-2.08,2.6-4.04,4.09c-1.16,0.86-1.93,1.47-2.32,1.82 c-0.39,0.35-0.85,0.8-1.38,1.36h8.99V34.65L91.15,34.65z M103.5,30.46H93.03v-5.11L103.5,11.9h5.01v13.75h2.6v4.81h-2.6v4.19h-5.01 V30.46L103.5,30.46z M103.5,25.65v-7.06l-5.53,7.06H103.5L103.5,25.65z"
              />
            </svg>
                    </div>
                    <div className="box-content">
                      <div className="number">
                        {dataReportPbx.totalCalls}
                      </div>
                      <h3 className="title">Tổng cuộc gọi</h3>
                    </div>
                  </div>
                  <div className="item">
                    <div className="box-icon">
                    <i className="fas fa-phone-volume"></i>
                    </div>
                    <div className="box-content">
                      <div className="number">
                        {dataReportPbx.totalDuration}
                      </div>
                      <h3 className="title">Tổng cuộc nghe máy</h3>
                    </div>
                  </div>
                  <div className="item">
                    <div className="box-icon">
                    <i className="fas fa-clock"></i>
                    </div>
                    <div className="box-content">
                      <div className="number">
                        {dataReportPbx.totalAttended}
                      </div>
                      <h3 className="title">Tổng thời gian gọi</h3>
                    </div>
                  </div>
                  
                  <div className="item">
                    <div className="box-icon">
                      <i className="fas fa-clock"></i>
                    </div>
                    <div className="box-content">
                      <div className="number">
                       {dataReportPbx.totalAttendedDuration}
                      </div>
                      <h3 className="title">Tổng thời gian nghe máy</h3>
                    </div>
                  </div>
                  <div className="item">
                    <div className="box-icon">
                      <i className="fas fa-phone"></i>
                    </div>
                    <div className="box-content">
                      <div className="number">
                      
                       {dataReportPbx?.acd?.toFixed(2)}
                      </div>
                      <h3 className="title">ACD</h3>
                    </div>
                  </div>
                  <div className="item">
                    <div className="box-icon">
                      <i className="fas fa-phone"></i>
                    </div>
                    <div className="box-content">
                      <div className="number">
                       {dataReportPbx?.asr?.toFixed(2)}
                      </div>
                      <h3 className="title">ASR</h3>
                    </div>
                  </div>
                  
        </div>
      </div>
    </>
  );
}
