import React from 'react'
import blank from "../../assets/images/logo.png";
const ZaloChatSidebar = ({data, selected, setSelected, setOpen, setOpenSidebar, openSidebar, setLoading}) => {
    const handleSelected = (item) => {
        setSelected(item)
        setOpen(true)
        setOpenSidebar(!openSidebar)
        setLoading(true)
    }
  return (
    <>
        <h3 className="heading">Hội thoại gần đây</h3>
        {
            data && 
            data.length > 0 &&
            data.map((item,index) => (
                <div className={`item  ${selected === item?.z_uid ? 'bg-blue' : ''}`} 
                    key={index}
                    onClick={()=>{
                        handleSelected(item?.z_uid)
                        }

                    }
                >
                    <div className="photo">
                        <img
                            src={item?.z_uid.zimg ? `${item?.z_uid.zimg}` : blank}
                            alt=""
                            />
                    </div>
                    <div className="info">
                        <div className="username">{item?.z_uid.zaloName}</div>
                        <div className={`recent-msg ${item?.status === false ? '' : 'fbold'}`}>{item?.msg}</div>
                    </div>
                </div>
            ))
        }
    </>
  )
}

export default ZaloChatSidebar