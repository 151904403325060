import React, { useState, useEffect, useRef } from "react";

import Paper from "@mui/material/Paper";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";

import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import Tooltip from "@mui/material/Tooltip";
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import CloseIcon from "@mui/icons-material/Close";
import FiberNewIcon from '@mui/icons-material/FiberNew';

import ChatBubbleOutlineOutlinedIcon from "@mui/icons-material/ChatBubbleOutlineOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import CallSplitOutlinedIcon from "@mui/icons-material/CallSplitOutlined";
import AutorenewOutlinedIcon from "@mui/icons-material/AutorenewOutlined";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";
import useOutsideClick from "../../hook/useOutsideClick";

import $ from "jquery";
// eslint-disable-next-line
import daterangepicker from "daterangepicker";  // required for date range picker to work despite being grayed out
import moment from "moment";
import Dialog from "@mui/material/Dialog";

import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Autocomplete from "@mui/material/Autocomplete";
import IconButton from "@mui/material/IconButton";
import axiosInstance from "../../components/axiosApi";
import DiaLogAddCustomer from "./components/DialogCustomer";

import Loading from "../../components/Common/Loading";
import { useDispatch, useSelector } from "react-redux";

import TableCustomers from "./components/TableCustomers";
import { getCustomerStart, searchCustomer } from "../../redux/customers/action";
import { getListCity } from "../../redux/city/action";
import { getProductsData } from "../../redux/products/action";
import SourceData from "../../components/Common/SourceData";
import AssignTo from "../../components/Common/AssignTo";
import { Box, Grid, InputLabel } from "@mui/material";
import { BoxInput } from "./components/DialogCustomer/styled";
import { getSourceData } from "../../redux/sourceData/action";
import { DateRangePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";

import { CustomButton } from "../../components/Common/CustomButton";

import CheckIcon from "@mui/icons-material/Check";

import { getUserStart } from "../../redux/users/action";
import HourglassBottomIcon from "@mui/icons-material/HourglassBottom";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import CancelIcon from "@mui/icons-material/Cancel";
import ConnectingAirportsIcon from "@mui/icons-material/ConnectingAirports";

import DeleteIcon from "@mui/icons-material/Delete";

import DiaLogChangeStatus from "./components/DiaglogChangeStatus";
import DiaLogSplitData from "./components/SplitData";
import SmsSendForm from "../Sms/SmsSendForm";
import { getTrangThaiData } from "../../redux/trangThaiData/action";
import { getCallstatus } from "../../redux/callstatus/action";
import Excel from "exceljs";
import { saveAs } from "file-saver";
import { Upload } from "antd";
import { ExcelRenderer } from "react-excel-renderer";
import TableImport from "./components/DialogImport";
import { showMessageSuccess, showMessageError } from "../../redux/notification/action";

import { getStatusLead } from "../../redux/statusLead/action";
import { getStatusLeadId } from "../../redux/statusLeadId/action";
import { makeStyles } from "@material-ui/core/styles";
import TicketHistoryCareList from "../Ticket/TicketHistoryCareList";




const useStyles = makeStyles((theme) => ({
  menuSelect: {
    maxHeight: 300,
  },
  btnIcon: {
    fontSize: '16px !important',
  },
  iconMenuItem: {
    marginRight: 10,
  },
  btnGlobal: {
    minWidth: "inherit",
    width: 36,
    height: 36,
    lineHeight: 36,
    padding: 0,
    marginLeft: 5,
  },
  customModalContent:{
    paddingLeft:'15px',
    paddingRight:'15px'
  },
  customModal:{
    width:'calc(100% - 20px)',
    marginLeft:'10px',
    marginRight:'10px'
  },
  customModalTitle:{
    padding:'10px 15px',
    color:'#fff',
    background:process.env.NODE_ENV === 'development' ? "#5a8dee" : process.env.REACT_APP_PRODUCT_BASE_COLOR
  }
}));


export default function CustomerManage({directSelected, dataLogoFooter}) {
  
  const classes = useStyles();
  const [detailCustomer, setDetailCustomer] = useState(null);
  const [isAddingNewData, setIsAddingNewData] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const [phone, setPhone] = useState(null); // eslint-disable-line
  const [openDialogChangeStatus, setOpenDialogChangeStatus] = useState(false);
  const [openDialogSplitData, setOpenDialogSplitData] = useState(false);
  const [selected, setSelected] = useState([]);
  const checkEnablePhone = dataLogoFooter?.org?.hide_number

  useEffect(() => {
    setTimeout(() => setLoading(false), 500);
    $('input[name="dates"]').daterangepicker({
      ranges: {
        "Hôm nay": [moment(), moment()],
        "Hôm qua": [moment().subtract(1, "days"), moment().subtract(1, "days")],
        "7 ngày trước": [moment().subtract(6, "days"), moment()],
        "30 ngày trước": [moment().subtract(29, "days"), moment()],
        "Tháng này": [moment().startOf("month"), moment().endOf("month")],
        "Tháng trước": [
          moment().subtract(1, "month").startOf("month"),
          moment().subtract(1, "month").endOf("month"),
        ],
      },
    });
    $("li[data-range-key='Custom Range']").html("Tùy chỉnh");
    $("button[class='cancelBtn btn btn-sm btn-default']").html("Hủy bỏ");
    $("button[class='applyBtn btn btn-sm btn-primary']").html("Áp dụng");
  }, []);

  const [userDetailOpen, setUDO] = React.useState(false);
  const [userDetailCareOpen, setCareUDO] = React.useState(false);
  const [dialogImp, setDialogImp] = React.useState(false);
  const dataCustomers = useSelector((state) => state.getCustomersReducer);
  const [value, setValue] = React.useState([null, null]);
  const { data } = useSelector((item) => item.listCityRuducer);
  const stateDataTrangThai = useSelector((state) => state.trangThaiDataReducer);
  const stateDataSource = useSelector((state) => state.sourceDataReducer)
  const stateDataCallStatus = useSelector((state) => state.callstatusReducer.dataList)
  const [dataPagination, setDataPagination] = useState({ offset: 0, limit: 10 });
  const initialValueSearch = {
    phone: "",
    city: "",
    district: "",
    ward: "",
    assignTo: "",
    lead_status: "",
    lead_status_sub: "",
    dataOrigin: "",
    lead_source_sub: "",
    from: "",
    to: "",
  };

  
  const [search, setSearch] = useState(initialValueSearch);
  

  const workSheetName = "Danh sách đơn hàng";
  const workBookName = "DanhSachDonHang";
  const myInputId = "myInput";
  const workbook = new Excel.Workbook();
  const columns = [
    { header: "STT", key: "stt" },
    { header: "NGÀY TẠO", key: "ngayTao" },
    { header: "SĐT/MÃ KH", key: "sdt" },
    { header: "TÊN KHÁCH HÀNG", key: "fullName" },
    { header: "THÔNG TIN KHÁCH HÀNG	", key: "thongTin" },
    { header: "LỊCH SỬ CHĂM SÓC", key: "historyCare" },
    { header: "Trạng Thái", key: "status" },
    { header: "GIÁ TIỀN", key: "giaTien" },
  ];
  const [dataImp, setDataImp] = useState({
    cols: [],
    rows: [],
    errorMessage: null,
  });
  const [dataImp1, setDataImp1] = useState({
    cols: [],
    rows: [],
    errorMessage: null,
  });

  const dataStatusLead = useSelector((state) => state.statusLeadRuducer.data);


  const newDataStatusLead = [
    ...dataStatusLead,
    {id:'', title:'Chọn tất cả'}
  ]

  const handleCloseUserDetailDialog = () => {
    setUDO(!userDetailOpen);
    setDetailCustomer(null);
  };
  const handleCloseDialogImp = () => {
    setDialogImp(!dialogImp);
    setDataImp({ rows: [] });
    setDataImp1({ rows: [] });
  };

  const handleCloseUserDetailCareDialog = () => {
    setCareUDO(!userDetailCareOpen);
    setDetailCustomer(null);
  };


  const handleAutocomplete = (event, newValue) => {
    if (newValue != null) {
        
        let cityId = newValue.id
        let updateSearch = { ...search };
        updateSearch.city = cityId;
        setSearch(updateSearch);
    }
  }

  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    setSearch({ ...search, [name]: value });
    if (name === "lead_status") {
      dispatch(getStatusLeadId(value));
    }
  };

  const handleChangeAssign = (event, newValue) => {
    if (newValue != null) {
      
      let assignToId = newValue.id
      let updateSearch = { ...search };
      updateSearch.assignTo = assignToId;
      setSearch(updateSearch);
  }
  }

  const handleSearch = () => {
    const valueSearch = {
      ...search,
      offset: dataPagination.offset,
      limit: dataPagination.limit,
    };
    dispatch(searchCustomer(valueSearch));
    if (openSidebar) setOpenSidebar(false);
  };

  const handleClear = () => {
    const valueSearch = {
      offset: dataPagination.offset,
      limit: dataPagination.limit,
    };
    setSearch({ ...initialValueSearch });
    setValue([null,null])
    dispatch(searchCustomer(valueSearch));
    if (openSidebar) setOpenSidebar(false);
  }

  const handleRowClick = (stt) => {
    setUDO(true);
  };
  const handleImp = () => {
    setDialogImp(true);
  };

  const [newFile, setNewFile] = useState();


  const saveExcel = async () => {
    // const newDataCustomerToExport =
    try {
      const myInput = document.getElementById(myInputId);
      const fileName = myInput.value || workBookName;

      // creating one worksheet in workbook
      const worksheet = workbook.addWorksheet(workSheetName);

      // add worksheet columns
      // each columns contains header and its mapping key from data
      worksheet.columns = columns;

      // updated the font for first row.
      worksheet.getRow(1).font = { bold: true };

      // loop through all of the columns and set the alignment with width.
      worksheet.columns.forEach((column) => {
        column.width = column.header.length + 5;
        column.alignment = { horizontal: "center" };
      });

      // loop through data and add each one to worksheet
      dataCustomers.dataCustomers.open_leads.open_leads.forEach(
        (singleData, index) => {
          let a = singleData.history_care.map((item, index) => {
            return item.content + "\n";
          });
          //console.log(a.toString());
          const newData = {
            stt: index,
            ngayTao: moment(singleData.created_on).format("MM/DD/YYYY"),
            sdt: singleData.lead.phone,
            fullName: singleData.lead.fullname,
            thongTin: singleData.lead.description,
            historyCare: a.toString(),
            status:singleData.lead_status.title,
            giaTien: singleData.total_order - singleData.giamgia + "VND",
          };
          worksheet.addRow(newData);
        }
      );

      // loop through all of the rows and set the outline style.
      worksheet.eachRow({ includeEmpty: false }, (row) => {
        // store each cell to currentCell
        const currentCell = row._cells;

        // loop through currentCell to apply border only for the non-empty cell of excel
        currentCell.forEach((singleCell) => {
          // store the cell address i.e. A1, A2, A3, B1, B2, B3, ...
          const cellAddress = singleCell._address;

          // apply border
          worksheet.getCell(cellAddress).border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" },
          };
        });
      });

      // write the content using writeBuffer
      const buf = await workbook.xlsx.writeBuffer();

      // download the processed file
      saveAs(new Blob([buf]), `${fileName}.xlsx`);
    } catch (error) {
      //console.error("<<<ERRROR>>>", error);
      //console.error("Something Went Wrong", error.message);
    } finally {
      // removing worksheet's instance to create new one
      workbook.removeWorksheet(workSheetName);
    }
  };

  const fileHandler = (fileList) => {
    setNewFile(fileList);
    let fileObj = fileList;
    if (!fileObj) {
      setDataImp({
        errorMessage: "No file uploaded!",
      });
      return false;
    }
    if (
      !(
        fileObj.type === "application/vnd.ms-excel" ||
        fileObj.type ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      )
    ) {
      setDataImp({
        errorMessage: "Unknown file format. Only Excel files are uploaded!",
      });
      return false;
    }
    //just pass the fileObj as parameter
    ExcelRenderer(fileObj, (err, resp) => {
      if (err) {
       // console.log(err);
      } else {
        let newRows = [];
        resp.rows.slice(1).map((row, index) => {
         // console.log(row);
          if (row && row !== "undefined") {
            newRows.push({
              key: index,
              name: row[0],
              phone: row[1],
              email: row[2],
              address: row[3],
              source_lead: row[4],
              source_lead_sub: row[5],
              info: row[6],
              masp: row[7],
              status_lead: row[8],
            });
          }
          return row
        });
        if (newRows.length === 0) {
          setDataImp({
            errorMessage: "No data found in file!",
          });
          return false;
        } else {
          setDataImp({
            cols: resp.cols,
            rows: newRows,
            errorMessage: null,
          });
        }
      }
    });
    return false;
  };



  const handleSubmit = async () => {
    let formData = new FormData();
    formData.append("leads_file", newFile);
    try {
      const response = await axiosInstance.post(
        `/leads/upload-xls-custommer/`,
        formData
      );
      // setTimeout(() => setLoading(false), 500);
      if (response.status === 200) {
        dispatch(showMessageSuccess("Nhập data khách hàng thành công."));
        dispatch(getCustomerStart(dataPagination));
        setDialogImp(false);
      }
    } catch (error) {
      //console.log(error);
    }
  };


  // more button

  const dropdownRef = useRef(null);
  const sidebarRef = useRef(null);
  const [openDrp, setOpenDrp] = useState(false);
  const [openSidebar, setOpenSidebar] = useState(false);
  useOutsideClick(dropdownRef, () => {
    if (openDrp) setOpenDrp(false);
  });
  useOutsideClick(sidebarRef, () => {
    if (openSidebar) setOpenSidebar(false);
    //popup.classList.remove('active');
  });

  
  // let popup = document.querySelector(".boxsearch-sidebar");
  // function hidePopup() {
  //   popup.classList.remove('active');
  // }

  // function showPopup() {
  //   popup.classList.add('active');
  // }

  // sms send form
  const [openSmsSend, setOpenSmsSend] = useState(false);

  const handleSend = (item) => {
    
    setOpenSmsSend(true);
  };


  const handleCloseSmsSend = () => {
    setOpenSmsSend(false);
  };



  useEffect(() => {
    // let data = {
    //   offset: 0,
    //   limit: 10,
    // };
    // dispatch(getCustomerStart(data));
    dispatch(getSourceData());
    dispatch(getListCity());
    dispatch(getTrangThaiData());
    dispatch(getUserStart());
    dispatch(getStatusLead());
    dispatch(getCallstatus());
    // eslint-disable-next-line
  }, []);


  // download file sample

  const handleDownloadSample = async () => {
        
    try {
      const res = await axiosInstance.get("/temp-xls/?code=KH");
      if (res.status === 200) {
        let url = res.data.data[0].url;
       
        console.log(url)
        saveAs(url, `Customer-Sample.xlsx`);            
      }
    } catch (error) {
      dispatch(showMessageError("Lỗi server"));
    }
  };

  


  



  return (
    <>
      <div className="screen modify-label">
        <Loading loading={isLoading} />
        <Paper
          className="user-list"
          variant="outlined"
          style={{ marginBottom: 10 }}
        >
          <input
            style={{ display: "none" }}
            id={myInputId}
            defaultValue={workBookName}
          />
          <div className="ul-heading-placeholder">
            <span
              className="ul-heading"
              style={{ display: "flex", alignItems: "center", fontWeight: 500 }}
            >
              Chăm sóc khách hàng
            </span>
            <div className="box-right">
              <Tooltip title="Thêm Đơn Hàng" enterTouchDelay={0}>
                <CustomButton
                  variant="contained"
                  className={`${classes.btnGlobal} ${classes.btnBlue}`}
                  onClick={() => {
                    handleRowClick();
                    setIsAddingNewData(!isAddingNewData);
                    dispatch(getProductsData([], ""));
                  }}

                >
                  <AddShoppingCartIcon className={`${classes.btnIcon}`} />
                </CustomButton>
              </Tooltip>
              <Tooltip title="Tải lên">
              
                <CustomButton
                  variant="contained"
                  className={`${classes.btnGlobal} ${classes.btnBlue}`}
                  onClick={handleImp}
                >
                  <FileUploadOutlinedIcon className={`${classes.btnIcon}`} />
                </CustomButton>
                
              </Tooltip>
              <Tooltip title="Tải xuống">
                <CustomButton
                  variant="contained"
                  className={`${classes.btnGlobal} ${classes.btnBlue}`}
                  onClick={saveExcel}
                >
                  <FileDownloadOutlinedIcon className={`${classes.btnIcon}`} />
                </CustomButton>
              </Tooltip>
              <div className="box-drp" ref={dropdownRef}>
                <CustomButton
                  variant="contained"
                  className={`${classes.btnGlobal} ${classes.btnBlue}`}
                  onClick={() => setOpenDrp(!openDrp)}
                >
                  <MoreHorizIcon className={`${classes.btnIcon}`} />
                </CustomButton>
                <nav className={`menuExpand ${openDrp ? "active" : ""}`}>
                  <ul>
                    <li className="menuItem"
                    onClick={() => {
                                handleSend()
                            }}
                    >
                      <span
                        
                      >
                        <ChatBubbleOutlineOutlinedIcon
                          className={`${classes.btnIcon} ${classes.iconMenuItem}`}
                        />
                        SMS
                      </span>
                    </li>
                    <li className="menuItem">
                      <span>
                        <EmailOutlinedIcon
                          className={`${classes.btnIcon} ${classes.iconMenuItem}`}
                        />
                        Gửi Email
                      </span>
                    </li>
                    <li className="menuItem" onClick={() => setOpenDialogSplitData(true)}>
                    <span>
                        <CallSplitOutlinedIcon
                          className={`${classes.btnIcon} ${classes.iconMenuItem}`}
                        />
                        Chia Data
                      </span>
                    </li>
                    <li className="menuItem" onClick={() => setOpenDialogChangeStatus(true)}>
                      <span>
                        <AutorenewOutlinedIcon
                          className={`${classes.btnIcon} ${classes.iconMenuItem}`}
                        />
                        Chuyển Trạng Thái
                      </span>
                    </li>
                  </ul>
                </nav>
              </div>

              <CustomButton
                style={{position:'relative',zIndex:'20'}}
                variant="contained"
                className={`${classes.btnGlobal} ${classes.btnBlue}`}
                onClick={() => {
                  setOpenSidebar(!openSidebar)
                  
                }}
              >
                <FilterAltIcon style={{position:'relative',zIndex:'20'}} className={`${classes.btnIcon}`} />
              </CustomButton>
              <div
                className={`boxsearch-sidebar ${openSidebar ? "active" : ""}`}
              >
                
                <div className="boxsearch-content">
                <span
                  className="ul-heading"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    fontWeight: 500,
                  }}
                >
                  <ArrowBackIosNewOutlinedIcon
                    style={{
                      color: "#5a8dee",
                      marginRight: 5,
                      cursor: "pointer",
                    }}
                    onClick={() => setOpenSidebar(!openSidebar)}
                  />
                  Tìm kiếm
                </span>
                <Divider sx={{ display: { xs: 'block', sm: 'none' } }}/>
                <Grid
                  container
                  rowSpacing={1}
                  columnSpacing={{ xs: 1, sm: 1.5 }}
                  style={{ padding: "0 14px" }}
                >
                  <Grid item xs={12}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DateRangePicker
                        fullWidth
                        inputFormat="dd/MM/yyyy"
                        startText="Từ ngày"
                        endText="Đến ngày"
                        value={value ?? ''}
                        onChange={(newValue) => {
                          setValue(newValue);
                          let from = moment(newValue[0]).format("DD/MM/YYYY");
                          let to = moment(newValue[1]).format("DD/MM/YYYY");

                          let updateSearch = { ...search };
                          updateSearch.from = from;
                          updateSearch.to = to;
                          setSearch(updateSearch);
                        }}
                        renderInput={(startProps, endProps) => (
                          <>
                            <TextField
                              fullWidth
                              {...startProps}
                              size="small"
                              style={{
                                height: "40px!important",
                                marginTop: 12,
                              }}
                            />
                            <Box sx={{ mx: 1 }}> - </Box>
                            <TextField
                              fullWidth
                              {...endProps}
                              size="small"
                              style={{
                                height: "40px!important",
                                marginTop: 12,
                              }}
                            />
                          </>
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={12}>
                    <BoxInput style={{ maxWidth: "100%",marginTop:'10px' }}>
                      <TextField
                        style={{ maxWidth: "100%" }}
                        id={search.phone}
                        name={"phone"}
                        size="small"
                        fullWidth
                        placeholder={"Nhập mã khách hàng/SĐT"}
                        onChange={handleChangeInput}
                      />
                    </BoxInput>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <BoxInput>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Trạng thái
                        </InputLabel>
                        <Select
                          label="Trạng thái"
                          name="lead_status"
                          value={search.lead_status}
                          onChange={handleChangeInput}
                          size="small"
                        >
                          {dataStatusLead?.length > 0
                            ? newDataStatusLead.map((item, index) => (
                                <MenuItem key={index} value={item.id}>
                                  {item.title}
                                </MenuItem>
                              ))
                            : null}
                        </Select>
                      </FormControl>
                    </BoxInput>
                    <BoxInput>
                      <FormControl fullWidth>
                        <Autocomplete
                            options={ data }
                            sx={{ 
                              
                              '& .MuiAutocomplete-inputFocused':{
                                marginTop:'-18px'
                              },
                              width: '100%',marginTop:'10px' }}
                            getOptionLabel={(option) => option.name}
                            renderInput={params => {
                                return (
                                    <TextField 
                                        {...params} 
                                        label='Chọn thành phố'
                                        variant="outlined" 
                                        fullWidth
                                    />
                                )
                            }}
                            onChange={handleAutocomplete}
                          >
                                      
                        </Autocomplete>

                      </FormControl>
                    </BoxInput>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <BoxInput>
                      <SourceData
                        parentValue={search.dataOrigin}
                        onChangeParent={handleChangeInput}
                        subSource={search.lead_source_sub}
                        childValue={""}
                      />
                    </BoxInput>
                    <BoxInput style={{marginTop:'22px'}}>
                      <AssignTo
                        value={search.assignTo}
                        onChange={handleChangeAssign}
                      />
                    </BoxInput>
                  </Grid>
                  <Grid item xs={12}>
                      
                    <div style={{display:'flex'}}>
                    <CustomButton style={{marginTop:'-10px',width:'40px',height:'40px'}}
                        variant="contained"
                        className="search-row-button"
                        onClick={handleSearch}
                      >
                      Tìm kiếm
                    </CustomButton>

                     <CustomButton style={{marginTop:'-10px',width:'40px',height:'40px',marginLeft:'10px'}}
                        variant="contained"
                        className="search-row-button"
                        onClick={handleClear}
                      >
                      Xoá Bộ Lọc
                    </CustomButton>
                    </div>
                    
                  </Grid>
                </Grid>
                </div>
              </div>
            </div>
          </div>
        </Paper>

        <Paper className="user-list">
          <TableCustomers
            dataCallStatus = {stateDataCallStatus}
            dataSources = {stateDataSource}
            dataStatus = {stateDataTrangThai}
            dataCustomers={dataCustomers}
            showDetail={setUDO}
            setDetailCustomer={setDetailCustomer}
            dataPagination={dataPagination}
            setDataPagination={setDataPagination}
            setPhone={setPhone}
            phone={phone}
            selected={selected}
            setSelected={setSelected}
            directSelected={directSelected}
            checkEnablePhone={checkEnablePhone}
            handleRowClick={handleRowClick}
            showDetailCare={setCareUDO}
            onHandleSend = {handleSend}
            setOpenDialogSplitData = {setOpenDialogSplitData}
          />
        </Paper>
        <div className="customer-overview">
          <div className="item">
            <div className="box-content">
              <h3 className="title">{Object.getOwnPropertyNames(
              dataCustomers?.dataCustomers?.open_leads?.count_status?.[0] ?? 0
            )}</h3>
              <div className="number">{Object.values(
              dataCustomers?.dataCustomers?.open_leads?.count_status?.[0] ?? 0
            )}</div>
            </div>
              <div className="box-icon">
                <CheckIcon style={{ color: "#fff" }} />
              </div>
          </div>
          <div className="item">
            <div className="box-content">
              <h3 className="title">{Object.getOwnPropertyNames(
              dataCustomers?.dataCustomers?.open_leads?.count_status?.[1] ?? 0
            )}</h3>
              <div className="number">{Object.values(
              dataCustomers?.dataCustomers?.open_leads?.count_status?.[1] ?? 0
            )}</div>
            </div>
              <div className="box-icon">
                <HourglassBottomIcon style={{ color: "#fff" }} />
              </div>
          </div>
          <div className="item">
            <div className="box-content">
              <h3 className="title">{Object.getOwnPropertyNames(
              dataCustomers?.dataCustomers?.open_leads?.count_status?.[2] ?? 0
            )}</h3>
              <div className="number">{Object.values(
              dataCustomers?.dataCustomers?.open_leads?.count_status?.[2] ?? 0
            )}</div>
            </div>
              <div className="box-icon">
                <LocalShippingIcon style={{ color: "#fff" }} />
              </div>
          </div>
          <div className="item">
            <div className="box-content">
              <h3 className="title">{Object.getOwnPropertyNames(
              dataCustomers?.dataCustomers?.open_leads?.count_status?.[3] ?? 0
            )}</h3>
              <div className="number">{Object.values(
              dataCustomers?.dataCustomers?.open_leads?.count_status?.[3] ?? 0
            )}</div>
            </div>
              <div className="box-icon">
                <ThumbUpIcon style={{ color: "#fff" }} />
              </div>
          </div>
          <div className="item">
            <div className="box-content">
              <h3 className="title">{Object.getOwnPropertyNames(
              dataCustomers?.dataCustomers?.open_leads?.count_status?.[4] ?? 0
            )}</h3>
              <div className="number">{Object.values(
              dataCustomers?.dataCustomers?.open_leads?.count_status?.[4] ?? 0
            )}</div>
            </div>
              <div className="box-icon">
                <CancelIcon style={{ color: "#fff" }} />
              </div>
          </div>
          <div className="item">
            <div className="box-content">
              <h3 className="title">{Object.getOwnPropertyNames(
              dataCustomers?.dataCustomers?.open_leads?.count_status?.[5] ?? 0
            )}</h3>
              <div className="number">{Object.values(
              dataCustomers?.dataCustomers?.open_leads?.count_status?.[5] ?? 0
            )}</div>
            </div>
              <div className="box-icon">
                <ConnectingAirportsIcon style={{ color: "#fff" }} />
              </div>
          </div>
          <div className="item">
            <div className="box-content">
              <h3 className="title">{Object.getOwnPropertyNames(
              dataCustomers?.dataCustomers?.open_leads?.count_status?.[6] ?? 0
            )}</h3>
              <div className="number">{Object.values(
              dataCustomers?.dataCustomers?.open_leads?.count_status?.[6] ?? 0
            )}</div>
            </div>
              <div className="box-icon">
                <DeleteIcon style={{ color: "#fff" }} />
              </div>
          </div>
          <div className="item">
            <div className="box-content">
              <h3 className="title">{Object.getOwnPropertyNames(
              dataCustomers?.dataCustomers?.open_leads?.count_status?.[7] ?? 0
            )}</h3>
              <div className="number">{Object.values(
              dataCustomers?.dataCustomers?.open_leads?.count_status?.[7] ?? 0
            )}</div>
            </div>
              <div className="box-icon">
                <FiberNewIcon style={{ color: "#fff" }} />
              </div>
          </div>
        </div>
        


        <Dialog
          fullWidth={true}
          maxWidth={"md"}
          open={userDetailOpen}
          onClose={handleCloseUserDetailDialog}
          style={{ minHeight: "100%" }}
          classes={{
            paper: classes.customModal
          }}
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {    
                maxWidth: "1040px",  // Set your width here
                boxShadow:'none',
                marginTop:'10px'
              },
            },
    }}
        >
          <DialogTitle className={`${classes.customModalTitle}`}>
            Lên đơn hàng
            <IconButton
              onClick={handleCloseUserDetailDialog}
              sx={{
                position: "absolute!important",
                right: 8,
                top: 8,
                color:'#fff',
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent className={`${classes.customModalContent}`}>
            <DiaLogAddCustomer
              setUDO={setUDO}
              setDetailCustomer={setDetailCustomer}
              detailCustomer={detailCustomer}
              setDataPagination={setDataPagination}
              dataPagination={dataPagination}
              checkEnablePhone={checkEnablePhone}
              dataLogoFooter={dataLogoFooter}
              dataCallStatus = {stateDataCallStatus}
            />
          </DialogContent>
        </Dialog>

        <DiaLogChangeStatus
          setOpenDialogChangeStatus={setOpenDialogChangeStatus}
          openDialogChangeStatus={openDialogChangeStatus}
          selected={selected}
          setSelected={setSelected}
        />

        <DiaLogSplitData
          setOpenDialogSplitData={setOpenDialogSplitData}
          openDialogSplitData={openDialogSplitData}
          dataCustomers={dataCustomers}
          selected={selected}
          setSelected={setSelected}
        />

          {/* option import */}
          <Dialog
          fullWidth={true}
          maxWidth={
            "md"
          }
          open={dialogImp}
          onClose={handleCloseDialogImp}
          style={{ minHeight: "100%" }}
          classes={{
            paper: classes.customModal,
          }}
        >
          <DialogTitle className={`${classes.customModalTitle}`}>
            Tải file
            <IconButton
              onClick={handleCloseDialogImp}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: "#fff",
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>

          <DialogContent className={`${classes.customModalContent}`}>
            
            <div className="modal-boxupload">
            <span style={{'color':'#5a8dee','cursor':'pointer'}} onClick={()=> handleDownloadSample()}><i className="fa-solid fa-cloud-arrow-down"></i> Tải xuống File mẫu</span>
             
              <Upload
                accept=".xlsx, .xls"
                name="file"
                beforeUpload={fileHandler}
                onRemove={() => setDataImp({ rows: [] })}
                multiple={false}
                disabled={dataImp.rows.length > 0 ? true : false}
              >
                <div className="form-upload">
                  <div className="content-upload">
                    <i className="fas fa-cloud-upload-alt"></i>
                    <p>Drag files here or browse (.xlsx)</p>
                  </div>
                </div>
              </Upload>
            </div>
            <div className="box-submitImport">
              <CustomButton
                onClick={handleSubmit}
                size="large"
                type="primary"
                style={{ marginBottom: 16, marginLeft: 10 }}
              >
                Tải lên
              </CustomButton>
            </div>
            {dataImp.rows.length > 0 && <TableImport dataImp={dataImp} />}
          </DialogContent>
        </Dialog>

        {openSmsSend && (
        <SmsSendForm
        setUDO1={setOpenSmsSend}  
           
          onClose={handleCloseSmsSend} 
          

          dataCustomers={dataCustomers}
          selected={selected}
          setSelected={setSelected}
        />
      )}

      <Dialog
          fullWidth={true}
          maxWidth={"sm"}
          open={userDetailCareOpen}
          onClose={handleCloseUserDetailCareDialog}
          style={{ minHeight: "100%" }}
          classes={{
            paper: classes.customModal
          }}
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {    
                  // Set your width here
                boxShadow:'none',
                marginTop:'10px'
              },
            },
    }}
        >
          <DialogTitle className={`${classes.customModalTitle}`}>
            Lịch sử chăm sóc
            <IconButton
              onClick={handleCloseUserDetailCareDialog}
              sx={{
                position: "absolute!important",
                right: 8,
                top: 8,
                color:'#fff',
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent className={`${classes.customModalContent}`}>
            <TicketHistoryCareList
              
              setDetailCustomer={setDetailCustomer}
              detailCustomer={detailCustomer}
              
            />
          </DialogContent>
        </Dialog>


          

      </div>
    </>
  );
}
