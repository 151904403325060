import {
    Box,
    Checkbox,
    IconButton,
    styled,
    Table,
    TableBody,
    TableCell,
    tableCellClasses,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow
} from '@mui/material';
import PropTypes from 'prop-types';
import moment from 'moment';
import React from 'react';
import { useTheme } from '@mui/material/styles';
import LastPageIcon from '@mui/icons-material/LastPage';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import { useDispatch } from 'react-redux';
import { getCustomerStart } from '../../../../redux/customers/action';

function TablePaginationActions(props) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;
  
    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    };
  
    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };
  
    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };
  
    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };
  
    return (
      <Box sx={{ flexShrink: 0, ml: 2.5 }}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </Box>
    );
  }
  
  TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
  };

const TableImport = ({ dataImp
 }) => {
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: theme.palette.action.hover,
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
        },
    }));

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        // hide last border
        "&:last-child td, &:last-child th": {
            border: 0,
        },
    }));

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const dispatch = useDispatch();

    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
    };

    // const handleChangePage = (event, newPage) => {
    //     let data = {
    //       offset: newPage * rowsPerPage,
    //       limit: rowsPerPage
    //     }
    //     dispatch(getCustomerStart(data))
    //     setDataPagination(data)
    //     setPage(newPage);
    //   };

    // const handleChangeRowsPerPage = (event) => {
    //     let data = {
    //       offset: page,
    //       limit: event.target.value !== "-1" ? +event.target.value : 9999
    //     }
    //     setDataPagination(data)
    //     dispatch(getCustomerStart(data))
    //     setRowsPerPage(+event.target.value);
    //     setPage(0);
    //   };


    return (
        <TableContainer style={{ width: "100%", overflowX: "auto" ,borderRadius:'0.5rem'}}>
            <Table sx={{ minWidth: 700 }} size={"small"}>
                <TableHead>
                    <TableRow>
                        <StyledTableCell align="left">STT</StyledTableCell>
                        <StyledTableCell align="left" style={{ minWidth: 150 }}>
                            Họ tên
                        </StyledTableCell>
                        <StyledTableCell align="left" style={{ minWidth: 80 }}>
                            SĐT
                        </StyledTableCell>
                        {/* <StyledTableCell align="left" style={{ minWidth: 120 }}>
                            Email
                        </StyledTableCell> */}
                        <StyledTableCell align="left" style={{ minWidth: 120 }}>
                            Địa chỉ
                        </StyledTableCell>
                        <StyledTableCell align="left" style={{ minWidth: 120 }}>
                            Nguồn_KH
                        </StyledTableCell>
                        {/* <StyledTableCell align="left" style={{ minWidth: 125 }}>
                            Kênh nguồn
                        </StyledTableCell> */}
                        <StyledTableCell align="left" style={{ minWidth:125 }}>
                            Thông tin KH
                        </StyledTableCell>
                        {/* <StyledTableCell align="left" style={{ minWidth: 125 }}>
                            Mã sp
                        </StyledTableCell> */}
                        <StyledTableCell align="left" style={{ minWidth: 125 }}>
                            Trạng thái
                        </StyledTableCell>
                    </TableRow>
                </TableHead>

                <TableBody>
                    {
                        dataImp?.rows.length > 0 ? dataImp?.rows?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((row, index) => {
                            return (
                                <StyledTableRow
                                    hover={true}
                                    key={row.name}
                                >
                                    <StyledTableCell align="left">{index + 1}</StyledTableCell>
                                    <StyledTableCell align="left">{row?.name}</StyledTableCell>
                                    <StyledTableCell align="left">{row?.phone}</StyledTableCell>
                                    {/* <StyledTableCell align="left">{row?.email}</StyledTableCell> */}
                                    <StyledTableCell align="left">{row?.address}</StyledTableCell>
                                    <StyledTableCell align="left">{row?.source_lead}</StyledTableCell>
                                    {/* <StyledTableCell align="left">{row?.source_lead_sub}</StyledTableCell> */}
                                    <StyledTableCell align="left">{row?.info}</StyledTableCell>
                                    {/* <StyledTableCell align="left">{row?.masp}</StyledTableCell> */}
                                    <StyledTableCell align="left">{row?.status_lead}</StyledTableCell>
                                    
                                </StyledTableRow>
                            )
                        }) : <h1>Không có dữ liệu</h1>
                    }
                </TableBody>
            </Table>

            <TablePagination
                rowsPerPageOptions={[ 10, 25]}
                colSpan={3}
                labelRowsPerPage={"Số hàng trên 1 trang:"}
                count={dataImp?.rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                    inputProps: {
                    'aria-label': 'rows per page',
                    },
                    native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                // ActionsComponent={TablePaginationActions}
            />
      </TableContainer>
    );
};

export default TableImport;