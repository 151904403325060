import { put, takeLatest } from "redux-saga/effects";
import axiosInstance from "../../components/axiosApi";
import { getTrangThaiDataError, getTrangThaiDataSuccess } from "./action";
import { GET_TRANG_THAI_DATA_START } from "./type";

function* getTrangThaiData() {
    try {
        const res = yield axiosInstance.get(`/leads/status/`);
        yield put(getTrangThaiDataSuccess(res.data.status))
    } catch (error) {
        yield put(getTrangThaiDataError(error))
    }
}

export function* watchingGetTrangThaiData() {
    yield takeLatest(GET_TRANG_THAI_DATA_START, getTrangThaiData);
}