import React from "react";
import {
  Paper,
  Table,
  TableBody,
  TableRow,
  TableContainer,
  Typography,
} from "@mui/material";
import MuiTableCell from "@material-ui/core/TableCell";
import MuiTableHead from "@mui/material/TableHead";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import "moment/locale/vi";
import { CustomButton } from "../../components/Common/CustomButton";
import { useDispatch } from "react-redux";
import { showMessageError, showMessageSuccess } from "../../redux/notification/action";
import axiosInstance from "../../components/axiosApi";
import blank from "../../assets/images/logo.png";


const useStyles = makeStyles((theme) => ({
  tableBorder: {
    border: "1px solid #d4d8dd",
    borderRadius: "0.5rem",
  },
}));

const TableCell = withStyles({
  root: {
    borderBottom: "1px solid rgb(235, 237, 242)",
    padding: "13px 10px",
    lineHeight: "18px",
  },
})(MuiTableCell);

const TableHead = withStyles((theme) => ({
  root: {
    backgroundColor: "rgba(38,60,85,.1)",
  },
}))(MuiTableHead);

const TableHeaderCell = withStyles((theme) => ({
  root: {
    color: "#516377",
    fontWeight: 700,
    padding: 10,
    fontSize: ".75rem",
    textTransform: "uppercase",
    letterSpacing: "0.8px",
  },
}))(TableCell);

const ZaloTable = ({ data }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleConnectFollow = async(id) => {
   
    try {
      const res = await axiosInstance.post(`/zalo/sync-user/${id}/`);
      if (res.status === 200) {
        dispatch(showMessageSuccess("Connect Success"));
      }
    } catch (error) {
      dispatch(showMessageError("Lỗi server"));
    }
  }


  

  return (
    <>
      {data?.zaloOA?.length > 0 ? (
        <Paper
          sx={{ width: "100%", mb: 2 }}
          elevation={0}
          className={`${classes.tableBorder}`}
        >
          <TableContainer
            style={{ width: "100%", overflowX: "auto", borderRadius: "0.5rem" }}
          >
            <Table sx={{ minWidth: 700 }} size={"small"}>
              <TableHead>
                <TableRow>
                  <TableHeaderCell align="left">ID</TableHeaderCell>
                  <TableHeaderCell align="left" style={{ minWidth: 80 }}>
                    Logo
                  </TableHeaderCell>
                  <TableHeaderCell align="left" style={{ minWidth: 120 }}>
                    TÊN GỌI
                  </TableHeaderCell>
                  <TableHeaderCell align="left" style={{ minWidth: 120 }}>
                    Mô Tả
                  </TableHeaderCell>
                  
                  <TableHeaderCell align="left" style={{ minWidth: 120 }}>
                    NGÀY TẠO
                  </TableHeaderCell>
                  <TableHeaderCell align="left" style={{ minWidth: 200 }}>
                    Đồng bộ Followers
                  </TableHeaderCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {data?.zaloOA &&
                  data?.zaloOA.length > 0 &&
                  data?.zaloOA.map((item, index) => (
                    <TableRow key={index}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell align="left">{item?.id}</TableCell>
                      <TableCell align="left">
                      <div className="zalo-logo">
                      <img src={
                                  item?.zimg ?
                                  `${item?.zimg}` : blank
                                  } alt="" />
                      </div>
                      </TableCell>
                      <TableCell align="left">{item?.oa_name}</TableCell>
                      <TableCell align="left">{item?.des}</TableCell>
                      
                      <TableCell align="left">
                        {item?.created_on === null
                          ? ""
                          : moment(item?.created_on).format("MM/DD/YYYY")}
                      </TableCell>
                      <TableCell align="left">
                        <CustomButton onClick={() => handleConnectFollow(item?.id)}
        >+ Follower</CustomButton>
        
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      ) : (
        <Typography>Dữ liệu chưa đồng bộ</Typography>
      )}
    </>
  );
};

export default ZaloTable;
