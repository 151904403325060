import * as types from "./type"

export const updateOrgStart = (data) => ({
    type: types.UPDATE_ORG_START,
    payload: data
})

export const updateOrgSuccess = (data) => ({
    type: types.UPDATE_ORG_SUCCESS,
    payload: data
})

export const updateOrgError = (data) => ({
    type: types.UPDATE_ORG_ERROR,
    payload: data
})