import styled from "styled-components";

export const BoxReport = styled.div`
    .MuiPaper-root {
        height: auto;
    }
    .title-content {
        color: #333;
        text-transform: capitalize;
        font-weight: 500;
        font-size: 1rem;
        line-height: 24px;
        padding: 0;
    }
    .ul-heading {
        font-family: "Open Sans","Helvetica","Arial",sans-serif;
        font-size: 1.2rem;
        font-weight: 600;
        display: block;
        span{
            margin-left:8px;
        }
    }
    .box-icon {
        
        color:${process.env.NODE_ENV === 'development' ? "#5a8dee" : process.env.REACT_APP_PRODUCT_BASE_COLOR};
        height: 50px;
        width: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 10px;
    }
    .box-content {
        display: flex;
        align-items: center;
        cursor: pointer;
        flex-direction:column;
        border-radius: 0.5rem;
        border:2px solid ${process.env.NODE_ENV === 'development' ? '#e5edfc' : process.env.REACT_APP_PRODUCT_BASE_COLOR2};
        padding:15px;
        &:hover{
            box-shadow: inherit;
        }
    }
    .title-icon {
        display: flex;
        align-items: center;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        border: 1px dashed ${process.env.NODE_ENV === 'development' ? "#e5edfc" : process.env.REACT_APP_PRODUCT_BASE_COLOR2};
        flex: 0 0 50px;
        justify-content: center;
        background: ${process.env.NODE_ENV === 'development' ? "#e5edfc" : process.env.REACT_APP_PRODUCT_BASE_COLOR2};
    }
`