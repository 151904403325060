import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Paper,
  Typography,
  Breadcrumbs,
  Link,
} from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import TableStatusLead from "./Table";
import CloseIcon from "@mui/icons-material/Close";
import AlertMessage from "../../../../components/Common/Alert";
import Loading from "../../../../components/Common/Loading";
import { Box } from "./styled";
import DialogOrderStatus from "./Dialog";
import { getStatusLead } from "../../../../redux/statusLead/action";

const StatusLead = () => {
  const dispatch = useDispatch();
  const [userDetailOpen, setUDO] = React.useState(false);
  const loading = useSelector((item) => item.statusLeadRuducer.loading);
  const statusLead = useSelector((item) => item.statusLeadRuducer.data);

  const handleAdd = (stt) => {
    setUDO(true);
  };

  const handleCloseUserDetailDialog = () => {
    setUDO(!userDetailOpen);
  };

  const navigate = (url) => {
    if (url === "/") {
      window.location.href = "/";
    } else {
      window.location.href = "/configuration";
    }
  };

  useEffect(() => {
    dispatch(getStatusLead());
  }, []);

  return (
    <Box>
      <Loading loading={loading} />
      <div className="screen">
        {/* <Paper className="user-list">

                    <div className="box-btn-add">
                        <div class="btn-add">
                            <Button
                                variant="outlined"
                                className="search-row-button"
                                onClick={() => {
                                    handleAdd();
                                }}
                            >
                                Thêm mới trạng thái
                            </Button>
                        </div>
                    </div>
                </Paper> */}
        <Breadcrumbs
          sx={{ fontSize: { xs: "14px", sm: "16px" } }}
          aria-label="breadcrumb"
          style={{ marginBottom: 15 }}
        >
          <Link
            underline="hover"
            className="cursor-pointer"
            color="inherit"
            onClick={() => {
              navigate("/");
            }}
          >
            Trang chủ
          </Link>
          <Link
            underline="hover"
            className="cursor-pointer"
            color="inherit"
            onClick={() => {
              navigate("/configuration");
            }}
          >
            Cấu Hình
          </Link>
          <Typography
            sx={{ fontSize: { xs: "14px", sm: "16px" } }}
            color="text.primary"
          >
            Trạng thái đơn hàng
          </Typography>
        </Breadcrumbs>
        <Paper
          sx={{
            width: "100%",
            marginTop: "10px",
            border: "1px solid #d4d8dd",
            borderRadius: "0.5rem",
          }}
          elevation={0}
        >
          <TableStatusLead statusLead={statusLead} />
        </Paper>

        <Dialog
          fullWidth={true}
          maxWidth={"sm"}
          open={userDetailOpen}
          onClose={handleCloseUserDetailDialog}
          style={{ minHeight: "100%" }}
        >
          <DialogTitle>
            Thêm mới mã
            <IconButton
              onClick={() => setUDO(false)}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>

          <DialogContent>
            <DialogOrderStatus setUDO={setUDO} />
          </DialogContent>
        </Dialog>
      </div>
    </Box>
  );
};

export default StatusLead;
