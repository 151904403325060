import * as types from './type'

const initialState = {
    data: [],
    loading: false,
    creLoadStatusId: false,
}

export const statusLeadIdReducer = (state = initialState, action) => {

    switch (action.type) {

        case types.GET_STATUS_LEAD_ID: 
            return {
                ...state,
                ...{ loading: true }
            }
        case types.GET_STATUS_LEAD_ID_SUCCESS:
            return {
                ...state,
                ...{
                    data: action.payload.status,
                    loading: false
                }
            }
        case types.GET_STATUS_LEAD_ID_FAIL:
            {
                return {
                    ...state,
                    ...{ data: [], loading: false }
                }
            }
        //
        case types.CREATE_STATUS_LEAD_ID: 
            return {
                ...state,
                creLoadStatusId: true,
            }
        case types.CREATE_STATUS_LEAD_ID_SUCCESS:
            return {
                ...state,
                creLoadStatusId: false
            }
        default:
            return state
    }
}