import React, { useEffect } from "react";
import {
  Table,
  TableBody,
  TableContainer,
  TableRow,
  Typography,
  Paper,
  TablePagination,
} from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import MuiTableCell from "@material-ui/core/TableCell";
import MuiTableHead from "@mui/material/TableHead";
import { renderWithCommand } from "../../helpers";
import moment from "moment";
import axiosInstance from "../../components/axiosApi";
import { getCustomerStart } from "../../redux/customers/action";
import { getProductsData } from "../../redux/products/action";
import { useDispatch } from "react-redux";

const useStyles = makeStyles((theme) => ({
  tableBorder: {
    border: "1px solid #d4d8dd",
    borderRadius: "0.5rem",
  },
  btnGlobal: {
    minWidth: "inherit",
    width: 28,
    padding: "0",
    height: "28px",
    lineHeight: "28px",

    marginLeft: 5,
    boxShadow: "none",
    fontSize: "12px",
    fontWeight: "500",
  },
  btnBlue: { backgroundColor: "#556ee6" },
  // btnGreen:{
  //   backgroundColor: '#34c38f',
  // },
  btnYellow: {
    backgroundColor: "#f1b44c",
  },
  btnRed: {
    backgroundColor: "#f46a6a",
  },
}));

const TableCell = withStyles({
  root: {
    borderBottom: "1px solid rgb(235, 237, 242)",
    padding: "10px",
    lineHeight: "18px",
  },
})(MuiTableCell);

const TableHead = withStyles((theme) => ({
  root: {
    backgroundColor: "rgba(38,60,85,.1)",
  },
}))(MuiTableHead);

const TableHeaderCell = withStyles((theme) => ({
  root: {
    color: "#516377",
    fontWeight: 700,
    padding: "10px",
    fontSize: ".75rem",
    textTransform: "uppercase",
    letterSpacing: "0.8px",
    lineHeight: "18px",
  },
}))(TableCell);

const CustomerCallTable = ({dataCustomers,setDetailCustomer, detailCustomer, showDetail, filterByPhone, setDataPagination, dataPagination}) => {
    const dispatch = useDispatch()
    const classes = useStyles();
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [rows, setRows] = React.useState();
    const [page, setPage] = React.useState(0);




    const handleChangePage = (event, newPage) => {
      let data = {
        offset: newPage * rowsPerPage,
        limit: rowsPerPage,
        phone: filterByPhone,
      };
      dispatch(getCustomerStart(data));
      setDataPagination(data);
      setPage(newPage);

    };
  
    const handleChangeRowsPerPage = (event) => {
      let data = {
        phone: filterByPhone,
        offset: page,
        limit: event.target.value !== "-1" ? +event.target.value : 9999,
      };
      // setDataPagination(data)
      dispatch(getCustomerStart(data));
      setRowsPerPage(+event.target.value);
      setPage(0);

    };

    const handleShowDetail = async (data) => {
      try {
        const res = await axiosInstance.get(`/leads/${data.id}/`);
        if (res.status === 200) 
        setDetailCustomer(res.data);
        dispatch(getProductsData([], ""));
        showDetail(true)
       
      } catch (error) {
        setDetailCustomer({});
      }
    };



    useEffect(() => {
        if (dataCustomers?.dataCustomers?.open_leads?.open_leads) {
          setRows([...dataCustomers?.dataCustomers?.open_leads?.open_leads]);
        }
      }, [dataCustomers?.dataCustomers?.open_leads]);
    console.log(rows)
  return (
    <>
        {dataCustomers?.dataCustomers?.open_leads?.open_leads.length > 0 ? (
        <Paper
          sx={{ width: "100%", mb: 2 }}
          elevation={0}
          className={`${classes.tableBorder}`}
        >
          <div
        className="ul-heading"
        style={{ padding: '8px', display: "flex", alignItems: "center", marginBottom:'0px',flexWrap:'wrap' }}
      >
        <span >Danh sách đơn hàng</span>
      </div>
          <TableContainer
            style={{
              width: "100%",
              overflowX: "auto",
              borderRadius: "0.5rem",
            }}
          >
            <Table sx={{ minWidth: 700 }} size={"small"}>
              <TableHead>
                <TableRow>
                <TableHeaderCell align="left" style={{ minWidth: 40 }}>
                   STT
                  </TableHeaderCell>
                  <TableHeaderCell align="left" style={{ width: 120 }}>
                  SĐT
                  </TableHeaderCell>

                  <TableHeaderCell align="left" style={{ minWidth: 40 }}>
                  TÊN KHÁCH HÀNG  
                  </TableHeaderCell>

                  <TableHeaderCell align="left" style={{ minWidth: 60 }}>
                  TỔNG TIỀN 
                  </TableHeaderCell>
                  <TableHeaderCell align="left" style={{ minWidth: 60 }}>
                  KHUYẾN MÃI  
                  </TableHeaderCell>
                  <TableHeaderCell align="left" style={{ minWidth: 60 }}>
                  NGÀY TẠO  
                  </TableHeaderCell>

                  
                </TableRow>
              </TableHead>
              <TableBody>
                {rows &&
                    rows?.map((row, index) => (
                    <TableRow hover={true} key={index}>
                        <TableCell onClick={() => handleShowDetail(row)} align="left"> {index + 1}</TableCell>
                      <TableCell onClick={() => handleShowDetail(row)} align="left">{row?.lead?.phone}</TableCell>
                      <TableCell  onClick={() => handleShowDetail(row)} align="left"> {row?.lead?.fullname}</TableCell>

                      <TableCell onClick={() => handleShowDetail(row)} align="left"> {renderWithCommand(row.total)} VNĐ</TableCell>
                      <TableCell onClick={() => handleShowDetail(row)} align="left"> {renderWithCommand(row.discount)} VNĐ</TableCell>
                      <TableCell onClick={() => handleShowDetail(row)} align="left"> {moment(row?.created_on).format("MM/DD/YYYY")} VNĐ</TableCell>
                      
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
                      rowsPerPageOptions={[10, 25]}
                      colSpan={3}
                      labelRowsPerPage={"Số hàng trên 1 trang:"}
                      count={dataCustomers?.dataCustomers?.open_leads?.leads_count}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      SelectProps={{
                        inputProps: {
                          "aria-label": "rows per page",
                        },
                        native: true,
                      }}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      style={{ border: "none" }}
                      // ActionsComponent={TablePaginationActions}
                    />
        </Paper>
      ) : (
        <Typography>Dữ liệu chưa đồng bộ</Typography>
      )}
    </>
  )
}

export default CustomerCallTable
