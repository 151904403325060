import axiosInstance from "../../components/axiosApi"

const StatusLeadApi = {
    getDataStatusSource: async (value) => {
        return axiosInstance.get(`/leads/statussub/${value}/`)
    },
    getDataLeadSubSource: async (id) => {
        return axiosInstance.get(`/leads/statussub/${id}/`)
    }
}

export default StatusLeadApi;