import { useEffect, useState } from "react";

const MOBILE_DIMENSION = 768;

const getIsMobile = (MOBILE_DIMENSION) => window.innerWidth <= MOBILE_DIMENSION;

export const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState(getIsMobile(MOBILE_DIMENSION));

  useEffect(() => {
    const onResize = () => {
      setIsMobile(getIsMobile(MOBILE_DIMENSION));
    };

    window.addEventListener("resize", onResize);

    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, [MOBILE_DIMENSION]);

  return isMobile;
};
