import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { Select, MenuItem } from "@mui/material";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";

import Tooltip from "@mui/material/Tooltip";

import { useDispatch, useSelector } from "react-redux";
import { getCustomerStart } from "../../../../redux/customers/action";
import { showMessageError } from "../../../../redux/notification/action";
import { showMessageSuccess } from "../../../../redux/notification/action";
import moment from "moment";
import { IconPhone, WrapCellPhone } from "./styled";
import { CustomButton } from "../../../../components/Common/CustomButton";
import { startCall } from "../../../../redux/callPhone/action";
import { getProductsData } from "../../../../redux/products/action";
import axiosInstance from "../../../../components/axiosApi";
import { renderWithCommand } from "../../../../helpers";
import { replaceMidd } from "../../../../helpers";

import { withStyles } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import MuiTableCell from "@material-ui/core/TableCell";
import MuiTableHead from "@mui/material/TableHead";
import ChatBubbleOutlineOutlinedIcon from "@mui/icons-material/ChatBubbleOutlineOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import CallSplitOutlinedIcon from "@mui/icons-material/CallSplitOutlined";

import phone_history from "../../../../assets/images/phonebook.png";
import {
  updateRegisteringCall,
  updateUnregisterCall,
} from "../../../../redux/users/action";
const useStyles = makeStyles((theme) => ({
  btnIcon: {
    fontSize: "16px !important",
  },
  iconMenuItem: {
    marginRight: 10,
  },
  btnGlobal: {
    minWidth: "inherit",
    width: 36,
    height: 36,
    lineHeight: 36,
    padding: 0,
    marginBottom: 5,
  },
  tableBorder: {
    border: "1px solid #d4d8dd",
    borderRadius: "0.5rem",
  },

  boxPhone: {
    display: "flex",
    borderRadius: "0.3rem",
    padding: "3px 5px",
    color:
      process.env.NODE_ENV === "development"
        ? "#5a8dee"
        : process.env.REACT_APP_PRODUCT_BASE_COLOR,
    alignItems: "center",
    backgroundColor:
      process.env.NODE_ENV === "development"
        ? "#e5edfc"
        : process.env.REACT_APP_PRODUCT_BASE_COLOR2,
    cursor: "pointer",
  },
  clickMore: {
    fontWeight: "bold",
    cursor: "pointer",
    color: "#4099ff",
    fontSize: "12px",
  },
  moreBg: {
    position: "relative",
    "&::before": {
      content: `''`,
      position: "absolute",
      width: "100%",
      bottom: 0,
      left: 0,
      height: "100%",
      background: "linear-gradient(180deg,rgba(255,255,255,0) 0%,#fff 79.17%)",
      zIndex: 5,
    },
  },
  moreBgActive: {
    "&::before": {
      content: "none",
    },
  },
  textCenter: {
    textAlign: "center",
  },
}));

const TableCell = withStyles({
  root: {
    borderBottom: "1px solid rgb(235, 237, 242)",
    padding: "3px 10px",
    lineHeight: "18px",
  },
})(MuiTableCell);

const TableHead = withStyles((theme) => ({
  root: {
    backgroundColor: "rgba(38,60,85,.1)",
  },
}))(MuiTableHead);

const TableHeaderCell = withStyles((theme) => ({
  root: {
    color: "#516377",
    fontWeight: 700,
    padding: 10,
    fontSize: ".75rem",
    textTransform: "uppercase",
    letterSpacing: "0.8px",
  },
}))(TableCell);

function EnhancedTableHead(props) {
  const { onSelectAllClick, numSelected, rowCount } = props;

  return (
    <TableHead>
      <TableRow>
        <TableHeaderCell
          component="th"
          scope="row"
          align="left"
          style={{ width: 40 }}
        >
          <Checkbox
            sx={{
              ".MuiSvgIcon-root": {
                width: "0.8em",
                height: "0.8em",
              },
            }}
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          />
        </TableHeaderCell>
        <TableHeaderCell align="left" style={{ width: 40 }}>
          STT
        </TableHeaderCell>
        <TableHeaderCell align="center" style={{ width: 80 }}>
          SĐT
        </TableHeaderCell>
        <TableHeaderCell align="left" style={{ minWidth: 140 }}>
          Tên Khách Hàng
        </TableHeaderCell>
        <TableHeaderCell align="left" style={{ minWidth: 125 }}>
          Tổng Tiền
        </TableHeaderCell>
        <TableHeaderCell align="left" style={{ minWidth: 100 }}>
          Khuyến Mãi
        </TableHeaderCell>
        <TableHeaderCell align="left" style={{ minWidth: 120 }}>
          Trạng Thái <br></br> Cuộc Gọi
        </TableHeaderCell>
        <TableHeaderCell align="left" style={{ minWidth: 150 }}>
          Trạng Thái
        </TableHeaderCell>
        <TableHeaderCell align="left" style={{ minWidth: 120 }}>
          Chọn nguồn
        </TableHeaderCell>
        <TableHeaderCell align="left" style={{ minWidth: 140 }}>
          Nhân viên chăm sóc
        </TableHeaderCell>
        <TableHeaderCell align="left">Ngày Tạo</TableHeaderCell>
        <TableHeaderCell align="center" style={{ minWidth: 100 }}>
          Lịch sử cuộc gọi
        </TableHeaderCell>
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,

  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const { numSelected } = props;

  return (
    <Toolbar
      variant="dense"
      sx={{
        height: 38,
        minHeight: 38,
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} được chọn
        </Typography>
      ) : (
        <Typography
          sx={{ flex: "1 1 100%" }}
          variant="h6"
          id="tableTitle"
          component="div"
        ></Typography>
      )}

      {/* {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon sx={{ width: "0.8em", height: "0.8em" }} />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <FilterListIcon sx={{ width: "0.8em", height: "0.8em" }} />
          </IconButton>
        </Tooltip>
      )} */}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function EnhancedTable({
  dataSources,
  dataCallStatus,
  dataStatus,
  dataCustomers,
  showDetail,
  setDetailCustomer,
  dataPagination,
  setDataPagination,
  setPhone,
  phone,
  selected,
  setSelected,
  directSelected,
  checkEnablePhone,
  filterByPhone,
  handleRowClick,
  showDetailCare,
  onHandleSend,
  setOpenDialogSplitData,
}) {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);

  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const [rows, setRows] = React.useState();
  const [previous, setPrevious] = React.useState({});
  const dispatch = useDispatch();

  // const dataContext = useContext(CountContext)

  useEffect(() => {
    let data = {
      phone: filterByPhone ?? "",
      offset: 0,
      limit: 10,
    };
    dispatch(getCustomerStart(data));
    // eslint-disable-next-line
  }, []);

  // useEffect(() => {

  //   if(dataContext.isPhone === true){
  //     let data = {
  //       offset: 0,
  //       limit: 10,
  //     };
  //     dispatch(getCustomerStart(data));
  //   }
  //   // eslint-disable-next-line
  // }, [dataContext.isPhone])

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds =
        dataCustomers.dataCustomers.open_leads.open_leads.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    let data = {
      offset: newPage * rowsPerPage,
      limit: rowsPerPage,
      phone: filterByPhone ?? "",
    };
    dispatch(getCustomerStart(data));
    setDataPagination(data);
    setPage(newPage);
    setSelected([]);
  };

  const handleChangeRowsPerPage = (event) => {
    let data = {
      phone: filterByPhone ?? "",
      offset: page,
      limit: event.target.value !== "-1" ? +event.target.value : 9999,
    };
    // setDataPagination(data)
    dispatch(getCustomerStart(data));
    setRowsPerPage(+event.target.value);
    setPage(0);
    setSelected([]);
  };

  const handleShowDetail = async (data) => {
    try {
      const res = await axiosInstance.get(`/leads/${data.id}/`);
      if (res.status === 200) setDetailCustomer(res.data);
      dispatch(getProductsData([], ""));
      showDetail(true);
    } catch (error) {
      setDetailCustomer({});
    }
  };

  const handleShowDetail2 = async (data) => {
    try {
      const res = await axiosInstance.get(`/leads/${data.id}/`);
      if (res.status === 200) setDetailCustomer(res.data);
      showDetailCare(true);
    } catch (error) {
      setDetailCustomer({});
    }
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  // const emptyRows =
  //   page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  // change Status color
  const getBackgroundColor = (status) => {
    if (status === 5) {
      return "bg-success";
    }
    if (status === 1) {
      return "bg-failed";
    }
    if (status === 2) {
      return "bg-success";
    }
    if (status === 3) {
      return "bg-pending";
    }
    if (status === 4) {
      return "bg-failed";
    }
    if (status === 7) {
      return "bg-pending";
    }
    if (status === 8) {
      return "bg-failed";
    }
    if (status === 6) {
      return "bg-failed";
    }
  };

  useEffect(() => {
    if (dataCustomers?.dataCustomers?.open_leads?.open_leads) {
      setRows([...dataCustomers?.dataCustomers?.open_leads?.open_leads]);
    }
  }, [dataCustomers?.dataCustomers?.open_leads]);

  const onChangeCallStatus = (e, row) => {
    if (!previous[row.id]) {
      setPrevious((state) => ({ ...state, [row.id]: row }));
    }
    const value = e.target.value;
    const name = e.target.name;
    const { id } = row;
    const filterValue = dataCallStatus?.status.filter(
      (item) => item.id === value
    );

    const getValue = filterValue[0];
    rows.map((row, idx) => {
      if (row.id === id) {
        const rowClone = { ...row, [name]: getValue };
        rows[idx] = rowClone;
      }

      return row;
    });
    setRows([...rows]);
    onToggleEditMode(row.id);
    handleUpdate(row.id);
  };

  const onChangeSourceData = (e, row) => {
    if (!previous[row.id]) {
      setPrevious((state) => ({ ...state, [row.id]: row }));
    }
    const value = e.target.value;
    const name = e.target.name;
    const { id } = row;
    const filterValue = dataSources?.sourceData.filter(
      (item) => item.id === value
    );
    const getValue = filterValue[0];
    rows.map((row, idx) => {
      if (row.id === id) {
        const rowClone = { ...row, [name]: getValue };
        rows[idx] = rowClone;
      }
      return row;
    });
    setRows([...rows]);
    onToggleEditMode(row.id);
    handleUpdate(row.id);
  };
  const onChange = (e, row) => {
    if (!previous[row.id]) {
      setPrevious((state) => ({ ...state, [row.id]: row }));
    }
    const value = e.target.value;
    const name = e.target.name;

    const filterValue = dataStatus?.dataTrangThai.filter(
      (item) => item.id === value
    );
    const getValue = filterValue[0];

    const { id } = row;
    rows.map((row, idx) => {
      if (row.id === id) {
        const rowClone = { ...row, [name]: getValue };
        rows[idx] = rowClone;
      }
      return row;
    });
    setRows([...rows]);
    onToggleEditMode(row.id);
    handleUpdate(row.id);
  };
  const onToggleEditMode = (id) => {
    rows.map((row, idx) => {
      row.isEditMode = row.isEditMode === undefined ? false : row.isEditMode;
      if (row.id === id) {
        const rowClone = { ...row, isEditMode: !row.isEditMode };
        rows[idx] = rowClone;
      }
      return row;
    });

    setRows([...rows]);
  };

  const handleUpdate = async (id) => {
    const rowUpdate = rows.find((row) => row.id === id);

    const leadStatusId =
      rowUpdate?.lead_status?.id === undefined
        ? ""
        : rowUpdate?.lead_status?.id;
    const leadSourceId =
      rowUpdate?.lead_source?.id === undefined
        ? ""
        : rowUpdate?.lead_source?.id;
    const callStatusId =
      rowUpdate?.callstatus?.id === undefined ? "" : rowUpdate?.callstatus?.id;
    const params = `lead_status=${leadStatusId}&lead_source=${leadSourceId}&callstatus=${callStatusId}`;

    try {
      const res = await axiosInstance.post(`/leads/${id}/?${params}`);
      if (res.status === 200) {
        dispatch(showMessageSuccess("Cập nhập thành công"));
      }
    } catch (error) {
      dispatch(showMessageError("Lỗi server"));
    }
  };

  //click goto history page
  const navigate = useNavigate();
  const handleClickCallHistory = (id) => {
    let historyURL = `/callhistory/${id}`;
    directSelected(22);
    navigate(historyURL);
  };

  const [openModalCall, setOpenModalCall] = React.useState(false);
  const [itemDetail, setItemDetail] = React.useState();
  const dataUser = useSelector((item) => item.UsersReducer);

  const handleOpenModalCall = (phone) => {
    setPhone(phone);
    setOpenModalCall(true);
  };

  const handleCloseModalCall = () => {
    setOpenModalCall(false);
    dispatch(updateUnregisterCall());
  };

  const handleRegistering = () => {
    dispatch(updateRegisteringCall());
  };

  const handleCall = (phone) => {
    dispatch(startCall(phone));

    setOpenModalCall(false);
  };

  // smart button

  return (
    <Box sx={{ width: "100%" }}>
      <Paper
        sx={{ width: "100%", mb: 2 }}
        elevation={0}
        className={`${classes.tableBorder}`}
      >
        <EnhancedTableToolbar numSelected={selected.length} />
        <TableContainer style={{ width: "100%", overflowX: "auto" }}>
          <Table
            sx={{ minWidth: 700 }}
            size={"small"}
            // sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            // size={dense ? 'small' : 'medium'}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              // order={order}
              // orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              // onRequestSort={handleRequestSort}
              rowCount={
                dataCustomers?.dataCustomers?.open_leads?.open_leads?.length
              }
            />
            <TableBody>
              {dataCustomers?.dataCustomers &&
                rows?.map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      sx={{
                        "&.MuiTableRow-hover": {
                          "&:hover": {
                            backgroundColor: "#fff",
                          },
                        },
                      }}
                      hover
                      // role="checkbox"
                      // aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.name}
                      // selected={isItemSelected}
                    >
                      <TableCell component="th" scope="row" align="left">
                        <Checkbox
                          sx={{
                            ".MuiSvgIcon-root": {
                              width: "0.8em",
                              height: "0.8em",
                            },
                          }}
                          color="primary"
                          checked={isItemSelected}
                          inputProps={{
                            "aria-labelledby": labelId,
                          }}
                          onClick={(event) => handleClick(event, row.id)}
                        />
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        align="left"
                        onClick={() => handleShowDetail(row)}
                      >
                        {index + 1}
                      </TableCell>
                      <TableCell align="left">
                        <WrapCellPhone>
                          {row?.lead?.phone ? (
                            <Tooltip title="Gọi ngay số này" arrow>
                              <div
                                className={`${classes.boxPhone}`}
                                onClick={() => {
                                  handleOpenModalCall(row?.lead?.phone);
                                  setItemDetail(row);
                                }}
                              >
                                <IconPhone className="phone-icon">
                                  <svg
                                    xmlns="http:www.w3.org/2000/svg"
                                    aria-hidden="true"
                                    role="img"
                                    width="18px"
                                    height="18px"
                                    preserveAspectRatio="xMidYMid meet"
                                    viewBox="0 0 1024 1024"
                                  >
                                    <path
                                      fill="currentColor"
                                      d="M885.6 230.2L779.1 123.8a80.83 80.83 0 0 0-57.3-23.8c-21.7 0-42.1 8.5-57.4 23.8L549.8 238.4a80.83 80.83 0 0 0-23.8 57.3c0 21.7 8.5 42.1 23.8 57.4l83.8 83.8A393.82 393.82 0 0 1 553.1 553A395.34 395.34 0 0 1 437 633.8L353.2 550a80.83 80.83 0 0 0-57.3-23.8c-21.7 0-42.1 8.5-57.4 23.8L123.8 664.5a80.89 80.89 0 0 0-23.8 57.4c0 21.7 8.5 42.1 23.8 57.4l106.3 106.3c24.4 24.5 58.1 38.4 92.7 38.4c7.3 0 14.3-.6 21.2-1.8c134.8-22.2 268.5-93.9 376.4-201.7C828.2 612.8 899.8 479.2 922.3 344c6.8-41.3-6.9-83.8-36.7-113.8z"
                                    />
                                  </svg>
                                </IconPhone>

                                <p style={{ margin: 0 }}>
                                  {checkEnablePhone === true
                                    ? replaceMidd(row?.lead?.phone, 0)
                                    : replaceMidd(row?.lead?.phone, 3)}{" "}
                                </p>
                              </div>
                            </Tooltip>
                          ) : null}
                        </WrapCellPhone>
                        <div className="smart-button">
                          <span
                            className="btn"
                            onClick={(event) => {
                              onHandleSend(row);
                              handleClick(event, row.id)
                            }}
                          >
                            <ChatBubbleOutlineOutlinedIcon
                              style={{ width: "1rem", height: "1rem" }}
                            />
                          </span>
                          <span className="btn">
                            <EmailOutlinedIcon
                              style={{ width: "1rem", height: "1rem" }}
                            />
                          </span>
                          <span
                            className="btn"
                            onClick={(event) => {
                              handleClick(event, row.id);
                              setOpenDialogSplitData(true);
                              
                            }}
                          >
                            <CallSplitOutlinedIcon
                              style={{ width: "1rem", height: "1rem" }}
                            />
                          </span>
                        </div>
                      </TableCell>
                      <TableCell
                        align="left"
                        onClick={() => handleShowDetail(row)}
                      >
                        {row?.lead?.fullname}
                      </TableCell>
                      {/* <TableCell
                          style={{ width: 180 }}
                          align="left"
                          onClick={() => handleShowDetail(row)}
                        >
                          {row.lead.description}
                        </TableCell> */}
                      <TableCell
                        align="left"
                        onClick={() => handleShowDetail(row)}
                        style={{ fontWeight: 500 }}
                      >
                        {renderWithCommand(row.total)} VNĐ
                      </TableCell>
                      <TableCell
                        align="left"
                        onClick={() => handleShowDetail(row)}
                      >
                        {renderWithCommand(row.discount)} VNĐ
                      </TableCell>

                      <TableCell align="left">
                        {row.isEditMode ? (
                          <Select
                            label="Trạng thái cuộc gọi"
                            name="callstatus"
                            value={row?.callstatus?.id}
                            onChange={(e) => onChangeCallStatus(e, row)}
                            size="small"
                          >
                            {dataCallStatus?.status?.length > 0
                              ? dataCallStatus?.status?.map((item) => (
                                  <MenuItem value={item.id}>
                                    {item.title}
                                  </MenuItem>
                                ))
                              : null}
                          </Select>
                        ) : (
                          <Tooltip title="Click Edit" arrow>
                            <div
                              onClick={() => {
                                onToggleEditMode(row.id);
                              }}
                              className={`text-status ${getBackgroundColor(
                                row?.callstatus?.id
                              )}`}
                            >
                              {row?.callstatus === null
                                ? "cập nhập"
                                : row?.callstatus?.title}
                            </div>
                          </Tooltip>
                        )}
                      </TableCell>

                      <TableCell align="left">
                        {row.isEditMode ? (
                          <Select
                            label="Trạng thái phê duyệt"
                            name="lead_status"
                            value={row?.lead_status?.id}
                            onChange={(e) => onChange(e, row)}
                            size="small"
                          >
                            {dataStatus?.dataTrangThai?.length > 0
                              ? dataStatus?.dataTrangThai?.map((item) => (
                                  <MenuItem value={item.id}>
                                    {item.title}
                                  </MenuItem>
                                ))
                              : null}
                          </Select>
                        ) : (
                          <Tooltip title="Click Edit" arrow>
                            <div
                              onClick={() => {
                                onToggleEditMode(row.id);
                              }}
                              className={`text-status ${getBackgroundColor(
                                row?.lead_status?.id
                              )}`}
                            >
                              {row?.lead_status?.title}
                            </div>
                          </Tooltip>
                        )}
                      </TableCell>
                      <TableCell align="left">
                        {row.isEditMode ? (
                          <Select
                            label="Trạng thái phê duyệt"
                            name="lead_source"
                            value={row?.lead_source?.id}
                            onChange={(e) => onChangeSourceData(e, row)}
                            size="small"
                          >
                            {dataSources?.sourceData?.length > 0
                              ? dataSources?.sourceData?.map((item) => (
                                  <MenuItem value={item.id}>
                                    {item.title}
                                  </MenuItem>
                                ))
                              : null}
                          </Select>
                        ) : (
                          <Tooltip title="Click Edit" arrow>
                            <div
                              onClick={() => {
                                onToggleEditMode(row.id);
                              }}
                            >
                              {row?.lead_source?.title || ""}
                            </div>
                          </Tooltip>
                        )}
                      </TableCell>

                      <TableCell align="left">
                        {row?.assigned_to &&
                          `${row?.assigned_to?.first_name} ${row?.assigned_to?.last_name}`}
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        onClick={() => handleShowDetail(row)}
                      >
                        {moment(row?.created_on).format("MM/DD/YYYY")}
                      </TableCell>
                      <TableCell align="center">
                        <div
                          onClick={() =>
                            handleClickCallHistory(row?.lead?.phone)
                          }
                          className={`${classes.textCenter}`}
                        >
                          <img
                            style={{ margin: "0 auto", cursor: "pointer" }}
                            alt="phone-img"
                            src={phone_history}
                          />
                        </div>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25]}
          colSpan={3}
          labelRowsPerPage={"Số hàng trên 1 trang:"}
          count={dataCustomers.dataCustomers?.open_leads?.leads_count}
          rowsPerPage={rowsPerPage}
          page={page}
          SelectProps={{
            inputProps: {
              "aria-label": "rows per page",
            },
            native: true,
          }}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          style={{ border: "none" }}
          // ActionsComponent={TablePaginationActions}
        />
      </Paper>

      {/* <ul className={`list-support-registercall
                        ${dataUser.registered === "registered" && itemDetail ? "active" : ""}`}
                >
              <Tooltip title="Thêm Đơn Hàng" enterTouchDelay={0}>
                <CustomButton
                  variant="contained"
                  className={`${classes.btnGlobal} ${classes.btnBlue}`}
                  onClick={() => 
                    {
                      handleShowDetail(itemDetail)
                    }
                  }

                >
                  <AddShoppingCartIcon className={`${classes.btnIcon}`} />
                </CustomButton>
              </Tooltip>
              <Tooltip title="Lịch sử chăm sóc" enterTouchDelay={0}>
                <CustomButton
                  variant="contained"
                  className={`${classes.btnGlobal} ${classes.btnBlue}`}
                  onClick={() => {
                    
                    handleShowDetail2(itemDetail)
                  }}

                >
                  <SupportAgentOutlinedIcon className={`${classes.btnIcon}`} />
                </CustomButton>
              </Tooltip>
                  </ul> */}

      {openModalCall && (
        <div className="pu-phone">
          <div className="text">
            Cuộc gọi sẽ kết nối tới tổng đài. Bạn có muốn thực hiện cuộc gọi đến
            số
            <span style={{ fontWeight: 600 }}>
              {" "}
              {checkEnablePhone === true
                ? replaceMidd(phone, 0)
                : replaceMidd(phone, 3)}
            </span>
          </div>
          <div style={{ display: "flex", marginTop: 5 }}>
            {dataUser && dataUser.registered === "unregistered" && (
              <CustomButton
                // color="primary"
                variant="contained"
                type="submit"
                onClick={() => handleRegistering()}
                style={{ marginLeft: "auto" }}
              >
                Đồng ý
              </CustomButton>
            )}
            {dataUser && dataUser.registered === "registered" && (
              <CustomButton
                // color="primary"
                variant="contained"
                type="submit"
                onClick={() => handleCall(phone)}
                style={{ marginLeft: "auto" }}
              >
                Gọi
              </CustomButton>
            )}
            <CustomButton
              // color="primary"
              variant="contained"
              type="submit"
              style={{ marginLeft: 10 }}
              onClick={handleCloseModalCall}
            >
              Kết thúc
            </CustomButton>
          </div>
        </div>
      )}
    </Box>
  );
}
