import * as types from "./type";

export const getReports = (data) => ({
  type: types.GET_REPORTS,
  payload: data,
});
export const getReportsSuccess = (data) => ({
  type: types.GET_REPORTS_SUCCESS,
  payload: data,
});
export const getReportsFail = (data) => ({
  type: types.GET_REPORTS_FAIL,
  payload: data,
});

export const getReportCall = (data) => ({
  type: types.GET_REPORT_CALL,
  payload: data,
});
export const getReportCallSuccess = (data) => ({
  type: types.GET_REPORT_CALL_SUCCESS,
  payload: data,
});
export const getReportCallFail = (data) => ({
  type: types.GET_REPORT_CALL_FAIL,
  payload: data,
});

export const resetMessage = (data) => ({
  type: types.RESET_MESSAGE,
  payload: data,
});

export const resetMessageError = (data) => ({
  type: types.RESET_MESSAGE_ERROR,
  payload: data,
});
