import React, { useEffect, useState } from "react";
import {
  
  Grid,
  
  MenuItem,
  Select,
  TextareaAutosize,
  TextField,
  DialogContent,
  DialogTitle,
  Dialog,
  
} from "@mui/material";

import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import { CustomButton } from "../../components/Common/CustomButton";
import { makeStyles } from "@material-ui/core/styles";
import styled from "styled-components";
import AssignToSelect from "../../components/Common/AssignTo/AssignToSelect";

import { useFormik } from "formik";
import * as yup from "yup";
import TicketCustomer from "./TicketCustomer";
import { createTicket } from "../../redux/ticket/action";
import { useDispatch } from "react-redux";
import TicketHistoryCareList from "./TicketHistoryCareList";
const priorityData = [
  { id: 1, label: "Khẩn cấp" },
  { id: 2, label: "Cao" },
  { id: 3, label: "Trung Bình" },
  { id: 4, label: "Thấp" },
];

const statusData = [
  { id: 1, label: "Mới" },
  { id: 2, label: "Đang chờ" },
  { id: 3, label: "Đang xử lý" },
  { id: 4, label: "Đã đóng" },
];

export const TitleInput = styled("p")(({ theme }) => ({
  fontSize: 14,
  fontWeight: 500,
  marginBottom: 10,
}));

export const BoxInput = styled("div")(({ theme }) => ({
  marginBottom: "10px",
}));

const useStyles = makeStyles((theme) => ({
  textFieldError: {
    "& input": {
      border: "1px solid red",
      color: "red",
    },
    "& p": {
      color: "red",
      marginLeft: 0,
    },
  },
  customModalContent: {
    paddingLeft: "15px",
    paddingRight: "15px",
  },
  customModal: {
    width: "calc(100% - 20px)",
    marginLeft: "10px",
    marginRight: "10px",
  },
  customModalTitle: {
    padding: "10px 15px",
    color: "#fff",
    background:
      process.env.NODE_ENV === "development"
        ? "#5a8dee"
        : process.env.REACT_APP_PRODUCT_BASE_COLOR,
    marginBottom: "15px",
  },
}));

const TicketModal = ({ onCloseTicket, setUDO1, dataUser, number, cusName }) => {
  const dispatch = useDispatch()
  const classes = useStyles();

  const [detailCustomer, setDetailCustomer] = useState(null);

  const [priority, setPriority] = useState(priorityData[2].id);
  const [status, setStatus] = useState(statusData[0].id);

  const [assign, setAssign] = useState("");
  const [name, setName] = useState("");
  const [content, setContent] = useState("");



  const validationSchema = yup.object({});


  const submitForm =  () => {
    dispatch(createTicket(values));
    setUDO1(false);
  };

  const { values, handleSubmit, handleChange } =
    useFormik({
      initialValues: {
        phone: number,
        name: "",
        content: "",
        cus_name: cusName,
        trangthai: status,
        assigned_to: assign,
        priorityname: priority,
      },
      validationSchema: validationSchema,
      onSubmit: submitForm,
    });

    

    

  return (
    <Dialog
      
      fullWidth={true}
          maxWidth={"md"}
          open={true}
          onClose={onCloseTicket}
          style={{ minHeight: "100%" }}
          classes={{
            paper: classes.customModal
          }}
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {    
                maxWidth: "1040px",  // Set your width here
                boxShadow:'none',
                marginTop:'10px'
              },
            },
          }}
        >
      <DialogTitle className={`${classes.customModalTitle}`}>
        Tạo Phiếu
        <IconButton
          onClick={() => setUDO1(false)}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "#fff",
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={`${classes.customModalContent}`}>
        <Grid container rowSpacing={1} columnSpacing={{ sm: 2 }}>
          <Grid item xs={12} sm={7}>
            <Grid container columnSpacing={{ sm: 1 }}>
              <Grid item xs={12} sm={6}>
                <BoxInput>
                  <TitleInput>Tên</TitleInput>
                  <TextField
                    name="cus_name"
                    size="small"
                    fullWidth
                    value={cusName}
                    disabled
                    onChange={handleChange}
                  />
                </BoxInput>
              </Grid>
              <Grid item xs={12} sm={6}>
                <BoxInput>
                  <TitleInput>Số điện thoại</TitleInput>
                  <TextField
                    name="phone"
                    size="small"
                    fullWidth
                    value={number}
                    disabled
                    
                  />
                </BoxInput>
              </Grid>
              <Grid item xs={12}>
                <BoxInput>
                  <TitleInput>Địa chỉ</TitleInput>
                  <TextField
                    name="address"
                    size="small"
                    fullWidth
                    value={dataUser?.open_leads?.open_leads[0]?.lead?.address}
                    disabled
                  />
                </BoxInput>
              </Grid>
              <Grid item xs={12}>
                <div className="boxgroup">
                  <BoxInput>
                    <TitleInput>Tỉnh / Thành Phố</TitleInput>
                    <TextField
                      name="city"
                      size="small"
                      fullWidth
                      value={
                        dataUser?.open_leads?.open_leads[0]?.lead?.city?.name
                      }
                      disabled
                    />
                  </BoxInput>
                  <BoxInput>
                    <TitleInput>Quận / Huyện</TitleInput>
                    <TextField
                      name="district"
                      size="small"
                      fullWidth
                      value={
                        dataUser?.open_leads?.open_leads[0]?.lead?.district
                          ?.name
                      }
                      disabled
                    />
                  </BoxInput>
                  <BoxInput>
                    <TitleInput>Phường / Xã</TitleInput>
                    <TextField
                      name="ward"
                      size="small"
                      fullWidth
                      value={
                        dataUser?.open_leads?.open_leads[0].lead?.ward?.name
                      }
                      disabled
                    />
                  </BoxInput>
                </div>
              </Grid>
              <Grid item xs={12} sm={4}>
                <BoxInput>
                  <TitleInput>Giao cho</TitleInput>
                  <AssignToSelect
                    value={values.assigned_to}
                    onChange={handleChange}
                  />
                </BoxInput>
              </Grid>
              <Grid item xs={12} sm={4}>
                <TitleInput>Ưu tiên</TitleInput>

                <Select
                  style={{ fontSize: 14 }}
                  labelId="priorityname"
                  fullWidth
                  size="small"
                  label="Ưu tiên"
                  name="priorityname"
                  value={values.priorityname}
                  onChange={handleChange}
                >
                  {priorityData.map((data, index) => (
                    <MenuItem key={index} value={data.id}>
                      {data.label}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={12} sm={4}>
                <TitleInput>Trạng thái</TitleInput>

                <Select
                  style={{ fontSize: 14 }}
                  labelId="trangthai"
                  fullWidth
                  size="small"
                  label="Trạng thái"
                  name="trangthai"
                  value={values.trangthai}
                  onChange={handleChange}
                >
                  {statusData.map((data, index) => (
                    <MenuItem key={index} value={data.id}>
                      {data.label}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={5}>
            <Grid container>
              <Grid item xs={12}>
                <div className="box-note" style={{ marginBottom: "10px" }}>
                <span
            className="ul-heading"
            style={{ display: "flex", flex: "inherit", fontWeight: 500, padding:0 }}
          >
            Nội dung phiếu ghi
          </span>
                  <BoxInput>
                    <TitleInput>Tên phiếu ghi</TitleInput>
                    <TextField
                      name="name"
                      size="small"
                      fullWidth
                      placeholder="Tên phiếu ghi"
                      value={values.name}
                      onChange={handleChange}
                    />
                  </BoxInput>

                  <BoxInput>
                    <TitleInput>Ghi chú</TitleInput>
                    <TextareaAutosize
                      minRows={1}
                      id="careContent"
                      name="content"
                      size="small"
                      placeholder="Nhập nội dung"
                      variant="outlined"
                      style={{ border: "none !important", fontSize:'14px' }}
                      value={values.content}
                      onChange={handleChange}
                    />
                  </BoxInput>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <BoxInput>
          <CustomButton
            variant="contained"
            type="submit"
            onClick={() => handleSubmit()}
            style={{ marginLeft: "auto" }}
          >
            Tạo phiếu
          </CustomButton>
        </BoxInput>
        <Grid container rowSpacing={1} columnSpacing={{ sm: 2 }}>
          <Grid item xs={12} sm={7}>
            <TicketCustomer 
            dataCustomers={dataUser}
            setDetailCustomer={setDetailCustomer}
            />
          </Grid>
          <Grid item xs={12} sm={5}>
          <TicketHistoryCareList 
            setDetailCustomer={setDetailCustomer}
              detailCustomer={detailCustomer}

            />
          </Grid>
        </Grid>
        
        
      </DialogContent>
    </Dialog>
  );
};

export default TicketModal;
