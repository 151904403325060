import React, { useEffect, useState , memo} from "react";
import Dialog from "@mui/material/Dialog";
import { Grid } from "@mui/material";
import TextField from "@mui/material/TextField";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { CustomButton } from "../../components/Common/CustomButton";
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import styled from "styled-components";
import { editSmsconfig, getSmsconfig } from "../../redux/smsconfig/action";

export const TitleInput = styled("p")(({ theme }) => ({
    fontSize: 14,
    fontWeight: 500,
    marginBottom: 10,
  }));
  
  export const BoxInput = styled("div")(({ theme }) => ({
    
    marginBottom:'10px'
  }));

const useStyles = makeStyles((theme) => ({
    textFieldError: {
        "& input": {
          border: "1px solid red",
          color: "red",
        },
        "& p": {
          color: "red",
          marginLeft:0,
        },
      },
  customModalContent: {
    paddingLeft: "15px",
    paddingRight: "15px",
  },
  customModal: {
    width: "calc(100% - 20px)",
    marginLeft: "10px",
    marginRight: "10px",
  },
  customModalTitle: {
    padding: "10px 15px",
    color: "#fff",
    background: process.env.NODE_ENV === 'development' ? "#5a8dee" : process.env.REACT_APP_PRODUCT_BASE_COLOR,
    marginBottom:'15px',
  },
}));

const SmsEdit = ({ setUDO1, item, onClose, idSms }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [nameEdit, setNameEdit] = useState(item?.name ?? "");
  const [brandEdit, setBrandEdit] = useState(item?.brandName ?? "");
  
  const [tranidEdit, setTranidEdit] = useState(item?.tranId ?? "");
  const [usernameEdit, setUsernameEdit] = useState(item?.userName ?? "");
  const [pwdEdit, setPwdEdit] = useState(item?.password ?? null);
  const [urlEdit, setUrlEdit] = useState(item?.url ?? "") 

  const validationSchema = yup.object({
    // name: yup.string().required('Bạn chưa nhập tên phòng ban'),
  });

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item]);

  const formik = useFormik({
    initialValues: {
      name: nameEdit,
      brand: brandEdit,
     
      tranid: tranidEdit,
      username:usernameEdit,
      password:pwdEdit,
      id:idSms,
      url:urlEdit,
    },
    validationSchema: validationSchema,
      onSubmit: async (values) => {
      dispatch(editSmsconfig(values));
      setUDO1(false);
      dispatch(getSmsconfig());
    },
  });
  return (
    <Dialog
      fullWidth={true}
      maxWidth={"sm"}
      open={true}
      onClose={onClose}
      style={{ minHeight: "100%" }}
      classes={{
        paper: classes.customModal,
      }}
    >
      <DialogTitle className={`${classes.customModalTitle}`}>
        Sửa cấu hình
        <IconButton
          onClick={() => setUDO1(false)}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "#fff",
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={`${classes.customModalContent}`}>
        <form onSubmit={formik.handleSubmit}>
          <Grid
            container
            rowSpacing={2}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item xs={12} sm={6}>
              <BoxInput>
                <TitleInput>
                  Tên
                </TitleInput>
                <TextField
                  name="name"
                  size="small"
                  fullWidth
                  placeholder="Tên"
                  value={nameEdit}
                  onChange={(e) => {
                    formik.setFieldValue("name", e.currentTarget.value);
                    setNameEdit(e.currentTarget.value);
                  }}
                />
              </BoxInput>
            </Grid>
            <Grid item xs={12} sm={6}>
              <BoxInput>
                <TitleInput>
                  Brand Name
                </TitleInput>
                <TextField
                  name="brand"
                  size="small"
                  fullWidth
                  placeholder="Brand Name"
                  value={brandEdit}
                  onChange={(e) => {
                    formik.setFieldValue("brand", e.currentTarget.value);
                    setBrandEdit(e.currentTarget.value);
                  }}
                />
              </BoxInput>
            </Grid>
            
            <Grid item xs={12} sm={6}>
              <BoxInput>
                <TitleInput>TRANID</TitleInput>
                <TextField
                  name="tranid"
                  size="small"
                  fullWidth
                  value={tranidEdit}
                  onChange={(e) => {
                    formik.setFieldValue("tranid", e.currentTarget.value);
                    setTranidEdit(e.currentTarget.value);
                  }}
                />
              </BoxInput>
            </Grid>
            
            <Grid item xs={6}>
              <BoxInput>
                <TitleInput>
                Tên đăng nhập
                </TitleInput>
                
                <TextField
                id="name"
                name="username"
                size="small"
                fullWidth
                placeholder="Tên đăng nhập"
                value={usernameEdit}
                  onChange={(e) => {
                    formik.setFieldValue("username", e.currentTarget.value);
                    setUsernameEdit(e.currentTarget.value);
                  }}
              />
              </BoxInput>
            </Grid>
            <Grid item xs={6}>
            <BoxInput>
              <TitleInput>
                Mật khẩu
              </TitleInput>
              <TextField
                name="password"
                size="small"
                type="password"
                fullWidth
                value={pwdEdit}
                  onChange={(e) => {
                    formik.setFieldValue("password", e.currentTarget.value);
                    setPwdEdit(e.currentTarget.value);
                  }}
              />
              </BoxInput>
            </Grid>
            <Grid item xs={6}>
              <BoxInput>
                <TitleInput>
                    Đường dẫn (URL)
                </TitleInput>
               
                <TextField
                name="url"
                size="small"
                fullWidth
                value={urlEdit}
                  onChange={(e) => {
                    formik.setFieldValue("url", e.currentTarget.value);
                    setUrlEdit(e.currentTarget.value);
                  }}
              />
              </BoxInput>
            </Grid>
          </Grid>

          <div
            style={{ display: "flex", justifyContent: "right", marginTop: 10 }}
          >
            <CustomButton
              variant="contained"
              type="submit"
              onClick={formik.handleSubmit}
            >
              Sửa
            </CustomButton>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
};
export default memo(SmsEdit);