export const GET_LEAD_STATUS = "GET_LEAD_STATUS"
export const GET_LEAD_STATUS_SUCCESS = "GET_LEAD_STATUS_SUCCESS"
export const GET_LEAD_STATUS_FAIL = "GET_LEAD_STATUS_FAIL"

export const GET_LEAD_STATUS_ID = "GET_LEAD_STATUS_ID"
export const GET_LEAD_STATUS_ID_SUCCESS = "GET_LEAD_STATUS_ID_SUCCESS"
export const GET_LEAD_STATUS_ID_FAIL = "GET_LEAD_STATUS_ID_FAIL"

// export const CREATE_LEAD_STATUS_ID = "CREATE_LEAD_STATUS_ID"
// export const CREATE_LEAD_STATUS_ID_SUCCESS = "CREATE_LEAD_STATUS_ID_SUCCESS"
// export const CREATE_LEAD_STATUS_ID_FAIL = "CREATE_LEAD_STATUS_ID_FAIL"