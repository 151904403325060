import React, { useState, useEffect, useCallback } from "react";
import { showMessageError } from "../../redux/notification/action";
import {
  Typography

} from "@mui/material";
import ZaloChatSidebar from './ZaloChatSidebar'
import ZaloChatOaSidebar from './ZaloChatOaSidebar'
import ZaloChat from './ZaloChat'
import ZaloWelcome from './ZaloWelcome'
import axiosInstance from "../../components/axiosApi";
import { useDispatch } from "react-redux";

const ZaloChatWrapper = () => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const [selected, setSelected] = React.useState();
    const [selectedOA, setSelectedOA] = React.useState(0);
    const [open, setOpen] = useState(false);
    const [openSidebar, setOpenSidebar] = useState(false);
    const [data, setDataOa] = useState([]);
    const [idOA, setIDOA] = React.useState([]);
    // eslint-disable-next-line
    const [search, setSearch] = useState('')
    const [dataFollowList, setDataFollowList] = useState([]);
    // eslint-disable-next-line
    const [dataPagination, setDataPagination] = useState({
        offset: 0,
        limit: 10,
      });

      useEffect(() => {
        const getZaloOaList = async () => {
          try {
            const res = await axiosInstance.get("/zalo/");
            if (res.status === 200) {
              setDataOa(res.data);
            }
            if(res.status === -224){
              dispatch(showMessageError('tài khoản zaloOA chưa nâng cấp'));
            }
          } catch (error) {
           
          }
        };
        getZaloOaList();
       // eslint-disable-next-line
      }, []);
      

       useEffect(() => {

         if (data?.zaloOA?.length > 0){
           const idOaFirst = data?.zaloOA[0];
             getDataFollowList(idOaFirst.id)
             setIDOA(idOaFirst.id);
           }
           // eslint-disable-next-line
       }, [data]);  

      const getDataFollowList = useCallback(async (idOA) => {
        let params = "";
        params += `&name=${search}&limit=${dataPagination.limit}&offset=${dataPagination.offset}`;
        try {
          const res = await axiosInstance.get(`/zalo/msg/${idOA}/?${params}`);
          if (res.status === 200) {
            setDataFollowList(res.data.open_leads.open_leads);
            setLoading(false)
          }
        } catch (error) {}
      }, [idOA, data]) 
      
  return (
    <>
      {
        data?.zaloOA?.length > 0 ?
        <div className='zalochat-wrapper'>
      <div className='box-chat-listing'>
        <ZaloChatOaSidebar 
          data = {data}
          setIDOA = {setIDOA}
          onClickLoadDataChat = {getDataFollowList}
          setSelectedOA={setSelectedOA}
          selectedOA={selectedOA}
        />
        <ZaloChatSidebar 
            data = {dataFollowList}
            selected={selected}
            setSelected={setSelected}
            setOpen={setOpen}
            setOpenSidebar={setOpenSidebar}
            openSidebar={openSidebar}
            setLoading={setLoading}
        />
      </div>
        {
            open ? 
            <>
            <ZaloChat
            item={selected} 
            idOA={idOA}
            openSidebar={openSidebar}
            setOpenSidebar={setOpenSidebar}
            loading={loading}
            setLoading={setLoading}
        /> 
            </>:
            <>
            <ZaloWelcome />
            </>
        }
       
    </div>
    : <Typography>Cần kết nối ZaloOA trước</Typography>
      }
    </>
    
  )
}

export default ZaloChatWrapper