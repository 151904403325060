import * as types from "./type"

export const getNewRecurringRevenue = (data) => ({
        type: types.GET_NEW_RECURRING_REVENUE,
        payload: data
})

export const getNewRecurringRevenueSuccess = (data) => ({
    type: types.GET_NEW_RECURRING_REVENUE_SUCCESS,
    payload: data
})

export const getNewRecurringRevenueFail = (data) => ({
    type: types.GET_NEW_RECURRING_REVENUE_FAIL,
    payload: data
})