import {
    Table,
    TableBody,
    TableContainer,
    TableRow,
    Paper,
  } from "@mui/material";
  import React from "react";
  import { withStyles } from "@material-ui/core/styles";
  import { makeStyles } from "@material-ui/core/styles";
  import MuiTableCell from "@material-ui/core/TableCell";
  import MuiTableHead from "@mui/material/TableHead";
  import { renderWithCommand } from "../../../../helpers";
  const useStyles = makeStyles((theme) => ({
    tableBorder: {
      border: "1px solid #d4d8dd",
      borderRadius:'0.5rem',
    },
  }));
  
  const TableCell = withStyles({
    root: {
      borderBottom: "1px solid rgb(235, 237, 242)",
      padding: "10px",
      lineHeight: "18px",
    },
  })(MuiTableCell);
  
  const TableHead = withStyles((theme) => ({
    root: {
      backgroundColor: "rgba(38,60,85,.1)",
    },
  }))(MuiTableHead);
  
  const TableHeaderCell = withStyles((theme) => ({
    root: {
      color: "#516377",
      fontWeight: 700,
      padding: 10,
      fontSize: ".75rem",
      textTransform: "uppercase",
      letterSpacing: "0.8px",
    },
  }))(TableCell);

const TableDetailReport = ({ data }) => {
    const classes = useStyles();
    return (
        <Paper
      sx={{ width: "100%", mb: 2 }}
      elevation={0}
      className={`${classes.tableBorder}`}
    >
        <TableContainer style={{ width: "100%", overflowX: "auto" ,borderRadius:'0.5rem'}}>
            <Table sx={{ minWidth: 700 }} size={"small"}>
                <TableHead>
                    <TableRow>
                        <TableHeaderCell align="left">STT</TableHeaderCell>
                        <TableHeaderCell align="left" style={{ minWidth: 120 }}>
                            Nhân viên
                        </TableHeaderCell>
                        
                        <TableHeaderCell align="left" style={{ minWidth: 150 }}>
                            Tổng đơn hàng
                        </TableHeaderCell>
                        <TableHeaderCell align="left" style={{ minWidth: 135 }}>
                        Tổng đơn hàng đã chốt
                        </TableHeaderCell>
                        <TableHeaderCell align="left" style={{ minWidth: 155 }}>
                        Tổng tiền
                        </TableHeaderCell>
                        <TableHeaderCell align="left" style={{ minWidth: 125 }}>
                        Tổng KM
                        </TableHeaderCell>
                        <TableHeaderCell align="left" style={{ minWidth:125 }}>
                        Tỷ lệ chuyển đổi
                        </TableHeaderCell>
                        
                    </TableRow>
                </TableHead>

                <TableBody>
                    {
                        data.length > 0 ? data?.map((row, index) => {
                            return (
                                <TableRow
                                    hover={true}
                                    key={index}
                                >
                                    <TableCell align="left">{index + 1}</TableCell>
                                    <TableCell align="left">{row?.assigned_fullname}</TableCell>
                                    <TableCell align="left">{row?.total_order}</TableCell>
                                    <TableCell align="left">{row?.total_ordered}</TableCell>
                                    <TableCell align="left">{renderWithCommand(row?.total_money?.total__sum)} VNĐ</TableCell>
                                    <TableCell align="left">{renderWithCommand(row?.total_discount?.discount__sum)} VNĐ</TableCell>
                                    <TableCell align="left">{row?.converts}</TableCell>
                                    
                                </TableRow>
                            )
                        }) : <h1>Không có dữ liệu</h1>
                    }
                </TableBody>
            </Table>
        </TableContainer>
        </Paper>
    );
};

export default TableDetailReport;