import React, { useEffect, useState } from "react";
import {Paper, Grid, Divider, TextField, Box, Autocomplete } from "@mui/material";
import TableReportProduct from "./components/TableReportProduct";
import { useDispatch, useSelector } from "react-redux";
import { getProductsData } from "../../redux/products/action";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";
import { DateRangePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { makeStyles } from "@material-ui/core/styles";
import { BoxInput } from "../Customer/components/DialogCustomer/styled";
import { CustomButton } from "../../components/Common/CustomButton";
import axiosInstance from "../../components/axiosApi";
import moment from "moment";
import { ChartColumn } from "./components/Chart";

const useStyles = makeStyles((theme) => ({
  btnIcon: {
    fontSize: 16,
  },
  iconMenuItem: {
    marginRight: 10,
  },
  btnGlobal: {
    minWidth: "inherit",
    width: 36,
    height: 36,
    lineHeight: 36,
    padding: 0,

    marginLeft: 5,
  },
  tableBorder: {
    border: "1px solid #d4d8dd",
    borderRadius: "0.5rem",
  },

  boxPhone: {
    display: "flex",
    borderRadius: "0.3rem",
    padding: "3px 5px",
    color: "#5a8dee",
    alignItems: "center",
    backgroundColor: "#e5edfc",
    cursor: "pointer",
  },
  Pagination: {
    display: "flex",
  },
}));

const ReportProduct = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const stateProducts = useSelector((state) => state.productReducer);

  const [dataReportTable, setDataReport] = useState([]);
  const [products, setProducts] = useState([]);

    useEffect(() => {
        dispatch(getProductsData([], ""));
    }, [])
    

  useEffect(() => {
    if (stateProducts?.products.length > 0) {
      const data = stateProducts?.products?.map((item) => ({
        idProduct: item.id,
        title: item.title,
        price: item.price,
      }));
      setProducts(data);
    }
  }, [stateProducts]);

  const [value, setValue] = React.useState([null, null]);

  const initialValueSearch = {
    fromdate: "",
    todate: "",
    product: "",
  };
  const [search, setSearch] = useState(initialValueSearch);

  const handleSearch = () => {
   
    getReportDetail();
    if (openSidebar) setOpenSidebar(false);
  };
  const [openSidebar, setOpenSidebar] = useState(false);

  const handleAutocomplete = (event, newValue) => {
    if (newValue != null) {
      
        let productId = newValue.idProduct
        let updateSearch = { ...search };
        updateSearch.product = productId;
        setSearch(updateSearch);
    }
  };

  const handleClear = () => {
    setSearch({...initialValueSearch})
    setValue([null,null])    
    getReportDetail();
  }

  const getReportDetail = async () => {
    const { product, fromdate, todate } = search;

    let params = "";
    params +=
    product !== "" && product !== undefined
        ? `product=${product}&`
        : "";
    params +=
      fromdate !== "" && fromdate !== undefined ? `fromdate=${fromdate}&` : "";
    params += todate !== "" && todate !== undefined ? `todate=${todate}&` : "";
    try {
      const res = await axiosInstance.get(`/report/report-product?${params}`);
      if (res.status === 200) {
        setDataReport(res.data.data);
      }
    } catch (error) {}
  };

  useEffect(() => {
    getReportDetail();
  }, []);

    // chart

    const getProduct = () => {
      const data = {
        labels: [],
        quantity: [],
        total:[],
      };
      dataReportTable?.map((item) => {
        data.labels.push(item?.name);
        data.quantity.push(item?.quantity?.quantity__sum);
        data.total.push(item?.total);
        return data
      });
      return data;
    };
  
    const dataProduct = {
      title: "Biểu đồ đơn hàng",
      labels: getProduct().labels,
      datasets: [
        {
          barPercentage: 0.5,
          barThickness: 20,
          base: 0,
          maxBarThickness: 30,
          // minBarLength: 2,
          data: getProduct().quantity,
          backgroundColor: "rgba(90, 141, 238, 0.85)",
          borderColor: "rgba(90, 141, 238, 0.85)",
          borderWidth: 4,
          label: "Tổng đơn hàng" // <-- pass this 
        },
        {
          barPercentage: 0.5,
          barThickness: 20,
          base: 0,
          maxBarThickness: 30,
          // minBarLength: 2,
          data: getProduct().total,
          backgroundColor: "rgba(46, 216, 182, 0.85)",
          borderColor: "rgba(46, 216, 182, 0.85)",
          borderWidth: 4,
          label: "Đơn hoàn thành" // <-- pass this 
        },
      ],
    };
    const getTotal = () => {
      const data = {
        labels: [],
        price: [],
      };
      dataReportTable?.map((item) => {
        data.labels.push(item?.name);
        data.price.push(item?.price * item?.quantity?.quantity__sum);
        return data
      });
      return data;
    };
  
    const dataTotal = {
      title: "Tổng doanh thu",
      labels: getTotal().labels,
      datasets: [
        {
          barPercentage: 0.5,
          barThickness: 20,
          base: 0,
          maxBarThickness: 30,
          // minBarLength: 2,
          data: getTotal().price,
          backgroundColor: "rgba(90, 141, 238, 0.85)",
          borderColor: "rgba(90, 141, 238, 0.85)",
          borderWidth: 4,
          label: "Doanh thu" // <-- pass this 
        },
      ],
    };
  return (
    <div className="screen">
      

      <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={12}>
          <Paper className="order-list" style={{marginBottom:15}}>
            <div className="ul-heading-placeholder">
              <input style={{ display: "none" }} id={""} defaultValue={""} />
              <span
                className="ul-heading"
                style={{
                  display: "flex",
                  alignItems: "center",
                  fontWeight: 500,
                }}
              >
                Báo cáo sản phẩm
              </span>
              <div className="box-right">
                <CustomButton
                  variant="contained"
                  className={`${classes.btnGlobal} ${classes.btnBlue}`}
                  onClick={() => setOpenSidebar(!openSidebar)}
                >
                  <FilterAltIcon
                    style={{ width: "1rem", height: "1rem" }}
                    className={`${classes.btnIcon}`}
                  />
                </CustomButton>
                <div
                  className={`boxsearch-sidebar ${openSidebar ? "active" : ""}`}
                >
                  <span
                    className="ul-heading"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      fontWeight: 500,
                    }}
                  >
                    <ArrowBackIosNewOutlinedIcon
                      style={{
                        color: "#5a8dee",
                        marginRight: 5,
                        cursor: "pointer",
                      }}
                      onClick={() => setOpenSidebar(!openSidebar)}
                    />
                    Tìm kiếm
                  </span>
                  <Divider sx={{ display: { xs: "block", sm: "none" } }} />
                  <Grid
                    container
                    rowSpacing={1}
                    columnSpacing={{ xs: 1, sm: 1.5 }}
                    style={{ padding: "0 14px" }}
                  >
                    <Grid item xs={12}>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DateRangePicker
                          inputFormat="dd/MM/yyyy"
                          startText="Từ ngày"
                          endText="Đến ngày"
                          value={value}
                          onChange={(newValue) => {
                            setValue(newValue);
                            let fromdate = moment(newValue[0]).format(
                              "YYYY-MM-DD"
                            );
                            let todate = moment(newValue[1]).format(
                              "YYYY-MM-DD"
                            );

                            let updateSearch = { ...search };
                            updateSearch.fromdate = fromdate;
                            updateSearch.todate = todate;
                            setSearch(updateSearch);
                          }}
                          renderInput={(startProps, endProps) => (
                            <>
                              <TextField
                                {...startProps}
                                size="small"
                                fullWidth
                                style={{ marginTop: 12 }}
                              />
                              <Box sx={{ mx: 1 }}> - </Box>
                              <TextField
                                {...endProps}
                                size="small"
                                fullWidth
                                style={{ marginTop: 12 }}
                              />
                            </>
                          )}
                        />
                      </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12}>
                    <BoxInput style={{ maxWidth: "100%"}}>
            <Autocomplete
              options={products}
              sx={{
                "& .MuiAutocomplete-inputFocused": {
                  marginTop: "-18px",
                },
                width: "100%",
                marginTop: "10px",
              }}
              getOptionLabel={(option) => option.title}
              
              renderInput={(params) => {
                return (
                  <TextField
                    {...params}
                    label="Chọn sản phẩm"
                    variant="outlined"
                    fullWidth
     
                  />
                );
              }}
              onChange={handleAutocomplete}
            ></Autocomplete>
                    </BoxInput>
                     
                    </Grid>
                    <Grid item xs={12}>
                    <div style={{display:'flex'}}>

                    <CustomButton
                        style={{ width: "40px", height: "40px" }}
                        variant="contained"
                        className="search-row-button"
                        onClick={handleSearch}
                      >
                        Tìm kiếm
                      </CustomButton>
                      <CustomButton style={{width:'40px',height:'40px',marginLeft:'10px'}}
                        variant="contained"
                        className="search-row-button"
                        onClick={handleClear}
                      >
                      Xoá Bộ Lọc
                    </CustomButton>
                    </div>

                    </Grid>
                  </Grid>
                </div>
              </div>
            </div>
          </Paper>
          <Paper className="order-list">
                        <TableReportProduct data={dataReportTable} /> 

          </Paper>
        </Grid>
      </Grid>
      <Paper className="order-list" style={{ marginBottom: "20px" }}>
        <span
          className="ul-heading"
          style={{ display: "flex", alignItems: "center", fontWeight: 500 }}
        >
          Biểu đồ
        </span>
</Paper>
<Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>

<Grid item xs={12} sm={6} >
        <Paper className="order-list">
        <ChartColumn data={dataProduct} />
        </Paper>
   
        </Grid>
        <Grid item xs={12} sm={6} >
        <Paper className="order-list">
        <ChartColumn data={dataTotal} />
        </Paper>
   
        </Grid>

</Grid>



    </div>
  );
};

export default ReportProduct;
