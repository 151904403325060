import * as types from "./type"

export const getDebtRevenue = (data) => ({
        type: types.GET_DEBT_REVENUE,
        payload: data
})

export const getDebtRevenueSuccess = (data) => ({
    type: types.GET_DEBT_REVENUE_SUCCESS,
    payload: data
})

export const getDebtRevenueFail = (data) => ({
    type: types.GET_DEBT_REVENUE_FAIL,
    payload: data
})