import { all, call } from "redux-saga/effects";
import { watchingAddCustomer } from "./add-customers/saga";
import { watchingGetListAddressFilter } from "./address/saga";
import { watchingGetListCityData } from "./city/saga";
import { watchingGetCustomers } from "./customers/saga";
import { watchingLeadStatus } from "./sourceLead/saga";
import { watchingGetLogoFooter } from "./logoFooter/saga";
import { watchingGetProducts } from "./products/saga";
import { watchingGetSourceData } from "./sourceData/saga";
import { watchingStatusLead } from "./statusLead/saga";
import { watchingGetTrangThaiData } from "./trangThaiData/saga";
import { watchingGetUsers } from "./users/saga";
import { watchingLeadStatusId } from "./sourceLeadId/saga";
import { watchingUpdateUsers } from "./org/saga";
import { watchingDepartments } from "./Departments/saga";
import { watchingDepartmentsId } from "./departmentsId/saga";
import { watchingStatusLeadId } from "./statusLeadId/saga";
import { watchingCompanies } from "./company/saga";
import { watchingOrders } from "./order/saga";
import { watchingCredits } from "./credit/saga";
import { watchingGetNewRevenue } from "./newRevenue/saga";
import { watchingGetNewRecurringRevenue } from "./recurringRevenue/saga";
import { watchingGetDebtRevenue } from "./debtRevenue/saga";
import { watchingReports } from "./report/saga";
import { watchingCdr } from "./cdr/saga";
import { watchingModule } from "./module/saga";
import { watchingPermissions } from "./Permissions/saga";
import { watchingPbx } from "./pbx/saga";
import { watchingSmsConfig } from "./smsconfig/saga";
import { watchingSmstemplate } from "./smstemplate/saga";
import { watchingSmsbot } from "./smsbots/saga";
import { watchingSmscamp} from "./smscamp/saga";
import { watchingCallstatus} from "./callstatus/saga";
import { watchingTicket } from "./ticket/saga";
import { watchingSmshistory } from "./smshistory/saga";
// call saga nào thi định nghĩa ở đây
export default function* rootSaga() {
  yield all([
    watchingGetListAddressFilter(),
    watchingAddCustomer(),
    watchingGetCustomers(),
    watchingGetUsers(),
    watchingGetTrangThaiData(),
    watchingGetListCityData(),
    watchingGetSourceData(),
    watchingGetProducts(),
    watchingGetLogoFooter(),
    watchingLeadStatus(),
    watchingStatusLead(),
    watchingLeadStatusId(),
    watchingUpdateUsers(),
    watchingDepartments(),
    watchingStatusLeadId(),
    watchingDepartmentsId(),
    watchingGetNewRevenue(),
    watchingGetNewRecurringRevenue(),
    watchingGetDebtRevenue(),
    watchingCompanies(),
    watchingOrders(),
    watchingCredits(),
    watchingReports(),
    watchingCdr(),
    watchingModule(),
    watchingPermissions(),
    watchingPbx(),
    watchingSmsConfig(),
    watchingSmstemplate(),
    watchingSmsbot(),
    watchingSmscamp(),
    watchingCallstatus(),
    watchingTicket(),
    watchingSmshistory()
  ]);
}
