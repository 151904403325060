import { put, takeLatest } from "redux-saga/effects";
import axiosInstance from "../../components/axiosApi";
import { getSourceDataError, getSourceDataSuccess } from "./action";
import { GET_SOURCE_DATA_START} from "./type";

function* getSourceData() {
    try {
        const res = yield axiosInstance.get(`/leads/sources/`)
        yield put(getSourceDataSuccess(res.data.sources))
    } catch (error) {
        yield put(getSourceDataError(error))
    }
}

export function* watchingGetSourceData() {
    yield takeLatest(GET_SOURCE_DATA_START, getSourceData);
}