/* eslint-disable array-callback-return */
import { put, takeLatest } from "redux-saga/effects";
import axiosInstance from "../../components/axiosApi";
import { showMessageSuccess } from "../notification/action";
import {
  createUserError,
  createUserSuccess,
  getUserError,
  getUserSuccess,
  updateUserError,
  updateUserSuccess,
  updateRegisterCall,
  updateUnregisterCall
} from "./action";
import { CREATE_USERS_START, GET_USERS_START, UPDATE_USERS, UPDATE_REGISTER_START } from "./type";

function* getUsers() {
  try {
    const res = yield axiosInstance.get(`/auth/users/`);
    yield put(getUserSuccess(res.data.active_users));
  } catch (error) {
    yield put(getUserError(error));
  }
}
function* createUsers({ payload }) {
  try {
    const item = payload;
    let queryBuilder = "";
    Object.keys(item).map((key, idx) => {
      if (item[key]) {
        queryBuilder =
          queryBuilder + `${idx === 0 ? key : "&" + key}=${encodeURIComponent(item[key])}`;
      }
    });
    const res = yield axiosInstance.post(`/auth/users/?${queryBuilder}`);
    yield put(createUserSuccess(res.data));
    yield put(showMessageSuccess("Thêm thành công"));
  } catch (error) {
    yield put(createUserError(error));
  }
}

function* updateUser({ payload }) {
  try {
    const item = payload;
    let queryBuilder = "";
    Object.keys(item).map((key, idx) => {
      if (item[key] && key !== "id") {
        queryBuilder =
          queryBuilder + `${idx === 0 ? key : "&" + key}=${encodeURIComponent(item[key])}`;
      }
    });
    const res = yield axiosInstance.put(
      `/users/${payload.id}/?${queryBuilder}`
    );
    yield put(updateUserSuccess(res.data));
    yield put(showMessageSuccess("Chỉnh sửa user thành công"));
  } catch (error) {
    yield put(updateUserError(error));
  }
}

function* updateRegister({ payload }) {
  if (payload) {
    yield put(updateRegisterCall());
  } else {
    yield put(updateUnregisterCall());
  }
}

export function* watchingGetUsers() {
  yield takeLatest(GET_USERS_START, getUsers);
  yield takeLatest(CREATE_USERS_START, createUsers);
  yield takeLatest(UPDATE_USERS, updateUser);
  yield takeLatest(UPDATE_REGISTER_START, updateRegister);
}
