import * as types from "./type";

export const getCredits = (data) => ({
  type: types.GET_CREDITS,
  payload: data,
});
export const getCreditsSuccess = (data) => ({
  type: types.GET_CREDITS_SUCCESS,
  payload: data,
});
export const getCreditsFail = (data) => ({
  type: types.GET_CREDITS_FAIL,
  payload: data,
});
// update credit

export const updateCredit = (data) => ({
  type: types.UPDATE_CREDIT,
  payload: data,
});

export const updateCreditSuccess = (data) => ({
  type: types.UPDATE_CREDIT_SUCCESS,
  payload: data,
});
export const updateCreditFail = (data) => ({
  type: types.UPDATE_CREDIT_FAIL,
  payload: data,
});

// create credit

export const createCredit = (data) => ({
  type: types.CREATE_CREDIT,
  payload: data,
});

export const createCreditSuccess = (data) => ({
  type: types.CREATE_CREDIT_SUCCESS,
  payload: data,
});
export const createCreditFail = (data) => ({
  type: types.CREATE_CREDIT_FAIL,
  payload: data,
});

export const resetMessage = (data) => ({
  type: types.RESET_MESSAGE,
  payload: data,
});

export const resetMessageError = (data) => ({
  type: types.RESET_MESSAGE_ERROR,
  payload: data,
});
