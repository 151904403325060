import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { red } from '@mui/material/colors';
import { useFormik } from "formik";
import React, { memo, useEffect, useState } from "react";
import * as yup from "yup";
import { BoxInput, TitleInput } from "./styled";
import { useDispatch, useSelector } from "react-redux";


import { getUserStart } from "../../../../redux/users/action";

import AssignTo from "../../../../components/Common/AssignTo";
import { ItemGrid } from "./styled";

import { CustomButton } from "../../../../components/Common/CustomButton";

import Tooltip from "@mui/material/Tooltip";
import { IconPhone } from "./styled";
import { startCall } from "../../../../redux/callPhone/action";
import { createCredit, getCredits } from "../../../../redux/credit/action";

import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  helperText: {
    marginLeft:0,
    
  },
}));
const color = red[500];

const PAID_STATUS = [
  { value: true, label: "Đã thanh toán" },
  { value: false, label: "Chưa thanh toán" },
];

const APPROVED_STATUS = [
  { value: true, label: "Đã kiểm duyệt" },
  { value: false, label: "Chưa kiểm duyệt" },
];

const DiaLogCredit = ({
  setCreateOpen,
  detailCredit,
  dataPagination,
  setDetailCredit,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const state = useSelector((state) => state.CreditReducer);

  const validationSchema = yup.object({
    order_no: yup.string().required("Bạn chưa nhập mã đơn hàng"),
    amount: yup.number().required("Bạn chưa nhập số tiền nợ"),
    duedate:yup
    .date()
    .nullable()
    .required("Bạn chưa nhập ngày thanh toán")
  });

  const formik = useFormik({
    initialValues: {
      amount: detailCredit?.amount,
      order_no: detailCredit?.order_no,
      duedate: null,
      paid: "",
      is_approved: "",
      approved_date: null,
      assigned_to: detailCredit?.assigned_to?.id ?? "",
      ref_code: detailCredit?.ref_code,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const creditValues = {
        ...values,
        duedate: values.duedate
          ? moment(values.duedate).format("DD/MM/YYYY")
          : "",
        approved_date: values.approved_date
          ? moment(values.approved_date).format("DD/MM/YYYY")
          : "",
      };
      await dispatch(createCredit(creditValues));
      setCreateOpen(false);
      setDetailCredit(null);
      await dispatch(getCredits(dataPagination));
    },
    validateOnChange: false,
    validateOnBlur: false,
  });

  const handleChangeInput = (event) => {
    const { name, value } = event.target;
    formik.setFieldValue(name, value);
  };

  const handleChangeAssign = (event, newValue) => {
    if (newValue != null) {
      formik.setFieldValue("assigned_to", newValue.id);
    }
  }

  const renderInput = (
    title,
    name,
    placeholder,
    value,
    handleChange,
    handleBlur,
    error,
    errorMessage,
    disabled = false,
    isPhone = false
  ) => (
    <BoxInput style={{ position: "relative" }}>
      <TitleInput>{title} <span style={{color:color}}>*</span></TitleInput>
      <TextField
        id={name}
        name={name}
        size="small"
        fullWidth
        placeholder={placeholder}
        helperText={errorMessage}
        value={
          !isPhone
            ? value
            : disabled && value?.replace(value.substr(4, 6), "******")
        }
        onChange={handleChange}
        onBlur={handleBlur}
        error={error}
        disabled={disabled}
        FormHelperTextProps={{ classes: { root: classes.helperText } }}
      />
      {isPhone && (
        <Tooltip title="Gọi ngay số này" arrow>
          <IconPhone
            style={{ bottom: 2, marginRight: "5px" }}
            onClick={() => handleCall(formik.values.phone)}
            className="phone-icon"
          >
            <svg
              xmlns="http:www.w3.org/2000/svg"
              aria-hidden="true"
              role="img"
              width="26px"
              height="26px"
              preserveAspectRatio="xMidYMid meet"
              viewBox="0 0 1024 1024"
            >
              <path
                fill="currentColor"
                d="M885.6 230.2L779.1 123.8a80.83 80.83 0 0 0-57.3-23.8c-21.7 0-42.1 8.5-57.4 23.8L549.8 238.4a80.83 80.83 0 0 0-23.8 57.3c0 21.7 8.5 42.1 23.8 57.4l83.8 83.8A393.82 393.82 0 0 1 553.1 553A395.34 395.34 0 0 1 437 633.8L353.2 550a80.83 80.83 0 0 0-57.3-23.8c-21.7 0-42.1 8.5-57.4 23.8L123.8 664.5a80.89 80.89 0 0 0-23.8 57.4c0 21.7 8.5 42.1 23.8 57.4l106.3 106.3c24.4 24.5 58.1 38.4 92.7 38.4c7.3 0 14.3-.6 21.2-1.8c134.8-22.2 268.5-93.9 376.4-201.7C828.2 612.8 899.8 479.2 922.3 344c6.8-41.3-6.9-83.8-36.7-113.8z"
              />
            </svg>
          </IconPhone>
        </Tooltip>
      )}
    </BoxInput>
  );

  useEffect(() => {
    dispatch(getUserStart());
    // dispatch(getTrangThaiData())
  }, []);

  const handleCall = (phone) => {
    dispatch(startCall(phone));
  };

  return (
    <div>
      {/* <Loading loading={state.loading} /> */}
      <form onSubmit={formik.handleSubmit}>
        <ItemGrid style={{ marginBottom: 12, boxShadow:"none", padding:0 }}>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 1.5}}
          >
            <Grid item xs={12} sm={6}>
            {renderInput(
                "Mã đơn hàng",
                "order_no",
                "Nhập mã đơn hàng",
                formik.values.order_no,
                formik.handleChange,
                formik.handleBlur,
                formik.touched.order_no && Boolean(formik.errors.order_no),
                formik.touched.order_no && formik.errors.order_no
              )}
            </Grid>
            <Grid item xs={12} sm={6}>
              

              {renderInput(
                "Số tiền nợ",
                "amount",
                "Nhập số tiền nợ",
                formik.values.amount,
                formik.handleChange,
                formik.handleBlur,
                formik.touched.amount && Boolean(formik.errors.amount),
                formik.touched.amount && formik.errors.amount
              )}
              {/* {renderInput(
                "Nhân viên phụ trách",
                "assigned_to",
                "Nhập nhân viên phụ trách",
                formik.values.assigned_to,
                formik.handleChange,
                formik.touched.assigned_to && Boolean(formik.errors.assigned_to)
              )} */}
              
              {/* <FastField name="phone" placeholder="Weezy" /> */}
            </Grid>
            <Grid item xs={12} sm={6}>
            {renderInput(
                "Mã chứng từ",
                "ref_code",
                "Nhập mã chứng từ",
                formik.values.ref_code,
                formik.handleChange,
                formik.handleBlur,
              )}
            </Grid>
            <Grid item xs={12} sm={6}>
              <BoxInput>
                <TitleInput>Trạng thái trả nợ</TitleInput>
                <FormControl fullWidth>
                  <InputLabel id="paid-status">Trạng thái trả nợ</InputLabel>
                  <Select
                    style={{ fontSize: 14 }}
                    labelId="paid"
                    fullWidth
                    size="small"
                    label="Trạng thái trả nợ"
                    name="paid"
                    value={formik.values.paid}
                    onChange={handleChangeInput}
                  >
                    {PAID_STATUS.map((paid, index) => (
                      <MenuItem key={index} value={paid.value}>
                        {paid.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </BoxInput>
             
            </Grid>
            <Grid item xs={12} sm={6}>
            
            <BoxInput>
            <TitleInput>Người phân công</TitleInput>
                <AssignTo
                  value={formik.values.assigned_to}
                  onChange={handleChangeAssign}
                />
              </BoxInput>
            </Grid>
            <Grid item xs={12} sm={6}>
            
            <BoxInput>
            <TitleInput>Trạng thái phê duyệt</TitleInput>
                <FormControl
                  fullWidth
                  style={{
                    borderColor: "#ffffff",
                    color: "#fffffff",
                  }}
                >
                  <InputLabel id="approve-status">
                    Trạng thái phê duyệt
                  </InputLabel>
                  <Select
                    style={{ fontSize: 14 }}
                    fullWidth
                    labelId="is_approved"
                    label="Trạng thái phê duyệt"
                    size="small"
                    name="is_approved"
                    value={formik.values.is_approved}
                    onChange={handleChangeInput}
                  >
                    {APPROVED_STATUS.map((item, index) => (
                      <MenuItem key={index} value={item.value}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </BoxInput>
              </Grid>
            <Grid item xs={12} sm={6}>
          <BoxInput>
          <TitleInput >Ngày phê duyệt </TitleInput>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DesktopDatePicker
                    label="Ngày phê duyệt"
                    onChange={(value) =>
                      formik.setFieldValue("approved_date", value)
                    }
                    format="DD/MM/YYYY"
                    value={formik.values.approved_date}
                    renderInput={(params) => <TextField {...params} fullWidth style={{marginLeft:"auto"}}/>}
                  />
                </LocalizationProvider>
              </BoxInput>
          </Grid>
          <Grid item xs={12} sm={6}>
          
            <BoxInput>
            <TitleInput >Ngày cần thanh toán <span style={{color:color}}>*</span></TitleInput>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DesktopDatePicker
                    label="Ngày cần thanh toán"
                    className={"date-picker-error"}
                    name="duedate"
                    value={formik.values.duedate}
                    onChange={(value) => formik.setFieldValue("duedate", value)}
                    renderInput={(params) => 
                      <TextField {...params} 
                        error={Boolean(formik.touched.duedate && formik.errors.duedate)}
                        helperText={formik.touched.duedate && formik.errors.duedate}
                        FormHelperTextProps={{ classes: { root: classes.helperText } }}
                        fullWidth

                      />}
                      
                    />
                </LocalizationProvider>
              </BoxInput>
          </Grid>
          </Grid>
          
        </ItemGrid>

        <div style={{ display: "flex", justifyContent: "left", marginTop: 15 }}>
          <CustomButton
            // color="primary"
            variant="contained"
            type="submit"
            onClick={formik.handleSubmit}
            style={{marginLeft:'auto'}}
          >
            Thêm mới công nợ
          </CustomButton>
        </div>
      </form>
    </div>
  );
};

export default memo(DiaLogCredit);
