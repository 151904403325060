import React, { useState, useEffect, useRef } from "react";

import blank from "../../assets/images/mail.jpg";
import { Typography } from "@mui/material";
import { useContext } from "react";
import { CountContext } from "../Notification/NotifyContent";
import Linkify from "linkify-react";
import { useNavigate } from "react-router";

const Notification = () => {
  const navigate = useNavigate();

  const dataContext = useContext(CountContext);

  const dataNotify = dataContext.dataNotify;

  const options = {
    /* ... */
  };
  return (
    <>
      <h3 className="heading">Thông báo</h3>
      {dataNotify?.length > 0 ? (
        <div className="notify-list">
          {dataNotify &&
            dataNotify.map((item, index) => (
              <div
                className="item"
                key={index}
                onClick={() => {
                  dataContext.handleGetInfoMsg(item);
                  navigate("/facebook");
                }}
              >
                <div className="photo">
                  <img src={blank} alt="" />
                </div>
                <div className="info">
                  <div className="source">{item.source}</div>
                  <div className="username">{item.name}</div>
                  <Linkify className="text-msg" tagName="p">
                    {item.content}
                  </Linkify>

                  <span
                    className="btn-del"
                    onClick={() => dataContext.handleDel(item.id)}
                  >
                    <i
                      className={`fas fa-check-double ${
                        item.status === "false" ? "" : "color-gray"
                      }`}
                    ></i>
                  </span>
                  {item.status === "false" ? (
                    <div className="status"></div>
                  ) : null}
                </div>
              </div>
            ))}
        </div>
      ) : (
        <Typography>Không có thông báo.</Typography>
      )}
    </>
  );
};

export default Notification;
