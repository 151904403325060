import React, { useEffect } from 'react'
import fb_logo from "../../assets/images/fb_avatar.jpg";
const FacebookChatSidebar = ({setExpand,dataNotify,openSidebar,setOpen,setOpenSidebar,data,selected, setSelected, setSelectedPart, setUserChat, setLoading}) => {
    const result = data?.some(item => item.participants.data[0].id === dataNotify.sender);
    const getT_id = data?.filter(item => item.participants.data[0].id === dataNotify.sender);
    
    useEffect(() => {
      if(result === true){
        setSelected(getT_id[0].id)
        setSelectedPart(dataNotify.sender)

        setOpen(true)
        setOpenSidebar(!openSidebar)
        setLoading(true)
        setExpand(true)
      }
    }, [result])
    

    const handleSelected = (item) => {
        setSelected(item)
        setOpen(true)
        setOpenSidebar(!openSidebar)
        setLoading(true)
        setExpand(true)
    }
  return (
    <>
    <h3 className="heading">Hội thoại gần đây</h3>
    {
      !data ? 'Ko có tài khoản liên kết trong Fanpage' :
      <>
      {data && 
        data.length > 0 &&
        data.map((item,index) => (
            <div className={`item ${selected === item?.id ? 'bg-blue' : ''}`} 
                key={index}
                onClick={()=>{
                        handleSelected(item?.id)
                        setSelectedPart(item?.participants.data[0].id)
                        setUserChat(item?.participants?.data[0].name)
                        }

                    }
            >
                <div className="photo">
                    <img src={fb_logo} alt='logo' />
                </div>
                <div className="info">
                <div className="username">{item?.participants?.data[0].name}</div>
 
                </div>
            </div>
        ))}
      </>
    }
    
</>
  )
}

export default FacebookChatSidebar