import * as types from './type'

const initialState = {
    data: [],
    loading: false,
}

export const dataDebtRevenueReducer = (state = initialState, action) => {

    switch (action.type) {

        case types.GET_DEBT_REVENUE: 
            return {
                ...state,
                ...{ loading: true }
            }
        
        case types.GET_DEBT_REVENUE_SUCCESS:
            return {
                ...state,
                ...{
                    data: action.payload.data,
                    loading: false
                }
            }
        
        case types.GET_DEBT_REVENUE_FAIL:
            {
                return {
                    ...state,
                    ...{ data: [], loading: false }
                }
            }
        default:
            return state
    }
}