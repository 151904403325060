export const GET_SMSBOT = "GET_SMSBOT"
export const GET_SMSBOT_SUCCESS = "GET_SMSBOT_SUCCESS"
export const GET_SMSBOT_FAIL = "GET_SMSBOT_FAIL"

export const CREATE_SMSBOT = "CREATE_SMSBOT"
export const CREATE_SMSBOT_SUCCESS = "CREATE_SMSBOT_SUCCESS"
export const CREATE_SMSBOT_FAIL = "CREATE_SMSBOT_FAIL"

export const RESET_MESSAGE = "RESET_MESSAGE"
export const RESET_MESSAGE_ERROR = "RESET_MESSAGE_ERROR"

export const EDIT_SMSBOT = "EDIT_SMSBOT"
export const EDIT_SMSBOT_SUCCESS = "EDIT_SMSBOT_SUCCESS"
export const EDIT_SMSBOT_FAIL = "EDIT_SMSBOT_FAIL"

export const DELETE_SMSBOT = "DELETE_SMSBOT"
export const DELETE_SMSBOT_SUCCESS = "DELETE_SMSBOT_SUCCESS"
export const DELETE_SMSBOT_FAIL = "DELETE_SMSBOT_FAIL"