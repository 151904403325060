
import * as types from "./type"

export const getSmsconfig = (data) => ({
  type: types.GET_SMSCONFIG,
  payload: data
})
export const getSmsconfigSuccess = (data) => ({
  type: types.GET_SMSCONFIG_SUCCESS,
  payload: data
})
export const getSmsconfigFail = (data) => ({
  type: types.GET_SMSCONFIG_FAIL,
  payload: data
})

// for create company

export const createSmsconfig = (data) => ({
  type: types.CREATE_SMSCONFIG,
  payload: data
})

export const createSmsconfigSuccess = (data) => ({
  type: types.CREATE_SMSCONFIG_SUCCESS,
  payload: data
})
export const createSmsconfigFail = (data) => ({
  type: types.CREATE_SMSCONFIG_FAIL,
  payload: data
})



export const resetMessage = (data) => ({
  type: types.RESET_MESSAGE,
  payload: data
})

export const resetMessageError = (data) => ({
  type: types.RESET_MESSAGE_ERROR,
  payload: data
})

//EDIT
export const editSmsconfig = (data) => ({
  type: types.EDIT_SMSCONFIG,
  payload: data
})
export const editSmsconfigSuccess = (data) => ({
  type: types.EDIT_SMSCONFIG_SUCCESS,
  payload: data
})
export const editSmsconfigFail = (data) => ({
  type: types.EDIT_SMSCONFIG_FAIL,
  payload: data
})