import { put, takeLatest } from "redux-saga/effects";
import * as types from "./type";
import * as actions from "./action";
import axiosInstance from "../../components/axiosApi";
import { showMessageError, showMessageSuccess } from "../notification/action";

function* getCredits(action) {
  try {
    const res = yield axiosInstance.get(`/sales/credits/`, {
      params: action.payload,
    });
    yield put(actions.getCreditsSuccess(res.data));
  } catch (error) {
    yield put(actions.getCreditsFail(error));
  }
}

function* updateCredit(action) {
  try {
    const item = action.payload;
    let queryBuilder = "";
    Object.keys(item).map((key, idx) => {
      if (item[key]) {
        queryBuilder =
          queryBuilder + `${idx === 0 ? key : "&" + key}=${item[key]}`;
      }
    });
    const res = yield axiosInstance.put(`/sales/credits/?${queryBuilder}`);
    yield put(actions.updateCreditSuccess(res.data));
    yield put(showMessageSuccess("Cập nhật thành công"));
  } catch (error) {
    yield put(actions.updateCreditFail(error));
    yield put(showMessageError("Thêm mới thất bại"));
  }
}

function* createCredit(action) {
  try {
    const res = yield axiosInstance.post(`/sales/credits/`, action.payload);
    yield put(actions.createCreditSuccess(res.data));
    yield put(showMessageSuccess("Thêm mới thành công"));
  } catch (error) {
    yield put(actions.createCreditFail(error));
    yield put(showMessageError("Cập nhật thất bại"));
  }
}

function* resetMessage() {
  yield put(actions.resetMessageError({}));
}

export function* watchingCredits() {
  yield takeLatest(types.GET_CREDITS, getCredits);
  yield takeLatest(types.UPDATE_CREDIT, updateCredit);
  yield takeLatest(types.CREATE_CREDIT, createCredit);
  yield takeLatest(types.RESET_MESSAGE, resetMessage);
}
