import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Customer from "../Customer";
import Company from "../Configuration/components/Company";
import Extension from "../Configuration/components/Extension/Extension";
import CallStatus from "../Configuration/components/CallStatus/CallStatus";
import Cdr from "../Configuration/components/Cdr";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import AssessmentOutlinedIcon from "@mui/icons-material/AssessmentOutlined";
import ManageSearchOutlinedIcon from "@mui/icons-material/ManageSearchOutlined";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import TextsmsOutlinedIcon from "@mui/icons-material/TextsmsOutlined";

import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import LogoutIcon from "@mui/icons-material/Logout";
import Badge from "@mui/material/Badge";
import SupportAgentOutlinedIcon from "@mui/icons-material/SupportAgentOutlined";
import NoteAddRoundedIcon from "@mui/icons-material/NoteAddOutlined";

import MainPanel from "../../MainPanel";
import Snackbar from "@mui/material/Snackbar";
import CloseIcon from "@mui/icons-material/Close";
import Avatar from "@mui/material/Avatar";

import PaymentIcon from "@mui/icons-material/Payment";

import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import ListAltIcon from "@mui/icons-material/ListAlt";
import $ from "jquery";
import jQuery from "jquery";

import Backdrop from "@mui/material/Backdrop";

import OrderManagenent from "../Order";
import PowerIcon from "@mui/icons-material/Power";
import PowerOffIcon from "@mui/icons-material/PowerOff";
import Report from "../Report";
import Configuration from "../Configuration";
import CallCenter from "../CallCenter";
import EmployeeManager from "../Configuration/components/EmployeeManager";
import WareHouse from "../Configuration/components/WareHouse";
import { useDispatch, useSelector } from "react-redux";
import { getLogoFooter } from "../../redux/logoFooter/action";
import OrderStatus from "../Configuration/components/OrderStatus";

import StatusLead from "../Configuration/components/StatusLead";
import CampaignOutlinedIcon from "@mui/icons-material/CampaignOutlined";
import PermPhoneMsgOutlinedIcon from "@mui/icons-material/PermPhoneMsgOutlined";

import Departments from "../Configuration/components/Departments";
import Staff from "../Configuration/components/Staff";
import TestCall from "../../components/Common/PhoneCall";
import PhoneBox from "../../components/Global/PhoneBox";
import CreditManagement from "../Credit";
import { UserProfile } from "../User/user";
import ZaloLayout from "../Zalo";
import FacebookLayout from "../Facebook/Facebook";
import CallHistory from "../Call/CallHistory";
import SmsConfig from "../Sms/SmsConfig";
import SmsTemplate from "../Sms/SmsTemplate";
import SmsSend from "../Sms/SmsSend";
import SmsBots from "../Sms/SmsBots";
import SmsCamp from "../Sms/SmsCamp";
import SmsLayout from "../Sms/SmsLayout";
import TicketLayout from "../Ticket/TicketLayout";
import TicketCreate from "../Ticket/TicketCreate";
import ReportProduct from "../Report/ReportProduct";
import ReportCall from "../Report/ReportCall";
import useOutsideClick from "../../hook/useOutsideClick";
import { useParams } from "react-router-dom";
import {
  updateUnregisterCall,
  updateRegisteringCall,
} from "../../redux/users/action";
import Notify from "../Notification/Notify";
import NotifyTicket from "../Notification/NotifyTicket";
import logo from "./../../assets/images/zalo.png";
import { BoxBody, PhoneRegisterText } from "./styled";

const URL = `${process.env.REACT_APP_API_URL}`;

export default function Dashboard() {
  const [theme, setTheme] = useState(false);
  const { phoneNumber } = useParams();
  const [open, setOpen] = useState(false);
  const [openMarketing, setOpenMarketing] = useState(false);
  const [openReport, setOpenReport] = useState(false);
  const [openAutoCall, setOpenAutoCall] = useState(false);
  const [openCallBot, setOpenCallBot] = useState(false);
  const [openTicket, setOpenTicket] = useState(false);
  const [ticketIcon, setTicketIcon] = useState(false);

  const [selected, setSelected] = useState(0);
  const [openNS, setOpenNS] = React.useState(false);
  const [networkStatus, setNetworkStatus] = React.useState(false);
  const [timeoutNS, setTimeoutNS] = React.useState(null);
  const [openManage, setOpenManage] = React.useState(false);
  const [menuIsOpened, setMenuIsOpened] = React.useState(true);
  const [path, setPath] = useState();

  const dataLogoFooter = useSelector((item) => item.dataLogoFooterRuducer.data);
  const state = useSelector((state) => state.callPhoneReducer);
  const [showPhone, setShowPhone] = useState(false);
  const [showHistoryUser, setShowHistoryUser] = useState(false);
  const [showDialogCall, setShowDialogCall] = useState(false);
  const [disablePhone, setDisablePhone] = useState(true);

  const dropdownRef = useRef(null);
  const [openDrp, setOpenDrp] = useState(false);
  useOutsideClick(dropdownRef, () => {
    if (openDrp) setOpenDrp(false);
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const profile = useSelector((item) => item.dataLogoFooterRuducer.data);
  const dataUser = useSelector((item) => item.UsersReducer);

  useEffect(() => {
    if (process.env.NODE_ENV !== "development") {
      setTheme(true);
      document.body.classList.toggle("org-theme", theme);
    }
  }, [theme]);

  useEffect(() => {
    if (profile.length === 0) {
      return;
    }
    if (Object.keys(profile).length !== 0 && profile.agent) {
      setDisablePhone(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile]);

  useEffect(() => {
    dispatch(updateUnregisterCall());
  }, []);

  const handleClick = () => {
    setOpen((prev) => !prev);
  };
  const handleClickMarketing = () => {
    setOpenMarketing((prev) => !prev);
  };
  const handleClickReport = () => {
    setOpenReport((prev) => !prev);
  };
  const handleClickAutoCall = () => {
    setOpenAutoCall((prev) => !prev);
  };
  const handleClickCallBot = () => {
    setOpenCallBot((prev) => !prev);
  };
  const handleClickTicket = () => {
    setOpenTicket((prev) => !prev);
  };

  // const handleManageClick = () => {
  //   setOpenManage(!openManage);
  //   if ($(".side-menu").width() == 54) {
  //     setMenuIsOpened(false);
  //   }
  //   setTimeout(() => {
  //     $(".upper-menu").hasScrollBar() === false
  //       ? $("#bottom-menu-divider").attr("hidden", "")
  //       : $("#bottom-menu-divider").removeAttr("hidden");
  //   }, 300);
  //   if (openManage === false && menuIsOpened === false) {
  //     toggleMenu();
  //   }
  // };

  const Menu = {
    config: { id: 1, path: "configuration" },
    report: { id: 2, path: "report" },
    customerManagement: { id: 3, path: "data-list" },
    dashboard: { id: 4, path: "dashboard" },
    company: { id: 5, path: "company" },
    orderManagement: { id: 6, path: "order-management" },
    creditManagement: { id: 7, path: "credit-management" },

    employeeManagement: { id: 10, path: "employee-manager" },
    productList: { id: 11, path: "ware-house" },
    sourceOrder: { id: 12, path: "source-lead" },
    statusOrderCanWatch: { id: 13, path: "status-lead" },
    marketing: { id: 9, path: "marketing" },
    facebook: { id: 99, path: "facebook" },
    department: { id: 14, path: "departments" },
    userAccount: { id: 15, path: "staff" },
    setting: { id: -1, path: "setting" },
    reportCdr: { id: 103, path: "report-cdr" },
    reportProduct: { id: 104, path: "report-product" },
    reportCall: { id: 154, path: "report-call" },
    callcen: { id: 8, path: "callcenter" },
    userProfile: { id: 900, path: "user-profile" },

    zalo: { id: 20, path: "zalo" },
    extension: { id: 21, path: "extension" },
    sms: { id: 9, path: "sms" },
    smsconfig: { id: 31, path: "smsconfig" },
    smstemplate: { id: 32, path: "smstemplate" },
    smssend: { id: 33, path: "smssend" },
    smsbots: { id: 34, path: "sms-bots" },
    smscamp: { id: 35, path: "sms-camp" },
    ticket: { id: 36, path: "ticket" },
    createticket: { id: 37, path: "create-ticket" },
    callstatus: { id: 40, path: "callstatus" },
    callhistory: { id: 22, path: `/callhistory/${phoneNumber}` },
  };

  const isHasAccess = (idFuntion) => {
    const ROLE = {
      ADMIN: "ADMIN",
      USER: "USER",
    };
    const FULL_HAS_ACCESS = Object.keys(Menu).map((key) => {
      return Menu[key].id;
    });

    if (dataLogoFooter) {
      const isAdmin = dataLogoFooter.role === ROLE.ADMIN;
      const isStaff = dataLogoFooter.is_staff;
      const hasAccess = dataLogoFooter.position?.has_access[0].module_page.map(
        (item) => {
          return item.unique_id;
        }
      );
      if (isAdmin || isStaff)
        return FULL_HAS_ACCESS.findIndex((e) => e === idFuntion) >= 0;

      if (
        hasAccess?.includes(Menu.sms.id) &&
        !hasAccess?.includes(Menu.customerManagement.id)
      ) {
        let newHasAccess = [
          ...hasAccess,
          Menu.smscamp.id,
          Menu.smsbots.id,
          Menu.facebook.id,
          Menu.zalo.id,
        ];
        return newHasAccess?.findIndex((e) => e === idFuntion) >= 0;
      } else if (
        hasAccess?.includes(Menu.customerManagement.id) &&
        !hasAccess?.includes(Menu.sms.id)
      ) {
        let newHasAccess = [...hasAccess, Menu.callhistory.id];
        return newHasAccess?.findIndex((e) => e === idFuntion) >= 0;
      } else if (hasAccess?.includes(Menu.sms.id, Menu.customerManagement.id)) {
        let newHasAccess = [
          ...hasAccess,
          Menu.smscamp.id,
          Menu.smsbots.id,
          Menu.facebook.id,
          Menu.zalo.id,
          Menu.callhistory.id,
        ];
        return newHasAccess?.findIndex((e) => e === idFuntion) >= 0;
      } else {
        return hasAccess?.findIndex((e) => e === idFuntion) >= 0;
      }
      
    } else return false;
  };

  const handleSetSelected = (condition, item) => {
    if (condition === item.path && isHasAccess(item.id)) {
      setSelected(item.id);
    }
  };

  const callbackFunction = (childData) => {
    setPath(childData);
    // handleSetSelected(childData, Menu.callhistory);
    // handleSetSelected(childData, Menu.ticket);
    // handleSetSelected(childData, Menu.createticket);
    // handleSetSelected(childData, Menu.zalo);
    // handleSetSelected(childData, Menu.userProfile);
    // handleSetSelected(childData, Menu.setting);
    // handleSetSelected(childData, Menu.callcen);
    // handleSetSelected(childData, Menu.Departments);
    // handleSetSelected(childData, Menu.facebook);
    // handleSetSelected(childData, Menu.facebook);
    // handleSetSelected(childData, Menu.marketing);
    // handleSetSelected(childData, Menu.creditManagement);
    // handleSetSelected(childData, Menu.orderManagement);
    // handleSetSelected(childData, Menu.customerManagement);
    // handleSetSelected(childData, Menu.report);
    // handleSetSelected(childData, Menu.config);
    handleSetSelected(childData, Menu.employeeManagement);
    handleSetSelected(childData, Menu.productList);
    handleSetSelected(childData, Menu.sourceOrder);
    handleSetSelected(childData, Menu.statusOrderCanWatch);
    handleSetSelected(childData, Menu.department);
    handleSetSelected(childData, Menu.userAccount);
    handleSetSelected(childData, Menu.reportCdr);
    handleSetSelected(childData, Menu.company);
    handleSetSelected(childData, Menu.extension);
    handleSetSelected(childData, Menu.callhistory);
    handleSetSelected(childData, Menu.smsconfig);
    handleSetSelected(childData, Menu.smstemplate);
    handleSetSelected(childData, Menu.smssend);
    handleSetSelected(childData, Menu.smsbots);
    handleSetSelected(childData, Menu.smscamp);
    handleSetSelected(childData, Menu.callstatus);
  };

  const handleNavigate = () => {
    const hasAccess = dataLogoFooter.position?.has_access[0].module_page.map(
      (item) => {
        return item.unique_id;
      }
    );
    if (
      window.location.pathname.split("/")[1] === Menu.dashboard.path &&
      hasAccess?.findIndex((e) => e !== Menu.dashboard.id) >= 0
    ) {
      Object.keys(Menu).map((key) => {
        if (Menu[key].id === hasAccess[0]) {
          const path = Menu[key].path;
          setSelected(Menu[key].id);

          window.history.pushState(null, "", path);
        }
      });
    }
  };

  useEffect(() => {
    dispatch(getLogoFooter());
  }, []);
  // console.log(Menu.callhistory.path)
  useEffect(() => {
    if (dataLogoFooter) {
      if (window.location.pathname === Menu.callhistory.path) {
        hanleSetSelectMulti(window.location.pathname, false);
      } else {
        hanleSetSelectMulti(window.location.pathname.split("/")[1], false);
      }

      handleNavigate();
    }
  }, [dataLogoFooter, window.location.pathname]);

  const hanleSetSelectMulti = (condition, isOnPopState) => {
    if (!isOnPopState) {
      Object.keys(Menu).map((key) => {
        const item = Menu[key];
        handleSetSelected(condition, item);
        if (condition === key) {
          $(".side-menu").width() != 54 ?? setOpenManage(true);
        }
      });
    } else {
      Object.keys(Menu).map((key) => {
        const item = Menu[key];
        handleSetSelected(condition, item);
        if (
          (key === Menu.dashboard.path ||
            key === Menu.customerManagement.path) &&
          condition === key
        ) {
          $(".side-menu").width() != 54 ?? setOpenManage(true);
        }
      });
    }
  };

  const handleHistoryPush = () => {
    Object.keys(Menu).map((key) => {
      if (selected === Menu[key].id && isHasAccess(Menu[key].id)) {
        window.history.pushState(null, "", Menu[key].label);
      }
    });
  };
  useEffect(() => {
    async function checkToken() {
      try {
        // await axiosInstance.get("/auth/users/");
        if (localStorage.getItem("access_token") === null) {
          window.location.href = "/login";
        }
      } catch (err) {
        //console.log(err);
      }
    }
    checkToken();
    (function ($) {
      $.fn.hasScrollBar = function () {
        return this.get(0).scrollHeight > this.height();
      };
    })(jQuery);
    $(".upper-menu").hasScrollBar() === false
      ? $("#bottom-menu-divider").attr("hidden", "")
      : $("#bottom-menu-divider").removeAttr("hidden");
    if (document.documentElement.clientWidth < 1100) {
      setMenuIsOpened(false);
      setOpenManage(false);
    }
    document.getElementById("year").innerHTML = new Date().getFullYear();
    window.onresize = () => {
      $(".upper-menu").hasScrollBar() === false
        ? $("#bottom-menu-divider").attr("hidden", "")
        : $("#bottom-menu-divider").removeAttr("hidden");
      if (document.documentElement.clientWidth < 1100) {
        setMenuIsOpened(false);
        setOpenManage(false);
        document.querySelector(".side-menu").classList.remove("expand");
        document.querySelector(".side-menu").classList.remove("force-expand");
      } else {
        setMenuIsOpened(!menuIsOpened);
      }
      if ($(".side-menu").width() == 280) {
        setMenuIsOpened(true);
      } else {
        document.querySelector("#backdrop").classList.remove("display");
        setMenuIsOpened(false);
      }
      if ($(".side-menu").width() == 54) {
        setMenuIsOpened(false);
      }
    };

    hanleSetSelectMulti(window.location.pathname.split("/")[1], false);

    handleHistoryPush();
    window.onpopstate = function (event) {
      hanleSetSelectMulti(window.location.pathname.split("/")[1], true);
    };
    window.addEventListener("load", function () {
      this.navigator.onLine === false && updateOnlineStatus();
      function updateOnlineStatus(event) {
        var condition = navigator.onLine
          ? "Đã khôi phục kết nối"
          : "Bạn đang ngoại tuyến";
        if (condition === "Bạn đang ngoại tuyến") {
          setTimeoutNS(null);
        } else {
          setTimeoutNS(6000);
        }
        setOpenNS(true);
        setNetworkStatus(condition);
      }
      window.addEventListener("online", updateOnlineStatus);
      window.addEventListener("offline", updateOnlineStatus);
    });
  }, []);

  const toggleMenu = () => {
    if ($(".side-menu").width() == 54) {
      setMenuIsOpened(false);
      document.querySelector("#copyright-placeholder").classList.add("expand");
    }
    menuIsOpened && setOpenManage(false);
    setMenuIsOpened(!menuIsOpened);
    document.querySelector(".side-menu").classList.toggle("close");
    document.querySelector(".side-menu").classList.toggle("expand");
    document.querySelector(".side-menu").classList.toggle("force-expand");
    document.querySelector("#menu-icon").classList.toggle("show");
    document.querySelector("#copyright-placeholder").classList.toggle("expand");
    if (document.documentElement.clientWidth < 1100) {
      document.querySelector("#backdrop").classList.toggle("display");
      setMenuIsOpened(!menuIsOpened);
    }
  };

  useEffect(() => {
    if (menuIsOpened === true) {
      setTicketIcon(false);
    } else {
      setTicketIcon(true);
    }
  }, [menuIsOpened]);
  // console.log(menuIsOpened, ticketIcon);
  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={() => setOpenNS(false)}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const handleRegistering = () => {
    dispatch(updateRegisteringCall());
  };
  const handleUnRegister = () => {
    dispatch(updateUnregisterCall());
  };
  return (
    <>
      <BoxBody style={{ height: "100%" }}>
        <div className="content">
          <Snackbar
            open={openNS}
            autoHideDuration={timeoutNS}
            onClose={() => setOpenNS(false)}
            message={networkStatus}
            action={action}
            ClickAwayListenerProps={{ onClickAway: () => null }}
            elevation={0}
          />
          <Backdrop
            id="backdrop"
            open={true}
            sx={{ color: "#fff", zIndex: 3 }}
            style={{ display: "none" }}
            onClick={toggleMenu}
          />
          <div
            className="side-menu expand"
            style={{
              border: "1px solid #ebedf2",
              borderLeft: "none",
              borderTop: "none",
              background:
                process.env.NODE_ENV === "development"
                  ? "#fff"
                  : process.env.REACT_APP_PRODUCT_BG_SIDEBAR_COLOR,
            }}
          >
            <div className="logo" style={{ cursor: "pointer" }}>
              <div
                className="logo-img"
                // src={require("./assets/images/logo.png")}
                // src={`http://103.252.1.222:8000${dataLogoFooter?.profile_pic}`}
                onClick={() => (window.location.href = "/")}
                alt=""
                style={
                  dataLogoFooter?.org?.logo
                    ? {
                        backgroundImage: `url(${process.env.REACT_APP_API_URL}${dataLogoFooter?.org?.logo})`,
                        minWidth: 30,
                      }
                    : {
                        backgroundImage: `url(${require("./../../assets/images/logo.png")})`,
                        filter: "brightness(0) invert(0.25)",
                        minWidth: 30,
                      }
                }
              />
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <h1
                  onClick={() => (window.location.href = "/")}
                  style={{
                    minWidth: 160,
                    maxWidth: 160,
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    display: "block",
                    fontWeight: 500,
                  }}
                >
                  {dataLogoFooter?.org?.code}
                </h1>
              </div>
            </div>

            <div className="upper-menu">
              <List>
                {isHasAccess(Menu.dashboard.id) && (
                  <ListItem disablePadding>
                    <ListItemButton
                      selected={selected === Menu.dashboard.id}
                      onClick={() => {
                        setSelected(Menu.dashboard.id);
                        window.history.pushState({}, "", "dashboard");
                      }}
                    >
                      <ListItemIcon style={{ minWidth: 42 }}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-6 w-6"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          strokeWidth="2"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
                          />
                        </svg>
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <Typography
                            type="body2"
                            className="name-sidebar"
                            style={{
                              color:
                                process.env.NODE_ENV === "development"
                                  ? "#707287"
                                  : process.env.REACT_APP_PRODUCT_TEXT_COLOR,
                              fontSize: "0.875rem",
                            }}
                          >
                            Trang tổng quan
                          </Typography>
                        }
                      />
                    </ListItemButton>
                  </ListItem>
                )}
                {isHasAccess(Menu.customerManagement.id) && (
                  <ListItem disablePadding>
                    <ListItemButton
                      selected={selected === Menu.customerManagement.id}
                      onClick={() => {
                        setSelected(Menu.customerManagement.id);
                        navigate("/data-list");
                      }}
                    >
                      <ListItemIcon
                        style={{
                          minWidth: 42,
                          color:
                            process.env.NODE_ENV === "development"
                              ? "#707287"
                              : process.env.REACT_APP_PRODUCT_TEXT_COLOR,
                        }}
                      >
                        <SupportAgentOutlinedIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <Typography
                            type="body2"
                            style={{
                              color:
                                process.env.NODE_ENV === "development"
                                  ? "#707287"
                                  : process.env.REACT_APP_PRODUCT_TEXT_COLOR,
                              fontSize: "0.875rem",
                            }}
                          >
                            Chăm sóc khách hàng
                          </Typography>
                        }
                      />
                    </ListItemButton>
                  </ListItem>
                )}
                {isHasAccess(Menu.orderManagement.id) && (
                  <ListItem disablePadding>
                    <ListItemButton
                      selected={selected === Menu.orderManagement.id}
                      onClick={() => {
                        setSelected(Menu.orderManagement.id);
                        navigate("/order-management");
                      }}
                    >
                      <ListItemIcon
                        style={{
                          minWidth: 42,
                          color:
                            process.env.NODE_ENV === "development"
                              ? "#707287"
                              : process.env.REACT_APP_PRODUCT_TEXT_COLOR,
                        }}
                      >
                        <PaymentIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <Typography
                            type="body2"
                            style={{
                              color:
                                process.env.NODE_ENV === "development"
                                  ? "#707287"
                                  : process.env.REACT_APP_PRODUCT_TEXT_COLOR,
                              fontSize: "0.875rem",
                            }}
                          >
                            Quản lý đơn hàng
                          </Typography>
                        }
                      />
                    </ListItemButton>
                  </ListItem>
                )}
                {isHasAccess(Menu.creditManagement.id) && (
                  <ListItem disablePadding>
                    <ListItemButton
                      selected={selected === Menu.creditManagement.id}
                      onClick={() => {
                        setSelected(Menu.creditManagement.id);
                        navigate("/credit-management");
                      }}
                    >
                      <ListItemIcon
                        style={{
                          minWidth: 42,
                          color:
                            process.env.NODE_ENV === "development"
                              ? "#707287"
                              : process.env.REACT_APP_PRODUCT_TEXT_COLOR,
                        }}
                      >
                        <ListAltIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <Typography
                            type="body2"
                            style={{
                              color:
                                process.env.NODE_ENV === "development"
                                  ? "#707287"
                                  : process.env.REACT_APP_PRODUCT_TEXT_COLOR,
                              fontSize: "0.875rem",
                            }}
                          >
                            Quản lý công nợ
                          </Typography>
                        }
                      />
                    </ListItemButton>
                  </ListItem>
                )}

                {isHasAccess(Menu.report.id) && (
                  <>
                    <ListItem disablePadding>
                      <ListItemButton
                        onClick={() => {
                          handleClickReport();
                        }}
                      >
                        <ListItemIcon
                          style={{
                            minWidth: 42,
                            color:
                              process.env.NODE_ENV === "development"
                                ? "#707287"
                                : process.env.REACT_APP_PRODUCT_TEXT_COLOR,
                          }}
                        >
                          <ContentPasteIcon />
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            <Typography
                              type="body2"
                              style={{
                                color:
                                  process.env.NODE_ENV === "development"
                                    ? "#707287"
                                    : process.env.REACT_APP_PRODUCT_TEXT_COLOR,
                                fontSize: "0.875rem",
                              }}
                            >
                              Báo cáo
                            </Typography>
                          }
                        />
                        {openReport ? (
                          <ExpandLess style={{ fontSize: "1.2rem" }} />
                        ) : (
                          <ExpandMore style={{ fontSize: "1.2rem" }} />
                        )}
                      </ListItemButton>
                    </ListItem>
                    <Collapse in={openReport} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding>
                        <ListItemButton
                          style={{ paddingLeft: ticketIcon ? "16px" : "50px" }}
                          selected={selected === Menu.report.id}
                          onClick={() => {
                            setSelected(Menu.report.id);
                            navigate("/report");
                          }}
                        >
                          <ListItemIcon
                            style={{
                              minWidth: 42,
                              color:
                                process.env.NODE_ENV === "development"
                                  ? "#707287"
                                  : process.env.REACT_APP_PRODUCT_TEXT_COLOR,
                            }}
                          >
                            <AssessmentOutlinedIcon />
                          </ListItemIcon>

                          <ListItemText
                            primary={
                              <Typography
                                type="body2"
                                style={{
                                  color:
                                    process.env.NODE_ENV === "development"
                                      ? "#707287"
                                      : process.env
                                          .REACT_APP_PRODUCT_TEXT_COLOR,
                                  fontSize: "0.875rem",
                                }}
                              >
                                Báo cáo tổng quan
                              </Typography>
                            }
                          />
                        </ListItemButton>

                        <ListItemButton
                          style={{ paddingLeft: ticketIcon ? "16px" : "50px" }}
                          selected={selected === Menu.reportProduct.id}
                          onClick={() => {
                            setSelected(Menu.reportProduct.id);
                            navigate("/report-product");
                          }}
                        >
                          <ListItemIcon
                            style={{
                              minWidth: 42,
                              color:
                                process.env.NODE_ENV === "development"
                                  ? "#707287"
                                  : process.env.REACT_APP_PRODUCT_TEXT_COLOR,
                            }}
                          >
                            <AssessmentOutlinedIcon />
                          </ListItemIcon>

                          <ListItemText
                            primary={
                              <Typography
                                type="body2"
                                style={{
                                  color:
                                    process.env.NODE_ENV === "development"
                                      ? "#707287"
                                      : process.env
                                          .REACT_APP_PRODUCT_TEXT_COLOR,
                                  fontSize: "0.875rem",
                                }}
                              >
                                Báo cáo sản phẩm
                              </Typography>
                            }
                          />
                        </ListItemButton>

                        <ListItemButton
                          style={{ paddingLeft: ticketIcon ? "16px" : "50px" }}
                          selected={selected === Menu.reportCall.id}
                          onClick={() => {
                            setSelected(Menu.reportCall.id);
                            navigate("/report-call");
                          }}
                        >
                          <ListItemIcon
                            style={{
                              minWidth: 42,
                              color:
                                process.env.NODE_ENV === "development"
                                  ? "#707287"
                                  : process.env.REACT_APP_PRODUCT_TEXT_COLOR,
                            }}
                          >
                            <AssessmentOutlinedIcon />
                          </ListItemIcon>

                          <ListItemText
                            primary={
                              <Typography
                                type="body2"
                                style={{
                                  color:
                                    process.env.NODE_ENV === "development"
                                      ? "#707287"
                                      : process.env
                                          .REACT_APP_PRODUCT_TEXT_COLOR,
                                  fontSize: "0.875rem",
                                }}
                              >
                                Báo cáo cuộc gọi
                              </Typography>
                            }
                          />
                        </ListItemButton>
                      </List>
                    </Collapse>
                  </>
                )}

                {isHasAccess(Menu.marketing.id) && (
                  <>
                    <ListItem disablePadding>
                      <ListItemButton
                        onClick={() => {
                          handleClickMarketing();
                        }}
                      >
                        <ListItemIcon
                          style={{
                            minWidth: 42,
                            color:
                              process.env.NODE_ENV === "development"
                                ? "#707287"
                                : process.env.REACT_APP_PRODUCT_TEXT_COLOR,
                          }}
                        >
                          <CampaignOutlinedIcon />
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            <Typography
                              type="body2"
                              style={{
                                color:
                                  process.env.NODE_ENV === "development"
                                    ? "#707287"
                                    : process.env.REACT_APP_PRODUCT_TEXT_COLOR,
                                fontSize: "0.875rem",
                              }}
                            >
                              Marketing
                            </Typography>
                          }
                        />
                        {openMarketing ? (
                          <ExpandLess style={{ fontSize: "1.2rem" }} />
                        ) : (
                          <ExpandMore style={{ fontSize: "1.2rem" }} />
                        )}
                      </ListItemButton>
                    </ListItem>

                    <Collapse in={openMarketing} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding>
                        {process.env.REACT_APP_ENABLE_FACEBOOK === "true" ? (
                          <>
                            <ListItemButton
                              style={{
                                paddingLeft: ticketIcon ? "16px" : "50px",
                              }}
                              selected={selected === Menu.facebook.id}
                              onClick={() => {
                                setSelected(Menu.facebook.id);
                                navigate("/facebook");
                              }}
                            >
                              <ListItemIcon
                                style={{
                                  minWidth: 42,
                                  color:
                                    process.env.NODE_ENV === "development"
                                      ? "#707287"
                                      : process.env
                                          .REACT_APP_PRODUCT_TEXT_COLOR,
                                }}
                              >
                                <FacebookOutlinedIcon />
                              </ListItemIcon>

                              <ListItemText
                                primary={
                                  <Typography
                                    type="body2"
                                    style={{
                                      color:
                                        process.env.NODE_ENV === "development"
                                          ? "#707287"
                                          : process.env
                                              .REACT_APP_PRODUCT_TEXT_COLOR,
                                      fontSize: "0.875rem",
                                    }}
                                  >
                                    Facebook
                                  </Typography>
                                }
                              />
                            </ListItemButton>
                          </>
                        ) : null}

                        <ListItemButton
                          selected={selected === Menu.zalo.id}
                          style={{ paddingLeft: ticketIcon ? "16px" : "50px" }}
                          onClick={() => {
                            setSelected(Menu.zalo.id);
                            navigate("/zalo");
                          }}
                        >
                          <ListItemIcon
                            style={{
                              minWidth: 42,
                              color:
                                process.env.NODE_ENV === "development"
                                  ? "#707287"
                                  : process.env.REACT_APP_PRODUCT_TEXT_COLOR,
                            }}
                          >
                            <img src={logo} alt="zalo-img" />
                          </ListItemIcon>

                          <ListItemText
                            primary={
                              <Typography
                                type="body2"
                                style={{
                                  color:
                                    process.env.NODE_ENV === "development"
                                      ? "#707287"
                                      : process.env
                                          .REACT_APP_PRODUCT_TEXT_COLOR,
                                  fontSize: "0.875rem",
                                }}
                              >
                                Zalo
                              </Typography>
                            }
                          />
                        </ListItemButton>
                        <ListItemButton
                          style={{ paddingLeft: ticketIcon ? "16px" : "50px" }}
                          selected={selected === Menu.sms.id}
                          onClick={() => {
                            setSelected(Menu.sms.id);
                            navigate("/sms");
                          }}
                        >
                          <ListItemIcon
                            style={{
                              minWidth: 42,
                              color:
                                process.env.NODE_ENV === "development"
                                  ? "#707287"
                                  : process.env.REACT_APP_PRODUCT_TEXT_COLOR,
                            }}
                          >
                            <TextsmsOutlinedIcon />
                          </ListItemIcon>

                          <ListItemText
                            primary={
                              <Typography
                                type="body2"
                                style={{
                                  color:
                                    process.env.NODE_ENV === "development"
                                      ? "#707287"
                                      : process.env
                                          .REACT_APP_PRODUCT_TEXT_COLOR,
                                  fontSize: "0.875rem",
                                }}
                              >
                                SMS
                              </Typography>
                            }
                          />
                        </ListItemButton>
                      </List>
                    </Collapse>
                  </>
                )}
                {/* {isHasAccess(Menu.autoCall.id) && (
                <>
                  <ListItem disablePadding>
                    <ListItemButton
                      onClick={() => {
                        handleClickAutoCall();

                      }}
                    >
                      <ListItemIcon style={{ minWidth: 42 }}>
                        <CallIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <Typography
                            type="body2"
                            style={{ color: "rgb(112, 114, 135)", fontSize: "0.875rem" }}
                          >
                            Autocall
                          </Typography>
                        }
                      />
                      {openAutoCall ? <ExpandLess style={{ fontSize: '1.2rem' }} /> : <ExpandMore style={{ fontSize: '1.2rem' }} />}
                    </ListItemButton>

                  </ListItem>

                  <Collapse in={openAutoCall} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      <ListItemButton

                      >
                        <ListItemText
                          primary={
                            <Typography
                              type="body2"
                              style={{ color: "rgb(112, 114, 135)", fontSize: "0.875rem", paddingLeft: '42px' }}
                            >
                              Chiến dịch
                            </Typography>
                          }
                        />
                      </ListItemButton>
                      <ListItemButton>
                        <ListItemText
                          primary={
                            <Typography
                              type="body2"
                              style={{ color: "rgb(112, 114, 135)", fontSize: "0.875rem", paddingLeft: '42px' }}
                            >
                              Kịch bản
                            </Typography>
                          }
                        />
                      </ListItemButton>
                      <ListItemButton>
                        <ListItemText
                          primary={
                            <Typography
                              type="body2"
                              style={{ color: "rgb(112, 114, 135)", fontSize: "0.875rem", paddingLeft: '42px' }}
                            >
                              Nguồn dữ liệu
                            </Typography>
                          }
                        />
                      </ListItemButton>

                    </List>
                  </Collapse>
                </>


              )}
              {isHasAccess(Menu.callBot.id) && (
                <>
                  <ListItem disablePadding>
                    <ListItemButton
                      onClick={() => {
                        handleClickCallBot();

                      }}
                    >
                      <ListItemIcon style={{ minWidth: 42 }}>
                        <SmartToyIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <Typography
                            type="body2"
                            style={{ color: "rgb(112, 114, 135)", fontSize: "0.875rem" }}
                          >
                            Callbot
                          </Typography>
                        }
                      />
                      {openCallBot ? <ExpandLess style={{ fontSize: '1.2rem' }} /> : <ExpandMore style={{ fontSize: '1.2rem' }} />}
                    </ListItemButton>

                  </ListItem>

                  <Collapse in={openCallBot} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      <ListItemButton

                      >
                        <ListItemText
                          primary={
                            <Typography
                              type="body2"
                              style={{ color: "rgb(112, 114, 135)", fontSize: "0.875rem", paddingLeft: '42px' }}
                            >
                              Chiến dịch
                            </Typography>
                          }
                        />
                      </ListItemButton>
                      <ListItemButton>
                        <ListItemText
                          primary={
                            <Typography
                              type="body2"
                              style={{ color: "rgb(112, 114, 135)", fontSize: "0.875rem", paddingLeft: '42px' }}
                            >
                              Kịch bản
                            </Typography>
                          }
                        />
                      </ListItemButton>
                      <ListItemButton>
                        <ListItemText
                          primary={
                            <Typography
                              type="body2"
                              style={{ color: "rgb(112, 114, 135)", fontSize: "0.875rem", paddingLeft: '42px' }}
                            >
                              Nguồn dữ liệu
                            </Typography>
                          }
                        />
                      </ListItemButton>

                    </List>
                  </Collapse>
                </>
              )} */}
                {/* {isHasAccess(Menu.company.id) && (
                <ListItem disablePadding>
                  <ListItemButton
                    selected={selected === Menu.company.id}
                    onClick={() => {
                      setSelected(Menu.company.id);
                      window.history.pushState({}, null, "company");
                    }}
                  >
                    <ListItemIcon style={{ minWidth: 42 }}>
                      <CorporateFare />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <Typography
                          type="body2"
                          style={{ color: "rgb(112, 114, 135)",  fontSize:"0.875rem" }}

                        >
                          Công ty
                        </Typography>
                      }
                    />
                  </ListItemButton>
                </ListItem>
              )} */}
                {isHasAccess(Menu.callcen.id) && (
                  <>
                    <ListItem disablePadding>
                      <ListItemButton
                        onClick={() => {
                          handleClick();
                        }}
                      >
                        <ListItemIcon
                          style={{
                            minWidth: 42,
                            color:
                              process.env.NODE_ENV === "development"
                                ? "#707287"
                                : process.env.REACT_APP_PRODUCT_TEXT_COLOR,
                          }}
                        >
                          <PermPhoneMsgOutlinedIcon />
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            <Typography
                              type="body2"
                              style={{
                                color:
                                  process.env.NODE_ENV === "development"
                                    ? "#707287"
                                    : process.env.REACT_APP_PRODUCT_TEXT_COLOR,
                                fontSize: "0.875rem",
                              }}
                            >
                              Tổng đài
                            </Typography>
                          }
                        />
                        {open ? (
                          <ExpandLess style={{ fontSize: "1.2rem" }} />
                        ) : (
                          <ExpandMore style={{ fontSize: "1.2rem" }} />
                        )}
                      </ListItemButton>
                    </ListItem>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding>
                        <ListItemButton
                          style={{ paddingLeft: ticketIcon ? "16px" : "50px" }}
                          selected={selected === Menu.reportCdr.id}
                          onClick={() => {
                            setSelected(Menu.reportCdr.id);
                            navigate("/report-cdr");
                          }}
                        >
                          <ListItemIcon
                            style={{
                              minWidth: 42,
                              color:
                                process.env.NODE_ENV === "development"
                                  ? "#707287"
                                  : process.env.REACT_APP_PRODUCT_TEXT_COLOR,
                            }}
                          >
                            <ManageSearchOutlinedIcon />
                          </ListItemIcon>

                          <ListItemText
                            primary={
                              <Typography
                                type="body2"
                                style={{
                                  color:
                                    process.env.NODE_ENV === "development"
                                      ? "#707287"
                                      : process.env
                                          .REACT_APP_PRODUCT_TEXT_COLOR,
                                  fontSize: "0.875rem",
                                }}
                              >
                                Lịch sử
                              </Typography>
                            }
                          />
                        </ListItemButton>
                      </List>
                    </Collapse>
                  </>
                )}
                {isHasAccess(Menu.config.id) && (
                  <ListItem disablePadding>
                    <ListItemButton
                      selected={selected === Menu.config.id}
                      onClick={() => {
                        setSelected(Menu.config.id);
                        navigate("/configuration");
                      }}
                    >
                      <ListItemIcon
                        style={{
                          minWidth: 42,
                          color:
                            process.env.NODE_ENV === "development"
                              ? "#707287"
                              : process.env.REACT_APP_PRODUCT_TEXT_COLOR,
                        }}
                      >
                        <SettingsOutlinedIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <Typography
                            type="body2"
                            style={{
                              color:
                                process.env.NODE_ENV === "development"
                                  ? "#707287"
                                  : process.env.REACT_APP_PRODUCT_TEXT_COLOR,
                              fontSize: "0.875rem",
                            }}
                          >
                            Cấu hình
                          </Typography>
                        }
                      />
                    </ListItemButton>
                  </ListItem>
                )}
                {isHasAccess(Menu.ticket.id) && (
                  <>
                    <ListItem disablePadding>
                      <ListItemButton
                        onClick={() => {
                          handleClickTicket();
                        }}
                      >
                        <ListItemIcon
                          style={{
                            minWidth: 42,
                            color:
                              process.env.NODE_ENV === "development"
                                ? "#707287"
                                : process.env.REACT_APP_PRODUCT_TEXT_COLOR,
                          }}
                        >
                          <NoteAddRoundedIcon />
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            <Typography
                              type="body2"
                              style={{
                                color:
                                  process.env.NODE_ENV === "development"
                                    ? "#707287"
                                    : process.env.REACT_APP_PRODUCT_TEXT_COLOR,
                                fontSize: "0.875rem",
                              }}
                            >
                              Phiếu ghi
                            </Typography>
                          }
                        />
                        {openTicket ? (
                          <ExpandLess style={{ fontSize: "1.2rem" }} />
                        ) : (
                          <ExpandMore style={{ fontSize: "1.2rem" }} />
                        )}
                      </ListItemButton>
                    </ListItem>
                    <Collapse in={openTicket} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding>
                        <ListItemButton
                          style={{ paddingLeft: ticketIcon ? "16px" : "50px" }}
                          selected={selected === Menu.ticket.id}
                          onClick={() => {
                            setSelected(Menu.ticket.id);
                            navigate("/ticket");
                          }}
                        >
                          <ListItemIcon
                            style={{
                              minWidth: 42,
                              color:
                                process.env.NODE_ENV === "development"
                                  ? "#707287"
                                  : process.env.REACT_APP_PRODUCT_TEXT_COLOR,
                            }}
                          >
                            <ListAltIcon />
                          </ListItemIcon>

                          <ListItemText
                            primary={
                              <Typography
                                type="body2"
                                style={{
                                  color:
                                    process.env.NODE_ENV === "development"
                                      ? "#707287"
                                      : process.env
                                          .REACT_APP_PRODUCT_TEXT_COLOR,
                                  fontSize: "0.875rem",
                                }}
                              >
                                Danh sách
                              </Typography>
                            }
                          />
                        </ListItemButton>

                        <ListItemButton
                          style={{ paddingLeft: ticketIcon ? "16px" : "50px" }}
                          selected={selected === Menu.createticket.id}
                          onClick={() => {
                            setSelected(Menu.createticket.id);
                            navigate("/create-ticket");
                          }}
                        >
                          <ListItemIcon
                            style={{
                              minWidth: 42,
                              color:
                                process.env.NODE_ENV === "development"
                                  ? "#707287"
                                  : process.env.REACT_APP_PRODUCT_TEXT_COLOR,
                            }}
                          >
                            <AddBoxOutlinedIcon />
                          </ListItemIcon>

                          <ListItemText
                            primary={
                              <Typography
                                type="body2"
                                style={{
                                  color:
                                    process.env.NODE_ENV === "development"
                                      ? "#707287"
                                      : process.env
                                          .REACT_APP_PRODUCT_TEXT_COLOR,
                                  fontSize: "0.875rem",
                                }}
                              >
                                Tạo phiếu ghi
                              </Typography>
                            }
                          />
                        </ListItemButton>
                      </List>
                    </Collapse>
                  </>
                )}
                {/* <ListItem disablePadding>
                <ListItemButton onClick={handleManageClick}>
                  <ListItemIcon>
                    <ManageAccountsIcon />
                  </ListItemIcon>
                  <ListItemText primary="Cấu hình" />
                  {openManage ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
              </ListItem> */}

                {/* <Collapse in={openManage} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItem disablePadding>
                    <ListItemButton
                      sx={{ pl: 4 }}
                      selected={selected === 3}
                      onClick={() => {
                        setSelected(3);
                        window.history.pushState({}, "", "manage-users");
                      }}
                    >
                      <ListItemIcon>
                        <GroupIcon />
                      </ListItemIcon>
                      <ListItemText primary="Người dùng" />
                    </ListItemButton>
                  </ListItem>
                  <ListItem disablePadding>
                    <ListItemButton
                      sx={{ pl: 4 }}
                      selected={selected === 4}
                      onClick={() => {
                        setSelected(4);
                        window.history.pushState({}, "", "manage-groups");
                      }}
                    >
                      <ListItemIcon>
                        <GroupsIcon />
                      </ListItemIcon>
                      <ListItemText primary="Nhóm" />
                    </ListItemButton>
                  </ListItem>
                  <ListItem disablePadding>
                    <ListItemButton
                      sx={{ pl: 4 }}
                      selected={selected === 5}
                      onClick={() => {
                        setSelected(5);
                        window.history.pushState({}, "", "manage-sources");
                      }}
                    >
                      <ListItemIcon>
                        <SourceIcon />
                      </ListItemIcon>
                      <ListItemText primary="Nguồn khách hàng" />
                    </ListItemButton>
                  </ListItem>
                  <ListItem disablePadding>
                    <ListItemButton
                      sx={{ pl: 4 }}
                      selected={selected === 6}
                      onClick={() => {
                        setSelected(6);
                        window.history.pushState({}, "", "manage-status");
                      }}
                    >
                      <ListItemIcon>
                        <BallotIcon />
                      </ListItemIcon>
                      <ListItemText primary="Trạng thái khách hàng" />
                    </ListItemButton>
                  </ListItem>
                </List>
              </Collapse> */}
              </List>
            </div>

            <div className="bottom-menu">
              <Divider id="bottom-menu-divider" />
              <List>
                <ListItem disablePadding style={{ color: "red" }}>
                  <ListItemButton
                    onClick={() => (window.location.href = "/logout")}
                  >
                    <ListItemIcon
                      style={{
                        color:
                          process.env.NODE_ENV === "development"
                            ? "#5a8dee"
                            : process.env.REACT_APP_PRODUCT_BASE_COLOR,
                        minWidth: 42,
                      }}
                    >
                      <LogoutIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <Typography
                          type="body2"
                          style={{
                            fontSize: "0.875rem",
                            color:
                              process.env.NODE_ENV === "development"
                                ? "#5a8dee"
                                : process.env.REACT_APP_PRODUCT_BASE_COLOR,
                          }}
                        >
                          Đăng xuất
                        </Typography>
                      }
                    />
                  </ListItemButton>
                </ListItem>
              </List>
            </div>
          </div>
          <div className="main">
            <AppBar
              position="sticky"
              style={{
                background:
                  process.env.NODE_ENV === "development"
                    ? "#fff"
                    : process.env.REACT_APP_PRODUCT_BG_HEADER_COLOR,
                borderBottom: "1px solid #ebedf2",
                color: "#222",
                fontSize: "1.125rem",
              }}
              elevation={0}
            >
              <Toolbar>
                <IconButton
                  size="large"
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                  sx={{ mr: 2 }}
                  id="menu-icon"
                  onClick={toggleMenu}
                  style={{
                    marginRight: 0,
                    color:
                      process.env.NODE_ENV === "development"
                        ? "#5a8dee"
                        : process.env.REACT_APP_PRODUCT_BASE_COLOR,
                  }}
                >
                  <MenuIcon />
                </IconButton>
                <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                  {dataUser && dataUser.registered === "unregistered" && (
                    <PhoneRegisterText>
                      <div
                        className="flex p-1 text-sm text-yellow-700 bg-yellow-100 rounded-lg dark:bg-yellow-200 dark:text-yellow-800 w-fit"
                        role="alert"
                      >
                        <svg
                          className="inline flex-shrink-0 mr-3 w-5 h-5"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                            clipRule="evenodd"
                          ></path>
                        </svg>
                        <div>
                          {disablePhone
                            ? "Tài khoản chưa được config agent"
                            : "Hãy kết nối tới tổng đài."}
                        </div>
                      </div>
                    </PhoneRegisterText>
                  )}
                  {dataUser && dataUser.registered === "registered" && (
                    <PhoneRegisterText>
                      <div
                        className="flex p-1 text-sm text-green-700 bg-green-100 rounded-lg dark:bg-green-200 dark:text-green-800 w-fit"
                        role="alert"
                      >
                        <svg
                          className="inline flex-shrink-0 mr-3 w-5 h-5"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                            clipRule="evenodd"
                          ></path>
                        </svg>
                        <div>Đã kết nối tới tổng đài.</div>
                      </div>
                    </PhoneRegisterText>
                  )}
                  {/* {selected === Menu.dashboard.id ? "Trang tổng quan" : ""}
                {selected === Menu.customerManagement.id
                  ? "Dữ liệu khách hàng"
                  : ""}
                {selected === Menu.userManagement.id
                  ? "Quản lý người dùng"
                  : ""}
                {selected === Menu.groupManagement.id ? "Quản lý nhóm" : ""}
                {selected === Menu.sourceCustomer.id
                  ? "Quản lý nguồn khách hàng"
                  : ""}
                {selected === Menu.statusCustomer.id
                  ? "Quản lý trạng thái khách hàng"
                  : ""} 
                 {selected === 7 ? "Tài chính - Kế toán" : ""}
                {selected === Menu.report.id ? "Báo cáo" : ""}
                {selected === Menu.config.id ? "Cấu hình " : ""}
                {selected === Menu.employeeManagement.id
                  ? "Quản lý nhân viên "
                  : ""}
                {selected === Menu.productList.id ? "Danh sách sản phẩm  " : ""}
                {selected === Menu.sourceOrder.id ? "Nguồn đơn hàng " : ""}
                {selected === Menu.statusOrderCanWatch.id
                  ? "Trạng thái đơn hàng được xem "
                  : ""}
                {selected === Menu.marketing.id ? "Marketing" : ""}
                {selected === Menu.department.id ? "Phòng ban " : ""}
                {selected === Menu.userAccount.id
                  ? "Tài khoản người dùng "
                  : ""}
                {selected === Menu.setting.id ? "Cài đặt" : ""}
                {selected === Menu.company.id ? "Công ty" : ""}
                {selected === Menu.orderManagement.id ? "Quản lý đơn hàng" : ""}
                {selected === Menu.creditManagement.id ? "Quản lý công nợ" : ""} */}
                </Typography>
                {dataUser && (
                  <>
                    {dataUser.registered === "registered" && (
                      <IconButton
                        size="large"
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        color="error"
                        onClick={() => handleUnRegister()}
                        style={{ backgroundColor: "#fddddd" }}
                      >
                        <Badge
                          badgeContent={0}
                          sx={{
                            "& .MuiBadge-badge": {
                              color: "#fff",
                              backgroundColor: "rgb(244,106,106)",
                            },
                          }}
                        >
                          <PowerOffIcon style={{ color: "red" }} />
                        </Badge>
                      </IconButton>
                    )}
                    {dataUser.registered === "unregistered" && !disablePhone && (
                      <IconButton
                        size="large"
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        color="success"
                        onClick={() => handleRegistering()}
                        style={{ backgroundColor: "rgb(0,188,212)" }}
                      >
                        <Badge
                          badgeContent={0}
                          sx={{
                            "& .MuiBadge-badge": {
                              color: "#fff",
                              backgroundColor: "rgb(244,106,106)",
                            },
                          }}
                        >
                          <PowerIcon style={{ color: "white" }} />
                        </Badge>
                      </IconButton>
                    )}
                  </>
                )}
                <NotifyTicket />
                <Notify />

                <div
                  className="box-drp"
                  style={{ position: "relative" }}
                  ref={dropdownRef}
                >
                  <IconButton
                    size="large"
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    color="inherit"
                    onClick={() => setOpenDrp(!openDrp)}
                  >
                    {dataLogoFooter?.profile_pic ? (
                      <Avatar
                        alt={dataLogoFooter?.username}
                        src={`${URL}${dataLogoFooter?.profile_pic}`}
                      />
                    ) : (
                      <AccountCircleOutlinedIcon
                        style={{
                          color:
                            process.env.NODE_ENV === "development"
                              ? "#5a8dee"
                              : process.env.REACT_APP_PRODUCT_BASE_COLOR,
                        }}
                      />
                    )}
                  </IconButton>
                  <nav className={`menuExpand ${openDrp ? "active" : ""}`}>
                    <div className="box-account-info">
                      <div className="user-name">
                        {dataLogoFooter?.username}{" "}
                      </div>
                      <div className="user-status">{dataLogoFooter?.role} </div>
                    </div>
                    <ul>
                      <li
                        className="menuItem"
                        onClick={() => {
                          setSelected(Menu.userProfile.id);
                          window.history.pushState({}, "", "user-profile");
                        }}
                      >
                        <span>Thông tin cá nhân</span>
                      </li>
                      <li
                        className="menuItem"
                        onClick={() => (window.location.href = "/logout")}
                      >
                        <span>Đăng xuất</span>
                      </li>
                    </ul>
                  </nav>
                </div>
              </Toolbar>
            </AppBar>
            {selected === Menu.dashboard.id ? <MainPanel /> : ""}
            {selected === Menu.customerManagement.id ? (
              <Customer
                dataLogoFooter={dataLogoFooter}
                directSelected={setSelected}
              />
            ) : (
              ""
            )}

            {selected === Menu.userProfile.id ? <UserProfile /> : ""}
            {selected === Menu.zalo.id ? <ZaloLayout /> : ""}
            {selected === Menu.facebook.id ? <FacebookLayout directSelected={setSelected}/> : ""}

            {/* {selected === 7 ? <AccoutingFinancial /> : ""} */}
            {selected === Menu.report.id ? (
              <Report dataLogoFooter={dataLogoFooter} />
            ) : (
              ""
            )}
            {selected === Menu.reportProduct.id ? (
              <ReportProduct dataLogoFooter={dataLogoFooter} />
            ) : (
              ""
            )}
            {selected === Menu.reportCall.id ? (
              <ReportCall dataLogoFooter={dataLogoFooter} />
            ) : (
              ""
            )}
            {selected === Menu.config.id ? (
              <Configuration parentCallback={callbackFunction} />
            ) : (
              ""
            )}
            {selected === Menu.callcen.id ? (
              <CallCenter
                dataLogoFooter={dataLogoFooter}
                parentCallback={callbackFunction}
              />
            ) : (
              ""
            )}

            {selected === Menu.employeeManagement.id ? <EmployeeManager /> : ""}
            {selected === Menu.productList.id ? <WareHouse /> : ""}
            {selected === Menu.sourceOrder.id ? <OrderStatus /> : ""}
            {selected === Menu.statusOrderCanWatch.id ? <StatusLead /> : ""}
            {selected === Menu.department.id ? <Departments /> : ""}
            {selected === Menu.userAccount.id ? <Staff /> : ""}
            {selected === Menu.company.id ? <Company /> : ""}
            {selected === Menu.extension.id ? (
              <Extension dataLogoFooter={dataLogoFooter} />
            ) : (
              ""
            )}
            {selected === Menu.reportCdr.id ? (
              <Cdr dataLogoFooter={dataLogoFooter} />
            ) : (
              ""
            )}
            {selected === Menu.orderManagement.id ? (
              <OrderManagenent dataLogoFooter={dataLogoFooter} />
            ) : (
              ""
            )}
            {selected === Menu.creditManagement.id ? (
              <CreditManagement dataLogoFooter={dataLogoFooter} />
            ) : (
              ""
            )}
            {selected === Menu.callhistory.id ? (
              <CallHistory
                dataLogoFooter={dataLogoFooter}
                parentCallback={callbackFunction}
              />
            ) : (
              ""
            )}
            {selected === Menu.smsconfig.id ? <SmsConfig /> : ""}
            {selected === Menu.smstemplate.id ? <SmsTemplate /> : ""}
            {selected === Menu.sms.id ? (
              <SmsLayout
                parentCallback={callbackFunction}
                dataLogoFooter={dataLogoFooter}
              />
            ) : (
              ""
            )}
            {selected === Menu.smssend.id ? <SmsSend /> : ""}
            {selected === Menu.smsbots.id ? <SmsBots /> : ""}
            {selected === Menu.smscamp.id ? (
              <SmsCamp dataLogoFooter={dataLogoFooter} />
            ) : (
              ""
            )}
            {selected === Menu.ticket.id ? (
              <TicketLayout
                dataLogoFooter={dataLogoFooter}
                directSelected={setSelected}
              />
            ) : (
              ""
            )}
            {selected === Menu.createticket.id ? <TicketCreate /> : ""}
            {selected === Menu.callstatus.id ? <CallStatus /> : ""}
            <div
              id="copyright-placeholder"
              style={{
                position: "fixed",
                bottom: 0,
                padding: "10px 20px",
                backgroundColor:
                  process.env.NODE_ENV === "development"
                    ? "#fff"
                    : process.env.REACT_APP_PRODUCT_BG_FOOTER_COLOR,
                display: "flex",
                boxSizing: "border-box",
                fontSize: "0.875rem",
                color:
                  process.env.NODE_ENV === "development"
                    ? "#707287"
                    : process.env.REACT_APP_PRODUCT_TEXT_COLOR,
              }}
            >
              <span style={{ flex: 1 }}>
                Copyright &copy; <span id="year"></span>&nbsp;
                <a
                  href="http://vntel.vn"
                  style={{
                    textDecoration: "none",
                    fontWeight: "bold",
                    color:
                      process.env.NODE_ENV === "development"
                        ? "#5a8dee"
                        : process.env.REACT_APP_PRODUCT_BASE_COLOR,
                  }}
                  target="_blank"
                  rel="noreferrer"
                >
                  {dataLogoFooter?.org?.name}
                </a>
                <span id="rights">. All rights reserved.</span>
              </span>
              <span id="hotline">
                <i className="fas fa-phone-alt"></i> &nbsp;Hotline hỗ trợ:
                <b>
                  <a
                    href="callto:+84439928886"
                    style={{
                      textDecoration: "none",
                      fontWeight: "bold",
                      color:
                        process.env.NODE_ENV === "development"
                          ? "#5a8dee"
                          : process.env.REACT_APP_PRODUCT_BASE_COLOR,
                    }}
                  >
                    {" "}
                    {dataLogoFooter?.org?.phone !== null
                      ? dataLogoFooter?.org?.phone
                      : "09 xxxx xxxx"}
                  </a>
                </b>
              </span>
            </div>
          </div>
        </div>
        <div
          style={{
            position: "fixed",
            top: 0,
            zIndex: 99,
            right: 20,
            bottom: 0,
          }}
        >
          <div
            style={{
              display: state.numberPhone || showPhone ? "block" : "",
            }}
          >
            {<TestCall setShowPhone={setShowPhone} setShowDialogCall={setShowDialogCall} showDialogCall={showDialogCall} showPhone = {showPhone}/>}
          </div>

          {dataUser &&
          dataUser.registered === "registered" &&
          !disablePhone &&
          !showPhone &&
          !state.numberPhone ? (
            <PhoneBox
              setShowPhone={setShowPhone}
              setShowDialogCall={setShowDialogCall}
              showDialogCall={showDialogCall}
            />
          ) : null}
        </div>
      </BoxBody>
    </>
  );
}
