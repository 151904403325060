
import * as types from "./type"

export const getSmshistory = (data) => ({
  type: types.GET_SMSHISTORY,
  payload: data
})
export const getSmshistorySuccess = (data) => ({
  type: types.GET_SMSHISTORY_SUCCESS,
  payload: data
})
export const getSmshistoryFail = (data) => ({
  type: types.GET_SMSHISTORY_FAIL,
  payload: data
})





export const resetMessage = (data) => ({
  type: types.RESET_MESSAGE,
  payload: data
})

export const resetMessageError = (data) => ({
  type: types.RESET_MESSAGE_ERROR,
  payload: data
})

