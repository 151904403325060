import { Button, Checkbox, Grid, TextField } from '@mui/material';
import React from 'react';
import { BoxInput, TitleInput } from './styled';
import { CustomButton } from "../../../../../components/Common/CustomButton";
import { useFormik } from 'formik';
import * as yup from "yup";
import { useDispatch } from 'react-redux';
import { createDepartments, getDepartments } from '../../../../../redux/Departments/action';
import { red } from "@mui/material/colors";

const colorError = red[500];
const DialogDepartments = ({setUDO}) => {

    const dispatch = useDispatch()

    const validationSchema = yup.object({
        name: yup.string().required('Bạn chưa nhập tên phòng ban'),
    });

    
    const formik = useFormik({
        initialValues: {
            name: "",

        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            dispatch(createDepartments(values));
            dispatch(getDepartments());
            setUDO(false)
        },
    });


    return (
        <form onSubmit={formik.handleSubmit}>
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1}}>
                <Grid item xs={12}>
                    <BoxInput>
                        <TitleInput>Tên phòng ban <span style={{ color: colorError }}>*</span></TitleInput>
                        <TextField
                            id="name"
                            name="name"
                            size="small"
                            fullWidth
                            placeholder="Tên phòng ban"
                            value={formik.values.name}
                            onChange={formik.handleChange}
                            error={formik.touched.name && Boolean(formik.errors.name)}
                        />
                    </BoxInput>
                    <BoxInput>
                    <CustomButton
                    //color="primary"
                    variant="contained"
                    type="submit"
                    onClick={formik.handleSubmit}
                    style={{marginLeft:'auto'}}
                >
                    Thêm mới
                </CustomButton>
                    </BoxInput>
                </Grid>
            </Grid>
        </form>
    );
};

export default DialogDepartments;