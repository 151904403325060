import { put, takeLatest } from "redux-saga/effects";
import * as types from './type';
import * as actions from './action';
import axiosInstance from "../../components/axiosApi";
import { showMessageSuccess } from "../notification/action";

function* getDepartments() {
    try {
        const res = yield axiosInstance.get(`/org/departments/`)
        yield put(actions.getDepartmentsSuccess(res.data))
    } catch (error) {
        yield put(actions.getDepartmentsFail(error))
    }
}

function* createDepartments({payload}) {
    try {
        const res = yield axiosInstance.post(`/org/departments/`, payload)
        yield put(actions.createDepartmentsSuccess(res.data))
        yield put(showMessageSuccess("Thêm phòng ban thành công"))
    } catch (error) {
        yield put(actions.createDepartmentsFail(error))
    }
}

function* editDepartments({payload}) {
    try {
        const res = yield axiosInstance.put(`/org/departments/`, payload)
        yield put(actions.editDepartmentsSuccess(res.data))
        yield put(showMessageSuccess("Cập nhật phòng ban thành công"))
    } catch (error) {
        yield put(actions.editDepartmentsFail(error))
    }
}

export function* watchingDepartments() {
    yield takeLatest(types.GET_DEPARTMENTS, getDepartments);
    yield takeLatest(types.CREATE_DEPARTMENTS, createDepartments);
    yield takeLatest(types.EDIT_DEPARTMENTS, editDepartments);
}