import * as types from "./type";

const initialState = {
  data: [],
  dataList: [],
  loading: false,
  editLoad: false,
};

export const orderReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_ORDERS:
      return {
        ...state,
        ...{ loading: true },
      };

    case types.GET_ORDERS_SUCCESS:
      return {
        ...state,
        ...{
          dataList: action.payload,
          loading: false,
        },
      };

    case types.GET_ORDERS_FAIL: {
      return {
        ...state,
        ...{ dataList: [], loading: false },
      };
    }

    case types.UPDATE_ORDER:
      return {
        ...state,
        ...{ loading: true },
      };

    case types.UPDATE_ORDER_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };

    case types.UPDATE_ORDER_FAIL:
      return {
        ...state,
        loading: false,
        data: action.payload.response.data,
      };
    case types.RESET_MESSAGE_ERROR:
      return {
        ...state,
        loading: false,
        data: [],
      };

    default:
      return state;
  }
};
