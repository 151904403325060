import { put, takeLatest } from "redux-saga/effects";
import * as types from './type';
import * as actions from './action';
import axiosInstance from "../../components/axiosApi";
import { showMessageSuccess } from "../notification/action";

function* getCompanies(action) {
  try {
    const res = yield axiosInstance.get(`/org/`, {
      params: action.payload,
    })
    yield put(actions.getCompaniesSuccess(res.data))
  } catch (error) {
    yield put(actions.getCompaniesFail(error))
  }
}


function* createCompany({ payload }) {
  try {
    const res = yield axiosInstance.post(`/org/`, payload)
    yield put(actions.createCompanySuccess(res.data))
  } catch (error) {
    yield put(actions.createCompanyFail(error))
  }
}

function* editCompany({payload}) {
    let params = ''
    const { address, name, phone, logo ,user_limit, pbx_username, pbx_password, code, hide_number} = payload;
    params += code !== "" && code !== undefined ? `code=${code}&`: ""
    params += phone !== "" && phone !== undefined ? `phone=${phone}&`: ""
    params += name !== "" && name !== undefined ? `name=${name}&`: ""
    params += address !== "" && address !== undefined ? `address=${address}&`: ""
    params += user_limit !== "" && user_limit !== undefined ? `user_limit=${user_limit}&`: ""
    params += pbx_password !== "" && pbx_password !== undefined ? `pbx_password=${encodeURIComponent(pbx_password)}&`: ""
    params += pbx_username !== "" && pbx_username !== undefined ? `pbx_username=${pbx_username}&`: ""
    params += hide_number !== "" && hide_number !== undefined ? `hide_number=${hide_number}&`: ""
    
    try {
        let formData = new FormData()
        if(logo) {
            formData.append("logo", logo)
        }
        const res = yield axiosInstance.put(`/org/${payload.id}/?${params}`, formData);
        yield put(actions.editCompanySuccess(res.data))
        yield put(showMessageSuccess("Cập nhập thành công"))
        
    } catch (error) {
      yield put(actions.editCompanyFail(error))
    }
}

function* createAdminCompany({ payload }) {
  try {
    const res = yield axiosInstance.post(`/org/${payload.id}/`, payload)
    yield put(actions.createAdminCompanySuccess(res.data))
  } catch (error) {
    yield put(actions.createAdminCompanyFail(error))
  }
}

function* resetMessage() {
  yield put(actions.resetMessageError({}))
}

export function* watchingCompanies() {
  yield takeLatest(types.GET_COMPANIES, getCompanies);
  yield takeLatest(types.CREATE_COMPANY, createCompany);
  yield takeLatest(types.CREATE_ADMIN_COMPANY, createAdminCompany);
  yield takeLatest(types.RESET_MESSAGE, resetMessage);
  yield takeLatest(types.EDIT_COMPANY, editCompany);
}
