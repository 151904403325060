import React from "react";
import { Container, Typography } from "@mui/material";
import styled from "styled-components";
const StyledHeader = styled(Typography)`
  font-size: 1rem;
  font-weight: 500;
`;

const Term = () => {
  return (
    <Container>

      <StyledHeader component="h2" gutterBottom>
        Điều 1: QUY ĐỊNH SỬ DỤNG
      </StyledHeader>
      <Typography variant="body1" gutterBottom>
        Là các quy định liên quan đến việc sử dụng dịch vụ của CTCP Viễn Thông
        Tin Học Việt Nam. Với sản phẩm dịch vụ Crm (Crm.vntel.vn). Người dùng
        khi đăng ký, và sau khi đi đăng ký đều phải tuân thủ những quy định này.
      </Typography>

      <StyledHeader component="h2" gutterBottom>
        ĐIỀU 2: THAY ĐỔI QUY ĐỊNH
      </StyledHeader>
      <Typography variant="body1" gutterBottom>
        Công ty chúng tôi có thể thay đổi quy định theo các chính sách của công
        ty. Khi có thay đổi, chúng tôi sẽ đăng tải bản cập nhật lên CRM, phiên
        bản sửa đổi sẽ có hiệu lực tại thời điểm được đăng. Sau khi quy định
        thay đổi, nếu thành viên tiếp tục sử dụng CRM chúng tôi coi thành viên
        đã chấp nhận tất cả các quy định mới.
      </Typography>

      <StyledHeader component="h2" gutterBottom>
        ĐIỀU 3: SỬ DỤNG DỊCH VỤ
      </StyledHeader>
      <Typography variant="body1" gutterBottom>
        Người dùng đăng ký tài khoản và khởi tạo tài khoản trên CRM là người đã
        đăng ký sử dụng dịch vụ theo quy định của CTCP Viễn Thông Tin Học Việt
        Nam và được công ty chấp nhận sự đăng ký đó.
      </Typography>

      <StyledHeader component="h2" gutterBottom>
        ĐIỀU 4: QUẢN LÝ THÔNG TIN
      </StyledHeader>
      <Typography variant="body1" gutterBottom>
        Sau khi bàn giao các thông số quản trị dịch vụ cho người dùng, CRM không
        chịu trách nhiệm và không bảo đảm về tính chính xác của những thông tin
        từ tài khoản của người dùng. CRM không chịu trách nhiệm pháp lý và bồi
        thường cho người dùng và bên thứ ba đối với các thiệt hại trực tiếp,
        gián tiếp, vô ý, đặc biệt, vô hình, các thiệt hại về lợi nhuận, doanh
        thu, uy tín phát sinh từ việc sử dụng sản phẩm, dịch vụ của Nobi Pro.
      </Typography>
      <StyledHeader component="h2" gutterBottom>
        ĐIỀU 5: TẠM NGỪNG CUNG CẤP DỊCH VỤ
      </StyledHeader>
      <Typography variant="body1" gutterBottom>
        CRM có quyền ngưng cung cấp dịch vụ mà không hoàn lại bất kỳ một chi phí
        nào trong các trường hợp sau:
      </Typography>
      <Typography variant="body1" gutterBottom>
        Người dùng dùng CRM vào bất kỳ mục đích/hình thức nào vi phạm pháp luật
        Việt Nam, đặt biệc về vấn đề bản quyền phần mềm, ca khúc…
      </Typography>
      <Typography variant="body1" gutterBottom>
        Người dùng gửi, tạo liên kết hoặc trung chuyển cho các dữ liệu mang tính
        chất bất hợp pháp, đe doạ, lừa dối, thù hằn, xuyên tạc, nói xấu, tục
        tĩu, khiêu dâm, xúc phạm… hay các hình thức bị pháp luật Việt Nam ngăn
        cấm khác dưới bất kỳ cách thức nào.
      </Typography>
      <Typography variant="body1" gutterBottom>
        Người dùng lưu trữ, truyền bá các dữ liệu nào mà cấu thành hoặc khuyến
        khích các hình thức phạm tội; hoặc các dữ liệu mang tính vi phạm luật
        sáng chế, nhãn hiệu, quyền thiết kế, bản quyền hay bất kỳ quyền sỡ hữu
        trí tuệ hoặc các quyền hạn của bất kỳ cá nhân nào.
      </Typography>
      <Typography variant="body1" gutterBottom>
        Người dùng sử dụng CRM để phá hoại một website khác.
      </Typography>
      <Typography variant="body1" gutterBottom>
        Người dùng sử dụng các chương trình có khả năng làm tắc nghẽn hoặc đình
        trệ hệ thống, như gây cạn kiệt tài nguyên hệ thống, làm quá tải bộ vi xử
        lý và bộ nhớ.
      </Typography>
      <Typography variant="body1" gutterBottom>
        Người dùng sử dụng CRM hoặc tài khoản của mình để xâm nhập trái phép vào
        website khác hoặc gây ảnh hưởng đến khách hàng khác của CRM.
      </Typography>

      <Typography variant="body1" gutterBottom>
        Người dùng không thanh toán các chi phí đúng hạn.
      </Typography>

      <StyledHeader component="h2" gutterBottom>
        ĐIỀU 6: QUẢN LÝ TÀI KHOẢN QUẢN TRỊ
      </StyledHeader>
      <Typography variant="body1" gutterBottom>
        Người dùng giữ một cách an toàn các thông tin nhận biết, mật khẩu hay
        những thông tin, mặt khác liên quan đến tài khoản của mình và lập tức
        thông báo cho CRM khi phát hiện các hình thức truy cập trái phép bằng
        tài khoản của mình hoặc các sơ hở về bảo mật, bao gồm việc mất mát, đánh
        cắp hoặc để lộ các thông tin về mật khẩu và các thông tin bảo mật khác.
      </Typography>

      <StyledHeader component="h2" gutterBottom>
        ĐIỀU 7: SỬ DỤNG EMAIL
      </StyledHeader>
      <Typography variant="body1" gutterBottom>
        Người dùng phải tự chịu trách nhiệm về nội dung các email được gửi đi từ
        hộp thư được sử dụng để đăng ký tài khoản của mình tại CRM.
      </Typography>

      
      <StyledHeader component="h2" gutterBottom>
        ĐIỀU 8: TRÁCH NHIỆM CỦA CRM KHI NGƯNG CUNG CẤP DỊCH VỤ
      </StyledHeader>
      <Typography variant="body1" gutterBottom>
        Vntel sẽ hoàn lại tiền phí dịch vụ của quãng thời gian mà khách hàng đã
        đóng phí nhưng không sử dụng được dịch vụ của CRM do CRM ngưng hoạt
        động.
      </Typography>
      <StyledHeader component="h2" gutterBottom>
        ĐIỀU 9: GIẢI QUYẾT TRANH CHẤP, LUẬT ÁP DỤNG
      </StyledHeader>
      <Typography variant="body1" gutterBottom>
        Trong quá trình sử dụng nếu xảy ra tranh chấp giữa người sử dụng và công
        ty chúng tôi, hai bên sẽ tiến hành đàm phán hòa giải với tinh thần hữu
        nghị. Trong trường hợp không giải quyết được bằng hòa giải sẽ đưa ra toà
        án kinh tế Hà Nội giải quyết.
      </Typography>
    </Container>
  );
};

export default Term;
