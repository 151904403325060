import React, {
  useEffect,
  createRef,
  useState,
  useRef,
} from "react";
import { WebSocketInterface } from "jssip";
import _ from "lodash";
import CallsFlowControl from "../../../CallsFlowControl";
import Stack from "@mui/material/Stack";
import Slider from "@mui/material/Slider";
import VolumeUp from "@mui/icons-material/VolumeUp";
import Fab, { fabClasses } from "@mui/material/Fab";
import MicOffIcon from "@mui/icons-material/MicOff";
import MicIcon from "@mui/icons-material/Mic";
import CallIcon from "@mui/icons-material/Call";
import CallEndIcon from "@mui/icons-material/CallEnd";
import PauseIcon from "@mui/icons-material/Pause";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Divider from "@mui/material/Divider";
import BackspaceIcon from "@mui/icons-material/Backspace";
import useLongPress from "./../../useLongPress";
import Avatar from "@mui/material/Avatar";
import { useDispatch, useSelector } from "react-redux";
import { endCallPhone } from "../../../redux/callPhone/action";
import CloseIcon from "@mui/icons-material/Close";
import {
  updateUnregisterCall,
  updateRegisterCall,
} from "../../../redux/users/action";
import { getProductsData } from "../../../redux/products/action";
import ConfirmDialog from "../../ConfirmDialog/confirm-dialog";
import { getUserStart } from "../../../redux/users/action";
import TicketModal from "../../../pages/Ticket/TicketModal";
import DialogCustomerCall from "../../../pages/Customer/DialogCustomerCall";
import axiosInstance from "../../axiosApi";
import Tooltip from "@mui/material/Tooltip";
import { CustomButton } from "../CustomButton";
import { makeStyles } from "@material-ui/core/styles";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import NoteAddRoundedIcon from "@mui/icons-material/NoteAddOutlined";
import {
  showMessageError,
  showMessageSuccess,
} from "../../../redux/notification/action";
const useStyles = makeStyles((theme) => ({
  btnIcon: {
    fontSize: "16px !important",
  },
  iconMenuItem: {
    marginRight: 10,
  },
  btnGlobal: {
    minWidth: "inherit",
    width: 36,
    height: 36,
    lineHeight: 36,
    padding: 0,
    marginBottom: 5,
  },
  tableBorder: {
    border: "1px solid #d4d8dd",
    borderRadius: "0.5rem",
  },
}));

export function DurationTime(props) {
  const generator = (seconds) => {
    let duration = seconds;
    let hours = duration / 3600;
    duration = duration % 3600;

    let min = parseInt(duration / 60);
    duration = duration % 60;

    let sec = parseInt(duration);

    if (sec < 10) {
      sec = `0${sec}`;
    }
    if (min < 10) {
      min = `0${min}`;
    }
    if (parseInt(hours, 10) > 0) {
      return `${parseInt(hours, 10)}:${min}:${sec}`;
    }
    return `${min}:${sec}`;
  };

  return (
    <>
      {props.calling && props.answered === false ? (
        <Typography style={{ marginTop: 10, color: "#807F7F" }}>
          Đang gọi...
        </Typography>
      ) : props.answered ? (
        <Typography style={{ marginTop: 10, color: "#807F7F" }}>
          Thời lượng: {generator(props.duration)}
        </Typography>
      ) : props.justEnded ? (
        <Typography style={{ marginTop: 10, color: "#ff0000" }}>
          Cuộc gọi đã kết thúc
        </Typography>
      ) : (
        ""
      )}
    </>
  );
}

const flowRoute = new CallsFlowControl();
const player = createRef();
const endCall = createRef();
const ringCall = createRef();

export default function TestCall({
  setShowPhone,
  setShowDialogCall,
  showDialogCall,
  showPhone,
  phoneNumber,
  callVolume,
  ringVolume,
  setConnectOnStartToLocalStorage,
  setNotifications,
  setCallVolume,
  setRingVolume,
  notifications = true,
  connectOnStart = true,
  timelocale = "UTC",
  asteriskAccounts = [],
}) {
  const [config, setConfig] = useState({});
  const profile = useSelector((item) => item.dataLogoFooterRuducer.data);

  useEffect(() => {
    if (profile.length === 0) {
      return;
    }
    if (Object.keys(profile).length !== 0 && profile.agent) {
      setConfig({
        domain: profile.agent.webrtc_domain,
        uri: profile.agent.webrtc_uri, // sip:sip-user@your-domain.io
        password: profile.agent.webrtc_password, //  PASSWORD ,
        ws_servers: profile.agent.webrtc_websocket, //ws server
        sockets:
          profile.agent.webrtc_websocket &&
          [new WebSocketInterface(profile.agent.webrtc_websocket)],
        display_name: profile.agent.webrtc_display, //jssip Display Name
        debug: true, // Turn debug messages on
        pcConfig: {
          rtcpMuxPolicy: 'negotiate',
          iceServers:
            [
              { urls: ['stun:stun.l.google.com:19302'] }
            ]
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile]);

  const defaultSoftPhoneState = {
    displayCalls: [
      {
        id: 0,
        info: "Ch 1",
        hold: false,
        muted: 0,
        autoMute: 0,
        inCall: false,
        inAnswer: false,
        inTransfer: false,
        callInfo: "Ready",
        inAnswerTransfer: false,
        allowTransfer: true,
        transferControl: false,
        allowAttendedTransfer: true,
        transferNumber: "",
        attendedTransferOnline: "",
        inConference: false,
        callNumber: "",
        duration: 0,
        side: "",
        sessionId: "",
      },
    ],
    phoneConnectOnStart: connectOnStart,
    notifications,
    phoneCalls: [],
    connectedPhone: false,
    connectingPhone: false,
    activeCalls: [],
    callVolume,
    ringVolume,
  };
  const [localStatePhone, setLocalStatePhone] = useState(defaultSoftPhoneState);
  const phone = createRef();
  const [value, setValue] = React.useState(100);
  const [muted, setMuted] = useState(false);
  const [inCall, setInCall] = useState(false);
  const [hold, setHold] = useState(false);
  const [callAccepted, setCallAccepted] = useState(false);
  const [callJustEnded, setCallJustEnded] = useState(false);
  const [duration, setDuration] = React.useState(0);
  const durationInterval = useRef(null);
  const timer = useRef(null);
  const [sipStatus, setSipStatus] = useState("connecting");
  const [dialNumber, setDialNumber] = useState("");
  const [, updateState] = React.useState();
  const [openTicket, setOpenTicket] = useState(false);
  const [openModalTicket, setOpenModalTicket] = useState(false);
  const [openModalCreate, setOpenModalCreate] = useState(false);
  const dialogRefCall = useRef(null);
  const dispatch = useDispatch();
  const classes = useStyles();
  const state = useSelector((state) => state.callPhoneReducer);

  flowRoute.activeChanel = localStatePhone.displayCalls[0];
  flowRoute.connectedPhone = sipStatus === "connected";
  flowRoute.engineEvent = (event, payload) => {
    // Listen Here for Engine "UA jssip" events
    switch (event) {
      case "connecting":
        setSipStatus("connecting");
        break;
      case "connected":
        setSipStatus("connected");
        break;
      case "registered":
        break;
      case "disconnected":
        setSipStatus("disconnected");
        break;
      case "registrationFailed":
        break;

      default:
        break;
    }
  };

  const handleChange = (event, newValue) => {
    player.current.volume = parseInt(newValue, 10) / 100;
    setValue(newValue);
  };

  const dataUser = useSelector((item) => item.UsersReducer);
  useEffect(() => {
    if (
      Object.keys(config).length > 0 &&
      dataUser.registered === "registering"
    ) {
      flowRoute.config = config;
      flowRoute.init();
      flowRoute.start();
      try {
        player.current.defaultMuted = false;
        player.current.autoplay = true;
        player.current.volume = 1;

        ringCall.current.defaultMuted = false;
        ringCall.current.autoplay = false;
        ringCall.current.volume = 1;
        // player.volume = this.outputVolume;
        flowRoute.player = player;
        flowRoute.ringer = ringCall;
        dispatch(updateRegisterCall());
      } catch (e) {
        dispatch(updateUnregisterCall());
        //console.log(e);
      }
    }
    if (
      dataUser.registered === "unregistered" &&
      Object.keys(flowRoute.config).length > 0
    ) {
      flowRoute.stop();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dataUser.registered,
    config,
    localStatePhone.callVolume,
    localStatePhone.ringVolume,
  ]);

  const handleDuration = () => {
    var seconds = 0;
    durationInterval.current = setInterval(() => {
      seconds++;
      setDuration(seconds);
    }, 1000);
  };

  useEffect(() => {
    dispatch(getUserStart());
  }, []);

  const [checkMic2, setCheckMic] = useState(false)

  const checkMic = async () => {
    navigator.mediaDevices.getUserMedia({ audio: true})
    .then(function (stream) {
          if (stream.getAudioTracks().length > 0){
              //code for when none of the devices are available
              setCheckMic(true)
          } else {
             // code for when both devices are available
             setCheckMic(false)
             dispatch(showMessageError("Không tìm thấy micro"))
          }
    })
   .catch(function (error) { 
        // code for when there is an error
        setCheckMic(false)
        dispatch(showMessageError("Không tìm thấy micro"))

    });

  
  };



  flowRoute.onCallActionConnection = async (type, payload) => {
    console.log(payload);
    switch (type) {
      case "incomingCall":
        const number = payload._remote_identity._uri._user;
        if (payload._direction === "incoming") {
          setDialNumber(number);
          setOpenTicket(true);
        }
        ringCall.current?.play();
        const result = await ConfirmDialog({
          title: "Có cuộc gọi đến số máy của bạn",
          message: `Cuộc gọi từ số ${number}`,
          confirmText: "Đồng ý",
          confirmClass: "button-red",
          cancelText: "Từ chối",
          cancelClass: "button-cancel",
          phoneNumber: number,
        }, dialogRefCall);

        if (result && checkMic()) {
          ringCall.current?.pause();
          flowRoute.answer(payload._id);
          setShowPhone(true);
          //setOpenTicket(true);

          const newProgressLocalStatePhone = _.cloneDeep(localStatePhone);
          newProgressLocalStatePhone.displayCalls[0] = {
            ...localStatePhone.displayCalls[0],
            sessionId: payload._id,
          };
          // Save new object into the array with display calls

          setLocalStatePhone((prevState) => ({
            ...prevState,
            displayCalls: newProgressLocalStatePhone.displayCalls,
          }));

          //setDialNumber(number);
          setInCall(true);
        } else {
          flowRoute.hungup();
          ringCall.current.pause();
          setOpenTicket(false);
        }
        break;
      case "outgoingCall":
        // looks like new call its outgoing call
        // Create object with the Display data of new outgoing call

        const newProgressLocalStatePhone = _.cloneDeep(localStatePhone);
        newProgressLocalStatePhone.displayCalls[0] = {
          ...localStatePhone.displayCalls[0],
          sessionId: payload.id,
        };
        // Save new object into the array with display calls

        setLocalStatePhone((prevState) => ({
          ...prevState,
          displayCalls: newProgressLocalStatePhone.displayCalls,
        }));

        setInCall(true);

        break;

      case "callAccepted":
        setCallAccepted(true);
        //setOpenTicket(true);
        handleDuration();
        break;

      case "callEnded":
        dialogRefCall?.current?.closeDialogCall();
        phone.current.disabled = false;
        setInCall(false);
        setCallAccepted(false);
        setCallJustEnded(true);
        setTimeout(() => setCallJustEnded(false), 1500);
        setDuration(0);
        clearInterval(durationInterval.current);
        endCall.current.play();
        break;
      case "hold":
        setHold(true);
        break;
      case "unhold":
        setHold(false);
        break;
      default:
        break;
    }
  };

  const handleMicMute = () => {
    flowRoute.setMicMuted();
    setMuted(!muted);
  };

  const handleEndCall = (event) => {
    dispatch(endCallPhone());
    event.persist();
    phone.current.disabled = false;
    flowRoute.hungup(localStatePhone.displayCalls[0].sessionId);
    setInCall(false);
    clearInterval(durationInterval.current);
    setDuration(0);
    endCall.current.play();
  };

  const handleHold = () => {
    if (hold === false) {
      setHold(true);
      flowRoute.hold(localStatePhone.displayCalls[0].sessionId);
    } else if (hold === true) {
      setHold(false);
      flowRoute.unhold(localStatePhone.displayCalls[0].sessionId);
    }
  };

  const handleDialNumberChange = (event) => {
    event.persist();
    setDialNumber(event.target.value);
  };

  const onLongPressDelete = () => {
    !inCall && setDialNumber((dialNumber) => dialNumber.slice(0, -1));
    timer.current = setTimeout(onLongPressDelete, 100);
  };

  const onClickDelete = () => {
    !inCall && setDialNumber(dialNumber.slice(0, -1));
  };

  const defaultOptions = {
    shouldPreventDefault: true,
    delay: 500,
  };

  const longPressEvent = useLongPress(
    onLongPressDelete,
    onClickDelete,
    defaultOptions
  );

  const followCursor = () => {
    const end = phone.current?.value.length;
    phone.current.setSelectionRange(end, end);
    phone.current.focus();
  };

  useEffect(() => {
    if (!state.numberPhone) return;
    flowRoute.call(state.numberPhone);
    setInCall(true);

    phone.current.disabled = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.numberPhone]);

  useEffect(() => {
    if (phone.current) {
      phone.current.focus();
    }
  }, [phone]);

  const [dataByPhone, setData] = useState([]);
  const [cusName, setCusName] = useState("");
  const [isAddingNewData, setIsAddingNewData] = useState(false);
  //const numbertest = '0913344893'

  const handleCloseTicket = () => {
    setOpenTicket(false);
  };

  const handleCloseCreate = () => {
    setOpenModalCreate(false);
  };

  useEffect(() => {
    const handleSearchInfoByPhone = async () => {
      try {
        const res = await axiosInstance.get(`/leads/?phone=${dialNumber}`);
        if (res.status === 200) {
          setData(res.data);
          setCusName(res.data.open_leads?.open_leads[0]?.lead?.fullname);
        }
      } catch (error) {}
    };
    handleSearchInfoByPhone();
  }, [dialNumber]);

  return (
    <>
      {window.addEventListener("load", function () {
        document.getElementById("deleteBtn").addEventListener("mouseup", () => {
          clearInterval(timer.current);
        });
      })}
      {openModalTicket && (
        <TicketModal
          onCloseTicket={handleCloseTicket}
          setUDO1={setOpenModalTicket}
          dataUser={dataByPhone}
          number={dialNumber}
          cusName={cusName}
        />
      )}

      {openModalCreate && (
        <DialogCustomerCall 
            onCloseModal={handleCloseCreate}
            setUDO1={setOpenModalCreate}
            dataUser={dataByPhone}
            number={dialNumber}
        />
      )}

      <ul
        className={`list-support-registercall
                        ${openTicket === true ? "active" : ""}`}
      >
        <Tooltip title="Thêm Đơn Hàng" enterTouchDelay={0}>
          <CustomButton
            variant="contained"
            className={`${classes.btnGlobal} ${classes.btnBlue}`}
            onClick={() => setOpenModalCreate(true)}
          >
            <AddShoppingCartIcon className={`${classes.btnIcon}`} />
          </CustomButton>
        </Tooltip>

        <Tooltip title="Tạo Ticket" enterTouchDelay={0}>
          <CustomButton
            variant="contained"
            className={`${classes.btnGlobal} ${classes.btnBlue}`}
            onClick={() => {
              setOpenModalTicket(true);
            }}
          >
            <NoteAddRoundedIcon className={`${classes.btnIcon}`} />
          </CustomButton>
        </Tooltip>
      </ul>
      <Paper
        elevation={3}
        className="softphone-dialog"
        style={
          inCall
            ? {
                background:
                  "linear-gradient(180deg,#0e9f6e 0%,rgba(255, 255, 255, 1) 50%)",
              }
            : { display: showPhone ? "block" : "none" }
        }
      >
        {!inCall && (
          <div
            className="close-phone"
            onClick={() => {
              setShowDialogCall(false);
              setShowPhone(false);
            }}
          >
            <CloseIcon />
          </div>
        )}

        <div style={{ padding: 10 }}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: 17,
            }}
          >
            {inCall && (
              <Avatar
                sx={{ width: 100, height: 100 }}
                style={{ marginTop: 20 }}
              />
            )}
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <input
              type={"text"}
              value={
                !inCall
                  ? dialNumber
                  : state.numberPhone?.replace(
                      state.numberPhone?.substr(4, 6),
                      "******"
                    )
              }
              onChange={handleDialNumberChange}
              ref={phone}
              id="phoneNumberInput"
              placeholder={
                !state.numberPhone
                  ? "Nhập số điện thoại ..."
                  : state.numberPhone
              }
              style={{
                border: "none",
                textAlign: "center",
                width: "78%",
                fontSize: 20,
                marginLeft: 29,
              }}
              autoComplete="off"
            />
            <BackspaceIcon
              {...longPressEvent}
              style={
                !inCall
                  ? {
                      color: "gray",
                      cursor: "pointer",
                    }
                  : { display: "none" }
              }
              id="deleteBtn"
              // onMouseDown={continuosIncerment}
              // onMouseUp={timeoutClear}
            />
          </div>
          <div style={{ textAlign: "center" }}>
            <DurationTime
              calling={inCall}
              answered={callAccepted}
              duration={duration}
              justEnded={callJustEnded}
            />
          </div>
          <div
            style={
              !inCall
                ? {
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    paddingLeft: 10,
                    paddingRight: 10,
                    marginTop: 15,
                  }
                : { display: "none" }
            }
          >
            <div
              className="dialpad-number no-select"
              onClick={() => {
                setDialNumber(dialNumber.concat("1"));
                followCursor();
              }}
            >
              <div className="dialpad-number-circle">1</div>
            </div>
            <div
              className="dialpad-number no-select"
              onClick={() => {
                setDialNumber(dialNumber.concat("2"));
                followCursor();
              }}
            >
              <div className="dialpad-number-circle">2</div>
            </div>
            <div
              className="dialpad-number no-select"
              onClick={() => {
                setDialNumber(dialNumber.concat("3"));
                followCursor();
              }}
            >
              <div className="dialpad-number-circle">3</div>
            </div>
            <div
              className="dialpad-number no-select"
              onClick={() => {
                setDialNumber(dialNumber.concat("4"));
                followCursor();
              }}
            >
              <div className="dialpad-number-circle">4</div>
            </div>
            <div
              className="dialpad-number no-select"
              onClick={() => {
                setDialNumber(dialNumber.concat("5"));
                followCursor();
              }}
            >
              <div className="dialpad-number-circle">5</div>
            </div>
            <div
              className="dialpad-number no-select"
              onClick={() => {
                setDialNumber(dialNumber.concat("6"));
                followCursor();
              }}
            >
              <div className="dialpad-number-circle">6</div>
            </div>
            <div
              className="dialpad-number no-select"
              onClick={() => {
                setDialNumber(dialNumber.concat("7"));
                followCursor();
              }}
            >
              <div className="dialpad-number-circle">7</div>
            </div>
            <div
              className="dialpad-number no-select"
              onClick={() => {
                setDialNumber(dialNumber.concat("8"));
                followCursor();
              }}
            >
              <div className="dialpad-number-circle">8</div>
            </div>
            <div
              className="dialpad-number no-select"
              onClick={() => {
                setDialNumber(dialNumber.concat("9"));
                followCursor();
              }}
            >
              <div className="dialpad-number-circle">9</div>
            </div>
            <div
              className="dialpad-number no-select"
              onClick={() => {
                setDialNumber(dialNumber.concat("*"));
                followCursor();
              }}
            >
              <div className="dialpad-number-circle">*</div>
            </div>
            <div
              className="dialpad-number no-select"
              onClick={() => {
                setDialNumber(dialNumber.concat("0"));
                followCursor();
              }}
            >
              <div className="dialpad-number-circle">0</div>
            </div>
            <div
              className="dialpad-number no-select"
              onClick={() => {
                setDialNumber(dialNumber.concat("#"));
                followCursor();
              }}
            >
              <div className="dialpad-number-circle">#</div>
            </div>
          </div>
          <div hidden>
            <audio preload="auto" ref={player} />
          </div>
          <div hidden>
            <audio
              preload="auto"
              src={require("./../../../assets/sounds/end_call.mp3")}
              autoPlay={false}
              defaultmuted="false"
              volume="1"
              ref={endCall}
            />
          </div>
          <div hidden>
            <audio
              preload="auto"
              src={require("./../../../assets/sounds/ring_call.wav")}
              autoPlay={false}
              defaultmuted="false"
              volume="1"
              ref={ringCall}
            />
          </div>
          <div className="dial-buttons">
            {inCall ? (
              <Fab
                size="small"
                style={{ backgroundColor: "#f4f6f8" }}
                onClick={handleMicMute}
              >
                {muted ? <MicOffIcon /> : <MicIcon />}
              </Fab>
            ) : (
              <Fab size="small" disabled>
                <MicIcon />
              </Fab>
            )}
            {inCall === false ? (
              <Fab
                className={"callButton"}
                aria-label="4"
                onClick={async () => {
                    flowRoute.call(phone.current?.value);
                    setInCall(true);
                    phone.current.disabled = true;
                  
                }}
              >
                <CallIcon style={{ color: "white" }} />
              </Fab>
            ) : (
              <Fab className={"endCallButton"} onClick={handleEndCall}>
                <CallEndIcon style={{ color: "white" }} />
              </Fab>
            )}
            {callAccepted ? (
              <Fab
                size="small"
                style={{ backgroundColor: "#f4f6f8" }}
                onClick={handleHold}
              >
                {hold ? <PlayArrowIcon /> : <PauseIcon />}
              </Fab>
            ) : (
              <Fab size="small" disabled>
                <PauseIcon />
              </Fab>
            )}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              paddingLeft: 25,
              paddingRight: 35,
            }}
          >
            <VolumeUp style={{ marginRight: 8 }} />
            <Slider size="small" value={value} onChange={handleChange} />
          </div>
        </div>
        <Divider />
        <Stack
          direction={"row"}
          style={{
            justifyContent: "space-between",
            alignItems: "center",
            padding: 10,
          }}
        >
          <Typography style={{ fontSize: 15 }}>TRẠNG THÁI</Typography>
          <span className="sipStatusPlaceholder" style={{ float: "right" }}>
            {sipStatus === "connecting"
              ? "ĐANG KẾT NỐI..."
              : sipStatus === "connected"
              ? "ĐÃ KẾT NỐI"
              : sipStatus === "disconnected"
              ? "CHƯA KẾT NỐI"
              : "CHƯA XÁC ĐỊNH"}
          </span>
        </Stack>
      </Paper>
    </>
  );
}
