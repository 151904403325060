import React, { useEffect, useState } from "react";
import Loading from "../../../../components/Common/Loading";
import {
  Box,
  Table,
  TableBody,
  TableContainer,
  IconButton,
  TablePagination,
  TableRow,
} from "@mui/material";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import PropTypes from "prop-types";
import Paper from "@mui/material/Paper";
import { CustomButton } from "../../../../components/Common/CustomButton";
import { useDispatch, useSelector } from "react-redux";
import Tooltip from "@mui/material/Tooltip";
import { getCompanies, resetMessage } from "../../../../redux/company/action";

import { CreateAdminCompany } from "./create-admin-company";
import  EditCompany  from "./edit-company";
import { useTheme } from "@mui/material/styles";
import LastPageIcon from "@mui/icons-material/LastPage";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import MuiTableCell from "@material-ui/core/TableCell";
import MuiTableHead from "@mui/material/TableHead";
import AddRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import { MultiForm } from "./multi-form";
import blank from "../../../../assets/images/logo.png"; 

const URL =`${process.env.REACT_APP_API_URL}`;

const useStyles = makeStyles((theme) => ({
  tableBorder: {
    border: "1px solid #d4d8dd",
    borderRadius: "0.5rem",
  },
  btnGlobal: {
    minWidth: "inherit",
    width: 28,
    padding: "0",
    height: "28px",
    lineHeight: "28px",

    marginLeft: 5,
    boxShadow: "none",
    fontSize: "12px",
    fontWeight: "500",
  },
  btnBlue: { backgroundColor: "#556ee6" },
  // btnGreen:{
  //   backgroundColor: '#34c38f',
  // },
  btnYellow: {
    backgroundColor: "#f1b44c",
  },
  btnRed: {
    backgroundColor: "#f46a6a",
  },
}));

const TableCell = withStyles({
  root: {
    borderBottom: "1px solid rgb(235, 237, 242)",
    padding: "5px 10px",
    lineHeight: "18px",
  },
})(MuiTableCell);

const TableHead = withStyles((theme) => ({
  root: {
    backgroundColor: "rgba(38,60,85,.1)",
  },
}))(MuiTableHead);

const TableHeaderCell = withStyles((theme) => ({
  root: {
    color: "#516377",
    fontWeight: 700,
    padding: "10px",
    fontSize: ".75rem",
    textTransform: "uppercase",
    letterSpacing: "0.8px",
    lineHeight: "18px",
  },
}))(TableCell);

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const Company = () => {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  // const [expanded, setExpanded] = useState("panel1");
  const [listSelected, setListSelected] = useState([]);
  const [openCreateCompany, setOpenCreateCompany] = useState(false);
  const [openCreateAdminCompany, setOpenCreateAdminCompany] = useState(false);
  const [openEditCompany, setOpenEditCompany] = useState(false)
  const [item, setItem] = useState();
  const [editItemIndex, setItemIndex] = useState(null)

  const navigate = (url) => {
    if (url === "/") {
      window.location.href = "/";
    } else {
      window.location.href = "/configuration";
    }
  };


  const userProfile = useSelector((item) => item.dataLogoFooterRuducer.data);

  const dispatch = useDispatch();
  useEffect(() => {
    if (
      !Array.isArray(userProfile) &&
      userProfile &&
      userProfile?.role !== "ADMIN"
    ) {
      dispatch(
        getCompanies({
          offset: 0,
          limit: 10,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userProfile]);

  const createSuccess = useSelector(
    (item) => item.companyReducer.createSuccess
  );
  const createAdminCompanySuccess = useSelector(
    (item) => item.companyReducer.createAdminCompanySuccess
  );

  useEffect(() => {
    if (createSuccess || createAdminCompanySuccess) {
      dispatch(
        getCompanies({
          offset: 0,
          limit: 10,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createSuccess, createAdminCompanySuccess]);

  const totalCount = useSelector(
    (item) => item.companyReducer.dataList
  ).org_count;
  const listCompany = useSelector((item) => item.companyReducer.dataList)
    ?.list_org || [userProfile.org];
  const loading = useSelector((item) => item.companyReducer.loading);
  // const handleChange = (panel) => (event, newExpanded) => {
  //   setExpanded(newExpanded ? panel : false);
  // };


  const handleChangePage = (event, newPage) => {
    let data = {
      offset: newPage * rowsPerPage,
      limit: rowsPerPage,
    };
    dispatch(getCompanies(data));
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    let data = {
      offset: page,
      limit: event.target.value !== "-1" ? +event.target.value : 9999,
    };
    dispatch(getCompanies(data));
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleCloseEditCompany = () => {
    setOpenEditCompany(false);
  };

  const handleEdit = (item) => {
    setItem(item);
    setOpenEditCompany(true);
  };


  return (
    <div className="screen modify-label">
      <CustomButton style={{marginBottom:'15px',minWidth:'inherit',pading:'0 6px'}} onClick={() => {
            navigate("/configuration");
          }}><KeyboardArrowLeftIcon /></CustomButton>
      <Paper className="order-list" style={{ marginBottom: "10px" }}>
        <div className="ul-heading-placeholder">
          {/* <Typography style={{ fontWeight: 500, fontSize: 18,marginRight: "10px" }}>
              Danh sách công ty
            </Typography> */}
          <span
            className="ul-heading"
            style={{ display: "flex", flex: "inherit", fontWeight: 500 }}
          >
            Danh sách công ty
          </span>
          <CustomButton
            variant="contained"
            onClick={() => {
              setOpenCreateCompany(true);
            }}
          >
            <AddRoundedIcon style={{ fontSize: "1rem", marginRight: "5px" }} />
            Tạo công ty
          </CustomButton>
        </div>
      </Paper>

      <Loading loading={loading} />
      <Paper
        sx={{ width: "100%", mb: 2 }}
        elevation={0}
        className={`${classes.tableBorder}`}
      >
        <TableContainer
          style={{
            width: "100%",
            overflowX: "auto",
            borderRadius: "0.5rem",
          }}
        >
          <Table sx={{ minWidth: 700 }} size={"small"}>
            <TableHead>
              <TableRow>
                {/* <TableHeaderCell
                        align="left"
                        style={{ width: 40 }}
                      ></TableHeaderCell> */}
                <TableHeaderCell align="left" style={{ width: 120 }}>
                  Mã công ty
                </TableHeaderCell>
                <TableHeaderCell align="left" style={{ minWidth: 120 }}>
                  Tên công ty
                </TableHeaderCell>
                <TableHeaderCell align="left" style={{ minWidth: 120 }}>
                  Địa chỉ
                </TableHeaderCell>
                <TableHeaderCell align="left" style={{ minWidth: 120 }}>
                  Số điện thoại
                </TableHeaderCell>
                <TableHeaderCell align="left" style={{ minWidth: 120 }}>
                  Số lượng nhân viên (tối đa)
                </TableHeaderCell>
                <TableHeaderCell align="left" style={{ minWidth: 120 }}>
                  Ngày tạo
                </TableHeaderCell>
                <TableHeaderCell align="left" style={{ minWidth: 120 }}>
                  Hành động
                </TableHeaderCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {listCompany &&
                listCompany?.length > 0 &&
                listCompany?.map((company, index) => (
                  <TableRow hover={true} key={index}>
                    {/* <TableCell align="left" style={{ width: 40 }}>
                            <Checkbox
                              sx={{
                              ".MuiSvgIcon-root": {
                                width:'0.8em',
                                height:'0.8em'
                              },
                            }}
                              color="primary"
                              checked={isSelected(company?.id)}
                              inputProps={{
                                "aria-labelledby": index,
                              }}
                              onClick={(event) =>
                                handleSelectCompany(event, company?.id)
                              }
                            />
                          </TableCell> */}
                    <TableCell align="left" style={{ width: 120 }}>
                      {company?.id}
                    </TableCell>
                    <TableCell align="left" style={{ width: 200 }}>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "left",
                        }}
                      >
                        
                          <div
                            style={{
                              height: 30,
                              width: 30,
                              marginRight: "5px",
                            }}
                          >
                            <>
                              <img
                                alt=""
                                style={{
                                  height: 30,
                                  width: 30,
                                  borderRadius: "50%",
                                  marginRight: "5px",
                                  border: "1px dashed #5a8dee",
                                  flex:'0 0 30px'
                                }}
                                src={
                                  company?.logo ? 
                                  `${URL}${company?.logo}` : blank
                                  }
                              />
                            </>
                          </div>
                        
                        <span style={{ fontWeight: 500 }}>{company?.name}</span>
                      </div>
                    </TableCell>
                    <TableCell align="left" style={{ width: 200 }}>
                      {company?.address}
                    </TableCell>
                    <TableCell align="left" style={{ minWidth: 120 }}>
                      {company?.phone}
                    </TableCell>
                    <TableCell align="left" style={{ minWidth: 120 }}>
                      {company?.user_limit}
                    </TableCell>
                    <TableCell align="left" style={{ minWidth: 120 }}>
                      {moment(company?.created_on).format("MM/DD/YYYY")}
                    </TableCell>
                    <TableCell align="left" style={{ minWidth: 120 }}>
                      <div style={{ display: "flex", marginBottom: "5px" }}>
                        <Tooltip title="Tạo Admin">
                          <CustomButton
                            variant="contained"
                            className={`${classes.btnGlobal} ${classes.btnGreen}`}
                            onClick={() => {
                              setOpenCreateAdminCompany(true);
                              setListSelected(company?.id);
                            }}
                          >
                            <i
                              style={{ fontSize: "10px" }}
                              className="fas fa-user-plus"
                            ></i>
                          </CustomButton>
                        </Tooltip>
                        <Tooltip title="Chỉnh sửa">
                          <CustomButton
                            variant="contained"
                            className={`${classes.btnGlobal} ${classes.btnYellow}`}
                            onClick={() => {
                             
                              handleEdit(company);
                              setListSelected(company?.id);
                            }}
                          >
                            <i
                              style={{ fontSize: "10px" }}
                              className="fas fa-pencil-alt"
                            ></i>
                          </CustomButton>
                        </Tooltip>
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[10, 25]}
            colSpan={3}
            labelRowsPerPage={"Số hàng trên 1 trang:"}
            count={totalCount}
            rowsPerPage={rowsPerPage}
            page={page}
            SelectProps={{
              inputProps: {
                "aria-label": "rows per page",
              },
              native: true,
            }}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            ActionsComponent={TablePaginationActions}
          />
        </TableContainer>
      </Paper>

      {openCreateCompany && (
        <MultiForm
          onClose={() => {
            setOpenCreateCompany(false);
            dispatch(resetMessage());
          }}
        />
      )}
      {openCreateAdminCompany && (
        <CreateAdminCompany
          onClose={() => {
            setOpenCreateAdminCompany(false);
            dispatch(resetMessage());
          }}
          idCompany={listSelected}
        />
      )}
      {openEditCompany && (
        <EditCompany
          setUDO1={setOpenEditCompany}  item={item} onClose={handleCloseEditCompany} idCompany={listSelected}
        />
      )}
    </div>
  );
};

export default Company;
