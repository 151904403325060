import React from 'react'
import {
   
    Dialog, DialogContent, DialogTitle, IconButton,
  } from "@mui/material";
  
  import CloseIcon from "@mui/icons-material/Close";
  import { CustomButton } from "../../components/Common/CustomButton";
  import { makeStyles } from "@material-ui/core/styles";

  const useStyles = makeStyles((theme) => ({
    textFieldError: {
      "& input": {
        border: "1px solid red",
        color: "red",
      },
      "& p": {
        color: "red",
        marginLeft: 0,
      },
    },
    customModalContent: {
      paddingLeft: "15px",
      paddingRight: "15px",
    },
    customModal: {
      width: "calc(100% - 20px)",
      marginLeft: "10px",
      marginRight: "10px",
    },
    customModalTitle: {
      padding: "10px 15px",
      color: "#fff",
      background: process.env.NODE_ENV === 'development' ? "#5a8dee" : process.env.REACT_APP_PRODUCT_BASE_COLOR,
      marginBottom:'15px',
    },
  }));
const SmsTempDel = ({idTemp,onDel,onClose, setUDO1}) => {
    const classes = useStyles();
  return (
    <Dialog
      fullWidth={true}
      maxWidth={"xs"}
      open={true}
      onClose={onClose}
      style={{ minHeight: "100%" }}
      classes={{
        paper: classes.customModal,
      }}
    >
      <DialogTitle className={`${classes.customModalTitle}`}>
        Xác nhận
        <IconButton
          onClick={() => setUDO1(false)}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "#fff",
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={`${classes.customModalContent}`}>
      Bạn có thực sự muốn xoá
      <CustomButton
            // color="primary"
            variant="contained"
            type="submit"
            onClick={() => onDel(idTemp)}
            style={{marginLeft:'auto'}}
          >
            Đồng ý
          </CustomButton>
      </DialogContent>
    </Dialog>
  )
}

export default SmsTempDel