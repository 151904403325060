export const GET_TICKET = "GET_TICKET"
export const GET_TICKET_SUCCESS = "GET_TICKET_SUCCESS"
export const GET_TICKET_FAIL = "GET_TICKET_FAIL"

export const CREATE_TICKET = "CREATE_TICKET"
export const CREATE_TICKET_SUCCESS = "CREATE_TICKET_SUCCESS"
export const CREATE_TICKET_FAIL = "CREATE_TICKET_FAIL"

export const RESET_MESSAGE = "RESET_MESSAGE"
export const RESET_MESSAGE_ERROR = "RESET_MESSAGE_ERROR"

export const EDIT_TICKET = "EDIT_TICKET"
export const EDIT_TICKET_SUCCESS = "EDIT_TICKET_SUCCESS"
export const EDIT_TICKET_FAIL = "EDIT_TICKET_FAIL"

// export const DELETE_TICKET = "DELETE_TICKET"
// export const DELETE_TICKET_SUCCESS = "DELETE_TICKET_SUCCESS"
// export const DELETE_TICKET_FAIL = "DELETE_TICKET_FAIL"