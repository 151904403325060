import {
  Box,
  Select,
  Input,
  IconButton,
  Table,
  TableBody,
  TableContainer,
  TablePagination,
  TableRow,
  MenuItem,
  Paper
} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { IconPhone, WrapCellPhone } from "./styled";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import DiaLogAddCredit from "../../../Credit/components/DialogCredit";
import PropTypes from "prop-types";
import moment from "moment";
import React, { useState, useEffect ,useCallback} from "react";
import { useTheme } from "@mui/material/styles";
import LastPageIcon from "@mui/icons-material/LastPage";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import { useDispatch, useSelector } from "react-redux";
import { getOrders, updateOrder } from "../../../../redux/order/action";
import {
  updateRegisteringCall,
  updateUnregisterCall,
} from "../../../../redux/users/action";
import { startCall } from "../../../../redux/callPhone/action";

import { CustomButton } from "../../../../components/Common/CustomButton";
import { makeStyles } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import MuiTableCell from "@material-ui/core/TableCell";
import MuiTableHead from "@mui/material/TableHead";
import { renderWithCommand } from "../../../../helpers";
import { replaceMidd } from "../../../../helpers";
const useStyles = makeStyles((theme) => ({
  tableBorder: {
    border: "1px solid #d4d8dd",
    borderRadius:'0.5rem'
  },

  boxPhone: {
    display: "flex",
    borderRadius: "0.3rem",
    padding: "3px 5px",
    color: process.env.NODE_ENV === 'development' ? "#5a8dee" : process.env.REACT_APP_PRODUCT_BASE_COLOR,
    alignItems: "center",
    backgroundColor:process.env.NODE_ENV === 'development' ? "#e5edfc" : process.env.REACT_APP_PRODUCT_BASE_COLOR2,
    cursor: "pointer",
  },
  customModalContent:{
    paddingLeft:'15px',
    paddingRight:'15px'
  },
  customModal:{
    width:'calc(100% - 20px)',
    marginLeft:'10px',
    marginRight:'10px'
  },
  customModalTitle:{
    padding:'10px 15px',
    color:'#fff',
    background:process.env.NODE_ENV === 'development' ? "#5a8dee" : process.env.REACT_APP_PRODUCT_BASE_COLOR
  }
}));

const TableCell = withStyles({
  root: {
    borderBottom: "1px solid rgb(235, 237, 242)",
    padding: "5px 10px",
    lineHeight:'18px',
  },
})(MuiTableCell);

const TableHead = withStyles((theme) => ({
  root: {
    backgroundColor: "rgba(38,60,85,.1)",
  },
}))(MuiTableHead);

const TableHeaderCell = withStyles((theme) => ({
  root: {
    color: "#516377",
    fontWeight: 700,
    padding: '18px 10px',
    fontSize:'.75rem',
    textTransform:'uppercase',
    letterSpacing: '0.8px',
  },
}))(TableCell);



const PAID_STATUS = [
  { value: true, title: "Đã thanh toán" },
  { value: false, title: "Chưa thanh toán"},
];

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  


  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const OrderListTable = ({
  ordersData,
  // setDetailCustomer,
  dataPagination,
  setDataPagination,
  setPhone,
  phone,
  dataLogoFooter
}) => {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [rows, setRows] = React.useState();
  const [previous, setPrevious] = React.useState({});
  const [detailCredit, setDetailCredit] = useState(null);
  const [createOpen, setCreateOpen] = React.useState(false);
  const [inputValue, setInputValue] = React.useState(null);
  const checkEnablePhone = dataLogoFooter?.org?.hide_number

  const handleCloseCreateCreditDialog = () => {
    setCreateOpen(!createOpen);
    setDetailCredit(null);
  };

  const dispatch = useDispatch();

  useEffect(() => {
    if (ordersData?.dataList?.orders) {
      setRows([...ordersData?.dataList?.orders]);
    }
  }, [ordersData.dataList]);


  const handleChangePage = (event, newPage) => {
    let data = {
      offset: newPage * rowsPerPage,
      limit: rowsPerPage,
    };
    dispatch(getOrders(data));
    setDataPagination(data);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    let data = {
      offset: page,
      limit: event.target.value !== "-1" ? +event.target.value : 9999,
    };
    setDataPagination(data);
    dispatch(getOrders(data));
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const onToggleEditMode = (id) => {
    rows.map((row, idx) => {
      row.isEditMode = row.isEditMode === undefined ? false : row.isEditMode;
      if (row.id === id) {
        const rowClone = { ...row, isEditMode: !row.isEditMode };
        rows[idx] = rowClone;
      }
    });

    setRows([...rows]);
  };

  const handleInputChange = useCallback((e, row) => {
    if (!previous[row.id]) {
      setPrevious((state) => ({ ...state, [row.id]: row }));
    }
    const value = e.target.value;
    setInputValue(value)
    const name = e.target.name;
    let error = '';
    if (!value) {
      error = `${name} field cannot be empty`
    }
    const { id } = row;
    rows.map((row, idx) => {
      if (row.id === id) {
        const rowClone = { ...row, [name]: value, error };
        rows[idx] = rowClone;
      }
    })
    setRows([...rows]);
    
  })
  const handleMouseOut = (row) => {
    onToggleEditMode(row.id)
    updateRow(row.id);
    setInputValue(null)
  };

  const onChange = (e, row) => {
    if (!previous[row.id]) {
      setPrevious((state) => ({ ...state, [row.id]: row }));
    }
    const value = e.target.value;
    const name = e.target.name;
    const { id } = row;
    rows.map((row, idx) => {
      if (row.id === id) {
        const rowClone = { ...row, [name]: value };
        rows[idx] = rowClone;
      }
    });
    setRows([...rows]);
    onToggleEditMode(row.id);
    updateRow(row.id);
  };

  const onRevert = (id) => {
    const newRows = rows.map((row, idx) => {
      if (row.id === id) {
        rows[idx] = previous[id] ? previous[id] : row;
      }
      return row;
    });
    setRows(newRows);
    setPrevious((state) => {
      delete state[id];
      return state;
    });
    onToggleEditMode(id);
  };

  const updateRow = (id) => {
    const rowUpdate = rows.find((row) => row.id === id);
    // const now = moment().format("DD/MM/YYYY");
    if (rowUpdate) {
      const data = {
        id,
        paid: Boolean(rowUpdate.paid),
        paid_date: moment().format("DD/MM/YYYY"),
        ref_code: rowUpdate.ref_code,
      };
      dispatch(updateOrder(data));
    }
  };

  const handleAddCredit = (order) => {
    setDetailCredit({
      order_no: order.order_no,
      amount: order.total,
      ref_code: order.ref_code,
    });
    setCreateOpen(true);
  };


  const [openModalCall, setOpenModalCall] = React.useState(false);

  const dataUser = useSelector((item) => item.UsersReducer);

  const handleOpenModalCall = (phone) => {
    setPhone(phone);
    setOpenModalCall(true);
  };

  const handleCloseModalCall = () => {
    setOpenModalCall(false);
    dispatch(updateUnregisterCall());
  };

  const handleRegistering = () => {
    dispatch(updateRegisteringCall());
  };

  const handleCall = (phone) => {
    dispatch(startCall(phone));
    setOpenModalCall(false);
  };


  return (
    <Paper
        sx={{ width: "100%", mb: 2 }}
        elevation={0}
        className={`${classes.tableBorder}`}
      >
    <TableContainer style={{ width: "100%", overflowX: "auto",borderRadius:'0.5rem' }} >
      <Table sx={{ minWidth: 700 }} size={"small"}>
        <TableHead>
          <TableRow>
            <TableHeaderCell align="left">STT</TableHeaderCell>
            <TableHeaderCell align="left" style={{ minWidth: 130 }}>
              Mã đơn hàng
            </TableHeaderCell>
            <TableHeaderCell align="left" style={{ minWidth: 150 }}>
              Tên khách hàng
            </TableHeaderCell>
            <TableHeaderCell align="left" style={{ minWidth: 120 }}>
              Số điện thoại
            </TableHeaderCell>
            <TableHeaderCell align="left" style={{ minWidth: 125 }}>
              Tổng tiền
            </TableHeaderCell>
            <TableHeaderCell align="left" style={{ minWidth: 125 }}>
              Khuyến mãi
            </TableHeaderCell>
            <TableHeaderCell align="left" style={{ minWidth: 125 }}>
              Thanh toán
            </TableHeaderCell>
            <TableHeaderCell align="left" style={{ minWidth: 125 }}>
              Mã chứng từ
            </TableHeaderCell>
            <TableHeaderCell
              align="left"
              style={{ minWidth: 125 }}
            >
              Hành động
            </TableHeaderCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {!ordersData.loading ? (
            rows?.map((row, index) => {
              return (
                <TableRow hover={true} key={row.name}>
                {/* <TableCell align="left">
                    {row.isEditMode ? (
                      <>
                        <IconButton
                          aria-label="done"
                          onClick={() => {
                            updateRow(row.id);
                            onToggleEditMode(row.id);
                          }}
                        >
                          <DoneIcon />
                        </IconButton>
                        <IconButton
                          aria-label="revert"
                          onClick={() => onRevert(row.id)}
                        >
                          <HighlightOffIcon />
                        </IconButton>
                      </>
                    ) : (
                      <IconButton
                        aria-label="delete"
                        onClick={() => onToggleEditMode(row.id)}
                      >
                        <EditIcon />
                      </IconButton>
                    )}
                  </TableCell> */}

                  {/* <TableCell padding="checkbox">
                                        <Checkbox
                                            color="primary"
                                            onClick={(event) => {
                                                event.stopPropagation();
                                            }}
                                        />
                                    </TableCell> */}
                  <TableCell align="center">{index + 1}</TableCell>
                  <TableCell align="left">{row.order_no}</TableCell>
                  <TableCell style={{ width: 160 }} align="left">
                    {row.lead.lead.fullname}
                  </TableCell>
                  <TableCell align="left">
                    <WrapCellPhone>
                      <Tooltip title="Gọi ngay số này" arrow>
                        <div
                          className={`${classes.boxPhone}`}
                          onClick={() =>
                                  handleOpenModalCall(row.lead.lead.phone)
                                }
                        >
                          <IconPhone className="phone-icon">
                            <svg
                              xmlns="http:www.w3.org/2000/svg"
                              aria-hidden="true"
                              role="img"
                              width="18px"
                              height="18px"
                              preserveAspectRatio="xMidYMid meet"
                              viewBox="0 0 1024 1024"
                            >
                              <path
                                fill="currentColor"
                                d="M885.6 230.2L779.1 123.8a80.83 80.83 0 0 0-57.3-23.8c-21.7 0-42.1 8.5-57.4 23.8L549.8 238.4a80.83 80.83 0 0 0-23.8 57.3c0 21.7 8.5 42.1 23.8 57.4l83.8 83.8A393.82 393.82 0 0 1 553.1 553A395.34 395.34 0 0 1 437 633.8L353.2 550a80.83 80.83 0 0 0-57.3-23.8c-21.7 0-42.1 8.5-57.4 23.8L123.8 664.5a80.89 80.89 0 0 0-23.8 57.4c0 21.7 8.5 42.1 23.8 57.4l106.3 106.3c24.4 24.5 58.1 38.4 92.7 38.4c7.3 0 14.3-.6 21.2-1.8c134.8-22.2 268.5-93.9 376.4-201.7C828.2 612.8 899.8 479.2 922.3 344c6.8-41.3-6.9-83.8-36.7-113.8z"
                              />
                            </svg>
                          </IconPhone>

                          <p style={{ margin: 0 }}>
                          {
                                      checkEnablePhone === true ?
                                      (
                                        replaceMidd(row.lead.lead.phone,0)
                                      ) : (
                                        replaceMidd(row.lead.lead.phone,3)
                                      )
                                    }
                          </p>
                        </div>
                      </Tooltip>
                    </WrapCellPhone>
                  </TableCell>
                  <TableCell align="left">
                    {renderWithCommand(row.total)} VNĐ
                  </TableCell>
                  <TableCell align="left">
                    {renderWithCommand(row.discount)} VNĐ
                  </TableCell>
                  <TableCell align="left">
                    {!row.isEditMode ? (
                      row.paid ? (
                        <Tooltip title="Click Edit">
                        <div className='text-status bg-success' onClick={() => onToggleEditMode(row.id)}>
                          {PAID_STATUS[0].title}
                        </div>
                        </Tooltip>
                      ) : (
                        <Tooltip title="Click Edit">
                        <div className='text-status bg-failed' onClick={() => onToggleEditMode(row.id)}>
                          {PAID_STATUS[1].title}
                        </div>
                        </Tooltip>
                      )
                    ) : (
                      <Select
                        label="Trạng thái thanh toán"
                        name="paid"
                        value={row.paid}
                        onChange={(e) => onChange(e, row)}
                        size="small"
                      >
                        {PAID_STATUS?.length > 0
                          ? PAID_STATUS.map((item) => (
                              <MenuItem value={item.value}>
                                {item.title}
                              </MenuItem>
                            ))
                          : null}
                      </Select>
                    )}
                  </TableCell>
                  <TableCell align="left">
                    {row.isEditMode ? (
                      <>
                      <Input
                        style={{ height: "40px", width: "130px" }}
                        value={inputValue}
                        name={"ref_code"}
                        onChange={(e) => handleInputChange(e, row)}
                        onBlur={() => handleMouseOut(row)}
                      />
                      </>
                    ) : (
                      <Tooltip title="Click Edit">
                      <div onClick={() => onToggleEditMode(row.id)}>{row.ref_code}</div>
                      </Tooltip>
                     
                    )}
                  </TableCell>
                  <TableCell align="left">
                    <CustomButton
                      variant="contained"
                      className="search-row-button"
                      onClick={() => handleAddCredit(row)}
                    >
                      <div style={{ marginRight: 2 }}>Tạo công nợ</div>
                    </CustomButton>
                  </TableCell>
                </TableRow>
              );
            })
          ) : (
            <h1>Không có dữ liệu</h1>
          )}
        </TableBody>
      </Table>

      <TablePagination
        rowsPerPageOptions={[10, 25, { label: "All", value: -1 }]}
        colSpan={3}
        labelRowsPerPage={"Số hàng trên 1 trang:"}
        count={ordersData?.dataList?.orders_count}
        rowsPerPage={rowsPerPage}
        page={page}
        SelectProps={{
          inputProps: {
            "aria-label": "rows per page",
          },
          native: true,
        }}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        ActionsComponent={TablePaginationActions}
      />
      {openModalCall && (
        <div className="pu-phone">
          <div className="text">
            Cuộc gọi sẽ kết nối tới tổng đài. Bạn có muốn thực hiện cuộc gọi đến
            số
            <span style={{ fontWeight: 600 }}>
              {" "}
              {checkEnablePhone === true
                ? replaceMidd(phone, 0)
                : replaceMidd(phone, 3)}
            </span>
          </div>
          <div style={{ display: "flex", marginTop: 5 }}>
            {dataUser && dataUser.registered === "unregistered" && (
              <CustomButton
                // color="primary"
                variant="contained"
                type="submit"
                onClick={() => handleRegistering()}
                style={{ marginLeft: "auto" }}
              >
                Đồng ý
              </CustomButton>
            )}
            {dataUser && dataUser.registered === "registered" && (
              <CustomButton
                // color="primary"
                variant="contained"
                type="submit"
                onClick={() => handleCall(phone)}
                style={{ marginLeft: "auto" }}
              >
                Gọi
              </CustomButton>
            )}
            <CustomButton
              // color="primary"
              variant="contained"
              type="submit"
              style={{ marginLeft: 10 }}
              onClick={handleCloseModalCall}
            >
              Kết thúc
            </CustomButton>
          </div>
        </div>
      )}
      <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        open={createOpen}
        onClose={handleCloseCreateCreditDialog}
        style={{ minHeight: "100%" }}
        classes={{
            paper: classes.customModal
          }}
      >
        <DialogTitle className={`${classes.customModalTitle}`}>
          Tạo công nợ
          <IconButton
            onClick={handleCloseCreateCreditDialog}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: '#fff',
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className={`${classes.customModalContent}`}>
          <DiaLogAddCredit
            setCreateOpen={setCreateOpen}
            setDetailCustomer={setDetailCredit}
            detailCredit={detailCredit}
            setDataPagination={setDataPagination}
            dataPagination={dataPagination}
          />
        </DialogContent>
      </Dialog>
    </TableContainer>
  </Paper>
    // </TableContainer>
  );
};

export default OrderListTable;
