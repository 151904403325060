export const GET_CREDITS = "GET_CREDITS";
export const GET_CREDITS_SUCCESS = "GET_CREDITS_SUCCESS";
export const GET_CREDITS_FAIL = "GET_CREDITS_FAIL";
export const UPDATE_CREDIT = "UPDATE_CREDIT";
export const UPDATE_CREDIT_SUCCESS = "UPDATE_CREDIT_SUCCESS";
export const UPDATE_CREDIT_FAIL = "UPDATE_CREDIT_FAIL";
export const CREATE_CREDIT = "CREATE_CREDIT";
export const CREATE_CREDIT_SUCCESS = "CREATE_CREDIT_SUCCESS";
export const CREATE_CREDIT_FAIL = "CREATE_CREDIT_FAIL";
export const RESET_MESSAGE = "RESET_MESSAGE";
export const RESET_MESSAGE_ERROR = "RESET_MESSAGE_ERROR";
