
import * as types from "./type"

// for create pbx

export const createPbx = (data) => ({
  type: types.CREATE_PBX,
  payload: data
})

export const createPbxSuccess = (data) => ({
  type: types.CREATE_PBX_SUCCESS,
  payload: data
})
export const createPbxFail = (data) => ({
  type: types.CREATE_PBX_FAIL,
  payload: data
})




export const resetMessage = (data) => ({
  type: types.RESET_MESSAGE,
  payload: data
})

export const resetMessageError = (data) => ({
  type: types.RESET_MESSAGE_ERROR,
  payload: data
})
