import * as types from './type'

const initialState = {
  data: [],
  dataList: [],
  loading: false,
  createSuccess: false,
  editLoad: false,
  deleteLoad:false,
}

export const smsbotReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_SMSBOT:
      return {
        ...state,
        ...{ loading: true, createSuccess: false },
      }

    case types.GET_SMSBOT_SUCCESS:
      return {
        ...state,
        ...{
          dataList: action.payload,
          loading: false
        }
      }

    case types.GET_SMSBOT_FAIL:
      {
        return {
          ...state,
          ...{ dataList: [], loading: false }
        }
      }

    case types.CREATE_SMSBOT:
      return {
        ...state,
        ...{ loading: true }
      }

    case types.CREATE_SMSBOT_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        createSuccess: true,
      }

    case types.CREATE_SMSBOT_FAIL:
      return {
        ...state,
        loading: false,
        data: action.payload.response.data,
      }

    
    case types.RESET_MESSAGE_ERROR:
      return {
        ...state,
        loading: false,
        data: []
      }
      //edit
      case types.EDIT_SMSBOT: 
      return {
          ...state,
          editLoad: true,

      }
      case types.EDIT_SMSBOT_SUCCESS:
      return {
          ...state,
          editLoad: false,
      }
      //remove
      case types.DELETE_SMSBOT: 
      return {
          ...state,
          deleteLoad: true,

      }
      case types.DELETE_SMSBOT_SUCCESS:
      return {
          ...state,
          deleteLoad: false,
      }
    default:
      return state
  }
}