export const GET_SMSHISTORY = "GET_SMSHISTORY"
export const GET_SMSHISTORY_SUCCESS = "GET_SMSHISTORY_SUCCESS"
export const GET_SMSHISTORY_FAIL = "GET_SMSHISTORY_FAIL"



export const RESET_MESSAGE = "RESET_MESSAGE"
export const RESET_MESSAGE_ERROR = "RESET_MESSAGE_ERROR"


