import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Alert
} from "@mui/material";
import React, { useEffect } from "react";
import { BoxInput, TitleInput, BoxForm } from "./styled";
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { createUserStart, updateUser } from "../../../../../redux/users/action";
import { getDepartments } from "../../../../../redux/Departments/action";
import { getDepartmentsId } from "../../../../../redux/departmentsId/action";
import { red } from "@mui/material/colors";
import { CustomButton } from "../../../../../components/Common/CustomButton";
import { getUserStart } from "../../../../../redux/users/action";



const DialogStaff = ({ setUDO, dataEmp }) => {
  const colorError = red[500];
  const dispatch = useDispatch();
  const [department, setDepartment] = React.useState("");
  const [position, setPosition] = React.useState("");
  const [messageErrorAPI, setMessageErrorAPI] = React.useState("");

  const createUserMsg = useSelector((item) => item.UsersReducer.data);


  

  useEffect(() => {
    if (createUserMsg.error === true) {
      setMessageErrorAPI(createUserMsg.errors.user_errors.email);
      setUDO(true)
    } else {
      setMessageErrorAPI('');
      
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createUserMsg.error]);


  const dataDepartments = useSelector(
    (item) => item.departmentsRuducer.dataList
  );
  const dataPositions = useSelector((item) => item.departmentsIdRuducer.data);

  const handleChange = (event) => {
    setDepartment(event);
    dispatch(getDepartmentsId(event));
  };

  const handlePosition = (event) => {
    setPosition(event);
  };

  useEffect(() => {
    if (dataEmp) {
      setDepartment(dataEmp?.position?.department?.id);
      dispatch(getDepartmentsId(dataEmp?.position?.department?.id));
      setPosition(dataEmp?.position?.id);
    }
  }, [dataEmp]);

  const validationSchema = yup.object(
    !dataEmp
      ? {
          email: yup
            .string()
            .trim()
            .email("Email không hợp lệ")
            .required("Nhập email"),
          //username: yup.string().required("Bạn chưa nhập tên"),
          password: yup.string().required("Bạn chưa nhập passwords"),
          first_name: yup.string().required("Bạn chưa nhập first_name"),
          last_name: yup.string().required("Bạn chưa nhập last_name"),
          departments: yup.string().required("Bạn chưa nhập departments"),
          position: yup.string().required("Bạn chưa nhập positions"),
        }
      : {
          email: yup
            .string()
            .trim()
            .email("Email không hợp lệ")
            .required("Nhập email"),
          username: yup.string().required("Bạn chưa nhập tên"),
          first_name: yup.string().required("Bạn chưa nhập first_name"),
          last_name: yup.string().required("Bạn chưa nhập last_name"),
          departments: yup.string().required("Bạn chưa nhập departments"),
          position: yup.string().required("Bạn chưa nhập positions"),
        }
  );

  const submitForm = (values) => {
    
    if (dataEmp) {
      dispatch(updateUser(values));
      dispatch(getUserStart());
      setUDO(false);
    } else {
      const newValues = {...values};
      newValues.username = values.email;
      dispatch(createUserStart(newValues));
      dispatch(getUserStart());
      setUDO(false)
    }
    
  };


  const formik = useFormik({
    initialValues: !dataEmp
      ? {
          email: "",
          first_name: "",
          last_name: "",
          username: "",
          password: "",
          departments: "",
          position: "",
          phone:"",
        }
      : {
          id: dataEmp.id,
          email: dataEmp.email,
          first_name: dataEmp.first_name,
          last_name: dataEmp.last_name,
          username: dataEmp.username,
          password: "",
          departments: dataEmp?.position?.department?.id,
          position: dataEmp?.position?.id,
          phone:dataEmp?.phone,
        },
    validationSchema: validationSchema,
    onSubmit:submitForm,
  });

  useEffect(() => {
    dispatch(getDepartments());
  }, []);

  return (
    <>
      {messageErrorAPI && (
        <Alert style={{marginTop:'10px'}} severity="error">{messageErrorAPI}</Alert>
          )}
          <form onSubmit={formik.handleSubmit}>
      <Grid container columnSpacing={{ xs: 1, sm: 1.5 }}>
        
        <Grid item xs={12} sm={6}>
          <BoxInput>
            <TitleInput>
              Họ <span style={{ color: colorError }}>*</span>
            </TitleInput>
            <TextField
              id="last_name"
              name="last_name"
              size="small"
              fullWidth
              placeholder="Họ *"
              value={formik.values.last_name}
              onChange={formik.handleChange}
              error={
                formik.touched.last_name && Boolean(formik.errors.last_name)
              }
            />
          </BoxInput>
        </Grid>
        <Grid item xs={12} sm={6}>
          <BoxInput>
            <TitleInput>
              Tên <span style={{ color: colorError }}>*</span>
            </TitleInput>
            <TextField
              id="first_name"
              name="first_name"
              size="small"
              fullWidth
              placeholder="Tên *"
              value={formik.values.first_name}
              onChange={formik.handleChange}
              error={
                formik.touched.first_name && Boolean(formik.errors.first_name)
              }
            />
          </BoxInput>
        </Grid>
        <Grid item xs={6}>
          <BoxInput>
            <TitleInput>
              SĐT <span style={{ color: colorError }}>*</span>
            </TitleInput>
            <TextField
              id="phone"
              name="phone"
              size="small"
              fullWidth
              placeholder="SĐT *"
              value={formik.values.phone}
              onChange={formik.handleChange}
              error={formik.touched.phone && Boolean(formik.errors.phone)}
            />
          </BoxInput>
        </Grid>
        <Grid item xs={6}>
          <BoxInput>
            <TitleInput>
              Email <span style={{ color: colorError }}>*</span>
            </TitleInput>
            <TextField
              id="email"
              name="email"
              size="small"
              fullWidth
              placeholder="Email *"
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
            />
          </BoxInput>
        </Grid>
        {
          dataEmp ?
          <>
          
          <BoxInput>
           
            <TextField
              id="username"
              name="username"
              size="small"
              fullWidth
              placeholder="Tên đăng nhập *"
              value={formik.values.username}
              onChange={formik.handleChange}
              error={formik.touched.username && Boolean(formik.errors.username)}
              hidden
            />
          </BoxInput>
        
          </> : null
        }

        
        <Grid item xs={12} sm={6}>
          <BoxInput>
            <TitleInput>
              Mật khẩu <span style={{ color: colorError }}>*</span>
            </TitleInput>
            <TextField
              id="password"
              type="password"
              name="password"
              size="small"
              fullWidth
              placeholder="Mật khẩu *"
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.touched.password && Boolean(formik.errors.password)}
            />
          </BoxInput>
        </Grid>
        <Grid item xs={12} sm={6}>
          <BoxInput>
            <TitleInput>
              Phòng ban <span style={{ color: colorError }}>*</span>
            </TitleInput>
            <BoxForm>
              <FormControl sx={{ m: 1, margin: "none" }} fullWidth>
                <InputLabel id="demo-simple-select-helper-label">
                  Phòng ban
                </InputLabel>
                <Select
                  labelId="demo-simple-select-helper-label"
                  id="departments"
                  name="departments"
                  value={department}
                  label="Phòng ban"
                  onChange={(e) => {
                    formik.setFieldValue("departments", e?.target?.value);
                    handleChange(e?.target?.value);
                  }}
                  style={{ height: "37px" }}
                  error={
                    formik.touched.departments &&
                    Boolean(formik.errors.departments)
                  }
                >
                  {dataDepartments?.length > 0 &&
                    dataDepartments?.map((item) => (
                      <MenuItem value={item.id}>{item.name}</MenuItem>
                    ))}
                </Select>
              </FormControl>
            </BoxForm>
          </BoxInput>
        </Grid>
        <Grid item xs={12} sm={6}>
          <BoxInput>
            <TitleInput>Chức vụ <span style={{ color: colorError }}>*</span></TitleInput>
            <BoxForm>
              <FormControl sx={{ m: 1, margin: "none" }} fullWidth>
                <InputLabel id="demo-simple-select-helper-label">
                  Chức vụ
                </InputLabel>
                <Select
                  labelId="positions"
                  id="positions"
                  name="positions"
                  value={position}
                  label="Chức vụ"
                  // onChange={handleChucVu}
                  onChange={(e) => {
                    formik.setFieldValue("position", e?.target?.value);
                    handlePosition(e?.target?.value);
                  }}
                  style={{ height: "37px" }}
                  error={
                    formik.touched.position && Boolean(formik.errors.position)
                  }
                >
                  {dataPositions?.map((item) => (
                    <MenuItem value={item.id}>{item.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </BoxForm>
          </BoxInput>
        </Grid>

        
        <Grid item xs={12}>
          <BoxInput>
            <CustomButton
              //color="primary"
              variant="contained"
              type="submit"
              onClick={formik.handleSubmit}
              style={{ marginLeft: "auto" }}
            >
            {
              
          !dataEmp ? 'Tạo' : 'Lưu'
            }
              
            </CustomButton>
          </BoxInput>
        </Grid>
      </Grid>
    </form>
    </>
   
  );
};

export default DialogStaff;
