import * as types from "./type"

export const getListCity = (data) => ({
    type: types.GET_LIST_CITY,
    payload: data
})

export const getListCitySuccess = (data) => ({
    type: types.GET_LIST_CITY_SUCCESS,
    payload: data
})

export const getListCityFail = (data) => ({
    type: types.GET_LIST_CITY_FAIL,
    payload: data
})