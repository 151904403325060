import { styled } from "@mui/material";

export const WrapCellPhone = styled("div")(({ theme }) => ({
    display: "flex", 
    flexDirection:'column',
    alignItems:'center'
}));

export const IconPhone = styled("span")(({ theme }) => ({
    color: process.env.NODE_ENV === 'development' ? "#5a8dee" : process.env.REACT_APP_PRODUCT_BASE_COLOR,
    fontSize:12,
    transform:'rotate(90deg)',
    marginRight:'5px'
}));
