import { Button, Checkbox, Grid, TextField } from "@mui/material";
import React, { useState } from "react";
import { BoxInput, TitleInput } from "./styled";
import { CustomButton } from "../../../../../components/Common/CustomButton";
import { useFormik } from "formik";
import * as yup from "yup";
import { postProducts } from "../../../../../redux/products/action";
import { useDispatch } from "react-redux";

const DialogWareHouse = ({ setUDO }) => {
  const [recurring, setRecurring] = useState(false);

  const dispatch = useDispatch();

  const validationSchema = yup.object({
    title: yup.string().required("Bạn chưa nhập tên sản phẩm"),
  });

  const handleRecurring = (e) => {
    setRecurring(e.target.checked);
  };

  const formik = useFormik({
    initialValues: {
      title: "",
      description: "",
      price: "",
      recurring_fee: recurring,
      product_type: false,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      dispatch(postProducts(values));
      setUDO(false);
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={12}>
          <BoxInput>
            <TitleInput>Sản phẩm *</TitleInput>
            <TextField
              id="title"
              name="title"
              size="small"
              fullWidth
              placeholder="Sản phẩm"
              value={formik.values.title}
              onChange={formik.handleChange}
              error={formik.touched.title && Boolean(formik.errors.title)}
            />
          </BoxInput>
          <BoxInput style={{ display: "flex", marginBottom: 0 }}>
            <TitleInput>Phí định kỳ</TitleInput>
            <div style={{ width: "calc(100% - 100px)", marginTop: "-10px" }}>
              <Checkbox
                id="recurring_fee"
                name="recurring_fee"
                // value={formik.values.recurring_fee}
                // onChange={formik.handleChange}
                onChange={(e) => handleRecurring(e)}
              />
            </div>
          </BoxInput>
          <BoxInput>
            <TitleInput>Mô tả sản phẩm</TitleInput>
            <TextField
              id="description"
              name="description"
              size="small"
              fullWidth
              placeholder="Mô tả sản phẩm"
              value={formik.values.description}
              onChange={formik.handleChange}
            />
          </BoxInput>
          <BoxInput>
            <TitleInput>Giá bán *</TitleInput>
            <TextField
              id="price"
              name="price"
              size="small"
              fullWidth
              placeholder="Giá bán"
              value={formik.values.price}
              onChange={formik.handleChange}
            />
          </BoxInput>
          <BoxInput>
            <CustomButton
              color="primary"
              variant="contained"
              type="submit"
              onClick={formik.handleSubmit}
              style={{marginLeft:'auto'}}
            >
              Thêm mới sản phẩm
            </CustomButton>
          </BoxInput>
        </Grid>
      </Grid>
    </form>
  );
};

export default DialogWareHouse;
