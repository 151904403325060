import axiosInstance from "../../components/axiosApi"

const SourceLeadApi = {
    getLeadSource: async (value) => {
        return axiosInstance.get(`/leads/sourcessub/${value}/`)
    },
    getLeadSubSource: async (idParent) => {
        return axiosInstance.get(`/leads/sourcessub/${idParent}/`)
    }
}

export default SourceLeadApi;