import * as React from 'react';
import Stack from '@mui/material/Stack';
// import ButtonUnstyled, { buttonUnstyledClasses } from '@mui/base/ButtonUnstyled';
import { styled } from '@mui/system';
import { ButtonUnstyled, buttonUnstyledClasses } from '@mui/material';

const blue = {
  500:  process.env.NODE_ENV === 'development' ? "#5a8dee" : process.env.REACT_APP_PRODUCT_BASE_COLOR,
  600: '#0a875c',
  700: '#037851',
};

const CustomButtonRoot = styled('button')`
  font-size: 0.875rem;
  background-color: ${blue[500]};
  padding: 0 10px;
  height:36px;
  line-height:36px;
  border-radius: 6px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 150ms ease;
  cursor: pointer;
  border: none;
  font-weight:700;
  min-width: 120px;
  box-shadow: ${process.env.NODE_ENV === 'development' ? '0 2px 6px 0 rgb(59 130 246 / 50%)' 
  : process.env.REACT_APP_PRODUCT_SHADOW_COLOR};
  &:hover {
    background-color: ${blue[500]};
    color: white;
  }

  &.${buttonUnstyledClasses.active} {
    background-color: ${blue[500]};
  }

  &.${buttonUnstyledClasses.focusVisible} {
    box-shadow: 0 4px 20px 0 rgba(61, 71, 82, 0.1), 0 0 0 5px rgba(0, 127, 255, 0.5);
    outline: none;
  }

  &.${buttonUnstyledClasses.disabled} {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

// export function CustomButton(props) {
//   return <ButtonUnstyled {...props} component={CustomButtonRoot} />;
// }
export const CustomButton = React.forwardRef((props, ref) => (
   <ButtonUnstyled ref={ref} {...props} component={CustomButtonRoot} />
));
