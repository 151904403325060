import * as types from './type'

const initialState = {
    success: false,
    loading: false,
    error: false,
    editStatus: false
}

export const addCustomerReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.ADD_CUSTOMER_START: 
            return {
                ...state,
                loading: true,
                success: false,
                error: false,
                editStatus: false
            }
        
        case types.ADD_CUSTOMER_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                error: false,
                editStatus: false
            }
        
        case types.ADD_CUSTOMER_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: true,
                editStatus: false
            }
        case types.EDIT_ORDER_SUCCESS: 
            return {
                ...state,
                loading: false,
                success: false,
                error: false,
                editStatus: true
            }
        default:
            return state
    }
}