import { put, takeLatest } from "redux-saga/effects";
import * as types from './type';
import * as actions from './action';
import axiosInstance from "../../components/axiosApi";
import { showMessageSuccess } from "../notification/action";

function* getSmsbot() {
  try {
    const res = yield axiosInstance.get(`/sms/data-upload`)
    yield put(actions.getSmsbotSuccess(res.data))
  } catch (error) {
    yield put(actions.getSmsbotFail(error))
  }
}


function* createSmsbot({ payload }) {
  const { name,time_send,temp_id} = payload
  const params = `name=${name}&time_send=${time_send}&temp_id=${temp_id}`
  try {
    const res = yield axiosInstance.post(`/sms/camp-script/?${params}`)
    yield put(actions.createSmsbotSuccess(res.data))
  } catch (error) {
    yield put(actions.createSmsbotFail(error))
  }
}

function* editSmsbot({payload}) {
    const { id,name,time_send,temp_id} = payload
    let params = `id=${id}&name=${name}&time_send=${time_send}&temp_id=${temp_id}`
    try {
        
        const res = yield axiosInstance.put(`/sms/camp-script/?${params}`);
        yield put(actions.editSmsbotSuccess(res.data))
        yield put(showMessageSuccess("Cập nhập thành công"))
        
    } catch (error) {
      yield put(actions.editSmsbotFail(error))
    }
}

function* deleteSmsbot({payload}) {
  try {
      
      const res = yield axiosInstance.delete(`/sms/camp-script/?id=${payload}`);
      yield put(actions.deleteSmsbotSuccess(res.data))
      yield put(showMessageSuccess("Cập nhập thành công"))
      
  } catch (error) {
    yield put(actions.deleteSmsbotFail(error))
  }
}

function* resetMessage() {
  yield put(actions.resetMessageError({}))
}

export function* watchingSmsbot() {
  yield takeLatest(types.GET_SMSBOT, getSmsbot);
  yield takeLatest(types.CREATE_SMSBOT, createSmsbot);
  yield takeLatest(types.RESET_MESSAGE, resetMessage);
  yield takeLatest(types.EDIT_SMSBOT, editSmsbot);
  yield takeLatest(types.DELETE_SMSBOT, deleteSmsbot);
}
