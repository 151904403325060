import React, { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import { Grid, Divider, TextField, Box } from "@mui/material";
import TableDetailReport from "./components/TableDetailReport";
import AssignTo from "../../components/Common/AssignTo";
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";
import { DateRangePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { makeStyles } from "@material-ui/core/styles";
import { BoxInput } from "../Customer/components/DialogCustomer/styled";
import { CustomButton } from "../../components/Common/CustomButton";
import { renderWithCommand } from "../../helpers";
import axiosInstance from "../../components/axiosApi";
import moment from "moment";
import { ChartColumn } from "./components/Chart";
import { ChartBar } from "../Dashboard/components/Chart-Bar";
const useStyles = makeStyles((theme) => ({
  btnIcon: {
    fontSize: 16,
  },
  iconMenuItem: {
    marginRight: 10,
  },
  btnGlobal: {
    minWidth: "inherit",
    width: 36,
    height: 36,
    lineHeight: 36,
    padding: 0,

    marginLeft: 5,
  },
  tableBorder: {
    border: "1px solid #d4d8dd",
    borderRadius: "0.5rem",
  },

  boxPhone: {
    display: "flex",
    borderRadius: "0.3rem",
    padding: "3px 5px",
    color: "#5a8dee",
    alignItems: "center",
    backgroundColor: "#e5edfc",
    cursor: "pointer",
  },
  Pagination: {
    display: "flex",
  },
}));

const Report = () => {
  const classes = useStyles();

  const [dataReportDetail, setDataReportDetail] = useState([]);
  const [dataReportTable, setDataReport] = useState([]);



  const [value, setValue] = React.useState([null, null]);

  const initialValueSearch = {
    fromdate: "",
    todate: "",
    assigned_to: "",
  };
  const [search, setSearch] = useState(initialValueSearch);

  const handleSearch = () => {
   
    getReportDetail();
    if (openSidebar) setOpenSidebar(false);
  };
  const [openSidebar, setOpenSidebar] = useState(false);

  const handleChangeAssign = (event, newValue) => {
    if (newValue != null) {
      
      let assignToId = newValue.id
      let updateSearch = { ...search };
      updateSearch.assigned_to = assignToId;
      setSearch(updateSearch);
  }
  }

  const handleClear = () => {
   
    setSearch({ ...initialValueSearch });
    setValue([null,null])
    getReportDetail();
  }

  const getReportDetail = async () => {
    const { assigned_to, fromdate, todate } = search;

    let params = "";
    params +=
      assigned_to !== "" && assigned_to !== undefined
        ? `assigned_to=${assigned_to}&`
        : "";
    params +=
      fromdate !== "" && fromdate !== undefined ? `fromdate=${fromdate}&` : "";
    params += todate !== "" && todate !== undefined ? `todate=${todate}&` : "";
    try {
      const res = await axiosInstance.get(`/report/report-detail?${params}`);
      if (res.status === 200) {
        setDataReportDetail(res.data.totals);
        setDataReport(res.data.detail);
      }
    } catch (error) {}
  };

  useEffect(() => {
    getReportDetail();
  }, []);

  const getProductsData = () => {
    const data = {
      labels: [],
      count: [],
      count_ordered:[]
    };
    dataReportTable?.map((item) => {
      data.labels.push(item.assigned_fullname);
      data.count.push(item.total_order);
      data.count_ordered.push(item.total_ordered);
      return data
    });
    return data;
  };

  const dataProducts = {
    title: "Tổng đơn hàng",
    labels: getProductsData().labels,
    datasets: [
      {
        barPercentage: 0.5,
        barThickness: 20,
        base: 0,
        maxBarThickness: 30,
        // minBarLength: 2,
        data: getProductsData().count,
        backgroundColor: "rgba(90, 141, 238, 0.85)",
        borderColor: "rgba(90, 141, 238, 0.85)",
        borderWidth: 4,
        label: "Đơn hàng" // <-- pass this 
      },
      {
        barPercentage: 0.5,
        barThickness: 20,
        base: 0,
        maxBarThickness: 30,
        // minBarLength: 2,
        data: getProductsData().count_ordered,
        backgroundColor: "rgba(46, 216, 182, 0.85)",
        borderColor: "rgba(46, 216, 182, 0.85)",
        borderWidth: 4,
        label: "Đơn hàng đã chốt" // <-- pass this 
      },
    ],
  };

  const getTotal = () => {
    const data = {
      labels: [],
      count: [],
    };
    dataReportTable?.map((item) => {
      data.labels.push(item.assigned_fullname);
      data.count.push(item.total_money?.total__sum);
      return data
    });
    return data;
  };

  const dataTotal = {
    title: "Tổng doanh thu",
    labels: getTotal().labels,
    datasets: [
      {
        barPercentage: 0.5,
        barThickness: 20,
        base: 0,
        maxBarThickness: 30,
        // minBarLength: 2,
        data: getTotal().count,
        backgroundColor: "rgba(90, 141, 238, 0.85)",
        borderColor: "rgba(90, 141, 238, 0.85)",
        borderWidth: 4,
      },
    ],
  };

  return (
    <div className="screen">
      <Paper className="order-list" style={{ marginBottom: "10px" }}>
        <span
          className="ul-heading"
          style={{ display: "flex", alignItems: "center", fontWeight: 500 }}
        >
          Báo Cáo Tổng Quan
        </span>
      </Paper>

      <div className="box-card">
        <div className="item bg-c-blue">
          <div className="box-content">
            <h3 className="title">Tổng khách hàng</h3>
            <div className="text-bottom">
              <GroupOutlinedIcon />
              <div className="number">{dataReportDetail[0]?.total_data}</div>
            </div>
          </div>
        </div>
        <div className="item bg-c-green">
          <div className="box-content">
            <h3 className="title">Tổng đơn hàng</h3>
            <div className="text-bottom">
              <GroupOutlinedIcon />
              <div className="number">{dataReportDetail[0]?.total_order}</div>
            </div>
          </div>
        </div>
        <div className="item bg-c-yellow">
          <div className="box-content">
            <h3 className="title">Tổng đơn hàng đã chốt</h3>
            <div className="text-bottom">
              <GroupOutlinedIcon />
              <div className="number">{dataReportDetail[0]?.total_ordered}</div>
            </div>
          </div>
        </div>
        <div className="item bg-c-red">
          <div className="box-content">
            <h3 className="title">Tổng tiền</h3>
            <div className="text-bottom">
              <GroupOutlinedIcon />
              <div className="number">
                {renderWithCommand(
                  dataReportDetail[0]?.total_money?.total__sum
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="item bg-c-ibiza">
          <div className="box-content">
            <h3 className="title">Tổng KM</h3>
            <div className="text-bottom">
              <GroupOutlinedIcon />
              <div className="number">
                {renderWithCommand(
                  dataReportDetail[0]?.total_discount?.discount__sum
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="item bg-c-cosmic">
          <div className="box-content">
            <h3 className="title">Tỷ lệ chuyển đổi</h3>
            <div className="text-bottom">
              <GroupOutlinedIcon />
              <div className="number">{dataReportDetail[0]?.converts}</div>
            </div>
          </div>
        </div>
      </div>

      <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={12}>
          <Paper className="order-list">
            <div className="ul-heading-placeholder">
              <input style={{ display: "none" }} id={""} defaultValue={""} />
              <span
                className="ul-heading"
                style={{
                  display: "flex",
                  alignItems: "center",
                  fontWeight: 500,
                }}
              >
                Danh Sách
              </span>
              <div className="box-right">
                <CustomButton
                  variant="contained"
                  className={`${classes.btnGlobal} ${classes.btnBlue}`}
                  onClick={() => setOpenSidebar(!openSidebar)}
                >
                  <FilterAltIcon
                    style={{ width: "1rem", height: "1rem" }}
                    className={`${classes.btnIcon}`}
                  />
                </CustomButton>
                <div
                  className={`boxsearch-sidebar ${openSidebar ? "active" : ""}`}
                >
                  <span
                    className="ul-heading"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      fontWeight: 500,
                    }}
                  >
                    <ArrowBackIosNewOutlinedIcon
                      style={{
                        color: "#5a8dee",
                        marginRight: 5,
                        cursor: "pointer",
                      }}
                      onClick={() => setOpenSidebar(!openSidebar)}
                    />
                    Tìm kiếm
                  </span>
                  <Divider sx={{ display: { xs: "block", sm: "none" } }} />
                  <Grid
                    container
                    rowSpacing={1}
                    columnSpacing={{ xs: 1, sm: 1.5 }}
                    style={{ padding: "0 14px" }}
                  >
                    <Grid item xs={12}>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DateRangePicker
                          inputFormat="dd/MM/yyyy"
                          startText="Từ ngày"
                          endText="Đến ngày"
                          value={value}
                          onChange={(newValue) => {
                            setValue(newValue);
                            let fromdate = moment(newValue[0]).format(
                              "YYYY-MM-DD"
                            );
                            let todate = moment(newValue[1]).format(
                              "YYYY-MM-DD"
                            );

                            let updateSearch = { ...search };
                            updateSearch.fromdate = fromdate;
                            updateSearch.todate = todate;
                            setSearch(updateSearch);
                          }}
                          renderInput={(startProps, endProps) => (
                            <>
                              <TextField
                                {...startProps}
                                size="small"
                                fullWidth
                                style={{ marginTop: 12 }}
                              />
                              <Box sx={{ mx: 1 }}> - </Box>
                              <TextField
                                {...endProps}
                                size="small"
                                fullWidth
                                style={{ marginTop: 12 }}
                              />
                            </>
                          )}
                        />
                      </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12}>
                    <BoxInput style={{ maxWidth: "100%" }}>
                      <AssignTo
                        value={search.assigned_to}
                        onChange={handleChangeAssign}
                      />
                    </BoxInput>
                     
                    </Grid>
                    <Grid item xs={12}>
                    <div style={{display:'flex'}}>

                    <CustomButton
                        style={{ width: "40px", height: "40px" }}
                        variant="contained"
                        className="search-row-button"
                        onClick={handleSearch}
                      >
                        Tìm kiếm
                      </CustomButton>
                      <CustomButton style={{width:'40px',height:'40px',marginLeft:'10px'}}
                        variant="contained"
                        className="search-row-button"
                        onClick={handleClear}
                      >
                      Xoá Bộ Lọc
                    </CustomButton>
                    </div>

                    </Grid>
                  </Grid>
                </div>
              </div>
            </div>
            <TableDetailReport data={dataReportTable}  />
          </Paper>
        </Grid>
      </Grid>

      <Paper className="order-list" style={{ marginBottom: "20px" }}>
        <span
          className="ul-heading"
          style={{ display: "flex", alignItems: "center", fontWeight: 500 }}
        >
          Biểu đồ
        </span>

        

      </Paper>
      
      <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={12} sm={6} >
        <Paper className="order-list">
        <ChartColumn data={dataProducts} />
        </Paper>
        </Grid>

        <Grid item xs={12} sm={6} >
        <Paper className="order-list">
        <ChartBar data={dataTotal} />
        </Paper>
        </Grid>
        </Grid>
        
     
      
    </div>
  );
};

export default Report;
