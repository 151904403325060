import * as types from "./type"

export const getStatusLead = (data) => ({
        type: types.GET_STATUS_LEAD,
        payload: data
})

export const getStatusLeadSuccess = (data) => ({
    type: types.GET_STATUS_LEAD_SUCCESS,
    payload: data
})

export const getStatusLeadFail = (data) => ({
    type: types.GET_STATUS_LEAD_FAIL,
    payload: data
})