import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = (data) => {
  return {
    plugins: {
      legend: {
        display: false,
        position: "top",
      },
      title: {
        display: true,
        text: data.title,
        font: {
          size: 16
        }
      },
      scales: {
        xAxes: [
          {
            barPercentage: 0.8,
          },
        ],
      },
    },
  };
};

export function ChartBar({ data }) {
  return <Bar options={options(data)} data={data} />;
}
