import React from "react";
import {
  Typography
} from "@mui/material";
import { useContext } from 'react';
import { CountContext } from '../Notification/NotifyContent';
import ticket_icon from "../../assets/images/ticket.png";
const NotificationTicketList = () => {
  const dataContext = useContext(CountContext)

  const dataNotify = dataContext.dataNotifyTicket

    return (
      <>
        <h3 className="heading">Thông báo ticket</h3>
        {
          dataNotify?.length > 0 ? 
          <div className="notify-list">
         {
          dataNotify && dataNotify.map((item, index) => (
             <div className="item" key={index}>
               <div className="photo">
               <img src={ticket_icon} alt="" />
               </div>
               <div className="info">
               <div className="source">{item.source}</div>
                <div className="username">{item.recipient}</div>
                
                 <div className="text-msg">{item.content}</div>
                 <span 
                  className="btn-del"
                  onClick={()=> dataContext.handleDel(item.id)}
                  >
                  <i className={`fas fa-check-double ${item.status === 'false' ? '' : 'color-gray'}`}></i>
                  </span>
                 
                 
               </div>
          </div>
           ))
         }
          
        </div>:
        <Typography>Không có thông báo.</Typography>
        }
        
      </>
     )
  }
 

export default NotificationTicketList