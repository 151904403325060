import { Paper, styled } from "@mui/material";

export const BoxOrder = styled("div")(({ theme }) => ({
    display: "flex",
    justifyContent: "space-between",
    padding: "10px 10px",
    "@media (min-width: 480px)": {
        padding: "10px 28px"
    }
    
 }));

export const ItemGrid = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));