import { put, takeLatest } from "redux-saga/effects";
import * as types from './type';
import * as actions from './action';
import axiosInstance from "../../components/axiosApi";
import { showMessageError, showMessageSuccess } from "../notification/action";

function* addCustomer({ payload }) {
    const { orderValues, data } = payload
    const dataFormat = data.map((item) => ({
        idproduct: item.idProduct,
        quantity: item.quantity
    }))
    const {
        phone,
        city,
        ward,
        district,
        fullName,
        address,
        dataStatus,
        describeCustomer,
        debt,
        careContent,
        assignTo,
        singleCode,
        transportFee,
        dataOrigin,
        promotion,
        childStatus,
        total,
        orderPay,
        subDataOrigin,
        callstatus,
        vat,
    } = orderValues
    const params = `fullname=${fullName}&phone=${phone}&assigned_to=${assignTo}&city=${city}&district=${district}&ward=${ward}&address=${address}&description=${describeCustomer}&lead_history=${careContent}&lead_source=${dataOrigin}&lead_source_sub=${subDataOrigin}&lead_status_sub=${childStatus}&items={"data":${JSON.stringify(dataFormat)}}&discount=${promotion}&vat=${vat}&total=${total !== undefined ? total : ""}&lead_status=${dataStatus}&callstatus=${callstatus}`
    try {
        const res = yield axiosInstance.post(`/leads/?${params}`);
        yield put(actions.addCustomerSuccess(res))
        yield put(showMessageSuccess("Thêm mới thành công"))
    } catch (error) {
        yield put(actions.addCustomerError(error))
        yield put(showMessageError("Thêm mới thất bại"))
    }
}

function* editOrder({ payload }) {
    const { orderValues, data } = payload
    const dataFormat = data?.map((item) => ({
        idproduct: item.idProduct,
        quantity: item.quantity
    })) || []
    //console.log(dataFormat)
    const {
        id,
        phone,
        city,
        ward,
        district,
        fullName,
        address,
        dataStatus,
        describeCustomer,
        debt,
        careContent,
        assignTo,
        singleCode,
        transportFee,
        promotion,
        dataOrigin,
        childStatus,
        total,
        orderPay,
        subDataOrigin,
        callstatus,
        vat,
    } = orderValues
    const params = `fullname=${fullName}&phone=${phone}&assigned_to=${assignTo}&city=${city}&district=${district}&ward=${ward}&address=${address}&description=${describeCustomer}&lead_history=${careContent}&lead_source=${dataOrigin}&lead_source_sub=${subDataOrigin}&lead_status_sub=${childStatus}&items={"data":${JSON.stringify(dataFormat)}}&discount=${promotion}&vat=${vat}&total=${total}&lead_status=${dataStatus}&callstatus=${callstatus}`
    try {
        const res = yield axiosInstance.post(`/leads/${id}/?${params}`);
        yield put(actions.editOrderSuccess(res))
        yield put(showMessageSuccess("Cập nhập thành công"))
    } catch (error) {
        yield put(actions.addCustomerError(error))
        yield put(showMessageError("Cập nhập thất bại"))
    }
}

export function* watchingAddCustomer() {
    yield takeLatest(types.ADD_CUSTOMER_START, addCustomer);
    yield takeLatest(types.EDIT_ORDER_START, editOrder);
}