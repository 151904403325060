import styled from "styled-components";

export const Box = styled.div`
    .box-btn-add {
        display: flex;
        justify-content: end;
    }
    .btn-add {
        padding: 5px;
    }
    .MuiButton-root{
        background: ${process.env.NODE_ENV === 'development' ? "#5a8dee" : process.env.REACT_APP_PRODUCT_BASE_COLOR};
        box-shadow: ${process.env.NODE_ENV === 'development' ? '0 2px 6px 0 rgb(59 130 246 / 50%)' : process.env.REACT_APP_PRODUCT_SHADOW_COLOR};
        color: #fff;
        border: none;
    }
`