import * as types from "./type"

export const getNewRevenue = (data) => ({
        type: types.GET_NEW_REVENUE,
        payload: data
})

export const getNewRevenueSuccess = (data) => ({
    type: types.GET_NEW_REVENUE_SUCCESS,
    payload: data
})

export const getNewRevenueFail = (data) => ({
    type: types.GET_NEW_REVENUE_FAIL,
    payload: data
})