import {
    Box,
    Button,
    Checkbox,
    Collapse,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    styled,
    Table,
    TableBody,
    TableCell,
    tableCellClasses,
    TableContainer,
    TableHead,
    TableRow
} from '@mui/material';
import React, { useEffect } from 'react';
import moment from "moment";
import { useDispatch, useSelector } from 'react-redux';
import { getStatusLeadId } from '../../../../../redux/statusLeadId/action';
import DialogStatuId from '../Dialog';
import CloseIcon from "@mui/icons-material/Close";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  customModalContent:{
    paddingLeft:'15px',
    paddingRight:'15px'
  },
  customModal:{
    width:'calc(100% - 20px)',
    marginLeft:'10px',
    marginRight:'10px'
  },
  customModalTitle:{
    padding:'10px 15px',
    color:'#fff',
    background:process.env.NODE_ENV === 'development' ? "#5a8dee" : process.env.REACT_APP_PRODUCT_BASE_COLOR
  },
  notHover:{
    "&:hover": {
      backgroundColor: "transparent"
    }
  }
}));
const TableStatusLead = ({ statusLead }) => {
    const classes = useStyles();
    // console.log(statusLead);

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: 'rgba(38,60,85,.1)',
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
        },
    }));

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        "&:last-child td, &:last-child th": {
            border: 0,
        },
    }));

    const [idOpen, setIdOpen] = React.useState();
    const [open, setOpen] = React.useState(false);
    const [statusId, setStatusId] = React.useState();
    const [userDetailOpen, setUDO] = React.useState(false);

    const dispatch = useDispatch();
    const statusLeadIdReducer = useSelector(item => item.statusLeadIdReducer.data);
    const creLoadStatusId = useSelector(item => item.statusLeadIdReducer.creLoadStatusId);

    const handleStatusId = (id) => {
        setOpen(!open)
        setIdOpen(id)
        dispatch(getStatusLeadId(id))
        setStatusId(id)
    }
    const handleAddStatusId = (id) => {
        setUDO(true);
    }
    const handleCloseUserDetailDialog = () => {
        setUDO(!userDetailOpen);
    };

    const Row = (props) => {
        const { row } = props;
        return (
            <React.Fragment>
                <StyledTableRow>
                    <TableCell align="left">{row.title}
                        <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => handleStatusId(row.id)}
                            className={classes.notHover}
                        >
                            <div style={{fontSize: '13px',color: '#f46a6a'}}>({row.total_sub} trạng thái con)</div>
                        </IconButton>
                    </TableCell>
                    <TableCell align="left">{moment(row.created_on).format('DD-MM-YYYY, h:mm A')}</TableCell>
                </StyledTableRow>
                {row.id === idOpen &&
                    <TableRow>
                        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                            <Collapse in={open} timeout="auto" unmountOnExit>
                                <Box sx={{ margin: 1 }}>

                                    <Table size="small" aria-label="purchases">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell></TableCell>
                                                <TableCell style={{ minWidth: 100 }}>Tên trạng thái</TableCell>
                                                <TableCell align="left">Ngày tạo</TableCell>
                                                <TableCell align="left">
                                                    <Button
                                                        variant="outlined"
                                                        className="search-row-button"
                                                        onClick={() => {
                                                            handleAddStatusId(row.id);
                                                        }}
                                                    >
                                                        Thêm mới
                                                    </Button>
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>

                                        <TableBody>
                                            {statusLeadIdReducer?.map((item) => (
                                                <TableRow>
                                                    <TableCell></TableCell>
                                                    <TableCell>{item.title}</TableCell>
                                                    <TableCell align="left">
                                                        {moment(item.created_on).format('DD-MM-YYYY, h:mm A')}
                                                    </TableCell>
                                                    <TableCell></TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </Box>
                            </Collapse>
                        </TableCell>
                    </TableRow>
                }
            </React.Fragment>
        );
    }

    useEffect(() => {
        if(creLoadStatusId){
            dispatch(getStatusLeadId(statusId))
        }
    }, [creLoadStatusId])

    return (
        <TableContainer style={{ width: "100%", overflowX: "auto" ,borderRadius:'0.5rem'}}>
            <Table sx={{ minWidth: 700 }} size={"small"}>
                <TableHead>
                    <TableRow>
                        <StyledTableCell align="left" style={{ minWidth: 100 }}>
                            Tên trạng thái
                        </StyledTableCell>
                        <StyledTableCell align="left" style={{ minWidth: 100 }}>
                            Ngày tạo
                        </StyledTableCell>
                        {/* <StyledTableCell /> */}
                    </TableRow>
                </TableHead>

                <TableBody>
                    {statusLead?.map((row) => (
                        <Row row={row} />
                    ))
                    }
                </TableBody>
            </Table>

            <Dialog
                fullWidth={true}
                maxWidth={"sm"}
                open={userDetailOpen}
                onClose={handleCloseUserDetailDialog}
                style={{ minHeight: "100%" }}
                classes={{
                    paper: classes.customModal
                }}
            >
            <DialogTitle className={`${classes.customModalTitle}`}>
                    Thêm mới trạng thái
                    <IconButton
                        onClick={() => setUDO(false)}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: '#fff',
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>

                <DialogContent className={`${classes.customModalContent}`}>
                    <DialogStatuId setUDO={setUDO} statusId={statusId} />
                </DialogContent>
            </Dialog>
        </TableContainer>
    );
};

export default TableStatusLead;