import { FormControl, Autocomplete, TextField } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";

const AssignTo = ({ value, onChange }) => {
  const stateUser = useSelector((state) => state.UsersReducer);
  const userlist = stateUser?.dataUsers?.active_users;

  return (
    <>
      <FormControl fullWidth>
        <Autocomplete
          options={userlist}
          value={userlist?.filter((x) => x.id === value)[0]}
          sx={{
            "& .MuiAutocomplete-inputFocused": {
              marginTop: "-18px",
            },
          }}
          getOptionLabel={(option) => option.first_name}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          renderInput={(params) => {
            return (
              <TextField
                {...params}
                label="Chọn người phân công"
                variant="outlined"
                fullWidth
              />
            );
          }}
          onChange={onChange}
        ></Autocomplete>
      </FormControl>
    </>
  );
};

export default AssignTo;
