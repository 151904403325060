import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
Box,
IconButton,
  Table,
  TableBody,
  TableContainer,
  TableRow,
  Paper,
  Typography,
  TablePagination
} from "@mui/material";
import Loading from "../../components/Common/Loading";
import LastPageIcon from "@mui/icons-material/LastPage";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import { useTheme } from "@mui/material/styles";
import MuiTableCell from "@material-ui/core/TableCell";
import MuiTableHead from "@mui/material/TableHead";
import { getSmshistory } from "../../redux/smshistory/action";
import moment from "moment";
import "moment/locale/vi";

const useStyles = makeStyles((theme) => ({
    tableBorder: {
      border: "1px solid #d4d8dd",
      borderRadius: "0.5rem",
    },
    btnGlobal: {
      minWidth: "inherit",
      width: 28,
      padding: "0",
      height: "28px",
      lineHeight: "28px",
  
      marginLeft: 5,
      boxShadow: "none",
      fontSize: "12px",
      fontWeight: "500",
    },
    Pagination: {
        display: "flex",
      },
  }));
  
  const TableCell = withStyles({
    root: {
      borderBottom: "1px solid rgb(235, 237, 242)",
      padding: "13px 10px",
      lineHeight: "18px",
    },
  })(MuiTableCell);
  
  const TableHead = withStyles((theme) => ({
    root: {
      backgroundColor: "rgba(38,60,85,.1)",
    },
  }))(MuiTableHead);
  
  const TableHeaderCell = withStyles((theme) => ({
    root: {
      color: "#516377",
      fontWeight: 700,
      padding: "10px",
      fontSize: ".75rem",
      textTransform: "uppercase",
      letterSpacing: "0.8px",
      lineHeight: "18px",
    },
  }))(TableCell);

  function TablePaginationActions(props) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;
  
    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    };
  
    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };
  
    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };
  
    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };
  
    return (
      <Box sx={{ flexShrink: 0, ml: 2.5 }}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </Box>
    );
  }
  
  TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
  };

const SmsHistory = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [isLoading, setLoading] = useState(true);
    
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
  

    const handleChangePage = (event, newPage) => {
        let data = {
         
          offset: newPage * rowsPerPage,
          limit: rowsPerPage,
        };
        dispatch(getSmshistory(data));
        setPage(newPage);
      };
    
      const handleChangeRowsPerPage = (event) => {
        let data = {
          
          offset: page,
          limit: event.target.value !== "-1" ? +event.target.value : 9999,
        };
        dispatch(getSmshistory(data));
        setRowsPerPage(+event.target.value);
        setPage(0);
      };

      const listSmsHistory = useSelector((item) => item.smshistoryReducer.dataList.sms);

    useEffect(() => {
        setTimeout(() => setLoading(false), 500);
        dispatch(getSmshistory())
        // eslint-disable-next-line
      }, []);
  return (
    <>
        <Loading loading={isLoading} />
        {listSmsHistory?.list_sms?.length > 0 ? (
            <>
        <Paper className="order-list" style={{ marginBottom: "10px" }}>
        <div className="ul-heading-placeholder">
          <span
            className="ul-heading"
            style={{ display: "flex", flex: "inherit", fontWeight: 500 }}
          >
            Lịch sử SMS
          </span>
          
        </div>
      </Paper>

      <Paper
        sx={{ width: "100%", mb: 2 }}
        elevation={0}
        className={`${classes.tableBorder}`}
      >
        <TableContainer
          style={{
            width: "100%",
            overflowX: "auto",
            borderRadius: "0.5rem",
          }}
        >
          <Table sx={{ minWidth: 700 }} size={"small"}>
            <TableHead>
              <TableRow>
                <TableHeaderCell align="left" style={{ width: 40 }}>
                  STT
                </TableHeaderCell>

                <TableHeaderCell align="left" style={{ minWidth: 80 }}>
                  SĐT
                </TableHeaderCell>
                
                <TableHeaderCell align="left" style={{ minWidth: 80 }}>
                Thời gian gửi
                </TableHeaderCell>
                <TableHeaderCell align="left" style={{ minWidth: 120 }}>
                nội dung
                </TableHeaderCell>
                <TableHeaderCell align="center" style={{ minWidth: 40 }}>
                trạng thái
                </TableHeaderCell>
                
              </TableRow>
            </TableHead>
            <TableBody>
            {listSmsHistory?.list_sms &&
                listSmsHistory?.list_sms?.map((item, index) => (
                          <TableRow hover={true} key={index}>
                          <TableCell align="left">{index+1}</TableCell>
                            <TableCell align="left">{item?.number}</TableCell>

                            <TableCell align="left">{moment(item?.SendTime).format(
                                "DD/MM/YYYY, H:mm:ss"
                              )}</TableCell>
                            <TableCell align="left">{item?.content}</TableCell>
                            <TableCell align="center">
                            {
                                item?.status === true ? 
                                <>
                                <span style={{'color':'#19A442'}} className='fas fa-check'></span>
                                </> :
                                <>
                                <span style={{'color':'rgb(244, 67, 54)'}} className='fas fa-times'></span>
                                </>
                            }
                    
                            </TableCell>

                          </TableRow>
                        ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
                  rowsPerPageOptions={[10, 25]}
                  className={`${classes.Pagination}`}
                  component="div"
                  colSpan={3}
                  labelRowsPerPage={"Số hàng trên 1 trang:"}
                  count={listSmsHistory?.sms_count}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: {
                      "aria-label": "rows per page",
                    },
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
      </Paper>
      </>
      ) : (
        <Typography>Không có dữ liệu</Typography>
          )}
    </>
  )
}

export default SmsHistory