import React, { useEffect, createRef, useState, useRef } from "react";
import { WebSocketInterface } from "jssip";
import _ from "lodash";
import CallsFlowControl from "./CallsFlowControl";
import Stack from "@mui/material/Stack";
import Slider from "@mui/material/Slider";
import VolumeUp from "@mui/icons-material/VolumeUp";
import Fab from "@mui/material/Fab";
import MicOffIcon from "@mui/icons-material/MicOff";
import MicIcon from "@mui/icons-material/Mic";
import CallIcon from "@mui/icons-material/Call";
import CallEndIcon from "@mui/icons-material/CallEnd";
import PauseIcon from "@mui/icons-material/Pause";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Divider from "@mui/material/Divider";
import BackspaceIcon from "@mui/icons-material/Backspace";
import useLongPress from "./components/useLongPress";
import Avatar from "@mui/material/Avatar";
import { useSelector } from "react-redux";

export function DurationTime(props) {
  const generator = (seconds) => {
    let duration = seconds;
    let hours = duration / 3600;
    duration = duration % 3600;

    let min = parseInt(duration / 60);
    duration = duration % 60;

    let sec = parseInt(duration);

    if (sec < 10) {
      sec = `0${sec}`;
    }
    if (min < 10) {
      min = `0${min}`;
    }
    if (parseInt(hours, 10) > 0) {
      return `${parseInt(hours, 10)}:${min}:${sec}`;
    }
    return `${min}:${sec}`;
  };

  return (
    <>
      {props.calling && props.answered === false ? (
        <Typography style={{ marginTop: 10, color: "#807F7F" }}>
          Đang gọi...
        </Typography>
      ) : props.answered ? (
        <Typography style={{ marginTop: 10, color: "#807F7F" }}>
          Thời lượng: {generator(props.duration)}
        </Typography>
      ) : props.justEnded ? (
        <Typography style={{ marginTop: 10, color: "#ff0000" }}>
          Cuộc gọi đã kết thúc
        </Typography>
      ) : (
        ""
      )}
    </>
  );
}

const flowRoute = new CallsFlowControl();
const player = createRef();
const endCall = createRef();
const ringCall = createRef();

const config = {
  domain: "vnsale.siptrunk.vn", // sip-server@your-domain.io
  uri: "sip:35099@vnsale.siptrunk.vn", // sip:sip-user@your-domain.io
  password: "3dc454083d2fd21bae73a078e2b0b27b44b", //  PASSWORD ,
  ws_servers: "wss://vnsale.siptrunk.vn:58089/ws/", //ws server
  sockets: [new WebSocketInterface("wss://vnsale.siptrunk.vn:58089/ws/")],
  display_name: "***", //jssip Display Name
  debug: true, // Turn debug messages on
  pcConfig: {
    rtcpMuxPolicy: 'negotiate',
    iceServers:
      [
        { urls: ['stun:stun.l.google.com:19302'] }
      ]
  }
};

export default function TestCall({
  callVolume,
  ringVolume,
  setConnectOnStartToLocalStorage,
  setNotifications,
  setCallVolume,
  setRingVolume,
  notifications = true,
  connectOnStart = true,
  timelocale = "UTC",
  asteriskAccounts = [],
}) {
  const defaultSoftPhoneState = {
    displayCalls: [
      {
        id: 0,
        info: "Ch 1",
        hold: false,
        muted: 0,
        autoMute: 0,
        inCall: false,
        inAnswer: false,
        inTransfer: false,
        callInfo: "Ready",
        inAnswerTransfer: false,
        allowTransfer: true,
        transferControl: false,
        allowAttendedTransfer: true,
        transferNumber: "",
        attendedTransferOnline: "",
        inConference: false,
        callNumber: "",
        duration: 0,
        side: "",
        sessionId: "",
      },
    ],
    phoneConnectOnStart: connectOnStart,
    notifications,
    phoneCalls: [],
    connectedPhone: false,
    connectingPhone: false,
    activeCalls: [],
    callVolume,
    ringVolume,
  };
  const [localStatePhone, setLocalStatePhone] = useState(defaultSoftPhoneState);
  const phone = createRef();
  const [value, setValue] = React.useState(100);
  const [muted, setMuted] = useState(false);
  const [inCall, setInCall] = useState(false);
  const [hold, setHold] = useState(false);
  const [callAccepted, setCallAccepted] = useState(false);
  const [callJustEnded, setCallJustEnded] = useState(false);
  const [duration, setDuration] = React.useState(0);
  const durationInterval = useRef(null);
  const timer = useRef(null);
  const [sipStatus, setSipStatus] = useState("connecting");
  const [dialNumber, setDialNumber] = useState("");
  const [, updateState] = React.useState();

  const state = useSelector(state => state.callPhoneReducer)

  flowRoute.activeChanel = localStatePhone.displayCalls[0];
  flowRoute.connectedPhone = sipStatus === "connected";
  flowRoute.engineEvent = (event, payload) => {
    // Listen Here for Engine "UA jssip" events
    switch (event) {
      case "connecting":
        setSipStatus("connecting");
        break;
      case "connected":
        setSipStatus("connected");
        break;
      case "registered":
        break;
      case "disconnected":
        setSipStatus("disconnected");
        break;
      case "registrationFailed":
        break;

      default:
        break;
    }
  };

  const handleChange = (event, newValue) => {
    player.current.volume = parseInt(newValue, 10) / 100;
    setValue(newValue);
  };

  const continuosIncerment = () => {
    setDialNumber(dialNumber.slice(0, -1));
    timer.current = setTimeout(continuosIncerment, 200);
  };

  function timeoutClear() {
    clearTimeout(timer.current);
  }

  useEffect(() => {
    flowRoute.config = config;
    flowRoute.init();
    flowRoute.start();
    try {
      player.current.defaultMuted = false;
      player.current.autoplay = true;
      player.current.volume = 1;
      // player.volume = this.outputVolume;
      flowRoute.player = player;
    } catch (e) {
      console.log(e);
    }
  }, [config, localStatePhone.callVolume, localStatePhone.ringVolume]);

  const handleDuration = () => {
    var seconds = 0;
    durationInterval.current = setInterval(() => {
      seconds++;
      setDuration(seconds);
    }, 1000);
  };

  flowRoute.onCallActionConnection = async (type, payload, data) => {
    switch (type) {
      case "outgoingCall":
        // looks like new call its outgoing call
        // Create object with the Display data of new outgoing call

        const newProgressLocalStatePhone = _.cloneDeep(localStatePhone);
        newProgressLocalStatePhone.displayCalls[0] = {
          ...localStatePhone.displayCalls[0],
          sessionId: payload.id,
        };
        // Save new object into the array with display calls

        setLocalStatePhone((prevState) => ({
          ...prevState,
          displayCalls: newProgressLocalStatePhone.displayCalls,
        }));

        setInCall(true);

        break;

      case "callAccepted":
        setCallAccepted(true);
        handleDuration();
        break;

      case "callEnded":
        phone.current.disabled = false;
        setInCall(false);
        setCallAccepted(false);
        setCallJustEnded(true);
        setTimeout(() => setCallJustEnded(false), 1500);
        setDuration(0);
        clearInterval(durationInterval.current);
        endCall.current.play();
        break;
      case "hold":
        setHold(true);
        break;
      case "unhold":
        setHold(false);
        break;
      default:
        break;
    }
  };

  const handleMicMute = () => {
    flowRoute.setMicMuted();
    setMuted(!muted);
  };

  const handleEndCall = (event) => {
    event.persist();
    phone.current.disabled = false;
    flowRoute.hungup(localStatePhone.displayCalls[0].sessionId);
    setInCall(false);
    clearInterval(durationInterval.current);
    setDuration(0);
    endCall.current.play();
  };

  const handleHold = () => {
    if (hold === false) {
      setHold(true);
      flowRoute.hold(localStatePhone.displayCalls[0].sessionId);
    } else if (hold === true) {
      setHold(false);
      flowRoute.unhold(localStatePhone.displayCalls[0].sessionId);
    }
  };

  const handleDialNumberChange = (event) => {
    event.persist();
    setDialNumber(event.target.value);
  };

  const onLongPressDelete = () => {
    !inCall && setDialNumber((dialNumber) => dialNumber.slice(0, -1));
    timer.current = setTimeout(onLongPressDelete, 100);
  };

  const onClickDelete = () => {
    !inCall && setDialNumber(dialNumber.slice(0, -1));
  };

  const defaultOptions = {
    shouldPreventDefault: true,
    delay: 500,
  };

  const longPressEvent = useLongPress(
    onLongPressDelete,
    onClickDelete,
    defaultOptions
  );

  const followCursor = () => {
    const end = phone.current?.value.length;
    phone.current.setSelectionRange(end, end);
    phone.current.focus();
  };
  
  return (
    <>
      {window.addEventListener("load", function () {
        document.getElementById("deleteBtn").addEventListener("mouseup", () => {
          clearInterval(timer.current);
        });
      })}
      <Paper
        elevation={3}
        className="softphone-dialog"
        style={
          inCall
            ? {
              background:
                "linear-gradient(180deg,rgba(191, 237, 237, 1) 0%,rgba(255, 255, 255, 1) 50%)",
            }
            : { display: "block" }
        }
      >
        <div style={{ padding: 10 }}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: 17,
            }}
          >
            {inCall && (
              <Avatar
                sx={{ width: 100, height: 100 }}
                style={{ marginTop: 20 }}
              />
            )}
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <input
              type={"text"}
              value={dialNumber}
              onChange={handleDialNumberChange}
              ref={phone}
              id="phoneNumberInput"
              autoFocus
              placeholder="Nhập số điện thoại..."
              style={{
                border: "none",
                textAlign: "center",
                width: "78%",
                fontSize: 20,
                marginLeft: 29,
              }}
              autoComplete="off"
            />
            <BackspaceIcon
              {...longPressEvent}
              style={
                !inCall
                  ? {
                    color: "gray",
                    cursor: "pointer",
                  }
                  : { display: "none" }
              }
              id="deleteBtn"
            // onMouseDown={continuosIncerment}
            // onMouseUp={timeoutClear}
            />
          </div>
          <div style={{ textAlign: "center" }}>
            <DurationTime
              calling={inCall}
              answered={callAccepted}
              duration={duration}
              justEnded={callJustEnded}
            />
          </div>
          <div
            style={
              !inCall
                ? {
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  paddingLeft: 10,
                  paddingRight: 10,
                  marginTop: 15,
                }
                : { display: "none" }
            }
          >
            <div
              className="dialpad-number no-select"
              onClick={() => {
                setDialNumber(dialNumber.concat("1"));
                followCursor();
              }}
            >
              <div className="dialpad-number-circle">1</div>
            </div>
            <div
              className="dialpad-number no-select"
              onClick={() => {
                setDialNumber(dialNumber.concat("2"));
                followCursor();
              }}
            >
              <div className="dialpad-number-circle">2</div>
            </div>
            <div
              className="dialpad-number no-select"
              onClick={() => {
                setDialNumber(dialNumber.concat("3"));
                followCursor();
              }}
            >
              <div className="dialpad-number-circle">3</div>
            </div>
            <div
              className="dialpad-number no-select"
              onClick={() => {
                setDialNumber(dialNumber.concat("4"));
                followCursor();
              }}
            >
              <div className="dialpad-number-circle">4</div>
            </div>
            <div
              className="dialpad-number no-select"
              onClick={() => {
                setDialNumber(dialNumber.concat("5"));
                followCursor();
              }}
            >
              <div className="dialpad-number-circle">5</div>
            </div>
            <div
              className="dialpad-number no-select"
              onClick={() => {
                setDialNumber(dialNumber.concat("6"));
                followCursor();
              }}
            >
              <div className="dialpad-number-circle">6</div>
            </div>
            <div
              className="dialpad-number no-select"
              onClick={() => {
                setDialNumber(dialNumber.concat("7"));
                followCursor();
              }}
            >
              <div className="dialpad-number-circle">7</div>
            </div>
            <div
              className="dialpad-number no-select"
              onClick={() => {
                setDialNumber(dialNumber.concat("8"));
                followCursor();
              }}
            >
              <div className="dialpad-number-circle">8</div>
            </div>
            <div
              className="dialpad-number no-select"
              onClick={() => {
                setDialNumber(dialNumber.concat("9"));
                followCursor();
              }}
            >
              <div className="dialpad-number-circle">9</div>
            </div>
            <div
              className="dialpad-number no-select"
              onClick={() => {
                setDialNumber(dialNumber.concat("*"));
                followCursor();
              }}
            >
              <div className="dialpad-number-circle">*</div>
            </div>
            <div
              className="dialpad-number no-select"
              onClick={() => {
                setDialNumber(dialNumber.concat("0"));
                followCursor();
              }}
            >
              <div className="dialpad-number-circle">0</div>
            </div>
            <div
              className="dialpad-number no-select"
              onClick={() => {
                setDialNumber(dialNumber.concat("#"));
                followCursor();
              }}
            >
              <div className="dialpad-number-circle">#</div>
            </div>
          </div>
          <div hidden>
            <audio preload="auto" ref={player} />
          </div>
          <div hidden>
            <audio
              preload="auto"
              src={require("./assets/sounds/end_call.mp3")}
              autoPlay={false}
              defaultmuted="false"
              volume="1"
              ref={endCall}
            />
          </div>
          <div className="dial-buttons">
            {inCall ? (
              <Fab
                size="small"
                style={{ backgroundColor: "#f4f6f8" }}
                onClick={handleMicMute}
              >
                {muted ? <MicOffIcon /> : <MicIcon />}
              </Fab>
            ) : (
              <Fab size="small" disabled>
                <MicIcon />
              </Fab>
            )}
            {inCall === false ? (
              <Fab
                className={"callButton"}
                aria-label="4"
                onClick={() => {
                  flowRoute.call(phone.current?.value);
                  setInCall(true);
                  phone.current.disabled = true;
                }}
              >
                <CallIcon style={{ color: "white" }} />
              </Fab>
            ) : (
              <Fab className={"endCallButton"} onClick={handleEndCall}>
                <CallEndIcon style={{ color: "white" }} />
              </Fab>
            )}
            {callAccepted ? (
              <Fab
                size="small"
                style={{ backgroundColor: "#f4f6f8" }}
                onClick={handleHold}
              >
                {hold ? <PlayArrowIcon /> : <PauseIcon />}
              </Fab>
            ) : (
              <Fab size="small" disabled>
                <PauseIcon />
              </Fab>
            )}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              paddingLeft: 25,
              paddingRight: 35,
            }}
          >
            <VolumeUp style={{ marginRight: 8 }} />
            <Slider size="small" value={value} onChange={handleChange} />
          </div>
        </div>
        <Divider />
        <Stack
          direction={"row"}
          style={{
            justifyContent: "space-between",
            alignItems: "center",
            padding: 10,
          }}
        >
          <Typography style={{ fontSize: 15 }}>TRẠNG THÁI</Typography>
          <span className="sipStatusPlaceholder" style={{ float: "right" }}>
            {sipStatus === "connecting"
              ? "ĐANG KẾT NỐI..."
              : sipStatus === "connected"
                ? "ĐÃ KẾT NỐI"
                : sipStatus === "disconnected"
                  ? "CHƯA KẾT NỐI"
                  : "CHƯA XÁC ĐỊNH"}
          </span>
        </Stack>
      </Paper>
    </>
  );
}
