import { Button, Grid, TextField } from '@mui/material';
import React from 'react';
import { BoxInput, TitleInput } from './styled';
import { CustomButton } from "../../../../../components/Common/CustomButton";
import { useFormik } from 'formik';
import * as yup from "yup";
import { useDispatch } from 'react-redux';
import { createStatusLeadId } from '../../../../../redux/statusLeadId/action';
import { red } from "@mui/material/colors";

const colorError = red[500];
const DialogStatuId = ({setUDO, statusId}) => {

    const dispatch = useDispatch()

    const validationSchema = yup.object({
        title: yup.string().required('Bạn chưa nhập tên trạng thái'),
    });

    const formik = useFormik({
        initialValues: {
            title: "",
            parent_code: statusId,

        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            dispatch(createStatusLeadId(values));
            setUDO(false)
        },
    });

    return (
        <form onSubmit={formik.handleSubmit}>
            <Grid container columnSpacing={{ xs: 1}}>
                <Grid item xs={12}>
                    <BoxInput>
                        <TitleInput>Mã trạng thái <span style={{ color: colorError }}>*</span></TitleInput>
                        <TextField
                            disabled
                            id="parent_code"
                            name="parent_code"
                            size="small"
                            fullWidth
                            value={formik.values.parent_code}
                            onChange={formik.handleChange}
                        />
                    </BoxInput>
                    <BoxInput>
                        <TitleInput>Tên trạng thái <span style={{ color: colorError }}>*</span></TitleInput>
                        <TextField
                            id="title"
                            name="title"
                            size="small"
                            fullWidth
                            placeholder="Tên trạng thái"
                            value={formik.values.title}
                            onChange={formik.handleChange}
                            error={formik.touched.title && Boolean(formik.errors.title)}
                        />
                    </BoxInput>
                    <BoxInput>
                    <CustomButton
                    //color="primary"
                    variant="contained"
                    type="submit"
                    onClick={formik.handleSubmit}
                    style={{marginLeft:'auto'}}
                >
                    Lưu
                </CustomButton>
                    </BoxInput>
                    
                </Grid>
            </Grid>
        </form>
    );
};

export default DialogStatuId;