import { styled } from '@mui/material/styles';

export const TitleInput = styled("p")(({ theme }) => ({
    fontSize: 14,
    fontWeight: "bold",
    marginBottom: 10,
}));

export const BoxInput = styled("div")(({ theme }) => ({
    marginTop:10
}));

export const BoxForm = styled("div")(({ theme }) => ({
}));