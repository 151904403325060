import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Grid, TextField, Paper } from "@mui/material";
import styled from "styled-components";
import { CustomButton } from "../../components/Common/CustomButton";
import Loading from "../../components/Common/Loading";

import { useFormik } from "formik";
import * as yup from "yup";

import axiosInstance from "../../components/axiosApi";
import { useDispatch } from "react-redux";
import { showMessageSuccess, showMessageError } from "../../redux/notification/action";
import blank from "../../assets/images/logo.png";
const URL = `${process.env.REACT_APP_API_URL}`;

export const TitleInput = styled("p")(({ theme }) => ({
  fontSize: 14,
  fontWeight: 500,
  marginBottom: "10px",
  textAlign: "left",
}));

export const BoxInput = styled("div")(({ theme }) => ({
  marginTop: 12,
}));

export const UserProfile = () => {
  const [isLoading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const dataLogoFooter = useSelector((item) => item.dataLogoFooterRuducer.data);
  
  useEffect(() => {
    setTimeout(() => setLoading(false), 500);
    
  }, []);

  const validationSchema = yup.object({


      //  retype_password: yup.string()
      //  .oneOf([yup.ref("password")], "Password's not match")
      //  .required("Required!")
  } 

   );

   const submitForm = async(values,{resetForm}) => {
    const {old_password,new_password,retype_password } = values
    let params = `old_password=${old_password}&new_password=${new_password}&retype_password=${retype_password}`

    try {
      const res = await axiosInstance.post(`/users/change-password/?${params}`);
      if (res.status === 200) {
        dispatch(showMessageSuccess("Cập nhập thành công"));
        resetForm({ values: ''});
      }
    } catch (error) {
      setLoading(false);
      dispatch(showMessageError("Cập nhập thất bại"));
    }
  };

  const formik = useFormik({
    initialValues: {
      old_password: "",
      new_password: "",
      retype_password: "",
    },
    validationSchema: validationSchema,
    onSubmit:submitForm,
  });




  return (
    <>
      <Loading loading={isLoading} />

      <div className="screen">
        
        <Paper className="order-list" style={{ marginBottom: "15px" }}>
          <div className="ul-heading-placeholder">
            <span className="ul-heading" style={{ fontWeight: 500 }}>
              Thông Tin Cá Nhân
            </span>
          </div>
        </Paper>
        <Paper
          className="order-list"
          style={{
            maxWidth: "700px",
            margin: "0 auto",
            marginBottom: "15px",
            padding: "20px",
          }}
        >
          <div className="user-profile">
            <div className="box-avatar">
              <img
                src={
                  dataLogoFooter?.profile_pic
                    ? `${URL}${dataLogoFooter?.profile_pic}`
                    : blank
                }
                alt=""
              />
            </div>
            <div className="box-account-info" style={{ textAlign: "center" }}>
              <div className="user-name">{dataLogoFooter?.username} </div>
              <div className="user-status">{dataLogoFooter?.role} </div>
            </div>
            <div className="box-information">
            <form onSubmit={formik.handleSubmit}>
              <Grid container columnSpacing={{ sm: 1.5 }}>
                <Grid item xs={12} sm={6}>
                  <BoxInput>
                    <TitleInput>Tên</TitleInput>
                    <TextField
                      name="first_name"
                      size="small"
                      fullWidth
                      value={dataLogoFooter?.first_name}
                      disabled
                    />
                  </BoxInput>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <BoxInput>
                    <TitleInput>Họ</TitleInput>
                    <TextField
                      name="last_name"
                      size="small"
                      fullWidth
                      value={dataLogoFooter?.last_name}
                      disabled
                    />
                  </BoxInput>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <BoxInput>
                    <TitleInput>Tên đăng nhập</TitleInput>
                    <TextField
                      name="user_limit"
                      size="small"
                      fullWidth
                      value={dataLogoFooter?.username}
                      disabled
                    />
                  </BoxInput>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <BoxInput>
                    <TitleInput>Email</TitleInput>
                    <TextField
                      name="email"
                      size="small"
                      fullWidth
                      value={dataLogoFooter?.email}
                      disabled
                    />
                  </BoxInput>
                </Grid>
                <Grid item xs={12}>
                  <BoxInput>
                    <TitleInput>Mật khẩu cũ</TitleInput>
                    <TextField
                      name="old_password"
                      type="password"
                      size="small"
                      fullWidth
                      placeholder="Mật khẩu cũ"
                      value={formik.values.old_password}
                      onChange={formik.handleChange}
                    />
                  </BoxInput>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <BoxInput>
                    <TitleInput>Mật khẩu mới</TitleInput>
                    <TextField
                      name="new_password"
                      type="password"
                      size="small"
                      fullWidth
                      placeholder="Mật khẩu mới"
                      value={formik.values.new_password}
                      onChange={formik.handleChange}
                    />
                  </BoxInput>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <BoxInput>
                    <TitleInput>Nhập lại mật khẩu</TitleInput>
                    <TextField
                      name="retype_password"
                      type="password"
                      size="small"
                      fullWidth
                      placeholder="Nhập lại mật khẩu"
                      value={formik.values.retype_password}
                      onChange={formik.handleChange}
                      //error={formik.touched.retype_password && Boolean(formik.errors.retype_password)}
                    />
                  </BoxInput>
                </Grid>
                <Grid item xs={12}>
                  <BoxInput>
                  <CustomButton 
                    variant="contained"
                    type="submit"
                    onClick={formik.handleSubmit}
                    
                    style={{marginLeft:'auto'}}
                    >
                      Thay đổi
                  </CustomButton>
                  </BoxInput>
                </Grid>
              </Grid>
              </form>
            </div>
          </div>
        </Paper>
      </div>
    </>
  );
};
