import React, { useEffect, useState, memo } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  FormControl,
  Autocomplete,
  TextField,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { CustomButton } from "../../components/Common/CustomButton";

import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import styled from "styled-components";
import axiosInstance from "../../components/axiosApi";
import { getSmstemplate } from "../../redux/smstemplate/action";
import {
  showMessageError,
  showMessageSuccess,
} from "../../redux/notification/action";
export const TitleInput = styled("p")(({ theme }) => ({
  fontSize: 14,
  fontWeight: 500,
  marginBottom: 10,
}));

export const BoxInput = styled("div")(({ theme }) => ({
  marginBottom: "10px",
}));

const useStyles = makeStyles((theme) => ({
  textFieldError: {
    "& input": {
      border: "1px solid red",
      color: "red",
    },
    "& p": {
      color: "red",
      marginLeft: 0,
    },
  },
  customModalContent: {
    paddingLeft: "15px",
    paddingRight: "15px",
  },
  customModal: {
    width: "calc(100% - 20px)",
    marginLeft: "10px",
    marginRight: "10px",
  },
  customModalTitle: {
    padding: "10px 15px",
    color: "#fff",
    background: process.env.NODE_ENV === 'development' ? "#5a8dee" : process.env.REACT_APP_PRODUCT_BASE_COLOR,
    marginBottom:'15px',
  },
}));


const SmsSendForm = ({ setUDO1, selected,setSelected, onClose,dataCustomers }) => {
  const newDataCustomermatchSelected = dataCustomers.dataCustomers.open_leads.open_leads.map((n) => n).filter(o1 => selected.some(o2 => o1.id === o2));
  const selectedPhone = newDataCustomermatchSelected.map(item => item?.lead?.phone)
  const classes = useStyles();
  const dispatch = useDispatch();
  const [autocomplete, setAutocomplete] = useState([]);
  //   data sms template

  useEffect(() => {
    dispatch(getSmstemplate())
    // eslint-disable-next-line
  }, [])
  


  const listSmstemp = useSelector(
    (item) => item.smstemplateReducer.dataList.temp_cfg
  );


  const handleAutocomplete = (event, newValue) => {
    if (newValue != null) {
      setAutocomplete(newValue.id);
    }
  };



  const handleSubmit = async() => {
    if (selected.length <= 0) {
      dispatch(showMessageError("Không có dữ liệu khách hàng nào được chọn"));
      return;
    } else{
        let number_params = `{"numbers":${JSON.stringify(selectedPhone)}}`
    
    let params = `numbers=${number_params}&msg_id=${autocomplete}`
    try {
      const res = await axiosInstance.post(`/sms/send-sms/?${params}`);
      if (res.status === 200) {
        dispatch(showMessageSuccess("Gửi tin nhắn thành công"));
        setSelected([])
        setUDO1(false)
      }
    } catch (error) {
      dispatch(showMessageError("Gửi tin nhắn thất bại"));
      setUDO1(false)
    }
      }
    
  }
  



  return (
    <Dialog
      fullWidth={true}
      maxWidth={"xs"}
      open={true}
      onClose={onClose}
      style={{ minHeight: "100%" }}
      classes={{
        paper: classes.customModal,
      }}
    >
      <DialogTitle className={`${classes.customModalTitle}`}>
        Gửi tin nhắn
        <IconButton
          onClick={() => setUDO1(false)}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "#fff",
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={`${classes.customModalContent}`}>
        <BoxInput>
        <TitleInput>Chọn mẫu SMS *</TitleInput>
          <FormControl fullWidth>
            <Autocomplete
              options={listSmstemp}
              sx={{
                "& .MuiAutocomplete-inputFocused": {
                  marginTop: "-18px",
                },
                width: "100%",
                marginTop: "10px",
              }}
              getOptionLabel={(option) => option.name}
              
              renderInput={(params) => {
                return (
                  <TextField
                    {...params}
                    label="Chọn mẫu SMS"
                    variant="outlined"
                    fullWidth
     
                  />
                );
              }}
              onChange={handleAutocomplete}
            ></Autocomplete>
          </FormControl>
        </BoxInput>
        
        <CustomButton
          disabled={selected.length <= 0 ? true : false}
          variant="contained"
          type="submit"
          onClick={() => handleSubmit()}
          style={{ marginLeft: "auto" }}
        >
            
          Gửi
        </CustomButton>
      </DialogContent>
    </Dialog>
  );
};
export default memo(SmsSendForm);
