import React, { useEffect, useState } from "react";
import Loading from "../../../../components/Common/Loading";
import { useDispatch, useSelector } from "react-redux";
import axiosInstance from "../../../../components/axiosApi";
import { showMessageSuccess } from "../../../../redux/notification/action";
import {
  Table,
  TableBody,
  TableContainer,
  TableRow,
  Paper,
} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import AddRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import { CustomButton } from "../../../../components/Common/CustomButton";
import MuiTableCell from "@material-ui/core/TableCell";
import MuiTableHead from "@mui/material/TableHead";
import { makeStyles } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import CallStatusCreate from "./CallStatusCreate";
import CallStatusEdit from "./CallStatusEdit";
import SmsTempDel from '../../../../pages/Sms/SmsTempDel'
import { getCallstatus, deleteCallstatus } from "../../../../redux/callstatus/action";
import moment from "moment";
import "moment/locale/vi";
const useStyles = makeStyles((theme) => ({
  tableBorder: {
    border: "1px solid #d4d8dd",
    borderRadius: "0.5rem",
  },
  btnGlobal: {
    minWidth: "inherit",
    width: 28,
    padding: "0",
    height: "28px",
    lineHeight: "28px",

    marginLeft: 5,
    boxShadow: "none",
    fontSize: "12px",
    fontWeight: "500",
  },
  btnBlue: { backgroundColor: "#556ee6" },
  btnYellow: {
    backgroundColor: "#f1b44c",
  },
  btnRed: {
    backgroundColor: "#f46a6a",
  },
}));

const TableCell = withStyles({
  root: {
    borderBottom: "1px solid rgb(235, 237, 242)",
    padding: "5px 10px",
    lineHeight: "18px",
  },
})(MuiTableCell);

const TableHead = withStyles((theme) => ({
  root: {
    backgroundColor: "rgba(38,60,85,.1)",
  },
}))(MuiTableHead);

const TableHeaderCell = withStyles((theme) => ({
  root: {
    color: "#516377",
    fontWeight: 700,
    padding: "10px",
    fontSize: ".75rem",
    textTransform: "uppercase",
    letterSpacing: "0.8px",
    lineHeight: "18px",
  },
}))(TableCell);

const CallStatus = ({ dataLogoFooter }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(true);
  const [openCreateCallStatus, setOpenCreateCallStatus] = useState(false);
  const [openDelCallStatus, setOpenDelCallStatus] = useState(false);
  const [openEditCallStatus, setOpenEditCallStatus] = useState(false);
  const [item, setItem] = useState([]);
  const [listSelected, setListSelected] = useState([]);


  useEffect(() => {
    setTimeout(() => setLoading(false), 500);
    dispatch(getCallstatus());
    // eslint-disable-next-line
  }, []);

  const listCallStatus = useSelector(
    (item) => item.callstatusReducer.dataList.status
  );

  const delSuccess = useSelector(
    (item) => item.callstatusReducer.deleteLoad
  );

    //   delete
  useEffect(() => {
    if(delSuccess === true){
      dispatch(getCallstatus());
    }
    // eslint-disable-next-line
  }, [delSuccess])

  const handleDel = (id) =>{
    dispatch(deleteCallstatus(id))
    setOpenDelCallStatus(false);
    
}
const handleCloseDelCallStatus = () => {
  setOpenDelCallStatus(false);
};


//   edit

const handleEdit = (item) => {
  setItem(item);
  setOpenEditCallStatus(true);
  
};

const handleCloseEditCallStatus = () => {
  setOpenEditCallStatus(false);
};
  const navigate = (url) => {
    if (url === "/") {
      window.location.href = "/";
    } else {
      window.location.href = "/configuration";
    }
  };
  return (
    <>
      <Loading loading={isLoading} />
      <div className="screen modify-label">
        <CustomButton
          style={{ marginBottom: "15px", minWidth: "inherit", pading: "0 6px" }}
          onClick={() => {
            navigate("/configuration");
          }}
        >
          <KeyboardArrowLeftIcon />
        </CustomButton>
        <Paper className="order-list" style={{ marginBottom: "10px" }}>
          <div className="ul-heading-placeholder">
            <span
              className="ul-heading"
              style={{ display: "flex", flex: "inherit", fontWeight: 500 }}
            >
              Trạng thái cuộc gọi
            </span>
            <CustomButton
              variant="contained"
              onClick={() => {
                setOpenCreateCallStatus(true);
              }}
            >
              <AddRoundedIcon
                style={{ fontSize: "1rem", marginRight: "5px" }}
              />
              Thêm trạng thái cuộc gọi
            </CustomButton>
          </div>
        </Paper>
        <Paper
        sx={{ width: "100%", mb: 2 }}
        elevation={0}
        className={`${classes.tableBorder}`}
      >
        <TableContainer
          style={{
            width: "100%",
            overflowX: "auto",
            borderRadius: "0.5rem",
          }}
        >
          <Table sx={{ minWidth: 700 }} size={"small"}>
            <TableHead>
              <TableRow>
                <TableHeaderCell align="left" style={{ width: 40 }}>
                  STT
                </TableHeaderCell>
              

                <TableHeaderCell align="left" style={{ width: 120 }}>
                Trạng Thái
                </TableHeaderCell>
                
                <TableHeaderCell align="left" style={{ width: 120 }}>
                Ngày Tạo
                </TableHeaderCell>
                
                <TableHeaderCell align="left" style={{ width: 120 }}>
                  Hành động
                </TableHeaderCell>
              </TableRow>
            </TableHead>
            <TableBody>
            {listCallStatus &&
              listCallStatus.map((item, index) => (
                  <TableRow hover={true} key={index}>
                    <TableCell align="left">{index + 1}</TableCell>
                    <TableCell align="left">{item?.title}</TableCell>
                    
                    
                    <TableCell align="left">
                      
                    {item.created_on === null
                            ? ""
                            : moment(item.created_on).format(
                                "Do MMMM YYYY, H:mm:ss"
                              )}
                    </TableCell>
                    <TableCell align="left">
                      <div style={{ display: "flex", marginBottom: "5px" }}>
                        <Tooltip title="Chỉnh sửa">
                          <CustomButton
                            variant="contained"
                            className={`${classes.btnGlobal} ${classes.btnYellow}`}
                            onClick={() => {
                              handleEdit(item);
                             setListSelected(item?.id);
                             
                           }}
                          >
                            <i
                              style={{ fontSize: "10px" }}
                              className="fas fa-pencil-alt"
                            ></i>
                          </CustomButton>
                        </Tooltip>
                        <Tooltip title="Xoá">
                          <CustomButton
                            variant="contained"
                            className={`${classes.btnGlobal} ${classes.btnRed}`}
                            onClick={() => {
                              setOpenDelCallStatus(true);
                                  setListSelected(item?.id);
                            }}
                          >
                            <i
                              style={{ fontSize: "10px" }}
                              className="fas fa-trash"
                            ></i>
                          </CustomButton>
                        </Tooltip>
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
        {openCreateCallStatus && (
          <CallStatusCreate
            onClose={() => {
              setOpenCreateCallStatus(false);
            }}
          />
        )}
        {openEditCallStatus && (
        <CallStatusEdit
          setUDO1={setOpenEditCallStatus}  
          item={item} 
          onClose={handleCloseEditCallStatus} 
          idCallStatus={listSelected}
        />
      )}
        {openDelCallStatus && (
        <SmsTempDel 
          setUDO1={setOpenDelCallStatus}  
          onDel={handleDel}
          idTemp={listSelected}
          onClose={handleCloseDelCallStatus} 
        />
      )}
      </div>
    </>
  );
};

export default CallStatus;
