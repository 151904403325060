export const GET_SMSTEMPLATE = "GET_SMSTEMPLATE"
export const GET_SMSTEMPLATE_SUCCESS = "GET_SMSTEMPLATE_SUCCESS"
export const GET_SMSTEMPLATE_FAIL = "GET_SMSTEMPLATE_FAIL"

export const CREATE_SMSTEMPLATE = "CREATE_SMSTEMPLATE"
export const CREATE_SMSTEMPLATE_SUCCESS = "CREATE_SMSTEMPLATE_SUCCESS"
export const CREATE_SMSTEMPLATE_FAIL = "CREATE_SMSTEMPLATE_FAIL"

export const RESET_MESSAGE = "RESET_MESSAGE"
export const RESET_MESSAGE_ERROR = "RESET_MESSAGE_ERROR"

export const EDIT_SMSTEMPLATE = "EDIT_SMSTEMPLATE"
export const EDIT_SMSTEMPLATE_SUCCESS = "EDIT_SMSTEMPLATE_SUCCESS"
export const EDIT_SMSTEMPLATE_FAIL = "EDIT_SMSTEMPLATE_FAIL"

export const DELETE_SMSTEMPLATE = "DELETE_SMSTEMPLATE"
export const DELETE_SMSTEMPLATE_SUCCESS = "DELETE_SMSTEMPLATE_SUCCESS"
export const DELETE_SMSTEMPLATE_FAIL = "DELETE_SMSTEMPLATE_FAIL"