import * as React from 'react'
import IconButton from "@mui/material/IconButton";
import Badge from "@mui/material/Badge";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import useOutsideClick from "../../hook/useOutsideClick";
import { useContext } from 'react';
import { CountContext } from './NotifyContent';
import Notification from './Notification'
const Notify = () => {
    const dataContext = useContext(CountContext)
    const notifiRef = React.useRef(null);
    const [openNotifi, setOpenNotifi] = React.useState(false);
    useOutsideClick(notifiRef, () => {
      if (openNotifi) setOpenNotifi(false);
     
    });
  return (
    <div className="box-drp" style={{position:'relative'}} ref={notifiRef}>
    <IconButton
    size="large"
    aria-label="account of current user"
    aria-controls="menu-appbar"
    aria-haspopup="true"
    color="inherit"
    onClick={() => setOpenNotifi(!openNotifi)}
  >
    <Badge badgeContent={dataContext.countNotify} sx={{
      "& .MuiBadge-badge": {
        color: '#fff',
        backgroundColor: "rgb(244,106,106)"
      }
    }} >
      <NotificationsOutlinedIcon 
      style={{ color:process.env.NODE_ENV === "development"
                     ? "#5a8dee"
                     : process.env.REACT_APP_PRODUCT_BASE_COLOR 
      }}  
      />
    </Badge>
    </IconButton>
      <div className={`menuExpand notifi-wrapper ${openNotifi ? "active" : ""}`}> 
          <Notification 
            dataNotify={dataContext.dataNotify} 

          />
      </div>
    </div>
  )
}

export default Notify
