import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch } from "react-redux";
import { showMessageError, showMessageSuccess } from "../../redux/notification/action";
import { CustomButton } from "../../components/Common/CustomButton";
import axiosInstance from "../../components/axiosApi";
import ZaloTable from './ZaloTable'
const ZaloList = () => {
    const [dataOa, setDataOa] = useState([]);
    const dispatch = useDispatch();
  
    const handleConnectZalo = async () => {
        
        try {
          const res = await axiosInstance.get("/zalo-create/");
          if (res.status === 200) {
            dispatch(showMessageSuccess("Connect Success"));
            window.open(res.data,'_blank');
            
          }
        } catch (error) {
          dispatch(showMessageError("Lỗi server"));
        }
      };

      useEffect(() => {
        const getZaloOaList = async () => {
            try {
                const res = await axiosInstance.get("/zalo/");
                if (res.status === 200) {
                  setDataOa(res.data)
                }
                if(res.status === -224){
                  dispatch(showMessageError('tài khoản zaloOA chưa nâng cấp'));
                }
              } catch (error) {
                dispatch(showMessageError("Lỗi server"));
              }
        }
        
        getZaloOaList();
    }, []);

    
  return (
    <>
        <CustomButton onClick={() =>
        handleConnectZalo()
      }
      style={{marginBottom:'10px'}}
      >
        Thêm zaloOA
    </CustomButton>
    <ZaloTable 
      data={dataOa} 
      
      />
    </>
  )
}

export default ZaloList