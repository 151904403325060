import { put, takeLatest } from "redux-saga/effects";
import * as types from './type';
import * as actions from './action';
import axiosInstance from "../../components/axiosApi";

function* getNewRecurringRevenue({payload}) {
    try {
        const res = yield axiosInstance.get(`/report/recurring-revenue/${payload}/`);
        yield put(actions.getNewRecurringRevenueSuccess(res.data))
    } catch (error) {
        yield put(actions.getNewRecurringRevenueFail(error))
    }
}

export function* watchingGetNewRecurringRevenue() {
    yield takeLatest(types.GET_NEW_RECURRING_REVENUE, getNewRecurringRevenue);
}