import React, { useEffect, useState, useRef } from "react";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import { Grid } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import LoadingButton from "@mui/lab/LoadingButton";
import axiosInstance from "./components/axiosApi";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  helperText: {
    marginLeft:0,
    
  },
}));
export default function Login() {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const emailRef = useRef();
  const passwordRef = useRef();
  const [emailError, setEmailError] = useState(false);
  const [emailErrorText, setEmailErrorText] = useState("");
  const [passwordError, setPasswordError] = useState(false);
  const [passwordErrorText, setPasswordErrorText] = useState("");

  useEffect(() => {
    if (localStorage.getItem("access_token") !== null) {
      window.location.href = "/";
    }
    document.getElementById("year").innerHTML = new Date().getFullYear();
  });

  const auth = async () => {
    setLoading(true);

    try {
      const response = await axiosInstance.post("/auth/login/", {
        email: emailRef.current?.value,
        password: passwordRef.current?.value,
      });

      if (response.data.access && response.data.refresh) {
        window.location.href = "/";
        setPasswordError(false);
        setPasswordErrorText("");
        setEmailError(false);
        setEmailErrorText("");
        localStorage.setItem("access_token", response.data.access);
        localStorage.setItem("refresh_token", response.data.refresh);
      }

      return response.data;
    } catch (error) {
      setLoading(false);
      if (error.response) {
        //console.log(error.response.data);
        if (emailRef.current?.value === "") {
          setEmailError(true);
          setEmailErrorText("Email không được bỏ trống!");
        } else {
          if (isEmail(emailRef.current?.value) === false) {
            setEmailError(true);
            setEmailErrorText("Email không đúng định dạng!");
          } else {
            setEmailError(false);
            setEmailErrorText("");
          }
        }
        if (passwordRef.current?.value === "") {
          setPasswordError(true);
          setPasswordErrorText("Mật khẩu không được bỏ trống!");
        } else {
          setPasswordError(false);
          setPasswordErrorText("");
          if (
            error.response.data.error === true &&
            error.response.data.errors ===
              "user not avaliable in our records" &&
            isEmail(emailRef.current?.value) === true
          ) {
            setEmailError(true);
            setEmailErrorText("Không tìm thấy tài khoản!");
          } else if (
            error.response.data.error === true &&
            error.response.data.errors === "Email and password doesnot match"
          ) {
            setPasswordError(true);
            setPasswordErrorText("Mật khẩu sai!");
          } else if (
            error.response.data.error === true &&
            error.response.data.password === "This field is required"
          ) {
            setPasswordError(true);
            setPasswordErrorText("Mật khẩu không được bỏ trống!");
          }
        }
      }
      // Only network error comes here
      if (error != "Error: Request failed with status code 400") {
        document.getElementById("error").innerHTML = error;
        document.getElementById("error").style.marginTop = "10px";
      }
    }
  };

  const detectEnter = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      document.getElementById("loginBtn").click();
    }
  };

  const isEmail = (val) => {
    let regEmail =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!regEmail.test(val)) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <>
      <div className="login-box">
        <h1>Đăng nhập vào hệ thống</h1>
        <form>
          <Grid container rowSpacing={2} columnSpacing={{ xs: 1 }}>
            <Grid item xs={12} style={{marginTop:10}}>
              <TextField
                fullWidth
                error={emailError}
                helperText={emailErrorText}
                inputRef={emailRef}
                onKeyUp={detectEnter}
                className="login-input"
                id="email-input"
                label="Địa chỉ Email"
                type="email"
                required
                FormHelperTextProps={{ classes: { root: classes.helperText } }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                error={passwordError}
                helperText={passwordErrorText}
                inputRef={passwordRef}
                onKeyUp={detectEnter}
                className="login-input"
                id="password-input"
                label="Mật khẩu"
                type="password"
                required
                FormHelperTextProps={{ classes: { root: classes.helperText } }}
              />
            </Grid>
          </Grid>

          <FormControlLabel control={<Checkbox />} label="Ghi nhớ đăng nhập" />
          <br />
          <span
            id="error"
            style={{
              display: "flex",
              justifyContent: "center",
              color: "red",
              textAlign: "center",
            }}
          ></span>
          <LoadingButton
            id="loginBtn"
            loading={loading}
            onClick={auth}
            className="login-button"
            variant="contained"
            style={{ background: "#5a8dee" }}
          >
            Đăng nhập
          </LoadingButton>
          <a className="forgot-password" href="./forgot">
            Quên mật khẩu?
          </a>
        </form>
      </div>
      <p className="copyright">
        Copyright ©{" "}
        <a className="company-underline" href="http://vntel.vn/">
          VNTEL
        </a>{" "}
        <span id="year"></span>.
      </p>
    </>
  );
}
