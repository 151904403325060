import { put, takeLatest } from "redux-saga/effects";
import * as types from "./type";
import * as actions from "./action";
import axiosInstance from "../../components/axiosApi";

function* getReports(action) {
  try {
    const res = yield axiosInstance.get(`/report/dashboard/`, {
      params: action.payload,
    });
    yield put(actions.getReportsSuccess(res.data));
  } catch (error) {
    yield put(actions.getReportsFail(error));
  }
}

function* getReportCall(action) {
  let params = ''
  if(action.payload) {
      const {assigned_to, fromdate, todate, offset, limit } = action.payload
      params +=
      assigned_to !== "" && assigned_to !== undefined
          ? `assigned_to=${assigned_to}&`
          : "";
      params +=
        fromdate !== "" && fromdate !== undefined ? `fromdate=${fromdate}&` : "";
      params += todate !== "" && todate !== undefined ? `todate=${todate}&` : "";
      params += `offset=${offset}&limit=${limit}`
  }
  try {
    const res = yield axiosInstance.get(`/report/report-call?${params}`);
    yield put(actions.getReportCallSuccess(res.data));
  } catch (error) {
    yield put(actions.getReportCallFail(error));
  }
}

function* resetMessage() {
  yield put(actions.resetMessageError({}));
}

export function* watchingReports() {
  yield takeLatest(types.GET_REPORTS, getReports);
  yield takeLatest(types.GET_REPORT_CALL, getReportCall);
  yield takeLatest(types.RESET_MESSAGE, resetMessage);
}
