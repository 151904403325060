import * as types from './type'

const initialState = {
    data: [],
    loading: false,
}

export const listCityRuducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_LIST_CITY:
            return {
                ...state,
                ...{ loading: true }
            }
        case types.GET_LIST_CITY_SUCCESS:
            return {
                ...state,
                ...{
                    data: action.payload,
                    loading: false
                }
            }

        case types.GET_LIST_CITY_FAIL:
            {
                return {
                    ...state,
                    ...{ data: [], loading: false }
                }
            }
        default:
            return state
    }
}