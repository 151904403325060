import * as types from "./type"

export const getDepartments = (data) => ({
        type: types.GET_DEPARTMENTS,
        payload: data
})
export const getDepartmentsSuccess = (data) => ({
    type: types.GET_DEPARTMENTS_SUCCESS,
    payload: data
})
export const getDepartmentsFail = (data) => ({
    type: types.GET_DEPARTMENTS_FAIL,
    payload: data
})
//CREATE
export const createDepartments = (data) => ({
        type: types.CREATE_DEPARTMENTS,
        payload: data
})
export const createDepartmentsSuccess = (data) => ({
    type: types.CREATE_DEPARTMENTS_SUCCESS,
    payload: data
})
export const createDepartmentsFail = (data) => ({
    type: types.CREATE_DEPARTMENTS_FAIL,
    payload: data
})

//EDIT
export const editDepartments = (data) => ({
    type: types.EDIT_DEPARTMENTS,
    payload: data
})
export const editDepartmentsSuccess = (data) => ({
    type: types.EDIT_DEPARTMENTS_SUCCESS,
    payload: data
})
export const editDepartmentsFail = (data) => ({
    type: types.EDIT_DEPARTMENTS_FAIL,
    payload: data
})