import { put, takeLatest } from "redux-saga/effects";
import * as types from './type';
import * as actions from './action';
import axiosInstance from "../../components/axiosApi";
import { showMessageSuccess } from "../notification/action";

function* getSmscamp(action) {
  try {
    let params = ''
    if(action.payload) {
      const {  offset, limit } = action.payload
      params += `offset=${offset}&limit=${limit}`
    }
    const res = yield axiosInstance.get(`/sms/camp/?${params}`)
    yield put(actions.getSmscampSuccess(res.data))
  } catch (error) {
    yield put(actions.getSmscampFail(error))
  }
}


function* createSmscamp({ payload }) {
  let params = ''
  const {name,num_total,desc,trangthai,sendTime,temp_id,hot_send,packageId,activate} = payload
  params += name !== "" && name !== undefined ? `name=${name}&`: ""
  params += num_total !== "" && num_total !== undefined ? `num_total=${num_total}&`: ""
  params += desc !== "" && desc !== undefined ? `description=${desc}&`: ""
  params += trangthai !== "" && trangthai !== undefined ? `trangthai=${trangthai}&`: ""
  params += sendTime !== "" && sendTime !== undefined ? `sendTime=${sendTime}&`: ""
  params += temp_id !== "" && temp_id !== undefined ? `temp_id=${temp_id}&`: ""
  params += hot_send !== "" && hot_send !== undefined ? `hot_send=${hot_send}&`: ""
  params += packageId !== "" && packageId !== undefined ? `package=${packageId}&`: ""
  params += activate !== "" && activate !== undefined ? `activate=${activate}&`: ""
  try {
    const res = yield axiosInstance.post(`/sms/camp/?${params}`)
    yield put(actions.createSmscampSuccess(res.data))
  } catch (error) {
    yield put(actions.createSmscampFail(error))
  }
}

function* editSmscamp({payload}) {
  console.log(payload)
    let params = ''
    const {id,name,num_total,desc,trangthai,sendTime,temp_id,hot_send,packageId,activate} = payload
    params += id !== "" && id !== undefined ? `id=${id}&`: ""
    params += name !== "" && name !== undefined ? `name=${name}&`: ""
    params += num_total !== "" && num_total !== undefined ? `num_total=${num_total}&`: ""
    params += desc !== "" && desc !== undefined ? `description=${desc}&`: ""
    params += trangthai !== "" && trangthai !== undefined ? `trangthai=${trangthai}&`: ""
    params += sendTime !== "" && sendTime !== undefined ? `sendTime=${sendTime}&`: ""
    params += temp_id !== "" && temp_id !== undefined ? `temp_id=${temp_id}&`: ""
    params += hot_send !== "" && hot_send !== undefined ? `hot_send=${hot_send}&`: ""
    params += packageId !== "" && packageId !== undefined ? `package=${packageId}&`: ""
    params += activate !== "" && activate !== undefined ? `activate=${activate}&`: ""
    try {
        
        const res = yield axiosInstance.put(`/sms/camp/?${params}`);
        yield put(actions.editSmscampSuccess(res.data))
        yield put(showMessageSuccess("Cập nhập thành công"))
        
    } catch (error) {
      yield put(actions.editSmscampFail(error))
    }
}

function* deleteSmscamp({payload}) {
  try {
      
      const res = yield axiosInstance.delete(`/sms/camp/?id=${payload}`);
      yield put(actions.deleteSmscampSuccess(res.data))
      yield put(showMessageSuccess("Cập nhập thành công"))
      
  } catch (error) {
    yield put(actions.deleteSmscampFail(error))
  }
}

function* resetMessage() {
  yield put(actions.resetMessageError({}))
}

export function* watchingSmscamp() {
  yield takeLatest(types.GET_SMSCAMP, getSmscamp);
  yield takeLatest(types.CREATE_SMSCAMP, createSmscamp);
  yield takeLatest(types.RESET_MESSAGE, resetMessage);
  yield takeLatest(types.EDIT_SMSCAMP, editSmscamp);
  yield takeLatest(types.DELETE_SMSCAMP, deleteSmscamp);
}
