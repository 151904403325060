import React, { useEffect, useState , memo} from "react";
import Dialog from "@mui/material/Dialog";
import { Grid } from "@mui/material";
import TextField from "@mui/material/TextField";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { CustomButton } from "../../../../components/Common/CustomButton";
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { editCompany , getCompanies} from "../../../../redux/company/action";
import * as Yup from "yup";
import { makeStyles } from "@material-ui/core/styles";
import { red } from "@mui/material/colors";
import { BoxInput, TitleInput } from "./styled";

import blank from "../../../../assets/images/logo.png";
const URL = `${process.env.REACT_APP_API_URL}`;
const colorError = red[500];


const useStyles = makeStyles((theme) => ({
  textFieldError: {
    "& input": {
      border: "1px solid red!important",
      color: "red",
      height: "max-content!important",
      width: "max-content!important",
      margin: "16.5px 0",
      padding: "0 14px!important",
    },
    "& p": {
      color: "red",
      marginLeft: 0,
      letterSpacing: 0,
    },
  },
  customModalContent: {
    paddingLeft: "15px",
    paddingRight: "15px",
  },
  customModal: {
    width: "calc(100% - 20px)",
    marginLeft: "10px",
    marginRight: "10px",
  },
  customModalTitle: {
    padding: "10px 15px",
    color: "#fff",
    background: process.env.NODE_ENV === 'development' ? "#5a8dee" : process.env.REACT_APP_PRODUCT_BASE_COLOR,
  },
}));

const EditCompany = ({ setUDO1, item, onClose, idCompany }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [logoEdit, setLogoEdit] = useState('');
  const [nameEdit, setNameEdit] = useState(item?.name);
  const [phoneEdit, setPhoneEdit] = useState(item?.phone);
  const [addressEdit, setAddressEdit] = useState(item?.address);
  const [limitEdit, setLimitEdit] = useState(item?.user_limit);
  const [pbxUserName, setPbxUserName] = useState(item?.pbx_username !== null ? '' : item?.pbx_username);
  const [pbxPwd, setPbxPwd] = useState(item?.pbx_password ?? "") 
  const [pbxId, setPbxId] = useState(item?.pbx_id ?? "")
  // const dataCompany = useSelector((item) => item.companyReducer.data);

  const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/


  const validationSchema = yup.object({
     phone: Yup.string()
     .required("required")
     .matches(phoneRegExp, 'Phone number is not valid')
     .min(10, "to short")
     .max(10, "to long"),
  });

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item]);

  const formik = useFormik({
    initialValues: {
      name: nameEdit,
      phone: phoneEdit,
      address: addressEdit,
      user_limit: limitEdit,
      logo:logoEdit,
      id:idCompany,
      pbx_username:pbxUserName,
      pbx_password:pbxPwd,
      pbx_id:pbxId
    },
    validationSchema: validationSchema,
      onSubmit: async (values) => {
      dispatch(editCompany(values));
      setUDO1(false);
      await dispatch(
        getCompanies({
          offset: 0,
          limit: 10,
        })
      );
    },
  });
  return (
    <Dialog
      fullWidth={true}
      maxWidth={"sm"}
      open={true}
      onClose={onClose}
      style={{ minHeight: "100%" }}
      classes={{
        paper: classes.customModal,
      }}
    >
      <DialogTitle className={`${classes.customModalTitle}`}>
        Sửa công ty
        <IconButton
          onClick={() => setUDO1(false)}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "#fff",
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={`${classes.customModalContent}`}>
        <form onSubmit={formik.handleSubmit}>
          <Grid
            container
            rowSpacing={2}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item xs={12} sm={6}>
              <BoxInput>
                <TitleInput>
                  Tên công ty <span style={{ color: colorError }}>*</span>
                </TitleInput>
                <TextField
                  name="name"
                  size="small"
                  fullWidth
                  placeholder="Tên công ty"
                  value={nameEdit}
                  onChange={(e) => {
                    formik.setFieldValue("name", e.currentTarget.value);
                    setNameEdit(e.currentTarget.value);
                  }}
                />
              </BoxInput>
            </Grid>
            <Grid item xs={12} sm={6}>
              <BoxInput>
                <TitleInput>
                  SĐT <span style={{ color: colorError }}>*</span>
                </TitleInput>
                <TextField
                  name="phone"
                  size="small"
                  fullWidth
                  placeholder="SĐT"
                  value={phoneEdit}
                  onBlur={formik.handleBlur}
                  error={formik.errors.phone && formik.touched.phone ? classes.textFieldError : ""}
                  helperText={formik.errors.phone && formik.errors.phone ? formik.errors.phone : ""}
                  onChange={(e) => {
                    formik.setFieldValue("phone", e.currentTarget.value);
                    setPhoneEdit(e.currentTarget.value);
                  }}
                />
              </BoxInput>
            </Grid>
            <Grid item xs={12} sm={6}>
              <BoxInput>
                <TitleInput>
                  Địa chỉ <span style={{ color: colorError }}>*</span>
                </TitleInput>
                <TextField
                  name="address"
                  size="small"
                  fullWidth
                  placeholder="Địa chỉ"
                  value={addressEdit}
                  onChange={(e) => {
                    formik.setFieldValue("address", e.currentTarget.value);
                    setAddressEdit(e.currentTarget.value);
                  }}
                />
              </BoxInput>
            </Grid>
            <Grid item xs={12} sm={6}>
              <BoxInput>
                <TitleInput>Số lượng user</TitleInput>
                <TextField
                  name="user_limit"
                  size="small"
                  fullWidth
                  placeholder="Số lượng user"
                  value={limitEdit}
                  onChange={(e) => {
                    formik.setFieldValue("user_limit", e.currentTarget.value);
                    setLimitEdit(e.currentTarget.value);
                  }}
                />
              </BoxInput>
            </Grid>
            <Grid item xs={12}>
              <BoxInput>
                <TitleInput>
                  Logo công ty <span style={{ color: colorError }}>*</span>
                </TitleInput>
                <div
                  style={{
                    textAlign: "center",
                    display: "inline-block",
                  }}
                >
                  <>
                    <img
                      alt=""
                      style={{
                        height: 40,
                        width: 40,
                        borderRadius: "50%",
                        border: "1px dashed #5a8dee",
                                  flex:'0 0 30px'
                      }}
                      src={
                                  item?.logo ? 
                                  `${URL}${item?.logo}` : blank
                                  }
                    />
                  </>
                </div>
                <TextField
                  name="logo"
                  size="small"
                  fullWidth
                  placeholder="Tên công ty"
                  inputProps={{ accept: "image/png, image/gif, image/jpeg" }}
                  onChange={(e) => {
                    formik.setFieldValue("logo", e.currentTarget.files[0]);
                    setLogoEdit(e.currentTarget.files[0]);
                  }}
                  type="file"
                />
              </BoxInput>
            </Grid>
            <Grid item xs={12}>
              <BoxInput>
                <TitleInput>
                ID tổng đài
                </TitleInput>
                
                <TextField
                id="name"
                name="pbx_id"
                size="small"
                fullWidth
                placeholder="ID tổng đài"
                value={pbxUserName}
                  onChange={(e) => {
                    formik.setFieldValue("pbx_id", e.currentTarget.value);
                    setPbxId(e.currentTarget.value);
                  }}
              />
              </BoxInput>
            </Grid>
            <Grid item xs={12} sm={6}>
              <BoxInput>
                <TitleInput>
                Tên đăng nhập tổng đài
                </TitleInput>
                
                <TextField
                id="name"
                name="pbx_username"
                size="small"
                fullWidth
                placeholder="Tên đăng nhập tổng đài"
                value={pbxUserName}
                  onChange={(e) => {
                    formik.setFieldValue("pbx_username", e.currentTarget.value);
                    setPbxUserName(e.currentTarget.value);
                  }}
              />
              </BoxInput>
            </Grid>
            <Grid item xs={12} sm={6}>
              <BoxInput>
              <TitleInput>
                Mật khẩu tổng đài
              </TitleInput>
              <TextField
                id="pbx_password"
                name="pbx_password"
                size="small"
                type="password"
                fullWidth
                value={pbxPwd}
                  onChange={(e) => {
                    formik.setFieldValue("pbx_password", e.currentTarget.value);
                    setPbxPwd(e.currentTarget.value);
                  }}
              />
              </BoxInput>
            </Grid>
          </Grid>

          <div
            style={{ display: "flex", justifyContent: "right", marginTop: 10 }}
          >
            <CustomButton
              variant="contained"
              type="submit"
              onClick={formik.handleSubmit}
            >
              Sửa
            </CustomButton>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
};
export default memo(EditCompany);