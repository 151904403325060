import * as types from "./type"

export const getTrangThaiData = () => ({
    type: types.GET_TRANG_THAI_DATA_START
})

export const getTrangThaiDataSuccess = (data) => ({
    type: types.GET_TRANG_THAI_DATA_SUCCESS,
    payload: data
})

export const getTrangThaiDataError = (data) => ({
    type: types.GET_TRANG_THAI_DATA_ERROR,
    payload: data
})