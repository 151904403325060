import React, { useEffect, useState } from "react";
import {
  
  Paper,
  Grid,
  Select,
  TextareaAutosize,
  TextField,
  DialogContent,
  DialogTitle,
  Dialog,
  MenuItem,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { CustomButton } from "../../components/Common/CustomButton";
import AssignToSelect from "../../components/Common/AssignTo/AssignToSelect";
import axiosInstance from "../../components/axiosApi";
import { makeStyles } from "@material-ui/core/styles";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import TableCustomers from "../../pages/Customer/components/TableCustomers"; 
import DiaLogAddCustomer from "../../pages/Customer/components/DialogCustomer"; 
import { useFormik } from "formik";
import * as yup from "yup";
import { getSourceData } from "../../redux/sourceData/action";
import { getListCity } from "../../redux/city/action";
import { getTrangThaiData } from "../../redux/trangThaiData/action";
import { getUserStart } from "../../redux/users/action";
import { getCallstatus } from "../../redux/callstatus/action";
import { getStatusLead } from "../../redux/statusLead/action";
import { searchCustomer } from "../../redux/customers/action";
import { editTicket, getTicket } from "../../redux/ticket/action";
import Loading from "../../components/Common/Loading";
export const TitleInput = styled("p")(({ theme }) => ({
  fontSize: 14,
  fontWeight: 500,
  marginBottom: 10,
}));

export const BoxInput = styled("div")(({ theme }) => ({
  marginBottom: "10px",
}));
const useStyles = makeStyles((theme) => ({
  tableBorder: {
    border: "1px solid #d4d8dd",
    borderRadius: "0.5rem",
    padding: "15px",
  },
  customModalContent: {
    paddingLeft: "15px",
    paddingRight: "15px",
  },
  customModal: {
    width: "calc(100% - 20px)",
    marginLeft: "10px",
    marginRight: "10px",
  },
  customModalTitle: {
    padding: "10px 15px",
    color: "#fff",
    background:
      process.env.NODE_ENV === "development"
        ? "#5a8dee"
        : process.env.REACT_APP_PRODUCT_BASE_COLOR,
    marginBottom: "15px",
  },
}));


const statusData = [
  { id: 1, label: "Mới" },
  { id: 2, label: "Đang chờ" },
  { id: 3, label: "Đang xử lý" },
  { id: 4, label: "Đã đóng" },
];

const TicketDetail = ({ phoneNumber,directSelected, dataLogoFooter,idTicket, itemDetail }) => {
  const checkEnablePhone = dataLogoFooter?.org?.hide_number
  const classes = useStyles();
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(true);
  const [dataPagination, setDataPagination] = useState({ offset: 0, limit: 10 });
  const [detailCustomer, setDetailCustomer] = useState(null);
  const [userDetailOpen, setUDO] = useState(false);
  const [phone, setPhone] = useState(null);

  

  const [selected, setSelected] = useState([]);

  const stateDataTrangThai = useSelector((state) => state.trangThaiDataReducer);
  const stateDataSource = useSelector((state) => state.sourceDataReducer)
  const stateDataCallStatus = useSelector((state) => state.callstatusReducer.dataList)

  const [data, setData] = useState([]);
  




  const handleCloseUserDetailDialog = () => {
    setUDO(!userDetailOpen);
    setDetailCustomer(null);
  };

  const filterByStatus = statusData.filter((x) => x.label === itemDetail.trangthai)
  const filterStatusId = filterByStatus[0].id

  const [cusName, setCusName]= useState(itemDetail?.cus_name);
  const [status, setStatus] = useState(filterStatusId);
  const [assign, setAssign] = useState(itemDetail?.assigned_to?.id);
  const [name, setName] = useState(itemDetail?.name);
  const [content, setContent] = useState(itemDetail?.content);

  useEffect(() => {
    const handleSearchInfoByPhone = async () => {
      try {
        const res = await axiosInstance.get(`/leads/?phone=${phoneNumber}`);
        if (res.status === 200) {
          setData(res.data);
          setCusName(res.data.open_leads?.open_leads[0]?.lead?.fullname)
        }
      } catch (error) {}
    };
    handleSearchInfoByPhone();
  }, [phoneNumber]);

  

  useEffect(() => {
    setTimeout(() => setLoading(false), 500);
    const filterByPhone = {
        phone: phoneNumber
      };

      dispatch(searchCustomer(filterByPhone));
  }, [phoneNumber])
  

  useEffect(() => {
   
    dispatch(getSourceData());
    dispatch(getListCity());
    dispatch(getTrangThaiData());
    dispatch(getUserStart());
    dispatch(getStatusLead());
    dispatch(getCallstatus());
    
    // eslint-disable-next-line
  }, []);

  

  const dataCustomers = useSelector((state) => state.getCustomersReducer);


    
    const validationSchema = yup.object({});
    const submitForm =  () => {
      dispatch(editTicket(values));
      dispatch(getTicket());
      const filterByPhone = {
        phone: values.phone
      };

      dispatch(searchCustomer(filterByPhone));
    };
      const { values, handleSubmit, handleChange, setFieldValue } =
      useFormik({
        initialValues: {
          id:idTicket,
          phone: phoneNumber,
        
          cus_name: cusName,
          trangthai: status,
          assigned_to: assign,
        },
        validationSchema: validationSchema,
        onSubmit: submitForm,
      });




 

  return (
    <>
        <Loading loading={isLoading} />
      <Paper className="order-list" style={{ marginBottom: "10px" }}>
        <div className="ul-heading-placeholder">
          <span
            className="ul-heading"
            style={{ display: "flex", flex: "inherit", fontWeight: 500 }}
          >
            Chi tiết phiếu ghi
          </span>
        </div>
      </Paper>
      <Paper
        sx={{ width: "100%", mb: 2 }}
        elevation={0}
        className={`${classes.tableBorder}`}
      >
        <Grid container rowSpacing={1} columnSpacing={{ sm: 2 }}>
          <Grid item xs={12} md={7}>
            <Grid container columnSpacing={{ sm: 1 }}>
              <Grid item xs={12} sm={6}>
                <BoxInput>
                  <TitleInput>Tên *</TitleInput>
                  <TextField
                    id="cus_name"
                    name="cus_name"
                    size="small"
                    fullWidth
                    value={cusName}
                    onChange={(e) => {
                    setFieldValue("cus_name", e.currentTarget.value);
                    setCusName(e.currentTarget.value);
                  }}
                  />
                </BoxInput>
              </Grid>
              <Grid item xs={12} sm={6}>
                <BoxInput>
                  <TitleInput>Số điện thoại *</TitleInput>
                  <TextField
                    name="phone"
                    size="small"
                    fullWidth
                    value={values.phone}
                    onChange={handleChange}
                  />
                </BoxInput>
              </Grid>
              {
                data?.open_leads?.open_leads[0]?.lead && (
                <>
                <Grid item xs={12}>
                <BoxInput>
                  <TitleInput>Địa chỉ</TitleInput>
                  <TextField
                    name="address"
                    size="small"
                    fullWidth
                    value={data?.open_leads?.open_leads[0]?.lead?.address}
                    disabled
                  />
                </BoxInput>
              </Grid>
              <Grid item xs={12}>
                <div className="boxgroup">
                  <BoxInput>
                    <TitleInput>Tỉnh / Thành Phố</TitleInput>
                    <TextField
                      name="city"
                      size="small"
                      fullWidth
                      value={data?.open_leads?.open_leads[0]?.lead?.city?.name}
                      disabled
                    />
                  </BoxInput>
                  <BoxInput>
                    <TitleInput>Quận / Huyện</TitleInput>
                    <TextField
                      name="district"
                      size="small"
                      fullWidth
                      value={
                        data?.open_leads?.open_leads[0]?.lead?.district?.name
                      }
                      disabled
                    />
                  </BoxInput>
                  <BoxInput>
                    <TitleInput>Phường / Xã</TitleInput>
                    <TextField
                      name="ward"
                      size="small"
                      fullWidth
                      value={data?.open_leads?.open_leads[0].lead?.ward?.name}
                      disabled
                    />
                  </BoxInput>
                </div>
              </Grid>
                </> 
              )
              }
              
              <Grid item xs={12} sm={4}>
                  <BoxInput>
                  <TitleInput>Giao cho *</TitleInput>
                  <AssignToSelect
                    value={values.assigned_to}
                    onChange={handleChange}
                  />
                </BoxInput>
              </Grid>
              <Grid item xs={12} sm={4}>
                <TitleInput>Ưu tiên</TitleInput>

                <TextField
                  name="priority"
                  size="small"
                  fullWidth
                  value={itemDetail.priority}
                  disabled
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TitleInput>Trạng thái *</TitleInput>

                <Select
                  style={{ fontSize: 14 }}
                  labelId="trangthai"
                  fullWidth
                  size="small"
                  label="Trạng thái"
                  name="trangthai"
                  value={values.trangthai}
                  onChange={handleChange}
                >
                  {statusData.map((data, index) => (
                    <MenuItem key={index} value={data.id}>
                      {data.label}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}  md={5}>
            <Grid container>
              <Grid item xs={12}>
                <div className="box-note" style={{ marginBottom: "10px" }}>
                  <BoxInput>
                    <TitleInput>Tên phiếu ghi</TitleInput>
                    <TextField
                      name="name"
                      size="small"
                      fullWidth
                      placeholder="Tên phiếu ghi"
                      value={name}
                      disabled
                    />
                  </BoxInput>

                  <BoxInput>
                    <TitleInput>Ghi chú</TitleInput>
                    <TextareaAutosize
                      minRows={1}
                      id="careContent"
                      name="content"
                      size="small"
                      placeholder="Nhập nội dung"
                      variant="outlined"
                      style={{ border: "none !important", fontSize: "14px" }}
                      value={content}
                      disabled
                    />
                  </BoxInput>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <BoxInput>
          <CustomButton
            variant="contained"
            type="submit"
            onClick={() => handleSubmit()}
            style={{ marginLeft: "auto" }}
          >
            Cập nhập
          </CustomButton>
        </BoxInput>
      </Paper>
      <Paper className="order-list" style={{ marginBottom: "10px" }}>
        <div className="ul-heading-placeholder">
          <span
            className="ul-heading"
            style={{ display: "flex", flex: "inherit", fontWeight: 500 }}
          >
            Danh sách đơn hàng
          </span>
        </div>
      </Paper>
      <Paper className="user-list">
          <TableCustomers
            dataCallStatus = {stateDataCallStatus}
            dataSources = {stateDataSource}
            dataStatus = {stateDataTrangThai}
            dataCustomers={dataCustomers}
            showDetail={setUDO}
            setDetailCustomer={setDetailCustomer}
            dataPagination={dataPagination}
            setDataPagination={setDataPagination}
            setPhone={setPhone}
            phone={phone}
            selected={selected}
            setSelected={setSelected}
            directSelected={directSelected}
            checkEnablePhone={checkEnablePhone}
            filterByPhone = {phoneNumber}
          />
        </Paper>

        <Dialog
          fullWidth={true}
          maxWidth={"md"}
          open={userDetailOpen}
          onClose={handleCloseUserDetailDialog}
          style={{ minHeight: "100%" }}
          classes={{
            paper: classes.customModal
          }}
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {    
                maxWidth: "1040px",  // Set your width here
                boxShadow:'none',
                marginTop:'10px'
              },
            },
    }}
        >
          <DialogTitle className={`${classes.customModalTitle}`}>
            Lên đơn hàng
            <IconButton
              onClick={handleCloseUserDetailDialog}
              sx={{
                position: "absolute!important",
                right: 8,
                top: 8,
                color:'#fff',
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent className={`${classes.customModalContent}`}>
            <DiaLogAddCustomer
              setUDO={setUDO}
              setDetailCustomer={setDetailCustomer}
              detailCustomer={detailCustomer}
              setDataPagination={setDataPagination}
              dataPagination={dataPagination}
              checkEnablePhone={checkEnablePhone}
              dataLogoFooter={dataLogoFooter}
              dataCallStatus = {stateDataCallStatus}
              filterByPhone = {phoneNumber}
            />
          </DialogContent>
        </Dialog>
    </>
  );
};

export default TicketDetail;
