import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import { useFormik } from "formik";
import styled from "styled-components";
import TextField from "@mui/material/TextField";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { CustomButton } from "../../../../components/Common/CustomButton";
import { useDispatch, useSelector } from "react-redux";
import { createAdminCompany } from "../../../../redux/company/action";
import * as Yup from "yup";
import { makeStyles } from "@material-ui/core/styles";
import { red } from "@mui/material/colors";

const colorError = red[500];
export const TitleInput = styled("p")(({ theme }) => ({
  fontSize: 14,
  fontWeight: 500,
  marginBottom: 10,
}));

export const BoxInput = styled("div")(({ theme }) => ({
  
  marginBottom:'10px'
}));

const useStyles = makeStyles((theme) => ({
  textFieldError: {
    "& input": {
      border: "1px solid red",
      color: "red",
    },
    "& p": {
      color: "red",
      marginLeft:0,
    },
  },
  customModalContent:{
    paddingLeft:'15px',
    paddingRight:'15px',
    marginTop:'10px'
  },
  customModal:{
    width:'calc(100% - 20px)',
    marginLeft:'10px',
    marginRight:'10px',
    
  },
  customModalTitle:{
    padding:'10px 15px',
    color:'#fff',
    background:process.env.NODE_ENV === 'development' ? "#5a8dee" : process.env.REACT_APP_PRODUCT_BASE_COLOR
  }
}));

const adminCompanySchema = Yup.object({
  email: Yup.string()
    .email("Hãy nhập đúng định dạng email")
    .required("Hãy nhập email"),

  password: Yup.string()
    .required("Hãy nhập user password")
    .min(8, "password phải dài hơn 7 ký tự"),
  first_name: Yup.string().required("Hãy nhập first name"),
  last_name: Yup.string().required("Hãy nhập last name"),
});
export const CreateAdminCompany = (props) => {
  const classes = useStyles();
  const [messageErrorAPI, setMessageErrorAPI] = useState("");
  const createAdminCompanySuccess = useSelector(
    (item) => item.companyReducer.createAdminCompanySuccess
  );

  const createCompanyData = useSelector((item) => item.companyReducer.data);

  useEffect(() => {
    if (createCompanyData && createCompanyData.error) {
      setMessageErrorAPI(JSON.stringify(createCompanyData.errors));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createCompanyData]);

  useEffect(() => {
    if (createAdminCompanySuccess) {
      props.onClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createAdminCompanySuccess]);

  const dispatch = useDispatch();
  const submitForm = () => {
    const newValues = {...values};
    newValues.username = values.email;
     dispatch(
       createAdminCompany({
         ...newValues,
         id: props.idCompany,
       })
     );
  };

  const { values, handleSubmit, handleChange, handleBlur, errors, touched } =
    useFormik({
      initialValues: {
        email: "",
        username: "",
        password: "",
        first_name: "",
        last_name: "",
        position: "",
      },
      validationSchema: adminCompanySchema,
      onSubmit: submitForm,
    });

  return (
    <Dialog
      fullWidth={true}
      maxWidth={"xs"}
      open={true}
      onClose={props.onClose}
      style={{ minHeight: "100%" }}
      classes={{
          paper: classes.customModal
      }}
    >
      <DialogTitle className={`${classes.customModalTitle}`}>
        Tạo admin cho công ty
        <IconButton
          onClick={props.onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: '#fff',
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={`${classes.customModalContent}`}>
        
          {messageErrorAPI && (
            <div className={classes.textFieldError}>
              <p>{messageErrorAPI}</p>
            </div>
          )}
          <BoxInput>
            <TitleInput>Email <span style={{ color: colorError }}>*</span></TitleInput>
            <TextField
              name="email"
              variant="outlined"
              fullWidth
              placeholder="Nhập email..."
              size="small"
              onChange={handleChange}
              onBlur={handleBlur}
              className={
                errors.email && touched.email ? classes.textFieldError : ""
              }
              helperText={errors.email && touched.email ? errors.email : ""}
              value={values.email}
            />
          </BoxInput>
          
          <BoxInput>
            <TitleInput>Mật khẩu <span style={{ color: colorError }}>*</span></TitleInput>
            <TextField
              size="small"
              name="password"
              type="password"
              fullWidth
              placeholder="Nhập Mật khẩu..."
              onChange={handleChange}
              onBlur={handleBlur}
              className={
                errors.password && touched.password
                  ? classes.textFieldError
                  : ""
              }
              helperText={
                errors.password && touched.password ? errors.password : ""
              }
              value={values.password}
            />
          </BoxInput>
          <BoxInput>
            <TitleInput>Họ <span style={{ color: colorError }}>*</span></TitleInput>
            <TextField
              size="small"
              name="last_name"
              fullWidth
              placeholder="Nhập họ..."
              onChange={handleChange}
              onBlur={handleBlur}
              className={
                errors.last_name && touched.last_name
                  ? classes.textFieldError
                  : ""
              }
              helperText={
                errors.last_name && touched.last_name ? errors.last_name : ""
              }
              value={values.last_name}
            />
          </BoxInput>
          <BoxInput>
            <TitleInput>Tên <span style={{ color: colorError }}>*</span></TitleInput>
            <TextField
              size="small"
              name="first_name"
              fullWidth
              placeholder="Nhập tên..."
              onChange={handleChange}
              onBlur={handleBlur}
              className={
                errors.first_name && touched.first_name
                  ? classes.textFieldError
                  : ""
              }
              helperText={
                errors.first_name && touched.first_name ? errors.first_name : ""
              }
              value={values.first_name}
            />
          </BoxInput>
          
          <BoxInput>
            <TextField
              hidden
              size="small"
              name="position"
              fullWidth
              placeholder="Nhập địa chỉ..."
              onChange={handleChange}
              value={values.position}
            />
          </BoxInput>

          <CustomButton
            // color="primary"
            variant="contained"
            type="submit"
            onClick={() => handleSubmit()}
            style={{marginLeft:'auto'}}
          >
            Tạo admin công ty
          </CustomButton>
      </DialogContent>
    </Dialog>
  );
};
