import * as types from './type'

const initialState = {
  data: [],
  dataList: [],
  loading: false,
  
}

export const smshistoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_SMSHISTORY:
      return {
        ...state,
        ...{ loading: true },
      }

    case types.GET_SMSHISTORY_SUCCESS:
      return {
        ...state,
        ...{
          dataList: action.payload,
          loading: false
        }
      }

    case types.GET_SMSHISTORY_FAIL:
      {
        return {
          ...state,
          ...{ dataList: [], loading: false }
        }
      }



    
    case types.RESET_MESSAGE_ERROR:
      return {
        ...state,
        loading: false,
        data: []
      }

    default:
      return state
  }
}