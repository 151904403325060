import * as types from "./type";

export const getOrders = (data) => ({
  type: types.GET_ORDERS,
  payload: data,
});
export const getOrdersSuccess = (data) => ({
  type: types.GET_ORDERS_SUCCESS,
  payload: data,
});
export const getOrdersFail = (data) => ({
  type: types.GET_ORDERS_FAIL,
  payload: data,
});
// update order

export const updateOrder = (data) => ({
  type: types.UPDATE_ORDER,
  payload: data,
});

export const updateOrderSuccess = (data) => ({
  type: types.UPDATE_ORDER_SUCCESS,
  payload: data,
});
export const updateOrderFail = (data) => ({
  type: types.UPDATE_ORDER_FAIL,
  payload: data,
});

export const resetMessage = (data) => ({
  type: types.RESET_MESSAGE,
  payload: data,
});

export const resetMessageError = (data) => ({
  type: types.RESET_MESSAGE_ERROR,
  payload: data,
});
