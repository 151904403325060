import React from 'react'
import {
  Container,
    Typography,
  } from "@mui/material";
  import styled from 'styled-components'
  const StyledHeader = styled(Typography)`
  font-size:1rem;
  font-weight: 500;
`; 

const Privacy = () => {
  return (
    <Container >
    <Typography variant="body1" gutterBottom>
        VNtel đã xây dựng giải pháp CRM dưới dạng phần mềm thương mại. DỊCH VỤ này được cung cấp bởi Vntel và được sử dụng như hiện tại.

Trang này được sử dụng để thông báo cho khách truy cập trang web về các chính sách của chúng tôi với việc thu thập, sử dụng và tiết lộ dữ liệu nếu bất kỳ ai quyết định sử dụng dịch vụ của chúng tôi.

Nếu bạn chọn sử dụng dịch vụ của chúng tôi, nghĩa là bạn đồng ý với việc thu thập và sử dụng thông tin liên quan đến chính sách này. Dữ liệu mà chúng tôi thu thập được sử dụng để cung cấp và cải thiện dịch vụ. Chúng tôi sẽ không sử dụng hoặc chia sẻ dữ liệu của bạn với bất kỳ ai ngoại trừ được mô tả trong Chính sách Bảo mật này.

Các điều khoản được sử dụng trong Chính sách Bảo mật này có cùng ý nghĩa như trong Điều khoản và Điều kiện của chúng tôi, có thể truy cập được tại Vntel trừ khi được định nghĩa khác trong Chính sách Bảo mật này.
    </Typography>
    <StyledHeader component="h2"  gutterBottom>
    1. Mục đích và phạm vi thu thập
    </StyledHeader>
    <Typography variant="body1" gutterBottom>
    CRM yêu cầu khách hàng cung cấp các thông tin cơ bản bao gồm: email, điện thoại, tên đăng nhập, mật khẩu đăng nhập, địa chỉ khi khách hàng đăng ký sử dụng dịch vụ của CRM và một số thông tin không bắt buộc khác khi khách hàng muốn tương tác với một số nội dung trên website. CRM sử dụng các thông tin này nhằm liên hệ xác nhận với khách hàng và đảm bảo quyền lợi cho Khách hàng khi cần thiết.


      </Typography>
      <Typography variant="body1" gutterBottom>
      Việc bạn truy cập, đăng ký, sử dụng CRM có nghĩa rằng bạn đồng ý và chấp nhận ràng buộc bởi các quy định trong chính sách bảo mật của chúng tôi.


      </Typography>
      <Typography variant="body1" gutterBottom>
      Khách hàng sẽ tự chịu trách nhiệm về bảo mật và lưu giữ mọi hoạt động sử dụng dịch vụ dưới tên đăng ký, mật khẩu và hộp thư điện tử và/hoặc số điện thoại của mình. CRM không chịu trách nhiệm về các thất thoát dữ liệu, bí mật thông tin của khách hàng do khách hàng vô tình hoặc cố ý gây ra. Ngoài ra, Khách hàng có trách nhiệm thông báo kịp thời cho CRM về những hành vi sử dụng trái phép, lạm dụng, vi phạm bảo mật, lưu giữ tên đăng ký và mật khẩu của bên thứ ba để có biện pháp giải quyết phù hợp.


      </Typography>
      <StyledHeader component="h2" gutterBottom component="div">
      2. Dịch vụ, ứng dụng liên kết với CRM


    </StyledHeader>
    <Typography variant="body1" gutterBottom>
    Để đảm bảo quyền lợi và trải nghiệm tốt nhất cho Khách hàng, CRM áp dụng một số điều khoản riêng khi Khách hàng sử dụng các dịch vụ do CRM cung cấp hoặc có liên kết với CRM:



      </Typography>
      <Typography variant="body1" gutterBottom>A. Đối với tài khoản Facebook của Khách hàng khi liên kết CRM, Phần mềm sẽ yêu cầu quyền truy cập các thông tin sau:

</Typography>
      <Typography variant="body1" gutterBottom>1) Quyền truy cập vào địa chỉ email của Trang (Fanpage) Facebook sử dụng để tích hợp với CRM;

</Typography>
      <Typography variant="body1" gutterBottom>2) Quyền truy cập vào tập hợp các mục công khai trên Trang Facebook đã tích hợp với CRM;

</Typography>
      <Typography variant="body1" gutterBottom>3) Cho phép truy cập vào tập hợp các mục công khai trên Tài khoản cá nhân của người dùng có tương tác với Trang Facebook tích hợp với CRM.

</Typography>
      <Typography variant="body1" gutterBottom>4) Cho phép truy cập hộp thư trên Trang Facebook tích hợp với CRM thông qua CRM;

</Typography>
      <Typography variant="body1" gutterBottom>5) Cho phép gửi và nhận tin nhắn, bình luận trên Trang Facebook tích hợp với CRM thông qua CRM.

</Typography>
      <Typography variant="body1" gutterBottom>B. Đối với tài khoản Zalo của Khách hàng khi liên kết với CRM, CRM sẽ yêu cầu quyền truy cập các thông tin sau:

</Typography>
      <Typography variant="body1" gutterBottom>1) Quyền truy cập vào ảnh đại diện, tên, ảnh bìa và mô tả của tài khoản Zalo Official Account sử dụng để tích hợp với CRM;

</Typography>
      <Typography variant="body1" gutterBottom>2) Quyền truy cập vào thông tin họ tên, địa chỉ, số điện thoại của người dùng theo dõi (follow) tài khoản Zalo Official account sử dụng tích hợp với CRM

</Typography>
      <Typography variant="body1" gutterBottom>3) Cho phép gửi và nhận tin nhắn từ tài khoản Zalo Official Account tích hợp với Vntel thông qua CRM.

</Typography>
      <StyledHeader component="h2" gutterBottom component="div">3. Phạm vi sử dụng thông tin

</StyledHeader>
      <Typography variant="body1" gutterBottom>Vntel sử dụng thông tin Khách hàng cung cấp để:

</Typography>
      <Typography variant="body1" gutterBottom>– Cung cấp các dịch vụ đến Khách hàng.

</Typography>
      <Typography variant="body1" gutterBottom>– Gửi các thông báo về các hoạt động trao đổi thông tin giữa Khách hàng và đơn vị Hỗ trợ kĩ thuật của CRM

</Typography>
      <Typography variant="body1" gutterBottom>– Ngăn ngừa các hoạt động phá hủy tài khoản người dùng của Khách hàng hoặc các hoạt động giả mạo Khách hàng.

</Typography>
      <Typography variant="body1" gutterBottom>– Liên lạc và giải quyết với khách hàng trong những trường hợp đặc biệt.

</Typography>
      <Typography variant="body1" gutterBottom>– Vntel có trách nhiệm hợp tác cung cấp thông tin cá nhân Khách hàng khi có yêu cầu từ cơ quan nhà nước có thẩm quyền.

</Typography>
      <Typography variant="body1" gutterBottom>– Chia sẻ thông tin cần thiết cho bên đối tác nếu nhận được sự đồng ý của Khách hàng.

</Typography>
      <StyledHeader component="h2" gutterBottom component="div">4. Thời gian lưu trữ thông tin

</StyledHeader>
      <Typography variant="body1" gutterBottom>Trong mọi trường hợp thông tin cá nhân Khách hàng sẽ được bảo mật hoàn toàn trên máy chủ của CRM. Khách hàng có quyền cập nhật, sửa đổi và xóa thông tin của các Dữ liệu cá nhân này. Tuy nhiên, trong một số trường hợp, CRM vẫn có thể khôi phục những thông tin đó từ cơ sở dữ liệu của chúng tôi để giải quyết các tranh chấp, thi hành điều khoản sử dụng, hay vì các yêu cầu kỹ thuật, pháp lý liên quan đến sự an toàn và những hoạt động của CRM.

</Typography>
      <StyledHeader component="h2" gutterBottom component="div">5. Địa chỉ của đơn vị thu thập, quản lý thông tin và hỗ trợ Khách hàng

</StyledHeader>
      <Typography variant="body1" gutterBottom>Công ty Cổ phần Phần Viễn thông tin học Việt Nam

</Typography>
      <Typography variant="body1" gutterBottom>Trụ sở chính: Nhà B1, ĐN14, số 118/6 Nguyễn Khánh Toàn, Q. Cầu Giấy, TP. Hà Nội.

</Typography>
      <Typography variant="body1" gutterBottom>Hotline: 0901.826.555 – 0901.899.555

</Typography>
      <Typography variant="body1" gutterBottom>Email: cskh@vntel.vn

</Typography>
     <StyledHeader component="h2" gutterBottom component="div">6. Phương tiện và công cụ để Khách hàng tiếp cận và chỉnh sửa dữ liệu của mình

</StyledHeader>
      <Typography variant="body1" gutterBottom>Khách hàng có quyền tự kiểm tra, cập nhật, điều chỉnh thông tin cá nhân của mình bằng cách đăng nhập vào tài khoản và chỉnh sửa thông tin cá nhân hoặc yêu cầu CRM thực hiện việc này.

</Typography>
      <Typography variant="body1" gutterBottom>Khách hàng có quyền gửi khiếu nại về việc lộ thông tin các nhân cho bên thứ ba đến Ban quản trị của CRM. Khi tiếp nhận những phản hồi này, CRM sẽ xác nhận lại thông tin, phải có trách nhiệm trả lời lý do và hướng dẫn Khách hàng khôi phục và bảo mật lại thông tin.

</Typography>
     <StyledHeader component="h2" gutterBottom component="div">7. Cam kết bảo mật thông tin cá nhân Khách hàng

</StyledHeader>
      <Typography variant="body1" gutterBottom>Thông tin của Khách hàng trên CRM được CRM cam kết bảo mật tuyệt đối theo chính sách bảo vệ thông tin cá nhân của CRM. Việc thu thập và sử dụng thông tin của mỗi Khách hàng chỉ được thực hiện khi có sự đồng ý của Khách hàng đó, trừ những trường hợp pháp luật có quy định khác. CRM cam kết:

</Typography>
      <Typography variant="body1" gutterBottom>– Không sử dụng, không chuyển giao, cung cấp hay tiết lộ cho bên thứ ba nào về thông tin cá nhân của Khách hàng khi không có sự cho phép hoặc đồng ý từ Khách hàng, trừ những trường hợp pháp luật có quy định khác.

</Typography>
      <Typography variant="body1" gutterBottom>– Bảo mật tuyệt đối mọi thông tin giao dịch trực tuyến của Khách hàng bao gồm thông tin hóa đơn, chứng từ kế toán số hóa tại khu vực dữ liệu trung tâm an toàn của CRM.

</Typography>
      <Typography variant="body1" gutterBottom>Lưu ý: Chính sách bảo mật này có hiệu lực từ ngày 31/05/2020.

</Typography>

   
</Container>
  )
}

export default Privacy