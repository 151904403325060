import { applyMiddleware, createStore } from "redux";
import rootReducer from "./rootReducer";
import rootSaga from "./saga";
import createSagaMiddleware from "redux-saga";
import { composeWithDevTools } from "redux-devtools-extension";

// const store = (loadState = {}) => {
    const sagaMiddleware = createSagaMiddleware();
    const store = createStore(
        rootReducer, 
        {},
        composeWithDevTools(
            applyMiddleware(sagaMiddleware)
        )
        // applyMiddleware(logger, sagaMiddleware),
    );
    sagaMiddleware.run(rootSaga)
// }

export default store;