import React, { useState, useEffect, useCallback } from "react";
import { showMessageError } from "../../redux/notification/action";

import axiosInstance from "../../components/axiosApi";
import { useDispatch } from "react-redux";
import FacebookFanpageSidebar from './FacebookFanpageSidebar'
import FacebookChatSidebar from "./FacebookChatSidebar";
import FbWelcome from "../Facebook/FacebookWelcome";

import FacebookConver from "./FacebookConver";
import { useContext } from "react";
import { CountContext } from "../Notification/NotifyContent";
const FacebookChat = ({setExpand}) => {

  const dataContext = useContext(CountContext)

  const dataNotify = dataContext.info


  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [dataFanpage, setDataFanpage] = useState([]);
  const [selected, setSelected] = useState();
  const [selectedFanpage, setSelectedFanpage] = useState(0);
  const [selectedPart, setSelectedPart] = useState([]);
  const [userChat,setUserChat] = useState([]);
  const [idFanpage, setIdFanpage] = useState([]);
  const [idChat, setIdChat] = useState([]);
  const [dataConvert, setDataConvert] = useState([]);

  const [open, setOpen] = useState(false);
    const [openSidebar, setOpenSidebar] = useState(false);

  useEffect(() => {
    const getFacebookFanPage = async () => {
      try {
        const res = await axiosInstance.get("/facebook/");
        if (res.status === 200) {
          
          setDataFanpage(res.data);
          
        }
      } catch (error) {
        dispatch(showMessageError("Lỗi server"));
      }
    }
    getFacebookFanPage();
   // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (dataFanpage?.Fanpage?.length > 0 && !dataNotify){
      const idOaFirst = dataFanpage?.Fanpage[0];
        getConvert(idOaFirst.id)
        setSelectedFanpage(idOaFirst.id)

        setIdFanpage(idOaFirst.id);
      } else {
        getConvert(dataNotify.send_to)
        setIdFanpage(dataNotify.send_to);
      }
      // eslint-disable-next-line
  }, [dataFanpage]);  
  
  



    const getConvert = useCallback(async (idFanpage) => {
      try {
        const res = await axiosInstance.get(`/facebook/conversations/?id=${idFanpage}`);
        if (res.status === 200) {
          setDataConvert(res?.data?.conversations?.data)
          setIdChat(res?.data?.id)
        }
      } catch (error) {}
    }, [idFanpage, dataFanpage]) 
  return (
    <div className='zalochat-wrapper'>
      <div className='box-chat-listing'>
      <FacebookFanpageSidebar 
          data = {dataFanpage}
          setIdFanpage = {setIdFanpage}
          onClickLoadDataChat = {getConvert}
          setSelected={setSelected}
          setSelectedFanpage={setSelectedFanpage}
          selectedFanpage={selectedFanpage}
          dataNotify={dataNotify}
        />
        <FacebookChatSidebar 
            data = {dataConvert}
            selected={selected}
            setSelected={setSelected}
            setSelectedPart={setSelectedPart}
            setUserChat = {setUserChat}
            setLoading={setLoading}
            setOpen={setOpen}
            setOpenSidebar={setOpenSidebar}
            openSidebar={openSidebar}
            dataNotify={dataNotify}
            setExpand={setExpand}
        />
      </div>
      {
            open && selected ? 
            <>
      <FacebookConver
            item={selected} 
            idFanpage={idFanpage}
            idChat = {idChat}
            selectedPart = {selectedPart}
            username={userChat}
            loading={loading}
            setLoading={setLoading}
            openSidebar={openSidebar}
            setOpenSidebar={setOpenSidebar}
        /> 
       </> : <>
            <FbWelcome />
            </>
        }
    </div>
  )
}

export default FacebookChat