import {
  Checkbox,
  Dialog,
  DialogTitle,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  DialogContent,
  Grid,
  TextField,
  Button,
  Collapse,
  Box,
  Paper,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/EditOutlined";
import AddIcon from "@mui/icons-material/Add";
import AspectRatioOutlinedIcon from '@mui/icons-material/AspectRatioOutlined';
import Tooltip from "@mui/material/Tooltip";
import React, { useEffect, useState } from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import DialogEdit from "../DialogEdit";
import { getDepartmentsId } from "../../../../../redux/departmentsId/action";
import { getDepartments } from "../../../../../redux/Departments/action";
import DialogAddDepartmentId from "../DialogAddDepartmentId";
import DialogEditPosition from "../DialogEditPosition";
import { makeStyles } from "@material-ui/core/styles";
import { CustomButton } from "../../../../../components/Common/CustomButton";

const useStyles = makeStyles((theme) => ({
  customModalContent:{
    paddingLeft:'15px',
    paddingRight:'15px'
  },
  customModal:{
    width:'calc(100% - 20px)',
    marginLeft:'10px',
    marginRight:'10px'
  },
  customModalTitle:{
    padding:'10px 15px',
    color:'#fff',
    background:process.env.NODE_ENV === 'development' ? "#5a8dee" : process.env.REACT_APP_PRODUCT_BASE_COLOR
  },
  CustomButton:{
    width:'30px',
    height:'30px',
    background:'#e5edfc',
    marginRight:'3px'
  }
}));
const TableDepartments = ({ data }) => {
  const classes = useStyles();
  
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      textTransform: "capitalize",
      color: "#707275",
      backgroundColor: "rgba(38,60,85,.1)",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  const dispatch = useDispatch();
  const [userDetailOpen, setUDO] = React.useState(false);
  const [userDetailOpen1, setUDO1] = React.useState(false);
  const [userDetailOpen2, setUDO2] = React.useState(false);
  const [item, setItem] = useState();
  const [item2, setItem2] = useState();
  const [idOpen, setIdOpen] = React.useState();
  const [open, setOpen] = React.useState(false);
  const [statusId, setStatusId] = React.useState();

  const departmentsId = useSelector((item) => item.departmentsIdRuducer.data);
  const crePoLoad = useSelector((item) => item.departmentsIdRuducer.crePoLoad);
  const loadPositions = useSelector(
    (item) => item.departmentsIdRuducer.loadPositions
  );

  const handleCloseUserDetailDialog = () => {
    setUDO(!userDetailOpen);
  };
  const handleCloseUserDetailDialog1 = () => {
    setUDO1(!userDetailOpen1);
  };
  const handleCloseUserDetailDialog2 = () => {
    setUDO2(!userDetailOpen2);
  };

  const handleEdit = (item) => {
    setItem(item);
    setUDO1(true);
  };

  const handleStatusId = (id) => {
    setOpen(!open);
    setIdOpen(id);
    dispatch(getDepartmentsId(id));
    setStatusId(id);
  };
  const handleAddStatusId = (id) => {
    setUDO(true);
  };

  const handlePosition = (item) => {
    setUDO2(true);
    setItem2(item);
  };

  const Row = (props) => {
    const { row } = props;
    return (
      <React.Fragment>
        <StyledTableRow>
        <TableCell align="left">
        <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => handleStatusId(row.id)}
              >
            
              <AddIcon sx={{ fontSize:'0.8rem' }}/>
              
            </IconButton>
        
        </TableCell>
        
          <TableCell align="left">
            {row.name}
            
          </TableCell>
          <TableCell align="left">
            {moment(row.created_on).format("DD-MM-YYYY, h:mm A")}
          </TableCell>
          <TableCell align="left">
            <div>
            <Tooltip title="Sửa phòng ban">
            <IconButton className={classes.CustomButton} onClick={() => handleEdit(row)} >
              <EditIcon  sx={{ color: "#5a8dee", fontSize:'1.1rem' }} />
              </IconButton>
            </Tooltip>
            </div>
          </TableCell>
        </StyledTableRow>
        {row.id === idOpen && (
          <StyledTableRow>
            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
              <Collapse in={open} timeout="auto" unmountOnExit>
                <Box sx={{ margin: 1 }}>
                  <Table size="small" aria-label="purchases">
                    <TableHead>
                      <TableRow>
                        <TableCell style={{ minWidth: 150 }}>
                          Tên chức vụ
                        </TableCell>
                        <TableCell style={{ minWidth: 100 }}></TableCell>
                        <TableCell align="left">Ngày tạo</TableCell>
                        <TableCell align="right">
                          <Button
                            variant="outlined"
                            className="search-row-button"
                            onClick={() => {
                              handleAddStatusId(row.id);
                            }}
                          >
                            Thêm mới
                          </Button>
                        </TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {departmentsId?.map((item) => (
                        <TableRow>
                          <TableCell style={{ minWidth: 150 }}>
                            {item.name}
                          </TableCell>
                          <TableCell style={{ minWidth: 100 }}></TableCell>
                          <TableCell align="left">
                            {moment(item.created_on).format(
                              "DD-MM-YYYY, h:mm A"
                            )}
                          </TableCell>
                          {/* <TableCell></TableCell> */}
                          <TableCell align="left">
                          <Tooltip title="Sửa chức vụ">
                            <div onClick={() => handlePosition(item)}>
                              <EditIcon sx={{ color: "#5a8dee", fontSize:'1.2rem' }} />
                            </div>
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Box>
              </Collapse>
            </TableCell>
          </StyledTableRow>
        )}
      </React.Fragment>
    );
  };

  useEffect(() => {
    if (crePoLoad) {
      dispatch(getDepartmentsId(statusId));
    }
    if (loadPositions) {
      dispatch(getDepartmentsId(statusId));
    }
  }, [crePoLoad, loadPositions]);

  return (
    <Paper
      sx={{
        width: "100%",
        marginTop: "10px",
        border: "1px solid #d4d8dd",
        borderRadius: "0.5rem",
      }}
      elevation={0}
    >
      <TableContainer
        style={{ width: "100%", overflowX: "auto", borderRadius: "0.5rem" }}
      >
        <Table sx={{ minWidth: 700 }} size={"small"}>
          <TableHead>
            <TableRow>
              {/* <StyledTableCell align="left">STT</StyledTableCell> */}
              <StyledTableCell align="left" style={{ width: 20 }}>

              </StyledTableCell>
              <StyledTableCell align="left" style={{ minWidth: 100 }}>
                Tên phòng ban
              </StyledTableCell>
              <StyledTableCell align="left" style={{ minWidth: 100 }}>
                Ngày tạo
              </StyledTableCell>
              <StyledTableCell />
            </TableRow>
          </TableHead>

          <TableBody>
            {data?.length > 0 ? (
              data?.map((row, index) => {
                return <Row key={row.name} row={row} />;
              })
            ) : (
              <h3 style={{ marginLeft: 20 }}>Không có dữ liệu</h3>
            )}
          </TableBody>
        </Table>

        <Dialog
          fullWidth={true}
          maxWidth={"sm"}
          open={userDetailOpen}
          onClose={handleCloseUserDetailDialog}
          style={{ minHeight: "100%" }}
          classes={{
              paper: classes.customModal
          }}
        >
          <DialogTitle className={`${classes.customModalTitle}`}>
            Thêm chức vụ
            <IconButton
              onClick={() => setUDO(false)}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: '#fff',
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>

          <DialogContent className={`${classes.customModalContent}`}>
            <DialogAddDepartmentId setUDO={setUDO} statusId={statusId} />
          </DialogContent>
        </Dialog>

        {/* //edit_phongban */}
        <Dialog
          fullWidth={true}
          maxWidth={"sm"}
          open={userDetailOpen1}
          onClose={handleCloseUserDetailDialog1}
          style={{ minHeight: "100%" }}
          classes={{
              paper: classes.customModal
          }}
        >
          <DialogTitle className={`${classes.customModalTitle}`}>
            Sửa phòng ban
            <IconButton
              onClick={() => setUDO1(false)}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color:'#fff',
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>

          <DialogContent className={`${classes.customModalContent}`}>
            <DialogEdit setUDO1={setUDO1} item={item} />
          </DialogContent>
        </Dialog>

        {/* editchucvu */}
        <Dialog
          fullWidth={true}
          maxWidth={"sm"}
          open={userDetailOpen2}
          onClose={handleCloseUserDetailDialog2}
          style={{ minHeight: "100%" }}
          classes={{
              paper: classes.customModal
          }}
        >
          <DialogTitle className={`${classes.customModalTitle}`}>
            Sửa chức vụ
            <IconButton
              onClick={() => setUDO2(false)}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: '#fff',
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>

          <DialogContent className={`${classes.customModalContent}`}>
            <DialogEditPosition setUDO2={setUDO2} item2={item2} />
          </DialogContent>
        </Dialog>
      </TableContainer>
    </Paper>
  );
};

export default TableDepartments;
