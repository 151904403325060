import { put, takeLatest } from "redux-saga/effects";
import * as types from "./type";
import * as actions from "./action";
import axiosInstance from "../../components/axiosApi";
import { showMessageError, showMessageSuccess } from "../notification/action";

function* getOrders(action) {
  try {
    const res = yield axiosInstance.get(`/sales/orders/`, {
      params: action.payload,
    });
    yield put(actions.getOrdersSuccess(res.data));
  } catch (error) {
    yield put(actions.getOrdersFail(error));
  }
}

function* updateOrder(action) {
  try {
    const item = action.payload;
    let queryBuilder = "";
    Object.keys(item).map((key, idx) => {
      if (item[key]) {
        queryBuilder =
          queryBuilder + `${idx === 0 ? key : "&" + key}=${item[key]}`;
      }
    });
    const res = yield axiosInstance.put(`/sales/orders/?${queryBuilder}`);
    yield put(actions.updateOrderSuccess(res.data));
    yield put(showMessageSuccess("Cập nhật thành công"));
  } catch (error) {
    yield put(actions.updateOrderFail(error));
    yield put(showMessageError("Cập nhật thất bại"));
  }
}

function* resetMessage() {
  yield put(actions.resetMessageError({}));
}

export function* watchingOrders() {
  yield takeLatest(types.GET_ORDERS, getOrders);
  yield takeLatest(types.UPDATE_ORDER, updateOrder);
  yield takeLatest(types.RESET_MESSAGE, resetMessage);
}
