import styled from "styled-components";

export const ChildStatus = styled.div`
    .MuiPaper-root {
        box-shadow: none;
        background: none;
        /* width: 129%; */
        border-bottom: 1px solid rgba(224, 224, 224, 1);
    }
`
export const BoxStatus = styled.div`
    /* .MuiTableCell-root {
        border-bottom: none;
    } */
`

export const LeadDetail = styled.div`
    font-size: 13px;
    color: #3c8dbc;
`