import {
  Button,
  Checkbox,
  Grid,
  MenuItem,
  Select,
  TextField,
  FormControlLabel,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { BoxInput, TitleInput } from "./styled";
import { CustomButton } from "../../../../../components/Common/CustomButton";
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import DeleteIcon from "@mui/icons-material/Delete";
import { putPositions } from "../../../../../redux/departmentsId/action";
import { getModule } from "../../../../../redux/module/action";
import {
  getPermissions,
  editPermissions,
} from "../../../../../redux/Permissions/action";
import { red } from "@mui/material/colors";

const colorError = red[500];
const DialogEditPosition = ({ setUDO2, item2 }) => {
  const dispatch = useDispatch();
  const [idEdit, setIdEdit] = useState(item2.id);
  const [nameEdit, setNameEdit] = useState(item2.name);
  const dataModule = useSelector((item) => item.dataModuleReducer.data);
  const dataPermission = useSelector((item) => item.permissionReducer.data[0]);
  const [modules, setModules] = useState([]);
  const [permission, setPermission] = useState([]);
  const [selected, setSelected] = useState([]);

  const validationSchema = yup.object({
    // name: yup.string().required('Bạn chưa nhập tên phòng ban'),
  });

  useEffect(() => {
    dispatch(getModule());
    dispatch(getPermissions(item2.id));
  }, []);

  useEffect(() => {
    if (dataModule) {
      setModules(
        dataModule.map((item) => {
          return { value: item.unique_id, label: item.name };
        })
      );
    }
  }, [dataModule]);

  useEffect(() => {
    if (dataPermission) {
      setPermission(
        dataPermission.module_page.map((item) => {
          return item.unique_id;
        })
      );
    }
  }, [dataPermission]);

  // const handleChange = (event) => {
  //   const {
  //     target: { value },
  //   } = event;
  //   formik.setFieldValue("permission", value);
  //   setPermission(value);
  // };

  const handleClick = (event, name) => {
    const selectedIndex = permission.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(permission, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(permission.slice(1));
    } else if (selectedIndex === permission.length - 1) {
      newSelected = newSelected.concat(permission.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        permission.slice(0, selectedIndex),
        permission.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
    formik.setFieldValue("permission", newSelected);
    setPermission(newSelected);
  };
  const isSelected = (name) => permission.indexOf(name) !== -1;
  const formik = useFormik({
    initialValues: {
      id: idEdit,
      name: nameEdit,
      permission,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      dispatch(putPositions({ id: values.id, name: values.name }));
      dispatch(editPermissions({ id: values.id, pages: values.permission }));
      setUDO2(false);
    },
  });
  
  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={12} style={{display:'none'}}>
          <BoxInput>
            <TitleInput>ID chức vụ <span style={{ color: colorError }}>*</span></TitleInput>
            <TextField
              disabled
              id="id"
              name="id"
              size="small"
              fullWidth
              value={idEdit}
            />
          </BoxInput>
        </Grid>
        <Grid item xs={12}>
          <BoxInput>
            <TitleInput>
              Tên chức vụ <span style={{ color: colorError }}>*</span>
            </TitleInput>
            <TextField
              id="name"
              name="name"
              size="small"
              fullWidth
              placeholder="Tên chức vụ"
              value={nameEdit}
              onChange={(e) => {
                formik.setFieldValue("name", e.currentTarget.value);
                setNameEdit(e.currentTarget.value);
              }}
            />
          </BoxInput>
          <BoxInput>
            <TitleInput>Quyền truy cập</TitleInput>

            {modules.map((item, index) => {  
              const isItemSelected = isSelected(item.value); 
            return (
              <FormControlLabel
                key={index + item.label}
                control={
                  <Checkbox
                    inputProps={{ "aria-label": "controlled" }}
                    sx={{
                      ".MuiSvgIcon-root": {
                        width: "0.8em",
                        height: "0.8em",
                      },
                    }}
                    color="primary"
                    name={item.label}
                    value={item.value}
                    onClick={(event) => handleClick(event, item.value)}
                    checked={isItemSelected}
                  />
                }
                label={item.label}
              />
            )
            })}
          </BoxInput>
          

          <BoxInput>
            <CustomButton
              variant="contained"
              type="submit"
              onClick={formik.handleSubmit}
              style={{ marginLeft: "auto" }}
            >
              Sửa
            </CustomButton>
          </BoxInput>
        </Grid>
      </Grid>
    </form>
  );
};

export default DialogEditPosition;
