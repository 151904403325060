export const GET_SMSCAMP = "GET_SMSCAMP"
export const GET_SMSCAMP_SUCCESS = "GET_SMSCAMP_SUCCESS"
export const GET_SMSCAMP_FAIL = "GET_SMSCAMP_FAIL"

export const CREATE_SMSCAMP = "CREATE_SMSCAMP"
export const CREATE_SMSCAMP_SUCCESS = "CREATE_SMSCAMP_SUCCESS"
export const CREATE_SMSCAMP_FAIL = "CREATE_SMSCAMP_FAIL"

export const RESET_MESSAGE = "RESET_MESSAGE"
export const RESET_MESSAGE_ERROR = "RESET_MESSAGE_ERROR"

export const EDIT_SMSCAMP = "EDIT_SMSCAMP"
export const EDIT_SMSCAMP_SUCCESS = "EDIT_SMSCAMP_SUCCESS"
export const EDIT_SMSCAMP_FAIL = "EDIT_SMSCAMP_FAIL"

export const DELETE_SMSCAMP = "DELETE_SMSCAMP"
export const DELETE_SMSCAMP_SUCCESS = "DELETE_SMSCAMP_SUCCESS"
export const DELETE_SMSCAMP_FAIL = "DELETE_SMSCAMP_FAIL"