import { Button, Checkbox, Grid, TextField } from "@mui/material";
import { CustomButton } from "../../../../../components/Common/CustomButton";
import React, { useState } from "react";
import { BoxInput, TitleInput } from "./styled";
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { putProducts } from "../../../../../redux/products/action";

const DialogEditProduct = ({ setUDO, dataEdit }) => {
  const [nameEdit, setNameEdit] = useState(dataEdit?.title);
  const [description, setDescription] = useState(dataEdit?.description);
  const [price, setPrice] = useState(dataEdit?.price);
  const [recurring_fee, setRecurring_fee] = useState(dataEdit?.recurring_fee);

  const dispatch = useDispatch();

  const validationSchema = yup.object({
    title: yup.string().trim().min(1, "").required("Tên sp là bắt buộc"),
  });

  const formik = useFormik({
    initialValues: {
      id: dataEdit?.id,
      title: nameEdit,
      price: price,
      recurring_fee: recurring_fee,
      activated: true,
      description: description,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      dispatch(putProducts(values));
      setUDO(false);
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1}}>
        <Grid item xs={12}>
          <BoxInput>
            <TitleInput>Sản phẩm *</TitleInput>
            <TextField
              id="title"
              name="title"
              size="small"
              fullWidth
              placeholder="Sản phẩm"
              value={nameEdit}
              error={formik.touched.title && Boolean(formik.errors.title)}
              onChange={(e) => {
                formik.setFieldValue("title", e.currentTarget.value);
                setNameEdit(e.currentTarget.value);
              }}
            />
          </BoxInput>
          <BoxInput style={{ display: "flex", marginBottom: 0 }}>
            <TitleInput>Phí định kỳ</TitleInput>
            <div style={{ width: "calc(100% - 100px)", marginTop: "-10px" }}>
              <Checkbox
                id="recurring_fee"
                name="recurring_fee"
                checked={recurring_fee}
                onChange={(e) => {
                  formik.setFieldValue(
                    "recurring_fee",
                    e.currentTarget.checked
                  );
                  setRecurring_fee(e.currentTarget.checked);
                }}
              />
            </div>
          </BoxInput>
          <BoxInput>
            <TitleInput>Mô tả sản phẩm</TitleInput>
            <TextField
              id="description"
              name="description"
              size="small"
              fullWidth
              placeholder="Mô tả sản phẩm"
              value={description}
              onChange={(e) => {
                formik.setFieldValue("description", e.currentTarget.value);
                setDescription(e.currentTarget.value);
              }}
            />
          </BoxInput>
          <BoxInput>
            <TitleInput>Giá bán *</TitleInput>
            <TextField
              id="price"
              name="price"
              size="small"
              fullWidth
              placeholder="Giá bán"
              value={price}
              onChange={(e) => {
                formik.setFieldValue("price", e.currentTarget.value);
                setPrice(e.currentTarget.value);
              }}
            />
          </BoxInput>
          <BoxInput>
            <CustomButton
              // color="primary"
              variant="contained"
              type="submit"
              onClick={formik.handleSubmit}
              style={{marginLeft:'auto'}}
            >
              Lưu
            </CustomButton>
          </BoxInput>
        </Grid>
      </Grid>
    </form>
  );
};

export default DialogEditProduct;
