
import * as types from "./type"

export const getSmscamp = (data) => ({
  type: types.GET_SMSCAMP,
  payload: data
})
export const getSmscampSuccess = (data) => ({
  type: types.GET_SMSCAMP_SUCCESS,
  payload: data
})
export const getSmscampFail = (data) => ({
  type: types.GET_SMSCAMP_FAIL,
  payload: data
})

// for create company

export const createSmscamp = (data) => ({
  type: types.CREATE_SMSCAMP,
  payload: data
})

export const createSmscampSuccess = (data) => ({
  type: types.CREATE_SMSCAMP_SUCCESS,
  payload: data
})
export const createSmscampFail = (data) => ({
  type: types.CREATE_SMSCAMP_FAIL,
  payload: data
})



export const resetMessage = (data) => ({
  type: types.RESET_MESSAGE,
  payload: data
})

export const resetMessageError = (data) => ({
  type: types.RESET_MESSAGE_ERROR,
  payload: data
})

//EDIT
export const editSmscamp = (data) => ({
  type: types.EDIT_SMSCAMP,
  payload: data
})
export const editSmscampSuccess = (data) => ({
  type: types.EDIT_SMSCAMP_SUCCESS,
  payload: data
})
export const editSmscampFail = (data) => ({
  type: types.EDIT_SMSCAMP_FAIL,
  payload: data
})

//Delete
export const deleteSmscamp = (data) => ({
  type: types.DELETE_SMSCAMP,
  payload: data
})
export const deleteSmscampSuccess = (data) => ({
  type: types.DELETE_SMSCAMP_SUCCESS,
  payload: data
})
export const deleteSmscampFail = (data) => ({
  type: types.DELETE_SMSCAMP_FAIL,
  payload: data
})