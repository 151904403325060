import * as types from './type'

const initialState = {
    loading: false,
    dataCustomers: []
}

export const getCustomersReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_CUSTOMER_START: 
            return {
                ...state,
                loading: true,
            }
        
        case types.GET_CUSTOMER_SUCCESS:
            return {
                ...state,
                loading: false,
                dataCustomers: action.payload
            }
        
        case types.GET_CUSTOMER_ERROR:
            return {
                ...state,
                loading: false,
            }
        default:
            return state
    }
}