import * as types from "./type"

export const addCustomerStart = (data) => ({
        type: types.ADD_CUSTOMER_START,
        payload: data
})

export const addCustomerSuccess = (data) => ({
    type: types.ADD_CUSTOMER_SUCCESS,
    payload: data
})

export const addCustomerError = (data) => ({
    type: types.ADD_CUSTOMER_ERROR,
    payload: data
})

export const editOrderStart = (data) => ({
    type: types.EDIT_ORDER_START,
    payload: data
})

export const editOrderSuccess = (data) => ({
    type: types.EDIT_ORDER_SUCCESS,
    payload: data
})