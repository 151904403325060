
import * as types from "./type"

export const getCompanies = (data) => ({
  type: types.GET_COMPANIES,
  payload: data
})
export const getCompaniesSuccess = (data) => ({
  type: types.GET_COMPANIES_SUCCESS,
  payload: data
})
export const getCompaniesFail = (data) => ({
  type: types.GET_COMPANIES_FAIL,
  payload: data
})

// for create company

export const createCompany = (data) => ({
  type: types.CREATE_COMPANY,
  payload: data
})

export const createCompanySuccess = (data) => ({
  type: types.CREATE_COMPANY_SUCCESS,
  payload: data
})
export const createCompanyFail = (data) => ({
  type: types.CREATE_COMPANY_FAIL,
  payload: data
})


// for create admin company

export const createAdminCompany = (data) => ({
  type: types.CREATE_ADMIN_COMPANY,
  payload: data
})

export const createAdminCompanySuccess = (data) => ({
  type: types.CREATE_ADMIN_COMPANY_SUCCESS,
  payload: data
})
export const createAdminCompanyFail = (data) => ({
  type: types.CREATE_ADMIN_COMPANY_FAIL,
  payload: data
})

export const resetMessage = (data) => ({
  type: types.RESET_MESSAGE,
  payload: data
})

export const resetMessageError = (data) => ({
  type: types.RESET_MESSAGE_ERROR,
  payload: data
})

//EDIT
export const editCompany = (data) => ({
  type: types.EDIT_COMPANY,
  payload: data
})
export const editCompanySuccess = (data) => ({
  type: types.EDIT_COMPANY_SUCCESS,
  payload: data
})
export const editCompanyFail = (data) => ({
  type: types.EDIT_COMPANY_FAIL,
  payload: data
})