import { put, takeLatest } from "redux-saga/effects";
import * as types from './type';
import * as actions from './action';
import axiosInstance from "../../components/axiosApi";
import { showMessageSuccess } from "../notification/action";

function* getSmsconfig() {
  try {
    const res = yield axiosInstance.get(`/sms/config`)
    yield put(actions.getSmsconfigSuccess(res.data))
  } catch (error) {
    yield put(actions.getSmsconfigFail(error))
  }
}


function* createSmsconfig({ payload }) {
  try {
    const res = yield axiosInstance.post(`/sms/config`, payload)
    yield put(actions.createSmsconfigSuccess(res.data))
  } catch (error) {
    yield put(actions.createSmsconfigFail(error))
  }
}

function* editSmsconfig({payload}) {
    const { id,name,password,brand,tranid,username,url} = payload
    let params = `id=${id}&name=${name}&brandName=${brand}&tranId=${tranid}&userName=${username}&password=${encodeURIComponent(password)}&url=${url}`

    try {
        
        const res = yield axiosInstance.put(`/sms/config?${params}`);
        yield put(actions.editSmsconfigSuccess(res.data))
        yield put(showMessageSuccess("Cập nhập thành công"))
        
    } catch (error) {
      yield put(actions.editSmsconfigFail(error))
    }
}


function* resetMessage() {
  yield put(actions.resetMessageError({}))
}

export function* watchingSmsConfig() {
  yield takeLatest(types.GET_SMSCONFIG, getSmsconfig);
  yield takeLatest(types.CREATE_SMSCONFIG, createSmsconfig);
  yield takeLatest(types.RESET_MESSAGE, resetMessage);
  yield takeLatest(types.EDIT_SMSCONFIG, editSmsconfig);
}
