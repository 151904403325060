export const GET_USERS_START = "GET_USERS_START";
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS";
export const GET_USERS_ERROR = "GET_USERS_ERROR";

export const CREATE_USERS_START = "CREATE_USERS_START";
export const CREATE_USERS_SUCCESS = "CREATE_USERS_SUCCESS";
export const CREATE_USERS_ERROR = "CREATE_USERS_ERROR";

export const UPDATE_USERS = "UPDATE_USERS";
export const UPDATE_USERS_SUCCESS = "UPDATE_USERS_SUCCESS";
export const UPDATE_USERS_ERROR = "UPDATE_USERS_ERROR";

export const UPDATE_REGISTER_START = 'UPDATE_REGISTER_START';
export const UPDATE_REGISTER_CALL = "UPDATE_REGISTER_CALL";
export const UPDATE_REGISTERING_CALL = "UPDATE_REGISTERING_CALL";
export const UPDATE_UNREGISTER_CALL = "UPDATE_UNREGISTER_CALL";