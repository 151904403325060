import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);

export function ChartDoughnut({ data }) {
  return (
    <Doughnut
      data={data}
      
      options={{
        responsive: true,
        cutoutPercentage: 80,
        maintainAspectRatio: false,
        plugins: {
          
          legend: {
          display: true,
          position: "right",
          align: "center",
          fontFamily: "Open Sans",
          textDirection: 'ltr',
            labels: {
              usePointStyle: true,
              boxWidth: 8
            }
        },
          title: {
            display: true,
            text: data.title,
            font: {
              size: 16
            }
          },
        },
      }}
    />
  );
}
