import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  
  TextField,
  Checkbox,
  MenuItem,
  Select,
  Grid,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { CustomButton } from "../../components/Common/CustomButton";

import DateTimePicker from "@mui/lab/DateTimePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import moment from "moment";

import styled from "styled-components";
import { useFormik } from "formik";
import * as Yup from "yup";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { createSmscamp, getSmscamp } from "../../redux/smscamp/action";
import { red } from "@mui/material/colors";
const colorError = red[500];
const statusData = [
  { id: 1, label: "Đang chờ" },
];

export const TitleInput = styled("p")(({ theme }) => ({
    fontSize: 14,
    fontWeight: 500,
    marginBottom: 10,
  }));
  
  export const BoxInput = styled("div")(({ theme }) => ({
    
    marginBottom:'10px'
  }));
  //#material disabled Selecbox
  export const StyledSelect = styled(Select)`

  &.Mui-disabled {
    background: #eee;
  }
`;

const useStyles = makeStyles((theme) => ({
    textFieldError: {
        "& input": {
          border: "1px solid red",
          color: "red",
        },
        "& p": {
          color: "red",
          marginLeft:0,
        },
      },

  
  customModalContent: {
    paddingLeft: "15px",
    paddingRight: "15px",
  },
  customModal: {
    width: "calc(100% - 20px)",
    marginLeft: "10px",
    marginRight: "10px",
  },
  customModalTitle: {
    padding: "10px 15px",
    color: "#fff",
    background: process.env.NODE_ENV === 'development' ? "#5a8dee" : process.env.REACT_APP_PRODUCT_BASE_COLOR,
    marginBottom:'15px',
  },
}));



const SmsCreateBot = ({onClose,dataLogoFooter, setUDO1}) => {
  const classes = useStyles();
  const [active, setActive] = useState(false);
  const [sendActive, setSendActive] = useState(false);
  const dispatch = useDispatch();

  //   data sms template

  const isAdmin = dataLogoFooter.role === 'ADMIN';
  const isStaff = dataLogoFooter.is_staff;
  


  const listSmsbot = useSelector(
    (item) => item.smsbotReducer.dataList.package
  );

  const listSmstemp = useSelector(
    (item) => item.smstemplateReducer.dataList.temp_cfg
  );


  const validationSchema = Yup.object({
    sendTime: Yup.string()
     .required("Trường này bắt buộc")
    
  });

  const { values, handleSubmit, handleChange,setFieldValue, handleBlur, errors, touched } =
  useFormik({
    initialValues: {
      name: "",
      num_total:'',
      desc:"",
      trangthai:statusData[0].id,
      sendTime:'',
      temp_id:'',
      hot_send:'',
      packageId:'',
      activate:active,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const smsValues = {
        ...values,
        sendTime: values.sendTime
        ? moment(values.sendTime).format("YYYY-MM-DD H:mm:ss")
        : "",
      };
      setUDO1(false);
      dispatch(createSmscamp(smsValues));
    },
  });




  return (
    <Dialog
      fullWidth={true}
      maxWidth={"sm"}
      open={true}
      onClose={onClose}
      style={{ minHeight: "100%" }}
      classes={{
        paper: classes.customModal,
      }}
    >
      <DialogTitle className={`${classes.customModalTitle}`}>
        Chiến dịch
        <IconButton
          onClick={() => setUDO1(false)}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: '#fff',
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={`${classes.customModalContent}`}>
          
      <Grid container rowSpacing={1} columnSpacing={{ sm: 2 }}>
        <Grid item xs={12} sm={6}>
        <BoxInput>
            <TitleInput>Tên chiến dịch</TitleInput>
            <TextField
              size="small"
              name="name"
              fullWidth
              placeholder="Tên"
              onChange={handleChange}
              value={values.name}
            />
          </BoxInput>
        </Grid>
        <Grid item xs={12} sm={6}>
        <BoxInput>
            <TitleInput>Số tin gửi tối đa</TitleInput>
            <TextField
              size="small"
              name="num_total"
              fullWidth
              placeholder="Số tin gửi tối đa"
              onChange={handleChange}
              value={values.num_total}
              type="number"
            />
          </BoxInput>
        </Grid>
        <Grid item xs={12} sm={6}>
        <BoxInput>
            <TitleInput>Mô tả</TitleInput>
            <TextField
              size="small"
              name="desc"
              fullWidth
              placeholder="Mô tả"
              onChange={handleChange}
              value={values.desc}
            />
          </BoxInput>
        </Grid>
        <Grid item xs={12} sm={6}>
        <BoxInput>
          <TitleInput>Trạng thái</TitleInput>

          <StyledSelect
            style={{ fontSize: 14 }}
            labelId="trangthai"
            fullWidth
            size="small"
            label="Trạng thái"
            name="trangthai"
            value={values.trangthai}
            onChange={handleChange}
            disabled
            
          >
            {statusData.map((data, index) => (
              <MenuItem key={index} value={data.id}>
                {data.label}
              </MenuItem>
            ))}
          </StyledSelect>
          </BoxInput>
        </Grid>
        <Grid item xs={12}>
        <BoxInput>
            <TitleInput>Thời gian gửi <span style={{ color: colorError }}>*</span></TitleInput>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DateTimePicker
                label="Thời gian gửi"
                
                value={values.sendTime}
                onChange={(value) => setFieldValue("sendTime", value)}
                onBlur={handleBlur}
                  error={errors.sendTime && touched.sendTime ? classes.textFieldError : ""}
                  helperText={errors.sendTime && errors.sendTime ? errors.sendTime : ""}

                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    style={{ marginLeft: "auto" }}
                  />
                )}
              />
            </LocalizationProvider>
          </BoxInput>
        </Grid>
        

        <Grid item xs={12} sm={6}>
        <TitleInput>Gói dữ liệu</TitleInput>
        <Select
                    labelId="packageId"
                    fullWidth
                    size="small"
                    label="Gói dữ liệu"
                    name="packageId"
                    value={values.packageId}
                    onChange={handleChange}
                  >
                    {listSmsbot?.list_package.length > 0 ? (
                      listSmsbot?.list_package.map((item) => (
                        <MenuItem value={item.id}>{item.name}</MenuItem>
                      ))
                    ) : (
                      <MenuItem value={""}>--none--</MenuItem>
                    )}
                  </Select>

        </Grid>
        <Grid item xs={12} sm={6}>
        <BoxInput>
            <TitleInput>Mẫu tin nhắn</TitleInput>
            <Select
                    labelId="temp_id"
                    fullWidth
                    size="small"
                    label="Mẫu tin nhắn"
                    name="temp_id"
                    value={values.temp_id}
                    onChange={handleChange}
                  >
                    {listSmstemp.length > 0 ? (
                      listSmstemp.map((item) => (
                        <MenuItem value={item.id}>{item.name}</MenuItem>
                      ))
                    ) : (
                      <MenuItem value={""}>--none--</MenuItem>
                    )}
                  </Select>
          </BoxInput>
        </Grid>
        <Grid item xs={12}>
        <BoxInput style={{display:'flex'}}>
            <TitleInput>Gửi ngay</TitleInput>
            
              <Checkbox style={{marginTop:'-12px'}}
                id="hot_send"
                name="hot_send"
                checked={sendActive}
                onChange={(e) => {
                  setFieldValue(
                    "hot_send",
                    e.currentTarget.checked
                  );
                  setSendActive(e.currentTarget.checked);
                }}
                />


<TitleInput>Kích hoạt chiến dịch</TitleInput>
            
            <Checkbox style={{marginTop:'-12px'}}
              disabled = {(isAdmin||isStaff) ? false : true}
              id="activate"
              name="activate"
              checked={active}
              onChange={(e) => {
                setFieldValue(
                  "activate",
                  e.currentTarget.checked
                );
                setActive(e.currentTarget.checked);
              }}
              />
          </BoxInput>
          
            
          
        </Grid>
      </Grid>
      

          

          

          
          
          





          
          <CustomButton
            // color="primary"
            variant="contained"
            type="submit"
            onClick={() => handleSubmit()}
            style={{marginLeft:'auto'}}
          >
            Tạo 
          </CustomButton>

      </DialogContent>
    </Dialog>
  );
};

export default SmsCreateBot;