import { put, takeLatest } from "redux-saga/effects";
import * as types from './type';
import * as actions from './action';
import axiosInstance from "../../components/axiosApi";

function* getSmshistory(action) {
  try {
    let params = ''
    if(action.payload) {
      const {  offset, limit } = action.payload
      params += `offset=${offset}&limit=${limit}`
    }
    const res = yield axiosInstance.get(`/sms/sms-history?${params}`)
    yield put(actions.getSmshistorySuccess(res.data))
  } catch (error) {
    yield put(actions.getSmshistoryFail(error))
  }
}




function* resetMessage() {
  yield put(actions.resetMessageError({}))
}

export function* watchingSmshistory() {
  yield takeLatest(types.GET_SMSHISTORY, getSmshistory);
  yield takeLatest(types.RESET_MESSAGE, resetMessage);
}
