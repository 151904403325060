import {
    Table,
    TableBody,
    TableContainer,
    TableRow,
    Paper,
    TablePagination,
    Box,
    IconButton,
  } from "@mui/material";
  import React, {useState} from "react";
  import PropTypes from "prop-types";
  import { withStyles } from "@material-ui/core/styles";
  import { makeStyles } from "@material-ui/core/styles";
  import MuiTableCell from "@material-ui/core/TableCell";
  import MuiTableHead from "@mui/material/TableHead";
  import { useTheme } from "@mui/material/styles";
  import LastPageIcon from "@mui/icons-material/LastPage";
  import FirstPageIcon from "@mui/icons-material/FirstPage";
  import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
  import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
  import { renderWithCommand } from "../../../../helpers";
  import { getReportCall } from "../../../../redux/report/action";
  import { useDispatch } from "react-redux";

  const useStyles = makeStyles((theme) => ({
    tableBorder: {
      border: "1px solid #d4d8dd",
      borderRadius:'0.5rem',
    },
  }));
  
  const TableCell = withStyles({
    root: {
      borderBottom: "1px solid rgb(235, 237, 242)",
      padding: "10px",
      lineHeight: "18px",
    },
  })(MuiTableCell);
  
  const TableHead = withStyles((theme) => ({
    root: {
      backgroundColor: "rgba(38,60,85,.1)",
    },
  }))(MuiTableHead);
  
  const TableHeaderCell = withStyles((theme) => ({
    root: {
      color: "#516377",
      fontWeight: 700,
      padding: 10,
      fontSize: ".75rem",
      textTransform: "uppercase",
      letterSpacing: "0.8px",
    },
  }))(TableCell);


  function TablePaginationActions(props) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;
  
    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    };
  
    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };
  
    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };
  
    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };
  
    return (
      <Box sx={{ flexShrink: 0, ml: 2.5 }}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </Box>
    );
  }
  
  TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
  };


const TableReportCall = ({ data,dataPagination,setDataPagination, }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const handleChangePage = (event, newPage) => {
      let data = {
        offset: newPage * rowsPerPage,
        limit: rowsPerPage,
      };
      dispatch(getReportCall(data));
      setDataPagination(data);
      setPage(newPage);
    };
  
    const handleChangeRowsPerPage = (event) => {
      let data = {
        offset: page,
        limit: event.target.value !== "-1" ? +event.target.value : 9999,
      };
      setDataPagination(data);
      dispatch(getReportCall(data));
      setRowsPerPage(+event.target.value);
      setPage(0);
    };

    return (
        <Paper
      sx={{ width: "100%", mb: 2 }}
      elevation={0}
      className={`${classes.tableBorder}`}
    >
        <TableContainer style={{ width: "100%", overflowX: "auto" ,borderRadius:'0.5rem'}}>
            <Table sx={{ minWidth: 700 }} size={"small"}>
                <TableHead>
                    <TableRow>
                        <TableHeaderCell align="left">STT</TableHeaderCell>
                        <TableHeaderCell align="left" style={{ minWidth: 120 }}>
                            Nhân viên
                        </TableHeaderCell>
                        
                        <TableHeaderCell align="left" style={{ minWidth: 150 }}>
                        Tổng cuộc gọi
                        </TableHeaderCell>
                        <TableHeaderCell align="left" style={{ minWidth: 135 }}>
                        Tổng cuộc nghe máy
                        </TableHeaderCell>
                        <TableHeaderCell align="left" style={{ minWidth: 155 }}>
                        Tổng thời gian gọi
                        </TableHeaderCell>
                        <TableHeaderCell align="left" style={{ minWidth: 125 }}>
                        Tổng thời gian nghe máy
                        </TableHeaderCell>
                        <TableHeaderCell align="left" style={{ minWidth:125 }}>
                        Tổng đơn hàng
                        </TableHeaderCell>
                        <TableHeaderCell align="left" style={{ minWidth:125 }}>
                        Tổng đơn hàng đã chốt
                        </TableHeaderCell>
                        <TableHeaderCell align="left" style={{ minWidth:125 }}>
                        Tổng tiền
                        </TableHeaderCell>
                        <TableHeaderCell align="left" style={{ minWidth:125 }}>
                        Tổng KM

                        </TableHeaderCell>
                        <TableHeaderCell align="left" style={{ minWidth:125 }}>
                        Tỷ lệ chuyển đổi


                        </TableHeaderCell>
                        
                    </TableRow>
                </TableHead>

                <TableBody>
                    {
                        data?.data?.length > 0 ? data?.data.map((row, index) => {
                            return (
                                <TableRow
                                    hover={true}
                                    key={index}
                                >
                                    <TableCell align="left">{index + 1}</TableCell>
                                    <TableCell align="left">{row?.User}</TableCell>
                                    <TableCell align="left">{row?.call_Detail?.totalCalls}</TableCell>
                                    <TableCell align="left">{row?.call_Detail?.totalAttended}</TableCell>
                                    <TableCell align="left">{row?.call_Detail?.totalDuration}</TableCell>
                                    <TableCell align="left">{row?.call_Detail?.totalAttendedDuration}</TableCell>
                                    <TableCell align="left">{row?.total_order}</TableCell>
                                    <TableCell align="left">{row?.total_ordered}</TableCell>

                                    <TableCell align="left">{renderWithCommand(row?.total_money?.total__sum)} VNĐ</TableCell>

                                    <TableCell align="left">{renderWithCommand(row?.total_discount?.discount__sum)} VNĐ</TableCell>

                                    <TableCell align="left">{row?.converts}</TableCell>


                                    
                                </TableRow>
                            )
                        }) : <h1>Không có dữ liệu</h1>
                    }
                </TableBody>
            </Table>
            {
              data?.data && (
                <TablePagination
        rowsPerPageOptions={[10,25]}
        colSpan={3}
        labelRowsPerPage={"Số hàng trên 1 trang:"}
        count={data?.pagination[0]?.count}
        rowsPerPage={rowsPerPage}
        page={page}
        SelectProps={{
          inputProps: {
            "aria-label": "rows per page",
          },
          native: true,
        }}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        ActionsComponent={TablePaginationActions}
      />
              )
            }
            
        </TableContainer>
        </Paper>
    );
};

export default TableReportCall;