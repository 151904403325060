import { combineReducers } from "redux";
import { listAddressFilterRuducer } from "./address/reducer";
import { UsersReducer } from "./users/reducer";
import { trangThaiDataReducer } from "./trangThaiData/reducer";
import { addCustomerReducer } from "./add-customers/reducer";
import { getCustomersReducer } from "./customers/reducer";
import { listCityRuducer } from "./city/reducer";
import { sourceDataReducer } from "./sourceData/reducer";
import { productReducer } from "./products/reducer";
import { dataLogoFooterRuducer } from "./logoFooter/reducer";
import { dataLeadStatusRuducer } from "./sourceLead/reducer";
import { dataLeadStatusIdRuducer } from "./sourceLeadId/reducer";
import { statusLeadRuducer } from "./statusLead/reducer";
import { notificationReducer } from "./notification/reducer";
import { orgUsersReducer } from "./org/reducer";
import { departmentsRuducer } from "./Departments/reducer";
import { companyReducer } from "./company/reducer";
import { callPhoneReducer } from "./callPhone/reducer";
import { orderReducer } from "./order/reducer";
import { creditReducer } from "./credit/reducer";
import { reportReducer } from "./report/reducer";
import { statusLeadIdReducer } from "./statusLeadId/reducer";
import { departmentsIdRuducer } from "./departmentsId/reducer";
import { dataNewRevenueReducer } from "./newRevenue/reducer";
import { dataNewRecurringRevenueReducer } from "./recurringRevenue/reducer";
import { dataDebtRevenueReducer } from "./debtRevenue/reducer";
import { dataCdrReducer } from "./cdr/reducer";
import { dataModuleReducer } from "./module/reducer";
import { permissionReducer } from "./Permissions/reducer";
import { PbxReducer } from "./pbx/reducer";
import { smsconfigReducer } from "./smsconfig/reducer";
import { smstemplateReducer } from "./smstemplate/reducer";
import { smsbotReducer } from "./smsbots/reducer";
import { smscampReducer } from "./smscamp/reducer";
import { smshistoryReducer } from "./smshistory/reducer";
import { callstatusReducer } from "./callstatus/reducer";
import { ticketReducer } from "./ticket/reducer";

const rootReducer = combineReducers({
  listAddressFilterRuducer,
  addCustomerReducer,
  getCustomersReducer,
  UsersReducer,
  trangThaiDataReducer,
  listCityRuducer,
  sourceDataReducer,
  productReducer,
  dataLogoFooterRuducer,
  dataLeadStatusRuducer,
  statusLeadRuducer,
  dataLeadStatusIdRuducer,
  notificationReducer,
  orgUsersReducer,
  departmentsRuducer,
  callPhoneReducer,
  statusLeadIdReducer,
  departmentsIdRuducer,
  dataNewRevenueReducer,
  dataNewRecurringRevenueReducer,
  dataDebtRevenueReducer,
  companyReducer,
  orderReducer,
  creditReducer,
  reportReducer,
  dataCdrReducer,
  dataModuleReducer,
  permissionReducer,
  PbxReducer,
  smsconfigReducer,
  smstemplateReducer,
  smsbotReducer,
  smscampReducer,
  smshistoryReducer,
  callstatusReducer,
  ticketReducer,
});

export default rootReducer;
