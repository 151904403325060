import { Grid, Paper, IconButton } from "@mui/material";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";
import AddShoppingCartIcon from "@mui/icons-material/AddOutlined";
import Tooltip from "@mui/material/Tooltip";
import IosShareOutlinedIcon from "@mui/icons-material/IosShareOutlined";
import TextField from "@mui/material/TextField";
import Divider from "@mui/material/Divider";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import DiaLogAddCredit from "./components/DialogCredit";

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../../components/Common/Loading";
import CreditListTable from "./components/table";
import moment from "moment";
import { BoxInput } from "../Customer/components/DialogCustomer/styled";
import { CustomButton } from "../../components/Common/CustomButton";
import { getCredits } from "../../redux/credit/action";
import Excel from "exceljs";
import { saveAs } from "file-saver";
import { makeStyles } from "@material-ui/core/styles";
import { getUserStart } from "../../redux/users/action";

const useStyles = makeStyles((theme) => ({
  btnIcon: {
    fontSize: 16,
  },
  iconMenuItem: {
    marginRight: 10,
  },
  btnGlobal: {
    minWidth: "inherit",
    width: 36,
    height: 36,
    lineHeight: 36,
    padding: 0,

    marginLeft: 5,
  },
  customModalContent:{
    paddingLeft:'15px',
    paddingRight:'15px'
  },
  customModal:{
    width:'calc(100% - 20px)',
    marginLeft:'10px',
    marginRight:'10px'
  },
  customModalTitle:{
    padding:'10px 15px',
    color:'#fff',
    background:process.env.NODE_ENV === 'development' ? "#5a8dee" : process.env.REACT_APP_PRODUCT_BASE_COLOR
  }
}));

export const PAID_STATUS = [
  { value: true, title: "Đã thanh toán" },
  { value: false, title: "Chưa thanh toán" },
];

export default function CreditManagement({dataLogoFooter}) {
  const classes = useStyles();
  const [isLoading, setLoading] = useState(true);
  const [createOpen, setCreateOpen] = React.useState(false);
  const [detailCredit, setDetailCredit] = useState(null);
  const [phone, setPhone] = useState(null);
  const dataCredits = useSelector((state) => state.creditReducer);
  

  const workSheetName = "Danh sách đơn hàng";
  const workBookName = "DanhSachDonHang";
  const myInputId = "myInput";
  const workbook = new Excel.Workbook();
  const columns = [
    { header: "STT", key: "stt" },
    { header: "MÃ CÔNG NỢ", key: "credit_no" },
    { header: "MÃ ĐƠN HÀNG", key: "order_no" },
    { header: "TÊN KHÁCH HÀNG", key: "fullName" },
    { header: "SỐ ĐIỆN THOẠI", key: "phone" },
    { header: "TỔNG TIỀN", key: "amount" },
    { header: "NGÀY CÂN THANH TOÁN", key: "dueDate" },
    { header: "NGÀY PHÊ DUYỆT", key: "approved_date" },
    { header: "TRẠNG THÁI THANH TOÁN", key: "paid" },
    { header: "NGƯỜI PHỤ TRÁCH", key: "assign_to" },
  ];

  const initialValueSearch = {
    credit_no: "",
    order_no: "",
    phone: "",
    fullname: "",
  };

  const [search, setSearch] = useState(initialValueSearch);

  const dispatch = useDispatch();

  const creditsData = useSelector((state) => state.creditReducer);
  const [dataPagination, setDataPagination] = useState({ offset: 0, limit: 5 });

  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    setSearch({ ...search, [name]: value });
  };

  // const handleChangeRowsPerPage = (event) => {
  //   setRowsPerPage(event.target.value);
  // };

  const handleSearch = () => {
    const valueSearch = {
      ...search,
      offset: dataPagination.offset,
      limit: dataPagination.limit,
    };
    dispatch(getCredits(valueSearch));
    if (openSidebar) setOpenSidebar(false);
  };
  const [openSidebar, setOpenSidebar] = useState(false);
  useEffect(() => {
    setTimeout(() => setLoading(false), 500);
    let data = {
      offset: 0,
      limit: 10,
    };
    dispatch(getCredits(data));
  }, []);

  const handleCloseCreateCreditDialog = () => {
    setCreateOpen(!createOpen);
    setDetailCredit(null);
  };

  const handleRowClick = (stt) => {
    setCreateOpen(true);
  };

  const saveExcel = async () => {
    // const newDataCreditToExport =
    try {
      const myInput = document.getElementById(myInputId);
      const fileName = myInput.value || workBookName;

      // creating one worksheet in workbook
      const worksheet = workbook.addWorksheet(workSheetName);

      // add worksheet columns
      // each columns contains header and its mapping key from data
      worksheet.columns = columns;

      // updated the font for first row.
      worksheet.getRow(1).font = { bold: true };

      // loop through all of the columns and set the alignment with width.
      worksheet.columns.forEach((column) => {
        column.width = column.header.length + 5;
        column.alignment = { horizontal: "center" };
      });

      // loop through data and add each one to worksheet
      dataCredits.dataList.credits.forEach((credit, index) => {
        const newData = {
          stt: index,
          credit_no: credit.credit_no,
          order_no: credit.order_no?.order_no,
          fullName: credit.order_no?.lead?.lead?.fullname,
          phone: credit.order_no?.lead?.lead?.phone,
          amount: credit.amount,
          dueDate: credit.dueDate
            ? moment(credit.dueDate).format("DD/MM/YYYY")
            : "",
          approved_date: credit.approved_date
            ? moment(credit.approved_date).format("DD/MM/YYYY")
            : "",
          paid: credit.paid ? "Đã thanh toán" : "Chưa thanh toán",
          assigned_to: credit.assigned_to,
        };
        worksheet.addRow(newData);
      });

      // loop through all of the rows and set the outline style.
      worksheet.eachRow({ includeEmpty: false }, (row) => {
        // store each cell to currentCell
        const currentCell = row._cells;

        // loop through currentCell to apply border only for the non-empty cell of excel
        currentCell.forEach((singleCell) => {
          // store the cell address i.e. A1, A2, A3, B1, B2, B3, ...
          const cellAddress = singleCell._address;

          // apply border
          worksheet.getCell(cellAddress).border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" },
          };
        });
      });

      // write the content using writeBuffer
      const buf = await workbook.xlsx.writeBuffer();

      // download the processed file
      saveAs(new Blob([buf]), `${fileName}.xlsx`);
    } catch (error) {
      console.error("<<<ERRROR>>>", error);
      console.error("Something Went Wrong", error.message);
    } finally {
      // removing worksheet's instance to create new one
      workbook.removeWorksheet(workSheetName);
    }
  };

  useEffect(() => {
    setTimeout(() => setLoading(false), 500);
    dispatch(getUserStart());
  }, []);

  return (
    <>
      <div className="screen">
        <Loading loading={isLoading} />

        <Paper className="order-list" style={{ marginBottom: "10px" }}>
          <div className="ul-heading-placeholder">
            <input
              style={{ display: "none" }}
              id={myInputId}
              defaultValue={workBookName}
            />
            <span
              className="ul-heading"
              style={{ display: "flex", alignItems: "center", fontWeight: 500 }}
            >
              Danh Sách Công Nợ
            </span>
            <div className="box-right">
              <Tooltip title="Tạo công nợ">
                <CustomButton
                  variant="contained"
                  className={`${classes.btnGlobal} ${classes.btnBlue}`}
                  onClick={() => {
                    handleRowClick();
                    // setIsAddingNewData(true);
                    // dispatch(getProductsData([], ""));
                  }}
                >
                  <AddShoppingCartIcon className={`${classes.btnIcon}`} />
                </CustomButton>
              </Tooltip>
              <Tooltip title="Tải xuống">
                <CustomButton
                  variant="contained"
                  className={`${classes.btnGlobal} ${classes.btnBlue}`}
                  onClick={saveExcel}
                >
                  <IosShareOutlinedIcon className={`${classes.btnIcon}`} />
                </CustomButton>
              </Tooltip>
              <CustomButton
                variant="contained"
                className={`${classes.btnGlobal} ${classes.btnBlue}`}
                onClick={() => setOpenSidebar(!openSidebar)}
              >
                <FilterAltIcon className={`${classes.btnIcon}`} />
              </CustomButton>
              <div
                className={`boxsearch-sidebar ${openSidebar ? "active" : ""}`}
              >
                <span
                  className="ul-heading"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    fontWeight: 500,
                  }}
                >
                  <ArrowBackIosNewOutlinedIcon
                    style={{
                      color: "#5a8dee",
                      marginRight: 5,
                      cursor: "pointer",
                    }}
                    onClick={() => setOpenSidebar(!openSidebar)}
                  />
                  Tìm kiếm
                </span>
                <Divider sx={{ display: { xs: 'block', sm: 'none' } }}/>
                <Grid
                  container
                  columnSpacing={{ xs: 1,sm: 1.5 }}
                  style={{ padding: "0 14px" }}
                >
                  <Grid item xs={12} sm={6} style={{marginTop:'10px'}}>
                  <BoxInput style={{ maxWidth: "100%"}}>
                      <TextField
                        id={search.credit_no}
                        name={"credit_no"}
                        size="small"
                        fullWidth
                        placeholder={"Nhập mã công nợ"}
                        onChange={handleChangeInput}
                      />
                    </BoxInput>
                    
                  </Grid>
                  <Grid item xs={12} sm={6} sx={{ marginTop:{sm:'10px'}}}>
                  <BoxInput style={{ maxWidth: "100%"}}>
                      <TextField
                        id={search.order_no}
                        name={"order_no"}
                        size="small"
                        fullWidth
                        placeholder={"Nhập mã đơn hàng"}
                        onChange={handleChangeInput}
                      />
                    </BoxInput>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    {/* <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="Ngày cần thanh toán"
                  value={""}
                  onChange={(newValue) => {
                    // setValue(newValue);
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider> */}

              <BoxInput style={{ maxWidth: "100%"}}>
                      <TextField
                        id={search.fullname}
                        name={"fullname"}
                        size="small"
                        fullWidth
                        placeholder={"Nhập tên khách hàng"}
                        onChange={handleChangeInput}
                      />
                    </BoxInput>
                    
                  </Grid>
                  <Grid item xs={12} sm={6}>
                  <BoxInput style={{ maxWidth: "100%" }}>
                      <TextField
                        id={search.ref_code}
                        name={"phone"}
                        size="small"
                        fullWidth
                        placeholder={"Nhập sdt khách hàng"}
                        onChange={handleChangeInput}
                      />
                    </BoxInput>
                  </Grid>
                  <Grid item xs={12}>
                  <CustomButton style={{width:'40px',height:'40px'}}
                        variant="contained"
                        className="search-row-button"
                        onClick={handleSearch}
                      >
                      Tìm kiếm
                    </CustomButton>
                  </Grid>
                </Grid>
                
                
              </div>
            </div>
          </div>
        </Paper>

        <Paper className="user-list">
          <CreditListTable
            creditsData={creditsData}
            // setDetailCustomer={setDetailCustomer}
            dataLogoFooter={dataLogoFooter}
            setPhone={setPhone}
            phone={phone}
            dataPagination={dataPagination}
            setDataPagination={setDataPagination}
          />
        </Paper>

        <Dialog
          fullWidth={true}
          maxWidth={"sm"}
          open={createOpen}
          onClose={handleCloseCreateCreditDialog}
          style={{ minHeight: "100%" }}
          classes={{
            paper: classes.customModal
          }}
        >
          <DialogTitle className={`${classes.customModalTitle}`}>
            Tạo công nợ
            <IconButton
              onClick={handleCloseCreateCreditDialog}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: '#fff',
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent className={`${classes.customModalContent}`}>
            <DiaLogAddCredit
              setCreateOpen={setCreateOpen}
              setdetailCredit={setDetailCredit}
              detailCredit={detailCredit}
              setDataPagination={setDataPagination}
              dataPagination={dataPagination}
            />
          </DialogContent>
        </Dialog>
      </div>
    </>
  );
}
