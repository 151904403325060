import React, { useState } from "react";




import Paper from "@mui/material/Paper";



import SmsCamp from "./SmsCamp";
import SmsBots from "./SmsBots";
import SmsHistory from "./SmsHistory";




const SmsLayout = ({ parentCallback, dataLogoFooter }) => {
  const [state, setState] = useState("sms-camp");

  const menuNav = [
    { panel:<SmsCamp  dataLogoFooter={dataLogoFooter} />, label: "Chiến Dịch", state: "sms-camp" },
    { panel:<SmsBots />, label: "Dữ Liệu", state: "sms-bots" },
    { panel:<SmsHistory />, label: "Lịch sử", state: "sms-history" },
  ];

  function toggleExpand()  {
    let main = document.querySelector(".zalo-wrapper");
    
    main.classList.toggle("expand");
   
  }

  


   
  

  return (
    <>
    <div className="screen">
      <Paper className="user-list zalo-wrapper">
      <span className="circle-expand" onClick={()=>toggleExpand()}>
        <i className="fas fa-expand"></i>
      </span>
        <ul className="sidebar-nav">

          {menuNav.map((item, index) => (
            <li
              className={`menuItem ${state === `${item.state}` ? "active" : ""}`}
              onClick={
                () => {
                  setState(`${item.state}`);
                }}
              key={index}
            >
              {item.label}
            </li>
          ))}
        </ul>
        <div className="tab-content">
        {menuNav.map((item, index) => (
          <div
            id={`${item.state}`}
            className={`tab-panel ${state === `${item.state}` ? "current" : ""}`}
            key={index}
            
          >
            {state === item.state ? item.panel : null }
          </div>

          ))}
        </div>
      </Paper>
    </div>
    </>
  );
};
export default SmsLayout;
