import React, { useEffect, useState } from "react";
import Loading from "../../components/Common/Loading";
import TicketList from "./TicketList";
const TicketLayout = ({directSelected, dataLogoFooter}) => {
    const [isLoading, setLoading] = useState(true);

    useEffect(() => {
        setTimeout(() => setLoading(false), 500);
        // eslint-disable-next-line
      }, []);
  return (
    <>
        <Loading loading={isLoading} />
    <div className="screen modify-label">
        <TicketList dataLogoFooter={dataLogoFooter} directSelected={directSelected}/>
    </div>
    </>
  )
}

export default TicketLayout