import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';

const SourceData = ({ 
    parentValue,
    onChangeParent,
    childValue,
    onChangeChild,
    subSource
}) => {
    const stateDataSource = useSelector((state) => state.sourceDataReducer)
    const newStateDataSource = [
        ...stateDataSource.sourceData,
        {id:'', title:'Chọn tất cả'}
      ]
    return (
        <>
            {/* <TitleInput>Nguồn Data</TitleInput> */}
            <div style={{ display: "flex", width: "100%" }}>
                <FormControl fullWidth>
                    <InputLabel id="dataOrigin">Chọn nguồn</InputLabel>
                    <Select
                        labelId="dataOrigin"
                        fullWidth
                        size="small"
                        label="Chọn nguồn"
                        name="dataOrigin"
                        value={parentValue}
                        onChange={onChangeParent}
                    >
                        {newStateDataSource && newStateDataSource.map((item, index) => (
                            <MenuItem key={index} value={item.id}>{item.title}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </div>
        </>

    );
};

export default SourceData;