import * as types from "./type"

export const getSourceData = () => ({
    type: types.GET_SOURCE_DATA_START
})

export const getSourceDataSuccess = (data) => ({
    type: types.GET_SOURCE_DATA_SUCCESS,
    payload: data
})

export const getSourceDataError = (data) => ({
    type: types.GET_SOURCE_DATA_ERROR,
    payload: data
})