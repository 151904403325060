import React from 'react'

const ZaloChatOaSidebar = ({setSelectedOA,selectedOA, data , setIDOA, onClickLoadDataChat}) => {

    const getDataFollowList = (item) => {
        
        onClickLoadDataChat(item)
        setIDOA(item)
    }

  return (
    <>
        <h3 className="heading">Chọn OA</h3>
        {
            data?.zaloOA && 
            data?.zaloOA.length > 0 &&
            data?.zaloOA.map((item,index) => (
                <div className={`item  ${selectedOA === index ? 'bg-blue' : ''}`} 
                    key={index}
                    
                    onClick={() => {
                        getDataFollowList(item?.id)
                        setSelectedOA(index)
                    }}
                    
                >
                    
                    <div className="info">
                        <div className="username">{item?.id}</div>
                        <div className="">{item?.oa_name}</div>
                    </div>
                    {
                        selectedOA === index ?
                        <i className="fas fa-check"></i> : null
                    }
                    
                </div>
            ))
        }
    </>
  )
}

export default ZaloChatOaSidebar