import React, { useState } from "react";
import { Paper } from "@mui/material";
import FacebookList from "./FacebookList";
import FacebookChat from "./FacebookChat";
import { useContext } from "react";
import { CountContext } from "../Notification/NotifyContent";

const FacebookLayout = () => {
  const dataContext = useContext(CountContext)
  
  const dataNotify = dataContext.info

  const [expand, setExpand] = useState(false)

  const [state, setState] = useState(dataNotify !== '' ? 'fbchat' : 'fblist');
  const menuNav = [
    { panel:<FacebookList />, label: "Đồng Bộ Facebook", state: "fblist" },
    { panel:<FacebookChat setExpand={setExpand}/>, label: "Hội Thoại Facebook", state: "fbchat" },
  ];
  function toggleExpand()  {
    let main = document.querySelector(".zalo-wrapper");
    
    main.classList.toggle("expand");
   
  }
  return (
    <div className="screen">
      <Paper className={`user-list zalo-wrapper ${expand ? 'expand' : ''}`}>
      <span className="circle-expand" onClick={()=>setExpand(!expand)}>
        <i className="fas fa-expand"></i>
      </span>
        <ul className="sidebar-nav">
          {menuNav.map((item, index) => (
            <li
              className={`menuItem ${state === `${item.state}` ? "active" : ""}`}
              onClick={() => setState(`${item.state}`)}
              key={index}
            >
              {item.label}
            </li>
          ))}
        </ul>
        <div className="tab-content">
        {menuNav.map((item, index) => (
          <div
            id={`${item.state}`}
            className={`tab-panel ${state === `${item.state}` ? "current" : ""}`}
            key={index}
          >
            {state === item.state ? item.panel : null }
          </div>

          ))}
        </div>
      </Paper>
    </div>
  );
};

export default FacebookLayout;
