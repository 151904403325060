export const GET_SMSCONFIG = "GET_SMSCONFIG"
export const GET_SMSCONFIG_SUCCESS = "GET_SMSCONFIG_SUCCESS"
export const GET_SMSCONFIG_FAIL = "GET_SMSCONFIG_FAIL"

export const CREATE_SMSCONFIG = "CREATE_SMSCONFIG"
export const CREATE_SMSCONFIG_SUCCESS = "CREATE_SMSCONFIG_SUCCESS"
export const CREATE_SMSCONFIG_FAIL = "CREATE_SMSCONFIG_FAIL"

export const RESET_MESSAGE = "RESET_MESSAGE"
export const RESET_MESSAGE_ERROR = "RESET_MESSAGE_ERROR"

export const EDIT_SMSCONFIG = "EDIT_SMSCONFIG"
export const EDIT_SMSCONFIG_SUCCESS = "EDIT_SMSCONFIG_SUCCESS"
export const EDIT_SMSCONFIG_FAIL = "EDIT_SMSCONFIG_FAIL"