export const GET_DEPARTMENTS_ID = "GET_DEPARTMENTS_ID"
export const GET_DEPARTMENTS_ID_SUCCESS = "GET_DEPARTMENTS_ID_SUCCESS"
export const GET_DEPARTMENTS_ID_FAIL = "GET_DEPARTMENTS_ID_FAIL"

export const CREATE_POSITIONS = "CREATE_POSITIONS"
export const CREATE_POSITIONS_SUCCESS = "CREATE_POSITIONS_SUCCESS"
export const CREATE_POSITIONS_FAIL = "CREATE_POSITIONS_FAIL"

export const PUT_POSITIONS = "PUT_POSITIONS"
export const PUT_POSITIONS_SUCCESS = "PUT_POSITIONS_SUCCESS"
export const PUT_POSITIONS_FAIL = "PUT_POSITIONS_FAIL"