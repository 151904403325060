import SettingsIcon from "@mui/icons-material/Settings";
import React, { useEffect } from "react";
import { Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import EmployeeManager from "./components/EmployeeManager";
import EmployeePbx from "./components/EmployeePbx";

import HomeIcon from "@mui/icons-material/Home";
import PermPhoneMsgOutlinedIcon from "@mui/icons-material/PermPhoneMsgOutlined";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
import { BoxReport } from "./styled";

import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import Loading from "../../components/Common/Loading";

import { useState } from "react";
import MeetingRoomIcon from "@mui/icons-material/MeetingRoom";

import { useDispatch, useSelector } from "react-redux";
import { getUserStart } from "../../redux/users/action";
import { makeStyles } from "@material-ui/core/styles";
import CorporateFare from "@mui/icons-material/CorporateFare";
import sms_icon from "../../assets/images/sms.png";

import PostAddOutlinedIcon from "@mui/icons-material/PostAddOutlined";

const useStyles = makeStyles((theme) => ({
  customModalContent: {
    paddingLeft: "15px",
    paddingRight: "15px",
  },
  customModal: {
    width: "calc(100% - 20px)",
    marginLeft: "10px",
    marginRight: "10px",
  },
  customModalTitle: {
    padding: "10px 15px",
    color: "#fff",
    background: process.env.NODE_ENV === 'development' ? "#5a8dee" :process.env.REACT_APP_PRODUCT_BASE_COLOR,
  },
}));
const Configuration = ({ parentCallback }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [isLoading, setLoading] = useState(true);
  const [userDetailOpen, setUDO] = useState(false);
  const [companyDetailOpen, setUDO2] = useState(false);
  const data = useSelector((item) => item.UsersReducer?.dataUsers);
  const dataLogoFooter = useSelector((item) => item.dataLogoFooterRuducer.data);
  

  const handleCloseCompanyDetailDialog = () => {
    setUDO2(!companyDetailOpen);
  };

  

  const handleCloseUserDetailDialog = () => {
    setUDO(!userDetailOpen);
  };

  const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    textAlign: "center",
    color: theme.palette.text.secondary,
    height: 60,
    lineHeight: "60px",
    boxShadow: "0 0.5rem 1rem rgb(0 0 0 / 5%)",
  }));

  const lightTheme = createTheme({ palette: { mode: "light" } });



  const handle = (type) => {
   
    if (type === "handle-open-company") {
      setUDO2(true);
    }

    if (type === "employee-manager") {
      // window.history.pushState({}, "", "employee-manager");
      // parentCallback("employee-manager");
      setUDO(true);
    }
    if (type === "ware-house") {
      window.history.pushState({}, "", "ware-house");
      parentCallback("ware-house");
    }
    if (type === "source-lead") {
      window.history.pushState({}, "", "source-lead");
      parentCallback("source-lead");
    }
    if (type === "status-lead") {
      window.history.pushState({}, "", "status-lead");
      parentCallback("status-lead");
    }
    if (type === "departments") {
      window.history.pushState({}, "", "departments");
      parentCallback("departments");
    }
    if (type === "staff") {
      window.history.pushState({}, "", "staff");
      parentCallback("staff");
    }
    if (type === "company") {
      window.history.pushState({}, "", "company");
      parentCallback("company");
    }
    if (type === "extension") {
      window.history.pushState({}, "", "extension");
      parentCallback("extension");
    }
    if (type === "smstemplate") {
      window.history.pushState({}, "", "smstemplate");
      parentCallback("smstemplate");
    }
    if (type === "smsconfig") {
      window.history.pushState({}, "", "smsconfig");
      parentCallback("smsconfig");
    }
    if (type === "callstatus") {
      window.history.pushState({}, "", "callstatus");
      parentCallback("callstatus");
    }
  };

  const isAdmin = dataLogoFooter.role === 'ADMIN';
  const isStaff = dataLogoFooter.is_staff;

  useEffect(() => {
    setTimeout(() => setLoading(false), 500);
    dispatch(getUserStart());
    // eslint-disable-next-line
  }, []);

  const [state, setState] = useState("general"); // change state to store string values and default we will store tab1
  return (
    <>
      <Loading loading={isLoading} />
      <div className="screen">
        

        <div className="tabs-wrapper">
          <ul className="tabs-settings">
            <li
              id="general"
              onClick={() => setState("general")}
              className={`icon-box ${state === "general" ? "active" : ""}`}
            >
              <SettingsIcon style={{'width':'0.8em','height':'0.8em'}}/>
              <span>Cấu hình chung</span>
            </li>
            <li
              id="company"
              onClick={() => setState("company")}
              className={`icon-box ${state === "company" ? "active" : ""}`}
            >
              <CorporateFare />
              <span>Công ty</span>
            </li>
            <li id="sms" onClick={() => setState("sms")}
              className={`icon-box ${state === "sms" ? "active" : ""}`}
            >
              <img src={sms_icon} alt="img" />
              <span>SMS</span>
            </li>
            <li id="callcenter" onClick={() => setState("callcenter")}
              className={`icon-box ${state === "callcenter" ? "active" : ""}`}
            >
              <PermPhoneMsgOutlinedIcon />
              <span>Tổng Đài</span>
            </li>
          </ul>
          <div className="tab-content">
            <div
              id="general"
              className={`tab-panel ${state === "general" ? "current" : ""}`}
            >
              {state === "general" ? (
                <>
                  <BoxReport>
                    <div className="setting-paper">
                      <Paper className="user-list">
                       

                        <div>
                          {[lightTheme].map((theme, index) => (
                            <div item xs={6} key={index}>
                              <ThemeProvider theme={theme}>
                                <Box
                                  sx={{
                                    p: 2,
                                    bgcolor: "background.default",
                                    display: "grid",
                                    gridTemplateColumns: {
                                      xs: "100",
                                      sm: "50% 50% 50% 50%",
                                      md: "20% 20% 20% 20%",
                                    },
                                    gap: 2,
                                  }}
                                >
                                  <Item
                                    elevation={3}
                                    onClick={() => handle("employee-manager")}
                                  >
                                    <div className="box-content">
                                      <div className="box-icon">
                                        <div className="title-icon">
                                          <SettingsIcon style={{'width':'0.8em','height':'0.8em'}}/>
                                        </div>
                                      </div>

                                      <div className="title-content">
                                        Thông tin chung
                                      </div>
                                    </div>
                                  </Item>

                                  <Item
                                    elevation={3}
                                    onClick={() => handle("departments")}
                                  >
                                    <div className="box-content">
                                      <div className="box-icon">
                                        <div className="title-icon">
                                          <MeetingRoomIcon style={{'width':'0.8em','height':'0.8em'}}/>
                                        </div>
                                      </div>

                                      <div className="title-content">
                                        Phòng ban
                                      </div>
                                    </div>
                                  </Item>

                                  <Item elevation={3}>
                                    <div
                                      className="box-content"
                                      onClick={() => handle("staff")}
                                    >
                                      <div className="box-icon">
                                        <div className="title-icon">
                                          <PersonOutlineIcon style={{'width':'0.8em','height':'0.8em'}}/>
                                        </div>
                                      </div>

                                      <div className="title-content">
                                        Quản lý nhân viên
                                      </div>
                                    </div>
                                  </Item>
                                  <Item
                                    elevation={3}
                                    onClick={() => handle("ware-house")}
                                  >
                                    <div className="box-content">
                                      <div className="box-icon">
                                        <div className="title-icon">
                                          <HomeIcon />
                                        </div>
                                      </div>
                                      <div className="title-content">
                                        Sản phẩm
                                      </div>
                                    </div>
                                  </Item>
                                  <Item
                                    elevation={3}
                                    onClick={() => handle("extension")}
                                  >
                                    <div className="box-content">
                                      <div className="box-icon">
                                        <div className="title-icon">
                                          <CorporateFare style={{'width':'0.8em','height':'0.8em'}}/>
                                        </div>
                                      </div>
                                      <div className="title-content">
                                        Extension
                                      </div>
                                    </div>
                                  </Item>
                                  <Item
                                    elevation={3}
                                    onClick={() => handle("callstatus")}
                                  >
                                    <div className="box-content">
                                      <div className="box-icon">
                                        <div className="title-icon">
                                          <PermPhoneMsgOutlinedIcon style={{'width':'0.8em','height':'0.8em'}}/>
                                        </div>
                                      </div>
                                      <div className="title-content">
                                        Cuộc gọi
                                      </div>
                                    </div>
                                  </Item>
                                </Box>
                              </ThemeProvider>
                            </div>
                          ))}
                        </div>
                      </Paper>
                    </div>
                  </BoxReport>
                </>
              ) : null}
            </div>
            <div
              id="company"
              className={`tab-panel ${state === "company" ? "current" : ""}`}
            >
              {state === "company" ? (
                <>
                  {(isAdmin || isStaff) && (
                   
                    <BoxReport>
                      <Paper className="user-list">
                      <div>
                            {[lightTheme].map((theme, index) => (
                              <div item xs={6} key={index}>
                                <ThemeProvider theme={theme}>
                                  <Box
                                    sx={{
                                      p: 2,
                                      bgcolor: "background.default",
                                      display: "grid",
                                      gridTemplateColumns: {
                                        xs: "100",
                                        sm: "50% 50% 50% 50%",
                                        md: "20% 20% 20% 20%",
                                      },

                                      gap: 2,
                                    }}
                                  >
                                    <Item
                                      elevation={3}
                                      onClick={() => handle("company")}
                                    >
                                      <div className="box-content">
                                        <div className="box-icon">
                                          <div className="title-icon">
                                            <CorporateFare style={{'width':'0.8em','height':'0.8em'}}/>
                                          </div>
                                        </div>
                                        <div className="title-content">
                                          Công ty
                                        </div>
                                      </div>
                                    </Item>
                                  </Box>
                                </ThemeProvider>
                              </div>
                            ))}
                          </div>
                      </Paper>
                      </BoxReport>
                      
                    
                  )}
                </>
              ) : null}
            </div>
            <div
              id="sms"
              className={`tab-panel ${state === "sms" ? "current" : ""}`}
            >
              {state === "sms" ? (
                <>
                  {(isAdmin || isStaff) ? (
                    <BoxReport>
                      <Paper className="user-list">
                        

                        <div>
                          {[lightTheme].map((theme, index) => (
                            <div item xs={6} key={index}>
                              <ThemeProvider theme={theme}>
                                <Box
                                  sx={{
                                    p: 2,
                                    bgcolor: "background.default",
                                    display: "grid",
                                    gridTemplateColumns: {
                                      xs: "100",
                                      sm: "50% 50% 50% 50%",
                                      md: "20% 20% 20% 20%",
                                    },
                                    gap: 2,
                                  }}
                                >
                                  <Item
                                    elevation={3}
                                    onClick={() => handle("smsconfig")}
                                  >
                                    <div className="box-content">
                                      <div className="box-icon">
                                        <div className="title-icon">
                                          <SettingsIcon style={{'width':'0.8em','height':'0.8em'}}/>
                                        </div>
                                      </div>

                                      <div className="title-content">
                                        Cấu hình SMS
                                      </div>
                                    </div>
                                  </Item>
                                  <Item
                                    elevation={3}
                                    onClick={() => handle("smstemplate")}
                                  >
                                    <div className="box-content">
                                      <div className="box-icon">
                                        <div className="title-icon">
                                          <PostAddOutlinedIcon style={{'width':'0.8em','height':'0.8em'}}/>
                                        </div>
                                      </div>

                                      <div className="title-content">
                                        Mẫu SMS
                                      </div>
                                    </div>
                                  </Item>
                                </Box>
                              </ThemeProvider>
                            </div>
                          ))}
                        </div>
                      </Paper>
                    </BoxReport>
                  ) : null}
                </>
              ) : null}
            </div>
            <div
              id="callcenter"
              className={`tab-panel ${state === "callcenter" ? "current" : ""}`}
            >
              {state === "callcenter" ? (
                <>
                  {(isAdmin || isStaff) ? (
                    <>
                    <BoxReport>
                      <Paper className="user-list">
                      <div>
                            {[lightTheme].map((theme, index) => (
                              <div item xs={6} key={index}>
                                <ThemeProvider theme={theme}>
                                  <Box
                                    sx={{
                                      p: 2,
                                      bgcolor: "background.default",
                                      display: "grid",
                                      gridTemplateColumns: {
                                        xs: "100",
                                        sm: "50% 50% 50% 50%",
                                        md: "20% 20% 20% 20%",
                                      },

                                      gap: 2,
                                    }}
                                  >
                                    <Item
                                      elevation={3}
                                      onClick={() => handle("handle-open-company")}
                                    >
                                      <div className="box-content">
                                        <div className="box-icon">
                                          <div className="title-icon">
                                            <SettingsIcon style={{'width':'0.8em','height':'0.8em'}}/>
                                          </div>
                                        </div>
                                        <div className="title-content">
                                        Thông tin tổng đài
                                        </div>
                                      </div>
                                    </Item>
                                  </Box>
                                </ThemeProvider>
                              </div>
                            ))}
                          </div>
                      </Paper>
                      </BoxReport>
                      
                    </>
                  ) : null}
                </>
              ) : null}
            </div>
          </div>
        </div>

        <Dialog
          fullWidth={true}
          maxWidth={"sm"}
          open={userDetailOpen}
          onClose={handleCloseUserDetailDialog}
          style={{ overflow: "unset" }}
          classes={{
            paper: classes.customModal,
          }}
        >
          <DialogTitle className={`${classes.customModalTitle}`}>
            Cập nhật thông tin doanh nghiệp
            <IconButton
              onClick={() => setUDO(false)}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: "#fff",
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>

          <DialogContent className={`${classes.customModalContent}`}>
            <EmployeeManager setUDO={setUDO} data={dataLogoFooter} />
          </DialogContent>
        </Dialog>

        <Dialog
          fullWidth={true}
          maxWidth={"sm"}
          open={companyDetailOpen}
          onClose={handleCloseCompanyDetailDialog}
          style={{ overflow: "unset" }}
          classes={{
            paper: classes.customModal,
          }}
        >
          <DialogTitle className={`${classes.customModalTitle}`}>
            Cập nhật thông tin tổng đài
            <IconButton
              onClick={() => setUDO2(false)}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: "#fff",
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>

          <DialogContent className={`${classes.customModalContent}`}>
            <EmployeePbx setUDO={setUDO2} data={dataLogoFooter} />
          </DialogContent>
        </Dialog>
      </div>
    </>
  );
};

export default Configuration;
