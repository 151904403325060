import React, { useState } from "react";

import EmployeePbx from "../Configuration/components/EmployeePbx";


import { Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import Paper from "@mui/material/Paper";
import PermPhoneMsgOutlinedIcon from "@mui/icons-material/PermPhoneMsgOutlined";
import SettingsIcon from "@mui/icons-material/Settings";
import CloseIcon from "@mui/icons-material/Close";

import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
import { BoxReport } from "./styled";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  customModalContent: {
    paddingLeft: "15px",
    paddingRight: "15px",
  },
  customModal: {
    width: "calc(100% - 20px)",
    marginLeft: "10px",
    marginRight: "10px",
  },
  customModalTitle: {
    padding: "10px 15px",
    color: "#fff",
    background: process.env.NODE_ENV === 'development' ? "#5a8dee" : process.env.REACT_APP_PRODUCT_BASE_COLOR,
  },
}));

const CallCenter = ({ parentCallback, dataLogoFooter }) => {
  const classes = useStyles();
  const [companyDetailOpen, setUDO] = useState(false);
  const [selected, setSelected] = useState(0);
  

  const handleCloseCompanyDetailDialog = () => {
    setUDO(!companyDetailOpen);
  };



  const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    textAlign: "center",
    color: theme.palette.text.secondary,
    height: 60,
    lineHeight: "60px",
    boxShadow: "0 0.5rem 1rem rgb(0 0 0 / 5%)",
  }));

  const lightTheme = createTheme({ palette: { mode: "light" } });

  const handle = (type) => {
    if (type === "handle-open-company") {
      setUDO(true);
    }
    if (type === "report-cdr") {
      window.history.pushState({}, "", "report-cdr");
      parentCallback("report-cdr");
    }
  };

  const navigate = (url) => {
    if (url === "/") {
      window.location.href = "/";
    } else {
      url === "/callcenter" && setSelected(0);
    }
  };



  return (
    <>
      <div className="screen">
        <Breadcrumbs aria-label="breadcrumb" style={{ marginBottom: 15 }}>
          <Link
            underline="hover"
            className="cursor-pointer"
            color="inherit"
            onClick={() => {
              navigate("/");
            }}
          >
            Trang chủ
          </Link>
          <Link
            underline="hover"
            className="cursor-pointer"
            color="inherit"
            onClick={() => {
              navigate("/callcenter");
            }}
          >
            Tổng đài
          </Link>
        </Breadcrumbs>
        <BoxReport>
          <Paper className="user-list">
            <div className="ul-heading-placeholder">
              <span
                className="ul-heading"
                style={{ display: "flex", alignItems: "center" }}
              >
                <PermPhoneMsgOutlinedIcon />
                <span>Tổng Đài</span>
              </span>
            </div>

            <div>
              {[lightTheme].map((theme, index) => (
                <div item xs={6} key={index}>
                  <ThemeProvider theme={theme}>
                    <Box
                      
                      sx={{
                        p: 2,
                        bgcolor: "background.default",
                        display: "grid",
                        gridTemplateColumns: { md: "25% 25% 25% 25%" },
                        gap: 2,
                      }}
                    >
                      <Item
                          elevation={3}
                          onClick={() => handle("handle-open-company")}
                        >
                          <div className="box-content">
                            <div className="box-icon">
                              <div className="title-icon">
                                <SettingsIcon />
                              </div>
                            </div>

                            <div className="title-content">Thông tin tổng đài</div>
                          </div>
                        </Item>
                      <Item elevation={3} onClick={() => handle("report-cdr")}>
                        <div className="box-content">
                          <div className="box-icon">
                            <div className="title-icon">
                              <PermPhoneMsgOutlinedIcon />
                            </div>
                          </div>
                          <div className="title-content">báo cáo CDR</div>
                        </div>
                      </Item>
                    </Box>
                  </ThemeProvider>
                </div>
              ))}
            </div>
          </Paper>
        </BoxReport>


        <Dialog
          fullWidth={true}
          maxWidth={"sm"}
          open={companyDetailOpen}
          onClose={handleCloseCompanyDetailDialog}
          style={{ overflow: "unset" }}
          classes={{
            paper: classes.customModal,
          }}
        >
          <DialogTitle className={`${classes.customModalTitle}`}>
            Cập nhật thông tin tổng đài
            <IconButton
              onClick={() => setUDO(false)}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: "#fff",
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>

          <DialogContent className={`${classes.customModalContent}`}>
            <EmployeePbx setUDO={setUDO} data={dataLogoFooter} />
          </DialogContent>
        </Dialog>
      </div>
    </>
  );
};
export default CallCenter;
