import * as types from "./type"

export const getCustomerStart = (data) => ({
    type: types.GET_CUSTOMER_START,
    payload: data
})

export const getCustomerSuccess = (data) => ({
    type: types.GET_CUSTOMER_SUCCESS,
    payload: data
})

export const getCustomerError = (data) => ({
    type: types.GET_CUSTOMER_ERROR,
    payload: data
})

export const searchCustomer = (search) => ({
    type: types.SEARCH_CUSTOMER,
    payload: search
})