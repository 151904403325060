import React, { useState, useEffect } from "react";
import { DialogContent, DialogTitle, Dialog, TextField } from "@mui/material";

import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import { CustomButton } from "../../components/Common/CustomButton";

import TableImport from "./TableImport";
import { useDispatch, useSelector } from "react-redux";
import axiosInstance from "../../components/axiosApi";
import {
  showMessageSuccess,
  showMessageError,
} from "../../redux/notification/action";
import { ExcelRenderer } from "react-excel-renderer";
import { Upload } from "antd";
import Excel from "exceljs";
import { getSmsbot } from "../../redux/smsbots/action";
import { makeStyles } from "@material-ui/core/styles";
import styled from "styled-components";
import { saveAs } from "file-saver";
export const TitleInput = styled("p")(({ theme }) => ({
  fontSize: 14,
  fontWeight: 500,
  marginBottom: 10,
}));

export const BoxInput = styled("div")(({ theme }) => ({
  marginBottom: "10px",
}));
const useStyles = makeStyles((theme) => ({
  customModalContent:{
    paddingLeft:'15px',
    paddingRight:'15px'
  },
  customModal:{
    width:'calc(100% - 20px)',
    marginLeft:'10px',
    marginRight:'10px'
  },
  customModalTitle:{
    padding:'10px 15px',
    color:'#fff',
    background:process.env.NODE_ENV === 'development' ? "#5a8dee" : process.env.REACT_APP_PRODUCT_BASE_COLOR
  }
}));
const SmsUpload = ({ dataImp, setDataImp, setDialogImp, dialogImp }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [packageName, setPackageName] = useState("");

  const [newFile, setNewFile] = useState();

  const [dataImpTable, setDataImpTable] = useState({
    cols: [],
    rows: [],
    errorMessage: null,
  });

  const fileHandler = (fileList) => {
    setNewFile(fileList);
    let fileObj = fileList;
    if (!fileObj) {
      setDataImp({
        errorMessage: "No file uploaded!",
      });
      return false;
    }
    if (
      !(
        fileObj.type === "application/vnd.ms-excel" ||
        fileObj.type ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      )
    ) {
      setDataImp({
        errorMessage: "Unknown file format. Only Excel files are uploaded!",
      });
      return false;
    }
    //just pass the fileObj as parameter
    ExcelRenderer(fileObj, (err, resp) => {
      if (err) {
        //console.log(err);
      } else {
        let newRows = [];
        resp.rows.slice(1).map((row, index) => {
          //console.log(row);
          if (row && row !== "undefined") {
            newRows.push({
              key: index,
              name: row[0],
              phone: row[1],
              email: row[2],
              address: row[3],
              source_lead: row[4],
              info: row[5],
            });
          }
        });
        if (newRows.length === 0) {
          setDataImp({
            errorMessage: "No data found in file!",
          });
          return false;
        } else {
          setDataImp({
            cols: resp.cols,
            rows: newRows,
            errorMessage: null,
          });
        }
      }
    });
    return false;
  };

  const handleCloseDialogImp = () => {
    setDialogImp(!dialogImp);
    setDataImp({ rows: [] });
  };

  // download file sample

  const handleDownloadSample = async () => {
    try {
      const res = await axiosInstance.get("/temp-xls/?code=SMS");
      if (res.status === 200) {
        let url = res.data.data[0].url;

        console.log(url);
        saveAs(url, `SMS-Sample.xlsx`);
      }
    } catch (error) {
      dispatch(showMessageError("Lỗi server"));
    }
  };

  const handleSubmit = async () => {
    let formData = new FormData();
    formData.append("data_file", newFile);
    formData.append("package", packageName);
    try {
      const response = await axiosInstance.post(`/sms/data-upload?`, formData);
      if (response.status === 200) {
        dispatch(showMessageSuccess("Nhập data thành công."));
        dispatch(getSmsbot());
        setDialogImp(false);
      }
    } catch (error) {
      dispatch(showMessageError("Tải lên thất bại"));
    }
  };
  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth={"md"}
        open={dialogImp}
        onClose={handleCloseDialogImp}
        style={{ minHeight: "100%" }}
        classes={{
          paper: classes.customModal,
        }}
      >
        <DialogTitle className={`${classes.customModalTitle}`}>
          Upload Dữ liệu
          <IconButton
            onClick={handleCloseDialogImp}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: "#fff",
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent className={`${classes.customModalContent}`}>
          <div className="modal-boxupload">
            <span
              style={{ color: "#5a8dee", cursor: "pointer", fontSize: 14 }}
              onClick={() => handleDownloadSample()}
            >
              <i className="fa-solid fa-cloud-arrow-down"></i> Tải xuống File
              mẫu
            </span>

            <BoxInput>
              <TitleInput>Tên gói</TitleInput>
              <TextField
                size="small"
                name="package"
                fullWidth
                placeholder="Tên gói"
                value={packageName}
                onChange={(event) => {
                  setPackageName(event.target.value);
                }}
              />
            </BoxInput>
            <Upload
              accept=".xlsx, .xls"
              name="file"
              beforeUpload={fileHandler}
              onRemove={() => setDataImpTable({ rows: [] })}
              multiple={false}
              disabled={dataImp.rows.length > 0 ? true : false}
            >
              <div className="form-upload">
                <div className="content-upload">
                  <i className="fas fa-cloud-upload-alt"></i>
                  <p>Drag files here or browse (.xlsx)</p>
                </div>
              </div>
            </Upload>
          </div>
          <div className="box-submitImport">
            <CustomButton
              onClick={handleSubmit}
              size="large"
              type="primary"
              style={{ marginBottom: 16, marginLeft: 10 }}
            >
              Tải lên
            </CustomButton>
          </div>
          {dataImp.rows.length > 0 && <TableImport dataImp={dataImp} />}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default SmsUpload;
