import * as types from './type'

const initialState = {
    numberPhone: null,
    statusCall: false
}

export const callPhoneReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.START_CALL_PHONE:
            return {
                ...state,
                numberPhone: action.payload
            }

        case types.END_CALL:
            return {
                ...state,
                numberPhone: null
            }
        default:
            return state
    }
}