// import { styled } from '@mui/material/styles';
import styled from "styled-components";

export const TitleInput = styled.div`
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 5px;
    ,
`

export const BoxInput = styled.div`
margin-top:10px;
`

export const BoxSelectd = styled.div`
   display: flex;
   justify-content: space-between;
   width: 100%;
`

export const boxContainer = styled.div`
    
`
