import * as types from "./type";

//Edit permission
export const editPermissions = (data) => ({
  type: types.EDIT_PERMISSIONS,
  payload: data,
});
export const editPermissionsSuccess = (data) => ({
  type: types.EDIT_PERMISSIONS_SUCCESS,
  payload: data,
});
export const editPermissionsFail = (data) => ({
  type: types.EDIT_PERMISSIONS_FAIL,
  payload: data,
});

//Edit permission
export const getPermissions = (data) => ({
  type: types.GET_PERMISSIONS,
  payload: data,
});
export const getPermissionsSuccess = (data) => ({
  type: types.GET_PERMISSIONS_SUCCESS,
  payload: data,
});
export const getPermissionsFail = (data) => ({
  type: types.GET_PERMISSIONS_FAIL,
  payload: data,
});
