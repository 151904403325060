import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  showMessageError,
  showMessageSuccess,
} from "../../redux/notification/action";
import axiosInstance from "../../components/axiosApi";
import ZaloTableFollow from "./ZaloTableFollow";
const ZaloFollow = () => {
  const [dataOa, setDataOa] = useState([]);
  const [selected, setSelected] = React.useState([]);
  const dispatch = useDispatch();




  useEffect(() => {
    const getZaloOaList = async () => {
      try {
        const res = await axiosInstance.get("/zalo/");
        if (res.status === 200) {
          setDataOa(res.data);
        }
        if(res.status === -224){
          dispatch(showMessageError('tài khoản zaloOA chưa nâng cấp'));
        }
      } catch (error) {
        dispatch(showMessageError("Lỗi server"));
      }
    };
    getZaloOaList();
  }, []);

  return (
    <>
      
      <ZaloTableFollow
        data={dataOa}
        selected={selected}
        setSelected={setSelected}
      />
    </>
  );
};

export default ZaloFollow;
