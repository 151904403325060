import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { CustomButton } from "../../../../components/Common/CustomButton";
import CloseIcon from "@mui/icons-material/Close";
import React, { useEffect } from "react";
import Loading from "../../../../components/Common/Loading";
import { Box } from "./styled";
import TableDepartments from "./Table";
import { useDispatch, useSelector } from "react-redux";
import { getDepartments } from "../../../../redux/Departments/action";
import DialogDepartments from "./DialogDepartments";
import AlertMessage from "../../../../components/Common/Alert";
import { getModule } from "../../../../redux/module/action";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  customModalContent: {
    paddingLeft: "15px",
    paddingRight: "15px",
  },
  customModal: {
    width: "calc(100% - 20px)",
    marginLeft: "10px",
    marginRight: "10px",
  },
  customModalTitle: {
    padding: "10px 15px",
    color: "#fff",
    background:process.env.NODE_ENV === 'development' ? "#5a8dee" : process.env.REACT_APP_PRODUCT_BASE_COLOR,
  },
}));
const Departments = () => {
  const classes = useStyles();
  const [userDetailOpen, setUDO] = React.useState(false);
  const handleCloseUserDetailDialog = () => {
    setUDO(!userDetailOpen);
  };

  const dataList = useSelector((state) => state.departmentsRuducer.dataList);
  const dispatch = useDispatch();
  const loading = useSelector((item) => item.departmentsRuducer.loading);
  const creLoad = useSelector((item) => item.departmentsRuducer.creLoad);
  const editLoad = useSelector((item) => item.departmentsRuducer.editLoad);

  const handleAdd = () => {
    setUDO(true);
  };

  const navigate = (url) => {
    if (url === "/") {
      window.location.href = "/";
    } else {
      window.location.href = "/configuration";
    }
  };

  useEffect(() => {
    dispatch(getDepartments());
    dispatch(getModule());
  }, []);

  return (
    <Box>
      <Loading loading={loading} />
      {creLoad && <AlertMessage status="success" />}
      {editLoad && <AlertMessage status="success" />}
      <div className="screen">
      <CustomButton style={{marginBottom:'15px',minWidth:'inherit',pading:'0 6px'}} onClick={() => {
            navigate("/configuration");
          }}><KeyboardArrowLeftIcon /></CustomButton>
        <Paper className="user-list">
          <div className="box-btn-add" style={{padding:'5px'}}>
          <span
            className="ul-heading"
            style={{ display: "flex", flex: "inherit", fontWeight: 500, padding:0 }}
          >
            Quản lý phòng ban
          </span>
            
              <CustomButton
                style={{marginLeft:'auto'}}
                variant="outlined"
                className="search-row-button"
                onClick={() => {
                  handleAdd();
                }}
              >
                Thêm mới phòng ban
              </CustomButton>
            
          </div>
        </Paper>
        <TableDepartments data={dataList} />

        <Dialog
          fullWidth={true}
          maxWidth={"sm"}
          open={userDetailOpen}
          onClose={handleCloseUserDetailDialog}
          style={{ minHeight: "100%" }}
          classes={{
            paper: classes.customModal,
          }}
        >
          <DialogTitle className={`${classes.customModalTitle}`}>
            Thêm phòng ban
            <IconButton
              onClick={() => setUDO(false)}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: "#fff",
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>

          <DialogContent className={`${classes.customModalContent}`}>
            <DialogDepartments setUDO={setUDO} />
          </DialogContent>
        </Dialog>
      </div>
    </Box>
  );
};

export default Departments;
