import { put, takeLatest } from "redux-saga/effects";
import * as types from "./type";
import * as actions from "./action";
import axiosInstance from "../../components/axiosApi";
import { showMessageSuccess } from "../notification/action";

function* editPermissions({ payload }) {
  try {
    const res = yield axiosInstance.post(
      `/permissions/${payload.id}?pages=[${payload.pages}]`
    );
    yield put(actions.editPermissionsSuccess(res.data));
    yield put(showMessageSuccess("Cập nhật quyền truy cập thành công"));
  } catch (error) {
    yield put(actions.editPermissionsFail(error));
  }
}

function* getPermissions({ payload }) {
  try {
    const res = yield axiosInstance.get(`/permissions/${payload}`);
    yield put(actions.getPermissionsSuccess(res.data));
  } catch (error) {
    yield put(actions.getPermissionsFail(error));
  }
}

export function* watchingPermissions() {
  yield takeLatest(types.EDIT_PERMISSIONS, editPermissions);
  yield takeLatest(types.GET_PERMISSIONS, getPermissions);
}
