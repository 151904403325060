import { put, takeLatest } from "redux-saga/effects";
import * as types from './type';
import * as actions from './action';
import axiosInstance from "../../components/axiosApi";
import { showMessageSuccess } from "../notification/action";

function* getTicket(action) {
  try {
    let params = ''
    if(action.payload) {
      const {  offset, limit } = action.payload
      params += `offset=${offset}&limit=${limit}`
    }
    const res = yield axiosInstance.get(`/ticket/?${params}`)
    yield put(actions.getTicketSuccess(res.data))
  } catch (error) {
    yield put(actions.getTicketFail(error))
  }
}


function* createTicket({ payload }) {
  const { phone,name,content,cus_name ,trangthai,assigned_to,priorityname} = payload
  const params = `phone=${phone}&name=${name}&cus_name=${cus_name}&content=${content}&trangthai=${trangthai}&assigned_to=${assigned_to}&priority=${priorityname}`
  try {
    const res = yield axiosInstance.post(`/ticket/?${params}`)
    yield put(actions.createTicketSuccess(res.data))
    yield put(showMessageSuccess("Tạo thành công"))
  } catch (error) {
    yield put(actions.createTicketFail(error))
  }
}

function* editTicket({payload}) {

  const { id, phone,cus_name,assigned_to,trangthai} = payload
  let params = `id=${id}&cus_name=${cus_name}&phone=${phone}&trangthai=${trangthai}&assigned_to=${assigned_to}`
  try {
     
      const res = yield axiosInstance.put(`/ticket/?${params}`);
      yield put(actions.editTicketSuccess(res.data))
      yield put(showMessageSuccess("Cập nhập thành công"))
     
  } catch (error) {
    yield put(actions.editTicketFail(error))
  }
}

// function* deleteTicket({payload}) {
//   try {
      
//       const res = yield axiosInstance.delete(`/sms/camp-script/?id=${payload}`);
//       yield put(actions.deleteTicketSuccess(res.data))
//       yield put(showMessageSuccess("Cập nhập thành công"))
      
//   } catch (error) {
//     yield put(actions.deleteTicketFail(error))
//   }
// }

function* resetMessage() {
  yield put(actions.resetMessageError({}))
}

export function* watchingTicket() {
  yield takeLatest(types.GET_TICKET, getTicket);
  yield takeLatest(types.CREATE_TICKET, createTicket);
  yield takeLatest(types.RESET_MESSAGE, resetMessage);
  yield takeLatest(types.EDIT_TICKET, editTicket);
  //yield takeLatest(types.DELETE_TICKET, deleteTicket);
}
