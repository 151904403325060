import { put, takeLatest } from "redux-saga/effects";
import axiosInstance from "../../components/axiosApi";
import { showMessageError, showMessageSuccess } from "../notification/action";
import { updateOrgError, updateOrgSuccess } from "./action";
import { UPDATE_ORG_START } from "./type";

function* updateUsers({payload}) {
    const { address, name, phone, thumbnail } = payload
    try {
        let params = `phone=${phone}&name=${name}&address=${address}`
        let formData = new FormData()
        if(thumbnail) {
            formData.append("logo", thumbnail)
        }
        const res = yield axiosInstance.put(`/org/${payload.id}?${params}`, formData);
        yield put(showMessageSuccess("Cập nhập thành công"))
        yield put(updateOrgSuccess(res))
    } catch (error) {
        yield put(showMessageError("Cập nhập thất bại"))
        yield put(updateOrgError(error))
    }
}

export function* watchingUpdateUsers() {
    yield takeLatest(UPDATE_ORG_START, updateUsers);
}