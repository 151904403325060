import React, { useEffect, useState } from "react";
import { Paper, Grid, Divider, TextField, Box,Tooltip } from "@mui/material";
import Loading from "../../components/Common/Loading";
import TableReportCall from "./components/TableReportCall";
import AssignTo from "../../components/Common/AssignTo";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { DateRangePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { makeStyles } from "@material-ui/core/styles";
import { BoxInput } from "../Customer/components/DialogCustomer/styled";
import { CustomButton } from "../../components/Common/CustomButton";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { getReportCall } from "../../redux/report/action";

import Excel from "exceljs";
import { saveAs } from "file-saver";
import { ChartColumn } from "./components/Chart";

const useStyles = makeStyles((theme) => ({
  btnIcon: {
    fontSize: 16,
  },
  iconMenuItem: {
    marginRight: 10,
  },
  btnGlobal: {
    minWidth: "inherit",
    width: 36,
    height: 36,
    lineHeight: 36,
    padding: 0,

    marginLeft: 5,
  },
  tableBorder: {
    border: "1px solid #d4d8dd",
    borderRadius: "0.5rem",
  },

  boxPhone: {
    display: "flex",
    borderRadius: "0.3rem",
    padding: "3px 5px",
    color: "#5a8dee",
    alignItems: "center",
    backgroundColor: "#e5edfc",
    cursor: "pointer",
  },
  Pagination: {
    display: "flex",
  },
}));

const ReportCall = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const dataReportTable = useSelector((state) => state.reportReducer.dataCall);
  const loading = useSelector((state) => state.reportReducer.loading);
  const [dataPagination, setDataPagination] = useState({
    offset: 0,
    limit: 10,
  });

  // const [dataReportTable, setDataReport] = useState([]);

  const [value, setValue] = useState([null, null]);

  const initialValueSearch = {
    fromdate: "",
    todate: "",
    assigned_to: "",
  };
  const [search, setSearch] = useState(initialValueSearch);

  const handleSearch = () => {
    const valueSearch = {
      ...search,
      offset: dataPagination.offset,
      limit: dataPagination.limit,
    };
    dispatch(getReportCall(valueSearch));
    if (openSidebar) setOpenSidebar(false);
  };
  const [openSidebar, setOpenSidebar] = useState(false);

  const handleChangeAssign = (event, newValue) => {
    if (newValue !== null) {
      let assignToId = newValue.id;
      let updateSearch = { ...search };
      updateSearch.assigned_to = assignToId;
      setSearch(updateSearch);
    }
  };

  useEffect(() => {
    let data = {
      offset: 0,
      limit: 10,
    };
    dispatch(getReportCall(data));
  }, []);

  const handleClear = () => {
    let data = {
      offset: 0,
      limit: 10,
    };

    setSearch(initialValueSearch);
    setValue([null, null]);
    dispatch(getReportCall(data));
    if (openSidebar) setOpenSidebar(false);
  };


  // export

  const workSheetName = 'Danh sách cuộc gọi';
  const workBookName = 'DanhSachcuocgoi';
  const myInputId = 'myInput';
  const workbook = new Excel.Workbook();
  const columns = [
    { header: 'STT', key: 'stt' },
    { header: 'NHÂN VIÊN', key: 'User' },
    { header: 'TỔNG CUỘC GỌI', key: 'totalCalls' },
    { header: 'TỔNG CUỘC NGHE MÁY', key: 'totalAttended' },
    { header: 'TỔNG THỜI GIAN GỌI', key: 'totalDuration' },
    { header: 'TỔNG THỜI GIAN NGHE MÁY', key: 'totalAttendedDuration' },
    { header: 'TỔNG ĐƠN HÀNG', key: 'total_order' },
    { header: 'TỔNG ĐƠN HÀNG ĐÃ CHỐT', key: 'total_ordered' },
    { header: 'TỔNG TIỀN', key: 'total__sum' },
    { header: 'TỔNG KM', key: 'discount__sum' },
    { header: 'TỶ LỆ CHUYỂN ĐỔI', key: 'converts' },
  ];


  const saveExcel = async () => {
    // const newDataCustomerToExport =
    try {
      const myInput = document.getElementById(myInputId);
      const fileName = myInput.value || workBookName;

      // creating one worksheet in workbook
      const worksheet = workbook.addWorksheet(workSheetName);

      // add worksheet columns
      // each columns contains header and its mapping key from data
      worksheet.columns = columns;

      // updated the font for first row.
      worksheet.getRow(1).font = { bold: true };

      // loop through all of the columns and set the alignment with width.
      worksheet.columns.forEach((column) => {
        column.width = column.header.length + 5;
        column.alignment = { horizontal: "center" };
      });

      // loop through data and add each one to worksheet
      dataReportTable.data &&
      dataReportTable.data.forEach((row, index) => {
        const newData = {
          stt: index,
          User: row?.User,
          totalCalls: row?.call_Detail?.totalCalls,
          totalAttended: row?.call_Detail?.totalAttended,
          totalDuration: row?.call_Detail?.totalDuration,
          totalAttendedDuration: row?.total_order,
          total_order: row?.total_ordered,
          total_ordered: row?.total_money?.total__sum,
          total__sum: row?.total_money?.total__sum,
          discount__sum: row?.total_discount?.discount__sum,
          converts: row?.converts,
        };
        worksheet.addRow(newData);
      });

      // loop through all of the rows and set the outline style.
      worksheet.eachRow({ includeEmpty: false }, (row) => {
        // store each cell to currentCell
        const currentCell = row._cells;

        // loop through currentCell to apply border only for the non-empty cell of excel
        currentCell.forEach((singleCell) => {
          // store the cell address i.e. A1, A2, A3, B1, B2, B3, ...
          const cellAddress = singleCell._address;

          // apply border
          worksheet.getCell(cellAddress).border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" },
          };
        });
      });

      // write the content using writeBuffer
      const buf = await workbook.xlsx.writeBuffer();

      // download the processed file
      saveAs(new Blob([buf]), `${fileName}.xlsx`);
    } catch (error) {
      //console.error("<<<ERRROR>>>", error);
      //console.error("Something Went Wrong", error.message);
    } finally {
      // removing worksheet's instance to create new one
      workbook.removeWorksheet(workSheetName);
    }
  };
  // chart

  const getCallData = () => {
    const data = {
      labels: [],
      totalCalls: [],
      totalAttend:[],
      totalDuration:[],
      totalAttendDuration:[],
    };
    dataReportTable?.data?.map((item) => {
      data.labels.push(item?.User);
      data.totalCalls.push(item?.call_Detail?.totalCalls);
      data.totalAttend.push(item?.call_Detail?.totalAttended);
      data.totalDuration.push(item?.call_Detail?.totalDuration);
      data.totalAttendDuration.push(item?.call_Detail?.totalAttendedDuration);
      return data
    });
    return data;
  };

  const dataCall = {
    labels: getCallData().labels,
    datasets: [
      {
        barPercentage: 0.5,
        barThickness: 20,
        base: 0,
        maxBarThickness: 30,
        // minBarLength: 2,
        data: getCallData().totalCalls,
        backgroundColor: "rgba(90, 141, 238, 0.85)",
        borderColor: "rgba(90, 141, 238, 0.85)",
        borderWidth: 4,
        label: "Tổng cuộc gọi" // <-- pass this 
      },
      {
        barPercentage: 0.5,
        barThickness: 20,
        base: 0,
        maxBarThickness: 30,
        // minBarLength: 2,
        data: getCallData().totalAttend,
        backgroundColor: "rgba(46, 216, 182, 0.85)",
        borderColor: "rgba(46, 216, 182, 0.85)",
        borderWidth: 4,
        label: "Tổng cuộc nghe máy" // <-- pass this 
      },
      {
        barPercentage: 0.5,
        barThickness: 20,
        base: 0,
        maxBarThickness: 30,
        // minBarLength: 2,
        data: getCallData().totalDuration,
        backgroundColor: "rgba(74, 0, 224, 1)",
        borderColor: "rgba(74, 0, 224, 1)",
        borderWidth: 4,
        label: "Tổng thởi gian gọi" // <-- pass this 
      },
      {
        barPercentage: 0.5,
        barThickness: 20,
        base: 0,
        maxBarThickness: 30,
        // minBarLength: 2,
        data: getCallData().totalAttendDuration,
        backgroundColor: "rgba(255, 182, 77, 1)",
        borderColor: "rgba(255, 182, 77, 1)",
        borderWidth: 4,
        label: "Tổng thởi gian nghe máy" // <-- pass this 
      },
    ],
  };

  

  return (
    <div className="screen">
      <Loading loading={loading} />
      <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={12}>
          <Paper className="order-list" style={{marginBottom:15}}>
            <div className="ul-heading-placeholder">
              <input style={{ display: "none" }} id={myInputId} defaultValue={workBookName} />
              <span
                className="ul-heading"
                style={{
                  display: "flex",
                  alignItems: "center",
                  fontWeight: 500,
                }}
              >
                Báo cáo cuộc gọi
              </span>
              <div className="box-right">
              <Tooltip title="Tải xuống">
                <CustomButton
                  variant="contained"
                  className={`${classes.btnGlobal} ${classes.btnBlue}`}
                  onClick={saveExcel}
                >
                  <FileDownloadOutlinedIcon className={`${classes.btnIcon}`} />
                </CustomButton>
              </Tooltip>
                <CustomButton
                  variant="contained"
                  className={`${classes.btnGlobal} ${classes.btnBlue}`}
                  onClick={() => setOpenSidebar(!openSidebar)}
                >
                  <FilterAltIcon
                    style={{ width: "1rem", height: "1rem" }}
                    className={`${classes.btnIcon}`}
                  />
                </CustomButton>
                <div
                  className={`boxsearch-sidebar ${openSidebar ? "active" : ""}`}
                >
                  <span
                    className="ul-heading"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      fontWeight: 500,
                    }}
                  >
                    <ArrowBackIosNewOutlinedIcon
                      style={{
                        color: "#5a8dee",
                        marginRight: 5,
                        cursor: "pointer",
                      }}
                      onClick={() => setOpenSidebar(!openSidebar)}
                    />
                    Tìm kiếm
                  </span>
                  <Divider sx={{ display: { xs: "block", sm: "none" } }} />
                  <Grid
                    container
                    rowSpacing={1}
                    columnSpacing={{ xs: 1, sm: 1.5 }}
                    style={{ padding: "0 14px" }}
                  >
                    <Grid item xs={12}>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DateRangePicker
                          inputFormat="dd/MM/yyyy"
                          startText="Từ ngày"
                          endText="Đến ngày"
                          value={value}
                          onChange={(newValue) => {
                            setValue(newValue);
                            let fromdate = moment(newValue[0]).format(
                              "YYYY-MM-DD"
                            );
                            let todate = moment(newValue[1]).format(
                              "YYYY-MM-DD"
                            );

                            let updateSearch = { ...search };
                            updateSearch.fromdate = fromdate;
                            updateSearch.todate = todate;
                            setSearch(updateSearch);
                          }}
                          renderInput={(startProps, endProps) => (
                            <>
                              <TextField
                                {...startProps}
                                size="small"
                                fullWidth
                                style={{ marginTop: 12 }}
                              />
                              <Box sx={{ mx: 1 }}> - </Box>
                              <TextField
                                {...endProps}
                                size="small"
                                fullWidth
                                style={{ marginTop: 12 }}
                              />
                            </>
                          )}
                        />
                      </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12}>
                      <BoxInput style={{ maxWidth: "100%" }}>
                        <AssignTo
                          value={search.assigned_to}
                          onChange={handleChangeAssign}
                        />
                      </BoxInput>
                    </Grid>
                    <Grid item xs={12}>
                      <div style={{ display: "flex" }}>
                        <CustomButton
                          style={{ width: "40px", height: "40px" }}
                          variant="contained"
                          className="search-row-button"
                          onClick={() => handleSearch()}
                        >
                          Tìm kiếm
                        </CustomButton>
                        <CustomButton
                          style={{
                            width: "40px",
                            height: "40px",
                            marginLeft: "10px",
                          }}
                          variant="contained"
                          className="search-row-button"
                          onClick={() => handleClear()}
                        >
                          Xoá Bộ Lọc
                        </CustomButton>
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </div>
            
          </Paper>
          <Paper className="order-list">
          <TableReportCall
              data={dataReportTable}
              dataPagination={dataPagination}
              setDataPagination={setDataPagination}
            />
          </Paper>

          
        </Grid>
      </Grid>


      <Paper className="order-list" style={{ marginBottom: "20px" }}>
        <span
          className="ul-heading"
          style={{ display: "flex", alignItems: "center", fontWeight: 500 }}
        >
          Biểu đồ phân tích cuộc gọi
        </span>

        

      </Paper>
      
      <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={12} >
        <Paper className="order-list">
        <ChartColumn data={dataCall} />
        </Paper>
        </Grid>

        
        </Grid>
    </div>
  );
};

export default ReportCall;
