import * as types from './type'

const initialState = {
    data: [],
    dataList: [],
    loading: false,
    creLoad: false,
    editLoad: false,
}

export const departmentsRuducer = (state = initialState, action) => {

    switch (action.type) {

        case types.GET_DEPARTMENTS: 
            return {
                ...state,
                ...{ loading: true }
            }
        
        case types.GET_DEPARTMENTS_SUCCESS:
            return {
                ...state,
                ...{
                    dataList: action.payload.data,
                    loading: false
                }
            }
        
        case types.GET_DEPARTMENTS_FAIL:
            {
                return {
                    ...state,
                    ...{ dataList: [], loading: false }
                }
            }

        case types.CREATE_DEPARTMENTS: 
            return {
                ...state,
                creLoad: true,
                ...{ loading: true }
            }
        
        case types.CREATE_DEPARTMENTS_SUCCESS:
            return {
                ...state,
                loading: false,
                creLoad: false,
            }
//edit
        case types.EDIT_DEPARTMENTS: 
            return {
                ...state,
                editLoad: true,
            }
        
        case types.EDIT_DEPARTMENTS_SUCCESS:
            return {
                ...state,
                editLoad: false
            }
        
        default:
            return state
    }
}