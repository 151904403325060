import * as React from 'react'
import IconButton from "@mui/material/IconButton";
import Badge from "@mui/material/Badge";
import ConfirmationNumberRoundedIcon from '@mui/icons-material/ConfirmationNumberRounded';
import useOutsideClick from "../../hook/useOutsideClick";
import { useContext } from 'react';
import { CountContext } from './NotifyContent';
import NotificationTicketList from './NotificationTicketList'



const NotifyTicket = () => {
    const dataContextTicket = useContext(CountContext)
    const notifiRef = React.useRef(null);
    const [openNotifi, setOpenNotifi] = React.useState(false);
    useOutsideClick(notifiRef, () => {
      if (openNotifi) setOpenNotifi(false);
     
    });
  return (
    <div className="box-drp" style={{position:'relative'}} ref={notifiRef}>
    <IconButton
    size="large"
    aria-label="account of current user"
    aria-controls="menu-appbar"
    aria-haspopup="true"
    color="inherit"
    onClick={() => setOpenNotifi(!openNotifi)}
  >
    <Badge badgeContent={dataContextTicket.countNotifyTicket} sx={{
      "& .MuiBadge-badge": {
        color: '#fff',
        backgroundColor: "rgb(244,106,106)"
      }
    }} >
      <ConfirmationNumberRoundedIcon style={{ color:  'rgb(255 208 77)' }} />
    </Badge>
    </IconButton>
      <div className={`menuExpand notifi-wrapper ${openNotifi ? "active" : ""}`}> 
          <NotificationTicketList 
            dataNotify={dataContextTicket.dataNotifyTicket} 

          />
      </div>
    </div>
  )
}

export default NotifyTicket