import { Button, Grid, TextField } from '@mui/material';
import React, { useState } from 'react';
import { BoxInput, TitleInput } from './styled';
import { useFormik } from 'formik';
import * as yup from "yup";
import { useDispatch } from 'react-redux';
import { editDepartments } from '../../../../../redux/Departments/action';
import { getDepartments } from "../../../../../redux/Departments/action";
import { getModule } from "../../../../../redux/module/action";
const DialogEdit = ({ setUDO1, item }) => {

    const dispatch = useDispatch();
    const [idEdit, setIdEdit] = useState(item.id);
    const [nameEdit, setNameEdit] = useState(item.name);

    const validationSchema = yup.object({
        // name: yup.string().required('Bạn chưa nhập tên phòng ban'),
    });

    const handleName = (e) => {
        setNameEdit(e.target.value)
    }


    const formik = useFormik({
        initialValues: {
            name: nameEdit,
            id: idEdit,
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            dispatch(editDepartments(values))
            dispatch(getDepartments());
            dispatch(getModule());
            setUDO1(false)
        },
    });

    return (
        <form onSubmit={formik.handleSubmit}>
            <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={12} sx={{display:'none'}}>
                    <BoxInput>
                        <TitleInput>ID phòng ban *</TitleInput>
                        <TextField
                            disabled
                            id="id"
                            name="id"
                            size="small"
                            fullWidth
                            value={idEdit}
                        />
                    </BoxInput>
                </Grid>
                <Grid item xs={12}>
                    <BoxInput>
                        <TitleInput>Tên phòng ban *</TitleInput>
                        <TextField
                            id="name"
                            name="name"
                            size="small"
                            fullWidth
                            placeholder="Tên phòng ban"
                            value={nameEdit}
                            onChange={(e) => {
                                // formik.values.name;
                                formik.setFieldValue("name", e.currentTarget.value);
                                setNameEdit(e.currentTarget.value)
                            }}
                        />
                    </BoxInput>
                </Grid>
            </Grid>


            <div style={{ display: "flex", justifyContent: "right", marginTop: 10 }}>
                <Button
                    
                    variant="contained"
                    type="submit"
                    onClick={formik.handleSubmit}
                >
                    Sửa
                </Button>
            </div>
        </form>
    );
};

export default DialogEdit;