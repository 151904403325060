import React, { useState } from "react";
import { Paper } from "@mui/material";
import ZaloFollow from "./ZaloFollow";
import ZaloList from "./ZaloList";
import ZaloChatWrapper from "./ZaloChatWrapper";



const ZaloLayout = () => {
  const [state, setState] = useState("zalolist");

  const menuNav = [
    { panel:<ZaloList />, label: "ZaloOA", state: "zalolist" },
    { panel:<ZaloFollow />, label: "Zalo Follower", state: "zalofollower" },
    { panel:<ZaloChatWrapper />, label: "Zalo Chat", state: "zalochat" },
  ];
  
  function toggleExpand()  {
    let main = document.querySelector(".zalo-wrapper");
    
    main.classList.toggle("expand");
   
  }

  
  return (
    <div className="screen">
      <Paper className="user-list zalo-wrapper">
      <span className="circle-expand" onClick={()=>toggleExpand()}>
        <i className="fas fa-expand"></i>
      </span>
        <ul className="sidebar-nav">
          {menuNav.map((item, index) => (
            <li
              className={`menuItem ${state === `${item.state}` ? "active" : ""}`}
              onClick={() => setState(`${item.state}`)}
              key={index}
            >
              {item.label}
            </li>
          ))}
        </ul>
        <div className="tab-content">
        {menuNav.map((item, index) => (
          <div
            id={`${item.state}`}
            className={`tab-panel ${state === `${item.state}` ? "current" : ""}`}
            key={index}
          >
            {state === item.state ? item.panel : null }
          </div>

          ))}
        </div>
      </Paper>
    </div>
  );
};

export default ZaloLayout;
