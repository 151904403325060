import {
    Checkbox,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    styled,
    Table,
    TableBody,
    TableCell,
    tableCellClasses,
    TableContainer,
    TableHead,
    TableRow
} from '@mui/material';
import EditIcon from '@mui/icons-material/EditOutlined';
import React from 'react';
import CloseIcon from "@mui/icons-material/Close";
import DialogEditProduct from '../DialogEditProduct';
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    customModalContent:{
      paddingLeft:'15px',
      paddingRight:'15px'
    },
    customModal:{
      width:'calc(100% - 20px)',
      marginLeft:'10px',
      marginRight:'10px'
    },
    customModalTitle:{
      padding:'10px 15px',
      color:'#fff',
      background:process.env.NODE_ENV === 'development' ? "#5a8dee" :process.env.REACT_APP_PRODUCT_BASE_COLOR
    }
  }));

const TableWareHouse = ({products}) => {
    const classes = useStyles();
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            textTransform:"capitalize",
            color:"#707275",
            backgroundColor: 'rgba(38,60,85,.1)'
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
        },
    }));

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        // hide last border
        "&:last-child td, &:last-child th": {
            border: 0,
        },
    }));

    const [userDetailOpen, setUDO] = React.useState(false);
    const [dataEdit, setDataEdit] = React.useState();

    const handleCloseUserDetailDialog = () => {
        setUDO(!userDetailOpen);
    };

    const handleEditProduct = (item) => {
        setUDO(true);
        setDataEdit(item)
    }

    return (
        <TableContainer style={{ width: "100%", overflowX: "auto" ,borderRadius:'0.5rem'}}>
            <Table sx={{ minWidth: 700 }} size={"small"}>
                <TableHead>
                    <TableRow>
                        <StyledTableCell align="left">STT</StyledTableCell>
                        <StyledTableCell align="left" style={{ minWidth: 170 }}>
                            Sản phẩm
                        </StyledTableCell>
                        <StyledTableCell align="left" style={{ minWidth: 100 }}>
                            Mô tả sản phẩm
                        </StyledTableCell>
                        <StyledTableCell align="left" style={{ minWidth: 125 }}>
                            Giá bán
                        </StyledTableCell>
                        <StyledTableCell align="left" style={{ minWidth: 20 }}>
                            Định kỳ
                        </StyledTableCell>
                        <StyledTableCell align="left" style={{ minWidth: 30 }}></StyledTableCell>
                    </TableRow>
                </TableHead>

                <TableBody>
                    {
                        products.length > 0 ? products?.map((row, index) => {
                            return (
                                <StyledTableRow
                                    hover={true}
                                    // key={row.name}
                                >
                                
                                    <StyledTableCell align="left">{index + 1}</StyledTableCell>
                                    <StyledTableCell align="left">{row.title}</StyledTableCell>
                                    <StyledTableCell align="left">{row.description}</StyledTableCell>
                                    <StyledTableCell align="left">{row.price}</StyledTableCell>
                                    <StyledTableCell align="left">
                                        <Checkbox 
                                            id="recurring_fee" 
                                            name="recurring_fee"
                                            // value={row.recurring_fee}
                                            // onChange={formik.handleChange}
                                            disabled
                                            color="secondary"
                                            checked={row.recurring_fee}
                                        />
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                        <EditIcon onClick={() => handleEditProduct(row)} sx={{ color: "rgb(90, 141, 238)",width:'0.8em',height:'0.8em' }} />
                                    </StyledTableCell>
                                </StyledTableRow>
                            )}) : <h3 style={{marginLeft:20}}>Không có dữ liệu</h3> }
                </TableBody>
            </Table>

            <Dialog
                    fullWidth={true}
                    maxWidth={"sm"}
                    open={userDetailOpen}
                    onClose={handleCloseUserDetailDialog}
                    style={{ minHeight: "100%" }}
                    classes={{
                        paper: classes.customModal
                    }}
                >
                    <DialogTitle className={`${classes.customModalTitle}`}>
                        Sửa sản phẩm
                        <IconButton
                            onClick={() => setUDO(false)}
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                                color: '#fff',
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>

                    <DialogContent className={`${classes.customModalContent}`}>
                        <DialogEditProduct setUDO={setUDO} dataEdit={dataEdit}/>
                    </DialogContent>
            </Dialog>
        </TableContainer>
    );
};

export default TableWareHouse;