import { put, takeLatest } from "redux-saga/effects";
import * as types from './type';
import * as actions from './action';
import axiosInstance from "../../components/axiosApi";
import { showMessageSuccess } from "../notification/action";

function* getStatusLeadId({payload}) {
    try {
        const res = yield axiosInstance.get(`/leads/statussub/${payload}/`)
        yield put(actions.getStatusLeadIdSuccess(res.data))
    } catch (error) {
        yield put(actions.getStatusLeadIdFail(error))
    }
}
function* createStatusLeadId({payload}) {
    try {
        const res = yield axiosInstance.post(`/leads/statussub/${payload.parent_code}/`, payload)
        yield put(actions.createStatusLeadIdSuccess(res.data))
        yield put(showMessageSuccess("Thêm thành công"))
    } catch (error) {
        yield put(actions.createStatusLeadIdFail(error))
    }
}

export function* watchingStatusLeadId() {
    yield takeLatest(types.GET_STATUS_LEAD_ID, getStatusLeadId);
    yield takeLatest(types.CREATE_STATUS_LEAD_ID, createStatusLeadId);
}