import { put, takeLatest } from "redux-saga/effects";
import * as types from './type';
import * as actions from './action';
import axiosInstance from "../../components/axiosApi";

function* getStatusLead() {

    try {
        const res = yield axiosInstance.get(`/leads/status/`)
        yield put(actions.getStatusLeadSuccess(res.data))
    } catch (error) {
        yield put(actions.getStatusLeadFail(error))
    }
}

export function* watchingStatusLead() {
    yield takeLatest(types.GET_STATUS_LEAD, getStatusLead);
}