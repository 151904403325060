import { put, takeLatest } from "redux-saga/effects";
import * as types from './type';
import * as actions from './action';
import axiosInstance from "../../components/axiosApi";
import { showMessageSuccess } from "../notification/action";

function* getSmstemplate(action) {
  try {
    let params = ''
    if(action.payload) {
      const {  offset, limit } = action.payload
      params += `offset=${offset}&limit=${limit}`
    }
    const res = yield axiosInstance.get(`/sms/template?${params}`)
    yield put(actions.getSmstemplateSuccess(res.data))
  } catch (error) {
    yield put(actions.getSmstemplateFail(error))
  }
}


function* createSmstemplate({ payload }) {
  try {
    const res = yield axiosInstance.post(`/sms/template`, payload)
    yield put(actions.createSmstemplateSuccess(res.data))
  } catch (error) {
    yield put(actions.createSmstemplateFail(error))
  }
}

function* editSmstemplate({payload}) {
    let params = ''
    const { id,name,msg,activate,telco} = payload
    params += id !== "" && id !== undefined ? `id=${id}&`: ""
    params += name !== "" && name !== undefined ? `name=${name}&`: ""
    params += telco !== "" && telco !== undefined ? `telco=${name}&`: ""
    params += msg !== "" && msg !== undefined ? `msg_tem=${msg}&`: ""
    params += activate !== "" && activate !== undefined ? `activate=${activate}&`: ""
    try {
        
        const res = yield axiosInstance.put(`/sms/template?${params}`);
        yield put(actions.editSmstemplateSuccess(res.data))
        yield put(showMessageSuccess("Cập nhập thành công"))
        
    } catch (error) {
      yield put(actions.editSmstemplateFail(error))
    }
}

function* deleteSmstemplate({payload}) {
  try {
      
      const res = yield axiosInstance.delete(`/sms/template?id=${payload}`);
      yield put(actions.deleteSmstemplateSuccess(res.data))
      yield put(showMessageSuccess("Cập nhập thành công"))
      
  } catch (error) {
    yield put(actions.deleteSmstemplateFail(error))
  }
}

function* resetMessage() {
  yield put(actions.resetMessageError({}))
}

export function* watchingSmstemplate() {
  yield takeLatest(types.GET_SMSTEMPLATE, getSmstemplate);
  yield takeLatest(types.CREATE_SMSTEMPLATE, createSmstemplate);
  yield takeLatest(types.RESET_MESSAGE, resetMessage);
  yield takeLatest(types.EDIT_SMSTEMPLATE, editSmstemplate);
  yield takeLatest(types.DELETE_SMSTEMPLATE, deleteSmstemplate);
}
