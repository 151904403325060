import { TextareaAutosize } from "@mui/material";
import React, { useState, useEffect, useCallback, useRef } from "react";
import axiosInstance from "../../components/axiosApi";
import { useDispatch, useSelector } from "react-redux";
import { showMessageError } from "../../redux/notification/action";
import SendRoundedIcon from "@mui/icons-material/SendRounded";
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";
import { CustomButton } from "../../components/Common/CustomButton";
import fb_logo from "../../assets/images/fb_avatar.jpg";

import moment from "moment";
import Spinner from "../../components/Common/Spinner/Spinner";



const FacebookConver = ({
  item,
  idFanpage,
  idChat,
  selectedPart,
  username,
  openSidebar,
  setOpenSidebar,
  loading,
  setLoading,
  
}) => {

  const messageEl = useRef(null);
  const messagesEndRef = useRef(null);

  const [conversionList, setConversionList] = useState([]);
  const [msg, setMsg] = useState("");
  const dispatch = useDispatch();


  const loadBottomFirst = () => {
    messagesEndRef.current?.scrollIntoView({
      block: "end",
      behavior: "smooth",
    });
  };

  const scrollToBottom = () => {
    if (messageEl) {
      messageEl.current?.addEventListener('DOMNodeInserted', event => {
        const { currentTarget: target } = event;
        if(target.scrollHeight !== undefined){
          target.scroll({ top: target.scrollHeight, behavior: 'smooth' });
        }
        
      });
    }
  }
  useEffect(() => {
    if(conversionList && conversionList.data && conversionList.data.length > 0)
    scrollToBottom()
  }, [conversionList])


  const getConvertsionList = useCallback(async () => {
    try {
      
      const res = await axiosInstance.get(
        `/facebook/get_msg/${idFanpage}/?id_msg=${item}`
      );
      console.log(res)
      if (res.status === 200) {
        setConversionList(res.data);
        setLoading(false);
        loadBottomFirst()
      }
    } catch (error) {
      //dispatch(showMessageError("Lỗi server"));
    }
    // eslint-disable-next-line
  }, [idFanpage, item]);


  
  useEffect(() => {
    getConvertsionList();
    
    // dataContext.onLocalZalo(item.userID)
    // eslint-disable-next-line
  }, [idFanpage, item]);

  // useEffect(() => {
  //   setTimeout(() => {
  //     setLoading(false);
  //     scrollToBottom();
  //   }, 500);
  //   // eslint-disable-next-line
  // }, [conversionList]);

  const handleSendMsg = useCallback(async () => {
    // setLoading(true);
    let params = "";
  
    params += `recipient=${selectedPart}&message=${msg}`;
  
    try {
      const res = await axiosInstance.get(`/facebook/send_msg/${idFanpage}/?${params}`);
      
      if (res.status === 200) {
        setMsg("");
        setTimeout(() => {
          setLoading(false);
          getConvertsionList();

          //hien tai api tra ve lau nen set tam 7s
        }, 500);
      
      }
    } catch (error) {
      dispatch(showMessageError("Message send unsuccess"));
    }

  }, [msg]);

  const something = (event) => {
    if (event.keyCode === 13) {
      handleSendMsg();
    }
  };

  const dataLogoFooter = useSelector((item) => item.dataLogoFooterRuducer.data);
  const orgCode = dataLogoFooter?.org?.code;
  const webSocket = useRef(null);
  const [messages, setMessages] = useState([]);
    useEffect(() => {
        webSocket.current = new WebSocket(`wss://graph.vntel.vn/ws/socket-server/?org_Name=${orgCode}`);
        webSocket.current.onmessage = (message) => {
          setMessages(prev => [...prev,JSON.parse(message.data)]);
          getConvertsionList();
      };
      return () => webSocket.current.close();
    }, [getConvertsionList]);

  return (
    <div className={`wrapper-chat  ${openSidebar ? "active" : ""}`}>
      <div className="chat-box-top">
        <div className="chat-user">
          <ArrowBackIosNewOutlinedIcon
            style={{
              cursor: "pointer",
              position: "absolute",
              left: "10px",
              top: "20px",
              width: "0.8em",
              height: "0.8em",
            }}
            sx={{
              display: { xs: "block !important", sm: "none !important" },
            }}
            onClick={() => setOpenSidebar(!openSidebar)}
          />
          <div className="photo">
            <img src={fb_logo} alt="" />
          </div>
          <div className="info">
            <div className="username">{username}</div>
            <div className="status online"></div>
          </div>
          
        </div>
      </div>

      <div className="chat-msg">
        {loading ? (
          <Spinner />
        ) : (
          <>
            <div className="msg-content" ref={messageEl}>
              {conversionList?.data?.length > 0 &&
                conversionList?.data
                  .sort((a, b) => (a.created_time > b.created_time ? 1 : -1))
                  .map((msg, index) => {
                    return (
                      <div
                        key={index}
                        className={`msg-box-holder ${
                          msg?.from?.id === idChat ? "text-right" : ""
                        }`}
                      >
                        <div className="msg-seller">
                          <div className="photo">
                            {msg?.from?.id !== idChat ? (
                              <img
                                alt="zalo-img"
                                src={
                                  msg?.from_avatar
                                    ? `${msg?.from_avatar}`
                                    : fb_logo
                                }
                              />
                            ) : null}
                          </div>
                          <div className="msg-box">
                                {msg?.message}
                            <div className="msg-time">
                              {moment(msg?.created_time).format(" hh:mm A")}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              <div ref={messagesEndRef} />
            </div>
           <div className="chat-bottom">
              <div className="chat-input-holder">
                <TextareaAutosize
                  minRows={1}
                  id="msg"
                  name="msg"
                  size="small"
                  placeholder="Nhập nội dung tin nhắn"
                  value={msg}
                  onChange={(e) => setMsg(e.target.value)}
                  onKeyDown={(e) => something(e)}
                  variant="outlined"
                  style={{ border: "none !important" }}
                />
                <CustomButton
                  onClick={() => handleSendMsg()}
                  style={{
                    width: "36px",
                    height: "36px",
                    minWidth: "inherit",
                    position: "absolute",
                    right: "15px",
                    top: "15px",
                  }}
                >
                  <SendRoundedIcon
                    style={{
                      width: "0.8em",
                      height: "0.8em",
                      marginLeft: "5px",
                    }}
                  ></SendRoundedIcon>
                </CustomButton>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default FacebookConver;
