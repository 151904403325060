import * as types from './type'

const initialState = {
    data: [],
    valueInitial: '',
    loadingDiaChi: false,
}

export const listAddressFilterRuducer = (state = initialState, action) => {

    switch (action.type) {

        case types.GET_LIST_ADDRESS: 
            return {
                ...state,
                ...{ loadingDiaChi: true }
            }
        
        case types.GET_LIST_ADDRESS_SUCCESS:
            const dataQuanHuyen = action.payload.thanhpho.filter((value) => value.name === action.payload.district)
            return {
                ...state,
                ...{
                    data: action.payload.thanhpho,
                    valueInitial: dataQuanHuyen[0] !== undefined ? dataQuanHuyen[0] : action.payload.thanhpho[0],
                    loadingDiaChi: false
                }
            }
        
        case types.GET_LIST_ADDRESS_FAIL:
            {
                return {
                    ...state,
                    ...{ data: [], loadingDiaChi: false }
                }
            }
        default:
            return state
    }
}