import React, { useEffect, useState } from "react";
import {
  Box,
  Table,
  TableBody,
  TableContainer,
  IconButton,
  TablePagination,
  TableRow,
  Breadcrumbs,
  Link,
  Typography,
  Paper,
  Tooltip
} from "@mui/material";
import SendRoundedIcon from "@mui/icons-material/SendRounded";
import MuiTableCell from "@material-ui/core/TableCell";
import MuiTableHead from "@mui/material/TableHead";
import { CustomButton } from "../../components/Common/CustomButton";
import { makeStyles } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import { IconPhone, WrapCellPhone } from "./styled";
import { useDispatch, useSelector } from "react-redux";
import axiosInstance from "../../components/axiosApi";
import { getCustomerStart } from "../../redux/customers/action";
import { getSmstemplate } from "../../redux/smstemplate/action";
import { replaceMidd } from "../../helpers";
import SmsSendForm from "./SmsSendForm";
const useStyles = makeStyles((theme) => ({
  tableBorder: {
    border: "1px solid #d4d8dd",
    borderRadius: "0.5rem",
  },
  btnGlobal: {
    minWidth: "inherit",
    width: 28,
    padding: "0",
    height: "28px",
    lineHeight: "28px",

    marginLeft: 5,
    boxShadow: "none",
    fontSize: "12px",
    fontWeight: "500",
  },
  boxPhone: {
    display: "flex",
    borderRadius: "0.3rem",
    padding: "3px 5px",
    color: "#5a8dee",
    alignItems: "center",
    backgroundColor: "#e5edfc",
    cursor: "pointer",
  },
  btnBlue: { backgroundColor: "#556ee6" },
  // btnGreen:{
  //   backgroundColor: '#34c38f',
  // },
  btnYellow: {
    backgroundColor: "#f1b44c",
  },
  btnRed: {
    backgroundColor: "#f46a6a",
  },
}));

const TableCell = withStyles({
  root: {
    borderBottom: "1px solid rgb(235, 237, 242)",
    padding: "5px 10px",
    lineHeight: "18px",
  },
})(MuiTableCell);

const TableHead = withStyles((theme) => ({
  root: {
    backgroundColor: "rgba(38,60,85,.1)",
  },
}))(MuiTableHead);

const TableHeaderCell = withStyles((theme) => ({
  root: {
    color: "#516377",
    fontWeight: 700,
    padding: "10px",
    fontSize: ".75rem",
    textTransform: "uppercase",
    letterSpacing: "0.8px",
    lineHeight: "18px",
  },
}))(TableCell);

const SmsSend = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [data, setData] = useState(null);
  const [openSmsSend, setOpenSmsSend] = useState(false);
  const [listSelected, setListSelected] = useState([]);
  const [item, setItem] = useState();
  const dataCustomers = useSelector((state) => state.getCustomersReducer);

  


  const handleChangePage = (event, newPage) => {
    let data = {
      offset: newPage * rowsPerPage,
      limit: rowsPerPage,
    };
    dispatch(getCustomerStart(data));
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    let data = {
      offset: page,
      limit: event.target.value !== "-1" ? +event.target.value : 9999,
    };
    dispatch(getCustomerStart(data));
    setRowsPerPage(+event.target.value);
    setPage(0);
  };


  useEffect(() => {
    let data = {
      offset: 0,
      limit: 10,
    };
    dispatch(getCustomerStart(data));
    dispatch(getSmstemplate());
  }, []);


  useEffect(() => {
    if (dataCustomers?.dataCustomers?.open_leads?.open_leads) {
        setData([...dataCustomers?.dataCustomers?.open_leads?.open_leads]);
    }
  }, [dataCustomers?.dataCustomers?.open_leads]);


  const handleSend = (item) => {
    setItem(item);
    setOpenSmsSend(true);
  };


  const handleCloseSmsSend = () => {
    setOpenSmsSend(false);
  };

  const navigate = (url) => {
    if (url === "/") {
      window.location.href = "/";
    } else {
      window.location.href = "/sms";
    }
  };


  return (
    <div className="screen modify-label">
      <Breadcrumbs
        sx={{ fontSize: { xs: "14px", sm: "16px" } }}
        aria-label="breadcrumb"
        style={{ marginBottom: 15 }}
      >
        <Link
          underline="hover"
          className="cursor-pointer"
          color="inherit"
          onClick={() => {
            navigate("/");
          }}
        >
          Trang chủ
        </Link>
        <Link
          underline="hover"
          className="cursor-pointer"
          color="inherit"
          onClick={() => {
            navigate("/sms");
          }}
        >
          SMS
        </Link>
        <Typography
          sx={{ fontSize: { xs: "14px", sm: "16px" } }}
          color="text.primary"
        >
          Gửi SMS
        </Typography>
      </Breadcrumbs>
      <Paper className="order-list" style={{ marginBottom: "10px" }}>
        <div className="ul-heading-placeholder">
          <span
            className="ul-heading"
            style={{ display: "flex", flex: "inherit", fontWeight: 500 }}
          >
            Danh sách
          </span>
          
        </div>
      </Paper>

      {/* <Loading loading={loading} /> */}
      <Paper
        sx={{ width: "100%", mb: 2 }}
        elevation={0}
        className={`${classes.tableBorder}`}
      >
        <TableContainer
          style={{
            width: "100%",
            overflowX: "auto",
            borderRadius: "0.5rem",
          }}
        >
          <Table sx={{ minWidth: 700 }} size={"small"}>
            <TableHead>
              <TableRow>
                <TableHeaderCell align="left" >
                  STT
                </TableHeaderCell>
                <TableHeaderCell align="left">
                  Tên
                </TableHeaderCell>

                <TableHeaderCell align="left" >
                  SĐT
                </TableHeaderCell>
                <TableHeaderCell align="left" >
                  Gửi tin nhắn
                </TableHeaderCell>
              </TableRow>
            </TableHead>
            <TableBody>
            {data &&
                data?.map((item, index) => (
                  <TableRow hover={true} key={index}>
                    
                    <TableCell align="left" >
                      {index+1}
                    </TableCell>
                    <TableCell align="left" >
                      {item?.lead?.fullname}
                    </TableCell>
                    
                    <TableCell align="left">
                    <WrapCellPhone>
                            {item?.lead?.phone ? (
                                <div
                                  className={`${classes.boxPhone}`}
                                >
                                  <IconPhone className="phone-icon">
                                    <svg
                                      xmlns="http:www.w3.org/2000/svg"
                                      aria-hidden="true"
                                      role="img"
                                      width="18px"
                                      height="18px"
                                      preserveAspectRatio="xMidYMid meet"
                                      viewBox="0 0 1024 1024"
                                    >
                                      <path
                                        fill="currentColor"
                                        d="M885.6 230.2L779.1 123.8a80.83 80.83 0 0 0-57.3-23.8c-21.7 0-42.1 8.5-57.4 23.8L549.8 238.4a80.83 80.83 0 0 0-23.8 57.3c0 21.7 8.5 42.1 23.8 57.4l83.8 83.8A393.82 393.82 0 0 1 553.1 553A395.34 395.34 0 0 1 437 633.8L353.2 550a80.83 80.83 0 0 0-57.3-23.8c-21.7 0-42.1 8.5-57.4 23.8L123.8 664.5a80.89 80.89 0 0 0-23.8 57.4c0 21.7 8.5 42.1 23.8 57.4l106.3 106.3c24.4 24.5 58.1 38.4 92.7 38.4c7.3 0 14.3-.6 21.2-1.8c134.8-22.2 268.5-93.9 376.4-201.7C828.2 612.8 899.8 479.2 922.3 344c6.8-41.3-6.9-83.8-36.7-113.8z"
                                      />
                                    </svg>
                                  </IconPhone>

                                  <p style={{ margin: 0 }}>
                                    {
                                      replaceMidd(item?.lead?.phone,3)
                                    }
                                    {" "}
                                  </p>
                                </div>
                              
                            ) : null}
                            
                          </WrapCellPhone>
                    </TableCell>
                    <TableCell>
                    <Tooltip title="Gửi tin nhắn">
                          <CustomButton
                            variant="contained"
                            className={`${classes.btnGlobal} ${classes.btnYellow}`}
                            onClick={() => {
                                handleSend(item)
                                setListSelected(item?.id);
                            }}
                          >
                            <SendRoundedIcon
                    style={{
                      width: "0.6em",
                      height: "0.6em",
                      marginLeft: "3px",
                    }}
                  ></SendRoundedIcon>
                          </CustomButton>
                        </Tooltip>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[10, 25]}
            colSpan={3}
            labelRowsPerPage={"Số hàng trên 1 trang:"}
            count={dataCustomers.dataCustomers?.open_leads?.leads_count}
            rowsPerPage={rowsPerPage}
            page={page}
            SelectProps={{
              inputProps: {
                "aria-label": "rows per page",
              },
              native: true,
            }}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            //ActionsComponent={TablePaginationActions}
          />
        </TableContainer>
      </Paper>
      {openSmsSend && (
        <SmsSendForm
        setUDO1={setOpenSmsSend}  
            item={item} 
          onClose={handleCloseSmsSend} 
          idSms={listSelected}
        />
      )}
    </div>
  );
};

export default SmsSend;
