import * as types from "./type";

const initialState = {
  data: {},
  loading: false,
};

export const dataLogoFooterRuducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_LOGO_FOOTER:
      return {
        ...state,
        ...{ loading: true },
      };

    case types.GET_LOGO_FOOTER_SUCCESS:
      return {
        ...state,
        ...{
          data: action.payload.user_obj,
          loading: false,
        },
      };

    case types.GET_LOGO_FOOTER_FAIL: {
      return {
        ...state,
        ...{ data: [], loading: false },
      };
    }
    default:
      return state;
  }
};
