export const GET_DEPARTMENTS = "GET_DEPARTMENTS"
export const GET_DEPARTMENTS_SUCCESS = "GET_DEPARTMENTS_SUCCESS"
export const GET_DEPARTMENTS_FAIL = "GET_DEPARTMENTS_FAIL"

export const CREATE_DEPARTMENTS = "CREATE_DEPARTMENTS"
export const CREATE_DEPARTMENTS_SUCCESS = "CREATE_DEPARTMENTS_SUCCESS"
export const CREATE_DEPARTMENTS_FAIL = "CREATE_DEPARTMENTS_FAIL"

export const EDIT_DEPARTMENTS = "EDIT_DEPARTMENTS"
export const EDIT_DEPARTMENTS_SUCCESS = "EDIT_DEPARTMENTS_SUCCESS"
export const EDIT_DEPARTMENTS_FAIL = "EDIT_DEPARTMENTS_FAIL"