import React, {useEffect, useState, useMemo,useCallback} from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCustomerStart } from "../../redux/customers/action";
import { useParams } from "react-router-dom";
import {
  Paper,
  Table,
  TableBody,
  TableRow,
  TableContainer,
  Typography,
  Tooltip
} from "@mui/material";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import PlayCircleFilledRoundedIcon from "@mui/icons-material/PlayCircleFilledRounded";
import PauseCircleFilledRounded from "@mui/icons-material/PauseCircleFilledRounded";
import MuiTableCell from "@material-ui/core/TableCell";
import MuiTableHead from "@mui/material/TableHead";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import "moment/locale/vi";
import axiosInstance from "../../components/axiosApi";
import { CustomButton } from "../../components/Common/CustomButton";
import Dialog from './DialogAnalyst'
import DetailCallHistory from './DetailCallHistory'
import { showMessageError, showMessageSuccess } from "../../redux/notification/action";

const URL = `${process.env.REACT_APP_SIPTRUNK_URL}`;

const useStyles = makeStyles((theme) => ({
  tableBorder: {
    border: "1px solid #d4d8dd",
    borderRadius: "0.5rem",
  },
}));

const TableCell = withStyles({
  root: {
    borderBottom: "1px solid rgb(235, 237, 242)",
    padding: "5px 10px",
    lineHeight: "18px",
  },
})(MuiTableCell);

const TableHead = withStyles((theme) => ({
  root: {
    backgroundColor: "rgba(38,60,85,.1)",
  },
}))(MuiTableHead);

const TableHeaderCell = withStyles((theme) => ({
  root: {
    color: "#516377",
    fontWeight: 700,
    padding: 10,
    fontSize: ".75rem",
    textTransform: "uppercase",
    letterSpacing: "0.8px",
  },
}))(TableCell);

const useAudio = (url) => {
    const audio = useMemo(() => new Audio(url), [url]);
    const [playing, setPlaying] = useState(false);
    const toggle = () => setPlaying(!playing);
  
    useEffect(() => {
      playing ? audio.play() : audio.pause();
    }, [playing, audio]);
  
    useEffect(() => {
      audio.addEventListener("ended", () => setPlaying(false));
      return () => {
        audio.removeEventListener("ended", () => setPlaying(false));
      };
    }, [audio]);
  
    return [playing, toggle];
  };
  
  const Player = ({ url }) => {
    const [playing, toggle] = useAudio(url);
  
    return (
      <div>
        <button className="btn-play" onClick={toggle}>
          {playing ? (
            <PauseCircleFilledRounded />
          ) : (
            <PlayCircleFilledRoundedIcon />
          )}
        </button>
      </div>
    );
  };


const CallHistory = ({parentCallback,dataLogoFooter}) => {
    
    const { phoneNumber } = useParams();
    const [showDetail, setShowDetail] = useState(false);
    const [loading, setLoading] = useState(true);
    const [detailCustomer, setDetailCustomer] = useState(null);
    const [data, setData] = useState([]);
    const [webhook, setWebhook] = useState([]);
    const [openDialog, setOpenDialog] = useState(false);
    const [selected, setSelected] = useState([])
    const dispatch = useDispatch();
    const dataCustomers = useSelector((state) => state.getCustomersReducer);
    const pbx_username = dataLogoFooter?.org?.pbx_username
    const pbx_pwd = dataLogoFooter?.org?.pbx_password

    useEffect(() => {
      let data = {
        phone: phoneNumber,
        
      };
      dispatch(getCustomerStart(data));
     
    }, []);

    useEffect(() => {
      if (dataCustomers?.dataCustomers?.open_leads?.open_leads) {
        setDetailCustomer([...dataCustomers?.dataCustomers?.open_leads?.open_leads]);
      }
    }, [dataCustomers?.dataCustomers?.open_leads]);


  const classes = useStyles();

  const handleSendAnalyst  = async (item) => {
    try {
      const res = await axiosInstance.post(`/report/analysis/${item}/`);
      if (res.status === 200) {
        dispatch(showMessageSuccess("Connect Success"));
        setTimeout(() => {
            setLoading(false);
            getCallHistory();   
        }, 600);
      }
    } catch (error) {
      
    }
  };

  const handleGetDetailWebHook = async(code) => {
    try {
      const res = await axiosInstance.get(`/report/cdr-detail/${code}/`);
      if (res.status === 200) {
          setWebhook(res.data.data);
      }
    } catch (error) {
     
    }
  }

  const getCallHistory = useCallback(async () => {
    try {
      const res = await axiosInstance.get(`/report/getcdr/${phoneNumber}/?email=${pbx_username}&password=${pbx_pwd}`);
      if (res.status === 200) {
          setData(res.data.data);
      }
    } catch (error) {
     
    }
  }, [pbx_username,pbx_pwd,phoneNumber])

  useEffect(() => {
    
    getCallHistory();
    
  }, []);
  
  const handleAnalyst = (item) => {
    handleSendAnalyst(item)
    setOpenDialog(true)
    setSelected(item)
  }

  return (
    
    <div className="screen">
            {showDetail ? (
        <>
        <CustomButton style={{marginBottom:'15px',minWidth:'inherit',pading:'0 6px'}} onClick={() => setShowDetail(false)}><KeyboardArrowLeftIcon /></CustomButton>
        <DetailCallHistory 
            data = {webhook}
            detailCustomer = {detailCustomer}
            />
        </>
          ) : (
        
        <>
        <Paper
          className="user-list"
          variant="outlined"
          style={{ marginBottom: 10 }}
        >
                   <div className="ul-heading-placeholder">
            <span
              className="ul-heading"
              style={{ display: "flex", alignItems: "center", fontWeight: 500 }}
            >
             Lịch sử cuộc gọi
            </span>
          </div>
        </Paper>
      {data.length > 0 ? (
        <Paper
          sx={{ width: "100%", mb: 2 }}
          elevation={0}
          className={`${classes.tableBorder}`}
        >
          <TableContainer
            style={{ width: "100%", overflowX: "auto", borderRadius: "0.5rem" }}
          >
            <Table sx={{ minWidth: 700 }} size={"small"}>
              <TableHead>
                <TableRow>
                    <TableHeaderCell align="left">ID</TableHeaderCell>
                  
                  <TableHeaderCell align="left" style={{ minWidth: 80 }}>
                        NGÀY GỌI	
                  </TableHeaderCell>
                  <TableHeaderCell align="left">SĐT</TableHeaderCell>
                  <TableHeaderCell align="left">Nguồn</TableHeaderCell>
                  <TableHeaderCell align="left">File Nguồn</TableHeaderCell>
                  <TableHeaderCell align="left">Phân tích cuộc gọi</TableHeaderCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {data &&
                  data.length > 0 &&
                  data.map((item, index) => (
                    <TableRow key={index}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                    <TableCell align="left">{item?.id}</TableCell>
                      
                      
                      
                      
                      <TableCell align="left">
                        {item?.call_date === null
                          ? ""
                          : moment(item?.call_date).format("MM/DD/YYYY")}
                      </TableCell>
                      <TableCell align="left">{item?.des}</TableCell>
                      <TableCell align="left">{item?.src}</TableCell>
                      <TableCell align="left">
                      <Player url={`${URL}${item?.cdr_url}`} /> 
                      </TableCell>
                      <TableCell align="left" style={{display:'flex'}}>
                      
                      <Tooltip title="Phân tích cuộc gọi" placement="top">
                      <CustomButton style={{position:'relative',minWidth:'inherit',pading:'0 5px',marginBottom:'5px'}}
                            onClick={() => handleAnalyst(item?.id)}
                        >
                                <div className="wavy-line wavy-line-blue" data-text="xxxxxxxxxxxxxx"></div>
                        </CustomButton> 
                        </Tooltip>
                        
                        <CustomButton 
                          onClick={() =>{ handleGetDetailWebHook(item?.code)
                            setShowDetail(true)
                            }}
                          disabled = {
                            item?.webhook === null
                          }
                          style={{position:'relative',minWidth:'inherit',pading:'0 5px', marginLeft:'5px'}}>
                          <InfoOutlinedIcon />
                          </CustomButton>
                        
                        
                      
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Dialog 
                setOpenDialog={setOpenDialog}
                openDialog={openDialog}
                itemID={selected}
                loading={loading}
          />
          
        </Paper>
      ) : (
        <Typography>Dữ liệu chưa đồng bộ</Typography>
      )}
      </>
      )}
      
    </div>
    
  );
};

export default CallHistory;
