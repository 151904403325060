import React, { useState } from "react";
import {
  Checkbox,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Paper,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DialogStaff from "../Dialog/index";
import EditIcon from "@mui/icons-material/EditOutlined";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  customModalContent:{
    paddingLeft:'15px',
    paddingRight:'15px'
  },
  customModal:{
    width:'calc(100% - 20px)',
    marginLeft:'10px',
    marginRight:'10px'
  },
  customModalTitle:{
    padding:'10px 15px',
    color:'#fff',
    background:process.env.NODE_ENV === 'development' ? "#5a8dee" : process.env.REACT_APP_PRODUCT_BASE_COLOR
  }
}));
const TableStaff = ({ userData }) => {
  const classes = useStyles();
  const [userDetailOpen, setUDO] = useState(false);
  const [empSelected, setEmpSelected] = useState();

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
      [`&.${tableCellClasses.head}`]: {
          textTransform:"capitalize",
          color:"#707275",
          backgroundColor: 'rgba(38,60,85,.1)'
      },
      [`&.${tableCellClasses.body}`]: {
          fontSize: 14,
      },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    // hide last border
    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));


  const handleAdd = (row) => {
    setEmpSelected(row);
    setUDO(true);
  };

  const handleCloseUserDetailDialog = () => {
    setUDO(!userDetailOpen);
  };


  return (
    <TableContainer style={{ width: "100%", overflowX: "auto" ,borderRadius:'0.5rem'}}>
    <Table sx={{ minWidth: 700 }} size={"small"}>
        <TableHead>
          <TableRow>
            <StyledTableCell align="left">STT</StyledTableCell>
            <StyledTableCell align="left" style={{ minWidth: 100 }}>
              Tên
            </StyledTableCell>
            <StyledTableCell align="left" style={{ minWidth: 100 }}>
              Tên đăng nhập
            </StyledTableCell>
            
            <StyledTableCell align="left" style={{ minWidth: 125 }}>
              Phân loại
            </StyledTableCell>
            <StyledTableCell align="left" style={{ minWidth: 30 }}>
              Email
            </StyledTableCell>
            <StyledTableCell align="left" style={{ minWidth: 30 }}>
              SĐT
            </StyledTableCell>
            <StyledTableCell
              align="left"
              style={{ minWidth: 30 }}
            ></StyledTableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {userData?.length > 0 ? (
            userData?.map((row, index) => {
              return (
                <StyledTableRow hover={true}>
                  <StyledTableCell align="left">{index + 1}</StyledTableCell>
                  <StyledTableCell align="left">
                    {row?.last_name}
                  </StyledTableCell>
                  <StyledTableCell align="left">{row?.username}</StyledTableCell>
                  <StyledTableCell align="left">{row?.role === 'USER'? 'Nhân viên' : 'Quản trị'}</StyledTableCell>
                  <StyledTableCell align="left">{row?.email}</StyledTableCell>
                  <StyledTableCell align="left">{row?.phone}</StyledTableCell>
                  {/* <StyledTableCell align="left">
                                        <Checkbox 
                                            id="recurring_fee" 
                                            name="recurring_fee"
                                            disabled
                                            color="secondary"
                                            checked={row?.recurring_fee}
                                        />
                                    </StyledTableCell> */}
                  <StyledTableCell align="left">
                    <EditIcon
                      sx={{ color: "rgb(90, 141, 238)" }}
                      onClick={() => handleAdd(row)}
                    />
                  </StyledTableCell>
                </StyledTableRow>
              );
            })
          ) : (
            <h1>Không có dữ liệu</h1>
          )}
        </TableBody>
      </Table>
      <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        open={userDetailOpen}
        onClose={handleCloseUserDetailDialog}
        style={{ minHeight: "100%" }}
        classes={{
            paper: classes.customModal
        }}
      >
        <DialogTitle className={`${classes.customModalTitle}`}>
          Chỉnh sửa nhân viên
          <IconButton
            onClick={() => setUDO(false)}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: '#fff',
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent className={`${classes.customModalContent}`}>
          <DialogStaff setUDO={setUDO} dataEmp={empSelected} />
        </DialogContent>
      </Dialog>
    </TableContainer>
  );
};

export default TableStaff;
