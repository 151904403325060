import React from "react";
import {
  Checkbox,
  TextField,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Select,
  MenuItem,
  Grid
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import { CustomButton } from "../../components/Common/CustomButton";

import DateTimePicker from "@mui/lab/DateTimePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import moment from "moment";

import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { makeStyles } from "@material-ui/core/styles";
import styled from "styled-components";
import { editSmscamp, getSmscamp } from "../../redux/smscamp/action";

export const TitleInput = styled("p")(({ theme }) => ({
  fontSize: 14,
  fontWeight: 500,
  marginBottom: 10,
}));

export const BoxInput = styled("div")(({ theme }) => ({
  marginBottom: "10px",
}));

  //#material disabled Selecbox
  export const StyledSelect = styled(Select)`

  &.Mui-disabled {
    background: #eee;
  }
`;

const statusData = [
  { id: 1, label: "Đang chờ" },
];

const useStyles = makeStyles((theme) => ({
  textFieldError: {
    "& input": {
      border: "1px solid red",
      color: "red",
    },
    "& p": {
      color: "red",
      marginLeft: 0,
    },
  },
  customModalContent: {
    paddingLeft: "15px",
    paddingRight: "15px",
  },
  customModal: {
    width: "calc(100% - 20px)",
    marginLeft: "10px",
    marginRight: "10px",
  },
  customModalTitle: {
    padding: "10px 15px",
    color: "#fff",
    background:process.env.NODE_ENV === 'development' ? "#5a8dee" : process.env.REACT_APP_PRODUCT_BASE_COLOR,
    marginBottom: "15px",
  },
}));

const SmsTempEdit = ({ setUDO1, item, onClose, idCamp, dataLogoFooter }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const isAdmin = dataLogoFooter.role === 'ADMIN';
  const isStaff = dataLogoFooter.is_staff;


  const listSmsbot = useSelector(
    (item) => item.smsbotReducer.dataList.package
  );

  const listSmstemp = useSelector(
    (item) => item.smstemplateReducer.dataList.temp_cfg
  );


  const validationSchema = Yup.object({
    // name: yup.string().required('Bạn chưa nhập tên phòng ban'),
  });



  const filterStatusId = statusData.filter((x) => x.label === item?.trangthai).map(x => x.id)[0]

  const filterTempId = listSmstemp.filter((x) => x.name === item?.temp_id?.name).map(x => x.id)[0]

  const filterPackageId = listSmsbot.list_package.filter((x) => x.name === item?.package?.name).map(x => x.id)[0]
  
  const formik = useFormik({
    initialValues: {
      id:idCamp,
      name: item?.name,
      num_total:item?.num_total,
      desc:item?.description,
      trangthai:filterStatusId,
      sendTime:item?.sendTime,
      temp_id:filterTempId,
      hot_send:item?.hot_send ?? false,
      packageId:filterPackageId,
      activate:item?.activate ?? false,
    },
    validationSchema: validationSchema,
    onSubmit:  (values) => {
      const smsValues = {
        ...values,
        sendTime: values.sendTime
        ? moment(values.sendTime).format("YYYY-MM-DD H:mm:ss")
        : "",
      };
      dispatch(editSmscamp(smsValues));
      setUDO1(false);
      dispatch(getSmscamp());
    },
  });

  return (
    <Dialog
      fullWidth={true}
      maxWidth={"sm"}
      open={true}
      onClose={onClose}
      style={{ minHeight: "100%" }}
      classes={{
        paper: classes.customModal,
      }}
    >
      <DialogTitle className={`${classes.customModalTitle}`}>
        Sửa chiến dịch
        <IconButton
          onClick={() => setUDO1(false)}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "#fff",
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={`${classes.customModalContent}`}>
        <form onSubmit={formik.handleSubmit}>
        <Grid container rowSpacing={1} columnSpacing={{ sm: 2 }}>
        <Grid item xs={12} sm={6}>
        <BoxInput>
            <TitleInput>Tên</TitleInput>
            <TextField
              name="name"
              size="small"
              fullWidth
              value={formik.values.name}
              onChange={formik.handleChange}
            />
          </BoxInput>
        </Grid>
        <Grid item xs={12} sm={6}>
        <BoxInput>
            <TitleInput>Số tin gửi tối đa</TitleInput>
            <TextField
              size="small"
              name="num_total"
              fullWidth
              placeholder="Số tin gửi tối đa"
              value={formik.values.num_total}
              onChange={formik.handleChange}
              type="number"
            />
          </BoxInput>
        </Grid>
        <Grid item xs={12} sm={6}>
        <BoxInput>
            <TitleInput>Mô tả</TitleInput>
            <TextField
              size="small"
              name="desc"
              fullWidth
              placeholder="Mô tả"
              value={formik.values.desc}
              onChange={formik.handleChange}
            />
          </BoxInput>
        </Grid>
        <Grid item xs={12} sm={6}>
        <TitleInput>Trạng thái</TitleInput>
        <StyledSelect
            style={{ fontSize: 14 }}
            labelId="trangthai"
            fullWidth
            size="small"
            label="Trạng thái"
            name="trangthai"
            value={formik.values.trangthai}
            onChange={formik.handleChange}
            disabled
            
          >
            {statusData.map((data, index) => (
              <MenuItem key={index} value={data.id}>
                {data.label}
              </MenuItem>
            ))}
          </StyledSelect>
        </Grid>
        <Grid item xs={12}>
        <TitleInput>Thời gian gửi</TitleInput>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DateTimePicker
                label="Thời gian gửi"
                value={formik.values.sendTime}
                    onChange={(value) => formik.setFieldValue("sendTime", value)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    style={{ marginLeft: "auto" }}
                  />
                )}
              />
            </LocalizationProvider>
        </Grid>
        <Grid item xs={12} sm={6}>
        <TitleInput>Gói dữ liệu</TitleInput>
        <Select
                    labelId="packageId"
                    fullWidth
                    size="small"
                    label="Gói dữ liệu"
                    name="packageId"
                    value={formik.values.packageId}
                    onChange={formik.handleChange}
                  >
                    {listSmsbot.list_package?.length > 0 ? (
                      listSmsbot.list_package?.map((item) => (
                        <MenuItem value={item.id}>{item.name}</MenuItem>
                      ))
                    ) : (
                      <MenuItem value={""}>--none--</MenuItem>
                    )}
                  </Select>
        </Grid>
        <Grid item xs={12} sm={6}>
        <TitleInput>Mẫu tin nhắn</TitleInput>
            <Select
                    labelId="temp_id"
                    fullWidth
                    size="small"
                    label="Mẫu tin nhắn"
                    name="temp_id"
                    value={formik.values.temp_id}
                    onChange={formik.handleChange}
                  >
                    {listSmstemp.length > 0 ? (
                      listSmstemp.map((item) => (
                        <MenuItem value={item.id}>{item.name}</MenuItem>
                      ))
                    ) : (
                      <MenuItem value={""}>--none--</MenuItem>
                    )}
                  </Select>
        </Grid>
        <Grid item xs={12}>
          <BoxInput style={{display:'flex'}}>
          <TitleInput>Gửi ngay</TitleInput>
            
            <Checkbox style={{marginTop:'-12px'}}
              
              id="hot_send"
              name="hot_send"
              checked={formik.values.hot_send}
              onChange={(e) => {
                formik.setFieldValue(
                  "hot_send",
                  e.currentTarget.checked
                );
               
              }}
              />
            <TitleInput>Kích hoạt chiến dịch</TitleInput>
              
              <Checkbox style={{marginTop:'-12px'}}
                disabled = {(isAdmin||isStaff) ? false : true}
                id="activate"
                name="activate"
                checked={formik.values.active}
                onChange={(e) => {
                   formik.setFieldValue(
                     "activate",
                     e.currentTarget.checked
                   );
                }}
                />
          </BoxInput>
        </Grid>
</Grid>

          

          <div
            style={{ display: "flex", justifyContent: "right", marginTop: 10 }}
          >
            <CustomButton
              variant="contained"
              type="submit"
              onClick={formik.handleSubmit}
            >
              Sửa
            </CustomButton>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
};
export default SmsTempEdit;
