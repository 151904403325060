import * as types from "./type"

export const getCdr = (data) => ({
        type: types.GET_CDR,
        payload: data
})

export const getCdrSuccess = (data) => ({
    type: types.GET_CDR_SUCCESS,
    payload: data
})

export const getCdrFail = (data) => ({
    type: types.GET_CDR_FAIL,
    payload: data
})