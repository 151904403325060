import React, { forwardRef } from "react";
import { render, unmountComponentAtNode } from "react-dom";
import ConfirmModal from "./confirm-modal";

const ConfirmDialog = (props, ref) => {
  return new Promise((resolve) => {
    let el = document.createElement("div", { ref: ref });
    const handleResolve = (result) => {
      unmountComponentAtNode(el);
      el = null;
      resolve(result);
    };
    render(<ConfirmModal {...props} onClose={handleResolve} ref={ref} />, el);
  });
};

export default ConfirmDialog;
