import React from "react";
import ReactDOM from "react-dom";
import "./assets/css/index.css";
import "./index.css";
import Login from "./Login";
import Dashboard from "./pages/Dashboard";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import TestCall from "./TestCall";
import { Provider } from "react-redux";
import store from "./redux/store";

import Privacy from "./pages/Privacy";
import Term from "./pages/Term";
import AlertMessage from "./components/Common/Alert";
import { NotifyProvider } from "./pages/Notification/NotifyContent";
export function LogOut() {
  sessionStorage.clear();
  localStorage.clear();
  window.history.pushState({}, "", "logout");
  window.location.href = "/login";
}


ReactDOM.render(
  <>
    <Provider store={store}>
    
    <NotifyProvider >
   
      <AlertMessage />
      <BrowserRouter>
        <Routes>
          {localStorage.getItem("access_token") === null ? (
            <>
              <Route path="/" element={<Navigate to="/login" />} />
              <Route path="login" element={<Login />} />
              <Route path="dashboard" element={<Navigate to="/login" />} />
              <Route path="data-list" element={<Navigate to="/login" />} />
              <Route
                path="accouting-financial"
                element={<Navigate to="/login" />}
              />

              <Route path="report" element={<Navigate to="/login" />} />
              <Route path="report-cdr" element={<Navigate to="/login" />} />
              <Route path="configuration" element={<Navigate to="/login" />} />
              <Route path="callcenter" element={<Navigate to="/login" />} />
              <Route path="ware-house" element={<Navigate to="/login" />} />
              <Route path="company" element={<Navigate to="/login" />} />
              <Route path="source-lead" element={<Navigate to="/login" />} />
              <Route path="status-lead" element={<Navigate to="/login" />} />
              <Route path="departments" element={<Navigate to="/login" />} />
              <Route path="staff" element={<Navigate to="/login" />} />
              {/* <Route path="employee-manager" element={<Navigate to="/login" />} /> */}
              <Route path="logout" element={<LogOut />} />
              {/* <Route path="test" element={<Navigate to="/login" />} /> */}
              <Route path="user-profile" element={<Navigate to="/login" />} />
              <Route path="zalo" element={<Navigate to="/login" />} />
              <Route path="facebook" element={<Navigate to="/login" />} />
              <Route path="sms" element={<Navigate to="/login" />} />
              <Route path="smstemplate" element={<Navigate to="/login" />} />
              <Route path="smsconfig" element={<Navigate to="/login" />} />
              <Route path="smssend" element={<Navigate to="/login" />} />
              <Route path="sms-bots" element={<Navigate to="/login" />} />
              <Route path="sms-camp" element={<Navigate to="/login" />} />
              <Route path="extension" element={<Navigate to="/login" />} />
              <Route path="callstatus" element={<Navigate to="/login" />} />
              <Route path="ticket" element={<Navigate to="/login" />} />
              <Route path="create-ticket" element={<Navigate to="/login" />} />
              <Route path="report-product" element={<Navigate to="/login" />} />
              <Route path="report-call" element={<Navigate to="/login" />} />
              <Route 
                path='callhistory/:phoneNumber'
                element={<Navigate to="/login" />} />
            </>
          ) : (
            <>
              <Route path="/" element={<Navigate to="/dashboard" />} />
              <Route path="login" element={<Navigate to="/dashboard" />} />
              <Route path="dashboard" element={<Dashboard />} />
              <Route path="data-list" element={<Dashboard />} />
              <Route path="accouting-financial" element={<Dashboard />} />

              <Route path="report" element={<Dashboard />} />
              <Route path="report-cdr" element={<Dashboard />} />
              <Route path="report-product" element={<Dashboard />} />
              <Route path="report-call" element={<Dashboard />} />
              <Route path="configuration" element={<Dashboard />} />
              <Route path="callcenter" element={<Dashboard />} />
              <Route path="company" element={<Dashboard />} />
              <Route path="ware-house" element={<Dashboard />} />
              <Route path="source-lead" element={<Dashboard />} />
              <Route path="status-lead" element={<Dashboard />} />
              <Route path="departments" element={<Dashboard />} />
              <Route path="staff" element={<Dashboard />} />
              {/* <Route path="employee-manager" element={<Dashboard />} /> */}
              <Route path="logout" element={<LogOut />} />
              <Route path="user-profile" element={<Dashboard />} />
              <Route path="zalo" element={<Dashboard />} />
              <Route path="facebook" element={<Dashboard />} />
              <Route path="extension" element={<Dashboard />} />
              <Route path="sms" element={<Dashboard />} />
              <Route path="smsconfig" element={<Dashboard />} />
              <Route path="smstemplate" element={<Dashboard />} />
              <Route path="smssend" element={<Dashboard />} />
              <Route path="sms-bots" element={<Dashboard />} />
              <Route path="sms-camp" element={<Dashboard />} />
              <Route path="callstatus" element={<Dashboard />} />
              <Route path="ticket" element={<Dashboard />} />
              <Route path="create-ticket" element={<Dashboard />} />
              <Route 
                path='callhistory/:phoneNumber'
                element={<Dashboard />} />
            
            </>
          )}
          <Route path="test" element={<TestCall />} />

          <Route path="order-management" element={<Dashboard />} />
          <Route path="credit-management" element={<Dashboard />} />
          <Route path="privacy-policy" element={<Privacy />} />
          <Route path="terms-of-use" element={<Term />} />
          <Route
            path="*"
            element={
              <main
                style={{
                  padding: "1rem",
                  textAlign: "center",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                  height: "90vh",
                  fontWeight: "bold",
                  fontSize: 20,
                }}
              >
                <span style={{ fontSize: 100 }}>404</span>
                <p style={{ margin: 0 }}>
                  Không có gì ở đây cả!
                  <br />
                  <span style={{ fontWeight: "normal" }}>
                    Trang bạn đang tìm kiếm không có sẵn...
                  </span>
                </p>
              </main>
            }
          />
        </Routes>
      </BrowserRouter>
      </NotifyProvider>
      
    </Provider>
  </>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
