import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableContainer,
  TableRow,
  Typography,
  Paper,
  TablePagination
} from "@mui/material";
import Loading from "../../components/Common/Loading";
import LastPageIcon from "@mui/icons-material/LastPage";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import { CustomButton } from "../../components/Common/CustomButton";
import { useDispatch, useSelector } from "react-redux";
import Tooltip from "@mui/material/Tooltip";
import { makeStyles } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import { useTheme } from "@mui/material/styles";
import MuiTableCell from "@material-ui/core/TableCell";
import MuiTableHead from "@mui/material/TableHead";
import AddRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import SmsCampCreate from "./SmsCampCreate";
import SmsTempDel from "./SmsTempDel";
import SmsCampEdit from "./SmsCampEdit";
import { deleteSmscamp, getSmscamp } from "../../redux/smscamp/action";
import { getSmstemplate } from "../../redux/smstemplate/action";
import { getSmsbot } from "../../redux/smsbots/action";

import moment from "moment";
import "moment/locale/vi";

const useStyles = makeStyles((theme) => ({
  tableBorder: {
    border: "1px solid #d4d8dd",
    borderRadius: "0.5rem",
  },
  btnGlobal: {
    minWidth: "inherit",
    width: 28,
    padding: "0",
    height: "28px",
    lineHeight: "28px",

    marginLeft: 5,
    boxShadow: "none",
    fontSize: "12px",
    fontWeight: "500",
  },
  Pagination: {
    display: "flex",
  },
  btnBlue: { backgroundColor: "#556ee6" },
  // btnGreen:{
  //   backgroundColor: '#34c38f',
  // },
  btnYellow: {
    backgroundColor: "#f1b44c",
  },
  btnRed: {
    backgroundColor: "#f46a6a",
  },
}));

const TableCell = withStyles({
  root: {
    borderBottom: "1px solid rgb(235, 237, 242)",
    padding: "5px 10px",
    lineHeight: "18px",
  },
})(MuiTableCell);

const TableHead = withStyles((theme) => ({
  root: {
    backgroundColor: "rgba(38,60,85,.1)",
  },
}))(MuiTableHead);

const TableHeaderCell = withStyles((theme) => ({
  root: {
    color: "#516377",
    fontWeight: 700,
    padding: "10px",
    fontSize: ".75rem",
    textTransform: "uppercase",
    letterSpacing: "0.8px",
    lineHeight: "18px",
  },
}))(TableCell);

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const SmsCamp = ({dataLogoFooter}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(true);
  const [openCreateSmsCamp, setOpenCreateSmsCamp] = useState(false);
  const [openDelSmsCamp, setOpenDelSmsCamp] = useState(false);
  const [openEditSmsCamp, setOpenEditSmsCamp] = useState(false);
  const [item, setItem] = useState([]);
  const [listSelected, setListSelected] = useState([]);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);


  const handleChangePage = (event, newPage) => {
      let data = {
       
        offset: newPage * rowsPerPage,
        limit: rowsPerPage,
      };
      dispatch(getSmscamp(data));
      setPage(newPage);
    };
  
    const handleChangeRowsPerPage = (event) => {
      let data = {
        
        offset: page,
        limit: event.target.value !== "-1" ? +event.target.value : 9999,
      };
      dispatch(getSmscamp(data));
      setRowsPerPage(+event.target.value);
      setPage(0);
    };
    


  useEffect(() => {
    setTimeout(() => setLoading(false), 500);
    dispatch(getSmsbot());
    dispatch(getSmscamp());
    // eslint-disable-next-line
  }, []);

  const listSmscamp = useSelector(
    (item) => item.smscampReducer.dataList.campaign
  );

  const delSuccess = useSelector((item) => item.smscampReducer.deleteLoad);

  //   delete
  useEffect(() => {
    dispatch(getSmscamp());
    dispatch(getSmstemplate())
    // eslint-disable-next-line
  }, [delSuccess]);

  const handleDel = (id) => {
    dispatch(deleteSmscamp(id));
    setOpenDelSmsCamp(false);
  };
  const handleCloseDelSmsCamp = () => {
    setOpenDelSmsCamp(false);
  };

  //   edit

  const handleEdit = (item) => {
    setItem(item);
    setOpenEditSmsCamp(true);
  };

  const handleCloseEditSmsCamp = () => {
    setOpenEditSmsCamp(false);
  };

  const handleCreateSmsCamp = () => {
    setOpenCreateSmsCamp(false)
  }

  function cutText(text){
    var remove_after= text?.indexOf('_');
      if (remove_after  > -1){
          return text?.substring(0, remove_after)
      } return text
  }
  

  return (
    <>
      <Loading loading={isLoading} />

      <Paper className="order-list" style={{ marginBottom: "10px" }}>
        <div className="ul-heading-placeholder">
          <span
            className="ul-heading"
            style={{ display: "flex", flex: "inherit", fontWeight: 500 }}
          >
            Danh sách
          </span>
          <CustomButton
            variant="contained"
            onClick={() => {
              setOpenCreateSmsCamp(true);
            }}
          >
            <AddRoundedIcon style={{ fontSize: "1rem", marginRight: "5px" }} />
            Tạo chiến dịch
          </CustomButton>
        </div>
      </Paper>

      <Paper
        sx={{ width: "100%", mb: 2 }}
        elevation={0}
        className={`${classes.tableBorder}`}
      >
        <TableContainer
          style={{
            width: "100%",
            overflowX: "auto",
            borderRadius: "0.5rem",
          }}
        >
          <Table sx={{ minWidth: 700 }} size={"small"}>
            <TableHead>
              <TableRow>
                <TableHeaderCell align="left" style={{ width: 40 }}>
                  STT
                </TableHeaderCell>

                <TableHeaderCell align="left" style={{ width: 120 }}>
                  Tên Chiến dịch
                </TableHeaderCell>

                <TableHeaderCell align="left" style={{ width: 40 }}>
                  Tối đa
                </TableHeaderCell>
                <TableHeaderCell align="left" style={{ width: 80 }}>
                  Mô tả
                </TableHeaderCell>
                <TableHeaderCell align="left" style={{ width: 80 }}>
                  Trạng thái
                </TableHeaderCell>
                <TableHeaderCell align="left" style={{ width: 120 }}>
                  Thởi gian gửi
                </TableHeaderCell>
                <TableHeaderCell align="left" style={{ width: 120 }}>
                  Mẫu gửi
                </TableHeaderCell>
                <TableHeaderCell align="left" style={{ width: 40 }}>
                  Gửi ngay
                </TableHeaderCell>
                <TableHeaderCell align="left" style={{ width: 80 }}>
                  Gói dữ liệu
                </TableHeaderCell>
                <TableHeaderCell align="left" style={{ width: 120 }}>
                  Hành động
                </TableHeaderCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {listSmscamp?.list_campaign &&
                listSmscamp?.list_campaign.map((item, index) => (
                  <TableRow hover={true} key={index}>
                    <TableCell align="left">{index + 1}</TableCell>
                    <TableCell align="left">{item?.name}</TableCell>

                    <TableCell align="left">{item?.num_total}</TableCell>
                    <TableCell align="left">{item?.description}</TableCell>
                    <TableCell align="left">{item?.trangthai}</TableCell>
                    <TableCell align="left">
                      {item?.sendTime === null
                        ? ""
                        : moment(item?.sendTime).format("DD/MM/YYYY, H:mm:ss")}
                    </TableCell>
                    <TableCell align="left">{item?.temp_id?.name}</TableCell>
                    <TableCell align="left">
                      <span style={{'color':'#19A442'}} className={`${item?.hot_send === false ? null : 'fas fa-check'}`}>

                      </span>
                    </TableCell>
                    <TableCell align="left">{cutText(item?.package?.name)}</TableCell>
                    <TableCell align="left">
                      <div style={{ display: "flex", marginBottom: "5px" }}>
                        <Tooltip title="Chỉnh sửa">
                          <CustomButton
                            variant="contained"
                            className={`${classes.btnGlobal} ${classes.btnYellow}`}
                            onClick={() => {
                              handleEdit(item);
                              setListSelected(item?.id);
                            }}
                          >
                            <i
                              style={{ fontSize: "10px" }}
                              className="fas fa-pencil-alt"
                            ></i>
                          </CustomButton>
                        </Tooltip>
                        <Tooltip title="Xoá">
                          <CustomButton
                            variant="contained"
                            className={`${classes.btnGlobal} ${classes.btnRed}`}
                            onClick={() => {
                              setOpenDelSmsCamp(true);
                              setListSelected(item?.id);
                            }}
                          >
                            <i
                              style={{ fontSize: "10px" }}
                              className="fas fa-trash"
                            ></i>
                          </CustomButton>
                        </Tooltip>
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
                  rowsPerPageOptions={[10, 25]}
                  className={`${classes.Pagination}`}
                  component="div"
                  colSpan={3}
                  labelRowsPerPage={"Số hàng trên 1 trang:"}
                  count={listSmscamp?.campaign_count}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: {
                      "aria-label": "rows per page",
                    },
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
      </Paper>
      {openCreateSmsCamp && (
        <SmsCampCreate
          dataLogoFooter={dataLogoFooter}
          onClose={handleCreateSmsCamp}
          setUDO1={setOpenCreateSmsCamp}
        />
      )}
      {openDelSmsCamp && (
        <SmsTempDel
          setUDO1={setOpenDelSmsCamp}
          onDel={handleDel}
          idTemp={listSelected}
          onClose={handleCloseDelSmsCamp}
        />
      )}
      {openEditSmsCamp && (
        <SmsCampEdit
          dataLogoFooter={dataLogoFooter}
          setUDO1={setOpenEditSmsCamp}
          item={item}
          onClose={handleCloseEditSmsCamp}
          idCamp={listSelected}
        />
      )}
    </>
  );
};

export default SmsCamp;
