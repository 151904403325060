import * as types from "./type";

export const getModule = (data) => ({
  type: types.GET_MODULE,
  payload: data,
});

export const getModuleSuccess = (data) => ({
  type: types.GET_MODULE_SUCCESS,
  payload: data,
});

export const getModuleFail = (data) => ({
  type: types.GET_MODULE_FAIL,
  payload: data,
});
