import {
  Dialog,
  DialogContent,
  DialogTitle,

  IconButton,

  MenuItem,
  Select,
  Grid,
  Autocomplete,
  TextField,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axiosInstance from "../../../../components/axiosApi";
import { CustomButton } from "../../../../components/Common/CustomButton";
import Loading from "../../../../components/Common/Loading";
import {
  showMessageError,
  showMessageSuccess,
} from "../../../../redux/notification/action";
import { BoxInput, TitleInput } from "./styled";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  customModalContent: {
    paddingLeft: "15px",
    paddingRight: "15px",
  },
  customModal: {
    width: "calc(100% - 20px)",
    marginLeft: "10px",
    marginRight: "10px",
  },
  customModalTitle: {
    padding: "10px 15px",
    color: "#fff",
    background: process.env.NODE_ENV === 'development' ? "#5a8dee" : process.env.REACT_APP_PRODUCT_BASE_COLOR
  },
}));

const RANDOMDATA = [
  { value: true, label: "Chia dữ liệu tự động" },
  { value: false, label: "Chia dữ liệu thủ công" },
];

const DiaLogSplitData = ({
  setOpenDialogSplitData,
  openDialogSplitData,
  selected,
  setSelected
}) => {
  const classes = useStyles();
  const [data, setData] = useState(true);

  const [autocomplete, setAutocomplete] = useState([]);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const handleChangeStatus = async () => {
    if (selected.length <= 0) {
      dispatch(showMessageError("Không có dữ liệu khách hàng nào được chọn"));
      return;
    } else {
      let params = "";
      if (data === false) {
        params += `random=${data}&m_leads={"m_lead":[${selected}]}&id_users={"id_user":[${autocomplete}]}`;
      } else {
        params += `random=${data}&m_leads={"m_lead":[${selected}]}&id_users={"id_user":[${arrayUserListById}]}`;
      }
      try {
        setLoading(true);
        const res = await axiosInstance.post(`/leads/as-data/?${params}`);
        if (res.status === 200) {
          dispatch(showMessageSuccess("Chia Data thành công"));
          setOpenDialogSplitData(false);
          setLoading(false);
          setSelected([])
        }
      } catch (error) {
        setLoading(false);
        dispatch(showMessageError("Chia Data thất bại"));
        setOpenDialogSplitData(false);
      }
    }
  };

  const handleChange = (event) => {
    setData(event.target.value);
  };

  const stateUser = useSelector((state) => state.UsersReducer);

  const userlist = stateUser?.dataUsers?.active_users;


  const randomUserList = userlist?.slice(0, 5).map(function () {
    return this.splice(Math.floor(Math.random() * this.length), 1)[0];
     }, userlist?.slice());
     const arrayUserListById = randomUserList?.map((item) => item.id);



  const handleAutocomplete = (event, newValue) => {
    if (newValue != null) {
      setAutocomplete(newValue.map((el) => el.id));
    }
  };


  return (
    <div>
      <Dialog
        fullWidth
        maxWidth={"xs"}
        open={openDialogSplitData}
        onClose={() => setOpenDialogSplitData(false)}
        style={{ minHeight: "100%" }}
        classes={{
          paper: classes.customModal,
        }}
      >
        <DialogTitle className={`${classes.customModalTitle}`}>
          Chia Data
          <IconButton
            sx={{
              position: "absolute!important",
              right: 8,
              top: 8,
              color: "#fff",
            }}
            onClick={() => setOpenDialogSplitData(false)}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className={`${classes.customModalContent}`}>
          <Loading loading={loading} />
          <Grid container rowSpacing={1} columnSpacing={{ sm: 2 }}>
            <Grid item xs={12}>
            <BoxInput>
                    <TitleInput>Chia Dữ Liệu</TitleInput>
                <Select
                  style={{ fontSize: 14 }}
                  labelId="splitdata"
                  fullWidth
                  size="small"
                  label="Chia Dữ Liệu"
                  name="splitdata"
                  value={data}
                  onChange={handleChange}
                >
                  {RANDOMDATA.map((data, index) => (
                    <MenuItem key={index} value={data.value}>
                      {data.label}
                    </MenuItem>
                  ))}
                </Select>
              </BoxInput>
            </Grid>
            {data === false ? (
              <>
                <Grid item xs={12}>
                  <BoxInput>
                    <TitleInput>Nhân viên</TitleInput>
                    <Autocomplete
                      multiple
                      id="id_users"
                      options={userlist}
                      getOptionLabel={(option) => option.first_name}
                      onChange={handleAutocomplete}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Nhân viên"
                          variant="standard"
                          fullWidth
                        />
                      )}
                    />
                  </BoxInput>
                </Grid>
                <Grid item xs={12}></Grid>
              </>
            ) : (
              null
            )}

            <Grid item xs={12}>
              <CustomButton
                // color="primary"
                variant="contained"
                type="submit"
                disabled={selected.length <= 0 ? true : false}
                onClick={handleChangeStatus}
                style={{ marginLeft: "auto" }}
              >
                Chia Data
              </CustomButton>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default DiaLogSplitData;
