import { Backdrop, Box, CircularProgress } from '@mui/material';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { getLogoFooter } from "../../../redux/logoFooter/action";
import logo from "./../../../assets/images/logo.png"

const Loading = ({ loading }) => {
    const dispatch = useDispatch();
    const dataLogoFooter = useSelector((item) => item.dataLogoFooterRuducer.data);
    useEffect(() => {
        dispatch(getLogoFooter());
         // eslint-disable-next-line
      }, []);

      useEffect(() => {
        // console.log(dataLogoFooter)
         // eslint-disable-next-line
      }, [dataLogoFooter])
    
    return (
        <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loading}
        >
            {/* require("./../../../assets/images/logo.png") */}
            <Box sx={{ m: 1, position: "relative" }}>
                {dataLogoFooter?.org?.logo && (
                    <div
                        className="loading-logo"
                        alt=""
                        style={dataLogoFooter?.org?.logo ? {
                            backgroundImage: `url(${logo})`,
                            backgroundSize: "contain", backgroundRepeat: "no-repeat"
                        } : {
                            backgroundImage: `url(${logo})`,
                            backgroundSize: "contain", backgroundRepeat: "no-repeat"
                        }}
                    />
                )}
                <CircularProgress
                    size={70}
                    sx={{
                        position: "absolute",
                        top: -14,
                        left: -16,
                        zIndex: 1,
                    }}
                    color="inherit"
                />
            </Box>
        </Backdrop>
    );
};

export default Loading;