import { Dialog, DialogContent, DialogTitle, FormControl, IconButton, InputLabel, MenuItem, Select } from '@mui/material';
import CloseIcon from "@mui/icons-material/Close";
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axiosInstance from '../../../../components/axiosApi';
import { CustomButton } from '../../../../components/Common/CustomButton';
import Loading from '../../../../components/Common/Loading';
import { showMessageError, showMessageSuccess } from '../../../../redux/notification/action';
import { makeStyles } from "@material-ui/core/styles";
import { getCustomerStart } from '../../../../redux/customers/action';

const useStyles = makeStyles((theme) => ({
    customModalContent:{
      paddingLeft:'15px',
      paddingRight:'15px'
    },
    customModal:{
      width:'calc(100% - 20px)',
      marginLeft:'10px',
      marginRight:'10px'
    },
    customModalTitle:{
      padding:'10px 15px',
      color:'#fff',
      background:process.env.NODE_ENV === 'development' ? "#5a8dee" : process.env.REACT_APP_PRODUCT_BASE_COLOR
    }
  }));

const DiaLogChangeStatus = ({setOpenDialogChangeStatus, openDialogChangeStatus, selected, setSelected}) => {
    const classes = useStyles();
    const stateDataTrangThai = useSelector((state) => state.trangThaiDataReducer)
    const [trangthai, setTrangthai] = useState("")
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)

    const handleChangeStatus = async () => {
        if(selected.length <= 0) {
            dispatch(showMessageError("Không có dữ liệu khách hàng nào được chọn"))
            return
        } else if (trangthai === "") {
            dispatch(showMessageError("Vui lòng chọn trạng thái muốn thay đổi"))
            return
        } else {
            let data = {
                offset: 0,
                limit: 10,
              };
            try {
                setLoading(true)
                const res = await axiosInstance.post(`/leads/change-status/?m_leads={"m_lead":[${selected}]}&id_status=${trangthai}`)
                if(res.status === 200) {
                    dispatch(showMessageSuccess("Cập nhập trạng thái thành công"))
                    dispatch(getCustomerStart(data));
                    setOpenDialogChangeStatus(false)
                    setLoading(false)
                    setSelected([])
                }
            } catch (error) {
                setLoading(false)
                dispatch(showMessageError("Cập nhập trạng thái thất bại"))
                setOpenDialogChangeStatus(false)
            }
        }
    }

    const handleChangeTrangThaiData = (event) => {
        setTrangthai(event.target.value)
    }

    return (
        <div>
            
            <Dialog
                fullWidth
                maxWidth={"xs"}
                open={openDialogChangeStatus}
                onClose={() => setOpenDialogChangeStatus(false)}
                style={{ minHeight: "100%" }}
                classes={{
                    paper: classes.customModal
                }}
            >
                    <DialogTitle className={`${classes.customModalTitle}`}>
                        Chuyển trạng thái
                        
                        <IconButton
                            sx={{
                                position: 'absolute!important',
                                right: 8,
                                top: 8,
                                color: '#fff',
                            }}
                            onClick={() => setOpenDialogChangeStatus(false)}
                        >
                        <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent className={`${classes.customModalContent}`}>
                        <Loading loading={loading} />
                        <div style={{ display: "flex", width: "100%", margin: "20px 0" }}>
                            <FormControl fullWidth>
                                <InputLabel id="dataStatus">Chọn trạng thái</InputLabel>
                                <Select
                                    labelId="dataStatus"
                                    fullWidth
                                    size="small"
                                    name="dataStatus"
                                    label="Chọn trạng thái"
                                    value={trangthai}
                                    // value={formik.values.dataStatus}
                                    onChange={handleChangeTrangThaiData}
                                >
                                    {
                                        stateDataTrangThai.dataTrangThai.length > 0 ? stateDataTrangThai.dataTrangThai.map((item, index) => {
                                            return (
                                                <MenuItem key={index} value={item.id}>{item.title}</MenuItem>
                                            )
                                        }) : (<MenuItem value={-99}>--NO DATA--</MenuItem>)
                                    }
                                </Select>
                            </FormControl>
                        </div>
                        <CustomButton
                            // color="primary"
                            variant="contained"
                            type="submit"
                            onClick={handleChangeStatus}
                            disabled={selected.length <= 0 || trangthai === "" ? true : false}
                            style={{marginLeft:'auto'}}
                        >
                            Chuyển Trạng Thái
                        </CustomButton>
                    </DialogContent>
                </Dialog>
        </div>
    );
};

export default DiaLogChangeStatus;