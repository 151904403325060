import {
  Grid,
  TextareaAutosize,
  TextField,
  Checkbox
} from "@mui/material";
import { BoxInput, TitleInput } from "./styled";
import React, { useState } from "react";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import * as yup from "yup";

import { CustomButton } from "../../../../components/Common/CustomButton";
import { red } from "@mui/material/colors";
import { editCompany } from "../../../../redux/company/action";
import { getLogoFooter } from "../../../../redux/logoFooter/action";
const colorError = red[500];
const EmployeePbx = ({ setUDO, data }) => {
  
  const [logoEdit, setLogoEdit] = useState('');
  const [nameEdit, setNameEdit] = useState(data?.org?.name);
  const [phoneEdit, setPhoneEdit] = useState(data?.org?.phone);
  const [addressEdit, setAddressEdit] = useState(data?.org?.address);
  const [limitEdit, setLimitEdit] = useState(data?.org?.user_limit);
  const [pbxUserName, setPbxUserName] = useState(data.org?.pbx_username);
  const [pbxPwd, setPbxPwd] = useState(data?.org?.pbx_password);
  const [enablePhone, setEnablePhone] = useState(data?.org?.hide_number)

  const dispatch = useDispatch();
  
  const validationSchema = yup.object({
    
  });


  const formik = useFormik({
    initialValues: {
      id:data?.org?.id,
      code:data?.org?.code,
      name: nameEdit,
      phone: phoneEdit,
      address: addressEdit,
      user_limit: limitEdit,
      logo:logoEdit,
      pbx_username:pbxUserName,
      pbx_password:pbxPwd,
      hide_number:enablePhone,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      dispatch(editCompany(values));
      dispatch(getLogoFooter());
      setUDO(false);
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <boxContainer>
        <Grid container columnSpacing={{ xs: 1 }}>
          <Grid item xs={12}>
            <BoxInput>
              <TitleInput>
                Tên doanh nghiệp <span style={{ color: colorError }}>*</span>
              </TitleInput>
              <TextField
                id="name"
                name="name"
                size="small"
                fullWidth
                // placeholder="Tên doanh nghiệp"
                value={nameEdit}
                onChange={(e) => {
                  formik.setFieldValue("name", e.currentTarget.value);
                  setNameEdit(e.currentTarget.value);
                }}
              />
            </BoxInput>
          </Grid>

          <Grid item xs={12}>
            <BoxInput>
              <TitleInput>
                Số điện thoại <span style={{ color: colorError }}>*</span>
              </TitleInput>
              <TextField
                id="phone"
                name="phone"
                size="small"
                fullWidth
                // placeholder="Số điện thoại"
                value={phoneEdit}
                  onChange={(e) => {
                    formik.setFieldValue("phone", e.currentTarget.value);
                    setPhoneEdit(e.currentTarget.value);
                  }}
              />
            </BoxInput>
          </Grid>

          <Grid item xs={12}>
            <BoxInput>
              <TitleInput>
                Địa chỉ <span style={{ color: colorError }}>*</span>
              </TitleInput>
              <TextareaAutosize
                className="text-diachi"
                id="address"
                name="address"
                fullWidth
                minRows={3}
                // placeholder="Địa chỉ"
                value={addressEdit}
                  onChange={(e) => {
                    formik.setFieldValue("address", e.currentTarget.value);
                    setAddressEdit(e.currentTarget.value);
                  }}
              />
            </BoxInput>
          </Grid>
          <Grid item xs={12}>
            <BoxInput>
              
              <TextField
                id="user_limit"
                name="user_limit"
                size="small"
                fullWidth
                hidden
                // placeholder="Địa chỉ"
                value={limitEdit}
                onChange={(e) => {
                    formik.setFieldValue("hide_number", e.currentTarget.value);
                    setLimitEdit(e.currentTarget.value);
                  }}
              />
            </BoxInput>
          </Grid>
          <Grid item xs={12}>
            <BoxInput>
              <TitleInput>
                Tên đăng nhập tổng đài 
              </TitleInput>
              <TextField
                id="name"
                name="name"
                size="small"
                fullWidth
                // placeholder="Tên doanh nghiệp"
                value={pbxUserName}
                  onChange={(e) => {
                    formik.setFieldValue("pbx_username", e.currentTarget.value);
                    setPbxUserName(e.currentTarget.value);
                  }}
                error={formik.touched.pbx_username && Boolean(formik.errors.pbx_username)}
              />
            </BoxInput>
          </Grid>
          <Grid item xs={12}>
            <BoxInput>
              <TitleInput>
                Mật khẩu tổng đài
              </TitleInput>
              <TextField
                id="pbx_password"
                name="pbx_password"
                size="small"
                type="password"
                fullWidth
                value={pbxPwd}
                  onChange={(e) => {
                    formik.setFieldValue("pbx_password", e.currentTarget.value);
                    setPbxPwd(e.currentTarget.value);
                  }}
              />
            </BoxInput>
          </Grid>
          <Grid item xs={12}>
          <BoxInput style={{display:'flex'}}>
            <TitleInput>Hiện thị SĐT Khách Hàng</TitleInput>
            
              <Checkbox style={{marginTop:'-12px'}}
                id="hide_number"
                name="hide_number"
                checked={enablePhone}
                onChange={(e) => {
                  formik.setFieldValue(
                    "hide_number",
                    e.currentTarget.checked
                  );
                  setEnablePhone(e.currentTarget.checked);
                }}
                />
          </BoxInput>
          </Grid>
          <Grid item xs={12}>
            <BoxInput>
              <CustomButton
                variant="contained"
                type="submit"
                onClick={formik.handleSubmit}
                style={{marginLeft:'auto'}}
              >
                Cập nhập
              </CustomButton>
            </BoxInput>
          </Grid>
        </Grid>
      </boxContainer>
    </form>
  );
};

export default EmployeePbx;
