import * as types from './type'

const initialState = {
    data: [],
    loading: false,
    dataId: [],
    loadingId: false
}

export const dataLeadStatusRuducer = (state = initialState, action) => {

    switch (action.type) {

        case types.GET_LEAD_STATUS: 
            return {
                ...state,
                ...{ loading: true }
            }
        
        case types.GET_LEAD_STATUS_SUCCESS:
            return {
                ...state,
                ...{
                    data: action.payload.sources,
                    loading: false
                }
            }
        
        case types.GET_LEAD_STATUS_FAIL:
            {
                return {
                    ...state,
                    ...{ data: [], loading: false }
                }
            }

        case types.GET_LEAD_STATUS_ID:
            {
                return {
                    ...state,
                    ...{ dataId: [], loadingId: false }
                }
            }
        case types.GET_LEAD_STATUS_ID_SUCCESS:
            // console.log(action.payload);
                return {
                    ...state,
                    ...{ dataId: action.payload.status, loadingId: false }
                }
        case types.GET_LEAD_STATUS_ID_FAIL:
            {
                return {
                    ...state,
                    ...{ dataId: [], loadingId: false }
                }
            }

        // case types.CREATE_LEAD_STATUS_ID:
        //     console.log(action.payload);
        //         return {
        //             ...state,
        //             loadingId: true,
        //         }
        // case types.CREATE_LEAD_STATUS_ID_SUCCESS:
        //     console.log(action.payload);
        //         return {
        //             ...state,
        //             loadingId: false,
        //         }
        // case types.CREATE_LEAD_STATUS_ID_FAIL:
        //     {
        //         return {
        //             ...state,
        //             ...{ dataId: [], loadingId: false }
        //         }
        //     }
        default:
            return state
    }
}