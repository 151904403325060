import { Button, Dialog, DialogContent, DialogTitle, Divider, IconButton, Paper, Typography,
    Breadcrumbs,
    Link, } from '@mui/material';
import React from 'react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import TableOrder from "./Table";
import CloseIcon from "@mui/icons-material/Close";
import AlertMessage from '../../../../components/Common/Alert';
import Loading from '../../../../components/Common/Loading';
import { Box } from './styled';
import { getLeadStatus } from '../../../../redux/sourceLead/action';
import DialogOrderStatus from './Dialog';


const LeadStatus = () => {

    const dispatch = useDispatch();
    const [userDetailOpen, setUDO] = React.useState(false);
    const loading = useSelector((item) => item.dataLeadStatusRuducer.loading);
    const leadStatus = useSelector((item) => item.dataLeadStatusRuducer.data);
    const loadingId = useSelector((item) => item.dataLeadStatusIdRuducer.loadingId)

    const handleAdd = (stt) => {
        setUDO(true);
    };

    const handleCloseUserDetailDialog = () => {
        setUDO(!userDetailOpen);
    };

    const navigate = (url) => {
        if (url === "/") {
            window.location.href = "/";
        } else {
            window.location.href = "/configuration";
        }
    };

    useEffect(() => {
        dispatch(getLeadStatus())
    }, [loadingId])


    return (
        <Box>
            <Loading loading={loading} />
            {loadingId && <AlertMessage status="success" />}
            <div className="screen">
                {/* <Paper className="user-list">

                    <div className="box-btn-add">
                        <div class="btn-add">
                            <Button
                                variant="outlined"
                                className="search-row-button"
                                onClick={() => {
                                    handleAdd();
                                }}
                            >
                                Thêm mới 1
                            </Button>
                        </div>
                    </div>
                </Paper> */}

                <Breadcrumbs
                sx={{ fontSize: { xs: "14px", sm: "16px" } }}
                aria-label="breadcrumb"
                style={{ marginBottom: 15 }}
                >
                    <Link
                        underline="hover"
                        className="cursor-pointer"
                        color="inherit"
                        onClick={() => {
                        navigate("/");
                        }}
                    >
                        Trang chủ
                    </Link>
                    <Link
                        underline="hover"
                        className="cursor-pointer"
                        color="inherit"
                        onClick={() => {
                        navigate("/configuration");
                        }}
                    >
                        Cấu Hình
                    </Link>
                    <Typography
                        sx={{ fontSize: { xs: "14px", sm: "16px" } }}
                        color="text.primary"
                    >
                        Nguồn đơn hàng
                    </Typography>
                </Breadcrumbs>
                <Paper className="user-list">
                    <TableOrder leadStatus={leadStatus}/>
                </Paper> 
                
                    
               




                {/* <Dialog
                    fullWidth={true}
                    maxWidth={"sm"}
                    open={userDetailOpen}
                    onClose={handleCloseUserDetailDialog}
                    style={{ minHeight: "100%" }}
                >
                    <DialogTitle>Thêm mới mã
                        <IconButton 
                            onClick={() => setUDO(false)}
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                                color: (theme) => theme.palette.grey[500],
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>

                    <DialogContent>
                        <DialogOrderStatus setUDO={setUDO} leadStatus={leadStatus} />
                    </DialogContent>
                </Dialog> */}
            </div>
        </Box>
    );
};

export default LeadStatus;