import { put, takeLatest } from "redux-saga/effects";
import axiosInstance from "../../components/axiosApi";
import { showMessageSuccess } from "../notification/action";
import { getProductsError, getProductsSuccess, postProductsError, postProductsSuccess, putProductsError, putProductsSuccess } from "./action";
import { GET_PRODUCTS_START, POST_PRODUCTS, PUT_PRODUCTS, START_SEARCH_PRODUCTS } from "./type";

function* getProducts({payload}) {
    const { arrayChecked, valueSearch } = payload
    try {
        let params = "?title="
        params += valueSearch ?? ""
        const res = yield axiosInstance.get(`/sales/products/${params}`)
        yield put(getProductsSuccess(arrayChecked, res.data))
    } catch (error) {
        yield put(getProductsError(error))
    }
}

function* postProducts({payload}) {
    try {
        const res = yield axiosInstance.post(`/sales/products/`, payload);
        yield put(postProductsSuccess(res))
        yield put(showMessageSuccess("Thêm mới thành công"))
    } catch (error) {
        yield put(postProductsError(error))
    }
}

function* putProducts({payload}) {
    try {
        const res = yield axiosInstance.put(`/sales/products/`, payload);
        yield put(putProductsSuccess(res))
        yield put(showMessageSuccess("Sửa sản phẩm thành công"))
    } catch (error) {
        yield put(putProductsError(error))
    }
}


export function* watchingGetProducts() {
    yield takeLatest(GET_PRODUCTS_START, getProducts);
    yield takeLatest(START_SEARCH_PRODUCTS, getProducts);
    yield takeLatest(POST_PRODUCTS, postProducts);
    yield takeLatest(PUT_PRODUCTS, putProducts);
}