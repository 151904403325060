import {
  Grid,
  TextareaAutosize,
  TextField,
} from "@mui/material";
import { BoxInput, TitleInput } from "./styled";
import React, { useRef, useState } from "react";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import * as yup from "yup";
import { updateOrgStart } from "../../../../redux/org/action";
import { CustomButton } from "../../../../components/Common/CustomButton";
import { red } from "@mui/material/colors";
import { editCompany } from "../../../../redux/company/action";
import { getLogoFooter } from "../../../../redux/logoFooter/action";
const colorError = red[500];
const EmployeeManager = ({ setUDO, data }) => {
  console.log(data)
  const dispatch = useDispatch();
  const refImg = useRef(null);
  const validationSchema = yup.object({
    name: yup.string().required("Bạn chưa nhập tên"),
  });
  const [img, setImg] = useState(
    `${process.env.REACT_APP_API_URL}${data?.org?.logo}`
  );

  const changeTitle = (event) => {
    const { name, value } = event.target;
    formik.setFieldValue(name, value);
    // setSubStatus(subStatusResponse.data.status)
  };

  const formik = useFormik({
    initialValues: {
      id:data?.org?.id,
      name: data?.org?.name ?? "",
      phone: data?.org?.phone ?? "",
      address: data?.org?.address ?? "",
      pbx_username:data?.org?.pbx_username ?? "",
      pbx_password:data?.org?.pbx_password ?? "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      dispatch(editCompany(values));
      setUDO(false);
      
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <boxContainer>
        <Grid container columnSpacing={{ xs: 1 }}>
          <Grid item xs={12}>
            <BoxInput>
              <TitleInput>
                Tên doanh nghiệp <span style={{ color: colorError }}>*</span>
              </TitleInput>
              <TextField
                id="name"
                name="name"
                size="small"
                fullWidth
                // placeholder="Tên doanh nghiệp"
                value={formik.values.name}
                onChange={changeTitle}
                error={formik.touched.name && Boolean(formik.errors.name)}
              />
            </BoxInput>
          </Grid>

          <Grid item xs={12}>
            <BoxInput>
              <TitleInput>
                Số điện thoại <span style={{ color: colorError }}>*</span>
              </TitleInput>
              <TextField
                id="phone"
                name="phone"
                size="small"
                fullWidth
                // placeholder="Số điện thoại"
                value={formik.values.phone}
                onChange={formik.handleChange}
                error={formik.touched.phone && Boolean(formik.errors.phone)}
              />
            </BoxInput>
          </Grid>

          <Grid item xs={12}>
            <BoxInput>
              <TitleInput>
                Địa chỉ <span style={{ color: colorError }}>*</span>
              </TitleInput>
              <TextareaAutosize
                className="text-diachi"
                id="address"
                name="address"
                fullWidth
                minRows={3}
                // placeholder="Địa chỉ"
                value={formik.values.address}
                onChange={formik.handleChange}
                error={formik.touched.address && Boolean(formik.errors.address)}
              />
            </BoxInput>
          </Grid>

          <Grid item xs={12}>
            <div
              className="box-upload"
              onClick={(e) => refImg && refImg.current.click()}
            >
              {data?.org?.logo !== "" && (
                <div className="box-img">
                  <img style={{ maxWidth: "100%" }} src={img} alt="img"/>
                </div>
              )}
              <input
                type="file"
                style={{ display: "none" }}
                name="logo"
                ref={refImg}
                onChange={(event) => {
                  formik.setFieldValue(
                    "logo",
                    event.currentTarget.files[0]
                  );
                  setImg(URL?.createObjectURL(event.currentTarget.files[0]));
                }}
              />
              <div className="btn-editImg">
                <span>Sửa</span>
              </div>
              {/* {img && <img 
                                            style={{ width: 152, height: 152, position: 'absolute', objectFit: 'cover' }} 
                                            src={URL?.createObjectURL(img) ?? "ddd"} 
                                />} */}
            </div>
          </Grid>
          <Grid item xs={12}>
            <BoxInput>
              <CustomButton
                variant="contained"
                type="submit"
                onClick={formik.handleSubmit}
                style={{marginLeft:'auto'}}
              >
                Cập nhập
              </CustomButton>
            </BoxInput>
          </Grid>
        </Grid>
      </boxContainer>
    </form>
  );
};

export default EmployeeManager;
