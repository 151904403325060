import React from "react";

const PhoneBox = ({ setShowPhone, setShowDialogCall, showDialogCall }) => {
  console.log(showDialogCall)
  return (
    <>
      <div
        onClick={() => {
          setShowDialogCall(true);
          setShowPhone(true);
        }}
        className="phonering-alo-phone phonering-alo-green phonering-alo-show"
        id="phonering-alo-phoneIcon"
        style={{ right: 290, top: -3, position: "fixed", cursor: "pointer" }}
      >
        <div className="phonering-alo-ph-circle"></div>
        <div className="phonering-alo-ph-circle-fill"></div>
        <a></a>
        <div className="phonering-alo-ph-img-circle">
          <a></a>
          <a className="pps-btn-img " title="Liên hệ">
            <img
              src="https://i.imgur.com/v8TniL3.png"
              alt="Liên hệ"
              width="50"
            />
          </a>
        </div>
      </div>
      <a></a>
    </>
  );
};

export default PhoneBox;
