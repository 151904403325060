import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableContainer,
  TableRow,
  Typography,
  Paper,
  TablePagination
} from "@mui/material";
import Loading from "../../components/Common/Loading";
import LastPageIcon from "@mui/icons-material/LastPage";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import { CustomButton } from "../../components/Common/CustomButton";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import { useTheme } from "@mui/material/styles";
import MuiTableCell from "@material-ui/core/TableCell";
import MuiTableHead from "@mui/material/TableHead";
import AddRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import SmsUpload from "./SmsUpload";
import {  getSmsbot } from "../../redux/smsbots/action";

import moment from "moment";
import "moment/locale/vi";
 
const useStyles = makeStyles((theme) => ({
  tableBorder: {
    border: "1px solid #d4d8dd",
    borderRadius: "0.5rem",
  },
  btnGlobal: {
    minWidth: "inherit",
    width: 28,
    padding: "0",
    height: "28px",
    lineHeight: "28px",

    marginLeft: 5,
    boxShadow: "none",
    fontSize: "12px",
    fontWeight: "500",
  },
  Pagination: {
    display: "flex",
  },
}));

const TableCell = withStyles({
  root: {
    borderBottom: "1px solid rgb(235, 237, 242)",
    padding: "13px 10px",
    lineHeight: "18px",
  },
})(MuiTableCell);

const TableHead = withStyles((theme) => ({
  root: {
    backgroundColor: "rgba(38,60,85,.1)",
  },
}))(MuiTableHead);

const TableHeaderCell = withStyles((theme) => ({
  root: {
    color: "#516377",
    fontWeight: 700,
    padding: "10px",
    fontSize: ".75rem",
    textTransform: "uppercase",
    letterSpacing: "0.8px",
    lineHeight: "18px",
  },
}))(TableCell);

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const SmsBots = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(true);
  const [dialogImp, setDialogImp] = useState(false);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);


  const handleChangePage = (event, newPage) => {
      let data = {
       
        offset: newPage * rowsPerPage,
        limit: rowsPerPage,
      };
      dispatch(getSmsbot(data));
      setPage(newPage);
    };
  
    const handleChangeRowsPerPage = (event) => {
      let data = {
        
        offset: page,
        limit: event.target.value !== "-1" ? +event.target.value : 9999,
      };
      dispatch(getSmsbot(data));
      setRowsPerPage(+event.target.value);
      setPage(0);
    };

  useEffect(() => {
    setTimeout(() => setLoading(false), 500);
    dispatch(getSmsbot());
    // eslint-disable-next-line
  }, []);



  const listSmsbot = useSelector(
    (item) => item.smsbotReducer.dataList.package
  );




const [dataImp, setDataImp] = useState({
  cols: [],
  rows: [],
  errorMessage: null,
});


function cutText(text){
  var remove_after= text?.indexOf('_');
    if (remove_after  > -1){
        return text?.substring(0, remove_after)
    } return text
}



  return (
   <>
     <Loading loading={isLoading} />
     
      
      <Paper className="order-list" style={{ marginBottom: "10px" }}>
        <div className="ul-heading-placeholder">
          <span
            className="ul-heading"
            style={{ display: "flex", flex: "inherit", fontWeight: 500 }}
          >
            Danh sách
          </span>
          <CustomButton
            variant="contained"
            onClick={() => {
              setDialogImp(true);
            }}
          >
            <AddRoundedIcon style={{ fontSize: "1rem", marginRight: "5px" }} />
            Thêm mới dữ liệu
          </CustomButton>
        </div>
      </Paper>
      {listSmsbot?.list_package?.length > 0 ? (
            <>
     
      <Paper
        sx={{ width: "100%", mb: 2 }}
        elevation={0}
        className={`${classes.tableBorder}`}
      >
        <TableContainer
          style={{
            width: "100%",
            overflowX: "auto",
            borderRadius: "0.5rem",
          }}
        >
          <Table sx={{ minWidth: 700 }} size={"small"}>
            <TableHead>
              <TableRow>
                <TableHeaderCell align="left" style={{ width: 120 }}>
                  STT
                </TableHeaderCell>

                <TableHeaderCell align="left" style={{ minWidth: 40 }}>
                  Tên Dữ Liệu
                </TableHeaderCell>
                
                <TableHeaderCell align="left" style={{ minWidth: 40 }}>
                NGÀY TẠO	
                </TableHeaderCell>
                <TableHeaderCell align="left" style={{ minWidth: 60 }}>
                Người Tạo
                </TableHeaderCell>
                <TableHeaderCell align="left" style={{ minWidth: 60 }}>
                Só lượng
                </TableHeaderCell>
                
              </TableRow>
            </TableHead>
            <TableBody>
            {listSmsbot?.list_package &&
                listSmsbot?.list_package.map((item, index) => (
                  <TableRow hover={true} key={index}>
                    <TableCell align="left">{index + 1}</TableCell>
                    <TableCell align="left">{cutText(item?.name)}</TableCell>
                    
                    <TableCell align="left">{moment(item.created_on).format(
                      "DD/MM/YYYY, H:mm:ss"
                              )}</TableCell>
                    <TableCell align="left">{item?.create_by}</TableCell>
                    <TableCell align="left">
                      
                        {item?.data_count}
                    </TableCell>
                    
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
                  rowsPerPageOptions={[10, 25]}
                  className={`${classes.Pagination}`}
                  component="div"
                  colSpan={3}
                  labelRowsPerPage={"Số hàng trên 1 trang:"}
                  count={listSmsbot?.campaign_count}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: {
                      "aria-label": "rows per page",
                    },
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
      </Paper>
      </>
      ) : (
            <Typography>Không có dữ liệu</Typography>
          )}
      {dialogImp && (
        <SmsUpload
          dataImp={dataImp}
          setDataImp={setDataImp}
          setDialogImp={setDialogImp}
          dialogImp={dialogImp}
        />
      )}
        
    
   </>
  );
};

export default SmsBots;
