import * as types from "./type"

export const getLogoFooter = (data) => (
    {
        type: types.GET_LOGO_FOOTER,
        payload: data
    }
)

export const getLogoFooterSuccess = (data) => ({
    type: types.GET_LOGO_FOOTER_SUCCESS,
    payload: data
})

export const getLogoFooterFail = (data) => ({
    type: types.GET_LOGO_FOOTER_FAIL,
    payload: data
})