import { put, takeLatest } from "redux-saga/effects";
import * as types from './type';
import * as actions from './action';
import axios from "axios";
import axiosInstance from "../../components/axiosApi";
// import { URL_API } from "../../utils/helper";

function* getListAddressFilter({ payload }) {
    const { 
        phone,
        city,
        ward,
        district,
        fullName,
        address,
        dataStatus,
        describeCustomer,
        debt,
        careContent,
        assignTo,
        singleCode,
        transportFee,
        dataOrigin 
    } = payload
     const params = `
        fullname=${fullName}&
        phone=${phone}&
        email=${"email"}&
        assigned_to=${assignTo}&
        city=${city}
        &address=${address}&
        description=${describeCustomer}&
        district=${district}&
        ward=${ward}&
        lead_status=${dataStatus}
    `
    return
    try {
        const res = yield axiosInstance.post(`/leads/?${params}`);
        //console.log({res})
    } catch (error) {
        //console.log(error)
        yield put(actions.getListAddressFail("fail"));
    }
}

export function* watchingGetListAddressFilter() {
    yield takeLatest(types.GET_LIST_ADDRESS, getListAddressFilter);
}