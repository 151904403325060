import React, { useMemo, useEffect, useState } from "react";
import Loading from "../../../../components/Common/Loading";
import {
  Box,
  Table,
  TableBody,
  TableContainer,
  IconButton,
  TablePagination,
  TableRow,
  Grid,
  Paper,
  Breadcrumbs,
  Link
} from "@mui/material";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import PlayCircleFilledRoundedIcon from "@mui/icons-material/PlayCircleFilledRounded";
import PauseCircleFilledRounded from "@mui/icons-material/PauseCircleFilledRounded";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";

import TextField from "@mui/material/TextField";
import Divider from "@mui/material/Divider";
import PropTypes from "prop-types";
import { BoxInput } from "../../../Customer/components/DialogCustomer/styled";
import { CustomButton } from "../../../../components/Common/CustomButton";
import { DateRangePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { useDispatch, useSelector } from "react-redux";

import Typography from "@mui/material/Typography";

import { getCdr } from "../../../../redux/cdr/action";
import { useTheme } from "@mui/material/styles";
import LastPageIcon from "@mui/icons-material/LastPage";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import MuiTableCell from "@material-ui/core/TableCell";
import MuiTableHead from "@mui/material/TableHead";
import { IconPhone, WrapCellPhone } from "./styled";
import Tooltip from "@mui/material/Tooltip";
import { replaceMidd } from "../../../../helpers";
import { updateRegisteringCall, updateUnregisterCall } from "../../../../redux/users/action";
import { startCall } from "../../../../redux/callPhone/action";
const useStyles = makeStyles((theme) => ({
  btnIcon: {
    fontSize: 16,
  },
  iconMenuItem: {
    marginRight: 10,
  },
  btnGlobal: {
    minWidth: "inherit",
    width: 36,
    height: 36,
    lineHeight: 36,
    padding: 0,

    marginLeft: 5,
  },
  tableBorder: {
    border: "1px solid #d4d8dd",
    borderRadius:'0.5rem'
  },

  boxPhone: {
    display: "flex", 
    borderRadius: '0.3rem',
    padding: '3px 5px',
    color: '#5a8dee',
    alignItems: 'center',
    backgroundColor:'#e5edfc',
    cursor:'pointer'
  },
  Pagination: {
    display:'flex',
  }
 
}));


const TableCell = withStyles({
  root: {
    borderBottom: "1px solid rgb(235, 237, 242)",
    padding: "5px 10px",
    lineHeight:'18px',
  },
})(MuiTableCell);

const TableHead = withStyles((theme) => ({
  root: {
    backgroundColor: "rgba(38,60,85,.1)",
    height: '50px',
  },
}))(MuiTableHead);

const TableHeaderCell = withStyles((theme) => ({
  root: {
    color: "#516377",
    fontWeight: 700,
    padding: 10,
    fontSize:'.75rem',
    textTransform:'uppercase',
    letterSpacing: '0.8px',
    lineHeight:'18px'
  },
}))(TableCell);


const URL = `${process.env.REACT_APP_SIPTRUNK_URL}/records`;


function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const useAudio = (url) => {
  const audio = useMemo(() => new Audio(url), [url]);
  const [playing, setPlaying] = useState(false);
  const toggle = () => setPlaying(!playing);

  useEffect(() => {
    playing ? audio.play() : audio.pause();
  }, [playing, audio]);

  useEffect(() => {
    audio.addEventListener("ended", () => setPlaying(false));
    return () => {
      audio.removeEventListener("ended", () => setPlaying(false));
    };
  }, [audio]);

  return [playing, toggle];
};

const Player = ({ url }) => {
  const [playing, toggle] = useAudio(url);

  return (
    <div>
      <button className="btn-play" onClick={toggle}>
        {playing ? (
          <PauseCircleFilledRounded />
        ) : (
          <PlayCircleFilledRoundedIcon />
        )}
      </button>
    </div>
  );
};

const Cdr = ({dataLogoFooter}) => {
  const checkEnablePhone = dataLogoFooter?.org?.hide_number
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  
  const [listSelected, setListSelected] = useState([]);
  
  const [dataPagination, setDataPagination] = useState({offset: 0, limit: 10})
  const [value, setValue] = React.useState([null, null]);
  const initialValueSearch = {
    fromdate: "",
    todate: "",
    number: "",
  };


  const pbx_username = dataLogoFooter?.org?.pbx_username
  const pbx_pwd = dataLogoFooter?.org?.pbx_password


  const [search, setSearch] = useState(initialValueSearch);

  const handleSearch = () => {
    const valueSearch = {
      ...search,
      pbx_username:pbx_username,
      pbx_pwd:pbx_pwd,
      offset: 0,
      limit: 10,
    };
    console.log(valueSearch)
    dispatch(getCdr(valueSearch));
    if (openSidebar) setOpenSidebar(false);
  };
  const [openSidebar, setOpenSidebar] = useState(false);
  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    setSearch({ ...search, [name]: value });
  };

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      getCdr({
        pbx_username:pbx_username,
        pbx_pwd:pbx_pwd,
        offset: 0,
        limit: 10,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const totalCount = 15;
  // useSelector(
  //   (item) => item.companyReducer.dataList
  // ).org_count;

  const listCdr = useSelector((item) => item.dataCdrReducer.data.data);
  const loading = useSelector((item) => item.dataCdrReducer.loading);


  

  const handleChangePage = (event, newPage) => {
    let data = {
      pbx_username:pbx_username,
          pbx_pwd:pbx_pwd,
      offset: newPage * rowsPerPage,
      limit: rowsPerPage,
    };
    dispatch(getCdr(data));
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    let data = {
      pbx_username:pbx_username,
          pbx_pwd:pbx_pwd,
      offset: page,
      limit: event.target.value !== "-1" ? +event.target.value : 9999,
    };
    dispatch(getCdr(data));
    setRowsPerPage(+event.target.value);
    setPage(0);
  };




  const navigate = (url) => {
    if (url === "/") {
      window.location.href = "/";
    } else {
      window.location.href = "/callcenter";
    }
  };

  const [phone, setPhone] = useState(null);
  const [openModalCall, setOpenModalCall] = React.useState(false);
  const dataUser = useSelector((item) => item.UsersReducer);

  const handleOpenModalCall = (phone) => {
     setPhone(phone);
    setOpenModalCall(true);
  };

  const handleCloseModalCall = () => {
    setOpenModalCall(false);
    dispatch(updateUnregisterCall());
  };

  const handleRegistering = () => {
    dispatch(updateRegisteringCall());
  };

  const handleCall = (phone) => {
    dispatch(startCall(phone));
    setOpenModalCall(false);
  };


  return (
    <div className="screen">
       <CustomButton style={{marginBottom:'15px',minWidth:'inherit',pading:'0 6px'}} onClick={() => {
            navigate("/callcenter");
          }}><KeyboardArrowLeftIcon /></CustomButton>
      <Paper className="order-list" style={{ marginBottom: "10px" }}>
        <div className="ul-heading-placeholder">
          <input style={{ display: "none" }} id={""} defaultValue={""} />
          <span
            className="ul-heading"
            style={{ display: "flex", alignItems: "center", fontWeight: 500 }}
          >
            Danh Sách
          </span>
           <div className="box-right">
              <CustomButton
                variant="contained"
                className={`${classes.btnGlobal} ${classes.btnBlue}`}
                onClick={() => setOpenSidebar(!openSidebar)}
              >
                <FilterAltIcon style={{width:'1rem',height:'1rem'}} className={`${classes.btnIcon}`} />
              </CustomButton>
              <div
                className={`boxsearch-sidebar ${openSidebar ? "active" : ""}`}
              >
                <span
                  className="ul-heading"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    fontWeight: 500,
                  }}
                >
                  <ArrowBackIosNewOutlinedIcon
                    style={{
                      color: "#5a8dee",
                      marginRight: 5,
                      cursor: "pointer",
                    }}
                    onClick={() => setOpenSidebar(!openSidebar)}
                  />
                  Tìm kiếm
                </span>
                <Divider sx={{ display: { xs: 'block', sm: 'none' } }}/>
                <Grid
          container
          rowSpacing={1}
          columnSpacing={{ xs: 1,sm: 1.5 }}
          style={{ padding: "0 14px" }}
        >
          <Grid item xs={12}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DateRangePicker
                inputFormat="dd/MM/yyyy"
                startText="Từ ngày"
                endText="Đến ngày"
                value={value}
                onChange={(newValue) => {
                  setValue(newValue);
                  let fromdate = moment(newValue[0]).format("YYYY-MM-DD");
                  let todate = moment(newValue[1]).format("YYYY-MM-DD");

                  let updateSearch = { ...search };
                  updateSearch.fromdate = fromdate;
                  updateSearch.todate = todate;
                  setSearch(updateSearch);
                }}
                renderInput={(startProps, endProps) => (
                  <>
                    <TextField
                      {...startProps}
                      size="small"
                      fullWidth
                      style={{ marginTop: 12 }}
                    />
                    <Box sx={{ mx: 1}}> - </Box>
                    <TextField
                      {...endProps}
                      size="small"
                      fullWidth
                      style={{ marginTop: 12 }}
                    />
                  </>
                )}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12}>
          <BoxInput style={{ maxWidth: "100%",marginTop:'10px' }}>
              <TextField
                style={{ maxWidth: "100%" }}
                id={search.phone}
                name='number'
                size="small"
                fullWidth
                placeholder={"Nhập sdt khách hàng"}
                onChange={handleChangeInput}
              />
            </BoxInput>
          </Grid>
          <Grid item xs={12}>
          <CustomButton style={{width:'40px',height:'40px'}}
                        variant="contained"
                        className="search-row-button"
                        onClick={handleSearch}
                      >
                      Tìm kiếm
                    </CustomButton>
                  </Grid>
        </Grid>
        
              </div>
            </div> 
        </div>
        

        
      </Paper>

      
        <Paper
        sx={{ width: "100%", mb: 2 }}
        elevation={0}
        className={`${classes.tableBorder}`}
      >
          <Loading loading={loading} />
          <TableContainer style={{ width: "100%", overflowX: "auto" ,borderRadius:'0.5rem'}}>
            <Table sx={{ minWidth: 700 }} size={"small"}>
              <TableHead>
                <TableRow>
                  
                  <TableHeaderCell align="left" style={{ width: 80 }}>
                    ID
                  </TableHeaderCell>
                  <TableHeaderCell align="left" style={{ minWidth: 120 }}>
                    Ngày gọi
                  </TableHeaderCell>
                  <TableHeaderCell align="left" style={{ minWidth: 120 }}>
                    Thời gian gọi
                  </TableHeaderCell>
                  <TableHeaderCell align="left" style={{ minWidth: 120 }}>
                    Cuộc gọi (đến - đi)
                  </TableHeaderCell>
                  <TableHeaderCell align="left" style={{ minWidth: 120 }}>
                    Nguồn (callerid)
                  </TableHeaderCell>
                  <TableHeaderCell align="left" style={{ minWidth: 120 }}>
                    Gọi đến
                  </TableHeaderCell>
                  <TableHeaderCell align="left" style={{ minWidth: 120 }}>
                    File ghi âm
                  </TableHeaderCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {listCdr &&
                  listCdr?.length > 0 &&
                  listCdr?.map((item, index) => (
                    <TableRow hover={true} key={index}>
                      
                      <TableCell align="left" style={{ width: 80 }}>
                        {item?.id}
                      </TableCell>
                      <TableCell align="left" style={{ width: 200 }}>
                     
                      {item?.create_time}
                      </TableCell>
                      <TableCell align="left" style={{ width: 120 }}>
                        {item?.duration}
                      </TableCell>
                      <TableCell align="left" style={{ minWidth: 120 }}>
                        {item?.direction}
                      </TableCell>
                      <TableCell align="left" style={{ minWidth: 120 }}>
                        {item?.callerid}
                      </TableCell>
                      <TableCell align="left" style={{ minWidth: 120 }}>
                      {
                        item?.direction === "outgoing" ? <>
                        <WrapCellPhone>
                      <Tooltip title="Gọi ngay số này" arrow>
                        <div
                          className={`${classes.boxPhone}`}
                          onClick={() =>
                                  handleOpenModalCall(item?.destination)
                                }
                        >
                          <IconPhone className="phone-icon">
                            <svg
                              xmlns="http:www.w3.org/2000/svg"
                              aria-hidden="true"
                              role="img"
                              width="18px"
                              height="18px"
                              preserveAspectRatio="xMidYMid meet"
                              viewBox="0 0 1024 1024"
                            >
                              <path
                                fill="currentColor"
                                d="M885.6 230.2L779.1 123.8a80.83 80.83 0 0 0-57.3-23.8c-21.7 0-42.1 8.5-57.4 23.8L549.8 238.4a80.83 80.83 0 0 0-23.8 57.3c0 21.7 8.5 42.1 23.8 57.4l83.8 83.8A393.82 393.82 0 0 1 553.1 553A395.34 395.34 0 0 1 437 633.8L353.2 550a80.83 80.83 0 0 0-57.3-23.8c-21.7 0-42.1 8.5-57.4 23.8L123.8 664.5a80.89 80.89 0 0 0-23.8 57.4c0 21.7 8.5 42.1 23.8 57.4l106.3 106.3c24.4 24.5 58.1 38.4 92.7 38.4c7.3 0 14.3-.6 21.2-1.8c134.8-22.2 268.5-93.9 376.4-201.7C828.2 612.8 899.8 479.2 922.3 344c6.8-41.3-6.9-83.8-36.7-113.8z"
                              />
                            </svg>
                          </IconPhone>

                          <p style={{ margin: 0 }}>
                          {
                                      checkEnablePhone === true ?
                                      (
                                        replaceMidd(item?.destination,0)
                                      ) : (
                                        replaceMidd(item?.destination,3)
                                      )
                                    }
                          </p>
                        </div>
                      </Tooltip>
                    </WrapCellPhone>
                        </>
                        
                        
                        : item?.dst_extension
                      }
                      </TableCell>
                      <TableCell align="left" style={{ minWidth: 120 }}>
                        <Player url={`${URL}${item?.record_file}`} />
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25]}
            className={`${classes.Pagination}`}
            component="div"
            colSpan={3}
            labelRowsPerPage={"Số hàng trên 1 trang:"}
            count={totalCount}
            rowsPerPage={rowsPerPage}
            page={page}
            SelectProps={{
              inputProps: {
                "aria-label": "rows per page",
              },
              native: true,
            }}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            ActionsComponent={TablePaginationActions}
          />

{openModalCall && (
        <div className="pu-phone">
          <div className="text">
            Cuộc gọi sẽ kết nối tới tổng đài. Bạn có muốn thực hiện cuộc gọi đến
            số
            <span style={{ fontWeight: 600 }}>
              {" "}
              {checkEnablePhone === true
                ? replaceMidd(phone, 0)
                : replaceMidd(phone, 3)}
            </span>
          </div>
          <div style={{ display: "flex", marginTop: 5 }}>
            {dataUser && dataUser.registered === "unregistered" && (
              <CustomButton
                // color="primary"
                variant="contained"
                type="submit"
                onClick={() => handleRegistering()}
                style={{ marginLeft: "auto" }}
              >
                Đồng ý
              </CustomButton>
            )}
            {dataUser && dataUser.registered === "registered" && (
              <CustomButton
                // color="primary"
                variant="contained"
                type="submit"
                onClick={() => handleCall(phone)}
                style={{ marginLeft: "auto" }}
              >
                Gọi
              </CustomButton>
            )}
            <CustomButton
              // color="primary"
              variant="contained"
              type="submit"
              style={{ marginLeft: 10 }}
              onClick={handleCloseModalCall}
            >
              Kết thúc
            </CustomButton>
          </div>
        </div>
      )}

          </Paper>

    </div>
  );
};

export default Cdr;
