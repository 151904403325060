import { put, takeLatest } from "redux-saga/effects";
import * as types from './type';
import * as actions from './action';
import axiosInstance from "../../components/axiosApi";



function* createPbx({ payload }) {
  try {
    const res = yield axiosInstance.post(`/pbx/company/`, payload)
    yield put(actions.createPbxSuccess(res.data))
  } catch (error) {
    yield put(actions.createPbxFail(error))
  }
}


function* resetMessage() {
  yield put(actions.resetMessageError({}))
}

export function* watchingPbx() {
  yield takeLatest(types.CREATE_PBX, createPbx);
  yield takeLatest(types.RESET_MESSAGE, resetMessage);
}
