import { Snackbar } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { resetNotification } from "../../../redux/notification/action";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

const AlertMessage = ({ status, message }) => {
    const [open, setOpen] = React.useState(true);
    const dispatch = useDispatch()
    const stateNotification = useSelector((state) => state.notificationReducer)

    // const handleClose = (event, reason) => {
    //     if (reason === 'clickaway') {
    //       return;
    //     }
    //     setOpen(false);
    //     dispatch(resetNotification())
    // };

    useEffect(() => {
        setTimeout(() => {
            dispatch(resetNotification())
        }, 3000);
    }, [stateNotification.showStatus])

    return (
        <>
            {stateNotification.showStatus && (
                <Snackbar open={stateNotification.showStatus} autoHideDuration={6000} >
                    <Alert  severity={stateNotification.type} sx={{ width: '100%' }}>
                    {stateNotification.message}
                    </Alert>
                </Snackbar>
            )}
        </>
    );
};

export default AlertMessage;