import React, { useEffect, useState } from "react";
import Loading from "../../components/Common/Loading";
import {
  Table,
  TableBody,
  TableContainer,
  TableRow,
} from "@mui/material";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import Paper from "@mui/material/Paper";
import { CustomButton } from "../../components/Common/CustomButton";
import { useDispatch, useSelector } from "react-redux";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";

import { makeStyles } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import MuiTableCell from "@material-ui/core/TableCell";
import MuiTableHead from "@mui/material/TableHead";
import AddRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import SmsTempCreate from "./SmsTempCreate";
import SmsTempEdit from "./SmsTempEdit";
import SmsTempDel from "./SmsTempDel";
import { deleteSmstemplate, getSmstemplate } from "../../redux/smstemplate/action";

const useStyles = makeStyles((theme) => ({
  tableBorder: {
    border: "1px solid #d4d8dd",
    borderRadius: "0.5rem",
  },
  btnGlobal: {
    minWidth: "inherit",
    width: 28,
    padding: "0",
    height: "28px",
    lineHeight: "28px",

    marginLeft: 5,
    boxShadow: "none",
    fontSize: "12px",
    fontWeight: "500",
  },
  btnBlue: { backgroundColor: "#556ee6" },
  // btnGreen:{
  //   backgroundColor: '#34c38f',
  // },
  btnYellow: {
    backgroundColor: "#f1b44c",
  },
  btnRed: {
    backgroundColor: "#f46a6a",
  },
}));

const TableCell = withStyles({
  root: {
    borderBottom: "1px solid rgb(235, 237, 242)",
    padding: "5px 10px",
    lineHeight: "18px",
  },
})(MuiTableCell);

const TableHead = withStyles((theme) => ({
  root: {
    backgroundColor: "rgba(38,60,85,.1)",
  },
}))(MuiTableHead);

const TableHeaderCell = withStyles((theme) => ({
  root: {
    color: "#516377",
    fontWeight: 700,
    padding: "10px",
    fontSize: ".75rem",
    textTransform: "uppercase",
    letterSpacing: "0.8px",
    lineHeight: "18px",
  },
}))(TableCell);

const SmsTemplate = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(true);
  const [openCreateSmsTemp, setOpenCreateSmsTemp] = useState(false);
  const [openEditSmsTemp, setOpenEditSmsTemp] = useState(false);
  const [openDelSmsTemp, setOpenDelSmsTemp] = useState(false);
  const [item, setItem] = useState([]);
  const [listSelected, setListSelected] = useState([]);


  
  useEffect(() => {
    setTimeout(() => setLoading(false), 500);
    dispatch(getSmstemplate());
    // eslint-disable-next-line
  }, []);

  const listSmstemp = useSelector(
    (item) => item.smstemplateReducer.dataList.temp_cfg
  );

  const delSuccess = useSelector(
    (item) => item.smstemplateReducer.deleteLoad
  );

  const handleDel = (id) =>{
      dispatch(deleteSmstemplate(id))
      setOpenDelSmsTemp(false);
      
  }

  useEffect(() => {
    
      dispatch(getSmstemplate());
    
    // eslint-disable-next-line
  }, [delSuccess])
  

  const handleEdit = (item) => {
    setItem(item);
    setOpenEditSmsTemp(true);
    
  };

  const handleCloseEditSmsTemp = () => {
    setOpenEditSmsTemp(false);
  };
  const handleCloseDelSmsTemp = () => {
    setOpenDelSmsTemp(false);
  };

  const navigate = (url) => {
    if (url === "/") {
      window.location.href = "/";
    } else {
      window.location.href = "/configuration";
    }
  };
  return (
<>
<Loading loading={isLoading} />
    <div className="screen modify-label">
    <CustomButton style={{marginBottom:'15px',minWidth:'inherit',pading:'0 6px'}} onClick={() => {
            navigate("/configuration");
          }}><KeyboardArrowLeftIcon /></CustomButton>
      <Paper className="order-list" style={{ marginBottom: "10px" }}>
        <div className="ul-heading-placeholder">
          <span
            className="ul-heading"
            style={{ display: "flex", flex: "inherit", fontWeight: 500 }}
          >
            Danh sách
          </span>
          <CustomButton
            variant="contained"
            onClick={() => {
              setOpenCreateSmsTemp(true);
            }}
          >
            <AddRoundedIcon style={{ fontSize: "1rem", marginRight: "5px" }} />
            Tạo mẫu SMS
          </CustomButton>
        </div>
      </Paper>

      {/* <Loading loading={loading} /> */}
      <Paper
        sx={{ width: "100%", mb: 2 }}
        elevation={0}
        className={`${classes.tableBorder}`}
      >
        <TableContainer
          style={{
            width: "100%",
            overflowX: "auto",
            borderRadius: "0.5rem",
          }}
        >
          <Table sx={{ minWidth: 700 }} size={"small"}>
            <TableHead>
              <TableRow>
                <TableHeaderCell align="left" style={{ width: 120 }}>
                  STT
                </TableHeaderCell>

                <TableHeaderCell align="left" style={{ minWidth: 40 }}>
                  Tên
                </TableHeaderCell>
                
                <TableHeaderCell align="left" style={{ minWidth: 40 }}>
                  Nội dung
                </TableHeaderCell>

                <TableHeaderCell align="left" style={{ minWidth: 60 }}>
                Trạng Thái
                </TableHeaderCell>
                <TableHeaderCell align="left" style={{ minWidth: 120 }}>
                  Hành động
                </TableHeaderCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {listSmstemp &&
                listSmstemp.map((item, index) => (
                  <TableRow hover={true} key={index}>
                    <TableCell align="left">{index + 1}</TableCell>
                    <TableCell align="left">{item?.name}</TableCell>
                    
                    <TableCell align="left">{item?.msg_tem}</TableCell>

                    <TableCell align="left">
                      {item?.activate === false ? "Chờ Duyệt" : "Đang sử dụng"}
                    </TableCell>
                    <TableCell align="left">
                      <div style={{ display: "flex", marginBottom: "5px" }}>
                        <Tooltip title="Chỉnh sửa">
                          <CustomButton
                            variant="contained"
                            className={`${classes.btnGlobal} ${classes.btnYellow}`}
                            onClick={() => {
                             
                             handleEdit(item);
                             setListSelected(item?.id);
                           }}
                          >
                            <i
                              style={{ fontSize: "10px" }}
                              className="fas fa-pencil-alt"
                            ></i>
                          </CustomButton>
                        </Tooltip>
                        <Tooltip title="Xoá">
                          <CustomButton
                            variant="contained"
                            className={`${classes.btnGlobal} ${classes.btnRed}`}
                            onClick={() => {
                              setOpenDelSmsTemp(true);
                              setListSelected(item?.id);
                            }}
                          >
                            <i
                              style={{ fontSize: "10px" }}
                              className="fas fa-trash"
                            ></i>
                          </CustomButton>
                        </Tooltip>
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      {openCreateSmsTemp && (
        <SmsTempCreate
          onClose={() => {
            setOpenCreateSmsTemp(false);
          }}
        />
      )}
      {openEditSmsTemp && (
        <SmsTempEdit
          setUDO1={setOpenEditSmsTemp}  
          item={item} 
          onClose={handleCloseEditSmsTemp} 
          idTemp={listSelected}
        />
      )}
      {openDelSmsTemp && (
        <SmsTempDel 
          setUDO1={setOpenDelSmsTemp}  
          onDel={handleDel}
          idTemp={listSelected}
          onClose={handleCloseDelSmsTemp} 
        />
      )}
    </div>
</>
  );
};

export default SmsTemplate;
