import * as types from "./type";

const initialState = {
  loading: false,
  data:[],
  dataUsers: [],
  creLoading: false,
  registered: "unregistered",
};

export const UsersReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_USERS_START:
      return {
        ...state,
        loading: true,
      };
    case types.GET_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        dataUsers: action.payload,
      };
    case types.GET_USERS_ERROR:
      return {
        ...state,
        loading: false,
      };
    //
    case types.CREATE_USERS_START:
      return {
        ...state,
        creLoading: true,
      };

    case types.CREATE_USERS_SUCCESS:
      return {
        ...state,
        data: action.payload,
        creLoading: false,
      };
      case types.CREATE_USERS_ERROR:
        return {
          ...state,
          data: action.payload.response.data,
          creLoading: false,
        };

    // update user
    case types.UPDATE_USERS:
      return {
        ...state,
        creLoading: true,
      };

    case types.UPDATE_USERS_SUCCESS:
      return {
        ...state,
        creLoading: false,
      };

    // update register
    case types.UPDATE_REGISTER_START:
      return {
        ...state,
        registered: false,
      };
    case types.UPDATE_REGISTER_CALL:
      return {
        ...state,
        registered: "registered",
      };

    case types.UPDATE_REGISTERING_CALL:
      return {
        ...state,
        registered: "registering",
      };

    case types.UPDATE_UNREGISTER_CALL:
      return {
        ...state,
        registered: "unregistered",
      };

    default:
      return state;


  }
};
