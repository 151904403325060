import * as types from './type'

const initialState = {
    loading: false,
    products: [],
    statusAdd: false,
    putLoad: false,
}

export const productReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_PRODUCTS_START: 
            return {
                ...state,
                loading: true,
            }
        
        case types.GET_PRODUCTS_SUCCESS:
            const { arrayChecked, responseProducts } = action.payload
            let newArrProducts = responseProducts.products.map(item => ({...item, checked: false, quantity: 1}))
            if (arrayChecked.length > 0) {
                newArrProducts.map((item, index) => {
                    let checked = arrayChecked.findIndex(itemChecked => itemChecked.id === item.id)
                    if(checked !== -1) {
                        newArrProducts[index] = {
                            ...newArrProducts[index], 
                            checked: true, 
                            quantity: arrayChecked[index].quantity
                        }
                    }
                })
            }
            return {
                ...state,
                loading: false,
                products: newArrProducts,
            }
        case types.CHECKED_PRODUCTS: 
            return {
                ...state,
            }
        case types.GET_PRODUCTS_ERROR:
            return {
                ...state,
                loading: false,
            }
        case types.START_SEARCH_PRODUCTS:
            return {
                ...state,
                loading: true
            }
// them
        case types.POST_PRODUCTS:
            return {
                ...state,
                loading: true,
                statusAdd: false,
            }
        case types.POST_PRODUCTS_SUCCESS:
            return {
                ...state,
                loading: false,
                statusAdd: true,
            }
//sua
        case types.PUT_PRODUCTS:
            return {
                ...state,
                loading: true,
                putLoad: true,
            }
        case types.PUT_PRODUCTS_SUCCESS:
            return {
                ...state,
                loading: false,
                putLoad: false
            }
        default:
            return state
    }
}