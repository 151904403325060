import { memo, useEffect, useState } from "react";
import { TextField } from "@mui/material";
import { BoxOrder, ItemGrid } from "./styled";
import { renderWithCommand } from "../../../../helpers";
import { useFormik } from "formik";
import * as Yup from "yup";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  textFieldError: {
    "& input": {
      border: "1px solid red!important",
      color: "red",
      height: "max-content!important",
      width: "max-content!important",
      margin: "16.5px 0",
      padding: "0 14px!important",
    },
    "& p": {
      color: "red",
    },
  },
}));

const FinalOrder = ({
  setLastestOrder,
  lastestOrder,
  dataProducts,
  detailCustomer,
}) => {
  const classes = useStyles();

  

  const discountSchema = Yup.object({
    discount: Yup.number()
      .typeError('"Discount phải là số"')
      .max(lastestOrder[0].currentQuantity, "Discount phải nhỏ hơn tổng tiền")
      .min(0, "Discount phải lớn hơn 0"),

      vat: Yup.number()
      .typeError('"Vat phải là số"')
      .max(100, "Vat phải nhỏ 100%")
      .min(0, "Vat phải lớn hơn 0"),  
  });

  const { values, handleBlur, touched, errors, setFieldValue, handleChange } =
    useFormik({
      validationSchema: discountSchema,
      initialValues: {
        discount: "",
        vat:'',
      },
      validateOnChange: true,
    });

  const handleChangePromotion = (event) => {
    const updateLastestOrder = [...lastestOrder];
    updateLastestOrder[0].promotion =+event.target.value;

    if(values.vat < 100 && values.vat !== ''){
      updateLastestOrder[0].total = (lastestOrder[0].currentQuantity *  (lastestOrder[0].vat)/100) +
      lastestOrder[0].currentQuantity - +event.target.value ;
      setLastestOrder(updateLastestOrder);
    } else {
      updateLastestOrder[0].total =
      lastestOrder[0].currentQuantity - +event.target.value;
    setLastestOrder(updateLastestOrder);
    }
    
    handleChange(event);
  };

  const handleChangeVat = (event) => {
    const updateLastestOrder = [...lastestOrder];
    updateLastestOrder[0].vat = +event.target.value;

    if(values.discount !== ''){
      
      updateLastestOrder[0].total = ((lastestOrder[0].currentQuantity * ( lastestOrder[0].vat/100)) + lastestOrder[0].currentQuantity) -
      lastestOrder[0].promotion;
    setLastestOrder(updateLastestOrder);
    } else{
      updateLastestOrder[0].total =
    (lastestOrder[0].currentQuantity * ( (+event.target.value)/100)) + lastestOrder[0].currentQuantity;
    setLastestOrder(updateLastestOrder);
    }


    
    handleChange(event);
  };

  useEffect(() => {
    if (dataProducts.length <= 0) return;
    const updateLastestOrder = [...lastestOrder];
    updateLastestOrder[0].quantityProducts = dataProducts.length;
    updateLastestOrder[0].total = dataProducts.reduce(
      (previousValue, currentValue) => previousValue + currentValue.total,
      0
    );
    updateLastestOrder[0].currentQuantity = dataProducts.reduce(
      (previousValue, currentValue) => previousValue + currentValue.total,
      0
    );
    updateLastestOrder[0].total =
      updateLastestOrder[0].currentQuantity - +values.discount + ((updateLastestOrder[0].currentQuantity)*(values.vat/100))
    setLastestOrder(updateLastestOrder);
  }, [dataProducts]);

  useEffect(() => {
    if (dataProducts.length <= 0) return;
    if (detailCustomer &&( (detailCustomer?.detail?.discount) || (detailCustomer?.detail?.vat))) {
      const updateLastestOrder = [...lastestOrder];
      
      updateLastestOrder[0].promotion = +detailCustomer?.detail.discount;
      updateLastestOrder[0].vat = detailCustomer?.detail.vat
      updateLastestOrder[0].total =
        lastestOrder[0].currentQuantity - +detailCustomer?.detail.discount + 
       ( (updateLastestOrder[0].currentQuantity)*(+detailCustomer?.detail.vat)/100);
      setFieldValue("discount", +detailCustomer?.detail?.discount);
      setFieldValue("vat", +detailCustomer?.detail?.vat);
    }
  }, [detailCustomer]);
  return (
    <>
      
        
          <ItemGrid>
            <BoxOrder>
              <span>Tổng cộng: </span>
              <span>{renderWithCommand(lastestOrder[0].currentQuantity)}đ</span>
            </BoxOrder>
            <BoxOrder>
              <span>Số lượng sản phẩm:</span>
              <span>{lastestOrder[0].quantityProducts}</span>
            </BoxOrder>
            <BoxOrder style={{ alignItems: "center" }}>
              <span style={{textAlign:"left"}}>Khuyến mại (đ):</span>
              <TextField
                name="discount"
                inputProps={{
                  style: { textAlign: "right" },
                }}
                value={values.discount}
                onBlur={handleBlur}
                onChange={handleChangePromotion}
                className={
                  errors.discount && touched.discount
                    ? classes.textFieldError
                    : ""
                }
                helperText={
                  touched.discount && errors.discount ? errors.discount : ""
                }
              />
            </BoxOrder>
            <BoxOrder style={{ alignItems: "center" }}>
              <span style={{textAlign:"left"}}>VAT (%):</span>
              <TextField
                name="vat"
                inputProps={{
                  style: { textAlign: "right" },
                }}
                value={values.vat}
                onBlur={handleBlur}
                onChange={handleChangeVat}
                className={
                  errors.vat && touched.vat
                    ? classes.textFieldError
                    : ""
                }
                helperText={
                  touched.vat && errors.vat ? errors.vat : ""
                }
              />
            </BoxOrder>
            
            <BoxOrder>
              <span>Tổng tiền thanh toán:</span>
              <b>{renderWithCommand(lastestOrder[0].total)} đ</b>
            </BoxOrder>
          </ItemGrid>
      
    </>
  );
};

export default memo(FinalOrder);
