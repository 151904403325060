import * as types from "./type"

export const getLeadStatusId = (data) => ({
    type: types.GET_LEAD_STATUS_ID,
    payload: data
})
export const getLeadStatusIdSuccess = (data) => ({
    type: types.GET_LEAD_STATUS_ID_SUCCESS,
    payload: data
})
export const getLeadStatusIdFail = (data) => ({
    type: types.GET_LEAD_STATUS_ID_FAIL,
    payload: data
})

export const createLeadStatusId = (data) => ({
    type: types.CREATE_LEAD_STATUS_ID,
    payload: data
})
export const createLeadStatusIdSuccess = (data) => ({
    type: types.CREATE_LEAD_STATUS_ID_SUCCESS,
    payload: data
})
export const createLeadStatusIdFail = (data) => ({
    type: types.CREATE_LEAD_STATUS_ID_FAIL,
    payload: data
})