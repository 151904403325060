
import * as types from "./type"

export const getSmstemplate = (data) => ({
  type: types.GET_SMSTEMPLATE,
  payload: data
})
export const getSmstemplateSuccess = (data) => ({
  type: types.GET_SMSTEMPLATE_SUCCESS,
  payload: data
})
export const getSmstemplateFail = (data) => ({
  type: types.GET_SMSTEMPLATE_FAIL,
  payload: data
})

// for create company

export const createSmstemplate = (data) => ({
  type: types.CREATE_SMSTEMPLATE,
  payload: data
})

export const createSmstemplateSuccess = (data) => ({
  type: types.CREATE_SMSTEMPLATE_SUCCESS,
  payload: data
})
export const createSmstemplateFail = (data) => ({
  type: types.CREATE_SMSTEMPLATE_FAIL,
  payload: data
})



export const resetMessage = (data) => ({
  type: types.RESET_MESSAGE,
  payload: data
})

export const resetMessageError = (data) => ({
  type: types.RESET_MESSAGE_ERROR,
  payload: data
})

//EDIT
export const editSmstemplate = (data) => ({
  type: types.EDIT_SMSTEMPLATE,
  payload: data
})
export const editSmstemplateSuccess = (data) => ({
  type: types.EDIT_SMSTEMPLATE_SUCCESS,
  payload: data
})
export const editSmstemplateFail = (data) => ({
  type: types.EDIT_SMSTEMPLATE_FAIL,
  payload: data
})

//Delete
export const deleteSmstemplate = (data) => ({
  type: types.DELETE_SMSTEMPLATE,
  payload: data
})
export const deleteSmstemplateSuccess = (data) => ({
  type: types.DELETE_SMSTEMPLATE_SUCCESS,
  payload: data
})
export const deleteSmstemplateFail = (data) => ({
  type: types.DELETE_SMSTEMPLATE_FAIL,
  payload: data
})