import React, { useEffect, useState } from "react";
import {
  Checkbox,
  TextareaAutosize,
  TextField,
  Dialog, DialogContent, DialogTitle, IconButton,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import { CustomButton } from "../../components/Common/CustomButton";
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import styled from "styled-components";
import {
  editSmstemplate,
  getSmstemplate,
} from "../../redux/smstemplate/action";

export const TitleInput = styled("p")(({ theme }) => ({
  fontSize: 14,
  fontWeight: 500,
  marginBottom: 10,
}));

export const BoxInput = styled("div")(({ theme }) => ({
  marginBottom: "10px",
}));

const useStyles = makeStyles((theme) => ({
  textFieldError: {
    "& input": {
      border: "1px solid red",
      color: "red",
    },
    "& p": {
      color: "red",
      marginLeft: 0,
    },
  },
  customModalContent: {
    paddingLeft: "15px",
    paddingRight: "15px",
  },
  customModal: {
    width: "calc(100% - 20px)",
    marginLeft: "10px",
    marginRight: "10px",
  },
  customModalTitle: {
    padding: "10px 15px",
    color: "#fff",
    background: process.env.NODE_ENV === 'development' ? "#5a8dee" : process.env.REACT_APP_PRODUCT_BASE_COLOR,
    marginBottom:'15px',
  },
}));

const SmsTempEdit = ({ setUDO1, item, onClose, idTemp }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [nameEdit, setNameEdit] = useState(item?.name);
  //const [telcoEdit, setTelcoEdit] = useState(item?.brandName);
  const [msgEdit, setMsgEdit] = useState(item?.msg_tem);
  const [active, setActive] = useState(item?.activate);


  const validationSchema = yup.object({
    // name: yup.string().required('Bạn chưa nhập tên phòng ban'),
  });

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item]);

  const formik = useFormik({
    initialValues: {
      name: nameEdit,
      //telco: telcoEdit,
      msg: msgEdit,
      id: idTemp,
      activate:active
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      dispatch(editSmstemplate(values));
      setUDO1(false);
      dispatch(getSmstemplate());
    },
  });


  

  return (
    <Dialog
      fullWidth={true}
      maxWidth={"xs"}
      open={true}
      onClose={onClose}
      style={{ minHeight: "100%" }}
      classes={{
        paper: classes.customModal,
      }}
    >
      <DialogTitle className={`${classes.customModalTitle}`}>
        Sửa Mẫu SMS
        <IconButton
          onClick={() => setUDO1(false)}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "#fff",
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={`${classes.customModalContent}`}>
        <form onSubmit={formik.handleSubmit}>
          
              <BoxInput>
                <TitleInput>Tên</TitleInput>
                <TextField
                  name="name"
                  size="small"
                  fullWidth
                  value={nameEdit}
                  onChange={(e) => {
                    formik.setFieldValue("name", e.currentTarget.value);
                    setNameEdit(e.currentTarget.value);
                  }}
                />
              </BoxInput>
            
              
            
              <BoxInput>
                <TitleInput>Nội dung</TitleInput>
                <TextareaAutosize
                  name="msg"
                  size="small"
                  fullWidth
                  placeholder="Nội dung"
                  value={msgEdit}
                  onChange={(e) => {
                    formik.setFieldValue("msg", e.currentTarget.value);
                    setMsgEdit(e.currentTarget.value);
                  }}
                />
              </BoxInput>
               
                    <BoxInput style={{display:'flex'}}>
                <TitleInput>Active</TitleInput>
                <Checkbox  style={{marginTop:'-12px'}}
                  id="activate"
                  name="activate"
                  checked = {active}
                  
                  onChange={(e) => {
                    formik.setFieldValue("activate", e.currentTarget.checked);
                    setActive(e.currentTarget.checked);
                  }}
                />
              </BoxInput>
                 
              
            

          <div
            style={{ display: "flex", justifyContent: "right", marginTop: 10 }}
          >
            <CustomButton
              variant="contained"
              type="submit"
              onClick={formik.handleSubmit}
            >
              Sửa
            </CustomButton>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
};
export default SmsTempEdit;
