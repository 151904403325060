import React from "react";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import { CreateCompany } from "./create-company";


import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  customModalContent: {
    paddingLeft: "15px",
    paddingRight: "15px",
  },
  customModal: {
    width: "calc(100% - 20px)",
    marginLeft: "10px",
    marginRight: "10px",
  },
  customModalTitle: {
    padding: "10px 15px",
    color: "#fff",
    background: process.env.NODE_ENV === 'development' ? "#5a8dee" : process.env.REACT_APP_PRODUCT_BASE_COLOR,
  },
}));

export const MultiForm = (props) => {
  const classes = useStyles();
  return (
    <Dialog
      fullWidth={true}
      maxWidth={"sm"}
      open={true}
      onClose={props.onClose}
      style={{ minHeight: "100%" }}
      classes={{
        paper: classes.customModal,
      }}
    >
      <DialogTitle className={`${classes.customModalTitle}`}>
        Tạo công ty
        <IconButton
          onClick={props.onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: '#fff',
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={`${classes.customModalContent}`}>
        <CreateCompany onClose={props.onClose} />
      </DialogContent>
    </Dialog>
  );
};
