import * as types from './type'

const initialState = {
    loading: false,
    sourceData: []
}

export const sourceDataReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_SOURCE_DATA_START: 
            return {
                ...state,
                loading: true,
            }
        
        case types.GET_SOURCE_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                sourceData: action.payload
            }
        
        case types.GET_SOURCE_DATA_ERROR:
            return {
                ...state,
                loading: false,
            }
        default:
            return state
    }
}