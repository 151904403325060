import { styled } from "@mui/material/styles";
import { Paper } from "@mui/material";

export const TitleInput = styled("p")(({ theme }) => ({
  fontSize: 14,
  fontWeight: 600,
  marginBottom: 10,  
  textAlign:'left',
}));

export const BoxInput = styled("div")(({ theme }) => ({
  marginTop: 12,
}));

export const BoxOrder = styled("div")(({ theme }) => ({
  
}));

export const ItemGrid = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

export const WrapCellPhone = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  position: "relative",
}));

export const IconPhone = styled("span")(({ theme }) => ({
  color: "#0e9f6e",
  "&:hover": {
    transform: "scale(1.5)",
    transition: "0.5s",
  },
  position: "absolute",
  right: 0,
  transition: "0.5s",
}));

export const ErrorMessage = styled("span")(({ theme }) => ({
  color: "#d32f2f",
  fontWeight: "400",
  fontSize: "0.75rem",
  lineHeight: "1.66",
  letterSpacing: "0.03333em",
  textAlign: "left",
  marginTop: "4px",
  marginRight: "14px",
  marginBottom: "0",
  marginLeft: "14px",
}));
