import * as types from "./type";

const initialState = {
  data: [],
  dataList: [],
  loading: false,
  creLoad: false,
  editLoad: false,
};

export const permissionReducer = (state = initialState, action) => {
  switch (action.type) {
    //edit permission
    case types.EDIT_PERMISSIONS:
      return {
        ...state,
        editLoad: true,
      };

    case types.EDIT_PERMISSIONS_SUCCESS:
      return {
        ...state,
        editLoad: false,
      };

    //get permission
    case types.GET_PERMISSIONS:
      return {
        ...state,
        editLoad: true,
      };

    case types.GET_PERMISSIONS_SUCCESS:
      return {
        ...state,
        data: action.payload.permision,
        editLoad: false,
      };
    default:
      return state;
  }
};
