import React, { useEffect } from "react";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import TextField from "@mui/material/TextField";
import { CustomButton } from "../../components/Common/CustomButton";
import styled from "styled-components";
import { useFormik } from "formik";
import * as Yup from "yup";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { createSmsconfig } from "../../redux/smsconfig/action";
export const TitleInput = styled("p")(({ theme }) => ({
    fontSize: 14,
    fontWeight: 500,
    marginBottom: 10,
  }));
  
  export const BoxInput = styled("div")(({ theme }) => ({
    
    marginBottom:'10px'
  }));
const useStyles = makeStyles((theme) => ({
    textFieldError: {
        "& input": {
          border: "1px solid red",
          color: "red",
        },
        "& p": {
          color: "red",
          marginLeft:0,
        },
      },
  customModalContent: {
    paddingLeft: "15px",
    paddingRight: "15px",
  },
  customModal: {
    width: "calc(100% - 20px)",
    marginLeft: "10px",
    marginRight: "10px",
  },
  customModalTitle: {
    padding: "10px 15px",
    color: "#fff",
    background: process.env.NODE_ENV === 'development' ? "#5a8dee" : process.env.REACT_APP_PRODUCT_BASE_COLOR,
    marginBottom:'15px',
  },
}));

const adminCompanySchema = Yup.object({
        
  });

const SmsCreate = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { values, handleSubmit, handleChange, handleBlur} =
  useFormik({
    initialValues: {
      name: "",
      brandName: "",
      tranId: "",
      userName: "",
      password: "",
      url: "",
    },
    validationSchema: adminCompanySchema,
    onSubmit: (values) => {
        
        dispatch(createSmsconfig(values));
        
      },
  });
  const createSuccess = useSelector(
    (item) => item.smsconfigReducer.createSuccess
  );

  useEffect(() => {
    if (createSuccess) {
      props.onClose();
    }
    // eslint-disable-next-line
  }, [createSuccess]);



  return (
    <Dialog
      fullWidth={true}
      maxWidth={"sm"}
      open={true}
      onClose={props.onClose}
      style={{ minHeight: "100%" }}
      classes={{
        paper: classes.customModal,
      }}
    >
      <DialogTitle className={`${classes.customModalTitle}`}>
        Cấu hình SMS
        <IconButton
          onClick={props.onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: '#fff',
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={`${classes.customModalContent}`}>
          

      <BoxInput>
            <TitleInput>Tên</TitleInput>
            <TextField
              size="small"
              name="name"
              fullWidth
              placeholder="Tên"
              onChange={handleChange}
              value={values.name}
            />
          </BoxInput>
          <BoxInput>
            <TitleInput>brand Name</TitleInput>
            <TextField
              size="small"
              name="brandName"
              fullWidth
              placeholder="brand Name"
              onChange={handleChange}
              value={values.brandName}
            />
          </BoxInput>
          <BoxInput>
            <TitleInput>tranId</TitleInput>
            <TextField
              size="small"
              name="tranId"
              fullWidth
              placeholder="tranId"
              onChange={handleChange}
              value={values.tranId}
            />
          </BoxInput>
          <BoxInput>
            <TitleInput>Tên đăng nhập</TitleInput>
            <TextField
              name="userName"
              size="small"
              fullWidth
              placeholder="Tên đăng nhập..."
              onChange={handleChange}
              onBlur={handleBlur}
              
              value={values.userName}
            />
          </BoxInput>
          <BoxInput>
            <TitleInput>Mật khẩu </TitleInput>
            <TextField
              size="small"
              name="password"
              type="password"
              fullWidth
              placeholder="Nhập Mật khẩu..."
              onChange={handleChange}
              onBlur={handleBlur}
              
              value={values.password}
            />
          </BoxInput>
          
          
          <BoxInput>
            <TitleInput>url </TitleInput>
            <TextField
              size="small"
              name="url"
              fullWidth
              placeholder="url"
              onChange={handleChange}
              value={values.url}
            />
          </BoxInput>

          <CustomButton
            // color="primary"
            variant="contained"
            type="submit"
            onClick={() => handleSubmit()}
            style={{marginLeft:'auto'}}
          >
            Thêm 
          </CustomButton>

      </DialogContent>
    </Dialog>
  );
};

export default SmsCreate;