import React from "react";
import {
  Paper,
  Table,
  TableBody,
  TableRow,
  TableContainer,
  Typography,
} from "@mui/material";
import MuiTableCell from "@material-ui/core/TableCell";
import MuiTableHead from "@mui/material/TableHead";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import "moment/locale/vi";
import blank from "../../assets/images/default-avatar.jpg";
import { CustomButton } from "../../components/Common/CustomButton";
import CachedOutlinedIcon from "@mui/icons-material/CachedOutlined";
import { showMessageError, showMessageSuccess } from "../../redux/notification/action";
import axiosInstance from "../../components/axiosApi";
import { useDispatch } from "react-redux";
const useStyles = makeStyles((theme) => ({
  tableBorder: {
    border: "1px solid #d4d8dd",
    borderRadius: "0.5rem",
  },
}));

const TableCell = withStyles({
  root: {
    borderBottom: "1px solid rgb(235, 237, 242)",
    padding: "13px 10px",
    lineHeight: "18px",
  },
})(MuiTableCell);

const TableHead = withStyles((theme) => ({
  root: {
    backgroundColor: "rgba(38,60,85,.1)",
  },
}))(MuiTableHead);

const TableHeaderCell = withStyles((theme) => ({
  root: {
    color: "#516377",
    fontWeight: 700,
    padding: 10,
    fontSize: ".75rem",
    textTransform: "uppercase",
    letterSpacing: "0.8px",
  },
}))(TableCell);
const FacebookTable = ({ data, handleSub }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const handleReloadFanpage = async(id)=>{
    try {
      const res = await axiosInstance.get(`/facebook/reload_fanpage/${id}/`);
      if (res.status === 200) {
        
        dispatch(showMessageSuccess("tải lại thành công"));
        
      }
    } catch (error) {
      dispatch(showMessageError("Lỗi server"));
    }
  }

  return (
    <>
      <>
        {data?.Fanpage?.length > 0 ? (
          <Paper
            sx={{ width: "100%", mb: 2 }}
            elevation={0}
            className={`${classes.tableBorder}`}
          >
            <div className="ul-heading-placeholder">
              <span
                className="ul-heading"
                style={{
                  display: "flex",
                  alignItems: "center",
                  fontWeight: 500,
                }}
              >
                Danh sách FanPage
              </span>
            </div>

            <TableContainer
              style={{
                width: "100%",
                overflowX: "auto",
                borderRadius: "0.5rem",
              }}
            >
              <Table sx={{ minWidth: 700 }} size={"small"}>
                <TableHead>
                  <TableRow>
                    <TableHeaderCell align="left">STT</TableHeaderCell>
                    <TableHeaderCell align="left" style={{ minWidth: 80 }}>
                      Logo
                    </TableHeaderCell>
                    <TableHeaderCell align="left" style={{ minWidth: 120 }}>
                      TÊN GỌI
                    </TableHeaderCell>
                    <TableHeaderCell align="left" style={{ minWidth: 120 }}>
                      Số lượng Followers
                    </TableHeaderCell>

                    <TableHeaderCell align="left" style={{ minWidth: 120 }}>
                      NGÀY TẠO
                    </TableHeaderCell>
                    <TableHeaderCell align="left" style={{ minWidth: 120 }}>
                      Hành động
                    </TableHeaderCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {data?.Fanpage &&
                    data?.Fanpage.length > 0 &&
                    data?.Fanpage.map((item, index) => (
                      <TableRow
                        key={index}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell align="left">{index + 1}</TableCell>
                        <TableCell align="left">
                          <div className="zalo-logo">
                            <img
                              src={item?.fimg ? `${item?.fimg}` : blank}
                              alt=""
                            />
                          </div>
                        </TableCell>
                        <TableCell align="left">{item?.page_name}</TableCell>
                        <TableCell align="left">
                          {item?.followers_count}
                        </TableCell>

                        <TableCell align="left">
                          {item?.created_on === null
                            ? ""
                            : moment(item?.created_on).format("MM/DD/YYYY")}
                        </TableCell>
                        <TableCell align="left">
                            <div className="flex">
                            <CustomButton
                            onClick={() => handleSub(item?.id)}
                            style={{
                              background: "rgb(244,106,106)",
                              boxShadow:
                                "0px 2px 6px 0px rgb(246, 59, 59, 0.5)",
                            }}
                          >
                            <i
                              style={{ marginRight: 5 }}
                              className="fas fa-bell"
                            ></i>
                            Subscribe
                          </CustomButton>
                            <CustomButton
                            onClick={() => handleReloadFanpage(item?.u_facebook?.id)}
                            style={{
                              width: "36px",
                              minWidth: "inherit",
                              background: "#f1b44c",
                              marginLeft:5
                            }}
                          >
                            <CachedOutlinedIcon
                              style={{ width: "0.75em", height: "0.75em" }}
                            />
                          </CustomButton>
                          
                            </div>
                         
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        ) : (
          <Typography>Dữ liệu chưa đồng bộ</Typography>
        )}
      </>
    </>
  );
};

export default FacebookTable;
