import React, { useState, useEffect,useRef, useCallback } from "react";
import {
  Autocomplete,
  TextField,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableRow,
  Input,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { useSelector } from "react-redux";
import { CustomButton } from "../../../../components/Common/CustomButton";
import Tooltip from "@mui/material/Tooltip";
import AddRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import { withStyles } from "@material-ui/core/styles";
import MuiTableCell from "@material-ui/core/TableCell";
import MuiTableHead from "@mui/material/TableHead";
import { renderWithCommand } from "../../../../helpers";


const TableCell = withStyles({
  root: {
    borderBottom: "1px solid rgb(235, 237, 242)",
    padding: "10px",
    lineHeight: "18px",
  },
})(MuiTableCell);

const TableHead = withStyles((theme) => ({
  root: {
    backgroundColor: "rgba(38,60,85,.1)",
  },
}))(MuiTableHead);

const TableHeaderCell = withStyles((theme) => ({
  root: {
    color: "#516377",
    fontWeight: 700,
    padding: 10,
    fontSize: ".75rem",
    textTransform: "uppercase",
    letterSpacing: "0.8px",
  },
}))(TableCell);

const TableProducts = ({ data, setData }) => {
  const stateProducts = useSelector((state) => state.productReducer);
  const [rows, setRows] = useState();
  const [previous, setPrevious] = useState({});
  const [products, setProducts] = useState([]);
  const inputRef = useRef(null);

  const [displayForm, setDisplayForm] = useState(false);
  const [newProduct, setNewProduct] = useState({
    idProduct: "",
    product: "",
    price: "",
    quantity: "",
    total: "",
  });
  const onToggleEditMode = (id) => {
    rows.map((row, idx) => {
      row.isEditMode = row.isEditMode === undefined ? false : row.isEditMode;
      
      if (row.idProduct === id) {
        const rowClone = { ...row, isEditMode: !row.isEditMode };
        rows[idx] = rowClone;
      }
      // return the modified item
      return row;
    });
    
    setRows([...rows]);
  };


  const handleInputChange = (e, row) => {
    if (!previous[row.idProduct]) {
      setPrevious((state) => ({ ...state, [row.idProduct]: row }));
    }
    const value = parseInt(e.target.value);
    
    const name = e.target.name;
    const { idProduct } = row;

    rows.map((row, idx) => {
      if (row.idProduct === idProduct) {
        const rowClone = { ...row, [name]: value, total: row.price * value };
        rows[idx] = rowClone;
      }
      
      return row
    })
    setRows([...rows]);
    setData([...rows])
   
    // eslint-disable-next-line
  }


  const handleMouseOut = (row, id) => {
    rows.map((row, idx) => {
      row.isEditMode = row.isEditMode === undefined ? false : row.isEditMode;
      
      if (row.idProduct === id) {
        const rowClone = { ...row, isEditMode: !row.isEditMode };
        rows[idx] = rowClone;
      }
      return row
    });
    
    setRows([...rows]);
  };


  const onCustomChange = (e, nv) => {
    if (nv != null ) {
      setNewProduct({
        idProduct: nv.idProduct,
        product: nv.title,
        price: nv.price,
        quantity: 1,
        total: nv.price * 1 ?? 0,
      });
    }
    
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };




  const handleChangeQuantity = (e, nv) => {
    console.log(e.target.value)
      setNewProduct({
        idProduct: newProduct.idProduct,
        product: newProduct.product,
        price: newProduct.price,
        total: newProduct.price * +e.target.value,
        quantity: parseInt(+e.target.value),
      });
    
    
  };

  const createProduct = () => {
    
    if (data === undefined){
      setData([])
    } else {
      const index = data.findIndex((item) => item.product === newProduct.product);
      if (index !== -1) {
        const updatedDataProducts = [...data];
        updatedDataProducts[index].quantity =
          updatedDataProducts[index].quantity + newProduct?.quantity;
        updatedDataProducts[index].total =
          newProduct.price * updatedDataProducts[index].quantity;
        setData(updatedDataProducts);
        setNewProduct({
          idProduct: '',
          product: '',
          price: '',
          quantity: '',
          total: '',
        });
      } else {
        setData([...data, newProduct]);
        setNewProduct({
          idProduct: '',
          product: '',
          price: '',
          quantity: '',
          total: '',
        });
      }
      setDisplayForm(false);
    }
    

  };



  const deleteProduct = (index, e) => {
    setData(data.filter((item, i) => i !== index));
  };

  useEffect(() => {
    if (stateProducts?.products.length > 0) {
      const data = stateProducts?.products?.map((item) => ({
        idProduct: item.id,
        title: item.title,
        price: item.price,
      }));
      setProducts(data);
    }
  }, [stateProducts]);
  useEffect(() => {}, [data, products]);

  useEffect(() => {
    if (data === undefined){
      setData([])
    } else {
      setRows([...data]);
    }
   
  }, [data]);
  return (
    <Paper className="user-list">
      <div
        className="ul-heading"
        style={{ padding: 0, display: "flex", alignItems: "center", marginBottom:'10px',flexWrap:'wrap' }}
      >
        <span style={{ marginRight: "10px" }}>Danh sách sản phẩm</span>
        <CustomButton onClick={() => setDisplayForm(!displayForm)} >
          <AddRoundedIcon style={{ fontSize: "1rem", marginRight: "5px" }} />
          Thêm sản phẩm
        </CustomButton>
      </div>
      <TableContainer
        style={{
          width: "100%",
          overflowX: "auto",
          borderRadius: "0.5rem",
          border: "1px solid #d4d8dd",
        }}
      >
        <Table id="user-table">
          <TableHead>
            <TableRow>
              <TableHeaderCell style={{ width: "10%" }}>STT</TableHeaderCell>
              <TableHeaderCell style={{ width: "22.5%" }}>
                Tên sản phẩm
              </TableHeaderCell>
              <TableHeaderCell style={{ width: "22.5%" }}>Giá</TableHeaderCell>
              <TableHeaderCell style={{ width: "22.5%" }}>
                Số lượng
              </TableHeaderCell>
              <TableHeaderCell style={{ width: "22.5%" }}>
                Thành tiền
              </TableHeaderCell>
              <TableHeaderCell></TableHeaderCell>
            </TableRow>
          </TableHead>
          <TableBody>
          
                {displayForm ? (
                  <TableRow>
                    <TableCell align="left"></TableCell>
                    <TableCell align="left">
                      <Autocomplete
                        name="title"
                        getOptionLabel={(option) => option.title}
                        onChange={(e, nv) => onCustomChange(e, nv)}
                        disableClearable={false}
                        disablePortal={true}
                        filterSelectedOptions
                        autoHighlight
                        size="small"
                        // PopperComponent={CustomPopper}
                        id="combo-box-demo"
                        options={products}
                        sx={{ width: 300 }}
                        
                        renderInput={(params) => (
                          <TextField 
                            {...params} 
                            label="Tìm kiếm sản phẩm" 
                            required/>
                        )}
                      />
                    </TableCell>
                    <TableCell align="left">
                      {newProduct.price}
                    </TableCell>
                    <TableCell align="left">
                      <Input
                        defaultValue={newProduct.quantity ?? 1}
                        onChange={(e, nv) => handleChangeQuantity(e, nv)}
                        type="number"
                        name="quantity"
                        onBlur={() => createProduct()}
                        inputRef = {inputRef}
                      />
                    </TableCell>
                    <TableCell align="left">
                        {newProduct.total}
                    </TableCell>
                    </TableRow>
                ) : (
                  null
                )}
              
            {data?.length > 0 ? (
              rows.filter(row => row.idProduct !== '' && row.idProduct !== null && row.idProduct !== undefined )
              .map((row, index) => {
                return (
                  <TableRow hover={true} key={row.name}>
                    <TableCell align="left">{index + 1}</TableCell>
                    <TableCell align="left">{row?.product}</TableCell>
                    <TableCell align="left">
                      {renderWithCommand(row?.price)}
                    </TableCell>
                    <TableCell align="left">
                    {row.isEditMode ? (
                     
                      <Input
                        value={row?.quantity}
                        onChange={(e) => 
                        handleInputChange(e, row)
                        }
                        type="number"
                        name="quantity"
                        onBlur={() => handleMouseOut(row, row?.idProduct)}
                      />
                    ) : (
                      <Tooltip title="Click Edit" arrow>
                        <div onClick={() => onToggleEditMode(row?.idProduct)}>
                          {row?.quantity}
                        </div>
                        </Tooltip>
                      
                     
                    )}
                    </TableCell>
                    <TableCell align="left">
                      {renderWithCommand(row?.total)}
                    </TableCell>
                    <TableCell align="left">
                      <DeleteIcon
                        onClick={(e) => deleteProduct(index, e)}
                        sx={{
                          width: "0.8em",
                          height: "0.8em",
                          color: "#f46a6a",
                        }}
                      />
                    </TableCell>
                  </TableRow>
                );
              })
            ) : (
                  null
              
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default TableProducts;
