import * as types from './type'

const initialState = {
  data: [],
  dataList: [],
  loading: false,
  createSuccess: false,
}

export const PbxReducer = (state = initialState, action) => {
  switch (action.type) {
    

    case types.CREATE_PBX:
      return {
        ...state,
        ...{ loading: true }
      }

    case types.CREATE_PBX_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        createSuccess: true,
      }

    case types.CREATE_PBX_FAIL:
      return {
        ...state,
        loading: false,
        data: action.payload.response.data,
      }

    case types.RESET_MESSAGE_ERROR:
      return {
        ...state,
        loading: false,
        data: []
      }
      
    default:
      return state
  }
}