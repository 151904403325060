import React,{useEffect} from 'react'
const FacebookFanpageSidebar = ({dataNotify,setIdFanpage, data ,onClickLoadDataChat,selectedFanpage, setSelectedFanpage, setSelected}) => {
    useEffect(() => {
        if(dataNotify !== null){
            onClickLoadDataChat(dataNotify.send_to)
            setSelectedFanpage(dataNotify.send_to)

            setIdFanpage(dataNotify.send_to)
        }
    }, [dataNotify])
    

const getDataConvert = (item) => {
    
    onClickLoadDataChat(item)
    setIdFanpage(item)
}
  return (
    <>
        <h3 className="heading">Chọn Fanpage</h3>
        <div className={`${data?.Fanpage && data?.Fanpage.length > 5 ? 'fb-list' : ''}`}>
            {
            data?.Fanpage && 
            data?.Fanpage.length > 0 &&
            data?.Fanpage.map((item,index) => (
                
                <div className={`item ${parseInt(selectedFanpage) === item?.id ? 'bg-blue' : ''}`} 
                    key={index}
                    
                    onClick={() => {
                        getDataConvert(item?.id)
                        setSelectedFanpage(item?.id)
                        setSelected()
                    }}
                    
                >
                    
                    <div className="info">
                        <div className="username">{item?.id}</div>
                        <div className="">{item?.page_name}</div>
                    </div>
                    {
                        parseInt(selectedFanpage) === item?.id ?
                        <i className="fas fa-check"></i> : null
                    }
                    
                </div>
            ))
        }
        </div>
        
    </>
  )
}

export default FacebookFanpageSidebar