import * as types from './type'

const initialState = {
    data: [],
    loading: false,
}

export const statusLeadRuducer = (state = initialState, action) => {

    switch (action.type) {

        case types.GET_STATUS_LEAD: 
            return {
                ...state,
                ...{ loading: true }
            }
        
        case types.GET_STATUS_LEAD_SUCCESS:
            return {
                ...state,
                ...{
                    data: action.payload.status,
                    loading: false
                }
            }
        
        case types.GET_STATUS_LEAD_FAIL:
            {
                return {
                    ...state,
                    ...{ data: [], loading: false }
                }
            }
        default:
            return state
    }
}