import React, { useState, useEffect, useImperativeHandle } from "react";
import PropTypes from "prop-types";
import { DialogNotification } from "./dialog-notification";
import SupportAgentOutlinedIcon from "@mui/icons-material/SupportAgentOutlined";
import axiosInstance from "../axiosApi";

const ConfirmModal = React.forwardRef((props, ref) => {
  const { onClose } = props;
  const cancelRef = React.useRef(null);
  const [data, setData] = useState([]);

  useEffect(() => {
    const handleSearchInfoByPhone = async () => {
      try {
        const res = await axiosInstance.get(
          `/leads/?phone=${props.phoneNumber}`
        );
        if (res.status === 200) {
          setData(res.data);
        }
      } catch (error) {}
    };
    handleSearchInfoByPhone();
  }, [props.phoneNumber]);

  useImperativeHandle(ref, () => ({
    closeDialogCall: () => {
      onClose(false);
    },
  }));

  return (
    <>
      <DialogNotification
        leastDestructiveRef={cancelRef}
        overlayClassName={"z-999999"}
      >
        <div
          className=" sm:mr-6 mt-16 sm:mt-6 mb-6 sm:mb-0 w-2/12 mx-auto absolute left-0 sm:left-auto right-20 sm:top-10 bg-white dark:bg-gray-800 shadow-lg rounded flex pr-4 py-4 transition duration-150 ease-in-out"
          style={{ width: "300px", zIndex: "99" }}
        >
          <button
            onClick={() => onClose(false)}
            className="absolute right-0 mr-4 text-gray-500 dark:text-gray-100 dark:hover:text-gray-400 hover:text-gray-600 transition duration-150 ease-in-out cursor-pointer focus:ring-2 focus:outline-none focus:ring-gray-500 rounded"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="icon icon-tabler icon-tabler-x"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              stroke-width="2.5"
              stroke="currentColor"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" />
              <line x1="18" y1="6" x2="6" y2="18" />
              <line x1="6" y1="6" x2="18" y2="18" />
            </svg>
          </button>
          <div className="pr-3 pl-4">
            <div className="h-12 w-12 sm:h-10 sm:w-10 rounded-full">
              <SupportAgentOutlinedIcon />
            </div>
          </div>
          <div>
            <p className="text-gray-800 dark:text-gray-100 font-semibold">
              {props.title}
            </p>
            <p className="text-gray-600 dark:text-gray-400 font-normal">
              {props.message}
            </p>
            <div className="box-phone-customer">
              <h3 className="title">Thông tin khách hàng</h3>
              <div
                className="customer-name"
                style={{
                  color:
                    process.env.NODE_ENV === "development"
                      ? "#5a8dee"
                      : process.env.REACT_APP_PRODUCT_BASE_COLOR,
                }}
              >
                <i className="fas fa-info-circle"></i>{" "}
                {data?.open_leads?.open_leads[0]?.lead?.fullname}
              </div>
            </div>
            <div className="flex items-center pt-2">
              <button
                onClick={() => onClose(true)}
                ref={cancelRef}
                className="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-700 bg-indigo-700 transition duration-150 ease-in-out hover:bg-indigo-600 rounded text-white px-5 py-1 text-xs"
              >
                Chấp thuận
              </button>
              <button
                onClick={() => onClose(false)}
                className="bg-gray-200 dark:bg-gray-700 dark:text-indigo-600 dark:hover:bg-gray-600 transition duration-150 ease-in-out focus:outline-none focus:ring-2 focus:ring-offset-2  focus:ring-gray-300 hover:bg-gray-300 rounded text-indigo-700 px-5 py-1 text-xs ml-2"
              >
                Từ chối
              </button>
            </div>
          </div>
        </div>
      </DialogNotification>
    </>
  );
});

ConfirmModal.defaultProps = {
  message: "Are you sure?",
  title: "Warning!",
  confirmText: "Ok",
  confirmClass: "primary",
  cancelText: "Cancel",
  cancelClass: "",
  className: "",
  buttonsComponent: null,
  size: null,
};

ConfirmModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  message: PropTypes.node,
  title: PropTypes.node,
  confirmText: PropTypes.node,
  cancelText: PropTypes.node,
  confirmClass: PropTypes.string,
  cancelClass: PropTypes.string,
  className: PropTypes.string,
  size: PropTypes.string,
  buttonsComponent: PropTypes.func,
};

export default ConfirmModal;
