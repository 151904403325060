import React, { useRef, useEffect, useState } from "react";

import {
  Paper,
  Typography,
  Grid,
} from "@mui/material";
import SupportAgentOutlinedIcon from '@mui/icons-material/SupportAgentOutlined';
import MuiTableCell from "@material-ui/core/TableCell";
import MuiTableHead from "@mui/material/TableHead";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import blank from "../../assets/images/default-avatar.jpg";
const URL = "https://cms.siptrunk.vn:1443/";
const URLIMG = "http://media.stg.emas.apyylon.com:9300/";

const useStyles = makeStyles((theme) => ({
  tableBorder: {
    border: "1px solid #d4d8dd",
    borderRadius: "0.5rem",
  },
  contentChatHeight:{
    "@media (min-width: 1367px)": {
      height:'444px !important'
  }
  }
}));

const TableCell = withStyles({
  root: {
    borderBottom: "1px solid rgb(235, 237, 242)",
    padding: "13px 10px",
    lineHeight: "18px",
  },
})(MuiTableCell);

const TableHead = withStyles((theme) => ({
  root: {
    backgroundColor: "rgba(38,60,85,.1)",
  },
}))(MuiTableHead);

const TableHeaderCell = withStyles((theme) => ({
  root: {
    color: "#516377",
    fontWeight: 700,
    padding: 10,
    fontSize: ".75rem",
    textTransform: "uppercase",
    letterSpacing: "0.8px",
  },
}))(TableCell);

const DetailCallHistory = ({ data, detailCustomer }) => {
  const classes = useStyles();
  const audioRef = useRef(null);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);

  const dataWebhook = data.map((item) => item.webhook[0]);
  const dataWebhookAudio = data.map((item) => item.cdr_url);
  const dataWebhookEmotion = dataWebhook.map((item) => item.result.emotion);
  const dataWebhookSpeech = dataWebhook.map((item) => item.result.speech);
  const dataWebhookSentenseMiss = dataWebhookSpeech.map(
    (item) => item.sentencePatternMisses
  );

  const dataWebhookConversation = dataWebhookSpeech.map((item) =>
    item.conversation.map((item2) => item2)
  );

  const formatConversation = Object.assign({}, dataWebhookConversation); // {0:"a", 1:"b", 2:"c"}

  const dataCustomerbyPhone = detailCustomer?.map((item) => item?.lead);

  const imgEmotion = dataWebhookEmotion.map((item) => item.graphUrI);

  const onTimeUpdate = () => {
    let ref = audioRef.current;

    if (ref) {
      let videoTotalDuration = ref.duration;
      let videoDuration = ref.currentTime;
      setDuration(videoTotalDuration);
      setCurrentTime(videoDuration);
    }
  };

  const checkBgChange = (startime, endtime) => {
    const intStartTime = parseInt(startime);
    const intEndTime = parseInt(endtime);
    const intCurrentTime = parseInt(currentTime);

    for (let i = intStartTime; i <= intEndTime; i++) {
      if (i === intCurrentTime) {
        return "bg-bluepastel";
      }
    }
  };

  useEffect(() => {
    onTimeUpdate();
  }, []);

  return (
    <>
      <Grid container rowSpacing={2} columnSpacing={{ xs: 1 }}>
        <Grid item xs={12} sm={6}>
          <Paper
            className="user-list"
            variant="outlined"
            style={{ marginBottom: 10 }}
          >
            <div className="ul-heading-placeholder">
              <span
                className="ul-heading"
                style={{
                  display: "flex",
                  alignItems: "center",
                  fontWeight: 500,
                }}
              >
                Thông tin khách hàng
              </span>
            </div>
          </Paper>

          <Paper
            sx={{ width: "100%", mb: 2 }}
            elevation={0}
            className={`${classes.tableBorder}`}
          >
            <div className="box-info-customer">
              <div className="item">
                <div className="label-name">Họ và tên:</div>
                <div className="text">{dataCustomerbyPhone[0]?.fullname}</div>
              </div>
              <div className="item">
                <div className="label-name">SĐT:</div>
                <div className="text">{dataCustomerbyPhone[0]?.phone}</div>
              </div>
              <div className="item">
                <div className="label-name">Địa chỉ:</div>
                <div className="text">{dataCustomerbyPhone[0]?.address}</div>
              </div>
              <div className="item">
                <div className="label-name">Email:</div>
                <div className="text">{dataCustomerbyPhone[0]?.email}</div>
              </div>
            </div>
          </Paper>

          <Paper
            className="user-list"
            variant="outlined"
            style={{ marginBottom: 10 }}
          >
            <div className="ul-heading-placeholder">
              <span
                className="ul-heading"
                style={{
                  display: "flex",
                  alignItems: "center",
                  fontWeight: 500,
                }}
              >
                Phân tích cuộc gọi
              </span>
            </div>
          </Paper>
          <Paper
            className={`${classes.tableBorder}`}
            variant="outlined"
            style={{ marginBottom: 10, padding:'15px' }}
            
          >
            
                <div className="box-audio" style={{ marginBottom: 15}}>
                  <img src={`${URLIMG}${imgEmotion}`} />
                  <audio
                    src={`${URL}${dataWebhookAudio}`}
                    ref={audioRef}
                    controls
                    onTimeUpdate={onTimeUpdate}
                  />
                </div>
              
                {dataWebhookEmotion?.map((item) => (
                  <>
                    <p>
                      {" "}
                      <span className="fbold">Mức độ:</span> {item.warningLevel}
                    </p>
                    <p>
                      {" "}
                      <span className="fbold">Cảm xúc:</span> {item.emotionTag}
                    </p>
                    <p>
                      {" "}
                      <span className="fbold">Trạng thái kết thúc:</span>{" "}
                      {item.emotionTag}
                    </p>
                  </>
                ))}
                {dataWebhookSentenseMiss?.map((item) => (
                  <p>
                    {" "}
                    <span className="fbold">Mẫu câu bị thiếu:</span>
                    {item === null ? (
                      ""
                    ) : (
                      <span
                        style={{ color: "#fff" }}
                        className="text-status bg-c-blue"
                      >
                        {item === null ? "" : item}
                      </span>
                    )}
                  </p>
                ))}
             
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Paper
            className="user-list"
            variant="outlined"
            style={{ marginBottom: 10 }}
          >
            <div className="ul-heading-placeholder">
              <span
                className="ul-heading"
                style={{
                  display: "flex",
                  alignItems: "center",
                  fontWeight: 500,
                }}
              >
                Nội dung hội thoại
              </span>
            </div>
          </Paper>
          {formatConversation[0]?.length > 0 ? (
            <Paper
              sx={{ width: "100%", mb: 2 }}
              elevation={0}
              className={`${classes.tableBorder}`}
            >
              <div className="wrapper-chat">
                <div className={`msg-content ${classes.contentChatHeight}`}>
                  {formatConversation[0] &&
                    formatConversation[0]?.map((item, index) => {
                      return (
                        <div
                          key={index}
                          style={{ borderRadius: "0.5rem", padding: "5px" }}
                          className={`msg-box-holder
                        ${checkBgChange(item?.startTime, item?.endTime)} 
                        ${item?.speaker !== "Customer" ? "text-right" : ""}`}
                        >
                          <div className="msg-seller">
                            <div className="photo">
                              {item?.speaker === "Customer" ?  <img src={blank} alt="photo" /> : <SupportAgentOutlinedIcon></SupportAgentOutlinedIcon>}
                            </div>
                            <div className="msg-box">
                              {item?.text}
                              <div className="msg-time">
                                {item?.endTime - item?.startTime}s
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            </Paper>
          ) : (
            <Typography>Chưa có cuộc hội thoại nào</Typography>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default DetailCallHistory;
