import { put, takeLatest } from "redux-saga/effects";
import * as types from './type';
import * as actions from './action';
import axiosInstance from "../../components/axiosApi";
import { showMessageSuccess } from "../notification/action";

function* getDepartmentsId({payload}) {
    try {
        const res = yield axiosInstance.get(`/org/departments/${payload}/`)
        yield put(actions.getDepartmentsIdSuccess(res.data))
    } catch (error) {
        yield put(actions.getDepartmentsIdFail(error))
    }
}

function* createPositions({payload}) {
    try {
        const res = yield axiosInstance.post(`/org/positions/`, payload)
        yield put(actions.createPositionsSuccess(res.data))
        yield put(showMessageSuccess("Thêm chức vụ thành công"))
    } catch (error) {
        yield put(actions.createPositionsFail(error))
    }
}

function* editPositions({payload}) {
    try {
        const res = yield axiosInstance.put(`/org/positions/`, payload)
        yield put(actions.putPositionsSuccess(res.data))
        yield put(showMessageSuccess("Cập nhật chức vụ thành công"))
    } catch (error) {
        yield put(actions.putPositionsFail(error))
    }
}

export function* watchingDepartmentsId() {
    yield takeLatest(types.GET_DEPARTMENTS_ID, getDepartmentsId);
    yield takeLatest(types.CREATE_POSITIONS, createPositions);
    yield takeLatest(types.PUT_POSITIONS, editPositions);
}