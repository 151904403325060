import { put, takeLatest } from "redux-saga/effects";
import * as types from './type';
import * as actions from './action';
import axiosInstance from "../../components/axiosApi";
import { showMessageSuccess } from "../notification/action";

function* getCallstatus() {
  try {
    const res = yield axiosInstance.get(`/leads/callstatus/`)
    yield put(actions.getCallstatusSuccess(res.data))
  } catch (error) {
    yield put(actions.getCallstatusFail(error))
  }
}


function* createCallstatus({ payload }) {
  const { title,is_default} = payload
  const params = `title=${title}&is_default=${is_default}`
  try {
    const res = yield axiosInstance.post(`/leads/callstatus/?${params}`)
    yield put(actions.createCallstatusSuccess(res.data))
  } catch (error) {
    yield put(actions.createCallstatusFail(error))
  }
}

function* editCallstatus({payload}) {
    const { id,title,is_default} = payload
    const params = `id=${id}&title=${title}&is_default=${is_default}`
    try {
        
        const res = yield axiosInstance.put(`/leads/callstatus/?${params}`);
        yield put(actions.editCallstatusSuccess(res.data))
        yield put(showMessageSuccess("Cập nhập thành công"))
        
    } catch (error) {
      yield put(actions.editCallstatusFail(error))
    }
}

function* deleteCallstatus({payload}) {
  try {
      
      const res = yield axiosInstance.delete(`/leads/callstatus/?id=${payload}`);
      yield put(actions.deleteCallstatusSuccess(res.data))
      yield put(showMessageSuccess("Cập nhập thành công"))
      
  } catch (error) {
    yield put(actions.deleteCallstatusFail(error))
  }
}

function* resetMessage() {
  yield put(actions.resetMessageError({}))
}

export function* watchingCallstatus() {
  yield takeLatest(types.GET_CALLSTATUS, getCallstatus);
  yield takeLatest(types.CREATE_CALLSTATUS, createCallstatus);
  yield takeLatest(types.RESET_MESSAGE, resetMessage);
  yield takeLatest(types.EDIT_CALLSTATUS, editCallstatus);
  yield takeLatest(types.DELETE_CALLSTATUS, deleteCallstatus);
}
