export const GET_COMPANIES = "GET_COMPANIES"
export const GET_COMPANIES_SUCCESS = "GET_COMPANY_SUCCESS"
export const GET_COMPANIES_FAIL = "GET_COMPANY_FAIL"
export const CREATE_COMPANY = "CREATE_COMPANY"
export const CREATE_COMPANY_SUCCESS = "CREATE_COMPANY_SUCCESS"
export const CREATE_COMPANY_FAIL = "CREATE_COMPANY_FAIL"
export const CREATE_ADMIN_COMPANY = "CREATE_ADMIN_COMPANY"
export const CREATE_ADMIN_COMPANY_SUCCESS = "CREATE_ADMIN_COMPANY_SUCCESS"
export const CREATE_ADMIN_COMPANY_FAIL = "CREATE_ADMIN_COMPANY_FAIL"
export const RESET_MESSAGE = "RESET_MESSAGE"
export const RESET_MESSAGE_ERROR = "RESET_MESSAGE_ERROR"

export const EDIT_COMPANY = "EDIT_COMPANY"
export const EDIT_COMPANY_SUCCESS = "EDIT_COMPANY_SUCCESS"
export const EDIT_COMPANY_FAIL = "EDIT_COMPANY_FAIL"