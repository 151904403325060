import React, {useState, useEffect } from "react";
import {
  
  Grid,
  DialogContent,
  DialogTitle,
  Dialog,
  
} from "@mui/material";

import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import { makeStyles } from "@material-ui/core/styles";
import styled from "styled-components";

import { useDispatch, useSelector } from "react-redux";
import CustomerCallTable from "./CustomerCallTable";
import DiaLogAddCustomer from "./components/DialogCustomer"; 

import { getSourceData } from "../../redux/sourceData/action";
import { getListCity } from "../../redux/city/action";
import { getTrangThaiData } from "../../redux/trangThaiData/action";
import { getUserStart } from "../../redux/users/action";
import { getCallstatus } from "../../redux/callstatus/action";
import { getStatusLead } from "../../redux/statusLead/action";
import { getCustomerStart } from "../../redux/customers/action";
export const TitleInput = styled("p")(({ theme }) => ({
  fontSize: 14,
  fontWeight: 500,
  marginBottom: 10,
}));

export const BoxInput = styled("div")(({ theme }) => ({
  marginBottom: "10px",
}));

const useStyles = makeStyles((theme) => ({
  textFieldError: {
    "& input": {
      border: "1px solid red",
      color: "red",
    },
    "& p": {
      color: "red",
      marginLeft: 0,
    },
  },
  customModalContent: {
    paddingLeft: "15px",
    paddingRight: "15px",
  },
  customModal: {
    width: "calc(100% - 20px)",
    marginLeft: "10px",
    marginRight: "10px",
  },
  customModalTitle: {
    padding: "10px 15px",
    color: "#fff",
    background:
      process.env.NODE_ENV === "development"
        ? "#5a8dee"
        : process.env.REACT_APP_PRODUCT_BASE_COLOR,
    marginBottom: "15px",
  },
}));

const DialogCustomerCall = ({ onCloseModal, setUDO1, dataUser, number }) => {
  const dataLogoFooter = useSelector((item) => item.dataLogoFooterRuducer.data);
  const checkEnablePhone = dataLogoFooter?.org?.hide_number
  const dispatch = useDispatch()
  const classes = useStyles();

  const [detailCustomer, setDetailCustomer] = useState(null);    

  const [userDetailOpen, setUDO] = useState(false);
  const [dataPagination, setDataPagination] = useState({ offset: 0, limit: 10 });
  const [phone, setPhone] = useState(null);

  const [selected, setSelected] = useState([]);

  const stateDataTrangThai = useSelector((state) => state.trangThaiDataReducer);
  const stateDataSource = useSelector((state) => state.sourceDataReducer)
  const stateDataCallStatus = useSelector((state) => state.callstatusReducer.dataList)

  const [data, setData] = useState([]);


  const handleCloseUserDetailDialog = () => {
    setUDO(!userDetailOpen);
    setDetailCustomer(null);
  };

  useEffect(() => {
   
    dispatch(getSourceData());
    dispatch(getListCity());
    dispatch(getTrangThaiData());
    dispatch(getUserStart());
    dispatch(getStatusLead());
    dispatch(getCallstatus());
    
    // eslint-disable-next-line
  }, []);

  const dataCustomers = useSelector((state) => state.getCustomersReducer);

  useEffect(() => {
    let data = {
      phone: number,
      offset: 0,
     limit: 10,
    };
    dispatch(getCustomerStart(data));
   // eslint-disable-next-line
  }, []);

  return (
    <>
    <Dialog
      
      fullWidth={true}
          maxWidth={"md"}
          open={true}
          onClose={onCloseModal}
          style={{ minHeight: "100%" }}
          classes={{
            paper: classes.customModal
          }}
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {    
                maxWidth: "1040px",  // Set your width here
                boxShadow:'none',
                marginTop:'10px'
              },
            },
          }}
        >
      <DialogTitle className={`${classes.customModalTitle}`}>
        Danh sách
        <IconButton
          onClick={() => setUDO1(false)}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "#fff",
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={`${classes.customModalContent}`}>
        
        
        <Grid container rowSpacing={1} columnSpacing={{ sm: 2 }}>
          <Grid item xs={12}>
            <CustomerCallTable 

            dataCustomers={dataCustomers}
            setDetailCustomer={setDetailCustomer}

            dataCallStatus = {stateDataCallStatus}
            dataSources = {stateDataSource}
            dataStatus = {stateDataTrangThai}
            showDetail={setUDO}
            
            dataPagination={dataPagination}
            setDataPagination={setDataPagination}
            setPhone={setPhone}
            phone={phone}
            selected={selected}
            setSelected={setSelected}
            checkEnablePhone={checkEnablePhone}
            filterByPhone = {number}
            />
          </Grid>
         
        </Grid>
        
        
      </DialogContent>
    </Dialog>

            <Dialog
          fullWidth={true}
          maxWidth={"md"}
          open={userDetailOpen}
          onClose={handleCloseUserDetailDialog}
          style={{ minHeight: "100%" }}
          classes={{
            paper: classes.customModal
          }}
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {    
                maxWidth: "1040px",  // Set your width here
                boxShadow:'none',
                marginTop:'10px'
              },
            },
    }}
        >
          <DialogTitle className={`${classes.customModalTitle}`}>
            Lên đơn hàng
            <IconButton
              onClick={handleCloseUserDetailDialog}
              sx={{
                position: "absolute!important",
                right: 8,
                top: 8,
                color:'#fff',
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent className={`${classes.customModalContent}`}>
            <DiaLogAddCustomer
              setUDO={setUDO}
              setDetailCustomer={setDetailCustomer}
              detailCustomer={detailCustomer}
              setDataPagination={setDataPagination}
              dataPagination={dataPagination}
              checkEnablePhone={checkEnablePhone}
              dataLogoFooter={dataLogoFooter}
              dataCallStatus = {stateDataCallStatus}
              filterByPhone = {number}
            />
          </DialogContent>
        </Dialog>
        </>
  );
};

export default DialogCustomerCall;

