
import * as types from "./type"

export const getCallstatus = (data) => ({
  type: types.GET_CALLSTATUS,
  payload: data
})
export const getCallstatusSuccess = (data) => ({
  type: types.GET_CALLSTATUS_SUCCESS,
  payload: data
})
export const getCallstatusFail = (data) => ({
  type: types.GET_CALLSTATUS_FAIL,
  payload: data
})

// for create company

export const createCallstatus = (data) => ({
  type: types.CREATE_CALLSTATUS,
  payload: data
})

export const createCallstatusSuccess = (data) => ({
  type: types.CREATE_CALLSTATUS_SUCCESS,
  payload: data
})
export const createCallstatusFail = (data) => ({
  type: types.CREATE_CALLSTATUS_FAIL,
  payload: data
})



export const resetMessage = (data) => ({
  type: types.RESET_MESSAGE,
  payload: data
})

export const resetMessageError = (data) => ({
  type: types.RESET_MESSAGE_ERROR,
  payload: data
})

//EDIT
export const editCallstatus = (data) => ({
  type: types.EDIT_CALLSTATUS,
  payload: data
})
export const editCallstatusSuccess = (data) => ({
  type: types.EDIT_CALLSTATUS_SUCCESS,
  payload: data
})
export const editCallstatusFail = (data) => ({
  type: types.EDIT_CALLSTATUS_FAIL,
  payload: data
})

//Delete
export const deleteCallstatus = (data) => ({
  type: types.DELETE_CALLSTATUS,
  payload: data
})
export const deleteCallstatusSuccess = (data) => ({
  type: types.DELETE_CALLSTATUS_SUCCESS,
  payload: data
})
export const deleteCallstatusFail = (data) => ({
  type: types.DELETE_CALLSTATUS_FAIL,
  payload: data
})