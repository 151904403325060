import * as types from './type'

const initialState = {
    message: "",
    showStatus: false,
    type: ""
}

export const notificationReducer = (state = initialState, action) => {

    switch (action.type) {

        case types.SHOW_MESSAGE_SUCCESS: 
            return {
                ...state,
                message: action.payload,
                showStatus: true,
                type: "success"
            }
        case types.SHOW_MESSAGE_ERROR:
            return {
                ...state,
                message: action.payload,
                showStatus: true,
                type: "error"
            }
        case types.RESET_STATE:
            return {
                ...state,
                message: "",
                showStatus: false
            }
        default:
            return state
    }
}