
import * as types from "./type"

export const getSmsbot = (data) => ({
  type: types.GET_SMSBOT,
  payload: data
})
export const getSmsbotSuccess = (data) => ({
  type: types.GET_SMSBOT_SUCCESS,
  payload: data
})
export const getSmsbotFail = (data) => ({
  type: types.GET_SMSBOT_FAIL,
  payload: data
})

// for create company

export const createSmsbot = (data) => ({
  type: types.CREATE_SMSBOT,
  payload: data
})

export const createSmsbotSuccess = (data) => ({
  type: types.CREATE_SMSBOT_SUCCESS,
  payload: data
})
export const createSmsbotFail = (data) => ({
  type: types.CREATE_SMSBOT_FAIL,
  payload: data
})



export const resetMessage = (data) => ({
  type: types.RESET_MESSAGE,
  payload: data
})

export const resetMessageError = (data) => ({
  type: types.RESET_MESSAGE_ERROR,
  payload: data
})

//EDIT
export const editSmsbot = (data) => ({
  type: types.EDIT_SMSBOT,
  payload: data
})
export const editSmsbotSuccess = (data) => ({
  type: types.EDIT_SMSBOT_SUCCESS,
  payload: data
})
export const editSmsbotFail = (data) => ({
  type: types.EDIT_SMSBOT_FAIL,
  payload: data
})

//Delete
export const deleteSmsbot = (data) => ({
  type: types.DELETE_SMSBOT,
  payload: data
})
export const deleteSmsbotSuccess = (data) => ({
  type: types.DELETE_SMSBOT_SUCCESS,
  payload: data
})
export const deleteSmsbotFail = (data) => ({
  type: types.DELETE_SMSBOT_FAIL,
  payload: data
})