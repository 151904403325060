import React, { useState } from "react";
import {
  
    Paper,
    Grid,
    MenuItem,
    Select,
  TextareaAutosize,
  TextField,
  } from "@mui/material";
  import { makeStyles } from "@material-ui/core/styles";
  import styled from "styled-components";
  import { CustomButton } from "../../components/Common/CustomButton";
  import AssignToSelect from "../../components/Common/AssignTo/AssignToSelect";
  import { useFormik } from "formik";
  import { createTicket } from "../../redux/ticket/action";
  import { useDispatch } from "react-redux";
import * as yup from "yup";

  export const TitleInput = styled("p")(({ theme }) => ({
    fontSize: 14,
    fontWeight: 500,
    marginBottom: 10,
  }));
  
  export const BoxInput = styled("div")(({ theme }) => ({
    marginBottom: "10px",
  }));
  const useStyles = makeStyles((theme) => ({
    tableBorder: {
      border: "1px solid #d4d8dd",
      borderRadius: "0.5rem",
      padding: "15px",
    },
    
  }));

  const priorityData = [
    { id: 1, label: "Khẩn cấp" },
    { id: 2, label: "Cao" },
    { id: 3, label: "Trung Bình" },
    { id: 4, label: "Thấp" },
  ];
  
  const statusData = [
    { id: 1, label: "Mới" },
    { id: 2, label: "Đang chờ" },
    { id: 3, label: "Đang xử lý" },
    { id: 4, label: "Đã đóng" },
  ];

const TicketCreate = () => {
    const classes = useStyles();
    const dispatch = useDispatch()
    const [phone, setPhone] = useState('')
    const [priority, setPriority] = useState(priorityData[2].id);
    const [status, setStatus] = useState(statusData[0].id);

    const [assign, setAssign] = useState("");
    const [name, setName] = useState("");
    const [content, setContent] = useState("");
    const [cus_name, setCusName] = useState('');

    const validationSchema = yup.object({});

    const submitForm = () => {
        dispatch(createTicket(values))
    }

    const { values, handleSubmit, handleChange } =
    useFormik({
      initialValues: {
        phone: phone,
        name: name,
        content: content,
        cus_name: cus_name,
        trangthai: status,
        assigned_to: assign,
        priorityname: priority,
      },
      validationSchema: validationSchema,
      onSubmit: submitForm,
    });



  return (
    <div className="screen modify-label">
    <Paper className="order-list" style={{ marginBottom: "10px" }}>
        <div className="ul-heading-placeholder">
          <span
            className="ul-heading"
            style={{ display: "flex", flex: "inherit", fontWeight: 500 }}
          >
            Tạo phiếu ghi
          </span>
        </div>
      </Paper>
      <Paper
        sx={{ width: "100%", mb: 2 }}
        elevation={0}
        className={`${classes.tableBorder}`}
      >
        <Grid container rowSpacing={1} columnSpacing={{ sm: 2 }}>
          <Grid item xs={12} sm={7}>
            <Grid container columnSpacing={{ sm: 1 }}>
              <Grid item xs={12} sm={6}>
                <BoxInput>
                  <TitleInput>Tên</TitleInput>
                  <TextField
                    name="cus_name"
                    size="small"
                    fullWidth
                    onChange={handleChange}
                    value = {values.cus_name}
                  />
                </BoxInput>
              </Grid>
              <Grid item xs={12} sm={6}>
                <BoxInput>
                  <TitleInput>Số điện thoại</TitleInput>
                  <TextField
                    name="phone"
                    size="small"
                    fullWidth
                    onChange={handleChange}
                    value = {values.phone}
                  />
                </BoxInput>
              </Grid>
              
              
             
              <Grid item xs={12} sm={4}>
              <BoxInput>
                  <TitleInput>Giao cho</TitleInput>
                  <AssignToSelect
                    value={values.assigned_to}
                    onChange={handleChange}
                  />
                </BoxInput>
              </Grid>
              <Grid item xs={12} sm={4}>
                <TitleInput>Ưu tiên</TitleInput>

                <Select
                  style={{ fontSize: 14 }}
                  labelId="priorityname"
                  fullWidth
                  size="small"
                  label="Ưu tiên"
                  name="priorityname"
                  value={values.priorityname}
                  onChange={handleChange}
                >
                  {priorityData.map((data, index) => (
                    <MenuItem key={index} value={data.id}>
                      {data.label}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={12} sm={4}>
                <TitleInput>Trạng thái</TitleInput>

                <Select
                  style={{ fontSize: 14 }}
                  labelId="trangthai"
                  fullWidth
                  size="small"
                  label="Trạng thái"
                  name="trangthai"
                  value={values.trangthai}
                  onChange={handleChange}
                >
                  {statusData.map((data, index) => (
                    <MenuItem key={index} value={data.id}>
                      {data.label}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={5}>
            <Grid container>
              <Grid item xs={12}>
                <div className="box-note" style={{ marginBottom: "10px" }}>
                
          <span
            className="ul-heading"
            style={{ display: "flex", flex: "inherit", fontWeight: 500, padding:0 }}
          >
            Nội dung phiếu ghi
          </span>
       
                  <BoxInput>
                    <TitleInput>Tên phiếu ghi</TitleInput>
                    <TextField
                      name="name"
                      size="small"
                      fullWidth
                      placeholder="Tên phiếu ghi"
                      onChange={handleChange}
                      
                    />
                  </BoxInput>

                  <BoxInput>
                    <TitleInput>Ghi chú</TitleInput>
                    <TextareaAutosize
                      minRows={1}
                      id="careContent"
                      name="content"
                      size="small"
                      placeholder="Nhập nội dung"
                      variant="outlined"
                      style={{ border: "none !important", fontSize: "14px" }}
                      onChange={handleChange}
                      
                    />
                  </BoxInput>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <CustomButton
            variant="contained"
            type="submit"
            onClick={() => handleSubmit()}
            style={{ marginLeft: "auto" }}
          >
            Tạo phiếu
          </CustomButton>
      </Paper>
      </div>
  )
}

export default TicketCreate