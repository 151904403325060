import { Dialog, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material';
import CloseIcon from "@mui/icons-material/Close";
import React from 'react';
import Spinner from '../../components/Common/Spinner/Spinner'
import { makeStyles } from "@material-ui/core/styles";
import success from "../../assets/images/pu-sucess.png";
const useStyles = makeStyles((theme) => ({
    customModalContent:{
      paddingLeft:'15px',
      paddingRight:'15px'
    },
    customModal:{
      width:'calc(100% - 20px)',
      marginLeft:'10px',
      marginRight:'10px'
    },
    customModalTitle:{
      padding:'10px 15px',
      color:'#fff',
      background:process.env.NODE_ENV === 'development' ? "#5a8dee" : process.env.REACT_APP_PRODUCT_BASE_COLOR,
    }
  }));

const DialogAnalyst = ({setOpenDialog, openDialog, itemID, loading}) => {
    const classes = useStyles();
 

    return (
        <div>
            
            <Dialog
                fullWidth
                maxWidth={"xs"}
                open={openDialog}
                onClose={() => setOpenDialog(false)}
                style={{ minHeight: "100%" }}
                classes={{
                    paper: classes.customModal
                }}
            >
                    <DialogTitle className={`${classes.customModalTitle}`}>
                    {
                      loading ? 
                      'Đang phân tích dữ liệu cuộc gọi...' : 'Thành công'
                    }
                    
                        
                        <IconButton
                            sx={{
                                position: 'absolute!important',
                                right: 8,
                                top: 8,
                                color: '#fff',
                            }}
                            onClick={() => setOpenDialog(false)}
                        >
                        <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent className={`${classes.customModalContent}`}>
                        {
                          loading ? <Spinner /> : 
                          <>
                          <img
                            src={success}
                            style={{margin:'20px auto'}}
                          />
        <Typography align="center">Phân tích cuộc gọi thành công.</Typography>
                          </>
                          }
                    </DialogContent>
                </Dialog>
        </div>
    );
};

export default DialogAnalyst;