import * as types from "./type"

export const getStatusLeadId = (data) => ({
        type: types.GET_STATUS_LEAD_ID,
        payload: data
})
export const getStatusLeadIdSuccess = (data) => ({
    type: types.GET_STATUS_LEAD_ID_SUCCESS,
    payload: data
})
export const getStatusLeadIdFail = (data) => ({
    type: types.GET_STATUS_LEAD_ID_FAIL,
    payload: data
})
//create
export const createStatusLeadId = (data) => ({
        type: types.CREATE_STATUS_LEAD_ID,
        payload: data
})
export const createStatusLeadIdSuccess = (data) => ({
    type: types.CREATE_STATUS_LEAD_ID_SUCCESS,
    payload: data
})
export const createStatusLeadIdFail = (data) => ({
    type: types.CREATE_STATUS_LEAD_ID_FAIL,
    payload: data
})