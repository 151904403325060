import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Table,
  TableBody,
  TableContainer,
  IconButton,
  TablePagination,
  TableRow,
  Typography,
  Paper,
} from "@mui/material";

import { makeStyles } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import { useTheme } from "@mui/material/styles";
import MuiTableCell from "@material-ui/core/TableCell";
import MuiTableHead from "@mui/material/TableHead";
import Tooltip from "@mui/material/Tooltip";
import { CustomButton } from "../../components/Common/CustomButton";
import LastPageIcon from "@mui/icons-material/LastPage";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import { useDispatch, useSelector } from "react-redux";
import { getTicket } from "../../redux/ticket/action";
import TicketDetail from "./TicketDetail";

const useStyles = makeStyles((theme) => ({
  tableBorder: {
    border: "1px solid #d4d8dd",
    borderRadius: "0.5rem",
  },
  btnGlobal: {
    minWidth: "inherit",
    width: 28,
    padding: "0",
    height: "28px",
    lineHeight: "28px",

    marginLeft: 5,
    boxShadow: "none",
    fontSize: "12px",
    fontWeight: "500",
  },
  btnBlue: { backgroundColor: "#556ee6" },
  // btnGreen:{
  //   backgroundColor: '#34c38f',
  // },
  btnYellow: {
    backgroundColor: "#f1b44c",
  },
  btnRed: {
    backgroundColor: "#f46a6a",
  },
  Pagination: {
    display: "flex",
  },
}));

const TableCell = withStyles({
  root: {
    borderBottom: "1px solid rgb(235, 237, 242)",
    padding: "5px 10px",
    lineHeight: "18px",
  },
})(MuiTableCell);

const TableHead = withStyles((theme) => ({
  root: {
    backgroundColor: "rgba(38,60,85,.1)",
  },
}))(MuiTableHead);

const TableHeaderCell = withStyles((theme) => ({
  root: {
    color: "#516377",
    fontWeight: 700,
    padding: "10px",
    fontSize: ".75rem",
    textTransform: "uppercase",
    letterSpacing: "0.8px",
    lineHeight: "18px",
  },
}))(TableCell);

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const TicketList = ({ directSelected, dataLogoFooter }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [openDetailTicket, setOpenDetailTicket] = useState(false);
  const [phone, setPhone] = useState([]);
  const [selected, setSelected] = useState([]);
  const [item, setItem] = useState([]);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const [dataPagination, setDataPagination] = useState({
    offset: 0,
    limit: 10,
  });

  const handleChangePage = (event, newPage) => {
    let data = {
      offset: newPage * rowsPerPage,
      limit: rowsPerPage,
    };
    dispatch(getTicket(data));
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    let data = {
      offset: page,
      limit: event.target.value !== "-1" ? +event.target.value : 9999,
    };
    dispatch(getTicket(data));
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const listTicket = useSelector((item) => item.ticketReducer.dataList.tiket);

  const getBackgroundColor = (status) => {
    if (status === "Trung bình") {
      return "bg-success";
    }
    if (status === "Khẩn cấp") {
      return "bg-c-ibiza";
    }
    if (status === "Cao") {
      return "bg-failed";
    }
    if (status === "Thấp") {
      return "bg-pending";
    }
    if (status === "Mới") {
      return "bg-success";
    }
    if (status === "Đang chờ") {
      return "bg-pending";
    }
    if (status === "Đang xử lý") {
      return "bg-pending";
    }
    if (status === "Đã đóng") {
      return "bg-failed";
    }
  };

  const editSucess = useSelector((item) => item.ticketReducer.editLoad);

  //   delete
  useEffect(() => {
    if (editSucess === true) {
      dispatch(getTicket());
    }
    // eslint-disable-next-line
  }, [editSucess]);

  useEffect(() => {
    dispatch(getTicket());

    // eslint-disable-next-line
  }, []);

  return (
    <>
      {openDetailTicket ? (
        <>
          <CustomButton
            style={{
              marginBottom: "15px",
              minWidth: "inherit",
              pading: "0 6px",
            }}
            onClick={() => {
              setOpenDetailTicket(false);
              dispatch(getTicket());
            }}
          >
            <KeyboardArrowLeft />
          </CustomButton>

          <TicketDetail
            phoneNumber={phone}
            dataLogoFooter={dataLogoFooter}
            directSelected={directSelected}
            idTicket={selected}
            itemDetail={item}
          />
        </>
      ) : (
        <>
          {listTicket?.tickets?.length > 0 ? (
            <>
              <Paper className="order-list" style={{ marginBottom: "10px" }}>
                <div className="ul-heading-placeholder">
                  <span
                    className="ul-heading"
                    style={{
                      display: "flex",
                      flex: "inherit",
                      fontWeight: 500,
                    }}
                  >
                    Danh sách phiếu ghi
                  </span>
                </div>
              </Paper>
              <Paper
                sx={{ width: "100%", mb: 2 }}
                elevation={0}
                className={`${classes.tableBorder}`}
              >
                <TableContainer
                  style={{
                    width: "100%",
                    overflowX: "auto",
                    borderRadius: "0.5rem",
                  }}
                >
                  <Table sx={{ minWidth: 700 }} size={"small"}>
                    <TableHead>
                      <TableRow>
                        <TableHeaderCell align="left" style={{ width: 120 }}>
                          PHIẾU GHI
                        </TableHeaderCell>

                        <TableHeaderCell align="left" style={{ minWidth: 40 }}>
                          LIÊN HỆ
                        </TableHeaderCell>
                        <TableHeaderCell align="left" style={{ minWidth: 40 }}>
                          TRẠNG THÁI
                        </TableHeaderCell>
                        <TableHeaderCell align="left" style={{ width: 100 }}>
                          ƯU TIÊN
                        </TableHeaderCell>
                        <TableHeaderCell align="left" style={{ minWidth: 60 }}>
                          THỜI GIAN TẠO
                        </TableHeaderCell>

                        <TableHeaderCell align="left" style={{ minWidth: 120 }}>
                          Hành động
                        </TableHeaderCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {listTicket?.tickets &&
                        listTicket?.tickets.map((item, index) => (
                          <TableRow hover={true} key={index}>
                            <TableCell align="left">{item?.name}</TableCell>

                            <TableCell align="left">{item?.phone}</TableCell>
                            <TableCell align="left">
                              <div
                                className={`text-status ${getBackgroundColor(
                                  item?.trangthai
                                )}`}
                              >
                                {item?.trangthai}
                              </div>{" "}
                            </TableCell>
                            <TableCell align="left">
                              <div
                                className={`text-status ${getBackgroundColor(
                                  item?.priority
                                )}`}
                              >
                                {item?.priority}
                              </div>
                            </TableCell>
                            <TableCell align="left">
                              {item?.create_date}
                            </TableCell>
                            <TableCell align="center">
                              <Tooltip title="Chi tiết">
                                <CustomButton
                                  variant="contained"
                                  className={`${classes.btnGlobal} ${classes.btnYellow}`}
                                  onClick={() => {
                                    setOpenDetailTicket(true);
                                    setPhone(item?.phone);
                                    setSelected(item?.id);
                                    setItem(item);
                                  }}
                                >
                                  <i
                                    style={{ fontSize: "10px" }}
                                    className="fas fa-eye"
                                  ></i>
                                </CustomButton>
                              </Tooltip>
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[10, 25]}
                  className={`${classes.Pagination}`}
                  component="div"
                  colSpan={3}
                  labelRowsPerPage={"Số hàng trên 1 trang:"}
                  count={listTicket?.tickets_count}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: {
                      "aria-label": "rows per page",
                    },
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </Paper>
            </>
          ) : (
            <Typography>Dữ liệu chưa đồng bộ</Typography>
          )}
        </>
      )}
    </>
  );
};

export default TicketList;
