import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Checkbox,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { CustomButton } from "../../../../components/Common/CustomButton";


import styled from "styled-components";
import { useFormik } from "formik";
import * as Yup from "yup";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { createCallstatus, getCallstatus } from "../../../../redux/callstatus/action";


export const TitleInput = styled("p")(({ theme }) => ({
    fontSize: 14,
    fontWeight: 500,
    marginBottom: 10,
  }));
  
  export const BoxInput = styled("div")(({ theme }) => ({
    
    marginBottom:'10px'
  }));
const useStyles = makeStyles((theme) => ({
    textFieldError: {
        "& input": {
          border: "1px solid red",
          color: "red",
        },
        "& p": {
          color: "red",
          marginLeft:0,
        },
      },
  customModalContent: {
    paddingLeft: "15px",
    paddingRight: "15px",
  },
  customModal: {
    width: "calc(100% - 20px)",
    marginLeft: "10px",
    marginRight: "10px",
  },
  customModalTitle: {
    padding: "10px 15px",
    color: "#fff",
    background: process.env.NODE_ENV === 'development' ? "#5a8dee" :process.env.REACT_APP_PRODUCT_BASE_COLOR,
    marginBottom:'15px',
  },
}));

const validationSchema = Yup.object({
    
    
  });

const CallStatusCreate = (props) => {
  const classes = useStyles();
  const [active, setActive] = useState(false);
  const dispatch = useDispatch();

  const { values, handleSubmit, handleChange,setFieldValue, handleBlur, errors, touched } =
  useFormik({
    initialValues: {
      title: "",
      is_default: active,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      
      dispatch(createCallstatus(values));
    },
  });
  const createSuccess = useSelector(
    (item) => item.callstatusReducer.createSuccess
  );

  useEffect(() => {
    if (createSuccess) {
      props.onClose();
      dispatch(getCallstatus())
    }
  // eslint-disable-next-line
  }, [createSuccess]);



  return (
    <Dialog
      fullWidth={true}
      maxWidth={"xs"}
      open={true}
      onClose={props.onClose}
      style={{ minHeight: "100%" }}
      classes={{
        paper: classes.customModal,
      }}
    >
      <DialogTitle className={`${classes.customModalTitle}`}>
        Trạng Thái Cuộc Gọi
        <IconButton
          onClick={props.onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: '#fff',
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={`${classes.customModalContent}`}>
          

      <BoxInput>
            <TitleInput>Tên Trạng Thái</TitleInput>
            <TextField
              size="small"
              name="title"
              fullWidth
              placeholder="Tên Trạng Thái"
              onChange={handleChange}
              value={values.title}
            />
          </BoxInput>
          
         
          <BoxInput style={{display:'flex'}}>
            <TitleInput>Status</TitleInput>
            
              <Checkbox style={{marginTop:'-12px'}}
                id="activate"
                name="activate"
                checked={active}
                onChange={(e) => {
                  setFieldValue(
                    "is_default",
                    e.currentTarget.checked
                  );
                  setActive(e.currentTarget.checked);
                }}
                />
          </BoxInput>
          <CustomButton
            // color="primary"
            variant="contained"
            type="submit"
            onClick={() => handleSubmit()}
            style={{marginLeft:'auto'}}
          >
            Thêm 
          </CustomButton>

      </DialogContent>
    </Dialog>
  );
};

export default CallStatusCreate;