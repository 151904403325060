import axios from "axios";

export const baseURL = `${process.env.REACT_APP_API_URL}api/`;
const axiosInstance = axios.create({
  baseURL: baseURL,
  timeout: 5000,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

// Add a request interceptor
axiosInstance.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    const token = localStorage.getItem('access_token');
    // const token = process.env.TOKEN;
    config.headers.Authorization = token ? 'JWT ' + localStorage.getItem('access_token') : '';
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);
axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    const originalRequest = error.config;
    console.log(originalRequest);
    console.log(error.response);
    // Prevent infinite loops
    if (error.response.status === 401 && error.response.data.detail === 'Token is invalid or expired') {
      window.location.href = '/logout';
      return Promise.reject(error);
    }

    if (error.response.status === 401 && !originalRequest._retry) {
      const refreshToken = localStorage.getItem('refresh_token');

      if (refreshToken) {
        const tokenParts = JSON.parse(atob(refreshToken.split('.')[1]));

        // exp date in token is expressed in seconds, while now() returns milliseconds:
        const now = Math.ceil(Date.now() / 1000);

        if (tokenParts.exp > now) {
          return axiosInstance
            .post('auth/refresh/', { refresh: refreshToken })
            .then((response) => {
              localStorage.setItem('access_token', response.data.access);
              localStorage.setItem('refresh_token', response.data.refresh);

              axiosInstance.defaults.headers['Authorization'] = 'JWT ' + response.data.access;
              originalRequest.headers['Authorization'] = 'JWT ' + response.data.access;

              return axiosInstance(originalRequest);
            })
            .catch((err) => {
              //console.log(err);
            });
        } else {
          //console.log("Refresh token is expired", tokenParts.exp, now);
          window.location.href = '/logout/';
        }
      } else {
        //console.log("Refresh token not available.");
        // window.location.href = '/logout/';
      }
    }

    // specific error handling done elsewhere
    return Promise.reject(error);
  }
);

export default axiosInstance;
