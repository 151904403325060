import * as types from "./type";

const initialState = {
  data: [],
  loading: false,
};

export const dataModuleReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_MODULE:
      return {
        ...state,
        ...{ loading: true },
      };

    case types.GET_MODULE_SUCCESS:
      return {
        ...state,
        ...{
          data: action.payload.modules,
          loading: false,
        },
      };

    case types.GET_MODULE_FAIL: {
      return {
        ...state,
        ...{ data: [], loading: false },
      };
    }
    default:
      return state;
  }
};
