import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
} from "@mui/material";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";
import Tooltip from "@mui/material/Tooltip";
import IosShareOutlinedIcon from "@mui/icons-material/IosShareOutlined";
import TextField from "@mui/material/TextField";
import Divider from "@mui/material/Divider";

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../../components/Common/Loading";
import OrderListTable from "./components/table";
import { BoxInput } from "../Customer/components/DialogCustomer/styled";
import { CustomButton } from "../../components/Common/CustomButton";
import { getOrders } from "../../redux/order/action";
import Excel from "exceljs";
import { saveAs } from "file-saver";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  btnIcon: {
    fontSize: 16,
  },
  iconMenuItem: {
    marginRight: 10,
  },
  btnGlobal: {
    minWidth: "inherit",
    width: 36,
    height: 36,
    lineHeight: 36,
    padding: 0,

    marginLeft: 5,
  },
}));

export const PAID_STATUS = [
  { value: true, title: "Đã thanh toán" },
  { value: false, title: "Chưa thanh toán" },
];

export default function OrderManagenent({dataLogoFooter}) {
  const classes = useStyles();
  const [isLoading, setLoading] = useState(true);
  const [response, setResponse] = useState("waiting");
  const [purchase, setPurchase] = React.useState("chuaTT");
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [value, setValue] = React.useState([null, null]);
  const [phone, setPhone] = useState(null);

  const initialValueSearch = {
    order_no: "",
    phone: "",
    paid: "",
    ref_code: "",
  };

  const [search, setSearch] = useState(initialValueSearch);

  const dispatch = useDispatch();

  const ordersData = useSelector((state) => state.orderReducer);
  const workSheetName = "Danh sách đơn hàng";
  const workBookName = "DanhSachDonHang";
  const myInputId = "myInput";
  const workbook = new Excel.Workbook();
  const columns = [
    { header: "STT", key: "stt" },
    { header: "MÃ ĐƠN HÀNG", key: "order_no" },
    { header: "TÊN KHÁCH HÀNG", key: "fullName" },
    { header: "SỐ ĐIỆN THOẠI", key: "phone" },
    { header: "TỔNG TIỀN", key: "total" },
    { header: "KHUYẾN MÃI", key: "discount" },
    { header: "TRẠNG THÁI THANH TOÁN", key: "paid" },
    { header: "MÃ CHỨNG TỪ", key: "ref_code" },
  ];

  const [dataPagination, setDataPagination] = useState({ offset: 0, limit: 5 });

  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    setSearch({ ...search, [name]: value });
  };

  // const handleChangeRowsPerPage = (event) => {
  //   setRowsPerPage(event.target.value);
  // };

  const handleSearch = () => {
    const valueSearch = {
      ...search,
      offset: dataPagination.offset,
      limit: dataPagination.limit,
    };
    dispatch(getOrders(valueSearch));
    if (openSidebar) setOpenSidebar(false);
  };
  const [openSidebar, setOpenSidebar] = useState(false);
  useEffect(() => {
    setTimeout(() => setLoading(false), 500);
    let data = {
      offset: 0,
      limit: 10,
    };
    dispatch(getOrders(data));
  }, []);

  const saveExcel = async () => {
    // const newDataCreditToExport =
    try {
      const myInput = document.getElementById(myInputId);
      const fileName = myInput.value || workBookName;

      // creating one worksheet in workbook
      const worksheet = workbook.addWorksheet(workSheetName);

      // add worksheet columns
      // each columns contains header and its mapping key from data
      worksheet.columns = columns;

      // updated the font for first row.
      worksheet.getRow(1).font = { bold: true };

      // loop through all of the columns and set the alignment with width.
      worksheet.columns.forEach((column) => {
        column.width = column.header.length + 5;
        column.alignment = { horizontal: "center" };
      });

      // loop through data and add each one to worksheet
      ordersData.dataList.orders.forEach((order, index) => {
        const newData = {
          stt: index,
          order_no: order.order_no,
          fullName: order.lead.lead.fullname,
          phone: order.lead.lead.phone,
          total: order.total,
          discount: order.discount,
          paid: order.paid ? "Đã thanh toán" : "Chưa thanh toán",
          ref_code: order.ref_code,
        };
        worksheet.addRow(newData);
      });

      // loop through all of the rows and set the outline style.
      worksheet.eachRow({ includeEmpty: false }, (row) => {
        // store each cell to currentCell
        const currentCell = row._cells;

        // loop through currentCell to apply border only for the non-empty cell of excel
        currentCell.forEach((singleCell) => {
          // store the cell address i.e. A1, A2, A3, B1, B2, B3, ...
          const cellAddress = singleCell._address;

          // apply border
          worksheet.getCell(cellAddress).border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" },
          };
        });
      });

      // write the content using writeBuffer
      const buf = await workbook.xlsx.writeBuffer();

      // download the processed file
      saveAs(new Blob([buf]), `${fileName}.xlsx`);
    } catch (error) {
      console.error("<<<ERRROR>>>", error);
      console.error("Something Went Wrong", error.message);
    } finally {
      // removing worksheet's instance to create new one
      workbook.removeWorksheet(workSheetName);
    }
  };

  return (
    <>
      <div className="screen">
        <Loading loading={isLoading} />

        <Paper className="order-list" style={{ marginBottom: "10px" }}>
          <div className="ul-heading-placeholder">
            <input
              style={{ display: "none" }}
              id={myInputId}
              defaultValue={workBookName}
            />
            <span
              className="ul-heading"
              style={{ display: "flex", alignItems: "center", fontWeight: 500 }}
            >
              Danh Sách Đơn Hàng
            </span>
            <div className="box-right">
              <Tooltip title="Tải xuống">
                <CustomButton
                  variant="contained"
                  className={`${classes.btnGlobal} ${classes.btnBlue}`}
                  onClick={saveExcel}
                >
                  <IosShareOutlinedIcon className={`${classes.btnIcon}`} />
                </CustomButton>
              </Tooltip>
              <CustomButton
                variant="contained"
                className={`${classes.btnGlobal} ${classes.btnBlue}`}
                onClick={() => setOpenSidebar(!openSidebar)}
              >
                <FilterAltIcon className={`${classes.btnIcon}`} />
              </CustomButton>
              <div
                className={`boxsearch-sidebar ${openSidebar ? "active" : ""}`}
              >
                <span
                  className="ul-heading"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    fontWeight: 500,
                  }}
                >
                  <ArrowBackIosNewOutlinedIcon
                    style={{
                      color: "#5a8dee",
                      marginRight: 5,
                      cursor: "pointer",
                    }}
                    onClick={() => setOpenSidebar(!openSidebar)}
                  />
                  Tìm kiếm
                </span>
                <Divider sx={{ display: { xs: 'block', sm: 'none' } }}/>
                <Grid
                  container
                  columnSpacing={{ xs: 1,sm: 1.5 }}
                  style={{ padding: "0 14px" }}
                >
                  <Grid item xs={12} sm={6} style={{marginTop:'20px'}}>
                  <BoxInput style={{ maxWidth: "100%" }}>
                      <TextField
                        id={search.order_no}
                        name={"order_no"}
                        size="small"
                        fullWidth
                        placeholder={"Nhập mã đơn hàng"}
                        onChange={handleChangeInput}
                      />
                    </BoxInput>
                    <BoxInput style={{ maxWidth: "100%" }}>
                      <TextField
                        id={search.phone}
                        name={"phone"}
                        size="small"
                        fullWidth
                        placeholder={"Nhập sdt khách hàng"}
                        onChange={handleChangeInput}
                      />
                    </BoxInput>
                  </Grid>

                  <Grid item xs={12} sm={6} sx={{ marginTop:{sm:'20px'}}}>
                  <BoxInput style={{ maxWidth: "100%" }}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Trạng thái thanh toán
                        </InputLabel>
                        <Select
                          label="Trạng thái thanh toán"
                          name="paid"
                          value={search.paid}
                          onChange={handleChangeInput}
                          size="small"
                        >
                          {PAID_STATUS?.length > 0
                            ? PAID_STATUS.map((item) => (
                                <MenuItem value={item.value}>
                                  {item.title}
                                </MenuItem>
                              ))
                            : null}
                        </Select>
                      </FormControl>
                    </BoxInput>
                    <BoxInput style={{ maxWidth: "100%" }}>
                      <TextField
                        id={search.ref_code}
                        name={"ref_code"}
                        size="small"
                        fullWidth
                        placeholder={"Nhập mã chứng từ"}
                        onChange={handleChangeInput}
                      />
                    </BoxInput>
                  </Grid>
                  <Grid item xs={12}>
                    <CustomButton
                        variant="contained"
                        className="search-row-button"
                        onClick={handleSearch}
                      >
                      Tìm kiếm
                    </CustomButton>
                  </Grid>
                </Grid>
                
              </div>
            </div>
          </div>
        </Paper>

        <Paper className="user-list">
          <OrderListTable
            ordersData={ordersData}
            // setDetailCustomer={setDetailCustomer}
            setPhone={setPhone}
            phone={phone}
            dataPagination={dataPagination}
            setDataPagination={setDataPagination}
            dataLogoFooter={dataLogoFooter}
          />
          
        </Paper>
      </div>
    </>
  );
}
