import styled from "styled-components";

export const BoxBody = styled.div`

        .side-menu .Mui-selected{
    background-color: ${process.env.NODE_ENV === 'development' ? "#e5edfc" : process.env.REACT_APP_PRODUCT_BASE_COLOR2} !important;
}



 .side-menu .Mui-selected:before {
  background:${process.env.NODE_ENV === 'development' ? "#5a8dee" : process.env.REACT_APP_PRODUCT_BASE_COLOR} !important;
}

 .side-menu .Mui-selected > div > p,  
 .side-menu .Mui-selected > div > svg{
  color: ${process.env.NODE_ENV === 'development' ? "#5a8dee" : process.env.REACT_APP_PRODUCT_BASE_COLOR} !important;
}

 .customer-overview .item .box-icon{
    background:${process.env.NODE_ENV === 'development' ? "#5a8dee" : process.env.REACT_APP_PRODUCT_BASE_COLOR};

}

 .modal-boxupload .content-upload{
  color:${process.env.NODE_ENV === 'development' ? "#e5edfc" : process.env.REACT_APP_PRODUCT_BASE_COLOR};
}

 .zalo-wrapper .sidebar-nav .menuItem.active{
  background-color: ${process.env.NODE_ENV === 'development' ? "#e5edfc" : process.env.REACT_APP_PRODUCT_BASE_COLOR2};
    color: ${process.env.NODE_ENV === 'development' ? "#5a8dee" : process.env.REACT_APP_PRODUCT_BASE_COLOR};
}
 .zalo-wrapper .sidebar-nav .menuItem.active:before{
  background: ${process.env.NODE_ENV === 'development' ? "#5a8dee" : process.env.REACT_APP_PRODUCT_BASE_COLOR};
}


 .tabs-settings .icon-box.active{
  border-top: 2px solid ${process.env.NODE_ENV === 'development' ? "#5a8dee" : process.env.REACT_APP_PRODUCT_BASE_COLOR};
    background: ${process.env.NODE_ENV === 'development' ? "#e5edfc" : process.env.REACT_APP_PRODUCT_BASE_COLOR2};
    color: ${process.env.NODE_ENV === 'development' ? "#5a8dee" : process.env.REACT_APP_PRODUCT_BASE_COLOR};
}


 .box-icon{
  color: ${process.env.NODE_ENV === 'development' ? "#5a8dee" : process.env.REACT_APP_PRODUCT_BASE_COLOR};
}
 .title-icon{
  background: ${process.env.NODE_ENV === 'development' ? "#e5edfc" : process.env.REACT_APP_PRODUCT_BASE_COLOR2};
}
 .notify-list .item .info .source{
  color: ${process.env.NODE_ENV === 'development' ? "#5a8dee" : process.env.REACT_APP_PRODUCT_BASE_COLOR};
}
 .customer-overview .item{
  border-left: 3px solid ${process.env.NODE_ENV === 'development' ? "#5a8dee" : process.env.REACT_APP_PRODUCT_BASE_COLOR};
}

 .modal-boxupload .form-upload{
  border: 2px dashed ${process.env.NODE_ENV === 'development' ? "#e5edfc" : process.env.REACT_APP_PRODUCT_BASE_COLOR2};
}
.box-drp .menuExpand .menuItem:hover{
  background: ${process.env.NODE_ENV === 'development' ? "#e5edfc" : process.env.REACT_APP_PRODUCT_BASE_COLOR2};  
  color: ${process.env.NODE_ENV === 'development' ? "#5a8dee" : process.env.REACT_APP_PRODUCT_BASE_COLOR};
}
.logo{
  color: ${process.env.NODE_ENV === 'development' ? "#4b495e" : process.env.REACT_APP_PRODUCT_TEXT_COLOR};

}
.notify-list .item:hover{
  background: ${process.env.NODE_ENV === 'development' ? "#e5edfc" : process.env.REACT_APP_PRODUCT_BASE_COLOR2};

}

`

 export const PhoneRegisterText = styled.div`

@media(max-width:480px){
  .text-sm{
    position: fixed;
    top: 60px;
    right: 15px;
  }
}
 `
