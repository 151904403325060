import { put, takeLatest } from "redux-saga/effects";
import * as types from './type';
import * as actions from './action';
import axiosInstance from "../../components/axiosApi";
import { showMessageSuccess } from "../notification/action";

function* getLeadStatusId(payload) {
    const id = payload;
    try {
        const res = yield axiosInstance.get(`/leads/sourcessub/${id.payload}/`)
        yield put(actions.getLeadStatusIdSuccess(res.data))
    } catch (error) {
        yield put(actions.getLeadStatusIdFail(error))
    }
}

function* createLeadStatusId({payload}) {
    try {
        const res = yield axiosInstance.post(`/leads/sourcessub/${payload.parent_code}/`, payload)
        yield put(actions.createLeadStatusIdSuccess(res.data))
        yield put(showMessageSuccess("Thêm thành công"))
    } catch (error) {
        yield put(actions.createLeadStatusIdFail(error))
    }
}

export function* watchingLeadStatusId() {
    yield takeLatest(types.GET_LEAD_STATUS_ID, getLeadStatusId);
    yield takeLatest(types.CREATE_LEAD_STATUS_ID, createLeadStatusId);
}