import { styled } from "@mui/material/styles";

export const TitleInput = styled("p")(({ theme }) => ({
  fontSize: 14,
  fontWeight: 500,
  textAlign:'left',
  marginBottom: 10,
  
}));

export const BoxInput = styled("div")(({ theme }) => ({
  marginBottom: 15,
}));
