import * as types from "./type"

export const getDepartmentsId = (data) => ({
        type: types.GET_DEPARTMENTS_ID,
        payload: data
})
export const getDepartmentsIdSuccess = (data) => ({
    type: types.GET_DEPARTMENTS_ID_SUCCESS,
    payload: data
})
export const getDepartmentsIdFail = (data) => ({
    type: types.GET_DEPARTMENTS_ID_FAIL,
    payload: data
})
//CREATE
export const createPositions = (data) => ({
        type: types.CREATE_POSITIONS,
        payload: data
})
export const createPositionsSuccess = (data) => ({
    type: types.CREATE_POSITIONS_SUCCESS,
    payload: data
})
export const createPositionsFail = (data) => ({
    type: types.CREATE_POSITIONS_FAIL,
    payload: data
})

//EDIT
export const putPositions = (data) => ({
    type: types.PUT_POSITIONS,
    payload: data
})
export const putPositionsSuccess = (data) => ({
    type: types.PUT_POSITIONS_SUCCESS,
    payload: data
})
export const putPositionsFail = (data) => ({
    type: types.PUT_POSITIONS_FAIL,
    payload: data
})