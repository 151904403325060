import * as types from "./type"

export const getProductsData = (arrayChecked, valueSearch) => ({
    type: types.GET_PRODUCTS_START,
    payload: {
        arrayChecked,
        valueSearch
    }
})

export const getProductsSuccess = (arrayChecked, responseProducts) => ({
    type: types.GET_PRODUCTS_SUCCESS,
    payload: {
        arrayChecked,
        responseProducts
    }
})

export const getProductsError = (data) => ({
    type: types.GET_PRODUCTS_ERROR,
    payload: data
})

export const searchProductsStart = (arrayChecked, valueSearch) => ({
    type: types.START_SEARCH_PRODUCTS,
    payload: {
        arrayChecked,
        valueSearch
    }
})

export const checkedProduct = (data) => ({
    type: types.CHECKED_PRODUCTS,
    payload: data
})
// them
export const postProducts = (data) => ({
    type: types.POST_PRODUCTS,
    payload: data
})
export const postProductsSuccess = (data) => ({
    type: types.POST_PRODUCTS_SUCCESS,
    payload: data
})
export const postProductsError = (data) => ({
    type: types.POST_PRODUCTS_ERROR,
    payload: data
})
//sua
export const putProducts = (data) => ({
    type: types.PUT_PRODUCTS,
    payload: data
})
export const putProductsSuccess = (data) => ({
    type: types.PUT_PRODUCTS_SUCCESS,
    payload: data
})
export const putProductsError = (data) => ({
    type: types.PUT_PRODUCTS_ERROR,
    payload: data
})