import { put, takeLatest } from "redux-saga/effects";
import * as types from './type';
import * as actions from './action';
import axios from "axios";
import axiosInstance from "../../components/axiosApi";
// import { URL_API } from "../../utils/helper";

function* getListCityData() {
    // const { city } = payload;
    try {
        const res = yield axiosInstance.get(`/cities/?limit=63`);
        // const data = yield res.json();
        if(res){
            yield put(actions.getListCitySuccess(res.data.results))
        }
    } catch (error) {
        //console.log(error)
        yield put(actions.getListCityFail("fail"));
    }
}

export function* watchingGetListCityData() {
    yield takeLatest(types.GET_LIST_CITY, getListCityData);
}