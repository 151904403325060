import * as types from "./type";

export const getUserStart = () => ({
  type: types.GET_USERS_START,
});
export const getUserSuccess = (data) => ({
  type: types.GET_USERS_SUCCESS,
  payload: data,
});
export const getUserError = (data) => ({
  type: types.GET_USERS_ERROR,
  payload: data,
});

//create
export const createUserStart = (data) => ({
  type: types.CREATE_USERS_START,
  payload: data,
});
export const createUserSuccess = (data) => ({
  type: types.CREATE_USERS_SUCCESS,
  payload: data,
});
export const createUserError = (data) => ({
  type: types.CREATE_USERS_ERROR,
  payload: data,
});

//update user
export const updateUser = (data) => ({
  type: types.UPDATE_USERS,
  payload: data,
});
export const updateUserSuccess = (data) => ({
  type: types.UPDATE_USERS_SUCCESS,
  payload: data,
});
export const updateUserError = (data) => ({
  type: types.UPDATE_USERS_ERROR,
  payload: data,
});

export const updateRegister = () => ({
  type: types.UPDATE_REGISTER_START,
})


export const updateRegisterCall = () => ({
  type: types.UPDATE_REGISTER_CALL,
})

export const updateRegisteringCall = () => ({
  type: types.UPDATE_REGISTERING_CALL,
})


export const updateUnregisterCall = () => ({
  type: types.UPDATE_UNREGISTER_CALL,
})