import { TextareaAutosize } from "@mui/material";
import React, { useState, useEffect, useCallback, useRef } from "react";
import { useContext } from "react";
import { CountContext } from "../Notification/NotifyContent";
import axiosInstance from "../../components/axiosApi";
import { useDispatch } from "react-redux";
import { showMessageError } from "../../redux/notification/action";
import SendRoundedIcon from "@mui/icons-material/SendRounded";
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";
import { CustomButton } from "../../components/Common/CustomButton";
import blank from "../../assets/images/logo.png";
import moment from "moment";
import Spinner from "../../components/Common/Spinner/Spinner";

const ZaloChat = ({
  item,
  idOA,
  openSidebar,
  setOpenSidebar,
  loading,
  setLoading,
}) => {
  // const dataContext = useContext(CountContext);
  const [totalMsg, setTotalMsg] = useState([]);
  const [conversionList, setConversionList] = useState([]);
  const [msg, setMsg] = useState("");
  const dispatch = useDispatch();

  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({
      block: "end",
      behavior: "smooth",
    });
  };

  const getTypeMsg = (type, msg, url) => {
    if (type === "text") {
      return msg;
    }
    if (type === "sticker") {
      return <img src={url} alt="sticky" />;
    }
  };

  const handleSendMsg = useCallback(async () => {
    setLoading(true);
    let params = "";
    if (item.last_msg_id === null) {
      params += `uid=${item?.userID}&message=${msg}`;
    } else {
      params += `uid=${item?.userID}&message=${msg}&msg_id=${item?.last_msg_id}`;
    }
    try {
      const res = await axiosInstance.post(`/zalo/send-msg/${idOA}/?${params}`);
      console.log(res.data.info)
      if (res.status === 200) {
        setMsg("");
        setTimeout(() => {
          setLoading(false);
          getConvertsionList();

          //hien tai api tra ve lau nen set tam 7s
        }, 500);
        
      }
    } catch (error) {
      dispatch(showMessageError("Message send unsuccess"));
    }
    // eslint-disable-next-line
  }, [msg]);

  const getTotalMsg = useCallback(async () => {
    try {
      const res = await axiosInstance.get(
        `/zalo/oa-get-total-msg-free/${idOA}/`
      );
      if (res.status === 200) {
        setTotalMsg(res.data.data);
      }
    } catch (error) {
      dispatch(showMessageError("Lỗi server"));
    }
    // eslint-disable-next-line
  }, []);

  const getConvertsionList = useCallback(async () => {
    try {
      const res = await axiosInstance.get(
        `/zalo/sync-conversation/${idOA}/?uid=${item.userID}`
      );
      if (res.status === 200) {
        setConversionList(res.data);
        setLoading(false);
      }
    } catch (error) {
      dispatch(showMessageError("Lỗi server"));
    }
    // eslint-disable-next-line
  }, [idOA, item.userID]);

  const something = (event) => {
    if (event.keyCode === 13) {
      handleSendMsg();
    }
  };

  useEffect(() => {
    getConvertsionList();
    // dataContext.onLocalZalo(item.userID)
    // eslint-disable-next-line
  }, [item.userID]);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
      scrollToBottom();
    }, 500);
    // eslint-disable-next-line
  }, [conversionList]);

  useEffect(() => {
    getTotalMsg();
    // eslint-disable-next-line
  }, [idOA]);

  // useEffect(() => {
  //   dataContext.checkNotify();
  //   // eslint-disable-next-line
  // }, [conversionList])
  
  return (
    <div className={`wrapper-chat  ${openSidebar ? "active" : ""}`}>
      <div className="chat-box-top">
        <div className="chat-user">
          <ArrowBackIosNewOutlinedIcon
            style={{
              cursor: "pointer",
              position: "absolute",
              left: "10px",
              top: "20px",
              width: "0.8em",
              height: "0.8em",
            }}
            sx={{
              display: { xs: "block !important", sm: "none !important" },
            }}
            onClick={() => setOpenSidebar(!openSidebar)}
          />
          <div className="photo">
            <img src={item?.zimg ? `${item?.zimg}` : blank} alt="" />
          </div>
          <div className="info">
            <div className="username">{item?.zaloName}</div>
            <div className="status online"></div>
          </div>
          <div className="total-msg">
            {totalMsg && (
              <>
                Tin nhắn Free : {""}
                <span style={{ color: "rgb(90, 141, 238" }}>
                  {totalMsg.remain}/{totalMsg.total}
                </span>
              </>
            )}
          </div>
        </div>
      </div>

      <div className="chat-msg">
        {loading ? (
          <Spinner />
        ) : (
          <>
            <div className="msg-content">
              {conversionList?.data?.length > 0 &&
                conversionList?.data
                  .sort((a, b) => (a.time > b.time ? 1 : -1))
                  .map((msg, index) => {
                    return (
                      <div
                        key={index}
                        className={`msg-box-holder ${
                          msg?.src !== 1 ? "text-right" : ""
                        }`}
                      >
                        <div className="msg-seller">
                          <div className="photo">
                            {msg?.src !== 0 ? (
                              <img
                                alt="zalo-img"
                                src={
                                  msg?.from_avatar
                                    ? `${msg?.from_avatar}`
                                    : blank
                                }
                              />
                            ) : null}
                          </div>
                          <div className="msg-box">
                            {getTypeMsg(msg?.type, msg?.message, msg?.url)}
                            <div className="msg-time">
                              {moment(msg?.time).format(" hh:mm A")}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              <div ref={messagesEndRef} />
            </div>
            <div className="chat-bottom">
              <div className="chat-input-holder">
                <TextareaAutosize
                  minRows={1}
                  id="msg"
                  name="msg"
                  size="small"
                  placeholder="Nhập nội dung tin nhắn"
                  value={msg}
                  onChange={(e) => setMsg(e.target.value)}
                  onKeyDown={(e) => something(e)}
                  variant="outlined"
                  style={{ border: "none !important" }}
                />
                <CustomButton
                  onClick={() => handleSendMsg()}
                  style={{
                    width: "36px",
                    height: "36px",
                    minWidth: "inherit",
                    position: "absolute",
                    right: "15px",
                    top: "15px",
                  }}
                >
                  <SendRoundedIcon
                    style={{
                      width: "0.8em",
                      height: "0.8em",
                      marginLeft: "5px",
                    }}
                  ></SendRoundedIcon>
                </CustomButton>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ZaloChat;
