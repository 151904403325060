import * as types from './type'

const initialState = {
  data: [],
  dataList: [],
  loading: false,
  createSuccess: false,
  editLoad: false,
  createAdminCompanySuccess: false,
}

export const companyReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_COMPANIES:
      return {
        ...state,
        ...{ loading: true, createSuccess: false, createAdminCompanySuccess: false },
      }

    case types.GET_COMPANIES_SUCCESS:
      return {
        ...state,
        ...{
          dataList: action.payload.data,
          loading: false
        }
      }

    case types.GET_COMPANIES_FAIL:
      {
        return {
          ...state,
          ...{ dataList: [], loading: false }
        }
      }

    case types.CREATE_COMPANY:
      return {
        ...state,
        ...{ loading: true }
      }

    case types.CREATE_COMPANY_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        createSuccess: true,
      }

    case types.CREATE_COMPANY_FAIL:
      return {
        ...state,
        loading: false,
        data: action.payload.response.data,
      }

    case types.CREATE_ADMIN_COMPANY:
      return {
        ...state,
        ...{ loading: true }
      }

    case types.CREATE_ADMIN_COMPANY_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        createAdminCompanySuccess: true,
      }
    case types.CREATE_ADMIN_COMPANY_FAIL:
      return {
        ...state,
        loading: false,
        data: action.payload.response.data,
      }
    case types.RESET_MESSAGE_ERROR:
      return {
        ...state,
        loading: false,
        data: []
      }
      //edit
      case types.EDIT_COMPANY: 
      return {
          ...state,
          editLoad: true,

      }
      case types.EDIT_COMPANY_SUCCESS:
      return {
          ...state,
          editLoad: false,
      }
    default:
      return state
  }
}