import { Button, Checkbox, Grid, TextField } from "@mui/material";
import React, { useState } from "react";
import { BoxInput, TitleInput } from "./styled";
import { CustomButton } from "../../../../../components/Common/CustomButton";
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { createPositions } from "../../../../../redux/departmentsId/action";
import { getDepartmentsId } from "../../../../../redux/departmentsId/action";
import { getDepartments } from "../../../../../redux/Departments/action";
import { red } from "@mui/material/colors";

const colorError = red[500];
const DialogAddDepartmentId = ({ setUDO, statusId }) => {
  const dispatch = useDispatch();

  const validationSchema = yup.object({
    name: yup.string().required("Bạn chưa nhập tên chức vụ"),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      department: statusId,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      dispatch(createPositions(values));
      dispatch(getDepartmentsId(statusId));
      dispatch(getDepartments());
      setUDO(false);
    },
  });
  
  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container columnSpacing={{ xs: 1}}>
        <Grid item xs={12}>
          <BoxInput>
            <TitleInput>
              ID phòng ban <span style={{ color: colorError }}>*</span>
            </TitleInput>
            <TextField
              disabled
              id="department"
              name="department"
              size="small"
              fullWidth
              value={statusId}
            />
          </BoxInput>
        </Grid>

        <Grid item xs={12}>
          <BoxInput>
            <TitleInput>
              Tên chức vụ <span style={{ color: colorError }}>*</span>
            </TitleInput>
            <TextField
              id="name"
              name="name"
              size="small"
              fullWidth
              placeholder="Tên chức vụ"
              value={formik.values.name}
              onChange={formik.handleChange}
              error={formik.touched.name && Boolean(formik.errors.name)}
            />
          </BoxInput>
          <BoxInput>
            <CustomButton
              //color="primary"
              variant="contained"
              type="submit"
              onClick={formik.handleSubmit}
              style={{marginLeft:'auto'}}
            >
              Lưu
            </CustomButton>
          </BoxInput>
        </Grid>
      </Grid>
    </form>
  );
};

export default DialogAddDepartmentId;
