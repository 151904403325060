import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axiosInstance from "../../../../components/axiosApi";
import { showMessageSuccess } from "../../../../redux/notification/action";
import {
  
  Table,
  TableBody,
  TableContainer,
  TableRow,
  Typography,
  Paper,
  Select,
  MenuItem,
} from "@mui/material";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { CustomButton } from "../../../../components/Common/CustomButton";
import MuiTableCell from "@material-ui/core/TableCell";
import MuiTableHead from "@mui/material/TableHead";
import { makeStyles } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import { getUserStart } from "../../../../redux/users/action";

const useStyles = makeStyles((theme) => ({
  tableBorder: {
    border: "1px solid #d4d8dd",
    borderRadius: "0.5rem",
  },
  btnGlobal: {
    minWidth: "inherit",
    width: 28,
    padding: "0",
    height: "28px",
    lineHeight: "28px",

    marginLeft: 5,
    boxShadow: "none",
    fontSize: "12px",
    fontWeight: "500",
  },
  btnBlue: { backgroundColor: "#556ee6" },
  // btnGreen:{
  //   backgroundColor: '#34c38f',
  // },
  btnYellow: {
    backgroundColor: "#f1b44c",
  },
  btnRed: {
    backgroundColor: "#f46a6a",
  },
}));

const TableCell = withStyles({
  root: {
    borderBottom: "1px solid rgb(235, 237, 242)",
    padding: "5px 10px",
    lineHeight: "18px",
  },
})(MuiTableCell);

const TableHead = withStyles((theme) => ({
  root: {
    backgroundColor: "rgba(38,60,85,.1)",
  },
}))(MuiTableHead);

const TableHeaderCell = withStyles((theme) => ({
  root: {
    color: "#516377",
    fontWeight: 700,
    padding: "10px",
    fontSize: ".75rem",
    textTransform: "uppercase",
    letterSpacing: "0.8px",
    lineHeight: "18px",
  },
}))(TableCell);

const Extension = ({dataLogoFooter}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [dataExtension, setDataExtension] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [selected, setSelected] = useState("");
  const dataUsers = useSelector((item) => item.UsersReducer?.dataUsers);

  const pbx_username = dataLogoFooter?.org?.pbx_username
  const pbx_pwd = dataLogoFooter?.org?.pbx_password

  useEffect(() => {
    dispatch(getUserStart());
  }, []);

  useEffect(() => {
    
   
      const getExtension = async () => {
        let params = "";
        params += `email=${pbx_username}&password=${pbx_pwd}`;
        try {
          const res = await axiosInstance.get(`/pbx/extension/?${params}`);
          if (res.status === 200) {
            setDataExtension(res.data);
          }
        } catch (error) {}
      };
      getExtension();
     
    
    
  }, [pbx_username,pbx_pwd]);

  const newExtension = [
    ...dataExtension,
    {uid:'', number:' Ko đồng bộ '}
  ]
  
  const onChange = (event, item) => {
    const { value } = event.target;
    setSelected(value);
    

    handleUpdateUserBySelectPbx(item.id, item.email, value);
  };


  

  const handleUpdateUserBySelectPbx = async (id, email, value) => {
    let params = "";

    params += `&email=${email}&agent=${value}`;
    try {
      const res = await axiosInstance.put(`/users/${id}/?${params}`);
      dispatch(getUserStart());
      if (res.status === 200) {
        dispatch(showMessageSuccess("Kết nối Extension thành công"));
      }
    } catch (error) {}
  };

  const navigate = (url) => {
    if (url === "/") {
      window.location.href = "/";
    } else {
      window.location.href = "/configuration";
    }
  };
  return (
    <>
     
    <div className="screen modify-label">
    <CustomButton style={{marginBottom:'15px',minWidth:'inherit',pading:'0 6px'}} onClick={() => {
            navigate("/configuration");
          }}><KeyboardArrowLeftIcon /></CustomButton>
      <Paper className="order-list" style={{ marginBottom: "10px" }}>
        <div className="ul-heading-placeholder">
          <span
            className="ul-heading"
            style={{ display: "flex", flex: "inherit", fontWeight: 500 }}
          >
            Extensions
          </span>
        </div>
      </Paper>

      {dataUsers?.active_users?.length > 0 ? (
        <Paper
          sx={{ width: "100%", mb: 2 }}
          elevation={0}
          className={`${classes.tableBorder}`}
        >
          <TableContainer
            style={{
              width: "100%",
              overflowX: "auto",
              borderRadius: "0.5rem",
            }}
          >
            <Table sx={{ minWidth: 700 }} size={"small"}>
              <TableHead>
                <TableRow>
                  <TableHeaderCell align="left" style={{ width: 120 }}>
                    ID
                  </TableHeaderCell>
                  <TableHeaderCell align="left" style={{ minWidth: 120 }}>
                    Username
                  </TableHeaderCell>
                  <TableHeaderCell align="left" style={{ minWidth: 120 }}>
                    Quyền
                  </TableHeaderCell>
                  <TableHeaderCell align="left" style={{ minWidth: 120 }}>
                    email
                  </TableHeaderCell>
                  <TableHeaderCell align="left" style={{ minWidth: 120 }}>
                    Extension
                  </TableHeaderCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {dataUsers?.active_users &&
                  dataUsers?.active_users.map((item, index) => {
                    return (
                      <TableRow
                        key={index}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell align="left">{item?.id}</TableCell>
                        <TableCell align="left">{item?.username}</TableCell>
                        <TableCell align="left">{item?.role}</TableCell>
                        <TableCell align="left">{item?.email}</TableCell>
                        <TableCell align="left">
                        <Select
                            style={{ fontSize: 14 }}
                            labelId="uid"
                            fullWidth
                            size="small"
                            label="extension"
                            name="uid"
                            value={item?.agent?.pbx_id || ""}
                            onChange={(e) => onChange(e, item)}
                          >
                            
                            {newExtension &&
                              newExtension.map((item2, index) => (
                                <MenuItem key={index} value={item2.uid}>
                                  {item2.number}
                                </MenuItem>
                              ))}
                          </Select>
                          
                        </TableCell>
                        <TableCell align="left"></TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      ) : (
        <Typography>No data found</Typography>
      )}
    </div>
    </>
  );
};

export default Extension;
