import * as types from "./type"

export const showMessageSuccess = (message) => ({
        type: types.SHOW_MESSAGE_SUCCESS,
        payload: message
    }
)
export const showMessageError = (message) => ({
        type: types.SHOW_MESSAGE_ERROR,
        payload: message
    }
)

export const resetNotification = () => ({
    type: types.RESET_STATE
})