import React, { useEffect, useState } from "react";

import styled from "styled-components";
import { Grid,TextField } from "@mui/material";
import { CustomButton } from "../../../../components/Common/CustomButton";
import { useDispatch, useSelector } from "react-redux";
import { createCompany } from "../../../../redux/company/action";
import * as Yup from "yup";
import { useFormik } from "formik";
import { makeStyles } from "@material-ui/core/styles";
import { red } from "@mui/material/colors";



const colorError = red[500];
export const TitleInput = styled("p")(({ theme }) => ({
  fontSize: 14,
  fontWeight: 500,
  marginBottom: "10px",
  textAlign: "left",
}));

export const BoxInput = styled("div")(({ theme }) => ({
  marginTop: 12,
}));

const companySchema = Yup.object({
  code: Yup.string().required("Hãy nhập code"),
  name: Yup.string().required("Hãy nhập name"),
  address: Yup.string().required("Hãy nhập user address"),
  phone: Yup.string().required("Hãy nhập phone"),
  user_limit: Yup.number()
    .typeError("Số lượng user là kiểu số")
    .min(0, "Số lượng user phải lớn hơn 0"),
});

const useStyles = makeStyles((theme) => ({
  textFieldError: {
    "& input": {
      border: "1px solid red!important",
      color: "red",
      height: "max-content!important",
      width: "max-content!important",
      margin: "16.5px 0",
      padding: "0 14px!important",
    },
    "& p": {
      color: "red",
      marginLeft: 0,
      letterSpacing: 0,
    },
  },
  typo:{
    fontSize:'1.25rem',
    fontWeight:'bold',
    marginTop:'25px',
    marginBottom:'10px'
  },
  btnOutline:{
    border:'2px solid #5a8dee',
    background:'#fff',
    color:'#5a8dee',
    "&:hover": {
      background: '#e5edfc',
    },
  }
}));

export const CreateCompany = ({onClose}) => {
  const classes = useStyles();
  
  const [messageErrorAPI, setMessageErrorAPI] = useState("");
  const [fileImageLogo, setFileImageLogo] = useState();
  
  const dispatch = useDispatch();
  const submitForm = () => {
    const formData = new FormData();
    fileImageLogo && formData.append("logo", fileImageLogo);
    formData.append("code", values.code);
    formData.append("name", values.name);
    formData.append("address", values.address);
    formData.append("phone", values.phone);
    formData.append("user_limit", values.user_limit);
    dispatch(createCompany(formData));
  };

  const { values, handleSubmit, handleChange, handleBlur, errors, touched } =
    useFormik({
      initialValues: {
        code: "",
        name: "",
        address: "",
        phone: "",
        user_limit: "",
      },
      validationSchema: companySchema,
      onSubmit: submitForm,
    });

  const createSuccess = useSelector(
    (item) => item.companyReducer.createSuccess
  );

  const createCompanyData = useSelector((item) => item.companyReducer.data);

  useEffect(() => {
    if (createCompanyData && createCompanyData.error) {
      setMessageErrorAPI(JSON.stringify(createCompanyData.errors));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createCompanyData]);

  useEffect(() => {
    if (createSuccess) {
      onClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createSuccess]);
  

  return (
    
        

        <Grid>
          {messageErrorAPI && (
            <div className={classes.textFieldError}>
              <p>{messageErrorAPI}</p>
            </div>
          )}
          <BoxInput>
            <TitleInput>
              Mã code <span style={{ color: colorError }}>*</span>
            </TitleInput>
            <TextField
              name="code"
              variant="outlined"
              fullWidth
              placeholder="Nhập code..."
              size="small"
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.code && touched.code ? classes.textFieldError : ""}
              helperText={errors.code && touched.code ? errors.code : ""}
              value={values.code}
            />
          </BoxInput>
          <BoxInput>
            <TitleInput>
              Tên công ty <span style={{ color: colorError }}>*</span>{" "}
            </TitleInput>
            <TextField
              name="name"
              variant="outlined"
              fullWidth
              placeholder="Nhập tên công ty..."
              size="small"
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.name && touched.name ? classes.textFieldError : ""}
              helperText={errors.name && touched.name ? errors.name : ""}
              value={values.name}
            />
          </BoxInput>
          <BoxInput>
            <TitleInput>
              Địa chỉ <span style={{ color: colorError }}>*</span>{" "}
            </TitleInput>
            <TextField
              name="address"
              variant="outlined"
              fullWidth
              placeholder="Nhập địa chỉ..."
              size="small"
              onChange={handleChange}
              onBlur={handleBlur}
              error={
                errors.address && touched.address ? classes.textFieldError : ""
              }
              helperText={
                errors.address && touched.address ? errors.address : ""
              }
              value={values.address}
            />
          </BoxInput>
          <BoxInput>
            <TitleInput>
              SĐT <span style={{ color: colorError }}>*</span>{" "}
            </TitleInput>
            <TextField
              name="phone"
              variant="outlined"
              fullWidth
              placeholder="Nhập SĐT..."
              size="small"
              onChange={handleChange}
              onBlur={handleBlur}
              error={
                errors.phone && touched.phone ? classes.textFieldError : ""
              }
              helperText={errors.phone && touched.phone ? errors.phone : ""}
              value={values.phone}
            />
          </BoxInput>
          <BoxInput>
            <TextField
              hidden
              name="user_limit"
              variant="outlined"
              fullWidth
              placeholder="Nhập số lượng nhân viên"
              size="small"
              onChange={handleChange}
              onBlur={handleBlur}
              error={
                errors.user_limit && touched.user_limit
                  ? classes.textFieldError
                  : ""
              }
              helperText={
                errors.user_limit && touched.user_limit ? errors.user_limit : ""
              }
              value={values.user_limit}
            />
          </BoxInput>
          <BoxInput>
            <TitleInput>
              Logo công ty
            </TitleInput>
            <TextField
              name="name"
              variant="outlined"
              fullWidth
              size="small"
              inputProps={{ accept: "image/png, image/gif, image/jpeg" }}
              onChange={(e) => setFileImageLogo(e.target.files[0])}
              type="file"
            />
          </BoxInput>
          
          
          <BoxInput>
          <CustomButton
              // color="primary"
              variant="contained"
              type="submit"
              onClick={() => handleSubmit()}
              style={{ marginLeft: "auto" }}
              
            >
              
              Tạo công ty
            </CustomButton>
          </BoxInput>
        </Grid>
      
  );
};
