import { put, takeLatest } from "redux-saga/effects";
import * as types from './type';
import * as actions from './action';
import axiosInstance from "../../components/axiosApi";

function* getCdr(action) {
    try {
            let params = ''
            if(action.payload) {
                const {pbx_username,pbx_pwd,fromdate,todate,number } = action.payload
                params += number !== "" && number !== undefined ? `number=${number}&`: ""
                params += fromdate !== "" && fromdate !== undefined ? `fromdate=${fromdate}&`: ""
                params += todate !== "" && todate !== undefined ? `todate=${todate}&`: ""
                params += `email=${pbx_username}&password=${pbx_pwd}`
            }
            const res = yield axiosInstance.get(`/report/cdr/?${params}`);
        yield put(actions.getCdrSuccess(res.data))
    } catch (error) {
        yield put(actions.getCdrFail(error))
    }
}

export function* watchingCdr() {
    yield takeLatest(types.GET_CDR, getCdr);
}