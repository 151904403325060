import { put, takeLatest } from "redux-saga/effects";
import * as types from './type';
import * as actions from './action';
import axiosInstance from "../../components/axiosApi";

function* getDebtRevenue({payload}) {
    try {
        const res = yield axiosInstance.get(`/report/debt-revenue/${payload}/`);
        yield put(actions.getDebtRevenueSuccess(res.data))
    } catch (error) {
        yield put(actions.getDebtRevenueFail(error))
    }
}

export function* watchingGetDebtRevenue() {
    yield takeLatest(types.GET_DEBT_REVENUE, getDebtRevenue);
}