import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,

} from "@mui/material";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { CustomButton } from "../../../../components/Common/CustomButton";
import React from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import AlertMessage from "../../../../components/Common/Alert";
import Loading from "../../../../components/Common/Loading";
import { BoxStaff } from "./styled";
import TableStaff from "./Table";
import { getUserStart } from "../../../../redux/users/action";
import DialogStaff from "./Dialog";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  customModalContent:{
    paddingLeft:'15px',
    paddingRight:'15px'
  },
  customModal:{
    width:'calc(100% - 20px)',
    marginLeft:'10px',
    marginRight:'10px'
  },
  customModalTitle:{
    padding:'10px 15px',
    color:'#fff',
    background:process.env.NODE_ENV === 'development' ? "#5a8dee" : process.env.REACT_APP_PRODUCT_BASE_COLOR
  }
}));
const Staff = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [userDetailOpen, setUDO] = React.useState(false);
  const loading = useSelector((item) => item.UsersReducer.loading);
  const userData = useSelector(
    (item) => item.UsersReducer.dataUsers.active_users
  );
  const creLoading = useSelector((item) => item.UsersReducer.creLoading);

  const handleAdd = () => {
    setUDO(true);
  };

  const handleCloseUserDetailDialog = () => {
    setUDO(!userDetailOpen);
  };
  const navigate = (url) => {
    if (url === "/") {
      window.location.href = "/";
    } else {
      window.location.href = "/configuration";
    }
  };
  useEffect(() => {
    dispatch(getUserStart());
  }, [creLoading]);

  return (
    <BoxStaff>
      <Loading loading={loading} />
      {creLoading && <AlertMessage status="success" />}
      <div className="screen">
      <CustomButton style={{marginBottom:'15px',minWidth:'inherit',pading:'0 6px'}} onClick={() => {
            navigate("/configuration");
          }}><KeyboardArrowLeftIcon /></CustomButton>
        <Paper className="user-list">
        
          <div className="box-btn-add" style={{padding:'5px'}}>
          <span
            className="ul-heading"
            style={{ display: "flex", flex: "inherit", fontWeight: 500, padding:0 }}
          >
            Quản lý nhân viên
          </span>
              <CustomButton
                style={{marginLeft:'auto'}}
                variant="outlined"
                className="search-row-button"
                onClick={() => {
                  handleAdd();
                }}
              >
                Thêm mới
              </CustomButton>
            
          </div>
        </Paper>
        <Paper
          sx={{
            width: "100%",
            marginTop: "10px",
            border: "1px solid #d4d8dd",
            borderRadius: "0.5rem",
          }}
          elevation={0}
        >
          <TableStaff userData={userData} />
        </Paper>

        <Dialog
          fullWidth={true}
          maxWidth={"sm"}
          open={userDetailOpen}
          onClose={handleCloseUserDetailDialog}
          style={{ minHeight: "100%" }}
          classes={{
              paper: classes.customModal
          }}
        >
          <DialogTitle className={`${classes.customModalTitle}`}>
            Thêm mới tài khoản
            <IconButton
              onClick={() => setUDO(false)}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: '#fff',
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>

          <DialogContent className={`${classes.customModalContent}`}>
            <DialogStaff setUDO={setUDO} />
          </DialogContent>
        </Dialog>
      </div>
    </BoxStaff>
  );
};

export default Staff;
