import { put, takeLatest } from "redux-saga/effects";
import * as types from './type';
import * as actions from './action';
import axiosInstance from "../../components/axiosApi";

function* getLeadStatus() { 
    try {
        const res = yield axiosInstance.get(`/leads/sources/`)
        yield put(actions.getLeadStatusSuccess(res.data))
    } catch (error) {
        yield put(actions.getLeadStatusFail(error))
    }
}

function* getLeadStatusId(payload) {
    const id = payload;
    try {
        const res = yield axiosInstance.get(`/leads/sourcessub/${id.payload}/`)
        yield put(actions.getLeadStatusIdSuccess(res.data))
    } catch (error) {
        yield put(actions.getLeadStatusIdFail(error))
    }
}

// function* createLeadStatusId({payload}) {
//     console.log(payload);
//     try {
//         const res = yield axiosInstance.post(`/leads/sourcessub/${payload.parent_code}/`)
//         console.log(res);
//         yield put(actions.createLeadStatusIdSuccess(res.data))
//     } catch (error) {
//         yield put(actions.createLeadStatusIdFail(error))
//     }
// }

export function* watchingLeadStatus() {
    yield takeLatest(types.GET_LEAD_STATUS, getLeadStatus);
    yield takeLatest(types.GET_LEAD_STATUS_ID, getLeadStatusId);
    // yield takeLatest(types.CREATE_LEAD_STATUS_ID, createLeadStatusId);
}