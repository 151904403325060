import styled from "styled-components";

export const BoxStaff = styled.div`
    .box-btn-add {
        display: flex;
        justify-content: end;
    }
    .btn-add {
        padding: 5px;
    }
    .MuiButton-root{
        min-width: 80px;
        padding: 6px 10px;
        height: 36px;
        line-height: 36px;
        color: #fff;
        border: none;
        border-radius:3px;
        cursor: pointer;
        background: ${process.env.NODE_ENV === 'development' ? "#5a8dee" : process.env.REACT_APP_PRODUCT_BASE_COLOR};
        box-shadow: ${process.env.NODE_ENV === 'development' ? '0 2px 6px 0 rgb(59 130 246 / 50%)' : process.env.REACT_APP_PRODUCT_SHADOW_COLOR};
        letter-spacing:0;
        text-transform:capitalize;
    }
`