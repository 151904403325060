import { AlertDialogOverlay } from "@reach/alert-dialog";
import cx from "classnames";
import * as React from "react";

/**
 * A `<Dialog />` usually used to provide critical information or ask for a decision.
 *
 * `Dialog` interrupts user workflow to get a response. This is different than a `Modal`
 * as `Dialog` requires some user response, like "Save" or "Cancel" etc.
 *
 */
export const DialogNotification = ({
  isOpen,
  onDismiss,
  leastDestructiveRef,
  overlayClassName,
  ...props
}) => {
  return (
    <AlertDialogOverlay
      isOpen={isOpen}
      onDismiss={onDismiss}
      leastDestructiveRef={leastDestructiveRef}
      className={cx("px-5", overlayClassName)}
    >
      {props.children}
    </AlertDialogOverlay>
  );
};
