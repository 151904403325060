import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextareaAutosize,
  TextField,
  Autocomplete,
} from "@mui/material";
import { useFormik } from "formik";
import React, { memo, useEffect, useState } from "react";
import * as yup from "yup";
import { BoxInput, TitleInput } from "./styled";
import axiosInstance from "../../../../components/axiosApi";
import { useDispatch, useSelector } from "react-redux";
import {
  addCustomerStart,
  editOrderStart,
} from "../../../../redux/add-customers/action";
import Loading from "../../../../components/Common/Loading";
import { getCustomerStart } from "../../../../redux/customers/action";
import { getUserStart } from "../../../../redux/users/action";

import TableProducts from "../TableProducts/TableProducts";
import FinalOrders from "../FinalOrders";
import SourceData from "../../../../components/Common/SourceData";
import AssignTo from "../../../../components/Common/AssignTo";
import { ItemGrid } from "../FinalOrders/styled";
import { CustomButton } from "../../../../components/Common/CustomButton";
import { showMessageError } from "../../../../redux/notification/action";
import SourceLeadApi from "../../../../api/source_lead";
import StatusLeadApi from "../../../../api/status_lead";
import Tooltip from "@mui/material/Tooltip";

import { startCall } from "../../../../redux/callPhone/action";
import LocalPhoneRoundedIcon from "@mui/icons-material/LocalPhoneRounded";
import SendRoundedIcon from "@mui/icons-material/SendRounded";
import moment from "moment";
import { red } from "@mui/material/colors";
import { makeStyles } from "@material-ui/core/styles";
import { replaceMidd } from "../../../../helpers";
const useStyles = makeStyles((theme) => ({
  helperText: {
    marginLeft: 0,
  },
}));

const colorError = red[500];
const DiaLogCustomer = ({
  setUDO,
  detailCustomer,
  dataPagination,
  setDetailCustomer,
  checkEnablePhone,
  dataLogoFooter,
  dataCallStatus,
  filterByPhone
}) => {
  console.log(detailCustomer)
  const classes = useStyles();
  const [cities, setCity] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [wards, setWards] = useState([]);
  const [subStatus, setSubStatus] = useState([]);
  const [subSource, setSubSource] = useState([]);
  const [data, setData] = useState([]);
  const [lastestOrder, setLastestOrder] = useState([
    { promotion: 0, code: 0, quantityProducts: 0, total: 0, vat:0 },
  ]);
  const [customerPay, setCustomerPay] = useState("");
  const dispatch = useDispatch();
  const state = useSelector((state) => state.addCustomerReducer);
  const stateDataTrangThai = useSelector((state) => state.trangThaiDataReducer);



  const isUser = dataLogoFooter?.role
  const idProfile = dataLogoFooter?.id

  const validationSchema = yup.object({
    phone: yup
      .string()
      .required("Bạn chưa nhập số điện thoại")
      .max(10, "Số điện thoại phải có 10 số")
      .min(10, "Số điện thoại phải có 10 số"),
    // fullName: yup.string().required("Bạn chưa nhập tên"),
    // address: yup.string().required("Bạn chưa nhập địa chỉ"),
  });

  const dataFilterByphone = {
    phone: filterByPhone ?? '',
    offset: 0,
    limit: 10,
  };

  const formik = useFormik({
    initialValues: {
      id: detailCustomer?.detail?.id ?? "",
      phone: detailCustomer?.detail?.lead?.phone ?? "",
      city: detailCustomer?.detail?.lead?.city?.id ?? "",
      ward: detailCustomer?.detail?.lead?.ward?.id ?? "",
      district: detailCustomer?.detail?.lead?.district?.id ?? "",
      fullName: detailCustomer?.detail?.lead?.fullname ?? "",
      address: detailCustomer?.detail?.lead?.address ?? "",
      dataStatus: detailCustomer?.detail?.lead_status?.id ?? 8,
      describeCustomer: detailCustomer?.detail?.lead?.description ?? "",
      debt: "",
      careContent: "",
      assignTo: (
        isUser === 'USER' ? idProfile : (detailCustomer?.detail?.assigned_to?.id ?? '')
      ),
      singleCode: "",
      transportFee: "",
      dataOrigin: detailCustomer?.detail?.lead_source?.id ?? "",
      childStatus: detailCustomer?.detail?.lead_status_sub?.id ?? "",
      subDataOrigin: detailCustomer?.detail?.lead_source_sub?.id ?? "",
      callstatus:detailCustomer?.detail?.callstatus?.id ?? "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      
      const orderValues = {
        ...values,
        promotion:(
          data.length <= 0 ? 0 : ( lastestOrder[0].promotion ?? 0)
        ),
        vat:lastestOrder[0].vat,
        priceCustomerPay: customerPay,
        total: (
          data.length <= 0 ? 0 : (lastestOrder[0].total ?? 0)
        ),
        // orderPay: +customerPay,
      };
      
      await detailCustomer
        ? dispatch(editOrderStart({ orderValues, data }))
        : dispatch(addCustomerStart({ orderValues, data }));
        await setDetailCustomer(null);
        await setUDO(false);
        await filterByPhone 
        ? dispatch(getCustomerStart(dataFilterByphone))
        : dispatch(getCustomerStart(dataPagination));
    },
  });



  const handleChangeCityAuto = async (event, newValue) => {
    if (newValue != null) {
      const idDistrict = await getDistricts(newValue.id, true);
      await getWards(idDistrict, true);
      formik.setFieldValue("city", newValue.id);
    }
  };

  const handleChangeDistrictAuto = async (event, newValue) => {
    if (newValue != null) {
      getWards(newValue.id, true);
      formik.setFieldValue("district", newValue.id);
    }
  };

  const handleChangeWardAuto = async (event, newValue) => {
    if (newValue != null) {
      formik.setFieldValue("ward", newValue.id);
    }
  };

  // const handleChangeCity = async (event) => {
  //   const { name, value } = event.target;
  //   const idDistrict = await getDistricts(value, true);
  //   await getWards(idDistrict, true);
  //   formik.setFieldValue(name, value);
  // };

  // const handleChangeDistrict = (event) => {
  //   const { name, value } = event.target;
  //   getWards(value, true);
  //   formik.setFieldValue(name, value);
  // };

  const handleChangeInput = (event) => {
    const { name, value } = event.target;
    formik.setFieldValue(name, value);
  };

  const handleChangeAssign = (event, newValue) => {
    if (newValue != null) {
      formik.setFieldValue("assignTo", newValue.id);
    }
  };

  const getSubSource = async (id) => {
    try {
      const subSourceResponse = await SourceLeadApi.getLeadSubSource(id);
      setSubSource(subSourceResponse.data.status);
    } catch (error) {
      dispatch(showMessageError("Lỗi server"));
    }
  };

  const handleChangeSourceData = async (event) => {
    const { name, value } = event.target;
    formik.setFieldValue(name, value);

    getSubSource(value);
  };

  const getStatusData = async (id) => {
    try {
      const subStatusResponse = await StatusLeadApi.getDataLeadSubSource(id);
      setSubStatus(subStatusResponse.data.status);
    } catch (error) {
      dispatch(showMessageError("Lỗi server"));
    }
  };

  const handleChangeTrangThaiData = async (event) => {
    const { name, value } = event.target;
    formik.setFieldValue(name, value);
    getStatusData(value);
  };

  const getCities = async () => {
    try {
      const responseCity = await axiosInstance.get("/cities/?limit=63");
      setCity(responseCity.data.results);
      if (detailCustomer) return responseCity?.data?.results[0].id;

      formik.setFieldValue("city", responseCity?.data?.results[0].id);
      return responseCity?.data?.results[0].id;
    } catch (err) {
      dispatch(showMessageError("Lỗi server"));
    }
  };

  const getDistricts = async (idCity, status) => {
    const responseDistricts = await axiosInstance.get("/city/" + idCity + "/");
    setDistricts(responseDistricts.data.districts);
    if (detailCustomer) {
      if (status)
        formik.setFieldValue(
          "district",
          responseDistricts?.data?.districts[0].id
        );
      return responseDistricts?.data?.districts[0].id;
    }
    formik.setFieldValue("district", responseDistricts?.data?.districts[0].id);
    return responseDistricts?.data?.districts[0].id;
  };

  const getWards = async (idDistrict, status) => {
    const responseWards = await axiosInstance.get(
      "/district/" + idDistrict + "/"
    );
    setWards(responseWards.data.wards);
    if (detailCustomer) {
      if (status)
        formik.setFieldValue("ward", responseWards?.data?.wards[0].id);
      return responseWards?.data?.wards[0].id;
    }
    formik.setFieldValue("ward", responseWards?.data?.wards[0].id);
    return responseWards?.data?.wards[0].id;
  };


  

  useEffect(() => {
    if (!detailCustomer) return;
    const formatOrder = detailCustomer?.order[0]?.items?.map((order) => ({
      idProduct: order?.item.id,
      product: order?.item.title,
      price: order?.item.price,
      quantity: order?.quantity,
      total: order?.item.price * order?.quantity,
    }));
    setData(formatOrder);
    setCustomerPay(detailCustomer?.detail?.pay_order);
  }, [detailCustomer]);
  const renderInput = (
    title,
    name,
    placeholder,
    value,
    handleChange,
    handleBlur,
    error,
    errorMessage,
    disabled = false,
    isPhone = false
  ) => (
    <BoxInput style={{ position: "relative" }}>
      
      <TextField
        id={name}
        name={name}
        size="small"
        fullWidth
        placeholder={placeholder}
        helperText={errorMessage}
        value={
          !isPhone
            ? value
            : disabled &&
              (checkEnablePhone === true ? value : replaceMidd(value, 3))
        }
        onChange={handleChange}
        onBlur={handleBlur}
        error={error}
        disabled={disabled}
        FormHelperTextProps={{ classes: { root: classes.helperText } }}
      />
      {isPhone && (
        <Tooltip title="Gọi ngay số này" arrow>
          <LocalPhoneRoundedIcon
            style={{
              position: "absolute",
              right: 10,
              top: 10,
              width: "0.8em",
              height: "0.8em",
              transform: [{ rotate: "-90deg" }],
            }}
            onClick={() => handleCall(formik.values.phone)}
            className="phone-icon"
          ></LocalPhoneRoundedIcon>
        </Tooltip>
      )}
    </BoxInput>
  );

  useEffect(() => {
    const initialData = async () => {
      const idCity = await getCities();
      const idDistrict = await getDistricts(
        detailCustomer?.detail?.lead?.city.id ?? idCity,
        false
      );
      // eslint-disable-next-line
      const idWard = await getWards(
        detailCustomer?.detail?.lead?.district.id ?? idDistrict,
        false
      );
      // if(detailCustomer)
      detailCustomer?.detail?.lead_status?.id &&
        (await getStatusData(detailCustomer?.detail?.lead_status?.id));
      detailCustomer?.detail?.lead_source?.id &&
        (await getSubSource(detailCustomer?.detail?.lead_source?.id));
    };
    initialData();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch(getUserStart());
    // dispatch(getTrangThaiData())
    // eslint-disable-next-line
  }, []);

  const handleCall = (phone) => {
    dispatch(startCall(phone));
  };

  const checkDiscount = () => {
    if (
      isNaN(lastestOrder[0].promotion) ||
      lastestOrder[0].promotion > lastestOrder[0].currentQuantity ||
      lastestOrder[0].promotion < 0
    ) {
      return true;
    }
    return false;
  };

  const checkVat = () => {
    if (
      isNaN(lastestOrder[0].vat) ||
      lastestOrder[0].vat > lastestOrder[0].currentQuantity ||
      lastestOrder[0].vat < 0
    ) {
      return true;
    }
    return false;
  };

  // sort note list by time newest
  const arraySortByDate =
    detailCustomer?.detail?.history_care.length > 0 &&
    detailCustomer?.detail?.history_care
      .sort((a, b) => (a.created_on < b.created_on ? 1 : -1))
      .map((item, index) => (
        <div className="item" key={index}>
          <div className="box-avatar">
            {item?.created_by.first_name?.charAt(0)}
          </div>

          <div className="box-content">
            <div className="note-list-item">
              <div className="name">
                {item?.created_by.first_name}
                <span className="time">
                  {" "}
                  - {moment(item?.created_on).format("DD-MM-YYYY, h:mm A")}
                </span>
              </div>
              <p>{item?.content}</p>
            </div>
          </div>
        </div>
      ));
  // Api note history care

  const handleShowDetail = async () => {
    const id = detailCustomer.detail.id;
    try {
      const res = await axiosInstance.get(`/leads/${id}/`);
      if (res.status === 200) setDetailCustomer(res.data);
    } catch (error) {
      setDetailCustomer({});
    }
  };

  const handleSendNote = async (note) => {
    const id = detailCustomer.detail.id;
    try {
      const res = await axiosInstance.post(
        `/leads/historycare/${id}/?lead_history=${note}`
      );
      if (res.status === 200) {
        handleShowDetail();
        formik.setFieldValue("careContent", "");
        dispatch(getCustomerStart(dataPagination));
      }
    } catch (error) {
      dispatch(showMessageError("Lỗi server"));
    }
  };

  const valueCity = formik.values.city;
  const valueDistrict = formik.values.district;
  const valueWard = formik.values.ward;

  return (
    <div>
      <Loading loading={state.loading} />
      <form onSubmit={formik.handleSubmit}>
        <ItemGrid style={{ boxShadow: "none", padding: "8px 0" }}>
          <Grid container rowSpacing={1} columnSpacing={{ sm: 2 }}>
            <Grid item xs={12} sm={7}>
              <Grid container columnSpacing={{ sm: 1 }}>
                <Grid item xs={12} sm={6}>
                <TitleInput>
                Họ và tên
      </TitleInput>
                  {renderInput(
                    "Họ và tên",
                    "fullName",
                    "Nhập họ và tên",
                    formik.values.fullName,
                    formik.handleChange,
                    //formik.handleBlur,
                    //formik.touched.fullName && Boolean(formik.errors.fullName),
                    //formik.touched.fullName && formik.errors.fullName
                  )}
                </Grid>
                <Grid item xs={12} sm={6}>
                <TitleInput>
                Số điện thoại <span style={{ color: colorError }}>*</span>
      </TitleInput>
                  {renderInput(
                    "Số điện thoại",
                    "phone",
                    "Nhập số điện thoại",
                    formik.values.phone,
                    formik.handleChange,
                    formik.handleBlur,
                    formik.touched.phone && Boolean(formik.errors.phone),
                    formik.touched.phone && formik.errors.phone,
                    detailCustomer && true,
                    true
                  )}
                </Grid>
                <Grid item xs={6}>
                <TitleInput>
                Địa chỉ
      </TitleInput>
                  {renderInput(
                    "Địa chỉ",
                    "address",
                    "Nhập địa chỉ",
                    formik.values.address,
                    formik.handleChange,
                    //formik.handleBlur,
                    //formik.touched.address && Boolean(formik.errors.address),
                    //formik.touched.address && formik.errors.address
                  )}
                </Grid>
                <Grid item xs={6}>
                  <TitleInput>Trạng thái cuộc gọi</TitleInput>
                  <BoxInput>
                      <FormControl fullWidth>
                        <InputLabel id="dataStatus">Chọn trạng thái</InputLabel>
                        <Select
                          labelId="callstatus"
                          fullWidth
                          name="callstatus"
                          label="Chọn trạng thái"
                          value={formik.values.callstatus}
                          onChange={handleChangeInput}
                        >
                          {dataCallStatus?.status?.length > 0 ? (
                            dataCallStatus?.status?.map(
                              (item, index) => {
                                return (
                                  <MenuItem key={index} value={item.id}>
                                    {item.title}
                                  </MenuItem>
                                );
                              }
                            )
                          ) : (
                            <MenuItem value={-99}>--NO DATA--</MenuItem>
                          )}
                        </Select>
                      </FormControl>
                    </BoxInput>
                </Grid>
                <Grid item xs={12}>
                  <BoxInput className="boxgroup">
                    {valueCity !== "" ? (
                      <Autocomplete
                        options={cities}
                        value={
                          cities.filter((x) => x.id === valueCity)[0] || ""
                        }
                        sx={{
                          "& .MuiAutocomplete-inputFocused": {
                            marginTop: "-18px",
                          },
                        }}
                        getOptionLabel={(option) => option.name}
                        isOptionEqualToValue={(option, value) =>
                          option.id === value.id
                        }
                        renderInput={(params) => {
                          return (
                            <TextField
                              {...params}
                              label="Tỉnh / Thành Phố"
                              variant="outlined"
                              fullWidth
                            />
                          );
                        }}
                        onChange={handleChangeCityAuto}
                      />
                    ) : (
                      <Autocomplete
                        options={cities}
                        sx={{
                          "& .MuiAutocomplete-inputFocused": {
                            marginTop: "-18px",
                          },
                        }}
                        getOptionLabel={(option) => option.name}
                        isOptionEqualToValue={(option, value) =>
                          option.id === value.id
                        }
                        renderInput={(params) => {
                          return (
                            <TextField
                              {...params}
                              label="Tỉnh / Thành Phố"
                              variant="outlined"
                              fullWidth
                            />
                          );
                        }}
                        onChange={handleChangeCityAuto}
                      />
                    )}

                    {valueDistrict !== "" ? (
                    <Autocomplete
                      options={districts}
                      value={
                        districts.filter((x) => x.id === valueDistrict)[0] || ""
                      }
                      sx={{
                        "& .MuiAutocomplete-inputFocused": {
                          marginTop: "-18px",
                        },
                      }}
                      getOptionLabel={(option) => option.name}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      renderInput={(params) => {
                        return (
                          <TextField
                            {...params}
                            label="Quận / Huyện"
                            variant="outlined"
                            fullWidth
                          />
                        );
                      }}
                      onChange={handleChangeDistrictAuto}
                    ></Autocomplete>
                    ):(
                      <Autocomplete
                      options={districts}
                      
                      sx={{
                        "& .MuiAutocomplete-inputFocused": {
                          marginTop: "-18px",
                        },
                      }}
                      getOptionLabel={(option) => option.name}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      renderInput={(params) => {
                        return (
                          <TextField
                            {...params}
                            label="Quận / Huyện"
                            variant="outlined"
                            fullWidth
                          />
                        );
                      }}
                      onChange={handleChangeDistrictAuto}
                    ></Autocomplete>
                    )
                    }
                    {valueWard !== "" ? (
                      <Autocomplete
                      options={wards}
                      value={wards.filter((x) => x.id === valueWard)[0] || ""}
                      sx={{
                        "& .MuiAutocomplete-inputFocused": {
                          marginTop: "-18px",
                        },
                      }}
                      getOptionLabel={(option) => option.name}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      renderInput={(params) => {
                        return (
                          <TextField
                            {...params}
                            label="Phường / Xã"
                            variant="outlined"
                            fullWidth
                          />
                        );
                      }}
                      onChange={handleChangeWardAuto}
                    ></Autocomplete>
                    ) : (
                      <Autocomplete
                      options={wards}
                      sx={{
                        "& .MuiAutocomplete-inputFocused": {
                          marginTop: "-18px",
                        },
                      }}
                      getOptionLabel={(option) => option.name}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      renderInput={(params) => {
                        return (
                          <TextField
                            {...params}
                            label="Phường / Xã"
                            variant="outlined"
                            fullWidth
                          />
                        );
                      }}
                      onChange={handleChangeWardAuto}
                    ></Autocomplete>
                    )
                    }
                    
                  </BoxInput>
                </Grid>

                <Grid item xs={12} sm={12}>
                  <TitleInput>Trạng thái</TitleInput>
                  <div
                    className={`${
                      subStatus.length > 0 ? "boxgroup" : "boxgroup two"
                    }`}
                  >
                    <BoxInput>
                      <FormControl fullWidth>
                        <InputLabel id="dataStatus">Chọn trạng thái</InputLabel>
                        <Select
                          labelId="dataStatus"
                          fullWidth
                          name="dataStatus"
                          label="Chọn trạng thái"
                          value={formik.values.dataStatus}
                          onChange={handleChangeTrangThaiData}
                        >
                          {stateDataTrangThai.dataTrangThai.length > 0 ? (
                            stateDataTrangThai.dataTrangThai.map(
                              (item, index) => {
                                return (
                                  <MenuItem key={index} value={item.id}>
                                    {item.title}
                                  </MenuItem>
                                );
                              }
                            )
                          ) : (
                            <MenuItem value={-99}>--NO DATA--</MenuItem>
                          )}
                        </Select>
                      </FormControl>
                    </BoxInput>
                    {/* {subStatus.length > 0 && (
                      <BoxInput>
                        <FormControl fullWidth>
                          <InputLabel id="childStatus">
                            Trạng thái phụ
                          </InputLabel>
                          <Select
                            labelId="childStatus"
                            fullWidth
                            name="childStatus"
                            label="Trạng thái con"
                            value={formik.values.childStatus}
                            onChange={handleChangeInput}
                          >
                            {subStatus.length > 0 ? (
                              subStatus.map((item, index) => {
                                return (
                                  <MenuItem key={index} value={item.id}>
                                    {item.title}
                                  </MenuItem>
                                );
                              })
                            ) : (
                              <MenuItem value={-99}>--NO DATA--</MenuItem>
                            )}
                          </Select>
                        </FormControl>
                      </BoxInput>
                    )} */}
                    <BoxInput>
                      
                      <AssignTo
                        value={formik.values.assignTo}
                        onChange={handleChangeAssign}
                      />
                    </BoxInput>

                    <BoxInput>
                      <SourceData
                        parentValue={formik.values.dataOrigin}
                        onChangeParent={handleChangeSourceData}
                        subSource={subSource}
                        childValue={formik.values.subDataOrigin}
                        onChangeChild={handleChangeInput}
                      />
                    </BoxInput>
                  </div>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={5}>
              <Grid container>
                <Grid item xs={12}>
                  <div className="box-note" style={{ marginBottom: "10px" }}>
                    <TitleInput style={{ fontSize: "18px", color: "#222" }}>
                      Ghi chú
                    </TitleInput>
                    <div className="box-note-form">
                      <TextareaAutosize
                        minRows={1}
                        id="careContent"
                        name="careContent"
                        size="small"
                        placeholder="Nhập nội dung chăm sóc"
                        value={formik.values.careContent}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.careContent &&
                          Boolean(formik.errors.careContent)
                        }
                        variant="outlined"
                        style={{ border: "none !important" }}
                      />
                      {!detailCustomer ? (
                        <>
                          <SendRoundedIcon
                            style={{
                              marginTop: "auto",
                              fill: "gray",
                            }}
                          ></SendRoundedIcon>
                        </>
                      ) : (
                        <>
                          <SendRoundedIcon
                            style={{
                              marginTop: "auto",
                              fill: process.env.NODE_ENV === 'development' ? "#5a8dee" : process.env.REACT_APP_PRODUCT_BASE_COLOR,
                              cursor: "pointer",
                            }}
                            onClick={() =>
                              handleSendNote(formik.values.careContent)
                            }
                          ></SendRoundedIcon>
                        </>
                      )}
                    </div>

                    <div className="list">{arraySortByDate}</div>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </ItemGrid>
        <Grid item xs={12}>
          <TableProducts setData={setData} data={data} />
        </Grid>
        {data?.length > 0 ? (
          <Grid item xs={12}>
            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              style={{ marginTop: 5 }}
            >
              <Grid item xs={12} sm={7}>
                <BoxInput>
                  <TitleInput>Mô tả khách hàng</TitleInput>

                  <TextareaAutosize
                    aria-label="minimum height"
                    minRows={2}
                    placeholder="Nhập mô tả khách hàng"
                    name="describeCustomer"
                    value={formik.values.describeCustomer}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.describeCustomer &&
                      Boolean(formik.errors.describeCustomer)
                    }
                    cols={70}
                  />
                </BoxInput>
              </Grid>
              <Grid item xs={12} sm={5}>
                <FinalOrders
                  setLastestOrder={setLastestOrder}
                  lastestOrder={lastestOrder}
                  dataProducts={data}
                  detailCustomer={detailCustomer}
                  setCustomerPay={setCustomerPay}
                  customerPay={customerPay}
                  // check discount
                />
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <Grid item xs={12} sm={7}>
            <BoxInput style={{ marginTop: "10px" }}>
              <TitleInput>Mô tả khách hàng</TitleInput>

              <TextareaAutosize
                aria-label="minimum height"
                minRows={2}
                placeholder="Nhập mô tả khách hàng"
                name="describeCustomer"
                value={formik.values.describeCustomer}
                onChange={formik.handleChange}
                error={
                  formik.touched.describeCustomer &&
                  Boolean(formik.errors.describeCustomer)
                }
                cols={70}
              />
            </BoxInput>
          </Grid>
        )}
        <div style={{ display: "flex", justifyContent: "left", marginTop: 15 }}>
          <CustomButton
            // color="primary"
            disabled={
              Object.keys(formik.errors).length !== 0 || checkDiscount() || checkVat()
            }
            variant="contained"
            type="submit"
            onClick={formik.handleSubmit}
            style={{ marginLeft: "auto" }}
          >
            {detailCustomer ? "Lưu & Thoát" : "Thêm mới đơn hàng"}
          </CustomButton>
        </div>
      </form>
    </div>
  );
};

export default memo(DiaLogCustomer);
