import * as React from "react";
import { useSelector } from "react-redux";
import axiosInstance from "../../components/axiosApi";
import NotificationSound from "../../assets/sounds/notification-sound.mp3";

const CountContext = React.createContext();

function NotifyProvider({ children }) {
  //websocket
  const webSocket = React.useRef(null);
  const [msgSocket, setMsgSocket] = React.useState([]);
  const [countNotify, setCountNotify] = React.useState("0");
  const [dataNotify, setDataNotify] = React.useState([]);
  const [countNotifyTicket, setCountNotifyTicket] = React.useState("0");
  const [dataNotifyTicket, setDataNotifyTicket] = React.useState([]);


  const [isPhone, setIsPhone] = React.useState('');
  const dataLogoFooter = useSelector((item) => item.dataLogoFooterRuducer.data);

  var orgCode = dataLogoFooter?.org?.code;
  var url = `wss://graph.vntel.vn/ws/socket-server/?org_Name=${orgCode}`;
  var orgId = dataLogoFooter?.id;

  React.useEffect(() => {
    if (orgCode !== undefined) {
      webSocket.current = new WebSocket(url);

      webSocket.current.onmessage = (message) => {
        setMsgSocket((prev) => [...prev, JSON.parse(message.data)]);
      };
    }
  }, [url, orgCode]);

  const getNotifyList = React.useCallback(async () => {
    try {
      const res = await axiosInstance.get(
        `/notifycations/`
      );
      if (res.status === 200) {
        const resNotify = res.data.notify.filter(x => x.source !== 'Ticket')
        const resNotifyTicket = res.data.notify.filter(x => x.source === 'Ticket' && x.send_to == orgId)
        if(resNotify.length > 0){
          setDataNotify(resNotify)
          setCountNotify(resNotify.length)
        } 
        if (resNotifyTicket.length > 0){
          setDataNotifyTicket(resNotifyTicket)
          setCountNotifyTicket(resNotifyTicket.length)
        }
        
      }
    } catch (error) {
    
    }
  }, []);

  const handleDel = async(id) => {
    try {
      const res = await axiosInstance.delete(`/notifycations/?id=${id}`);
      if (res.status === 200) {
        getNotifyList();
      }
    } catch (error) {
      
    }
  };

  const [info, setInfo] = React.useState('')

  const handleGetInfoMsg = (elm) => {
    setInfo(elm)
  }

//   const addNotiToLocal = React.useCallback(() => {
//     if (localStorage.getItem("access_token") !== null) {
//       if (localStorage.getItem("notify") !== null) {
//         let dataMsg = JSON.parse(localStorage.getItem("notify"));
//         let arrWithStatus = dataMsg
//           .filter((x) => x.org === "1" && x.source !== 'Ticket')
//           .map((object) => {
//             return { ...object };
//           });
//         localStorage.setItem("notify", JSON.stringify(arrWithStatus));
//         setCountNotify(arrWithStatus.length);
//         setDataNotify(arrWithStatus);
//       }
//     }
//   },
//   []
// );

  // const checkNotify = () => {
  //   if (localStorage.getItem("notify") !== null) {
  //     let messagesLocal = JSON.parse(localStorage.getItem("notify"));
  //     let res = messagesLocal.filter(
  //       (item2) =>
  //         item2.status === "false" &&
  //         item2.org === '1' && item2.source !== 'Ticket'
  //     );

  //     setCountNotify(res.length);
  //     setDataNotify(res);
  //   }
  // };

  // const checkIsPhone = () => {
  //   var phoneno = /\b[\+]?[0-9]{10}(?!\d)/
  //   if (localStorage.getItem("notify") !== null) {
  //     let messagesLocal = JSON.parse(localStorage.getItem("notify"));
  //     let last = messagesLocal[messagesLocal.length - 1];


  //     if (phoneno.test(last?.message)) {
  //       setIsPhone(!isPhone);
        
  //     } else{
  //       setIsPhone(false);
       
  //     }
  //   }
  // };

  //  const handleDel = (sender) => {
  //    let messagesLocal = JSON.parse(localStorage.getItem("notify"));
  //    const itemPos = dataNotify.findIndex((item) => item.sender === sender);
  //    messagesLocal.splice(itemPos, 1);
  //    localStorage.setItem("notify", JSON.stringify(messagesLocal));
  //    setCountNotify(messagesLocal.length);
  //    setDataNotify(messagesLocal);
  //  };

  // const handleChangeStatus = (message) => {
  //   let messagesLocal = JSON.parse(localStorage.getItem("notify"));
  //   const itemPos = messagesLocal.findIndex((item) => item.message === message);
  //   console.log(itemPos)
  //   const newArr = [...messagesLocal];
  //   newArr[itemPos].status = true;
  //   localStorage.setItem("notify", JSON.stringify(newArr));
  //   setDataNotify(newArr);
      
    
    
  // }

  React.useEffect(() => {
    // addNotiToLocal();
    // checkNotify();
    getNotifyList()
    // setAudio(new Audio(NotificationSound));
    
  }, [msgSocket]);

    // notify sounds
    const audioEl = React.useRef(new Audio(NotificationSound));
  React.useEffect(() => {
    const audio = audioEl.current;
    const onPlay = () => audio.play();
    
    const filterMsgSocket = msgSocket.filter(x => x.message !== "event_VNT")
    if(filterMsgSocket && filterMsgSocket.length > 0){
      onPlay()
    }
    
  }, [msgSocket]);

  React.useEffect(() => {
    // checkIsPhone()
  }, [msgSocket]);

  const value = {
    countNotify,
    dataNotify,
    setDataNotify,
    isPhone,
    // checkNotify,
    msgSocket,
    countNotifyTicket,
    dataNotifyTicket,
    handleDel,
    handleGetInfoMsg,
    info,
    // handleDel
  };
  return (
    <CountContext.Provider value={value}>{children}</CountContext.Provider>
  );
}

export { CountContext, NotifyProvider };
