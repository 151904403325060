import { put, takeLatest } from "redux-saga/effects";
import axiosInstance from "../../components/axiosApi";
import { getCustomerError, getCustomerSuccess } from "./action";
import { GET_CUSTOMER_START, SEARCH_CUSTOMER } from "./type";

function* getCustomers(action) {
    try {
        let params = ''
        if(action.payload) {
            const { phone, city, assignTo, dataOrigin, offset, limit, from, to, lead_source_sub, lead_status, lead_status_sub  } = action.payload
            params += phone !== "" && phone !== undefined ? `phone=${phone}&`: ""
            params += city !== "" && city !== undefined ? `city=${city}&`: ""
            params += dataOrigin !== "" && dataOrigin !== undefined ? `lead_source=${dataOrigin}&`: ""
            params += lead_source_sub !== "" && lead_source_sub !== undefined ? `lead_source_sub=${lead_source_sub}&`: ""
            
            params += lead_status !== "" && lead_status !== undefined ? `lead_status=${lead_status}&`: ""
            params += lead_status_sub !== "" && lead_status_sub !== undefined ? `lead_status_sub=${lead_status_sub}&`: ""
            
            params += from !== "" && from !== undefined ? `from=${from}&`: ""
            params += to !== "" && to !== undefined ? `to=${to}&`: ""
            params += assignTo !== undefined && assignTo !== "" && assignTo != -99 ? `assigned_to=${assignTo}&`: ""
            params += `offset=${offset}&limit=${limit}`
        } 
        const res = yield axiosInstance.get(`/leads/?${params}`);
        
        yield put(getCustomerSuccess(res.data))
    } catch (error) {
        yield put(getCustomerError(error))
    }
}

export function* watchingGetCustomers() {
    yield takeLatest(GET_CUSTOMER_START, getCustomers);
    yield takeLatest(SEARCH_CUSTOMER, getCustomers);
}

