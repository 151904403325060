import { put, takeLatest } from "redux-saga/effects";
import * as types from './type';
import * as actions from './action';
import axiosInstance from "../../components/axiosApi";

function* getLogoFooter() {

    try {
        const res = yield axiosInstance.get(`/users/profile/`)
        yield put(actions.getLogoFooterSuccess(res.data))
    } catch (error) {
        yield put(actions.getLogoFooterFail(error))
    }
}

export function* watchingGetLogoFooter() {
    yield takeLatest(types.GET_LOGO_FOOTER, getLogoFooter);
}