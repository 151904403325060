export const GET_CALLSTATUS = "GET_CALLSTATUS"
export const GET_CALLSTATUS_SUCCESS = "GET_CALLSTATUS_SUCCESS"
export const GET_CALLSTATUS_FAIL = "GET_CALLSTATUS_FAIL"

export const CREATE_CALLSTATUS = "CREATE_CALLSTATUS"
export const CREATE_CALLSTATUS_SUCCESS = "CREATE_CALLSTATUS_SUCCESS"
export const CREATE_CALLSTATUS_FAIL = "CREATE_CALLSTATUS_FAIL"

export const RESET_MESSAGE = "RESET_MESSAGE"
export const RESET_MESSAGE_ERROR = "RESET_MESSAGE_ERROR"

export const EDIT_CALLSTATUS = "EDIT_CALLSTATUS"
export const EDIT_CALLSTATUS_SUCCESS = "EDIT_CALLSTATUS_SUCCESS"
export const EDIT_CALLSTATUS_FAIL = "EDIT_CALLSTATUS_FAIL"

export const DELETE_CALLSTATUS = "DELETE_CALLSTATUS"
export const DELETE_CALLSTATUS_SUCCESS = "DELETE_CALLSTATUS_SUCCESS"
export const DELETE_CALLSTATUS_FAIL = "DELETE_CALLSTATUS_FAIL"