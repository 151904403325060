import { FormControl, InputLabel, MenuItem, Select, Autocomplete, TextField } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';

const AssignToSelect = ({
    value,
    onChange
}) => {
    const stateUser = useSelector((state) => state.UsersReducer)
    
    const userlist = stateUser?.dataUsers?.active_users
    return (
        <>
             <FormControl fullWidth>
                <InputLabel id="assignTo">Chọn người phân công</InputLabel>
                <Select
                    labelId="assignTo"
                    fullWidth
                    size="small"
                    name="assigned_to"
                    label="Chọn người phân công"
                    value={value}
                    onChange={onChange}
                >
                    {
                        !stateUser.dataUsers.loading && stateUser?.dataUsers?.active_users?.length > 0 ? stateUser?.dataUsers?.active_users?.map((user, index) => {
                            return (
                                <MenuItem key={index} value={user.id}>{`${user.first_name} ${user.last_name}`}</MenuItem>
                            )
                        }) : <MenuItem value={-99}>{`--No Data--`}</MenuItem>
                    }

                </Select>
            </FormControl> 
           
        </>
    );
};

export default AssignToSelect;