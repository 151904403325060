import React from 'react'
import Typography from "@mui/material/Typography";
import blank from "../../assets/images/facebook-welcome.png"; 
const FbWelcome = () => {
  return (
    <div className="wrapper-chat user-list" style={{alignItems:'center', justifyContent:'center',display:'flex', flexDirection:'column'}}>
        <Typography style={{ fontWeight: 500, fontSize: 18,marginBottom: "10px" }}>Chào mừng đến với Chat Facebook</Typography>
        <img src={blank} />
    </div>
  )
}

export default FbWelcome